import React, {
  useState,
  useEffect,
} from "react";
import { Api } from "../../api/Api";
import "../../assets/OpenBanking/css/style.scss";
import { queryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useOpenBanking } from "../../Hooks/useOpenBanking";
import InputButton from "../../../src/Components/UI/InputButton";
import { AppDomain } from "../../Constants/EnvConstants";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import OB_AffordabilityV1 from "../Includes/Layouts/ObFollowup/OB_AffordabilityV1";
import OB_Splash from "../Includes/Layouts/ObFollowup/OB_Splash";


const OpenBankingProceed = () => {
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(1);
  const [reloader, setReloader] = useState(1);
  const history = useHistory();
  const { getVisitorID,getAccessToken, createCustomer, getProviders, getAuthGatewayUrl, storeApiResponse, OpenBankVisit,storeClickResponse } = useOpenBanking();
  const [btndisable, setBtnDisable] = useState(false);
  const [visitorId, setVisitorId] = useState(0);
  const [affordStatFirst, setAffordStatFirst] = useState('hide');
  const [obSplashStat,setObSplashStat] = useState('show');
  let redirectUrl = '';
  let redirectlink = '';
  let btn_label = '';
  let page = '';
  const query = new URLSearchParams(window.location.search);
  const query_source = query.get("atp_sub6");
  var source = query_source ? query_source : "live";

  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/api/v1/user-basic-details?query_string=" + queryString,
        {}
      );
      if (response.data.status === "Success") {
        let user_data = response.data.response.user_data;
        let uuid = response.data.response.uuid;
        let lender = response.data.response.lender;
        let source = response.data.response.source;
        let lstSalutation = response.data.response.lstSalutation;
        let txtFName = response.data.response.txtFName;
        let txtLName = response.data.response.txtLName;
        let txtEmail = response.data.response.txtEmail;
        let txtPhone = response.data.response.txtPhone;
        let is_test = response.data.response.is_test;
        let token = response.data.response.user_data.atp_sub2;
        let pixel = response.data.response.user_data.pixel;
        let obclosestat = response.data.response.obclosestat;


        localStorage.setItem('is_test', is_test);
        localStorage.setItem('user_data', JSON.stringify(user_data));

        if(uuid == null){
          history.push("/thankyou?uuid=" + uuid);
        }

        setUserDetails({
          uuid: uuid,
          lender: lender,
          source: source,
          lstSalutation: lstSalutation,
          txtFName: txtFName,
          txtLName: txtLName,
          txtEmail: txtEmail,
          txtPhone: txtPhone,
          is_test: is_test,
          token : token,
          pixel : pixel,
          obclosestat
        });

        setTimeout(() => {
          setLoader(0);
        }, 1500);

          
        const visitor = await getVisitorID(
          uuid,
          user_data,
          'new'
        )

        if(visitor.data.status == "success"){
          setVisitorId(visitor.data.visitorId);
        }
      
        const flpResponse = await OpenBankVisit(
          "open_bank_visit",
          uuid,
          user_data,
          "OpenBankingProceed",
          queryString,
          "proceed-ob"
        );
      } else {
        setLoader(0);
        history.push("/thankyou?uuid=" + response.data.response.uuid);
        return false;
      }
    })();
  }, []);


  useEffect(()=>{
       

    (async () => {
    
    localStorage.setItem('uuid',userDetails.uuid);
    localStorage.setItem('firstname',userDetails.txtFName);

    if (userDetails.uuid) {

      let payload = { "page": 'OB_SPLASH',type:'visit', "source": userDetails.source };
      await storeClickResponse({uuid:userDetails.uuid},"ob_creditcheck_history",userDetails.uuid,payload);

    } 

    if (userDetails.obclosestat == '1') { 
      history.push("/ob_splash_cancel?uuid=" + userDetails.uuid +"&atp_sub6="+ source);
      return false;
    }

  })();
  

  },[userDetails]);

  const pushProceedLink = async (e) => {
    GtmDataLayer({
      'question': 'OPEN BANKING - Proceed'
    });
    setBtnDisable(true);
    setLoader(1);
  
    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');
    
    let payload = { page,type:'btn_click', btn_label, "source": userDetails.source,'click_btn':'1' };
    await storeClickResponse({uuid:userDetails.uuid},"ob_creditcheck_history",userDetails.uuid,payload);



    localStorage.setItem('visitorId', visitorId);
    const response1 = await getAccessToken(userDetails.uuid, userDetails.source,userDetails.is_test,visitorId);

    if (response1 == undefined ||  response1 == null || response1 == '') {
      setLoader(0);
      setReloader(0);
      return false;
    }

    if (response1.data.status == "success") {
      //(response.data.access_token_ID);
      let payload = { "milestone_key": "access_token", "source": userDetails.source };
      await storeApiResponse(userDetails, "op_milestone_update", userDetails.uuid, payload);
      const customerResponse = await createCustomer(userDetails.uuid, userDetails.source, userDetails.lstSalutation, userDetails.txtFName, userDetails.txtLName, userDetails.txtEmail, userDetails.txtPhone,userDetails.is_test,visitorId)

      if (customerResponse == undefined ||  response1 == null || response1 == '') {
        setLoader(0);
        setReloader(0);
        return false;
      }

      if (customerResponse.data.status == "success") {
        localStorage.setItem('customer_id', customerResponse.data.customer_ID);
        let payload = { "milestone_key": "customer_create", "customer_id": customerResponse.data.customer_ID, 'source': userDetails.source };
        await storeApiResponse(userDetails, "op_customer_store", userDetails.uuid, payload);
        // const providerResponse = await getProviders(userDetails.uuid,userDetails.source,userDetails.is_test);
        // if (providerResponse.data.status === "success") {
        //     const providers = providerResponse.data.providers;
        //     const optionData = providers.map((value, index) => {
        //         return (
        //           <option value={value.provider} key={index}>
        //             {value.display_name}
        //           </option>
        //         );
        //       });
        // }
        let redirectUrl = (userDetails.source) ? AppDomain + "/ob-thankyou?uuid=" + userDetails.uuid + "&atp_sub6=" + userDetails.source : AppDomain + "/b-thankyou?uuid=" + userDetails.uuid;
        var response2 = await getAuthGatewayUrl(userDetails.uuid, customerResponse.data.customer_ID, redirectUrl, userDetails.source,userDetails.is_test,visitorId)
        
        if (response2 == undefined ||  response2 == null || response1 == '') {
          setLoader(0);
          setReloader(0);
          return false;
        }

        if (response2.data.status == "success") {
          payload = { "milestone_key": "lender_redirection", 'source': userDetails.source };
          await storeApiResponse(userDetails, "op_milestone_update", userDetails.uuid, payload);
          let url = response2.data.gateway_url;
          window.location.href = url;
          //setLoader(0)
        }
      }
    }
  }
  
  const keepMyFileLink = async(e) => { 
    setBtnDisable(true);
    setLoader(1);
    
    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');

    let payload = { page,type:'btn_click', "btn_label": btn_label, "source": userDetails.source,'click_btn':'7' };
    await storeClickResponse({uuid:userDetails.uuid},"ob_creditcheck_history",userDetails.uuid,payload);

    let payloadinitial = { "page": 'OB_LP',type:'visit', "source": userDetails.source };
    await storeClickResponse({uuid:userDetails.uuid},"ob_creditcheck_history",userDetails.uuid,payloadinitial);

    setObSplashStat('hide');
    setAffordStatFirst('show');
    setBtnDisable(false);

    setTimeout(() => {
      setLoader(0);
    }, 1500);

  }

  const closeMyFileLink = async(e) => {

    setBtnDisable(true);

    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');


    let payload = { page,type:'btn_click', "btn_label": btn_label, "source": userDetails.source,'click_btn':'8' };
    await storeClickResponse({uuid:userDetails.uuid},"ob_creditcheck_history",userDetails.uuid,payload);

    history.push("/ob_splash_cancel?uuid=" + userDetails.uuid +"&atp_sub6="+ source);
    return false;

  }

  return (
    <>
    
    <OB_Splash
         obSplashStat={obSplashStat}
         btndisable = {btndisable}
         keepMyFileLink = {keepMyFileLink}
         closeMyFileLink = {closeMyFileLink}
         loader={loader}
         userDetails={userDetails}
         />

    <OB_AffordabilityV1
        setLoader={setLoader}
        loader={loader}
        reloader={reloader}
        btndisable = {btndisable}
        setReloader={setReloader}
        pushProceedLink={pushProceedLink}
        lender ={userDetails.lender}
        affordStatFirst = {affordStatFirst}
        />

    </>


    // <div className="OpenBanking">
    //   <div className={`main-page-btn ${loader == 0 ? 'show' : 'hide'}`} id="page1">
    //     <section id="main-dv" className="btn-info_section">
    //       <div className="container btn-info_part">
    //         <div className="row justify-content-center">
    //           <div className="col-lg-10 col-11">
    //               <div className={`space text-center ${reloader == 1 ? 'show' : 'hide'}`}>
    //               <img className="logo_img" src="assets/img/logo_1.png" alt="" />
    //               <p className="htext">
    //                 <strong> One of the main reasons {userDetails.lender} rejects claims is the complaint was not accompanied by bank statements that verify income and expense at the time of the initial loan.</strong>
    //               </p>
    //               <p className="sub-text">Connect your bank account using <strong>OPEN BANKING</strong> to download your bank statements directly</p>
    //               <p className="info-italic">It's safe, secure, private and FREE!</p>
    //               <div className="button _1 anim_blue">
    //                 <InputButton
    //                   name="proceed"
    //                   className="btn_proceed"
    //                   id=""
    //                   value="Connect My Bank Account >"
    //                   btnType="button"
    //                   onClick={pushProceedLink}
    //                   disabled={btndisable}
    //                 />
    //                 <div className="back"></div>
    //               </div>
    //               <div className="mt-4">
    //               <img className="api-logo p-2" src="assets/img/bud-api.png" alt="" />
    //               <img className="api-logo p-2" src="assets/img/fca-logo.png" alt="" />
    //               </div>
    //             </div>

    //             <div className={`space text-center ${reloader == 0 ? 'show' : 'hide'}`}>
    //               <div className="button _1 w-100 anim_blue refresh_btn_outer">
    //                 <InputButton
    //                   name="proceedd"
    //                   className="btn_proceed refresh_btn"
    //                   id="bb"
    //                   value="Refresh"
    //                   btnType="button"
    //                   onClick={pushProceedLink}
    //                 />
    //                 <div className="back"></div>
    //               </div>
    //             </div>


    //           </div>
    //         </div>
    //       </div>
    //     </section>
    //   </div>
    //   <div className={`loader text-center ${loader == 1 ? 'showload' : 'hide'}`} id="page2">
    //     <div className="loader-anime">
    //       <img src="assets/img/loading-23.gif" alt="" />
    //     </div>
    //   </div>
    // </div>
  );
};

export default OpenBankingProceed;
