import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import InputButton from "../../../../UI/InputButton";
import Modal from "react-bootstrap/Modal";
import Button from "../../../../UI/Button";

const Difficulties = ({ className, onselectAnswer, addPending }) => {
  const labelclicked = async (e) => {
    if (e.target.value === "241") {
      setshowClaimModal(true);  
    }
    if (e.target.value === "240") {
      onselectAnswer(e);
    }
  };
  const [claimModal, setshowClaimModal] = useState(false);

  const handleCloseclaimModal = (e) => {
    setshowClaimModal(false);
  };

  return (
    <>
      <div className={className}>
      <div className="row">
          <div className="col-lg-6 offset-lg-3">
        <div className="radio-box question animated fadeInUp" >
          <h1 className="title">When you received the communications from the lender, did you feel that any difficulties you had repaying / financially were:</h1>
          <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="btn-comm com-btn">
          <RadioButtonQuest
            key="I thought that it was my fault / responsibility"
            className="radio-button"
            labelName="I thought that it was my fault / responsibility"
            labelClassName="com-btn btn-next w-100 mb-3"
            onClick={labelclicked}
            value="240"
            name="question_83"
            id="diffone"
            dataQuestId={83}
            dataAnsId={240}
            dataAnsLabel="I thought that it was my fault / responsibility"
          />
          <RadioButtonQuest
            key="I thought that it was the MoneyBarn’s fault / responsibility"
            className="radio-button"
            labelName="I thought that it was the MoneyBarn’s fault / responsibility"
            labelClassName="com-btn btn-next w-100 mb-3"
            onClick={labelclicked}
            value="241"
            name="question_83"
            id="difftwo"
            dataQuestId={83}
            dataAnsId={241}
            dataAnsLabel="I thought that it was the MoneyBarn’s fault / responsibility"
          />

<div class="text-center back">
                      <Button
                        className="btn btn-link mt-2 h2"
                        id="back1"
                        type="button"
                        buttonText="<< Previous"
                        onClick={() => addPending("difficulty")}
                      />
                    </div>
        </div>
        </div>
        </div>
        </div>
      </div>
      </div>
      </div>

      <Modal
        size="md"
        show={claimModal}
        onHide={handleCloseclaimModal}
        centered
        backdrop="static"
        className="claimdeinial"
      >
        <Modal.Body>
          <h3 className="text-center">Sorry we are unable to assist with this claim,<br /> as MoneyBarn are very likely to time-bar it,<br /> and not review it</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Difficulties;
