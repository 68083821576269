import React, { useEffect, useState } from "react";

const AlertModal = ({ 
  showSlidepopup,
  backpopup
}) => {
  return (
    <div className={`show_box ${showSlidepopup}`} >
      <div className="col-lg-12 text-center show_txt">
        <p>Sorry, we cannot accept your claim</p>
        <div style={{ width: '100%', textAlign: "center" }}>
          <button className="btn bckbt m-0  shw_bck" id="" onClick={backpopup}>{" Back"}</button>
        </div>
      </div>
    </div>
  );
};
export default AlertModal;
