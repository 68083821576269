import React, { useState } from "react";
import InputButton from "../../../UI/InputButton";
const OB_AffordabilityCheck = ({
  className,
  setLoader,
  loader,
  reloader,
  btndisable,
  setReloader,
  pushProceedLink,
  proceedManually,
  affordStat,
  goBack,
  bankname
}) => {
  return (
    <>
      {/* <div className="tms-ob-mbugf1">
        <div className={`tms-wrap2 bg-main-pr ${affordStat} `}>
          <div className="questtmsloan">
            <div
              className={`text-center loader-wrap ${
                loader == 1 ? "showload" : "hide"
              }`}
            >
              <div className="loader">
                <img src="assets/img/review.gif" alt="" />
                <div className="loader-text">
                  <p className=" text-analys">
                  </p>
                </div>
              </div>
            </div>

            <div className={`${reloader == 1 ? "show" : "hide"}`}>
              <div
                className={`main text-center main-div  ${
                  loader == 0 ? "showload" : "hide"
                }`}
              >
                <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
                  <img height="65" src="assets/img/logo_1.png" alt="" />
                </div>
                <div className="main-form-wraper form-part">
                  <div className="container">
                    <div className="item-cent-flx ">
                      <div className="pt-lg-2 pt-md-3 pt-sm-3  pt-3 text-center">
                        <h1 className="mt-md-2 mt-sm-2 mt-0 text-danger">
                          Complete Your Affordability Check to Finalise Your
                          Complaint{" "}
                        </h1>
                        <p className="my-3 p-style" style={{maxWidth: '800px',margin: '0 auto'}}>
                          We do this by connecting to your Bank Account using
                          the FCA regulated Open Banking platform via Bud
                          Financial.{" "}
                        </p>

                        <div className="row mt-lg-3 mt-md-2 mt-sm-3 mt-0">
                          <div className="col-lg-6 px-4 mb-2">
                            <div>
                              <button
                                className="btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 second anim_green disablebtn"
                                onClick={pushProceedLink}
                                disabled={btndisable}
                                data-btn-label="connect_bank_account"
                                data-page-label="OB_LP"
                              >
                                Connect Your Bank Account <span> {"›"}</span>
                              </button>

                              <p className="mt-3 text2b mb-0">
                                <img
                                  src="assets/img/lock.png"
                                  style={{ width: "2%" }}
                                />{" "}
                                &nbsp; Safe & Secure via FCA Regulated Open
                                Banking
                              </p>
                              <p className="mt-0 text3b red-text text-danger">
                                <img
                                  src="assets/img/clock.png"
                                  style={{ width: "3%" }}
                                />{" "}
                                Estimated Processing Time: 2 minutes{" "}
                              </p>
                            </div>
                          </div>
                          <div className="col-lg-6 px-4">
                            <div>
                              <button
                                className="btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 disablebtn"
                                onClick={proceedManually}
                                disabled={btndisable}
                                data-btn-label="pre_paid_envelopes"
                                data-page-label="OB_LP"
                              >
                                 I'll Upload Manually <span> {"›"}</span>{" "}
                              </button>
                              <p className="mt-3 text2b mb-0">
                                You can proceed and upload your Documents
                                manually
                              </p>
                              <p className="mt-0 text3b red-text text-danger">
                                <img
                                  src="assets/img/clock.png"
                                  style={{ width: "3%" }}
                                />{" "}
                                Estimated Processing time: 6-8 weeks{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="mb-lg-5 mb-md-5 mb-sm-3 mb-3 mt-5 mt-lg-3 mt-md-5 mt-sm-5 ">
                          <img
                            height="50"
                            src="assets/img/safe-secure.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section
              id="main-dv"
              className={`btn-info_section ${reloader == 0 ? "show" : "hide"}`}
            >
              <div className="inside_refresh">
                <div className="container btn-info_part info_part-vh-100">
                  <div className="row justify-content-center ">
                    <div className="col-lg-10 col-11">
                      <div className={`space text-center`}>
                        <div className="button _1 w-100 anim_blue refresh_btn_outer">
                          <InputButton
                            name="proceedd"
                            className="btn_proceed refresh_btn btn_proceed2"
                            id="bb"
                            value="Refresh"
                            btnType="button"
                            onClick={pushProceedLink}
                            dataBtnLabel="refresh_btn"
                            dataPageLabel="OB_LP"
                          />
                          <div className="back"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div> */}



      {/* // new Design  */}
      <div className="open_bank_GN_New">
      <div className={`${loader == 1 ? "show" : "hide"}`}>
              <div className="text-center">
                <img src="assets/img/review.gif" alt="" />
                <div className="loader-text">
                  <p className=" text-analys">
                  </p>
                </div>
              </div>
      </div>
      <div className={`${loader == 1 ? "hide" : "show"}`}>
        <div className="logo-two text-center pt-3"> <img src="assets\MB_UFGN_1\open_bank_GN_New\logo.png" alt="" /></div>
        <div className="container-fluid">

          <div
            className={`slide connect-account bglock slide02 ${affordStat} `}
          >
            <div className="wrap-app mt-3 text-center">
              {/* Banner */}
              <h2 className="fw-bold title mt-4">
                Unleash the full potential of your complaint with Open Banking!
              </h2>
              <p className=" ">
                Open Banking has enabled us to upend {bankname} baseless arguments about
                affordability. We have partnered with FCA regulated Open Banking platform,
                Bud Financial, to levarage state of the art technology to deliver tactful
                complaints with unprecedented sophistication.
              </p>
              <div className="mt-lg-5 mt-md-5 mt-sm-4 mt-4 mb-4">
                  <button
                    className="btn-comm2  btn-eff "
                    onClick={pushProceedLink}
                    disabled={btndisable}
                    dataBtnLabel="connect_bank_account"
                    dataPageLabel="OB_LP"
                    >
                    <small />
                    <small />
                    <small />
                    <small />
                    <span>
                      Connect Your Bank Account &nbsp;
                      <i className="bi bi-arrow-right-circle" />
                    </span>
                    </button>
              </div>
              <p className="mb-0  ">Safe &amp; Secure via FCA Regulated Open Banking</p>
              <div className="mb-4">
                {" "}
                <small className="text-danger">
                  Estimated Processing Time: 2 minutes
                </small>{" "}
              </div>
              <div className="text-center">
                {" "}
                <a
                  className=" text-primary mb-5 back-btn text-decoration-underline"
                  id="back1"
                  onClick={()=>goBack()}
                >
                  <i className="bi bi-arrow-left" /> Go Back
                </a>{" "}
              </div>
              <img className="mt-4" src="assets\MB_UFGN_1\open_bank_GN_New/bud-logo.png" alt="" />
            </div>
          </div>
        </div>
        <div className="text-center py-4 mb-lg-5 mb-md-5 mb-sm-4 mb-4 secure">
          <img height="35" src="assets\MB_UFGN_1\open_bank_GN_New/secure.png" alt="" />
        </div>
        <div className="btfooter pt-5 pb-4 px-4">
          <p className="mb-0 text-center">To finalize your complaint, it is essential to undergo the Affordability Check.
            Through this regulatory-compliant Open Banking approach, we can obtain the relevant information needed for
            evaluation of your financial circumstances and provide a comprehensive resolution to your concerns.</p>
        </div>
        </div>
      </div>
    </>
  );
};
export default OB_AffordabilityCheck;
