import react from "react";
import { MonthlyIncome } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
const CardMonthlyIncome = ({
  clsName,
  reff,
  onSelectAnswer,
  validationMsg,
  clearErrors,
  saveInputAns,
  validation,
  trigger,
  getValues,
  backClick,
}) => {
  const handleClick = async (e) => {
    let highestBalance = await trigger("cardMonthlyIncome");
    let errorFlag = 0;
    if (!highestBalance) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      saveInputAns(e);
    } else {
      return false;
    }
  };
  return (
    <>
      <div
        className={`radio-box animated fadeInUp text-center ${clsName}`}
        ref={reff}
      >
        <h3> When you took out your card what was your monthly income?</h3>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
          <SelectBox
            className="form-select form-control"
            OptionValue={MonthlyIncome}
            name="cardMonthlyIncome"
            id="cardMonthlyIncome"
            onChange={() => clearErrors("cardMonthlyIncome")}
            clearErrors={clearErrors}
            myRef={validation({
              required: "Please select your monthly income",
            })}
            validationMsg={
              validationMsg.cardMonthlyIncome &&
              validationMsg.cardMonthlyIncome.message
            }
          />
        </div>
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button
            type="button"
            className="btn-next"
            onClick={handleClick}
            data-quest-id={78}
            data-ans-id={null}
            data-ans-label={null}
            name="cardMonthlyIncome"
          >{`NEXT`}</button>
        </div>
      </div>
    </>
  );
};
export default CardMonthlyIncome;
