import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const BankStatement = ({
  className,
  slideChange,
  textHeadingLine1,
  textHeadingLine2,
  validation,
  backClick,
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const [checkedQuestionThree, setCheckedQuestionThree] = useState("unchecked");

  const nextSlide = async (e) => {
    if (e.target.value === "210") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      setCheckedQuestionThree("unchecked")
      slideChange(e);
    }
    if (e.target.value === "211") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      setCheckedQuestionThree("unchecked")
      slideChange(e);
    }
    if (e.target.value === "212") {
        setCheckedQuestionOne("unchecked");
        setCheckedQuestionTwo("unchecked");
        setCheckedQuestionThree("checked")
        slideChange(e);
    }
  };
  return (
    <>
      <div id="slide-bankstmt" className={`${className}`}>
        <ProgressBar value="15" />

        <h3> {textHeadingLine1} </h3>
        <div className="radio_buttons">
          <RadioButton
            value="210"
            name="bankStatement"
            className="radio regNextBtn"
            labelName="Payslips"
            labelClassName={`radio ${checkedQuestionOne}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <RadioButton
            value="211"
            name="bankStatement"
            className="radio regNextBtn"
            labelName="Bank Statements"
            labelClassName={`radio ${checkedQuestionTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <RadioButton
            value="212"
            name="bankStatement"
            className="radio regNextBtn"
            labelName="Can't remember"
            labelClassName={`radio ${checkedQuestionThree}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <p className="text-center">
          <span
            className="back-btn btn btn-link"
            name="backToPossesion"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div>
    </>
  );
};
export default BankStatement;