import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const Dependents = ({
  className,
  slideChange,
  textHeadingLine1,
  textHeadingLine2,
  validation,
  backClick,
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const [checkedFour, setcheckedFour] = useState("unchecked");
  const nextSlide = async (e) => {
    if (e.target.value === "7") {
      setCheckedOne("checked");
      slideChange(e);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "8") {
      setCheckedTwo("checked");
      slideChange(e);
    } else {
      setCheckedTwo("unchecked");
    }

    if (e.target.value === "9") {
      setCheckedThree("checked");
      slideChange(e);
    } else {
      setCheckedThree("unchecked");
    }

    if (e.target.value === "10") {
      setcheckedFour("checked");
      slideChange(e);
    } else {
      setcheckedFour("unchecked");
    }
  };
  return (
    <>
      <div id="slide-5" className={`${className}`}>
        <ProgressBar value="30" />

        <h3> {textHeadingLine1} </h3>
        <h4> {textHeadingLine2} </h4>
        <div className="radio_buttons">
        <div className="btn-comm">
          <RadioButton
            value="7"
            name="dependent"
            className="radio-button"
            labelName="0"
            labelClassName={`option_but radio ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
          <div className="btn-comm">
          <RadioButton
            value="8"
            name="dependent"
            className="radio-button"
            labelName="1"
            labelClassName={`option_but radio ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
          <div className="btn-comm">
          <RadioButton
            value="9"
            name="dependent"
            className="radio-button"
            labelName="2"
            labelClassName={`option_but radio ${checkedThree}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
          <div className="btn-comm">
          <RadioButton
            value="10"
            name="dependent"
            className="radio-button"
            labelName="3 or more"
            labelClassName={`option_but radio ${checkedFour}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
        </div>
        <p className="text-center">
          <span
            className="back-btn btn-link"
            name="back05"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div>
    </>
  );
};
export default Dependents;
