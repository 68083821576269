import React from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const DidReceiveIncome = ({ clsName, reff, onselectAnswer }) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>
          {" "}
          In the year when you took the card - did you receive any income?
        </h3>

        <RadioButtonQuest
          key="YES"
          className="radio"
          labelName="Yes"
          labelClassName="radioa"
          labelCountString="a"
          onClick={labelclicked}
          value="YES"
          name="didReceiveIncome"
          id="didReceiveIncomeYes"
          dataQuestId={77}
          dataAnsId={226}
        />
        <RadioButtonQuest
          key="NO"
          className="radio"
          labelName="No"
          labelClassName="radioa"
          labelCountString="b"
          onClick={labelclicked}
          value="NO"
          name="didReceiveIncome"
          id="didReceiveIncomeNo"
          dataQuestId={77}
          dataAnsId={227}
        />
      </div>
    </>
  );
};
export default DidReceiveIncome;
