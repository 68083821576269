import { useState } from 'react';
import OtpInputBox from '../../../../UI/OtpInputBox';

import secureLogo from  '../../../../../assets/NDY_UB_2/img/secure_icon.png';



const SortCode = ({
    skippedLeadsFLP,
    saveInputAns,
    setSortCode,
    clsName,
    reff
}) => {
    const [otp, setOtp] = useState("");
    const [error, setError] = useState(false);

    const handleOtpChange = (otp) => {
        setOtp(otp);
        setSortCode(otp);
        console.log("OTP Value:", otp);
    };
    
    const handleNext = async (e) => {
        if (otp.length != 6) {
            setError(true);
            return;
        } else if (parseInt(otp) === 0) {
            setError(true);
            return;
        } else {
            setError(false)
            saveInputAns(e);
        }
    };


    return (
        <div className={`radio-box animated fadeInUp space-top space-top2  ${clsName}`} ref={reff} >
            <h3><b>{`Please provide your Sort Code`}</b></h3>
            <div className="col-lg-12 mb-2">
                <div className="otp-input">
                    <OtpInputBox
                        length={6}
                        onChange={handleOtpChange}
                        className={'otp-input-field'}
                    />
                </div>
                {error && (
                    <span className='error_msg mt-2'>Please enter a valid Sort Code</span>
                )}
            </div>
            <div className="col-xl-12 col-lg-12 text-center p-0" >
                <button
                    type="button"
                    className="btn-next"
                    onClick={handleNext}
                    data-quest-id={86}
                    name="sortcode"
                >NEXT</button>
                <span className='warning-text'>{"(Please confirm your Sort Code to expedite your claim. If you prefer not to, you can skip this step.)"}</span>
                <br />
                    <a className="backbtn skip-btn" name="bank_acc_no" onClick={(e)=>skippedLeadsFLP(e,'skipFLPSortCode','sortcode')}>{"Skip"}</a>
            </div>
            <div className="row">
                <div className="mb-2 mt-3 text-center">
            <span className='col-12 text-center mt-1'><img src={secureLogo} style={{ width: "81px" }} /></span>
                </div>
            </div>
        </div>
    );
};

export default SortCode;
