import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import RadioButton from "../../../UI/RadioButton";

const Questionaire77 = ({
  clsName,
  reff,
  onselectAnswer,
  userEmail,
  setPrevStill,
  goBackOpt

}) => {

  const labelclicked = async (e) => {
    onselectAnswer(e);
    setPrevStill(e.target.value);
  };
  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>
          In the year when you took the car finance – did you receive any income?
        </h3>

        {/* <RadioButtonQuest
          key="YES"
          className="radio-button"
          labelName="Yes"
          labelClassName="option_but next-2"
          // labelCountString="a"
          onClick={labelclicked}
          value="226"
          name="question_77"
          id="flpsevenyes"
          dataQuestId={77}
          dataAnsId={226}
        /> */}
        <RadioButton
          key="sevenyesflp"
          id="sevenyesflp"
          value="226"
          name="question_77"
          className="radio-button option_but"
          labelName="Yes"
          labelClassName="option_but ques1"
          onClick={labelclicked}
          dataAnsId={77}
          dataQuestId={226}
        />

        <RadioButtonQuest
          key="NO"
          className="radio-button"
          labelName="No"
          labelClassName="option_but next-2"
          // labelCountString="b"
          onClick={labelclicked}
          value="227"
          name="question_77"
          id="flpsevenno"
          dataQuestId={77}
          dataAnsId={227}
        />
        <p className="text-center mb-2">
          <a href="#" className="backto77" name="backto77" onClick={goBackOpt}>
            &lt;&lt; Previous
          </a>
        </p>
      </div>
    </>
  );
};

export default Questionaire77;
