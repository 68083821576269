import React from 'react';

const ProgressBar = ({value}) => {
  if (value == 0) {
    return (
      <div className="progress progrss" data-percentage="0">
        <span className="progress-left">
          <span className="progress-bar"></span>
        </span>
        <span className="progress-right">
          <span className="progress-bar" id="progressBar"></span>
        </span>
        <div className="progress-value">
          <div id="progressBar_content" className="next01">
            <span className="perce">START</span><br />
            <span><img src="/assets/MB_UBRF_2/img/arrow_bt.png" alt="" /></span>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="progress progrss" data-percentage={value}>
        <span className="progress-left">
          <span className="progress-bar"></span>
        </span>
        <span className="progress-right">
          <span className="progress-bar" id="progressBar"></span>
        </span>
        <div className="progress-value">
          <div id="progressBar_content">
            <span className="perce">{value}%</span><br />
            <span>completed</span>
          </div>
        </div>
      </div>
    )

  }
}
export default ProgressBar;