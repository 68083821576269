import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import InputButton from "../../../../UI/InputButton";
import Modal from "react-bootstrap/Modal";

const Bankrupty = ({ onselectAnswer, reff, display, trigger ,unQualifiedAnswer}) => {
  const labelclicked = async (e) => {
    if (e.target.value === "21") {
      unQualifiedAnswer(e);
      setshowClaimModal(true);
    }
    if (e.target.value === "22") {
      onselectAnswer(e);
    }
  };
  const [claimModal, setshowClaimModal] = useState(false);

  const handleCloseclaimModal = (e) => {
    setshowClaimModal(false);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>Are you currently subject to Bankruptcy or Trust deed?</h3>
          <RadioButtonQuest
            key="No"
            className="radio-button"
            labelName="No"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="22"
            name="question_9"
            id="oneaa"
            dataQuestId={9}
            dataAnsId={22}
            dataAnsLabel="No"
          />
          <RadioButtonQuest
            key="Yes"
            className="radio-button"
            labelName="Yes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="21"
            name="question_9"
            id="twobb"
            dataQuestId={9}
            dataAnsId={21}
            dataAnsLabel="Yes"
          />
        </div>
      </div>

      <Modal
        size="md"
        show={claimModal}
        onHide={handleCloseclaimModal}
        centered
        backdrop="static"
        className="claimdeinial"
      >
        <Modal.Body>
          <h3 className="text-center">Sorry, we cannot accept your claim</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Bankrupty;
