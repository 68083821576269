import React, { useState, useEffect, useContext } from "react";
import { Api } from "../../api/Api";
import "../../assets/OpenBanking/css/styleV1.scss";
import { useHistory } from "react-router-dom";
import { useOpenBanking } from "../../Hooks/useOpenBanking";
import { queryString } from "../../Utility/QueryString";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import * as EnvConstants from "../../Constants/EnvConstants";
import { AppDomain } from "../../Constants/EnvConstants";
import Modal from "react-bootstrap/Modal";
import InputButton from "../UI/InputButton";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

const OB_AnalyseMB1 = () => {
  const history = useHistory();
  const [userDetails, setUserDetails] = useState({});
  const {
    getVisitorID,
    getAccessToken,
    createCustomer,
    getProviders,
    getAuthGatewayUrl,
    storeApiResponse,
    OpenBankVisit,
    callBackGateWay,
    customerConsentsApi,
    milestoneChase,
  } = useOpenBanking();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [visitorId, setVisitorId] = useState(0);
  const [loader, setLoader] = useState(1);

  const [modal, setModal] = useState(false);
  
  const {updateMilestone} = useUpdateMilestone();

  const handleClosePreModal = () => {
    setModal(false);
  };

  const environment = EnvConstants.AppEnv;
  const urlSearchParams = new URLSearchParams(window.location.search);

  // Get the value of the 'paramName' parameter
  const status = urlSearchParams.get("status");
  const uuid = urlSearchParams.get("uuid");
  const task_id = urlSearchParams.get("task_id");
  const query_source = urlSearchParams.get("atp_sub6");
  var source = (query_source) ? query_source : "live";
  var customer_id = localStorage.getItem("customer_id");
  var payload = "";
  var is_test = JSON.parse(localStorage.getItem("is_test"));
  //var visitorId = localStorage.getItem("visitorId");

  let fail_lender = localStorage.getItem("bud_lender");

  useEffect(() => {
    (async () => {
      if (status == "success") {
        setModal(false);
        payload = {
          milestone_key: "bank_connection",
          source: source,
          value: 1,
        };
        updateMilestone(uuid,"open_banking","live",1);
      } else {
        setModal(true);
        setLoader(0);
        //proceedOb(2);
        payload = {
          milestone_key: "bank_connection",
          source: source,
          value: 2,
        };
        updateMilestone(uuid,"open_banking","live",2);
      }
      const response = await Api.get(
        "/api/v1/ob-lender-status?uuid=" + uuid,
        {}
      );
      if (response.data.status === "Success") {
        let uuid = response.data.response.uuid;
        let is_test = response.data.response.is_test;

        await storeApiResponse(
          visitorParameters.visitor_parameters,
          "op_milestone_update",
          uuid,
          payload
        );
        await callBackGateWay(
          uuid,
          source,
          status,
          customer_id,
          task_id,
          is_test,
          visitorId
        );
        payload = { milestone_key: "callback_url", source: source };
        await storeApiResponse(
          visitorParameters.visitor_parameters,
          "op_milestone_update",
          uuid,
          payload
        );
        const custConsentRes = await customerConsentsApi(uuid, is_test);
        if (custConsentRes.data.status == "success") {
          await storeApiResponse(
            { uuid, source },
            "op_customer_consents",
            uuid,
            custConsentRes.data.consents
          );
        }
      }
      setTimeout(() => {
        let spliName = localStorage.getItem("split_name");
        if(status == "success"){
          setModal(false);
          setLoader(0);
          localStorage.setItem("BankStatement", 1);
          localStorage.setItem("OBStatement", 1);
          if (spliName == "MB_UFGN_4" || spliName == "MB_UFGN_4_1") {
            window.location.href = 'CheckListGN_4' + '?uuid=' + uuid;
            // history.push('CheckListGN_4' + '?uuid=' + uuid);
          }else{
            window.location.href = 'CheckList' + '?uuid=' + uuid;
            // history.push('CheckList' + '?uuid=' + uuid);
          }
        }else{
          // setModal(true);
          // setLoader(0);
          //history.push('ob_bank_stmt_upload-mb1'+ '?uuid=' + uuid);
        }
      }, 1500);
    })();
  }, []);

  const proceedOb = async (obstatus) => {
    if(obstatus == 1){
      setModal(false);
      setLoader(0);
      window.location.href = 'ob_bank_stmt_upload-mb1'+ '?uuid=' + uuid;
      // history.push('ob_bank_stmt_upload-mb1'+ '?uuid=' + uuid);
    }else{
      setModal(false);
      setLoader(1);
      setTimeout(() => {
        (async () => {
          const response = await Api.get(
            "/api/v1/ob-lender-status?uuid=" + uuid,
            {}
          );
          if (response.data.status === "Success") {
            let user_data = response.data.response.user_data;
            let uuid = response.data.response.uuid;
            let lender = response.data.response.lender;
            let source = "live";
            let lstSalutation = response.data.response.lstSalutation;
            let txtFName = response.data.response.txtFName;
            let txtLName = response.data.response.txtLName;
            let txtEmail = response.data.response.txtEmail;
            let txtPhone = response.data.response.txtPhone;
            let is_test = response.data.response.is_test;
            let token = response.data.response.user_data.atp_sub2;
            let pixel = response.data.response.user_data.pixel;
            let bud_lender = response.data.response.bud_lender;
            let ob_consent = response.data.response.consent_status;
            let spliName = localStorage.getItem("split_name");

            localStorage.setItem("is_test", is_test);
            //  localStorage.setItem('user_data', JSON.stringify(user_data));

            if (bud_lender != 0 || obstatus == 2) {
              if (obstatus == 2) {
                bud_lender = fail_lender;
              }

              if (bud_lender == 0) {
                if (ob_consent == '1') {
                  if (spliName == "MB_UFGN_4" || spliName == "MB_UFGN_4_1") {
                    window.location.href = 'CheckListGN_4' + '?uuid=' + uuid;
                    // history.push('CheckListGN_4' + '?uuid=' + uuid);
                  }else{
                    window.location.href = 'CheckList' + '?uuid=' + uuid;
                    // history.push('CheckList' + '?uuid=' + uuid);
                  }
                } else {
                  window.location.href = 'ob_bank_stmt_upload-mb1'+ '?uuid=' + uuid;
                  // history.push('ob_bank_stmt_upload-mb1'+ '?uuid=' + uuid);
              }
            }

              // if(uuid == null){
              //   history.push("/thankyou?uuid=" + uuid);
              // }
              let lender_array = [];

              if (environment == "prod" && is_test === true) {
                lender_array = [];
              } else {
                lender_array = [bud_lender];
              }

              setUserDetails({
                uuid: uuid,
                lender: lender,
                source: source,
                lstSalutation: lstSalutation,
                txtFName: txtFName,
                txtLName: txtLName,
                txtEmail: txtEmail,
                txtPhone: txtPhone,
                is_test: is_test,
                token: token,
                pixel: pixel,
                budLender: lender_array,
                ob_consent: ob_consent,
              });
            } else if (ob_consent == '1') {
              localStorage.setItem("BankStatement", 1);
              if (spliName == "MB_UFGN_4" || spliName == "MB_UFGN_4_1") {
                window.location.href = 'CheckListGN_4' + '?uuid=' + uuid;
                // history.push('CheckListGN_4' + '?uuid=' + uuid);
              }else{
                window.location.href = 'CheckList' + '?uuid=' + uuid;
                // history.push('CheckList' + '?uuid=' + uuid);
              }
            } else {
              window.location.href = 'ob_bank_stmt_upload-mb1'+ '?uuid=' + uuid;
              // history.push('ob_bank_stmt_upload-mb1'+ '?uuid=' + uuid);
            }

            setTimeout(() => {
              // setLoader(0);
            }, 1500);

            let visitor_id = localStorage.getItem("visitorId");

            if (
              visitor_id === undefined ||
              visitor_id === null ||
              visitor_id == ""
            ) {
              const visitor = await getVisitorID(uuid, user_data, "new");

              if (visitor.data.status == "success") {
                setVisitorId(visitor.data.visitorId);
              }
              localStorage.setItem("visitorId", visitorId);
            } else {
              setVisitorId(visitor_id);
            }

            const flpResponse = await OpenBankVisit(
              "open_bank_visit_live",
              uuid,
              user_data,
              "OpenBankingProceedS1",
              queryString,
              "ob-analyze-mb1"
            );
          } else {
            window.location.href = "/thankyou?uuid=" + response.data.response.uuid;

            // history.push("/thankyou?uuid=" + response.data.response.uuid);
            // setLoader(0);
            // history.push("/credit_check-s2?uuid=" + response.data.response.uuid);
            return false;
          }
        })();
      }, 3000);
    }
  };

  useEffect(async () => {
    const response1 = await getAccessToken(
      userDetails.uuid,
      source,
      userDetails.is_test,
      visitorId
    );
    if (response1.data.status == "success") {
      //(response.data.access_token_ID);
      let payload = {
        milestone_key: "access_token",
        source: source,
        lender: userDetails.budLender[0],
      };
      await storeApiResponse(
        userDetails,
        "op_milestone_update",
        userDetails.uuid,
        payload
      );
      const customerResponse = await createCustomer(
        userDetails.uuid,
        source,
        userDetails.lstSalutation,
        userDetails.txtFName,
        userDetails.txtLName,
        userDetails.txtEmail,
        userDetails.txtPhone,
        userDetails.is_test,
        visitorId
      );
      if (customerResponse.data.status == "success") {
        localStorage.setItem("customer_id", customerResponse.data.customer_ID);
        let payload = {
          milestone_key: "customer_create",
          customer_id: customerResponse.data.customer_ID,
          source: source,
          lender: userDetails.budLender[0],
        };
        await storeApiResponse(
          userDetails,
          "op_customer_store",
          userDetails.uuid,
          payload
        );
        let redirectUrl = AppDomain + "/ob-analyse-mb1?uuid=" + uuid;
        var response2 = await getAuthGatewayUrl(
          userDetails.uuid,
          customerResponse.data.customer_ID,
          redirectUrl,
          source,
          userDetails.is_test,
          visitorId,
          userDetails.budLender
        );
        if (response2.data.status == "success") {
          payload = {
            milestone_key: "lender_redirection",
            source: source,
            lender: userDetails.budLender[0],
          };
          await storeApiResponse(
            userDetails,
            "op_milestone_update",
            userDetails.uuid,
            payload
          );
          localStorage.setItem("bud_lender", userDetails.budLender);
          let url = response2.data.gateway_url;
          window.location.href = url;
          //setLoader(0)
        }
      }
    }
  }, [userDetails]);

  return (
    <div className="OpenBankingV1">
      <div
        className={`text-center loader-wrap ${
          loader == 1 ? "showload" : "hide"
        }`}
      >
        <div className="loader">
          <img src="assets/img/review.gif" alt="" />
          <h2>Please Wait...</h2>
        </div>
      </div>

      <Modal
        size="md"
        show={modal}
        onHide={handleClosePreModal}
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h5 className="text-center  mb-3">
            Are you sure that you want to cancel Open Banking with {fail_lender}
            ?
          </h5>
          <div className="prevname-Btnpop justify-content-center">
            <InputButton
              className="option_butb2   surNameYes  regNextBtnNew newfont "
              name="yes"
              value="YES"
              btnType="button"
              onClick={() => proceedOb(1)}
            />

            <InputButton
              className="option_butb2   surNameNo  regNextBtnNew newfont"
              name="no_prev_name"
              value="NO"
              btnType="button"
              onClick={() => proceedOb(2)}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OB_AnalyseMB1;
