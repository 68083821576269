import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import TextField from "../../../UI/TextField";
import InputButton from "../../../UI/InputButton";

const SupportingDocs = ({
  className,
  slideChange,
  textHeadingLine1,
  textHeadingLine2,
  validation,
  validationMsg,
  backClick,
  trigger,
  setSkipAgNo
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const [checkedFour, setCheckedFour] = useState("unchecked");
  const [checkedFive, setCheckedFive] = useState("unchecked");
  const [checkedSix, setcheckedSix] = useState("unchecked");
  const [checkedSeven, setcheckedSeven] = useState("unchecked");

  const [agNoShow, setAgNoShow] = useState("hide");

  const nextSlide = async (e) => {
    if (e.target.value === "124") {
      setCheckedOne("checked");
      setAgNoShow('show');
    } else {
      setCheckedOne("unchecked");
    }
    if (e.target.value === "125") {
      setCheckedTwo("checked");
      slideChange(e);
    } else {
      setCheckedTwo("unchecked");
    }
    if (e.target.value === "120") {
      setCheckedThree("checked");
      slideChange(e);
    } else {
      setCheckedThree("unchecked");
    }
    if (e.target.value === "126") {
      setCheckedFour("checked");
      slideChange(e);
    } else {
      setCheckedFour("unchecked");
    }
    if (e.target.value === "127") {
      setCheckedFive("checked");
      slideChange(e);
    } else {
      setCheckedFive("unchecked");
    }
    if (e.target.value === "147") {
      setcheckedSix("checked");
      slideChange(e);
    } else {
      setcheckedSix("unchecked");
    }
    if (e.target.value === "123") {
      setcheckedSeven("checked");
      slideChange(e);
    } else {
      setcheckedSeven("unchecked");
    }
  };

  const agreementValidation = async (e) => {
    let agreement_no = await trigger("question_45");
    if (agreement_no) {
      slideChange(e);
      setAgNoShow('hide');
    } else {
      return false;
    }
  };

  const SkipSupportdocs = async (e) =>{
    setAgNoShow('hide');
    slideChange(e);
    setSkipAgNo(true);
  };

  return (
    <>
      <div id="slide-5" className={`${className}`}>
        <ProgressBar value="45" />

        <h3> {textHeadingLine1} </h3>
        <h4> {textHeadingLine2} </h4>
        <div className="radio_buttons">
          {/* <RadioButton
            value="117"
            name="supportdocs"
            className="radio regNextBtn"
            labelName="Your Finance Agreement Number"
            labelClassName={`radio ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          /> */}

          <RadioButton
            value="124"
            name="supportdocs"
            className="radio-button"
            labelName="Your Loan Agreement number"
            labelClassName={`option_but radio ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />

          {/* <RadioButton
            value="118"
            name="supportdocs"
            className="radio regNextBtn"
            labelName="A Copy of Your Finance Agreement"
            labelClassName={`radio ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          /> */}


          <RadioButton
            value="125"
            name="supportdocs"
            className="radio-button"
            labelName="Any Documents Relating to your Loan agreement"
            labelClassName={`option_but radio ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />

          {/* <RadioButton
            value="119"
            name="supportdocs"
            className="radio regNextBtn"
            labelName="A Copy of the Initial Draft  of the Finance Agreement"
            labelClassName={`radio ${checkedThree}`}
            onClick={nextSlide}
            validation={validation()}
          /> */}

          <RadioButton
            value="120"
            name="supportdocs"
            className="radio regNextBtn"
            labelName="A Statement of Account"
            labelClassName={`radio ${checkedThree}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="126"
            name="supportdocs"
            className="radio-button"
            labelName="Bank statements (one month before and after the start of your Loan(s)"
            labelClassName={`option_but radio ${checkedFour}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="127"
            name="supportdocs"
            className="radio-button"
            labelName="Arrears Letters"
            labelClassName={`option_but radio ${checkedFive}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="123"
            name="supportdocs"
            className="radio regNextBtn"
            labelName="None of the above"
            labelClassName={`radio ${checkedSeven}`}
            onClick={nextSlide}
            validation={validation()}
          />

        </div>
        <p className="text-center">
          <span
            className="back-btn btn btn-link"
            name="back055"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
        <div role="dialog" className={`fade modal blackover ${agNoShow}`}>
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h3 className="text-center fonts15">Enter your Loan Agreement Number</h3>
                <div className="input-group mb-3 justify-content-center">
                  <div className="mb-3 mt-3 col-12 p-0">
                    <div className="input-group mb-3">
                      <TextField
                        type="tel"
                        className="form-control"
                        placeholder="Loan Agreement Number"
                        name="question_45"
                        validation={validation({
                          required: "Please Enter Loan Number",
                          minLength: {
                            value: 3,
                            message: "Please Enter Valid Loan Number",
                          },
                          pattern: {
                            value: /^\d*[1-9]\d*$/,
                            message:
                              "Loan Number is Invalid. Please Recheck",
                          },
                        })}
                        validationMsg={
                          validationMsg.question_45 &&
                          validationMsg.question_45.message
                        }
                      ></TextField>
                    </div>

                    <InputButton
                      name="supportdocs"
                      className="btn next08 regNextBtn"
                      id=""
                      value="Next"
                      btnType="button"
                      onClick={agreementValidation}
                      style={{ float: "none" }}
                    />
                    <p className="text-center">
                      <InputButton
                        name="skip_agree_no"
                        className="btn btn-link"
                        id=""
                        value="SKIP for now and follow up ASAP"
                        btnType="button"
                        onClick={SkipSupportdocs}
                        style={{ float: "none" }}
                      />
                    </p>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SupportingDocs;