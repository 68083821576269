import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import AnalyzeModal from "../../Layouts/Split_1/AnalayzeModal";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputButton from "../../../UI/InputButton";
const Bankruptcy = ({
  className,
  slideChange,
  textHeadingLine1,
  textHeadingLine2,
  validation,
  backClick,
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);

  const nextSlide = async (e) => {
    if (e.target.value === "21") {
      setCheckedOne("checked");
      setCheckedTwo("unchecked");
      setshowClaimModal(true);
      return false;
      //slideChange(e);
    } else {
      slideChange(e);
      setCheckedOne("unchecked");
    }
  };
  const [showFirstModal, setShowFirstModal] = useState(false);
  const handleShowFirst = () => setShowFirstModal(true);
  const handleCloseFirst = () => setShowFirstModal(false);

  const [showSecondModal, setShowSecondModal] = useState(false);
  const handleShowSecond = () => setShowSecondModal(true);
  const handleCloseSecond = () => setShowSecondModal(false);
  return (
    <>
      <div id="slide-4" className={`${className}`}>
        <ProgressBar value={50} />

        <h3> {textHeadingLine1} </h3>
        <div className="radio_buttons">
          <RadioButton
            value="21"
            name="bankruptcy"
            className="radio regNextBtn"
            labelName="Yes"
            labelClassName={`radio ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="22"
            name="bankruptcy"
            className="radio regNextBtn"
            labelName="No"
            labelClassName={`radio ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <p className="text-center">
          <span
            className="back-btn btn btn-link"
            name="back03"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div>
      {/* **** cannot accept your claim popup start **** */}
      <Modal
        size="md"
        show={claimModal}
        onHide={handleCloseclaimModal}
        centered
        backdrop="static"
        className="claimdeinial"
      >
        <Modal.Body>
          <h3 className="text-center">Sorry, we cannot accept your claim</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtn text-center"
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Bankruptcy;
