import React, {
  useState,
  useReducer,
  useContext,
  useEffect,
} from "react";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import TextField from "../../../UI/TextField";
import AddressTextField from "../../../UI/AddressTextField";
import { usePostCodeAddress } from "../../../../Hooks/usePostCodeAddress";
import HiddenField from "../../../UI/HiddenField";
import { useFormContext } from "react-hook-form";
import AddressSelectBox from "../../../UI/AddressSelectBox";


const initialState = {
  lookUppre: true,
  nextClickpre: false,
  checkSelectpre: false,
  getDetailspre: [],
  getAddresspre: [],
  showManualAddresspre: "hide",
  getPostcodepre: "#",
  clickManualLinkpre: 0,
};
const postcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucesspre": {
      return {
        ...state,
        lookUppre: action.payload.lookUppre,
        nextClickpre: action.payload.next_click,
        getPostcodepre: action.payload.postcode,
        getAddresspre: action.payload.get_address,
        checkSelectpre: action.payload.check_select,
        showManualAddresspre: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetailspre": {
      return {
        ...state,
        getDetailspre: action.payload.get_details,
        showManualAddresspre: action.payload.manual_address,
      };
    }
    case "showManualDivpre": {
      return {
        ...state,
        showManualAddresspre: "show",
        lookUppre: false,
        nextClickpre: true,
        checkSelectpre: false,
        clickManualLinkpre: action.payload.manual_click,
      };
    }
    case "resetaddresspre": {
      return {
        ...state,
        getDetailspre: [],
        getAddresspre: [],
        showManualAddresspre: "hide",
        lookUppre: true,
        nextClickpre: false,
        checkSelectpre: false,
      };
    }
    case "hideaddresspre": {
      return{
      ...state,
      checkSelectpre: false,
      showManualAddresspre: "hide",
    }
  }
    default:
      return state;
  }
};

const PrevAddressPopup = ({
  handleClose,
  show,
  formSubmit,
  setPreviousAddressData,
  titleQuest
}) => {
  const { trigger, errors, clearErrors, setError, register, getValues, setValue } = useFormContext();
  const [showAddress, setShowAddress] = useState(false);
  const [prevQuestname, setPrevQuestname] = useState(true);
  const [prevQuestaddress, setPrevQuestaddress] = useState(false);
  const [userTitle, setUserTitle] = useState(false);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { getPostcodepreValidation, getPostcodeAddress, getSplitPostcodeAddress } =
    usePostCodeAddress();
  const [state, dispatch] = useReducer(postcodeReducer, initialState);
  const [btnDisable, setBtnDisable] = useState(false);
  const [nobtnDisable, setNoBtnDisable] = useState(false);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const values = getValues();
  const checkValidation = async (e) => {

    e.preventDefault();
    dispatch({
      type: "resetaddresspre",
      get_address: [],
    });
    const txtCountryprev = await trigger("txtPrevPostCode");
    if (txtCountryprev && e.target.value != "") {
      await getPostcodeAddress(e.target.value)
        .then((res) => {
          if (res.data.status === "0") {
            setError("txtPrevPostCode", {
              type: "manual",
              message: "Please Enter Valid Postcode",
            });
          }
          if (res.data.length > 0) {
            dispatch({
              type: "validationSucesspre",
              payload: {
                lookUppre: false,
                next_click: true,
                postcode: e.target.value,
                get_address: res.data,
                check_select: true,
                manual_address: "hide",
              },
            });
            setPostCodeAddress(res.data);
          }
        })
        .catch(function () {
          setError("txtPrevPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        });
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    clearErrors([
      "addressprev1",
      "txtAddressLine1",
      "txtPrevAddress",
      "txtCounty",
      "txtTown2",
      "txtCountry2",
    ]);
    if (e.target.value != "") {
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcodepre
      );
      dispatch({
        type: "getExtraAddressDetailspre",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      setPostCodeAddress(getData.data);
      clearErrors([
        "txtHouseNumberPrev",
        "txtAddress3prev",
        "txtTownprev",
        "txtCountyprev",
        "txtCountryprev",
      ]);
    } else {
      dispatch({
        type: "getExtraAddressDetailspre",
        payload: { get_details: {}, manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookUppreValidation = async (e) => {
    await trigger("txtCountryprev");
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = state.getDetailspre.line_1;
    const addressLine2 = state.getDetailspre.line_2;
    const town = state.getDetailspre.town;
    const county = state.getDetailspre.county;
    const country = state.getDetailspre.country;
    setPreviousAddressData(state.getDetailspre);

    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const submitPreviousAddress = async (e) => {
    if (values.txtPrevPostCode) {
      addressValidationPre();
      const selectaddres = await trigger("addressprev1");
      if (selectaddres) {
        if (values.txtHouseNumberPrev) {
        formSubmit();
        setBtnDisable(true);
      }
    }
    }
    else {
      formSubmit();
      setBtnDisable(true);
    }
  };
  const HiddenFields = () => (
    <>
      <HiddenField
        name="txtUdprn"
        inputId="txtUdprn"
        inputValue={state.getDetailspre !== "#" ? state.getDetailspre.Udprn : ""}
      />
      <HiddenField
        name="txtDistrictPrev"
        inputId="txtDistrictPrev"
        inputValue={state.getDetailspre !== "#" ? state.getDetailspre.district : ""}
        validation={register()}
      />
      <HiddenField
        name="txtAddress3Previous"
        inputId="txtAddress3Previous"
        inputValue={state.getDetailspre !== "#" ? state.getDetailspre.line_3 : ""}
        validation={register()}
      />
      <HiddenField
        name="txtDeliveryPointSuffix"
        inputId="txtDeliveryPointSuffix"
        inputValue={
          state.getDetailspre !== "#" ? state.getDetailspre.deliverypointsuffix : ""
        }
      />
      <HiddenField
        name="txtPz_mailsort"
        inputId="txtPz_mailsort"
        inputValue={
          state.getDetailspre !== "#" ? state.getDetailspre.pz_mailsort : ""
        }
      />
      <HiddenField
        name="txtStreet"
        inputId="txtStreet"
        inputValue={
          state.getDetailspre.line_3 !== "#" ? state.getDetailspre.line_3 : ""
        }
      />
    </>
  );
  const addressValidationPre = async (e) => {
    let errorFlag = 0;
    const txtCountryprev = await trigger("txtPrevPostCode");
    var manual_txtHouseNumberPrev;
    var manual_txtTown;
    var manual_txtCountry;
    var txtAddress;
    if (txtCountryprev) {
      manual_txtHouseNumberPrev = await trigger("txtHouseNumberPrev");
      txtAddress = await trigger("addressprev1");
    } else {
      document.getElementById("txtCountryprev").focus();
      return false;
    }
    if (manual_txtHouseNumberPrev) {
      manual_txtTown = await trigger("txtTownprev");
    } else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await trigger("txtCountryprev");
    } else {
      return false;
    }
    if (
      !txtCountryprev ||
      !manual_txtHouseNumberPrev ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }

    if (state.clickManualLinkpre === 0) {
      const addressprev1 = await trigger("addressprev1");
      if (!addressprev1) {
        errorFlag = 1;
      }
    }
    if (errorFlag === 0) {
      checkPostCodeEntry();
    } else {
      return false;
    }
  };
  const clickAddress = (value) => {
    if (value == 1) {
        setUserTitle(false);
        setPrevQuestaddress(false);
        setShowAddress(true);
    } else {
      formSubmit();
      setNoBtnDisable(true);
    }
  };
  useEffect(()=>{
    if(titleQuest){
      setPrevQuestaddress(false);
    } else {
      setPrevQuestaddress(true)
    }
  },[titleQuest]);
  const clickSurname = (value) => {
    if (value == 1) {
        setPrevQuestname(false);
        setUserTitle(true);
    } else {
      let titleQuest = false;
      setPrevQuestaddress(true);
      setPrevQuestname(false);
    }
  };
  const nextModal = async () => {
    if (values.txtPreName) {
      const prevName = await trigger("txtPreName");
      if (prevName) {
        setUserTitle(false);
        setPrevQuestaddress(true);
      }
    } else {
      setUserTitle(false);
      setPrevQuestaddress(true);
    }

  }

  const revertClickAddress = () => {
    if(titleQuest){
      setPrevQuestname(true);
      setPrevQuestaddress(false);
    } else {
      setPrevQuestaddress(true);
    }
    if (values.txtPreName) {
      setValue('txtPreName', '');
    }
    setBtnDisable(false);
    setShowAddress(false);
    setUserTitle(false);
    if (values.txtPrevPostCode) {
      setValue('addressprev1', '');
      setValue('txtPrevPostCode', '');
      dispatch({
        type: "hideaddresspre"
    });
    }
  };

  return (
    <div
      className={`modal fade VAQPOP ${show ? "show" : "hide"}`}
      style={{ display: show ? "block" : "none", background: "#000000a8" }}


      id="submitBtn"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ backgroundColor: "#398cc0" }}>
          <div className="modal-header vaq-modal-head" style={{ height: "2.4rem", borderBottom: "0" }}>
            <button
              type="button"
              className="btn-close close-modal-btn"
              style={{ filter: "invert(1)" }}
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                revertClickAddress();
                handleClose();
              }}
            >


            </button>
          </div>
          <div className="modal-body vaq-modal-bdy">


            <div className={`cp1 ${(prevQuestname && titleQuest) ? "" : "d-none"}`}
              style={{ textAlign: "center" }}>
              <h5 className="modal-title mb-4" id="exampleModalLabel">Was your Surname different when you took out the credit with Vanquis
              </h5>
              <div className="row">
                <div className="col-6"> <input
                  type="button"
                  name="preDetails"
                  id="preDetails1"
                  className="btn btn-primary w-100 VAQbtn"
                  disabled={nobtnDisable}
                  defaultValue="No"
                  onClick={() => clickSurname(2)}
                /></div>
                <div className="col-6">
                  <input
                    type="button"
                    name="preDetails"
                    id="preDetails2"
                    className="btn btn-primary w-100 VAQbtn"
                    disabled={nobtnDisable}
                    defaultValue="Yes"
                    onClick={() => clickSurname(1)}

                  />
                </div>
              </div>

            </div>
            <div className={`cp1 ${prevQuestaddress ? "" : "d-none"}`}
              style={{ textAlign: "center" }}>
              <h5 className="modal-title mb-4" id="exampleModalLabel">Was your Address different when you took out the credit with Vanquis
              </h5>
              <div className="row">
                <div className="col-6"> <input
                  type="button"
                  name="preDetails"
                  id="preDetails1"
                  className="btn btn-primary w-100 VAQbtn"
                  disabled={nobtnDisable}
                  defaultValue="No"
                  onClick={() => clickAddress(2)}
                /></div>
                <div className="col-6">
                  <input
                    type="button"
                    name="preDetails"
                    id="preDetails2"
                    className="btn btn-primary w-100 VAQbtn"
                    disabled={nobtnDisable}
                    defaultValue="Yes"
                    onClick={() => clickAddress(1)}

                  />
                </div>
              </div>

            </div>

            <div
              className={`form-bg popup-form ${showAddress ? "" : "d-none"}`}
            >
              <div className="bodyVAQ">
                <div className="scheduler-border mt-2">
                  <h5> Enter your previous postcode</h5>
                  <div className="row preaddress">
                    <div className=" col-sm-7 form-group col-6">
                      <AddressTextField
                        type="text"
                        placeholder="Enter Previous Postcode"
                        name="txtPrevPostCode"
                        id="txtPrevPostCode"
                        className={`form-control prevaddressvaq`}
                        dataId="txtPrevPostCode"
                        onBlur={checkValidation}
                        autoComplete="off"
                        validation={register({
                          required: "Please select address",
                          pattern: {
                            value:
                              /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                            message: "Please enter a valid postcode",
                          },
                          minLength: {
                            value: 5,
                            message: "Please enter a valid postcode",
                          },
                        })}
                      />
                      <i className="validate" aria-hidden="true"></i>
                    </div>
                    <div className="col-sm-5 form-group form-g2 ps-lg-0 ps-md-0  ps-sm-0 col-6">
                      <button
                        type="button"
                        className="btn btn-primary w-100 VAQbtn pb-2 pt-2"
                        id="lookUppre2"
                        // style={{ height: "44px" }}
                        onClick={lookUppreValidation}
                      >
                        <span>Look Up Address</span>
                      </button>
                    </div>
                    <div className="col-sm-12 mt-1">
                    {errors.txtPrevPostCode && (
                      <span className="address1_error error_msg">
                        Please enter a valid postcode
                      </span>
                    )}
                    </div>
                  </div>
                  <div
                    className="pstcode postcdb mt-3"
                    style={
                      state.checkSelectpre
                        ? { display: "block" }
                        : { display: "none" }
                    }
                  >
                    <div className="mb-3">
                      <AddressSelectBox
                        className="form-control"
                        OptionValue={state.getAddresspre}
                        name="addressprev1"
                        id="addressprev1"
                        onChange={getValue}
                        myRef={register({ required: "Please select address" })}
                        validationMsg={
                          errors.addressprev1 && errors.addressprev1.message
                        }
                      />
                    </div>
                  </div>

                  <div className={`subform2 ${state.showManualAddresspre}`}>
                    <div className="mb-3">
                      <AddressTextField
                        type="text"
                        placeholder="Address Line 1 *"
                        name="txtHouseNumberPrev"
                        id="txtHouseNumberPrev"
                        className="form-control"
                        dataId="txtHouseNumberPrev"
                        autoComplete="off"
                        readonly={true}
                        validation={register({
                          // required: "Please Enter Address Line 1",
                        })}
                        validationMsg={
                          errors.txtHouseNumberPrev &&
                          errors.txtHouseNumberPrev.message
                        }
                        value={state.getDetailspre.line_1}
                      />
                    </div>
                    <div className="mb-3">
                      <AddressTextField
                        type={
                          state.getDetailspre.line_2 === null
                            ? "hidden"
                            : "text"
                        }
                        placeholder="Address Line 2"
                        name="txtAddress3prev"
                        id="txtAddress3prev"
                        className="form-control"
                        dataId="txtAddress3prev"
                        autoComplete="off"
                        readonly={true}
                        validation={register()}
                        validationMsg=""
                        value={state.getDetailspre.line_2}
                      />
                    </div>
                    <div className={`col-lg-12 form-group adj_width`}>
                      <AddressTextField
                        type="text"
                        placeholder="County"
                        name="txtCountyprev"
                        id="txtCountyprev"
                        className="form-control mb-3  hero-input"
                        dataId="txtCountyprev"
                        autoComplete="off"
                        readonly={true}
                        validation={register()}
                        validationMsg=""
                        value={state.getDetailspre.county}
                      />
                      <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                      ></i>
                    </div>
                    <div className={`col-lg-12 form-group adj_width}`}>
                      <AddressTextField
                        type="text"
                        placeholder="Town *"
                        name="txtTownprev"
                        id="txtTownprev"
                        className="form-control mb-3 hero-input"
                        dataId="txtTownprev"
                        autoComplete="off"
                        readonly={true}
                        validation={register()}
                        validationMsg={
                          errors.txtTownprev &&
                          errors.txtTownprev.message
                        }
                        value={state.getDetailspre.town}
                      />
                      <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                      ></i>
                    </div>
                    <div className={`col-lg-12 form-group adj_width}`}>
                      <AddressTextField
                        type="text"
                        placeholder="Country *"
                        name="txtCountryprev"
                        id="txtCountryprev"
                        className="form-control mb-3 hero-input"
                        dataId="txtCountryprev"
                        autoComplete="off"
                        readonly={true}
                        validation={register()}
                        validationMsg={
                          errors.txtCountryprev &&
                          errors.txtCountryprev.message
                        }
                        value={state.getDetailspre.country}
                      />
                      <i
                        className="validate validate_success"
                        aria-hidden="true"
                        style={{ display: "none" }}
                      ></i>
                    </div>
                    {Object.keys(state.getDetailspre).length > 0 ? <HiddenFields /> : null}
                  </div>
                </div>


                <div className="text-center mt-3">
                  <a>
                    <button
                      name="btnPreSubmit"
                      className="btn btn-primary w-100 VAQbtn"
                      id="btnPreSubmit"
                      type="button"
                      disabled={btnDisable}
                      onClick={submitPreviousAddress}
                    >
                      Submit
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div className="scheduler-border mt-2 mb-3 " style={{ display: userTitle ? "block" : "none" }}>
              <h5>

                Enter your previous surname
              </h5>
              <div id="preNameDiv">
                <div className=" form-group">
                  <TextField
                    type="text"
                    autoComplete="off"
                    id="txtPreName"
                    name="txtPreName"
                    className="form-control"
                    placeholder="Previous Surname"
                    validation={register({
                      required: "Please enter your first name",
                      minLength: {
                        value: 3,
                        message: "Please enter valid first name",
                      },
                      pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your name is invalid. Please recheck",
                      },
                    })}
                  ></TextField>
                  {errors.txtPreName && (
                    <span className="error_msg">
                      {errors.txtPreName.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="mt-3">
                <button
                  name="next"
                  className="btn btn-primary w-100 VAQbtn"
                  id="next"
                  type="button"
                  onClick={nextModal}
                >
                  Next
                </button>
              </div>

              {/* <div
                    className="addressbox"
                    id="add_prename"
                    style={{ display: "none" }}
                  >
                    <h6>Previous surname:</h6>
                    <div
                      className="previous-name-details"
                      id="previous-name-details"
                    ></div>
                  </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrevAddressPopup;
