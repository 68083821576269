import React, { useState } from "react";
import RadioButton from "../../../../UI/RadioButtonOutLabel";
import Button from "../../../../UI/Button";

const QuestionnaireFlpKdate = ({
  questArr,
  reff,
  display,
  onselectAnswer,
  vehicle,
  setValidKeeperDate,
  keeperDates,
  removeFromPending,
  analyzingFn,
  addPending,
  className
}) => {
  const nextSlide = async (e) => {
    let date_index = e.target.id;
    let dataNsId = e.target.getAttribute("data-ans-id")
      ? e.target.getAttribute("data-ans-id")
      : "";
    // if (date_index == '0') {

    // 	setValidKeeperDate(1);
    // 	removeFromPending('keeper_date');
    // 	removeFromPending('loan_date');
    // }else{
    // 	removeFromPending('keeper_date');
    // }
    analyzingFn(date_index);
    onselectAnswer(e);
  };

  let keeperArray = [];
  let keeperData = [];
  if (typeof vehicle.keeper_dates !== "undefined") {
    keeperData = JSON.parse(vehicle.keeper_dates);
    keeperArray = keeperData.keeper_dates;
  } else {
    keeperArray = keeperDates.keeper_dates;
  }
  return (
    <>
      <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">
              {" "}
              When did you start the finance?
            </h1>

            <div className="row mt-4">
              <div className="col-lg-12">
                <div className=" col-lg-12 mb-pd text-center ">
                  <div className="btn-comm com-btn  ">
                    {keeperArray &&
                      keeperArray.map((text, index, { length }) => (
                        <RadioButton
                          key={text.toString()}
                          id={index}
                          value={text}
                          name="question_11"
                          className="radio-button"
                          labelName={text}
                          labelClassName="option_but  date1"
                          onClick={nextSlide}
                          dataAnsId={text}
                          dataQuestId={11}
                        />
                      ))}
                    <RadioButton
                      key="Other"
                      id="Other"
                      value="Other"
                      name="question_11"
                      className="radio-button"
                      labelName="Other"
                      labelClassName="option_but  date1"
                      onClick={nextSlide}
                      dataAnsId="80other"
                      dataQuestId="11"
                    />
                  </div>
                  <div class="text-center back">
                    <Button 
                    className="btn btn-link mt-2 h2" 
                    id="back1" 
                    type="button"
                    buttonText="<< Previous"
                    onClick={()=>addPending("keeper_date")}
                    />
                </div>
                </div>
                <div></div>
              </div>
            </div>
            {/* <p className="text-center back"><a className="back2" id="back2">
                        &lt;&lt; Previous</a> 
                    </p> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireFlpKdate;
