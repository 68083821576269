import React from "react";

function ErrorMessage({ error, className = "" }) {
  return (
    <>
      {!!error && (
        <span className={`error_msg ${className}`}>{error.message}</span>
      )}
    </>
  );
}

export default ErrorMessage;
