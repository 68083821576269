import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useContext,
} from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import PersonalDetails from "../Includes/Layouts/TMS_G_V3/PersonalDetails";
import PostCode from "../Includes/Layouts/TMS_G_V3/PostCode";
import ContactDetails from "../Includes/Layouts/TMS_G_V3/ContactDetails";
import Bankruptcy from "../Includes/Layouts/TMS_G_V3/Bankruptcy";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import * as EnvConstants from "../../Constants/EnvConstants";
import Lenders from "../Includes/Layouts/TMS_G_V3/Lenders";
import Claim from "../Includes/Layouts/TMS_G_V3/Claim";

const FormTMS_G_V3 = () => {
  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
  };
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "personalvalidate") {
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "personal") {
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "contactvalidate") {
          return {
            ...state,
            showSlide2: "hide",
            showSlide3: "show",
          };
        } else if (action.payload.clickedSlide.slide === "no_prev_post") {
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "postcode") {
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "bankruptcy") {
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show",
          };
        } else if (action.payload.clickedSlide.slide === "lendervalidate") {
          return {
            ...state,
            showSlide5: "hide",
            showSlide6: "show",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      case "previousSlides": {
        if (action.payload.previousSlide.slide == "back01") {
          return {
            ...state,
            showSlide1: "show",
            showSlide2: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back02") {
          return {
            ...state,
            showSlide2: "show",
            showSlide3: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back03") {
          return {
            ...state,
            showSlide3: "show",
            showSlide4: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back04") {
          return {
            ...state,
            showSlide4: "show",
            showSlide5: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back05") {
          return {
            ...state,
            showSlide5: "show",
            showSlide6: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back06") {
          return {
            ...state,
            showSlide6: "show",
            showSlide7: "hide",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      default: {
      }
    }
  };
  const splitForm = useRef(null);
  const scrollfrmv2div = useRef([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const [previousName, setPreviousName] = useState("");
  const [previousAddressData, setPreviousAddressData] = useState({});

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const slideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setClickedSlide({ slide: e.target.name });
  };
  const slideChangeAddress = () => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setClickedSlide({ slide: "address" });
  };

  const previousSlideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: "previousSlides", payload: { previousSlide } });
    }
  }, [previousSlide]);

  const formSubmit = async () => {
    const form = splitForm.current;
    const values = getValues();
    const formData = values;

    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      const txtAddress3 = form["txtAddress3"].value;
      const txtDistrict = form["txtDistrict"].value;
      formData.txtDistrict = txtDistrict;
      formData.txtAddress3 = txtAddress3;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }

    //  ****Previous Name****
    formData.Previousname = previousName;

    //***previous_address ****/
    formData.previous_postcode = previousAddressData.postcode;
    formData.previous_address_line1 = previousAddressData.line_1;
    formData.previous_address_line2 = previousAddressData.line_2;
    formData.previous_address_line3 = previousAddressData.line_3;
    formData.previous_address_county = previousAddressData.county;
    formData.previous_address_town = previousAddressData.town;
    formData.previous_address_country = previousAddressData.country;
    formData.previous_district = previousAddressData.district;
    formData.previous_address_id = previousAddressData.prevAddressId;

    //Sending Questiionaire Data
    formData.question_26 = formData.stillactive;
    formData.question_27 = formData.possession;
    formData.question_28 = formData.YearOfPossession;
    formData.question_2 = formData.employment;
    formData.question_3 = formData.dependent;
    formData.question_29 = formData.verify;
    formData.question_30 = formData.verify2;
    formData.question_9 = formData.bankruptcy;
    //Questiionaire Data Complete

    formData.page_name = "MB_UBRF_1";
    formData.pCode_manual = form["pCode_EntryType"].value;
    console.log(formData);

    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("queryString", queryString);
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "split_form_submit",
        "MB_UBRF_1",
        queryString,
        visitorParameters.data
      );
      if (formSUbmitResult.data.status === "Success") {
        if (queryString != null) {
          console.log("first if");
          console.log(queryString);
          if (queryString.indexOf("&" + field + "=") !== -1) {
            console.log("s");
            if (EnvConstants.AppConversionPixel === "true") {
              console.log("if fb");
              history.push("/fbpixel?split_name=MB_UBRF_1"); // fb fixel firing
            } else {
              console.log("else fb");
              window.scrollTo(0, 0);
              history.push(
                "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
              );
            }
          } else {
            console.log("another else fb");
            window.scrollTo(0, 0);
            history.push(
              "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          }
        } else {
          console.log("laset else  fb");
          window.scrollTo(0, 0);
          history.push(
            "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
          );
        }
      }
    }
  };

  return (
    <>
      <form
        ref={splitForm}
        name="split_form"
        id="user_form"
        method="POST"
        autoComplete="off"
      >
        <div ref={scrollfrmv2div}>
          <PersonalDetails
            className={`col-12 top_minus ${state.showSlide1}`}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            getValues={getValues}
            splitForm={splitForm}
            slideChange={slideChange}
            slideChangeAddress={slideChangeAddress}
            backClick={previousSlideChange}
            setError={setError}
            setPreviousName={setPreviousName}
          />
          <ContactDetails
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            className={`col-12 top_minus ${state.showSlide2}`}
            getValues={getValues}
            formSubmit={formSubmit}
            slideChange={slideChange}
            backClick={previousSlideChange}
          />
          <PostCode
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            className={`${state.showSlide3}`}
            getValues={getValues}
            splitForm={splitForm}
            scrollView={scrollfrmv2div}
            slideChange={slideChange}
            backClick={previousSlideChange}
            setPreviousAddressData={setPreviousAddressData}
          />

          <Bankruptcy
            className={`col-12 top_minus ${state.showSlide4}`}
            textHeadingLine1="Have you ever been in or are proposed to be in an IVA, Debt Relief Order, Declared Bankrupt or something similar?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          <Lenders
            className={`col-12 top_minus ${state.showSlide5}`}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            getValues={getValues}
            splitForm={splitForm}
            slideChange={slideChange}
            slideChangeAddress={slideChangeAddress}
            backClick={previousSlideChange}
            setError={setError}
            setPreviousName={setPreviousName}
          />
          <Claim
            className={`col-12 top_minus ${state.showSlide6}`}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            getValues={getValues}
            splitForm={splitForm}
            slideChange={slideChange}
            slideChangeAddress={slideChangeAddress}
            backClick={previousSlideChange}
            setError={setError}
            setPreviousName={setPreviousName}
          />
        </div>
        <div className="col-lg-12 text-center slide-logo">
          <img src="/assets/TMS_G_V3/img/ssl.svg" alt="" />
        </div>
      </form>
    </>
  );
};

export default FormTMS_G_V3;
