import React, {
    useRef,
    useState,
    useEffect,
    useContext,
    useReducer,
} from "react";
import "../../assets/PersonKyc_MB_UFGN_2/css/person_edit.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import tms_logo from "../../assets/AnalyseMB_GN_NEW/img/logo.png";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import Accordion from "react-bootstrap/Accordion";
import { queryString } from "../../Utility/QueryString";
import { useIdUpload } from "../../Hooks/useIdUpload";
import { useHistory } from "react-router-dom";
import EditKycDetailsMB_UFGN_2 from "./EditKycDetailsMB_UFGN_2";
import { useForm } from "react-hook-form";
import Idupload_MB_UFGN_2 from "./Idupload_MB_UFGN_2";



const PersonKycMB_GN_2 = ({ }) => {

    const history = useHistory();
    const [editKycDiv, setEditKycDiv] = useState("hide");
    const [iduploadDiv, setIdUploadDiv] = useState("hide");
    const [showfirstloader, setShowfirstloader] = useState("hide");
    const [optionDiv, setOptionDiv] = useState("show");
    const { visitorParameters } = useContext(VisitorParamsContext);



    const formParameters = JSON.parse(localStorage.getItem("formData"));

    const editKycDetails = () => {
        setShowfirstloader("hide");
        setEditKycDiv("show");
        setIdUploadDiv("hide");

    };

    const uploadIdDoc = () => {
        setShowfirstloader("hide");
        setIdUploadDiv("show");
        setEditKycDiv("hide");

    };

    const goBack = () =>{
        history.push(
            "/CheckList?uuid=" + visitorParameters.visitor_parameters.uuid
        );
    }


    return (
        <>
            <GetVisitorsParams />
            <div className="kyc_edit">
                <div className="logo-two text-center pt-3" >
                    {" "}
                    <img src="assets/CheckList/img/logo.png" alt="" />
                </div>
                <div className="container-fluid">

                    <div className={` slide  page-loader loader1 ${showfirstloader}`}>
                        <div className="wrap-app mt-3 text-center">
                            <div className="mb-5 pb-5">
                                <img height="100" className="mb-2" src="assets/PersonKyc_MB_UFGN_2/img/loader-review2.gif" alt="" />
                                <p className="mb-5">Please Wait</p>
                            </div>
                        </div>
                    </div>

                    <div className="slide connect-account kycupload pb-1">
                        <div className="wrap-app mt-3 text-lg-center text-md-center text-sm-center text-start">
                            <div className={`d-flex ${optionDiv}`}>
                                <div className="text-center">
                                    {" "}
                                    <img height={40} src="assets/PersonKyc_MB_UFGN_2/img/alert.png" alt="" />{" "}
                                </div>
                                <div className="mt-2">
                                    <p className="ps-3 mb-lg-5 mb-md-5 mb-sm-4 mb-4 text-start">
                                        We were unable to match your provided address against the Electoral
                                        Roll. To satisfy our (KYC) "Know Your Customer" requirements, please
                                        either edit your details below or upload a current copy of your
                                        government issued identification.
                                    </p>
                                </div>
                            </div>
                            <div className="form-section">
                                <ul
                                    className={`nav-block nav-pills mb-3 p-0  ${optionDiv}`}
                                    id="pills-tab"
                                    role="tablist"
                                >
                                    <li className="nav-item  " role="presentation">
                                        <button
                                            className="w-100 btn-comm2 inline-block me-2  py-3 px-3 mb-3 editProfile"
                                            id="pills-home-tab"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-home"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-home"
                                            aria-selected="true"
                                            onClick={editKycDetails}
                                        >
                                            {" "}
                                            <span>
                                                <i className="bi bi-pencil-square" />
                                                &nbsp;&nbsp; I'll Edit My Details &nbsp;&nbsp;
                                                <i className="bi bi-arrow-right-circle" />{" "}
                                            </span>{" "}
                                        </button>
                                    </li>
                                    <li className="text-center">
                                        {" "}
                                        <div className="pb-3">--- OR ---</div>{" "}
                                    </li>
                                    <li className="nav-item " role="presentation">
                                        <button
                                            className="w-100  btn-comm2 inline-block  py-3 px-3 uploadID"
                                            id="pills-profile-tab "
                                            data-bs-toggle="pill"
                                            data-bs-target="#pills-profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="pills-profile"
                                            aria-selected="false"
                                            onClick={uploadIdDoc}
                                        >
                                            {" "}
                                            <span>
                                                {" "}
                                                <i className="bi bi-upload" />
                                                &nbsp;&nbsp; I'll Upload My ID &nbsp;&nbsp;
                                                <i className="bi bi-arrow-right-circle" />{" "}
                                            </span>
                                        </button>
                                    </li>
                                </ul>



                                <div className="tab-content" id="pills-tabContent">
                                    <EditKycDetailsMB_UFGN_2
                                        className={editKycDiv}
                                        setEditKycDiv={setEditKycDiv}
                                        setShowfirstloader={setShowfirstloader}
                                        setOptionDiv={setOptionDiv}
                                    />


                                    {/* // id upload section */}

                                    <Idupload_MB_UFGN_2
                                        className={iduploadDiv}
                                        setIdUploadDiv={setIdUploadDiv}
                                    />




                                </div>

                                <div className={`text-center ${optionDiv}`}>
                                    <a onClick={()=>goBack()} >
                                    <div className="mt-5 text-primary mb-5 back-btn text-decoration-underline" id="back2">
                                        <i className="bi bi-arrow-left"></i> Go Back</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center py-4 mb-lg-5 mb-md-5 mb-sm-4 mb-4 secure">
                    <img height="35" src="assets/PersonKyc_MB_UFGN_2/img/secure.png" alt=""/>
                </div>
            </div>


        </>
    );



};

export default PersonKycMB_GN_2;