import React from "react";

const Loader = ({ showLoad,content }) => {
  return (
    <>
    <div className={`tms-wrap2 bg-main-pr ${showLoad}`}>
     <div className="text-center loader-wrap loader2" >
      <div className="loader-logo">
        <img src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt=""/>
      </div>

      <div className="loader">
        <img src="assets/img/review.gif" alt=""/>
        <div className="loader-text">
          <p className="fs-5 pt-0">
            {content}</p>

        </div>
      </div>
    </div>

    {/* <div className="main text-center main-div ">    
        <img height="50" className="safe-secure-img" src="assets/img/safe and secure.png" alt=""/>
    </div> */}
  </div>

    </>
  );
};

export default Loader;