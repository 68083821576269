import React, { useState, useContext, useEffect } from "react";
import "../../assets/BankStatemenFLPS3/css/bankstatementS3.scss"
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";
import { queryString, updateQueryString } from "../../Utility/QueryString";
import { Api } from "../../api/Api";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
import { useIdUpload } from "../../Hooks/useIdUpload";
import IncomeExpenditure from "../Includes/Layouts/BankStatementS3/IncomeExpenditure";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

const BankIncomeStatementS3 = () => {
    const [showLoad, setshowLoad] = useState("show");
    const history = useHistory();
    const { skippedLeads, skippedLeadsFLP } = useSkippedLeads();
    const { saveFLPIdUpload, isLoading } = useIdUpload();
    const split_name = localStorage.getItem("split_name");
    const { visitorParameters } = useContext(VisitorParamsContext);
    const formParameters = JSON.parse(localStorage.getItem("formData"));
    const [selectedFile, setSelectedFile] = useState("");
    const [selectedFileType, setSelectedFileType] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isInValidfFile, setIsInValidfFile] = useState(false);
    const [base64Image, setBase64Image] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const [document, setDocument] = useState(false);
    const [file, setFile] = useState("");
    const [activeClass, setActiveClass] = useState("disabled");
    const [btnDisabled, SetBtnDisabled] = useState(false);
    const [allFollowupData, setFollowupData] = useState(false);
    const query_string = localStorage.getItem("queryString");
    const querystring = queryString;
    const queryStrings = query_string ? query_string : querystring;
    const [optionBtnDiv, setOptionDiv] = useState("hide");
    const [incomeInputDiv, setIncomeInputDiv] = useState("hide");
    const [uuid, setUuid] = useState("hide");
    const {updateMilestone} = useUpdateMilestone();

    const userfile = {
        preview: undefined,
        title: undefined,
    };

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
            try {
                const response = await Api.get(
                    "/api/v1/followup/get-pending-details-s2?query_string=" + queryStrings,
                    {}
                );

                if (isMounted) {
                    if (response.data.status === "Success") {
                        let followupDetails = response.data.response.followup_data;
                        localStorage.setItem("queryString", queryString);
                        localStorage.setItem(
                            "followupDetails",
                            JSON.stringify(followupDetails)
                        );
                        let user_uuid = response.data.response.uuid;
                        setFollowupData(followupDetails);
                        setUuid(user_uuid);
                    }
                }
                setTimeout(() => {
                    setshowLoad("hide");
                    setOptionDiv("show");
                }, 2000);
            } catch (error) {
                console.warn(error);
            }
        };

        fetchData();

        return () => {
            isMounted = false;
        };
    }, []);

    const flp_source = allFollowupData.atp_sub6;

    const incomeExpManually = (e) => {
        setOptionDiv("hide");
        setIncomeInputDiv("show");
    };


    const _handleReaderLoaded = (readerEvt) => {
        let binaryString = readerEvt.target.result;
        setBase64Image(binaryString);
    };

    const changeFilesHandler = (event) => {
        setActiveClass("fileupload");
        SetBtnDisabled(false);
        setSelectedFile(event.target.files[0]);
        let imageFile = event.target.files[0];
        if (imageFile) {
            setIsFilePicked(true);
            let extension = event.target.files[0].type;
            let imgsize = event.target.files[0].size;
            let allowedExtensions = [
                "image/jpeg",
                "image/png",
                //"application/zip",
                "application/msword",
                "application/pdf",
            ];
            if (allowedExtensions.indexOf(extension) === -1) {
                SetBtnDisabled(true);
                setIsInValidfFile(true);
                setErrorMessage(
                    "The uploaded Doc type should be an image in jpeg,png,jpg,pdf,doc, or docx"
                );
            } else if (imgsize > 1024 * 1024 * 20) {
                SetBtnDisabled(true);
                setIsInValidfFile(true);
                setErrorMessage("Please choose a file less than 20mb");
            } else {
                setSelectedFileType(event.target.name);
                setIsInValidfFile(false);
                //_handleFileRead.bind(event.target.files[0]);
            }
            setDocument(false);

            if ("nationalIDCard_doc" == event.target.name) {
                setDocument(true);
            }

            userfile.preview = URL.createObjectURL(imageFile);
            userfile.title = imageFile.name;

            if (imageFile) {
                let reader = new FileReader();
                reader.onload = _handleReaderLoaded.bind(this);
                reader.readAsDataURL(imageFile);
            }
        } else {
            setIsFilePicked(false);
            userfile.preview = undefined;
            userfile.title = undefined;
        }
    };


    const savedocument = async (e) => {
        if (selectedFile) {
            setIsInValidfFile(false);
            try {
                let uploadData = "";
                uploadData = {
                    imageFile: base64Image,
                    captureType: selectedFileType.substring(
                        selectedFileType.indexOf("doc")
                    ),
                    documentType: "BankStmt",
                };

                const response = await saveFLPIdUpload(
                    uploadData,
                    allFollowupData,
                    queryString,
                    "flp_support_docs_store"
                );
                if (response.data.status == "Success") {
                    console.log("Upload Status-" + response.data.status);
                    localStorage.setItem("skipBnkStmt", 1);
                    updateMilestone(uuid,"bank_stmt",flp_source);
                    pushPageChange();
                }
            } catch (e) {
                console.warn("error");
                console.warn(e);
                setIsInValidfFile(true);
                setErrorMessage("Please try again");
            }
        } else {
            setIsInValidfFile(true);
            setErrorMessage("Please choose an option");
        }
    };

    const onProceed = (e) => {
        if (activeClass == "fileupload" || activeClass == "camera") {
            setshowLoad('show');
            setOptionDiv("hide");
            savedocument(e);
        } else {
            setIsInValidfFile(true);
            setErrorMessage("Please choose an option");
        }
    };

    const backDocs = async (type) => {
        history.push("/checkListFLPS3?uuid" + uuid);
    };

    const onSelectOption = (e) => {
        setIsInValidfFile(false);
        SetBtnDisabled(false);
        if (e.target.name == "FinanceAgreementDocEmail") {
            setSelectedFile(undefined);
            setActiveClass("email");
        } else if (e.target.name == "FinanceAgreementDocSkip") {
            setSelectedFile(undefined);
            setActiveClass("skip");
        } else if (e.target.name == "closefile") {
            setActiveClass("disabled");
            SetBtnDisabled(true);
            setSelectedFile(undefined);
        }
    };
    const pushPageChange = () => {
        localStorage.setItem("BankStatementFlp", 1);
        const uploadPage = "/checkListFLPS3" + "?" + queryString;
        window.location.href = uploadPage;

    }

    let budLender = localStorage.getItem("bud_lender") ? localStorage.getItem("bud_lender") : 'Lender';
    let matchStatus = localStorage.getItem("match_check") ? localStorage.getItem("match_check") : 0;

    return (
        <>
            <div className="bankIncomeS3 followups2newFlow">
                {/* Loader*/}
                <div className={`slide  page-loader loader1 ${showLoad}`}>
                    <div className="wrap-app mt-3 text-center">
                        <div className="logo-two loader-logo text-center pt-3 mb-5">
                            {" "}
                            <img src="assets/BankStatemenFLPS3/img/logo.png" alt="" />
                        </div>
                        <div className="mb-5 mt-5 pb-5">
                            <img
                                height={100}
                                className="mb-2 mt-5"
                                src="assets/BankStatemenFLPS3/img/loader-review2.gif"
                                alt=""
                            />
                            <p className="mb-5">Please Wait</p>
                        </div>
                    </div>
                </div>

                <div
                    className="slide connect-account vehicleregist  pb-3"
                >
                    <div className={`wrap-app mt-3 text-center`}>

                        <div className={`${optionBtnDiv}`}>
                            <div className="logo-two loader-logo text-center pt-3 mb-5">
                                {" "}
                                <img src="assets/BankStatemenFLPS3/img/logo.png" alt="" />
                            </div>
                            <div className={`item qs-item1 px-lg-3 px-md-5 px-sm-5 px-3 mt-4 `}>
                                <h2 className="fw-bold title mt-4 mb-lg-4 mb-sm-2 mb-md-3 mb-2">
                                {matchStatus == 0 ? (
                                    `We are unable to retrieve your transaction history for the specified period related to your ${budLender} loan.`
                                ) : (
                                    "Please proceed by providing your Income and Expense details manually or by uploading your bank statement"
                                )}
                                    
                                </h2>
                                <h5 className="sub-text mb-lg-5  mb-md-4 mb-sm-4 mb-4  mx-lg-5 mx-md-5 mx-sm-2 mx-2">
                                {matchStatus == 0 ?(
                                    "Please proceed by providing your Income and Expense details manually or by uploading your bank statement"
                                ): null}
                                </h5>
                                <div className="form-section">
                                    <div className="mb-3">
                                        <div className="slot-filed mb-3 btn-c2" id="next1" onClick={incomeExpManually}>
                                            <div className="d-flex align-items-center  justify-content-center ">
                                                <div className="ps-3">
                                                    Input Income and Expenditure Manually
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slot-filed mb-3">
                                            <div className="d-flex align-items-center justify-content-center">
                                                {/* <input type="file" /> */}
                                                <input
                                                    type="file"
                                                    className="take_picture"
                                                    id="FinanceAgreementDoc"
                                                    name="FinanceAgreementDoc"
                                                    onChange={changeFilesHandler}
                                                    onClick={(e) => (e.target.value = null)}
                                                />
                                                <div className="icon">
                                                    <i className="bi bi-upload" />
                                                </div>
                                                <div className="ps-3">Upload files from your device</div>
                                            </div>
                                        </div>
                                        <div>
                                            {isInValidfFile && (
                                                <span className="error_msg">{errorMessage}</span>
                                            )}
                                            <div className="col-xl-12 col-lg-12 text-center p-1 btn_proceed_outer">
                                                {selectedFile && (
                                                    <>
                                                        {selectedFile.name}{" "}
                                                        <button
                                                            className="mb-1 btn btn-secondary btn-sm"
                                                            name="closefile"
                                                            onClick={onSelectOption}
                                                        >
                                                            Remove
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                            {selectedFile && (
                                                <div className="slot-filed mb-3">
                                                    <div className="d-flex align-items-center justify-content-center">
                                                        <input
                                                            type="button"
                                                            name="btn_qest_45"
                                                            disabled={btnDisabled}
                                                            className={`slot-filed mb-3 ${activeClass == "disabled" && "disabledclass"
                                                                }`}
                                                            onClick={onProceed}
                                                            value="Proceed"
                                                        />
                                                        <div className="ps-3">Proceed</div>

                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="mb-4 col-lg-12 mb-pd text-center mt-4 ">
                                            <div>
                                                <button className="btn btn-link"
                                                    onClick={backDocs}
                                                >

                                                    {"<< "} Previous
                                                </button>{" "}
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <IncomeExpenditure
                            className={incomeInputDiv}
                            setOptionDiv={setOptionDiv}
                            setIncomeInputDiv={setIncomeInputDiv}
                            allFollowupData={allFollowupData}
                        />
                    </div>
                </div>
                {/* Slide */}
                <div className="text-center py-4 mb-lg-5 mb-md-5 mb-sm-4 mb-4 secure">
                    <img height={35} src="assets/BankStatemenFLPS3/img/secure.png" alt="" />
                </div>
            </div>

        </>

    );

};
export default BankIncomeStatementS3;