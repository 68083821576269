import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButtonOutLabel";
import TextField from "../../../UI/TextField";

const QuestionnairDepandants = ({
  clsName,
  onselectAnswer,
  reff,
}) => {


  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp  text-center mt-0 ${clsName}`} ref={reff}>
      <div class="mb-3 col-lg-8 offset-lg-2 col-12 ">
        <h3 class="mb-3">
              How many dependents did you have at that time?{" "}
            </h3>

           
                    <RadioButton
                      key="0"
                      id="zero"
                      value="0"
                      name="dependent"
                      className="radio-button"
                      labelName="0"
                      labelClassName="option_but next-3 goDocument"
                      onClick={labelclicked}
                      dataAnsId={7}
                      dataQuestId={3}
                    />
 
                    <RadioButton
                      key="1"
                      id="one"
                      value="1"
                      name="dependent"
                      className="radio-button"
                      labelName="1"
                      labelClassName="option_but next-3 goDocument"
                      onClick={labelclicked}
                      dataAnsId={8}
                      dataQuestId={3}
                    />
    

                    <RadioButton
                      key="2"
                      id="two"
                      value="2"
                      name="dependent"
                      className="radio-button"
                      labelName="2"
                      labelClassName="option_but next-3 goDocument"
                      onClick={labelclicked}
                      dataAnsId={9}
                      dataQuestId={3}
                    />



                    <RadioButton
                      key="3"
                      id="3 or more"
                      value="3"
                      name="dependent"
                      className="radio-button"
                      labelName="3 or more"
                      labelClassName="option_but next-2"
                      onClick={labelclicked}
                      dataAnsId={10}
                      dataQuestId={3}
                    />
        

                  <div class="w-100 text-center pb-3 next88">
            <p className="text-center">
              <a className="skip-btn skip1" id="Not sure" data-ans-id={195} name="dependent"
                data-quest-id={3} onClick={labelclicked}>Not sure</a>
            </p>
          </div>
               
               </div>
               </div>
           
    </>
  );
};

export default QuestionnairDepandants;
