import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import Modal from "react-bootstrap/Modal";
import InputButton from "../../../UI/InputButton";
const Struggle = ({
  className,
  slideChange,
  textHeadingLine1,
  textHeadingLine2,
  validation,
  backClick,
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);
  const nextSlide = async (e) => {
    if (e.target.value === "100") {
      setCheckedOne("checked");
      setshowClaimModal(true);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "99") {
      setCheckedTwo("checked");
      slideChange(e);
    } else {
      setCheckedTwo("unchecked");
    }
  };
  return (
    <>
      <div id="slide-7" className={`${className}`}>
        <ProgressBar value="50" />

        <h3> {textHeadingLine1} </h3>
        <div className="radio_buttons">
          <RadioButton
            value="99"
            name="struggle"
            className="radio-button"
            labelName="Yes, I Have Struggled to Repay"
            labelClassName={`option_but radio ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <RadioButton
            value="100"
            name="struggle"
            className="radio-button"
            labelName="No Issues Repaying"
            labelClassName={`option_but radio ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <p className="text-center">
          <span
            className="back-btn btn-link"
            name="back07"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div>
       {/* **** cannot accept your claim popup start **** */}
       <Modal size="md" show={claimModal} onHide={handleCloseclaimModal} centered backdrop="static">
        <Modal.Body>
          <h3 className="text-center fonts15">Sorry, we cannot accept your claim</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="back04"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Struggle;
