import React, { useState } from "react";
import ProgressBar from './ProgressBar';
import RadioButton from '../../../UI/RadioButton';

const Slide2 = ({
  className,
  slideChange,
  validation,
  trigger,
  clearErrors,
  getValues,
  setError,
  backClick
}) => {

  const [checked1, setChecked1] = useState('');
  const [checked2, setChecked2] = useState('');
  const [checked3, setChecked3] = useState('');
  const nextSlide = async (e) => {
    if (e.target.value == "10 September 2022") {
      setChecked1('activeclass');
    } else {
      setChecked1('');
    }

    if (e.target.value == '15 September 2022') {
      setChecked2('activeclass');
    } else {
      setChecked2('');
    }

    if (e.target.value == "17 September 2022") {
      setChecked3('activeclass');
    } else {
      setChecked3('');
    }
    slideChange(e);
  };
  return (
    <>
      <div id="slide2" className={`col-12 top_minus ${className}`}>
        <ProgressBar value={10} />
        <h3>Select Your Keeper Date</h3>
        <div className="mb-3 offset-lg-2 col-lg-8 col-12 text-center">
          <RadioButton
            htmlFor="previously"
            value="10 September 2022"
            tabIndex="1"
            name="keeperDate"
            className="radio-button"
            labelName="10 September 2022"
            labelClassName={`option_but next02 ${checked1}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <RadioButton
            htmlFor="previously"
            value="15 September 2022"
            tabIndex="2"
            name="keeperDate"
            className="radio-button"
            labelName="15 September 2022"
            labelClassName={`option_but next02 ${checked2}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <RadioButton
            htmlFor="previously"
            value="17 September 2022"
            tabIndex="1"
            name="keeperDate"
            className="radio-button"
            labelName="17 September 2022"
            labelClassName={`option_but next02 ${checked3}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <p className="text-center"><a href="#" className="back01" name="back01" onClick={backClick}> {'<< Previous'} </a></p>
        </div>
      </div>
    </>
  );
};

export default Slide2;