import React, { useEffect, useState } from "react";
import InputButton from "../../../UI/InputButton";
const OB_AffordabilityCheckV2 = ({
  className,
  setLoader,
  loader,
  reloader,
  btndisable,
  setReloader,
  pushProceedLink,
  redirectTankyou,
  affordabilityStat,
  setMatchCheckLoader
}
) => {
  useEffect(() => {
    if (affordabilityStat === "show") {
      setMatchCheckLoader("hide");
    }
  }, [affordabilityStat]);
  return (
    <>
      <div className={`bg-main-pr ${affordabilityStat}`}>
        <div className={`text-center loader-wrap2 ${loader == 1 ? 'showload' : 'hide'}`}>
          <div className="loader">
            <img src="assets/img/review.gif" alt="" />
          </div>
        </div>

        <div className={`${reloader == 1 ? 'show' : 'hide'}`}>
          <div className={`main text-center main-div  ${loader == 0 ? 'showload' : 'hide'}`}>
            <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
              <img height="65" src="assets/img/logo_1.png" alt="" />
            </div>
            <div className="mt-val">
            <div className="container">
              <div className="item-cent-flx ">
                <div className="  pt-md-3 pt-sm-3  pt-3 text-center text-center">
                  <h1 className="  mt-md-2 mt-sm-2 mt-0 text-danger ">Complete Your Affordability Check
                    to Finalise Your Complaint </h1>
                  <p style={{maxWidth: '800px','margin': '0 auto'}} className="my-2"> We do this by connecting to your Bank Account using the FCA regulated Open Banking platform via Bud Financial. </p>

                  <div className="row mt-lg-5 mt-md-2 mt-sm-3 mt-0">
                    <div className="col-lg-6 px-4 mb-2">

                      <div>
                        <button className="btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 second anim_green disablebtn"
                          onClick={pushProceedLink}
                          disabled={btndisable}
                          data-btn-label="connect_bank_account"
                          data-page-label="OB_LP"
                        >
                          Connect Your Bank Account <span> {'›'}</span>
                        </button>

                        <p className="mt-3 text2b mb-0"><img src="assets/img/lock.png" style={{ width: '2%' }} /> &nbsp; Safe & Secure via FCA Regulated Open Banking</p>
                        <p className="mt-0 text3b text-danger"><img src="assets/img/clock.png" style={{ width: '3%' }} /> Estimated Processing Time: 2 minutes </p>
                      </div>
                    </div>
                    <div className="col-lg-6 px-4">
                      <div>
                        <button className="btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 disablebtn"
                          onClick={redirectTankyou}
                          disabled={btndisable}
                          data-btn-label="pre_paid_envelopes"
                          data-page-label="OB_LP"
                        >
                          Proceed Manually   <span> {'›'}</span> </button>
                        <p className="mt-3 text2b mb-0">You can proceed and upload your Documents manually</p>
                        <p className="mt-0 text3b text-danger"><img src="assets/img/clock.png" style={{ width: '3%' }} /> Estimated Processing time: 6-8 weeks </p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-lg-5 mb-md-5 mb-sm-3 mb-3 mt-5 mt-lg-3 mt-md-5 mt-sm-5 ">
                    <img height="50" src="assets/img/safe-secure.png" alt="" />
                  </div>
                </div>
              </div>

            </div>
            </div>  
          </div>
        </div>


        <section id="main-dv" className={`btn-info_section ${reloader == 0 ? 'show' : 'hide'}`}>
          <div className="inside_refresh">
            <div className="container btn-info_part">
              <div className="row justify-content-center ">
                <div className="col-lg-10 col-11">
                  <div className={`space text-center`}>
                    <div className="button _1 w-100 anim_blue refresh_btn_outer">
                      <InputButton
                        name="proceedd"
                        className="btn_proceed refresh_btn"
                        id="bb"
                        value="Refresh"
                        btnType="button"
                        onClick={pushProceedLink}
                        dataBtnLabel="refresh_btn"
                        dataPageLabel="OB_LP"
                      />
                      <div className="back"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}
export default OB_AffordabilityCheckV2;