import React from "react";
import Email from "../../../UI/Email";
const Emailconfirmation = ({
  clsName,
  reff,
  onSelectAnswer,
  setIsRegisteredEmail,
  setIsNewEmailVisible,
  isRegisteredEmail,
  isNewEmailVisible,
  validation,
  validationMsg,
  trigger,
  backClick,
  getValues,
  IandEPrev
}) => {

  // let toggle;
  // if(IandEPrev == "213"){
  //   toggle = "wereAlreadyPaying";
  // }else if(IandEPrev == "214"){
  //   toggle = "whetherIandE"
  // }

  const optionSelected = async(e) => {
    e.preventDefault();
    if(e.target.value == "163")
    {
      setIsRegisteredEmail('hide');
      setIsNewEmailVisible('show');
      onSelectAnswer(e);
    }else{
      onSelectAnswer(e);
    }

  }
  const handleClick = async(e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();
    let question48Result = await trigger("question_48");
    if (!question48Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag === 0) {
      onSelectAnswer(e);
    }
  }
    return (
        <>
        <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3" ref={reff}>
        <div className={`d-flex gap-2 ${isRegisteredEmail == 'hide' ? 'hide' : ''}`}>

      <div className="d-flex w-50 py-2" >
          <button 
          type="button"
          className=" btn-comm2 py-3 w-100  nextBtn"
          onClick={optionSelected}
          value="162"
          name="question_61"
          id="nextscroll"
          data-quest-id={61}
          data-ans-id={162}
          >
            Yes</button>
        </div>
          <div className="d-flex w-50 py-2" >
            <button
            type="button"
             className=" btn-comm2 py-lg-3 py-md-3 py-sm-3 py-3 w-100 newemailbut"   
          onClick={optionSelected}
          value="163"
          name="question_61"
          id="nextscroll"
          data-quest-id={61}
          data-ans-id={163}
             >
         No </button>
          </div>          
        </div>

        <div className={ `row  ${isNewEmailVisible== 'show' ? '' : 'hide'}`}>
      <div className="col-lg-8 mb-2 mb-lg-0" >
          <Email
            name="question_48"
            className="form-control p-3 h-55"
            id="question_48"
            placeholder="Email Address"
            // onBlur={emailValidCheck}
            validation={validation({
              required: "Please Enter Valid Email Address",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid Email Address",
              },
            })}
          ></Email>
          {validationMsg.question_48 && (
            <span className="error_msg " id="email_err">
              {validationMsg.question_48.message}
            </span>
          )}
        </div>
        <div className="col-lg-4">

            <button
                            type="button"
                            className="qb15 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                            id="nextscroll"
                            name="btn_qest_48"
                            onClick={handleClick}
                            data-quest-id={48}
                        >
                          Next &#8594; </button>
        </div>
      </div>
      <p className="text-center bck-marwid">
                    <button
                      name="wereAlreadyPaying"
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>
    </div>
        </>
    )
}
export default Emailconfirmation;