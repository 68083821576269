import react, { useState } from "react";
import { MonthlyIncome } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
const CardMonthlyIncome = ({
  clsName,
  saveInputAns,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
  togle
}) => {

  const [styleSelect,setStyleSelect]=useState("shadow-style");
  const optionSelected = async (e) => {
    let errorFlag = 0;
    let result = await trigger("cardMonthlyIncome");
    if (!result) {
      errorFlag = 1;
      setStyleSelect("shadow-style");
      return false;
    } else {
      saveInputAns(e);
    }
  };

  const HandleIncome =async (e) => {
     let results = await trigger("cardMonthlyIncome");
     clearErrors("cardMonthlyIncome");
     setStyleSelect("");
  };
  return (
    <>
      <div className={`question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 `}>
        <div className="row">
          <div className="col-lg-8 mb-2">
            <SelectBox
              className={`form-select ${styleSelect}`}
              OptionValue={MonthlyIncome}
              name="cardMonthlyIncome"
              id="cardMonthlyIncome"
              onChange={HandleIncome}
              clearErrors={clearErrors}
              myRef={validation({
                required: "Please select your monthly income",
              })}
              validationMsg={
                validationMsg.cardMonthlyIncome &&
                validationMsg.cardMonthlyIncome.message
              }
            />
          </div>
          <div className="col-lg-4 mb-2 ">
            <button
              type="button"
              className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
              id="nextscroll"
              name="cardMonthlyIncome"
              onClick={optionSelected}
              data-quest-id={78}
            >
              Next &#8594;
            </button>
          </div>
        </div>
        <p className="text-center bck-marwid">
          <button
            name="didReceiveIncome"
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}
          >
            Go Back
          </button>
        </p>
      </div>
    </>
  );
};
export default CardMonthlyIncome;
