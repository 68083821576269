import {Api} from '../api/Api';
import {useErrorWebhook} from "./useErrorWebhook";

export const useTotalCarCheck = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();

    const totalCarCheckApi = async (carreg,uuid,page_name) => {
        const getValidation = await Api.get(
            "/api/v1/carno-lender?carRegNo=" + carreg+"&uuid=" +uuid+"&page_name="+page_name,
            {}
          ).catch(function (error) {
            const erroMessage = {
              serviceName: 'TOTAL CAR CHECK',
              errorReason: error.message,
              errorCode: error.code,
              uuid: uuid
            }
            ErrorWebhookTrigger(erroMessage);
          });
        return getValidation;
    }
    return { totalCarCheckApi }
}