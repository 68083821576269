import React from "react";

const Loader2 = ({ showLoad,fname }) => { 
  return (
    <>


       {/* <div className="text-center loader-wrap loader1"> */}
       <div className={`text-center loader-wrap loader1 ${showLoad}`}>
            <div className="loader-logo">
              <img
                src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
                alt=""
              />
            </div>

            <div className="loader2">
              <img src="assets/img/review.gif" alt="" />
              <div className="loader-text">
                <p className=" text-analys">Analysing your informations</p>
              </div>
            </div>
          </div>
    </>
  );
};

export default Loader2;