import React, { useContext, useState } from "react";

import RadioButton from "../../../UI/RadioButton";
import AnalyzeModal from "../../Layouts/Split_1/AnalayzeModal";
import Anchor from "../../../UI/Anchor";
import { useHistory } from "react-router-dom";
import ProgressBar from "../../Layouts/Split_1/ProgressBar";

const BasicQuestionnaireV3_2 = ({
    className,
    slideChange,
    textHeading,
    validation,
    getValues,
    splitForm,
    backClick,
    questionId,
}) => { 
    const [modalClass, SetModalClass] = useState("modal fade");
    const [modalClass1, SetModalClass1] = useState("show");
    const [modalClass2, SetModalClass2] = useState("hide");
    const [checkedQuestionFive,setCheckedQuestionFive] = useState("unchecked");
    const [checkedQuestionSix,setCheckedQuestionSix] = useState("unchecked");
    const history = useHistory();
    const showAnalyzeModal = (e) => {
        if(e.target.value == 12){
            setCheckedQuestionFive("checked");
            setCheckedQuestionSix("unchecked");
        }
        if(e.target.value == 13){
            const query = new URLSearchParams(window.location.search);
            const query_uuid = query.get("uuid");
            setCheckedQuestionSix("checked");
            setCheckedQuestionFive("unchecked");
            history.push('/unqualified?uuid='+query_uuid+'&questionId='+questionId+'&answerId='+3);
        }
        document.body.classList.add("modal-open");
        document.getElementById("analayzeModal").classList.add('show');
        document.getElementById("analayzeModal").classList.remove('hide');
        SetModalClass1("show");

        document.getElementById("slide-4").classList.remove('show');
        document.getElementById("slide-4").classList.add('hide');
        SetModalClass("modal show");
        setTimeout(() => { UpdateSlide1() }, 3000);
        setTimeout(() => { UpdateSlide2(e) }, 6000);
        const UpdateSlide1 = () => {
            SetModalClass1("hide");
            SetModalClass2("show");
        }
        const UpdateSlide2 = (e) => {
            SetModalClass2("hide");
            document.getElementById("analayzeModal").classList.remove('show');
            document.getElementById("analayzeModal").classList.add('hide');
            document.body.classList.remove("modal-open");
            slideChange(e);
        }
    }
    return (
        <>
        <div id="slide-4" className={` slidetop_adjust ${className}`}>
               <ProgressBar value="50"/>
                  <h4 className="text-center">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  </h4>
                  <div className="radio_buttons">
                    
                    <RadioButton
                        value="12"
                        name="question_4"
                        className="radio"
                        labelName="No"
                        labelClassName={`radio ${checkedQuestionFive}`}
                        onClick={(e)=>{showAnalyzeModal(e)}}
                        validation={validation()}
                    />
                    <RadioButton
                        value="13"
                        name="question_4"
                        className="radio"
                        labelName="Yes"
                        labelClassName={`radio ${checkedQuestionSix}`}
                        onClick={(e)=>{showAnalyzeModal(e)}}
                        validation={validation()}
                    />
                  </div>
                  {/* <p className="text-center">
                    <span className="back-btn" id="back-3">
                      <u>
                        {`<<`} Previous
                      </u>
                    </span>
                  </p> */}
                  <p className="text-center">
                    <span
                        className="back-btn"
                        name="back03"
                        children="<< Previous"
                        onClick={backClick}
                    ></span>
                  </p>
                </div>
                <AnalyzeModal
                modalClass={modalClass}
                modalClass1={modalClass1}
                modalClass2={modalClass2}
                validation={validation}
                slideChange={slideChange}
                textHeading="Are you the Only Signer on this Account, or is this a Joint Account?"
                getValues={getValues}
                splitForm={splitForm}
            />
        </>


    );
};
export default BasicQuestionnaireV3_2;
