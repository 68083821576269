import React, { useState } from "react";
import Email from "../../../../UI/Email";
import { useEmailValidation } from "../../../../../Hooks/useEmailValidation";
import Button from "../../../../UI/Button";

const QuestionnaireEmail_MoneyBarn = ({
  clsName,
  reff,
  display,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  saveInputAns,
  className,
  addPending
}) => {
  const { emailValidation } = useEmailValidation();

  const selectClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();
    let question48Result = await trigger("question_48");
    if (!question48Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag === 0) {
      saveInputAns(e);
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["question_48"];

    if (email !== "") {
      const getEmailValidation = await emailValidation(email);
      if (getEmailValidation.data.status_code === 1) {
        return 1;
      } else {
        setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
      }
    }
  };

  return (
    <>
      <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">
              Please provide the email address you registered with MoneyBarn
            </h1>

            <div className="row mt-4">
              <div className="col-lg-12">
                <div className="mb-3">
                  <Email
                    name="question_48"
                    className="form-control formslp"
                    id="question_48"
                    placeholder="Enter Email Address"
                    onBlur={emailValidCheck}
                    validation={validation({
                      required: "Please Enter Valid Email Address",
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Invalid Email Address",
                      },
                    })}
                  ></Email>
                  {validationMsg.question_48 && (
                    <span className="error_msg " id="email_err">
                      {validationMsg.question_48.message}
                    </span>
                  )}
                </div>

                <div>
                  <button
                    type="button"
                    className="com-btn btn-next w-100 mb-3"
                    onClick={selectClick}
                    data-quest-id={48}
                    data-ans-id={null}
                    data-ans-label={null}
                    name="btn_qest_48"
                  >
                    Next
                  </button>
                </div>
                <div class="text-center back">
                    <Button 
                    className="btn btn-link mt-2 h2" 
                    id="back1" 
                    type="button"
                    buttonText="<< Previous"
                    onClick={()=>addPending("reg_email")}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default QuestionnaireEmail_MoneyBarn;
