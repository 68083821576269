import React, { useState } from "react";
import RadioButton from "../../../../UI/RadioButtonOutLabel";
import Button from "../../../../UI/Button";

const QuestionnaireFlpKdate = ({
  questArr,
  reff,
  display,
  onselectAnswer,
  vehicle,
  setValidKeeperDate,
  keeperDates,
  removeFromPending,
  analyzingFn,
  addPending,
  className,
  selectBank
}) => {
  const nextSlide = async (e) => {
    let date_index = e.target.id;
    let dataNsId = e.target.getAttribute("data-ans-id")
      ? e.target.getAttribute("data-ans-id")
      : "";
    // if (date_index == '0') {

    // 	setValidKeeperDate(1);
    // 	removeFromPending('keeper_date');
    // 	removeFromPending('loan_date');
    // }else{
    // 	removeFromPending('keeper_date');
    // }
    analyzingFn(date_index);
    onselectAnswer(e);
  };

  let keeperArray = [];
  let keeperData = [];
  if (typeof vehicle.keeper_dates !== "undefined") {
    keeperData = JSON.parse(vehicle.keeper_dates);
    keeperArray = keeperData.keeper_dates;
  } else {
    keeperArray = keeperDates.keeper_dates;
  }
  return (
    <>

      <div className={className}>
        <div className="item qs-item3 px-3 mt-4">
          <h2 className="fw-bold title mt-4 mb-4">
            Select the date the car was registered to you
          </h2>
          <div className="form-section">
            <div className="mb-3">
              <div className="mb-3">
                <div className=" col-lg-12 mb-pd text-center ">
                  <div className="option-type mb-3">
                    {keeperArray &&
                      keeperArray.map((text, index, { length }) => (
                        <RadioButton
                          key={text.toString()}
                          id={index}
                          value={text}
                          name="question_11"
                          className="radio-button"
                          labelName={text}
                          labelClassName="option_but"
                          onClick={nextSlide}
                          dataAnsId={text}
                          dataQuestId={11}
                        />
                      ))}
                    <RadioButton
                      key="Other"
                      id="Other"
                      value="Other"
                      name="question_11"
                      className="radio-button"
                      labelName="Other"
                      labelClassName="option_but mb-3"
                      onClick={nextSlide}
                      dataAnsId="80other"
                      dataQuestId="11"
                    />
                  </div>
                </div>
              </div>
              <p className="text-center mt-4">
                <a href="#" className="back6" onClick={() => addPending("keeper_date")}
                >
                  &lt;&lt; Previous
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireFlpKdate;
