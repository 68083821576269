import React, { useState, useEffect, useRef, useReducer } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import { questinnare1, questinnare2Very } from "../../../../Constants/Questions";
import IncomeExpenditure from "./IncomeExpenditure";
import QuestionnaireFlpQ5 from "../Followup/QuestionnaireFlpQ5";
import QuestionnaireFlpFANUpload from "../Followup/QuestionnaireFlpFANUpload";
import { useHistory } from "react-router-dom";
import QuestionnaireFlpAgreementNo from "../Followup/QuestionnaireFlpAgreementNo";
import AccountCloseYear from "./AccountCloseYear";
import HighestBalance from "./HighestBalance";
import HowManyTimes from "./HowManyTimes";
import HowOften from "./HowOften";
import MinimumPayment from "./MinimumPayment";
import LoanModal from "./LoanModal";
import MonthlyModal from "./MonthlyModal";
import QuestionnaireEmail from "./QuestionnaireEmail";
import EmailConfirmation from "./EmailConfirmation";

const initialState = {
  showSlide1: "hide",
  showSlide1a: "hide",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide",
  showSlide6: "hide",
  showSlide7: "hide",
  showSlide8: "hide",
  showSlide9: "hide",
  showSlide10: "hide",
  loanModal: "hide",
  monthlyModal: "hide",
};
const QuestionnaireReducer = (state, action) => {
  switch (action.type) {
    case "showSlideChange": {
      if (action.payload == "slide1") {
        return {
          ...state,
          showSlide1: "show",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
        };
      } else if (action.payload == "slide1a") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "show",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
        };
      } else if (action.payload == "slide2") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "show",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
        };
      } else if (action.payload == "slide3") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "show",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
        };
      } else if (action.payload == "slide4") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "show",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
        };
      } else if (action.payload == "slide5") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "show",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
        };
      } else if (action.payload == "slide6") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "show",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
        };
      } else if (action.payload == "slide7") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "show",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
        };
      } else if (action.payload == "slide8") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "show",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
        };
      } else if (action.payload == "slide9") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "show",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
        };
      } else if (action.payload == "slide10") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "show",
          loanModal: "hide",
          monthlyModal: "hide",
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "expenseModal": {
      if (action.payload == "loanModal") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "show",
          monthlyModal: "hide",
        };
      } else if (action.payload == "monthlyModal") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "show",
        };
      } else {
        return {
          ...state,
        };
      }
    }
  }
};
const QuestionsModal = ({
  lender,
  email_id,
  questionsPop,
  allFollowupData,
  pendingQuestionnaire,
  splitName,
  currentUuid,
  documentType,
  docType,
  sett2aValidation,
  setPendingStatus,
  sendT2aApiReqDataAPI,
  accClsYear
}) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const splitForm = useRef(null);
  const [optionSelected] = useState([]);

  const { register, errors, trigger, setError, clearErrors, getValues } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  useEffect(() => {
    setShow(questionsPop);
  }, [questionsPop]);

  useEffect(() => {
    slideChange(pendingQuestionnaire);
  }, [pendingQuestionnaire]);

  const slideChange = (pendingArr) => {
    let pendingCount = pendingArr.length;
    if (pendingCount == 0) {
      setShow(false);
      sendT2aApiReqDataAPI();
      setPendingStatus({ status_btn: "pending_question", status_flag: "showTick" });
    } else {
      setPendingStatus({ status_btn: "pending_question", status_flag: "showButton" });
    }

    pendingArr.every((element) => {
      dispatch({ type: "showSlideChange", payload: element });
    });
  };
  const storeFollowupQuestionnire = async (question_data) => {
    const response = await saveFollowupUserQuestions(question_data, allFollowupData, "followup_question_store");
  };

  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    let flage = false;
    let question_data = {};
    if (e.target.name == "question_54") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("slide3");
    } else if (e.target.name == "question_55") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("slide4");
    } else if (e.target.name == "question_56") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("slide5");
    } else if (e.target.name == "btn_qest_36") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("slide9");
    } else if (e.target.name == "question_61") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
        if (selectedAns == 162) {
          removeFromPending("slide8");
        }
      }
      removeFromPending("slide7");
    } else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem("currentquestionData", JSON.stringify(question_data));
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const saveIncomeExpenditure = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    const values = getValues();
    setCompletedQuestArr([...completedQuestArr, selectedQuest, 32, 33, 35]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    var question_57 = splitForm.current["question_57"].value;
    var question_58 = splitForm.current["question_58"].value;
    var question_59 = values["question_59"];
    var question_60 = values["question_60"];

    if (question_57 == 151 && (question_59 == undefined || question_59 == "")) {
      dispatch({ type: "expenseModal", payload: "loanModal" });
      return false;
    } else if (question_58 == 153 && (question_60 == undefined || question_60 == "")) {
      dispatch({ type: "expenseModal", payload: "monthlyModal" });
      return false;
    } else {
      var slide_array = [
        ["31", answer31],
        ["32", answer32],
        ["33", answer33],
        ["57", question_57],
        ["58", question_58],
      ];

      if (question_59 != "") {
        slide_array.push(["59", question_59]);
      }
      if (question_60 != "") {
        slide_array.push(["60", question_60]);
      }

      var question_data = {
        slide_4: slide_array,
      };
      removeFromPending("slide6");
      storeFollowupQuestionnire(question_data);

      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    }
  };

  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    const valuesreg = getValues();
    let highestBalance = valuesreg["highest_balance"];
    let agreementNo = valuesreg["question_45"];
    let email = valuesreg["question_48"];
    let accCloseYr = valuesreg["question_52"];
    let flage = false;
    let question_data = {};
    if (selectedQuest == "52" && accCloseYr) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: accCloseYr,
      };
      removeFromPending("slide1a");
    }
    if (selectedQuest == "45" && agreementNo) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: agreementNo,
      };
      removeFromPending("slide1");
    }
    if (selectedQuest == "53" && highestBalance) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: highestBalance,
      };
      removeFromPending("slide2");
    }
    if (selectedQuest == "48" && email) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: email,
      };
      removeFromPending("slide8");
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem("currentquestionData", JSON.stringify(question_data));
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const removeFromPending = (slidename) => {
    const index = pendingQuestionnaire.indexOf(slidename);
    if (index > -1) {
      pendingQuestionnaire.splice(index, 1);
    }
    slideChange(pendingQuestionnaire);
  };

  const pushPageChange = () => {
    removeFromPending("slide10");
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName="FollowupContainer modal-lg modal-content-q modal-dialog-centered"
      backdrop="static"
      keyboard={false}
    >
      <div className="modal-body mob-pad5">
        <form
          ref={splitForm}
          name="split_form"
          id="user_form"
          method="POST"
          autoComplete="off"
          //onSubmit={handleSubmit}
        >
          <div className={`question1 animated fadeInUp ${state.showSlide1}`} id="breakdown01">
            <QuestionnaireFlpAgreementNo
              questArr={questinnare1}
              labelclicked={onselectAnswer}
              onselectAnswer={onselectAnswer}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
              saveInputAns={saveInputAns}
            />
          </div>

          <div className={`question2a animated fadeInUp ${state.showSlide1a}`} id="breakdown1a">
            <AccountCloseYear
              lender={lender}
              saveInputAns={saveInputAns}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
              accClsYear = {accClsYear}
            />
          </div>

          <div className={`question2 animated fadeInUp ${state.showSlide2}`} id="breakdown1">
            <HighestBalance
              lender={lender}
              saveInputAns={saveInputAns}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
            />
          </div>
          <div className={`question3 animated fadeInUp text-center ${state.showSlide3}`} id="breakdown2">
            <HowManyTimes
              onselectAnswer={onselectAnswer}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
            />
          </div>
          <div className={`question4 animated fadeInUp ${state.showSlide4}`} id="breakdown3">
            <HowOften
              onselectAnswer={onselectAnswer}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
            />
          </div>
          <div className={`question5 animated fadeInUp ${state.showSlide5}`} id="breakdown4">
            <MinimumPayment
              onselectAnswer={onselectAnswer}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
            />
          </div>
          <div className={`question6 animated fadeInUp ${state.showSlide6}`} id="breakdown5">
            <IncomeExpenditure
              questArr={questinnare1}
              labelclicked={onselectAnswer}
              onselectAnswer={onselectAnswer}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
              saveIncomeExpenditure={saveIncomeExpenditure}
              splitForm={splitForm}
            />
          </div>
          <div className={`modalLoan animated fadeInUp ${state.loanModal}`} id="breakdown7">
            <LoanModal
              saveIncomeExpenditure={saveIncomeExpenditure}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
            />
          </div>
          <div className={`modalMonthly animated fadeInUp ${state.monthlyModal}`} id="breakdown8">
            <MonthlyModal
              saveIncomeExpenditure={saveIncomeExpenditure}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
            />
          </div>
          <div className={`question7 animated fadeInUp ${state.showSlide7}`} id="breakdown9">
            <EmailConfirmation onselectAnswer={onselectAnswer} userEmail={email_id} />
          </div>
          <div className={`question8 animated fadeInUp ${state.showSlide8}`} id="breakdown9">
            <QuestionnaireEmail
              validation={register}
              getValues={getValues}
              trigger={trigger}
              validationMsg={errors}
              saveInputAns={saveInputAns}
              lender={lender}
            />
          </div>
          <div className={`question9 animated fadeInUp ${state.showSlide9}`} id="breakdow10">
            <QuestionnaireFlpQ5
              questArr={questinnare2Very}
              onselectAnswer={onselectAnswer}
              optionSelected={optionSelected}
            />
          </div>
          <div className={`question10 animated fadeInUp ${state.showSlide10} radio-box`} id="breakdown11">
            <QuestionnaireFlpFANUpload
              questArr={questinnare2Very}
              labelclicked={onselectAnswer}
              onselectAnswer={onselectAnswer}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
              split_name={splitName}
              docType={docType}
              documentType={documentType}
              allFollowupData={allFollowupData}
              sett2aValidation={sett2aValidation}
              pushPageChange={pushPageChange}
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
export default QuestionsModal;
