import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import InputButton from "../../../UI/InputButton";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
const LatePayment = ({
    onSelectAnswer,
    backClick,
    setlatepymnentbck
}) => {
    const [checkedOne, setCheckedOne] = useState("unchecked");
    const [checkedTwo, setCheckedTwo] = useState("unchecked");

    const optionSelected = async (e) => {
        GtmDataLayer({
            question: "payment letters",
          });
        if (e.target.value === "238") {
            setlatepymnentbck("238");
            setCheckedOne("checked");
            setCheckedTwo("unchecked");
            onSelectAnswer(e);
        } else {
            setCheckedOne("unchecked");
        }
        if (e.target.value === "239") {
            setlatepymnentbck("239");
            setCheckedOne("unchecked");
            setCheckedTwo("checked");
            onSelectAnswer(e);
        } else {
            setCheckedTwo("unchecked");
        }

    };

    return (
        <>
            <div className="question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3">
                <div className="row" >
                    <div className="col-6">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100  nextBtn ${checkedTwo}`}
                                onClick={optionSelected}
                                value="238"
                                name="latePayment"
                                id="nextscroll"
                                data-quest-id={82}
                                data-ans-id={238}
                                data-ans-label={null}
                            >Yes
                            </button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 noissue  ${checkedOne}`}
                                onClick={optionSelected}
                                value="239"
                                name="latePayment"
                                id="nextscroll"
                                data-quest-id={82}
                                data-ans-id={239}
                                data-ans-label={null}
                            >No
                            </button>
                        </div>
                    </div>
                </div>
                <div className=" text-center mt-2">
                    <button className="backbtn" type="button" name="didYouAware" onClick={backClick}>
                        {" "}
                        <i className="bi bi-arrow-left" /> Back
                    </button>
                </div>
            </div>

        </>
    )
}
export default LatePayment;