import React, { useState, useEffect, useContext } from "react";
import { Api } from "../../api/Api";
import { queryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useFollowupLoad } from "../../Hooks/useFollowupLoad";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import "../../assets/css/FollowupVAQ.scss";
import Loader from "../Includes/Layouts/FollowupVAQ/Loader";
import IdFollowup from "../Includes/Layouts/FollowupVAQ/IdFollowup";
import SignatureForm from "../Includes/Layouts/FollowupVAQ/SignatureForm";
import VanquisQuestionForm from "../Includes/Layouts/FollowupVAQ/VanquisQuestionForm";


const FollowupVAQ = () => {
  const [showLoad, setshowLoad] = useState("show");
  const [allFollowupData, setFollowupData] = useState(false);
  const [sigDiv, setSigDiv] = useState(false);
  const { loadFollowup } = useFollowupLoad();
  const [splitName, setSplitName] = useState(false);
  const [user_uuid, setUser_uuid] = useState(false);
  const [qstnDiv, setQstnDiv] = useState(false);
  const [pendingQuestionnaire, setPendingQuestionnaire] = useState([]);
  const [t2aApiReqData, setT2aApiReqData] = useState({});
  const [t2aStatus, setT2aStatus] = useState("");
  const history = useHistory();
  const [idUploadFlp, setIdUploadFlp] = useState(false);
  const [userData, setUserData] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [vanquisQuestfour, setVanquisQuestfour] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/api/v1/followup/get-pending-details-vaq?query_string=" + queryString,
        {}
      );
      if (response.data.status === "Success") {
        let followupDetails = response.data.response.followup_data;
        localStorage.setItem('allFollowupData', JSON.stringify(followupDetails));
        let uuid = response.data.response.uuid;
        localStorage.setItem("uuid",uuid);
        let completed = response.data.response.completed;
        let signStatus = response.data.response.signed_status;
        let slidePending = response.data.response.qstn_slides;
        // let pendingLength = slidePending?.length;
        let qstnStatus = response.data.response.question_status;
        let split_name = response.data.response.split_name;
        let fname = response.data.response.first_name;
        let lname = response.data.response.last_name;
        let postcode = response.data.response.postcode;
        let address_line1 = response.data.response.address_line1;
        let t2a_result = response.data.response.t2a_status;
        let dob_day = response.data.response.dob_day;
        let dob_month = response.data.response.dob_month;
        let dob_yr = response.data.response.dob_yr;
        let page = "complete";
        let loan_yr = response.data.response.loan_year;
        let email = response.data.response.email;
        let lender = response.data.response.lender;
        let address_line2 = response.data.response.address_line2;
        let town = response.data.response.town;
        let country = response.data.response.country;
        let title = response.data.response.title;
        let addressLine3 = response.data.response.address_line3;
        let addressLine4 = response.data.response.address_line4;
        let county = response.data.response.county;
        let user_name = response.data.response.user_name;
        let sign_img = response.data.response.signature;
        localStorage.setItem("t2a_result", t2a_result);

        visitorParameters.email = email || false;

        


        setT2aApiReqData({
          uuid: uuid,
          fName: fname,
          lName: lname,
          pCode: postcode,
          addressLine1: address_line1,
          day: dob_day,
          month: dob_month,
          year: dob_yr,
          addressLine2: address_line2,
          postTown: town,
          country: country,
          title: title,
          addressLine3: addressLine3,
          addressLine4: addressLine4,
          county: county


        });

        setUserData({
          loan_year: loan_yr,
          email: email,
          lender: lender,
          user_name:user_name,
          signImg:sign_img,
          signStatus:signStatus
        })

        setT2aStatus(t2a_result);
        setFollowupData(followupDetails);
        setSplitName(split_name);
        setPendingQuestionnaire(slidePending);
        setUser_uuid(uuid);

        
        if (completed == 1) {
          page = "complete";
        }else{
          page = "signature";
        }

        if(signStatus == 1){
          localStorage.setItem('signature_preview',1);
        }

        // if (signStatus == 0) {
        //   page = "signature";
        // } else if (qstnStatus == 0 && pendingLength >= 1) {
        //   page = "question";
        // } else if (t2a_result == 0) {
        //   page = "idUpload";
        // } else {
        //   console.log("no data");
        // }

        setTimeout(() => {
          setDivShow(page, split_name, uuid);
          setshowLoad("hide");
        }, 2000);

        const flpResponse = await loadFollowup(
          "followup_load",
          followupDetails,
          split_name,
          queryString,
          "FlpVaq"
        );
      } else {
        history.push("/Vanquisthankyou?uuid=" + response.data.response.uuid);
        return false;
      }
    })();
  }, []);

  const setDivShow = (page, split_name, uuid) => {
    if (page == "complete") {
      history.push("/Vanquisthankyou?uuid=" + uuid);
    }

    if (page == "signature") {
      showSigForm();
    } else if (page == "question") {
      setSigDiv(false);
      showQuestion();
    } else if (page == "idUpload") {
      setQstnDiv(false);
      setIdUploadFlp(true);
      window.scrollTo(0,0);
    } else {
      history.push("/Vanquisthankyou?uuid=" + uuid);
    }
  };

  const showSigForm =() => {
      setSigDiv(true);
  }

  const showQuestion = (split_name) => {
    setSigDiv(false);
    setQstnDiv(true);
    setVanquisQuestfour(true)
  };

  const setLoader = (e) => {
    setshowLoad("show");
  };

  const hideLoader = (e) => {
    setshowLoad("hide");
  };

  return (
    <>
      <div className="FollowupVAQ">
        <Loader showLoad={showLoad}></Loader>
        {/* <div className={showLoad === "show" ? 'hide' : 'show' }>{viewData}</div> */}
        
        {sigDiv ? (
          <SignatureForm
            allFollowupData={allFollowupData}
            showQuestion={showQuestion}
            split_name={splitName}
            splitData={userData}
            uuid={user_uuid}
          />
        ) : null}

         {vanquisQuestfour && qstnDiv ? (
          <VanquisQuestionForm
            allFollowupData={allFollowupData}
            pendingQuestionnaire={pendingQuestionnaire}
            setLoader={setLoader}
            hideLoader={hideLoader}
            currentUuid={user_uuid}
            t2aApiReqData={t2aApiReqData}
            setPendingQuestionnaire={setPendingQuestionnaire}
            t2aStatus={t2aStatus}
            setDivShow={setDivShow}
            splitName={splitName}
            splitData={userData}
          />
        ) : null}
      </div>
      {idUploadFlp ? (
        <IdFollowup
          allFollowupData={allFollowupData}
          currentUuid={user_uuid}
        />
      ) : null}
    </>
  );
};

export default FollowupVAQ;
