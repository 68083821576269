import {useState} from 'react';
import {Api} from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const useContact = () => {

    const { ErrorWebhookTrigger } = useErrorWebhook();
    const [contactLoading,setContactLoading] = useState(false);
    const contactSubmit = async (phone,email,message) => {
        setContactLoading(true);
        const response = await Api.post("api/v1/adv_contact", {
            phone,
            email,
            message
        }).catch(function (error) {
            const erroMessage = {
              serviceName : 'CONTACT',
              errorReason:error.message,
              errorCode:error.code
            }
            ErrorWebhookTrigger(erroMessage);
        });
        setContactLoading(false);
        return response;
    }
    return {contactSubmit,contactLoading}
}