import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const EmploymentStatus = ({
  className,
  slideChange,
  validation,
  backClick,
  Year,
  getValues,
  skip
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const [checkedFour, setcheckedFour] = useState("unchecked");
  const [checkedFive, setcheckedFive] = useState("unchecked");


  const nextSlide = async (e) => {
    if (e.target.value === "3") {
      setCheckedOne("checked");
      slideChange(e);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "4") {
      setCheckedTwo("checked");
      slideChange(e);
    } else {
      setCheckedTwo("unchecked");
    }

    if (e.target.value === "5") {
      setCheckedThree("checked");
      slideChange(e);
    } else {
      setCheckedThree("unchecked");
    }

    if (e.target.value === "6") {
      setcheckedFour("checked");
      slideChange(e);
    } else {
      setcheckedFour("unchecked");
    }

    if (e.target.value === "199") {
      setcheckedFive("checked");
      slideChange(e);
    } else {
      setcheckedFive("unchecked");
    }

  };
  return (
    <>
      <div id="slide7" className={`${className}`}>
        <ProgressBar value="35" />

        {/* <h3> {textHeadingLine1} </h3> */}
        <h3>
          {" "}
          {skip == true
            ? `At the time you secured finance, what was your employment status?`
            : `In ${Year}, at the time you secured finance, what was your employment status?`}{" "}
        </h3>
        <div className="btn-comm">
          <RadioButton
            value="3"
            name="employment"
            className="radio-button"
            labelName="Employed"
            labelClassName={`option_but  next7 ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="199"
            name="employment"
            className="radio-button"
            labelName="Unemployed"
            labelClassName={`option_but  next7  ${checkedFive}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <RadioButton
            value="4"
            name="employment"
            className="radio-button"
            labelName="Self-employed"
            labelClassName={`option_but  next7 ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="5"
            name="employment"
            className="radio-button"
            labelName="Student"
            labelClassName={`option_but  next7 ${checkedThree}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="6"
            name="employment"
            className="radio-button"
            labelName="Retired"
            labelClassName={`option_but  next7 ${checkedFour}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <p className="text-center">
          <a className="back01" name="back06" onClick={backClick}>
            {" "}
            {"<< Previous"}{" "}
          </a>
        </p>
      </div>
    </>
  );
};
export default EmploymentStatus;
