import React, { useState } from "react";
import SelectBox from "../../../UI/SelectBox";
import { PossessionMonth } from "../../../../Constants/Constants";
import InputButton from "../../../UI/InputButton";
import RadioButton from "../../../UI/RadioButton";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import { useExternalFollowup } from "../../../../Hooks/useExternalFollowup";
import * as EnvConstants from "../../../../Constants/EnvConstants";


const YearOfPossession = ({
  className,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  Year,
  allFollowupData,
  setYearOfPossession,
  setBankName,
  setLenderList,
  Uuid,
  loader,
  setObSplashStat,
  setLoader,
  userDetails,
  setDivShow,
  setLoader1,
  setBackBtn
}) => {
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const { getVisitorID, getAccessToken, getProviders,milestoneChase } = useExternalFollowup();
  const [previousBtn, setPreviousBtn] = useState('');
  const environment = EnvConstants.AppEnv;
  const isTest = (environment == "prod") ? false : true;
  // const [agreeModalShow, setAgreeModalShow] = useState("hide");
  const formData = getValues();
  let yearSelected = formData.YearOfPossession;
  const nextSlide = async (e) => {
    let errorFlag = 0;
    let yearresult = await trigger("YearOfPossession");
    let monthresult = await trigger("MonthOfPossession");

    if (!yearresult || !monthresult) {
      return false;
    }
    formData.question_65 = formData.YearOfPossession.concat(" ", formData.MonthOfPossession);
    let question_65 = formData.question_65
    setPreviousBtn(question_65);
    
    let flage = false;
    let question_data = {};
    if (e.target.name == "yearposession") {
      // if (selectedQuest) {
      flage = true;
      question_data = {
        question_id: 65,
        option_id: null,
        answer_text: question_65,
        input_answer: question_65,
      };
      // }
    } else {
      // if (selectedAns && selectedQuest) {
      //   flage = true;
      //   question_data = {
      //     question_id: selectedQuest,
      //     option_id: selectedAns,
      //     answer_text: anslabel,
      //     input_answer: "",
      //   };
      // }
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      localStorage.setItem('agreementDate', question_65);
      await milestoneChase({ uuid: userDetails.uuid },userDetails.user_id,"agreement_date_milestone",1 , userDetails.source)
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  }

  const storeFollowupQuestionnire = async (question_data) => {
    const response = await saveFollowupUserQuestions(
      question_data,
      allFollowupData,     
      'question_store_external'
    )

    setLoader(1);
    setBackBtn('show');
    setDivShow('bankSelect');

    setTimeout(() => {
      setLoader(0);
    }, 1000);
  };

  const backClick = async (e) => {
    setLoader1(1);

    setYearOfPossession('hide');
    setDivShow('splash');

    setTimeout(() => {
      setLoader1(0);
    }, 1000);
  };

  const PossessionYear = [{ label: "Select Year", value: "" }];
  {
    const startofYear = 2023;
    const endofYear = Year;
    for (var k = startofYear; k >= endofYear; k--) {
      var objYearOfPossession = {};
      objYearOfPossession["label"] = k;
      objYearOfPossession["value"] = k;
      PossessionYear.push(objYearOfPossession);

    }
  }

  return (
    <>

      <div className={`tms-wrap bg-main-pr ${className}`}>

  <div className={`text-center loader-wrap loader1 ${loader === 1 ? 'showload' : 'hide'}`}>
    {/* <div className="loader-logo">
      <img src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt="" />
      </div> */}
      <div className="loader">
        <img src="assets/img/review.gif" alt="" />
        <div className="loader-text">
          <p className=" text-analys">
            We require a few more additional details which are necessary for the prompt processing of your claim.</p>
        </div>
      </div>
    </div>
        <div className={`main text-center main-div ${loader == 0 ? 'showload' : 'hide'}`}>
          <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
            <img height="65" src="assets/FollowupFos/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt="" />
          </div>

          <div className="main-form-wraper">
            <div className="container">
              <div className="item-cent-flx ">

                <div className='slide01' >
                  <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                      <h1>Time you taken out the loan with {userDetails.lender}</h1>
                      <div className="row mt-4">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <SelectBox
                              className="form-select form-control"
                              OptionValue={PossessionMonth}
                              name="MonthOfPossession"
                              id="MonthOfPossession"
                              onChange={() => clearErrors("MonthOfPossession")}
                              clearErrors={clearErrors}
                              myRef={validation({ required: "Please Select Month" })}
                              validationMsg={
                                validationMsg.MonthOfPossession && validationMsg.MonthOfPossession.message
                              }
                            ></SelectBox>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <SelectBox
                              className="form-select form-control"
                              OptionValue={PossessionYear}
                              name="YearOfPossession"
                              id="YearOfPossession"
                              onChange={() => clearErrors("YearOfPossession")}
                              clearErrors={clearErrors}
                              myRef={validation({ required: "Please Select Year" })}
                              validationMsg={
                                validationMsg.YearOfPossession && validationMsg.YearOfPossession.message
                              }
                            ></SelectBox>
                          </div>
                        </div>

                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div>
                            <InputButton
                              name="yearposession"
                              className="com-btn w-100 mb-3"
                              id=""
                              value="Next"
                              btnType="button"
                              onClick={nextSlide}
                              style={{ float: "none" }}
                            />
                          </div>
                          <div className="mt-3 mb-5"> 
                            <button
                              className="btn-prev"
                              name="prevKeep"
                              children=" Previous"
                              onClick={backClick}>
                            <span className="me-2"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg></span>
                              Previous
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-lg-5 mb-md-5 mb-sm-3 mb-3 mt-lg-5 mt-lg-3 mt-md-5 mt-sm-5 ">
            <img height="50" src="assets/img/safe and secure.png" alt="" />
          </div>
        </div>
      </div>
    </>

  );
};
export default YearOfPossession;
