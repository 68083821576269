import React from "react";

const Header = () => {
  return (
    <>
      <header id="hding">
        <div className="container">
          <div
            className="col-lg-8 offset-lg-2 col-12 text-center p-0"
            id="slide01"
          >
            <img src="/assets/TMS_G_V3/img/logo.png" alt="" />
            <h1> Are you a Victim of Irresponsible Lending? </h1>
            <h2>
              We can help you claim back any refund you my be entitled to from
              Unaffordable Lending
            </h2>
          </div>
          <div className="spacemob" style={{ display: "none" }}></div>
        </div>
      </header>
    </>
  );
};

export default Header;
