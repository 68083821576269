import React,{useState}  from 'react'
import IdUploadS2 from './IdUploadS2';
import DocPreviewS2 from './DocPreviewS2';
import "../../assets/FollowupS2/css/custom.scss";
import "../../assets/FollowupS2/css/id_upload.scss";
import "../../assets/FollowupS2/css/openbank.scss";
import "../../assets/FollowupS2/css/signature.scss";
import "../../assets/FollowupS2/css/style.scss";
import EditPersonalDetailsS2 from './EditPersonalDetailsS2';


const DocsPreviewPageS2 = () => {
  const [previewPage,setPreviewPage] = useState('show');
  const [IdUpload,setIdUpload] = useState('hide');
  const [editDetails,setEditDetails] = useState('hide');

  return (
    <div className='followups2newFlow'>
     <DocPreviewS2
        className={previewPage}
        setIdUpload={setIdUpload}
        setEditDetails={setEditDetails}
        setPreviewPage={setPreviewPage}
        previewPage={previewPage}
      />

     <IdUploadS2
        className={IdUpload}
        setPreviewPage={setPreviewPage}
        setIdUpload={setIdUpload}
        setEditDetails={setEditDetails}
      />

     <EditPersonalDetailsS2
        className={editDetails}
        setPreviewPage={setPreviewPage}
        setIdUpload={setIdUpload}
        setEditDetails={setEditDetails}
      /> 

    </div>
  )
}

export default DocsPreviewPageS2
