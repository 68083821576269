import React from "react";
import AnchorAdv from "../../../UI/AnchorAdv";
import FbViewPixel from "../../../../Utility/FbViewPixel";
const AdvHeader = () => {
  return (
    <>
      <FbViewPixel />
      <header className="border-bottom pb-3">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 text-center d-none d-sm-none d-md-block d-lg-block">
              <p className="adv_txt m-0 text-black-50 ">Auisquam</p>
            </div>
            <div className="col-lg-7 col-md-8 col-sm-7 col-6 ">
              <AnchorAdv href="#" dataId="SITE_lOGO">
                <img src="/assets/img/logo.png" alt="" className="w-50" />
              </AnchorAdv>
              <p className="adv_txt d-block d-sm-block d-md-none d-lg-none m-0">
                Auisquam
              </p>
            </div>
            <div className="col-lg-5 col-md-4  col-sm-5 col-6 text-end">
              <AnchorAdv  href="#" className="btn btn-danger py-2 gt_btn" dataId="TOP_CTA_BTN">
                {" "}
                Lorem Ipsum
                <img
                  src="https://thecoverageguru.co.uk/advertorials/2021/10/Funerals/TCG_M12.5/dist/img/arrow.svg"
                  alt=""
                  className="arrow_icon"
                />
              </AnchorAdv>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default AdvHeader;
