import { useContext } from "react";
import QueryStringContext from "../Contexts/QueryString";

export const useQueryString = () => {
  let { queryString } = useContext(QueryStringContext);

  queryString = !!queryString
    ? queryString
    : window.location.search.substring(1);

  const urlSearchParams = new URLSearchParams(queryString);
  const uuid = urlSearchParams.get("uuid");

  return { urlSearchParams, queryString, uuid };
};
