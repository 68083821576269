import React, { useState } from 'react';
import RadioButtonQuest from '../../../../UI/RadioButtonQuest';
import AlertModal from '../../QuestionnaireV2/AlertModal';
import AnalyzeModal from '../AnalayzeModal';

const QuestionnaireFlpVehicleMake = ({
	questArr,
	questId,
	reff,
	display,
	onclickNext,
	onInputType,
	dispError,
	saveVehicleDetails,
	savetotalCarCheck,

	onselectAnswer,
	vehicle,
	validKeeperDate,
	userVehicle
}) => {
	const [showSlidepopup, setshowSlidepopup] = useState("hide");
	const [loader, showLoader] = useState(false);

	const backpopup = (e) => {
		e.preventDefault();
		//scrollToElement('slide2');
		setshowSlidepopup("hide");
	};

	const labelclicked = async (e) => {
		let date_index = e.target.id;
		if (date_index == "Yes") {
			showLoader(true);
			if (validKeeperDate) {
				// const totalCarCheckApiData = await totalCarCheckApi(
				//   vehicle.reg_no,
				//   uuid,
				//   page_name
				// );
				// savetotalCarCheck(totalCarCheckApiData.data.vehicleData);

				setTimeout(() => {
					showLoader(false);
				}, 1000);
				onselectAnswer(e);
			} else {
				setTimeout(() => {
					showLoader(false);
				}, 1000);
				onselectAnswer(e);
			}
		} else {
			setshowSlidepopup("show");
			//pushUnqualified();
		}
	};

	let vehicle_model = "";
	if (vehicle) {
		if (vehicle.vehicle_make) {
			vehicle_model = vehicle.vehicle_make;
		}
		if (vehicle.vehicle_make && vehicle.vehicle_model) {
			vehicle_model = vehicle_model + " " + vehicle.vehicle_model;
		}
		if (!vehicle.vehicle_make && vehicle.vehicle_model) {
			vehicle_model = vehicle.vehicle_model;
		}
	} else {
		vehicle_model = userVehicle;
	}


	return (
		<>
			<div style={{ 'display': display }}>
				<div className="radio-box question animated fadeInUp" ref={reff} >
					<h3 style={{ color: "#444" }}>Is this your vehicle <b>{vehicle_model}</b></h3>

					<RadioButtonQuest
						key="YES"
						className="radio"
						labelName="Yes"
						labelClassName="br radioa l_g"
						labelCountString="a"
						onClick={labelclicked}
						value="YES"
						name="question_12"
						id="Yes"
						dataQuestId={12}
						dataAnsId={23}
						dataAnsLabel="Yes"
					/>

					<RadioButtonQuest
						key="NO"
						className="radio"
						labelName="No"
						labelClassName="br radioa l_g"
						labelCountString="b"
						onClick={labelclicked}
						value="NO"
						name="question_12"
						id="No"
						dataQuestId={12}
						dataAnsId={24}
						dataAnsLabel="No"
					/>
				</div>
				<AlertModal showSlidepopup={showSlidepopup} backpopup={backpopup} />
				<AnalyzeModal modalClass={loader} />
			</div>
		</>
	)
}

export default QuestionnaireFlpVehicleMake;