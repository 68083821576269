import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import InputButton from "../../../UI/InputButton";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const Difficulties = ({
    onselectAnswer,
    backClick,
    clsName,
    reff
}) => {
    const [checkedOne, setCheckedOne] = useState("unchecked");
    const [checkedTwo, setCheckedTwo] = useState("unchecked");
    const [claimModal, setshowClaimModal] = useState(false);
    const handleCloseclaimModal = () => setshowClaimModal(false);
    const optionSelected = async (e) => {
        if (e.target.value === "241") {
            setCheckedOne("checked");
            setshowClaimModal(true);
        } else {
            setCheckedOne("unchecked");
        }

        if (e.target.value === "240") {
            setCheckedTwo("checked");
            onselectAnswer(e);
        } else {
            setCheckedTwo("unchecked");
        }
    }
    return (
        <>
            <div
                className={`radio-box animated fadeInUp space-top space-top2 ${clsName}`}
                id="slide-23"
                ref={reff}
            >
                <h3><b>
                    When you received the communications from the lender, did you feel that any difficulties you had repaying / financially were:
                </b></h3>
                <div className="question-content mt-0 scale-down-ver-center pt-0 px-3 py-lg-3 py-md-3 py-sm-2 pt-lg-0 pt-md-0 pt-1 py-2 pb-3">
                    <ul className="mb-3 listStyle">
                        <li>The lender will claim that you should have known that <b>they</b> were at fault when you received these communications, and too much time has now passed.</li>
                        <li>If you consider that any financial difficulties you had were <b>your fault / responsibility</b>, not theirs we can argue your case with them and still do your claim.</li>
                    </ul>

                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className=" mb-2">
                                <RadioButtonQuest
                                    className="radio-buttons"
                                    labelName="I thought that it was my fault / responsibility"
                                    labelClassName="option_but next-2"
                                    onClick={(e) => optionSelected(e)}
                                    name="difficulty"
                                    dataQuestId={83}
                                    dataAnsId={240}
                                    value="240"
                                    id="noid2"
                                />
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12 ">
                            <div className=" mb-2">
                                <RadioButtonQuest
                                    className="radio-buttons"
                                    labelName="I thought that it was the NewDay's fault / responsibility"
                                    labelClassName="option_but next-2"
                                    onClick={(e) => optionSelected(e)}
                                    name="difficulty"
                                    dataQuestId={83}
                                    dataAnsId={241}
                                    value="241"
                                    id="noid3"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                size="md"
                show={claimModal}
                onHide={handleCloseclaimModal}
                centered
                backdrop="static"
                className="claimdeinial"
            >
                <Modal.Body>
                    <h3 className="text-center">Sorry we are unable to assist with this claim, as NewDay are very likely to time-bar it, and not review it.</h3>
                    <div className="input-group mb-3 prevname-Btnpop justify-content-center">
                        <InputButton
                            className="btn next08  regNextBtnNew text-center"
                            name="modalBack"
                            value="Back"
                            btnType="button"
                            onClick={handleCloseclaimModal}
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default Difficulties;