import React, { useState } from 'react';
import ProgressBar from './ProgressBar';
import RadioButton from '../../../UI/RadioButton';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const Slide11 = ({
  className,
  slideChange,
  validation,
  trigger,
  clearErrors,
  getValues,
  setError,
  backClick
}) => {

  const [checked1, setChecked1] = useState('');
  const [checked2, setChecked2] = useState('');

  const showModal = async (e) => {
    handleShowFirst();
    setTimeout(() => {
      handleCloseFirst();
      handleShowSecond();
    }, 3000);
    setTimeout(() => {
      handleCloseSecond();

      if (e.target.value == 21) {
        setChecked1('activeclass');
      } else {
        setChecked1('');
      }

      if (e.target.value == 22) {
        setChecked2('activeclass');
      } else {
        setChecked2('');
      }
      slideChange(e);
    }, 6000);
  };

  const [showFirstModal, setShowFirstModal] = useState(false);
  const handleShowFirst = () => setShowFirstModal(true);
  const handleCloseFirst = () => setShowFirstModal(false);

  const [showSecondModal, setShowSecondModal] = useState(false);
  const handleShowSecond = () => setShowSecondModal(true);
  const handleCloseSecond = () => setShowSecondModal(false);


  return (
    <>
      <div id="slide11" className={`col-12 top_minus ${className}`}>
        <ProgressBar value={65} />
        <h3>Are you currently subject to Bankruptcy or Trust deed?</h3>

        <div className="mb-3 text-center">

          {/* <label className="option_but next06" data-bs-toggle="modal" data-bs-target="#analyzemodal" htmlFor="ysactive">
            <input tabIndex="1" type="radio" className="radio-button" id="ysactive" name="everpaid" value="" />
            <span>Yes</span>
          </label> */}

          <RadioButton
            htmlFor="previously"
            value="21"
            tabIndex="2"
            id="trustDeed-1"
            name="trustDeed"
            className="radio-button"
            labelName="Yes"
            labelClassName={`option_but next06 ${checked1}`}
            onClick={(e) => { showModal(e) }}
            validation={validation()}
          />

          <RadioButton
            htmlFor="previously"
            value="22"
            tabIndex="2"
            id="trustDeed-2"
            name="trustDeed"
            className="radio-button"
            labelName="No"
            labelClassName={`option_but next06 ${checked2}`}
            onClick={(e) => { showModal(e) }}
            validation={validation()}
          />
          <p className="text-center">
            <a href="#" className="back10" id="back10" name="back10" onClick={backClick}>
              {'<< Previous'}
            </a>
          </p>

        </div>
        <Modal
          show={showFirstModal}
          onHide={handleCloseFirst}
          backdrop="static"
          keyboard={false}
          className="formmodal"
          contentClassName="modalcontent"
        >
          <Modal.Body>
            <div id="slide7" className="col-12 slide-pad text-center">
              <div className="form-group text-center" id="analize1">
                <h2>Analysing Your Results</h2>
                <img src="/assets/MB_UBRF_2/img/review.gif" alt="" />
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={showSecondModal}
          onHide={handleCloseSecond}
          backdrop="static"
          keyboard={false}
          contentClassName="modalcontent"
        >
          <Modal.Body>
            <div id="slide7nxt" className="col-12 slide-pad text-center">
              <div className="form-group text-center" id="analize1">
                <h2>Good News! </h2>
                <p>We are able to assist you further. Please give us some back information. <br />
                  (This will only take 1 minute)</p>
                <img src="/assets/MB_UBRF_2/img/review.gif" alt="" />
              </div>

            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Slide11;