import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import QueryStringContext from "../../Contexts/QueryString";
import { getParameterByName } from "../../Utility/Helper";
import ReactPixel from "react-facebook-pixel";
import VisitorParamsContext from "../../Contexts/VisitorParams";

require("dotenv").config();
const FbPixel = () => {
  const history = useHistory();
  const { queryString } = useContext(QueryStringContext);
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  console.log(visitorParameters);
  let current_domain = visitorParameters.data.existingdomain;
  // let current_domain = visitorParameters.data.existingdomain
  //   .replace("http://", "")
  //   .replace("https://", "")
  //   .split(/[/?#]/)[0];
  //   console.log(current_domain);
  if (current_domain) {
    current_domain = current_domain.replace("http://", "").replace("https://", "").split(/[/?#]/)[0];
  }
  const query = new URLSearchParams(window.location.search);
  const splitName = query.get("split_name");

  if (queryString != null) {
    console.log("current" + current_domain);
    console.log("redirect" + visitorParameters.data.redirectDomain);
    if (queryString.indexOf("&" + field + "=") !== -1) {
      const pid = getParameterByName(field, queryString);
      if (
        current_domain === visitorParameters.data.redirectDomain ||
        // process.env.REACT_APP_URL.includes("http://localhost")
        window.location.origin.includes("http://localhost")
      ) {
        console.log("fb pixel iffff");

        const advancedMatching = { em: "some@email.com" }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };
        // const query_url = window.location.href;
        // const full_redirect_url = query_url.substring(query_url.indexOf('redirect_url=') + 13);
        // console.log("fullredirect" +full_redirect_url);
        console.log("In fb pixel");
        console.log("PID-" + pid);
        ReactPixel.init(pid, advancedMatching, options);
        ReactPixel.pageView();
        ReactPixel.track("Lead");
        console.log("lead fired");
        console.log("UUID:-" + visitorParameters.visitor_parameters.uuid);
        let uuid = visitorParameters.visitor_parameters.uuid;
        console.log(uuid);
        if (uuid === null || uuid === undefined) {
          uuid = query.get('uuid') ? query.get('uuid') : localStorage.getItem('uuid');
        }
        console.log(uuid);
        if(splitName=="MB_UFGN_1" || splitName=="MB_OBV1" || splitName=="MB_UBRF_1" || splitName=="MB_UBRF_3" || splitName=="MB_OB_UBRF_3_1"){
          history.push(
            "/signatureMB?split_name="+ splitName + "&uuid=" + uuid
          );
        }else if(splitName=="VAQ_UB_3" || splitName=="VAQ_UB_2" || splitName=="VAQ_UB_1" || splitName =="VAQ_OB_UB_3_1"){
          history.push(
            "/SignatureVAQ?split_name="+ splitName + "&uuid=" + uuid
          );
        }else if(splitName=="NDY_UB_2" || splitName=="NDY_UB_1" || splitName=="NDY_OB_UB_2_1"){
          history.push(
            "/signatureNDY?split_name="+ splitName + "&uuid=" + uuid
          );
        } else if (splitName == "VAQ_UB_4") {
          history.push(
            "/questionnaireV15?uuid=" + uuid
          );
        } else if (splitName == "VAQ_UB_4_2") {
          history.push(
            "/questionnaireV19?uuid=" + uuid
          );
        } else if (splitName == "VAQ_UB_4_3") {
        history.push(
          "/questionnaireV20?uuid=" + uuid
        );
        } else if (splitName == "MB_UFGN_4") {
        history.push(
          "/questionnaireMBGN_4?uuid=" + uuid
        );
        }
        else if (splitName == "MB_UFGN_4_1") {
          history.push(
            "/questionnaireMBGN_4_1?uuid=" + uuid
          );
        }
        else if (splitName == "NDY_UB_4" || splitName == "NDY_UB_4_2") {
          history.push(
            "/questionnaireV22?uuid=" + uuid
          );
        }
        else {
        history.push(
          "/signature?split_name="+ splitName + "&uuid=" + uuid
        );
        }
        // window.location.href = full_redirect_url;
      } else {
        console.log("fb pixel elseee");

        // const app_url = process.env.REACT_APP_URL;
        const app_url = window.location.origin;
        // const redirect_url = app_url + "thankyou";
        let uuid = visitorParameters.visitor_parameters.uuid;
        console.log(uuid);
        if (uuid === null || uuid === undefined) {
          uuid = query.get('uuid') ? query.get('uuid') : localStorage.getItem('uuid');
        }

        let redirect_url = '';
        redirect_url = app_url + "/signature?uuid=" + uuid;
        console.log('redirect_url' + redirect_url);
        const advRedirectDomain = "https://" + visitorParameters.data.redirectDomain;
        // const advRedirectDomain =
        //   "https://" + visitorParameters.data.redirectDomain;
        // const fb_pixel_url =
        //   advRedirectDomain +
        //   "/fbpixel?" +
        //   queryString +
        //   "&redirect_url=" +
        //   redirect_url;
        const fb_pixel_url = advRedirectDomain + "/fbpixel?" + queryString + "&redirect_url=" + redirect_url;
        console.log('fb_pixel_url' + fb_pixel_url);
        window.location.href = fb_pixel_url;

        //return false;
      }
    }
    else {
      console.log("else case");
    }
  }
  return <></>;
};

export default FbPixel;