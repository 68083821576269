import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const EmailConfirmation = ({ onselectAnswer, reff, display, userEmail }) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="question animated fadeInUp" ref={reff}>
          <h3>Is this the email address "{userEmail}" that you registered with Vanquis</h3>

          <RadioButtonQuest
            key="YES"
            className="radio-button"
            labelName="Yes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="1"
            name="question_61"
            id="sixone"
            dataQuestId={61}
            dataAnsId={162}
          />

          <RadioButtonQuest
            key="NO"
            className="radio-button"
            labelName="No"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="NO"
            name="question_61"
            id="sixtwo"
            dataQuestId={61}
            dataAnsId={163}
          />
        </div>
      </div>
    </>
  );
};

export default EmailConfirmation;
