import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import SelectBox from "../../../UI/SelectBox";
import { RecentCreditIncreaseYear,VeryLenderArray } from "../../../../Constants/Constants";
import InputButton from "../../../UI/InputButton";
import RadioButton from "../../../UI/RadioButton";
import Modal from "react-bootstrap/Modal";

const RecentCreditIncrease = ({
  className,
  slideChange,
  textHeadingLine1,
  validationMsg,
  clearErrors,
  validation,
  backClick,
  trigger,
  getValues,
}) => {

  const [agreeModalShow, setAgreeModalShow] = useState("hide");
  // const [claimModal, setshowClaimModal] = useState(false);
  // const handleCloseclaimModal = () => setshowClaimModal(false);
  

  const nextSlide = async (e) => {
    let errorFlag = 0;
    let yearresult = await trigger("RecentCreditIncrease");

    if (!yearresult) {
      errorFlag = 1;
      return false;
    }
    const formData = getValues();
    let yearSelected = formData.RecentCreditIncrease;
    // if (yearSelected < 2016) {
    //   setshowClaimModal(true);
    // }
      slideChange(e);
   

  };

  return (
    <>
      <div id="slide-2" className={`${className}`}>
        <ProgressBar value="15" />

        <h3> { textHeadingLine1 } </h3>
        <div className="input-section mb-3">
        
          <SelectBox
            className="form-select form-control"
            OptionValue={RecentCreditIncreaseYear}
            name="RecentCreditIncrease"
            id="RecentCreditIncrease"
            onChange={() => clearErrors("RecentCreditIncrease")}
            clearErrors={clearErrors}
            myRef={validation({ required: "Please Select Year" })}
            validationMsg={
              validationMsg.RecentCreditIncrease && validationMsg.RecentCreditIncrease.message
            }
          ></SelectBox>
       
        </div>

        <InputButton
          name="recentcreditIncrease"
          className="btn next08  regNextBtn"
          id=""
          value="Next"
          btnType="button"
          onClick={nextSlide}
          style={{ float: "none" }}
        />

        <div>
          <p className="text-center">
            <span
              className="back1 back-btn btn-link"
              name="back03b"
              children="<< Previous"
              onClick={backClick}
            ></span>
          </p>
        </div>

        {/* <Modal size="md" show={claimModal} onHide={handleCloseclaimModal} centered backdrop="static" className="modaloutersec">
        <Modal.Body>
         <h5 className="text-center mb-3">Sorry, we cannot accept your claim</h5>
          <div className="input-group prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08 regNextBtnNew regNxtBtnNw text-center"
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal> */}
      </div>
    </>
  );
};
export default RecentCreditIncrease;
