import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import "../../assets/Viewdocs_MB_UFGN_1/css/custom.scss";
// import "../../assets/Viewdocs_MB_UFGN_1/css/id_upload.scss";
// import "../../assets/Viewdocs_MB_UFGN_1/css/openbank.scss";
// import "../../assets/Viewdocs_MB_UFGN_1/css/signature.scss";
// import "../../assets/Viewdocs_MB_UFGN_1/css/style.scss";
import "../../assets/PersonKyc_MB_UFGN_2/css/Credit_check_NEW.scss";
import { queryString } from "../../Utility/QueryString";
import { useOpenBanking } from "../../Hooks/useOpenBanking";
import { Api } from "../../api/Api";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

const CreditCheckMB_UFGN_1 = () => {
  const {updateMilestone} = useUpdateMilestone();
  const [loader, setLoader] = useState(1);
  const [credLoader, setCredLoader] = useState(0);
  const [proceedLoader, sePproceedLoader] = useState(0);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [btndisable, setBtnDisable] = useState(false);
  const [f_name, setF_name] = useState("");

  const urlPortion = localStorage.getItem("url_portion");
  const splitName = localStorage.getItem("split_name");
  // const fname = localStorage.getItem("fName");
  //const credcheckStat = localStorage.getItem("credStat");
  const history = useHistory();
  const { OpenBankVisit, storeClickResponse } = useOpenBanking();
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const local_storage_uuid = localStorage.getItem("uuid");
  var uuid = query_uuid ? query_uuid : local_storage_uuid;
  const query_source = query.get("atp_sub6");
  var source = localStorage.getItem("source");
  const user_data = { uuid: uuid, source: source };
  let btn_label = "";
  let page = "";

  useEffect(() => {
    (async () => {
      if (uuid) {
        const response = await Api.get(
          "/api/v1/user-basic-details-s2?uuid=" + uuid,
          {}
        );
        if (response.data.status === "Success") {

          let complete = response.data.response.completed;

          if(complete == 1){
            history.push("/thankyou?uuid=" + uuid);
          }

          
          let payload = { page: "CREDIT_CHECK", type: "visit", source };
          await storeClickResponse(
            { uuid: uuid },
            "ob_creditcheck_history",
            uuid,
            payload
          );

          setTimeout(() => {
            setLoader(0);
          }, 1500);

          let title = response.data.response.lstSalutation;
          let fname = response.data.response.txtFName;
          let lname = response.data.response.txtLName;
          let email = response.data.response.txtEmail;
          let phone = response.data.response.txtPhone;
          let dob = response.data.response.dob;
          let building_name = response.data.response.txtAddressline1;
          let straddr = response.data.response.txtAddressline2;
          let city = response.data.response.town;
          let zip = response.data.response.txtPostcode;
          let clientRefId = response.data.response.uuid;
          setF_name(fname);

          let urlPortion =
            "title=" +
            title +
            "&fname=" +
            fname +
            "&lname=" +
            lname +
            "&email=" +
            email +
            "&phone=" +
            phone +
            "&dob=" +
            dob +
            "&abodenumber=" +
            "&building_name=" +
            building_name +
            "&straddr=" +
            straddr +
            "&street=" +
            "&city=" +
            city +
            "&zip=" +
            zip +
            "&clientRefId=" +
            clientRefId;

          let iframUrl =
            "https://uk.rspcdn.com/xprr/red/PID/3916?" + urlPortion;
          console.log(iframUrl);
          setIframeUrl(iframUrl);
        }
      } else {
        history.push("/thankyou");
      }
    })();
  }, []);

  const redirectCreditCheck = async (e) => {

    updateMilestone(uuid,"credit_check_click","live");

    GtmDataLayer({
      question: "credit report",
    });

    setBtnDisable(true);
    setLoader(1);
    //setCredLoader(1);

    btn_label = e.target.getAttribute("data-btn-label");
    page = e.target.getAttribute("data-page-label");

    let payload = {
      page,
      type: "btn_click",
      btn_label,
      source,
      click_btn: "3",
    };
    await storeClickResponse(
      { uuid: uuid },
      "ob_creditcheck_history",
      uuid,
      payload
    );

    const flpResponse = await OpenBankVisit(
      "creditcheck_stats",
      uuid,
      user_data,
      "proceed-creditcheck",
      queryString,
      "v1"
    );
    setTimeout(() => {
      setLoader(0);
      setCredLoader(1);
    }, 1500);
  };

  const goBack = () =>{
    if (splitName == "MB_UFGN_4" || splitName == "MB_UFGN_4_1"){
      history.push(
        "/CheckListGN_4?uuid=" + uuid
    );
    } else {
      history.push(
        "/CheckList?uuid=" + uuid
    );
    }
   
}

  return (
    <>
      {/* <div className="followups2newFlow">
        <div className="tms-wrap2 bg-main-pr">
          <div
            className={`text-center loader-wrap ${loader == 1 ? "showload" : "hide"
              }`}
          >
            <div className="loader">
              <img src="assets/img/review.gif" alt="" />
              <p>Please wait while we save your progress...</p>
            </div>
          </div>
          <div className={`${credLoader == 0 ? "showload" : "hide"}`}>
            <div
              className={`main text-center main-div  ${loader == 0 ? "showload" : "hide"
                }`}
            >
              <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
                <img
                  height="65"
                  src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
                  alt=""
                />
              </div>
              <div className="container">
                <div className="item-cent-flx ">
                  <div className="pt-lg-5 pt-md-3 pt-sm-3  pt-3 text-center">
                    <div className="openbank-slide3  ">
                      <div className="  text-center">
                        <div className="col-lg-8 offset-lg-2 px-4 mb-2 text-center">
                          <h1 className="  mt-md-2 mt-sm-2 mt-0 text-danger">
                            {" "}
                            {f_name}, We require your credit report to complete
                            our comprehensive investigation and finalise your
                            complaint{" "}
                          </h1>
                          <p style={{ maxWidth: "800px " }} className="my-3 ">
                            We have partnered with UK Credit Ratings to offer a
                            FREE, comprehensive, and simple - to -use service.{" "}
                          </p>
                        </div>
                        <div className="row   mt-md-2 mt-sm-3 mt-0">
                          <div className="col-lg-6 offset-lg-3 px-4 mb-2 text-center">
                            <div>
                              <button
                                onClick={redirectCreditCheck}
                                disabled={btndisable}
                                data-btn-label="access_free_credit_report"
                                data-page-label="CREDIT_CHECK"
                                className="  btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 second anim_green disablebtn"
                              >
                                {" "}
                                Access Free Credit Report <span> ›</span>{" "}
                              </button>
                           
                              <p className="mt-3 text2b mb-2">
                                {" "}
                                24/7 Monitoring, Individual Credit Review.
                                Correct Credit Errors.
                              </p>
                              <p className="mt-0 text3b text-danger">
                                <i className="fa fa-clock-o"> </i> Estimated
                                Processing 1 minutes
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="text-center mt-4">
                          <img src="assets/img/uk-credits.png" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="mb-lg-5 mb-md-5 mb-sm-3 mb-3 mt-5 mt-lg-3 mt-md-5 mt-sm-5 ">
                      <img
                        height="30"
                        src="assets/img/LGO_UKC_Black.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg-5">
                <img
                  height="50"
                  className="safe-secure-img"
                  src="assets/img/safe and secure.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className={`${credLoader == 1 ? "showload" : "hide"
            } iframe-container`}
        >
          <iframe src={iframeUrl} frameborder="0"></iframe>
        </div>
      </div> */}


      {/* {** new code } */}
      <div className="credit_check_New_Mb">
        <div className={`logo-two text-center pt-3  ${credLoader == 0 ? "showload" : "hide"}`} >
          {" "}
          <img src="assets/PersonKyc_MB_UFGN_2/img/logo.png" alt="" />
        </div>
        <div class="container-fluid">

          <div className={`slide  page-loader loader1 ${loader == 1 ? "showload" : "hide"
              }`}>
            <div className="wrap-app mt-3 text-center">
              <div className="mb-5 pb-5">
                <img height="100" className="mb-2" src="assets/PersonKyc_MB_UFGN_2/img/loader-review2.gif" alt="" />
                <p className="mb-5">Please wait while we save your progress...</p>
              </div>
            </div>
          </div>
          <div className={`${credLoader == 0 ? "showload" : "hide"}`}>
          <div

            className={`slide connect-account   credit-report pb-3 ${loader == 0 ? "showload" : "hide"
          } `}
          >
            <div className="wrap-app mt-3 text-center">
              {/* Banner */}
              <h2 className="fw-bold title mt-4 mb-4">
                We have partnered with UKCreditRatings to offer a FREE, comprehensive, and
                simple to use service.
              </h2>
              <img className="img-fluid" src="assets/PersonKyc_MB_UFGN_2/img/credit.png" alt="" />
              <div className="mt-lg-4 mt-md-4 mt-sm-4 mt-4 mb-4">
                <button className="btn-comm3 btn-eff"
                 onClick={redirectCreditCheck}
                 disabled={btndisable}
                 data-btn-label="access_free_credit_report"
                 data-page-label="CREDIT_CHECK"
                >
                  <small />
                  <small />
                  <small />
                  <small />
                  <span> Access Free Credit Report</span>
                </button>
              </div>
              <p className="mb-0  ">
                24/7 Monitoring, Individual Credit Review. Correct Credit Errors.
              </p>
              <div className="mb-4">
                {" "}
                <small className="text-danger">
                  &nbsp;Estimated Processing 1 minutes
                </small>{" "}
              </div>
              <img src="assets/PersonKyc_MB_UFGN_2/img/uk-credits.png" alt="" />
              <div className={`text-center`}>
            <a onClick={()=>goBack()} >
            <div className="mt-5 text-primary mb-5 back-btn text-decoration-underline" id="back2">
              <i className="bi bi-arrow-left"></i> Go Back</div>
            </a>
          </div>
            </div>
          </div>
          </div>
          
        </div>
        <div className={`text-center py-4 mb-lg-5 mb-md-5 mb-sm-4 mb-4 secure ${credLoader == 0 ? "showload" : "hide"}`}>
          <img height="35" src="assets/PersonKyc_MB_UFGN_2/img/secure.png" alt="" />
        </div>
        <div
          className={`${credLoader == 1 ? "showload" : "hide"
            } iframe-container`}
        >
          <iframe src={iframeUrl} frameborder="0"></iframe>
        </div>
      </div>
      

    </>
  );
};
export default CreditCheckMB_UFGN_1;
