import { useContext } from "react";
import { Dependent } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import ReducerContext from "../../../../Contexts/ReducerContext";
export default function DependentsNew({
  validation,
  validationMsg,
  saveInputAns,
  trigger,
}) {
  const state = useContext(ReducerContext);

  const optionSelected = async (e) => {
    let errorFlag = 0;
    let result = await trigger("dependentsNew");

    if (!result) {
      errorFlag = 1;
      return false;
    } else {
    saveInputAns(e);
    }
  };
  return (
    <>
      <div
        className={`radio-box animated fadeInUp space-top space-top2 ${state.showSlide5_2}`}
        id="slide-9"
        // // ref={(el) => (scrollRef.current["dependents"] = el)}
      >
        <h3><b>
          How many dependents did you have at that time?
            </b>
          </h3>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
        <SelectBox
          className="form-select form-control"
          OptionValue={Dependent}
          name="dependentsNew"
          id="dependentsNew"
          myRef={validation({
            required: "Please Select Amount",
          })}
          validationMsg={
            validationMsg.dependentsNew &&
            validationMsg.dependentsNew.message
          }
        />
      </div>
      <div className="col-xl-12 col-lg-12 text-center p-0">
        <button
          type="button"
          className="btn-next"
          onClick={optionSelected}
          data-quest-id={3}
          data-ans-id={null}
          data-ans-label={null}
          name="dependentsNew"
        >{`NEXT`}</button>
      </div>
      </div>
    </>
  );
}
