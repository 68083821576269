import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import ProgressBar from '../Common/ProgressBar';
import TextField from '../../../UI/TextField';
import RadioButton from '../../../UI/RadioButton';
import review from "../../../../assets/MB_UFGN_1/img/review.gif";
//import { BankList } from "../../../../Constants/Lender";
import Select from 'react-select';


const Banks = ({
  SaveBank,
  validation,
  SelectBank,
  setSelectBank,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  setError,
  backClick,
  lenderList,
  saveInputAns
}) => {

    const [BankValidationMsg, setBankValidationMsg] = useState(false);


  

    const customStyles = {
        control: base => ({
          ...base,
          //height: 50,
          minHeight: 50
        })
      };
    
      const nextSlide = async (e) => {
        let LengthSelectedBank = SelectBank.length;
        let ValueSelectedBank = SelectBank.value;
       
        if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
          setBankValidationMsg("Please select your Bank");
        } else {
           localStorage.setItem("bud_lender",ValueSelectedBank);
           localStorage.setItem("lenderArray",JSON.stringify([SelectBank]));
          saveInputAns(e);
        }
      }

      // let toggle;
      // if (skip == true) {
      //   toggle = 'back05';
      // } else {
      //   toggle = 'prevKeeper';
      // }
    
      function handleSelect(opt) {
        setSelectBank(opt);
        setBankValidationMsg(false);
      }
      let BankList = []; //[{ value: null,  label: "---Select Bank Name---" }];
      // if(lenderList.length >0){
      //   lenderList.map(function (val) {
      //     let lenderData = { value: val.provider, label:val.display_name}
      //     BankList.push(lenderData);
      //   })
      // }
    
      if (lenderList.length > 0) {
        BankList = BankList.concat(lenderList.filter(function (val) {
          return !val.display_name.endsWith("Card") &&
            !val.display_name.endsWith("Business") &&
            !val.display_name.endsWith("card") &&
            !val.display_name.endsWith("Cards");
        }).map(function (val) {
          return {
            value: val.provider,
            label: val.display_name
          };
        }));
      }
    return (
        <div className={`row`}>
            <div className="col-lg-8 offset-lg-2 ">
                <h1 className="text-white  title mb-4">
                    <div className="d-flex align-items-center">
                        <div>
                            {" "}
                            <img src="../../assets/QuestionaireMBGN_4/img/lender-icon.png" alt="" />
                        </div>
                        <div>
                            This information is crucial for assessing your eligibility to
                            make an unaffordable claim against MoneyBarn.
                        </div>
                    </div>
                </h1>
                <h3 className="text-white text-lg-start text-md-start text-sm-center text-center   mb-4 qs-title">
                    {" "}
                    Which bank were you with when you took the finance with Moneybarn?
                </h3>
                <div className="mb-3 panel bg-light p-3 rounded-3   pulse2 ">
                    <div className="row">
                        <div className="col-lg-8 mb-lg-0 mb-md-3 mb-sm-3 mb-1 pe-lg-0">
                            <div className="drop-down-multi  ">
                                <Select
                                    placeholder="Select Bank"
                                    options={BankList}
                                    aria-label="Username"
                                    name="bankName"
                                    id="bankName"
                                    value={SelectBank}
                                    onChange={handleSelect}
                                    isSearchable={true}
                                    styles={customStyles}
                                    //isMulti
                                
                                />
                            </div>
                            <span id="bank_err" className="error_msg">{BankValidationMsg}</span>

                        </div>
                        <div className="col-lg-4">
                            <button
                                className=" btn-comm2   py-2 w-100  "
                                id="submitLender"
                                onClick={nextSlide}
                                name="banks"
                                data-quest-id={64}
                            >
                                {/* <span> */}
                                  Next 
                                  {/* </span>{" "} */}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

};

export default Banks;