import React, {
  useState,
  useEffect,
} from "react";
import { Api } from "../../api/Api";
import "../../assets/OpenBanking/css/styleV1.scss";
import "../../assets/OpenBanking/css/obSplash.scss";
import { queryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useOpenBanking } from "../../Hooks/useOpenBanking";
import InputButton from "../UI/InputButton";
import { AppDomain } from "../../Constants/EnvConstants";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import OB_CreditCheck from "../Includes/Layouts/ObFollowup/OB_CreditCheck";
import OB_AffordabilityCheck from "../Includes/Layouts/ObFollowup/OB_AffordabilityCheck";
import OB_Splash from "../Includes/Layouts/ObFollowup/OB_Splash";

const OpenBankingProceedV1 = () => {
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(1);
  const [reloader, setReloader] = useState(1);
  const [affordabilityStat, setAffordabilityStat] = useState('hide');
  const [obCredStat, setObCredStat] = useState('hide');
  const [obSplashStat,setObSplashStat] = useState('show');
  const history = useHistory();
  const { getVisitorID, getAccessToken, createCustomer, getProviders, getAuthGatewayUrl, storeApiResponse, OpenBankVisit, storeClickResponse } = useOpenBanking();
  const [btndisable, setBtnDisable] = useState(false);
  const [visitorId, setVisitorId] = useState(0);
  const [iframeUrl,setIframeUrl] = useState(null);
  let redirectUrl = '';
  let redirectlink = '';
  let btn_label = '';
  let page = '';
  const query = new URLSearchParams(window.location.search);
  const query_source = query.get("atp_sub6");
  var source = query_source ? query_source : "live";
  
  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/api/v1/user-basic-details?query_string=" + queryString,
        {}
      );
      if (response.data.status === "Success") {
        let user_data = response.data.response.user_data;
        let uuid = response.data.response.uuid;
        let lender = response.data.response.lender;
        let source = response.data.response.source;
        let lstSalutation = response.data.response.lstSalutation;
        let txtFName = response.data.response.txtFName;
        let txtLName = response.data.response.txtLName;
        let txtEmail = response.data.response.txtEmail;
        let txtPhone = response.data.response.txtPhone;
        let is_test = response.data.response.is_test;
        let token = response.data.response.user_data.atp_sub2;
        let pixel = response.data.response.user_data.pixel;
        let txtPostcode = response.data.response.txtPostcode;
        let txtAddressline1 = response.data.response.txtAddressline1;
        let txtAddressline2 = response.data.response.txtAddressline2;
        let txtAddressline3 = response.data.response.txtAddressline3;
        let town = response.data.response.town;
        let dob = response.data.response.dob;
        let userId = response.data.response.user_id;
        let obcreduser = response.data.response.obcreduser;
        let obstat = response.data.response.obstat;
        let credstat = response.data.response.credstat;
        let obclosestat = response.data.response.obclosestat;


        localStorage.setItem('is_test', is_test);
        localStorage.setItem('user_data', JSON.stringify(user_data));

        if(uuid == null){
          history.push("/thankyou?uuid=" + uuid);
        }

        setUserDetails({
          uuid: uuid,
          lender: lender,
          source: source,
          lstSalutation: lstSalutation,
          txtFName: txtFName,
          txtLName: txtLName,
          txtEmail: txtEmail,
          txtPhone: txtPhone,
          is_test: is_test,
          token: token,
          pixel: pixel,
          txtPostcode:txtPostcode,
          txtAddressline1:txtAddressline1,
          txtAddressline2:txtAddressline2,
          txtAddressline3:txtAddressline3,
          town:town,
          dob:dob,
          user_id:userId,
          obcreduser:obcreduser,
          obstat:obstat,
          credstat:credstat,
          obclosestat
        });

        setTimeout(() => {
          setLoader(0);
        }, 2000);
        
       
        const visitor = await getVisitorID(
          uuid,
          user_data,
          'new'
        )

        if (visitor.data.status == "success") {
          setVisitorId(visitor.data.visitorId);
        }

        const flpResponse = await OpenBankVisit(
          "open_bank_visit",
          uuid,
          user_data,
          "OpenBankingProceedV1",
          queryString,
          "proceed-obV1"
        );
      } else {
        setLoader(0);
        history.push("/thankyou?uuid=" + response.data.response.uuid);
        return false;
      }
    })();

  }, []);

    useEffect(()=>{
       

      (async () => {
      
      let urlPortion = 'title='+userDetails.lstSalutation+'&fname='+userDetails.txtFName+'&lname='+userDetails.txtLName+'&email='+userDetails.txtEmail+'&phone='+userDetails.txtPhone+'&dob='+userDetails.dob+'&abodenumber='+'&building_name=' + userDetails.txtAddressline1+'&straddr='+userDetails.txtAddressline2+'&street='+'&city='+userDetails.town+'&zip='+userDetails.txtPostcode+'&clientRefId='+userDetails.uuid;
 
      localStorage.setItem('url_portion',urlPortion);
      localStorage.setItem('uuid',userDetails.uuid);
      localStorage.setItem('firstname',userDetails.txtFName);
      localStorage.setItem('credStat',userDetails.credstat);

      if (userDetails.uuid) {

        let payload = { "page": 'OB_SPLASH',type:'visit', "source": userDetails.source };
        await storeClickResponse({uuid:userDetails.uuid},"ob_creditcheck_history",userDetails.uuid,payload);

      } 

      if (userDetails.obclosestat == '1') {
        history.push("/ob_splash_cancel?uuid=" + userDetails.uuid +"&atp_sub6="+ source);
        return false;
      }


      if ((userDetails.obcreduser == '1') && (userDetails.obstat == '' || userDetails.obstat == '0' || userDetails.obstat == null) && (userDetails.credstat == '' || userDetails.credstat == '0' || userDetails.credstat == null)) { 
        
        history.push("/thankyou?uuid=" + userDetails.uuid);
        return false;

      }

      // if (userDetails.obcreduser == '1') {
      //     setAffordabilityStat('hide');
          
      //     if (userDetails.obstat == '' || userDetails.obstat == '0' || userDetails.obstat == null) {
          
      //       if (userDetails.credstat == '1') {
      //         history.push("/credit_check?"+ queryString);
      //         return false;
            
      //       } else {
              
      //         setLoader(0);
      //         history.push("/thankyou?uuid=" + userDetails.uuid);
      //         return false;

      //       }

      //     } else if(userDetails.obstat == '1' && (userDetails.credstat == '0' || userDetails.credstat == null)) {

      //       setObCredStat('hide');
      //       // setAffordabilityStat('show');

      //     } else {

      //       setAffordabilityStat('hide');
      //       // setObCredStat('show');

      //     }

      // } else {

      //   setObCredStat('hide');
      //   // setAffordabilityStat('show');
         
      // }  

      // let iframUrl = 'https://uk.rspcdn.com/xprr/red/PID/3916?'+urlPortion;
      // setIframeUrl(iframUrl);

    })();
    

    },[userDetails]);

  

  const pushProceedLink = async (e) => {
    
    setBtnDisable(true);
    setLoader(1);

    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');
    
    let payload = { page,type:'btn_click', btn_label, "source": userDetails.source,'click_btn':'1' };
    await storeClickResponse({uuid:userDetails.uuid},"ob_creditcheck_history",userDetails.uuid,payload);

    GtmDataLayer({
      'question': 'OPEN BANKING'
    });
    
    localStorage.setItem('visitorId', visitorId);
    const response1 = await getAccessToken(userDetails.uuid, userDetails.source, userDetails.is_test, visitorId);

    if (response1 == undefined ||  response1 == null || response1 == '') {
      setLoader(0);
      setReloader(0);
      return false;
    }

    if (response1.data.status == "success") {
      //(response.data.access_token_ID);
      let payload = { "milestone_key": "access_token", "source": userDetails.source };
      await storeApiResponse(userDetails, "op_milestone_update", userDetails.uuid, payload);
      const customerResponse = await createCustomer(userDetails.uuid, userDetails.source, userDetails.lstSalutation, userDetails.txtFName, userDetails.txtLName, userDetails.txtEmail, userDetails.txtPhone, userDetails.is_test, visitorId)

      if (customerResponse == undefined ||  response1 == null || response1 == '') {
        setLoader(0);
        setReloader(0);
        return false;
      }


      if (customerResponse.data.status == "success") {
        localStorage.setItem('customer_id', customerResponse.data.customer_ID);
        let payload = { "milestone_key": "customer_create", "customer_id": customerResponse.data.customer_ID, 'source': userDetails.source };
        await storeApiResponse(userDetails, "op_customer_store", userDetails.uuid, payload);
        // const providerResponse = await getProviders(userDetails.uuid,userDetails.source,userDetails.is_test);
        // if (providerResponse.data.status === "success") {
        //     const providers = providerResponse.data.providers;
        //     const optionData = providers.map((value, index) => {
        //         return (
        //           <option value={value.provider} key={index}>
        //             {value.display_name}
        //           </option>
        //         );
        //       });
        // }
        if (userDetails.credstat == '1') { 
          redirectUrl = (userDetails.source) ? AppDomain + "/ob-thankyou02?uuid=" + userDetails.uuid + "&atp_sub6=" + userDetails.source : AppDomain + "/ob-thankyou02?uuid=" + userDetails.uuid;
        } else {
          redirectUrl = (userDetails.source) ? AppDomain + "/ob-thankyou?uuid=" + userDetails.uuid + "&atp_sub6=" + userDetails.source : AppDomain + "/ob-thankyou?uuid=" + userDetails.uuid;

        }

        var response2 = await getAuthGatewayUrl(userDetails.uuid, customerResponse.data.customer_ID, redirectUrl, userDetails.source, userDetails.is_test, visitorId)

        if (response2 == undefined ||  response2 == null || response1 == '') {
          setLoader(0);
          setReloader(0);
          return false;
        }

        if (response2.data.status == "success") {
          payload = { "milestone_key": "lender_redirection", 'source': userDetails.source };
          await storeApiResponse(userDetails, "op_milestone_update", userDetails.uuid, payload);
          let url = response2.data.gateway_url;
          window.location.href = url;
          //setLoader(0)
        }
      }
    }
  }
  
  const redirectTankyou = async (e) =>{
    setBtnDisable(true);
    setLoader(1)
    GtmDataLayer({
      'question': 'Pre-Paid Envelopes'
    });

    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');


    let payload = { page,type:'btn_click', "btn_label": btn_label, "source": userDetails.source,'click_btn':'2' };
    await storeClickResponse({uuid:userDetails.uuid},"ob_creditcheck_history",userDetails.uuid,payload);

    const flpResponse = await OpenBankVisit(
      "envelopes_stats",
      userDetails.uuid,
      userDetails,
      "proceed-open-banking",
      queryString,
      "v1"
    );

    
    if (userDetails.obcreduser == '1' && userDetails.obstat == '1' && userDetails.credstat == '1') {   
       redirectlink =  "/credit_check?"+ queryString;
    } else {
      redirectlink = "/envelope-thankyou?uuid=" + userDetails.uuid
    }

    setTimeout(() => {
      setLoader(0);
      history.push(redirectlink);
    }, 1500);
  }

  const keepMyFileLink = async(e) => {
    
    setBtnDisable(true);
    setLoader(1);

    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');


    let payload = { page,type:'btn_click', "btn_label": btn_label, "source": userDetails.source,'click_btn':'7' };
    await storeClickResponse({uuid:userDetails.uuid},"ob_creditcheck_history",userDetails.uuid,payload);


    let payloadinitial = { "page": 'OB_LP',type:'visit', "source": userDetails.source };
    await storeClickResponse({uuid:userDetails.uuid},"ob_creditcheck_history",userDetails.uuid,payloadinitial);


    if (userDetails.obcreduser == '1') {
      setObSplashStat('hide');
      setAffordabilityStat('hide');
      
      if (userDetails.obstat == '' || userDetails.obstat == '0' || userDetails.obstat == null) {
      
        if (userDetails.credstat == '1') {
          history.push("/credit_check?"+ queryString);
          return false;
        
        } else {
          
          setLoader(0);
          history.push("/thankyou?uuid=" + userDetails.uuid);
          return false;

        }

      } else if(userDetails.obstat == '1' && (userDetails.credstat == '0' || userDetails.credstat == null)) {

        setObCredStat('hide');
        setAffordabilityStat('show');

      } else {

        setAffordabilityStat('hide');
        setObCredStat('show');

      }

  } else {
    setObSplashStat('hide');
    setObCredStat('hide');
    setAffordabilityStat('show');
     
  }
    setBtnDisable(false);
    
    setTimeout(() => {
      setLoader(0);
    }, 1500);

  }


  const closeMyFileLink = async(e) => {

    setBtnDisable(true);

    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');


    let payload = { page,type:'btn_click', "btn_label": btn_label, "source": userDetails.source,'click_btn':'8' };
    await storeClickResponse({uuid:userDetails.uuid},"ob_creditcheck_history",userDetails.uuid,payload);

    history.push("/ob_splash_cancel?uuid=" + userDetails.uuid +"&atp_sub6="+ source);
    return false;

  }


  return (
  <>

      <OB_Splash
         obSplashStat={obSplashStat}
         btndisable = {btndisable}
         keepMyFileLink = {keepMyFileLink}
         closeMyFileLink = {closeMyFileLink}
         loader={loader}
         userDetails={userDetails}
         />

      <OB_AffordabilityCheck
        setLoader={setLoader}
        loader={loader}
        reloader={reloader}
        btndisable = {btndisable}
        setReloader={setReloader}
        pushProceedLink={pushProceedLink}
        redirectTankyou={redirectTankyou}
        affordStat = {affordabilityStat}
        />

      <OB_CreditCheck
        setLoader={setLoader}
        loader={loader}
        reloader={reloader}
        btndisable = {btndisable}
        obCredStat = {obCredStat} 
        pushProceedLink={pushProceedLink}
        redirectTankyou={redirectTankyou}
      />

</>
  );
};

export default OpenBankingProceedV1;