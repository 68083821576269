import React, {
  useState,
  useEffect,
} from "react";
import { Api } from "../../api/Api";
import "../../assets/OpenBanking/css/styleV1.scss";
import { queryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useOpenBanking } from "../../Hooks/useOpenBanking";
import InputButton from "../UI/InputButton";
import { AppDomain } from "../../Constants/EnvConstants";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import * as EnvConstants from "../../Constants/EnvConstants";

const OpenBanking = () => {
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(1);
  const [reloader, setReloader] = useState(1);
  const history = useHistory();
  const { getVisitorID, getAccessToken, createCustomer, getProviders, getAuthGatewayUrl, storeApiResponse, OpenBankVisit } = useOpenBanking();
  const [btndisable, setBtnDisable] = useState(false);
  const [visitorId, setVisitorId] = useState(0);
  const environment = EnvConstants.AppEnv;

  useEffect(() => {
    (async () => {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem('uuid');
      var uuid = (query_uuid)? query_uuid : local_storage_uuid;

      const response = await Api.get(
        "/api/v1/user-basic-details?uuid=" + uuid,
        {}
      );
      if (response.data.status === "Success") {
        let user_data = response.data.response.user_data;
        let uuid = response.data.response.uuid;
        let lender = response.data.response.lender;
        let source = 'live';
        let lstSalutation = response.data.response.lstSalutation;
        let txtFName = response.data.response.txtFName;
        let txtLName = response.data.response.txtLName;
        let txtEmail = response.data.response.txtEmail;
        let txtPhone = response.data.response.txtPhone;
        let is_test = response.data.response.is_test;
        let token = response.data.response.user_data.atp_sub2;
        let pixel = response.data.response.user_data.pixel;
        let bud_lender = response.data.response.bud_lender;

        localStorage.setItem('is_test', is_test);
        localStorage.setItem('user_data', JSON.stringify(user_data));

        // if(uuid == null){
        //   history.push("/thankyou?uuid=" + uuid);
        // }

        if(environment == "prod" && is_test === true){
          bud_lender = [];
        }else{
          bud_lender = [bud_lender]
        }

        setUserDetails({
          uuid: uuid,
          lender: lender,
          source: source,
          lstSalutation: lstSalutation,
          txtFName: txtFName,
          txtLName: txtLName,
          txtEmail: txtEmail,
          txtPhone: txtPhone,
          is_test: is_test,
          token: token,
          pixel: pixel,
          budLender:bud_lender
        });

        setTimeout(() => {
          setLoader(0);
        }, 1500);

        let visitor_id = localStorage.getItem('visitorId');

        if(visitor_id === undefined || visitor_id === null || visitor_id == "" ){
          const visitor = await getVisitorID(
            uuid,
            user_data,
            'new'
          )
  
          if (visitor.data.status == "success") {
            setVisitorId(visitor.data.visitorId);
          }
          localStorage.setItem('visitorId', visitorId);

        }else{
          setVisitorId(visitor_id);
        }

        const flpResponse = await OpenBankVisit(
          "open_bank_visit_live",
          uuid,
          user_data,
          "OpenBankingLive",
          queryString,
          "OBV1"
        );
      } else {
        setLoader(0);
        history.push("/thankyou?uuid=" + response.data.response.uuid);
        return false;
      }
    })();
  }, []);

  const pushProceedLink = async () => {
    GtmDataLayer({
      'question': 'Connect your bank'
    });
    setBtnDisable(true);
    setLoader(1)
    const response1 = await getAccessToken(userDetails.uuid, userDetails.source, userDetails.is_test, visitorId);

    if (response1 == undefined ||  response1 == null || response1 == '') {
      setLoader(0);
      setReloader(0);
      return false;
    }


    if (response1.data.status == "success") {
      //(response.data.access_token_ID);
      let payload = { "milestone_key": "access_token", "source": userDetails.source,"lender": userDetails.budLender[0]};

      await storeApiResponse(userDetails, "op_milestone_update", userDetails.uuid, payload);
      const customerResponse = await createCustomer(userDetails.uuid, userDetails.source, userDetails.lstSalutation, userDetails.txtFName, userDetails.txtLName, userDetails.txtEmail, userDetails.txtPhone, userDetails.is_test, visitorId);

      if (customerResponse == undefined ||  response1 == null || response1 == '') {
        setLoader(0);
        setReloader(0);
        return false;
      }

      if (customerResponse.data.status == "success") {
        localStorage.setItem('customer_id', customerResponse.data.customer_ID);
        let payload = { "milestone_key": "customer_create", "customer_id": customerResponse.data.customer_ID, 'source': userDetails.source,"lender": userDetails.budLender[0] };

        await storeApiResponse(userDetails, "op_customer_store", userDetails.uuid, payload);
        // const providerResponse = await getProviders(userDetails.uuid,userDetails.source,userDetails.is_test);
        // if (providerResponse.data.status === "success") {
        //     const providers = providerResponse.data.providers;
        //     const optionData = providers.map((value, index) => {
        //         return (
        //           <option value={value.provider} key={index}>
        //             {value.display_name}
        //           </option>TMUC49-fceee9e0-40d3-11ee-9a88-3921beb8c4e3
        let redirectUrl = AppDomain + "/analyse?uuid=" + userDetails.uuid;
        var response2 = await getAuthGatewayUrl(userDetails.uuid, customerResponse.data.customer_ID, redirectUrl, userDetails.source, userDetails.is_test, visitorId,userDetails.budLender);
        
        if (response2 == undefined ||  response2 == null || response1 == '') {
          setLoader(0);
          setReloader(0);
          return false;
        }

        if (response2.data.status == "success") {
          payload = { "milestone_key": "lender_redirection", 'source': userDetails.source , "lender": userDetails.budLender[0] };
          localStorage.setItem('bud_lender', userDetails.budLender[0]);
          await storeApiResponse(userDetails, "op_milestone_update", userDetails.uuid, payload);
          let url = response2.data.gateway_url;
          window.location.href = url;
          // setLoader(0);
          setReloader(1);
        }
      }
    }
  }
  
  const redirectTankyou = async () =>{
    setLoader(1)
    GtmDataLayer({
      'question': 'Connect your bank'
    });
    const flpResponse = await OpenBankVisit(
      "envelopes_stats",
      userDetails.uuid,
      userDetails,
      "proceed-open-banking",
      queryString,
      "v1"
    );
    setTimeout(() => {
      setLoader(0);
      history.push("/QuestionnaireEnvlp?uuid=" + userDetails.uuid);
    }, 1500);
  }

  return (
    <div className="OpenBankingV1">
      <div className={`text-center loader-wrap ${loader == 1 ? 'showload' : 'hide'}`}>
        <div className="loader">
          <img src="assets/img/review.gif" alt="" />
        </div>
      </div>
    
      <div className={`${reloader == 1 ? 'show' : 'hide'}`}>
      <div className={`main text-center main-div  ${loader == 0 ? 'showload' : 'hide'}`}>
        <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
          <img height="65" src="assets/img/logo_1.png" alt="" />
        </div>
        <div className="container">
          <div className="item-cent-flx ">
            <div className="pt-lg-5 pt-md-3 pt-sm-3  pt-3 text-center">
              <h1 className="mt-lg-5 mt-md-2 mt-sm-2 mt-0">Complete Your Affordability Check
                to Finalise Your Complaint </h1>
              <p className="my-3 p-style"> We do this by connecting to your Bank Account using the FCA regulated Open Banking platform via Bud Financial. </p>

              <div className="row mt-lg-5 mt-md-2 mt-sm-3 mt-0">
                <div className="col-lg-6 px-4 mb-2">

                  <div>
                    <button className="btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 second anim_green"
                      onClick={pushProceedLink}
                      //disable={btndisable}
                    >
                      Connect Your Bank Account <span> {'›'}</span>
                    </button>

                    <p className="mt-3 text2b mb-0"><img src="assets/img/lock.png" style={{width:'2%'}}/> &nbsp; Safe & Secure via FCA Regulated Open Banking</p>
                    <p className="mt-0 text3b red-text"><img src="assets/img/clock.png" style={{width:'3%'}}/> Estimated Processing Time: 2 minutes </p>
                  </div>
                </div>
                <div className="col-lg-6 px-4">
                  <div>
                    <button className="btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0"
                    onClick={redirectTankyou}
                    > 
                    Pre-Paid Envelopes   <span> {'›'}</span> </button>
                    <p className="mt-3 text2b mb-0">Post your bank statements securely to us via the Royal Mail</p>
                    <p className="mt-0 text3b red-text"><img src="assets/img/clock.png" style={{width:'3%'}}/> Estimated Processing time: 6-8 weeks </p>
                  </div>
                </div>
              </div>
              <div className="mb-lg-5 mb-md-5 mb-sm-3 mb-3 mt-5 mt-lg-3 mt-md-5 mt-sm-5 ">
                <img height="50" src="assets/img/safe-secure.png" alt="" />
              </div>
            </div>
          </div>

        </div>
      </div>
      </div>

      {/* <section id="main-dv" className="btn-info_section"> */}
          <div className="container btn-info_part">
            <div className="row justify-content-center ">
              <div className="col-lg-10 col-11">
                <div className={`space text-center ${reloader == 0 ? 'show' : 'hide'}`}>
                    <div className="button _1 w-100 anim_blue refresh_btn_outer">
                      <InputButton
                        name="proceedd"
                        className="btn_proceed refresh_btn"
                        id="bb"
                        value="Refresh"
                        btnType="button"
                        onClick={pushProceedLink}
                      />
                      <div className="back"></div>
                    </div>
                </div>
              </div>
            </div>
          </div>
      {/* </section> */}


    </div>
  );
};

export default OpenBanking;
