import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import Modal from "react-bootstrap/Modal";
import InputButton from "../../../UI/InputButton";
const Verify = ({
  className,
  slideChange,
  validation,
  backClick,
  textHeading,
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);
  const nextSlide = async (e) => {
    if (e.target.value === "99") {
      setCheckedOne("checked");
      slideChange(e);
    
      
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "100") {
      setCheckedTwo("checked");
      setshowClaimModal(true);
      // slideChange(e);
    } else {
      setCheckedTwo("unchecked");
    }
  };
  return (
    <>
      <div id="slide9" className={`col-12 top_minus_1 mb-5 ${className}`}>
        <ProgressBar value="0" />
        <h3> {textHeading} </h3>
        <div className="btn-comm">
          <RadioButton
            value="99"
            name="verify"
            className="radio-button"
            labelName="Yes, I Have Struggled to Repay"
            labelClassName={`option_but   next9 ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="100"
            name="verify"
            className="radio-button"
            labelName="No Issues Repaying"
            labelClassName={`option_but   next9 ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        {/* <p className="text-center"><a  className="back01" name="back08" onClick={backClick}> {'<< Previous'} </a></p> */}

      </div>
      {/* **** cannot accept your claim popup start **** */}
      <Modal size="md" show={claimModal} onHide={handleCloseclaimModal} centered backdrop="static" className="claimdeinial">
        <Modal.Body>
          <h3 className="text-center">Sorry, we will not be able to help you if you haven't experienced any hardships or struggles in repaying</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Verify;