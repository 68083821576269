import React from 'react';
import { DobYear, DobMonth, DobDay } from '../Constants/Constants';
import SelectBox from '../Components/UI/SelectBox';


const DateOfBirth = ({
    textDob,
    validation,
    validationMsg,
    dob_day,
    dob_month,
    dob_yr,
    notDobValid
}) => {

    return (
        <div className="form-group col-12">
            <fieldset className="scheduler-border">
                <legend className="scheduler-border dobs">{textDob}</legend>
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-2">

                        <SelectBox
                            className="form-select form-control"
                            OptionValue={DobDay}
                            defaultValue={dob_day}
                            name="DobDay"
                            id="DobDay"
                            myRef={validation({
                                required: "Please Select Date"
                            })}
                            validationMsg={validationMsg.DobDay && validationMsg.DobDay.message}
                        />
                        <i className="tick fa" style={{ display: 'none' }}></i>
                        {/* <span id="dobDay_err" className="error_msg"></span> */}

                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-2">

                        <SelectBox
                            className="form-select form-control"
                            OptionValue={DobMonth}
                            defaultValue={dob_month}
                            name="DobMonth"
                            id="DobMonth"
                            myRef={validation({
                                required: "Please Select Month"
                            })}
                            validationMsg={validationMsg.DobMonth && validationMsg.DobMonth.message}
                        />
                        <i className="tick fa" style={{ display: 'none' }}></i>
                        {/* <span id="dobMonth_err" className="error_msg"></span> */}

                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 mb-2">

                        <SelectBox
                            className="form-select form-control"
                            OptionValue={DobYear}
                            defaultValue={dob_yr}
                            name="DobYear"
                            id="DobYear"
                            myRef={validation({
                                required: "Please Select Year"
                            })}
                            validationMsg={validationMsg.DobYear && validationMsg.DobYear.message}
                        />
                        <i className="tick fa" style={{ display: 'none' }}></i>
                        {/* <span id="dobYear_err" className="error_msg"></span> */}

                    </div>
                    <span id="dob_final_err" className="error_msg"></span>

                    {notDobValid && (
                     <span id="dob_final_err" className="error_msg">
                      Invalid date of birth provided.
                     </span>
                    )}
                    
                </div>
            </fieldset>
        </div>
    )
}

export { DateOfBirth };