import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/Viewdocs_MB_UFGN_1/css/custom.scss";
import "../../assets/Viewdocs_MB_UFGN_1/css/id_upload.scss";
import "../../assets/Viewdocs_MB_UFGN_1/css/openbank.scss";
import "../../assets/Viewdocs_MB_UFGN_1/css/signature.scss";
import "../../assets/Viewdocs_MB_UFGN_1/css/style.scss";
import Loader2 from "../Includes/Layouts/MB_UFGN_1_Questionnaire/Loader2";

const Viewdocs_MB_UFGN_1 = () => {
  const history = useHistory();
  const [showLoad, setshowLoad] = useState("show");
  const getUuid = () => {
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_storage_uuid = localStorage.getItem("uuid");
    var uuid = query_uuid ? query_uuid : local_storage_uuid;
    return uuid;
  };
  const uuid = getUuid();

  useEffect(() => {
    setTimeout(() => {
      setshowLoad("hide");
      redirectPreview();
    }, 2000);
  }, []);

  const redirectPreview = () => {
    history.push("/preview_MB_UFGN_1?uuid=" + uuid);
  };
  return (
    <>

      <div className="viewdocsMB_UFGN_1">
        <div class="tms-wrap2 bg-main-pr">
          <div class="main text-center main-div ">
            <div class="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
              <img
                height="65"
                src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
                alt=""
              />
            </div>

            <Loader2 showLoad={showLoad} ></Loader2>


            {/* <div class="main-form-wraper">
              <div class="container">
                <div class="item-cent-flx ">
                  <div class="openbank-slide1 docum-slide1">
                    <div class="row">
                      <div class="col-lg-6 offset-lg-3">
                        <div class=" text-center">
                          <div style={{ fontSize: "120px;" }}>
                            <i class="bi bi-journals"></i>
                          </div>
                          <h1 class="  mt-md-2 mt-sm-2 mt-0 text-danger  ">
                            {" "}
                            Document Preview{" "}
                          </h1>

                          <div class="mb-2 mt-4">
                            <button
                              id="docmentPrevi"
                              class="btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 second"
                              onClick={redirectPreview}
                            >
                              {" "}
                              Proceed{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="  pt-3 pb-5 ">
                <img
                  height="50"
                  class="safe-secure-img"
                  src="assets/img/safe and secure.png"
                  alt=""
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default Viewdocs_MB_UFGN_1;
