import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import Button from "../../../../UI/Button";

const LatePayment = ({ className, onselectAnswer, addPending }) => {

  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="radio-box question animated fadeInUp">
              <h1 className="title "> Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company?</h1>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="btn-comm com-btn">
                    <RadioButtonQuest
                      key="No"
                      className="radio-button"
                      labelName="No"
                      labelClassName="com-btn btn-next w-100 mb-3"
                      onClick={labelclicked}
                      value="239"
                      name="question_82"
                      id="lateno"
                      dataQuestId={82}
                      dataAnsId={239}
                      dataAnsLabel="No"
                    />
                    <RadioButtonQuest
                      key="Yes"
                      className="radio-button"
                      labelName="Yes"
                      labelClassName="com-btn btn-next w-100 mb-3"
                      onClick={labelclicked}
                      value="238"
                      name="question_82"
                      id="lateyes"
                      dataQuestId={82}
                      dataAnsId={238}
                      dataAnsLabel="Yes"
                    />

                    <div class="text-center back">
                      <Button
                        className="btn btn-link mt-2 h2"
                        id="back1"
                        type="button"
                        buttonText="<< Previous"
                        onClick={() => addPending("latepayment")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LatePayment;
