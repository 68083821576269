import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

const AnalyzeModal = ({ modalClass }) => {
  return (
    <Modal
      show={modalClass}
      onHide={modalClass}
      backdrop="static"
      keyboard={false}
      className="formmodal flw-loader"
      contentClassName="modalcontent"
    >
      <Modal.Body className="modal-bordr">
        <div className="col-12 slide-pad text-center">
          <div className="form-group text-center" id="analize1">
            <img src="/assets/img/review.gif" alt="" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default AnalyzeModal;
