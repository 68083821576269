import React from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FormVAQ_OB_UB_3_1 from "../../Forms/FormVAQ_OB_UB_3_1.js";
import Header from "../../Includes/Layouts/VAQ_OB_UB_3_1/Header.js";
import Footer from "../../Includes/Layouts/VAQ_OB_UB_3_1/Footer.js";
import FbViewPixel from "../../../Utility/FbViewPixel";
import "../../../assets/VAQ_OB_UB_3_1/css/VAQ_OB_UB_3_1.scss";
import Section1 from "../../Includes/Layouts/VAQ_OB_UB_3_1/Section1.js";
import AccordSection from "../../Includes/Layouts/VAQ_OB_UB_3_1/AccordSection";

const VAQ_OB_UB_3_1 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="VAQ_OB_UB_3_1" />
      <FbViewPixel />
      <div className="VAQ_OB_UB_3_1">
        <Header />
        <section className="form-part" id="firstform">
          <div className="container nopad">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 nopad">
              <FormVAQ_OB_UB_3_1 />
            </div>
          </div>
        </section>
        <Section1 />
        <AccordSection />
        <Footer />
      </div>
    </>
  );
};

export default VAQ_OB_UB_3_1;