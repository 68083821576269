import React from "react";
import "../../../assets/MB_OBV1/scss/modules/MB_OBV1.scss";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import Header from "../../Includes/Layouts/MB_OBV1/Header";
import FormMB_OBV1 from "../../Forms/FormMB_OBV1";
import Section1 from "../../Includes/Layouts/MB_OBV1/Section1";
import AccordSection from "../../Includes/Layouts/MB_OBV1/AccordSection";
import Footer from "../../Includes/Layouts/Common/Footer";
import FbViewPixel from "../../../Utility/FbViewPixel";
const MB_OBV1 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="MB_OBV1" />
      <FbViewPixel />
      <div className="MB_OBV1">
        <Header />
        <section className="form-part" id="firstform">
          <div className="container nopad">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 nopad">
              <FormMB_OBV1 />
            </div>
          </div>
        </section>
        <Section1 />
        <AccordSection />
        <Footer />
      </div>

    </>
  );
};
export default MB_OBV1;