import React, { useState } from "react";
import TextArea from "../../../../UI/TextArea";
import Button from "../../../../UI/Button";

const FinanceProblem = ({
    className,
    validation,
    validationMsg,
    getValues,
    trigger,
    setError,
    onselectAnswer,
    addPending
}) => {

    const optionSelected = async (e) => {
        let errorFlag = 0;
        let result = await trigger("finance");
        if (!result) {
            errorFlag = 1;
            return false;
        } else {
            const fieldResult = getValues();
            var reason = fieldResult["finance"].trim();
            if (reason == "" || reason.length < 3) {
                setError("finance", {
                    type: "manual",
                    message: "Please Enter Valid Reason",
                });
                return false;
               } else {
            onselectAnswer(e);
        }
    }
    };


    return (
        <>
            <div className={className}>
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className={`radio-box question animated fadeInUp`}>
                            <h1 className="title">Please describe what really caused you to have problems in repaying / financially?</h1>
                            <div className="row mt-4">
                                <div className={`col-lg-12`}>
                                    <div className=" col-lg-12 mb-pd text-center ">
                                        <div className="btn-comm com-btn">
                                            <TextArea
                                                type="textarea"
                                                className="form-control"
                                                name="finance"
                                                validation={validation({
                                                    required: "Please Enter the Reason",
                                                    minLength: {
                                                        value: 3,
                                                        message: "Please Enter Valid Reason",
                                                    },
                                                })}
                                                validationMsg={
                                                    validationMsg.finance &&
                                                    validationMsg.finance.message
                                                }
                                            ></TextArea>
                                        </div>
                                        <div className="col-lg-12 mb-2 mt-2">
                                            <button
                                                type="button"
                                                className="btn-next w-100"
                                                onClick={optionSelected}
                                                data-quest-id={84}
                                                data-ans-id={null}
                                                data-ans-label={null}
                                                name="btn_qest_84"
                                            >{`Next`}</button>
                                        </div>
                                        <div class="text-center back">
                                            <Button
                                                className="btn btn-link mt-2 h2"
                                                id="back1"
                                                type="button"
                                                buttonText="<< Previous"
                                                onClick={() => addPending("financeproblem")}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default FinanceProblem;
