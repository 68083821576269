import React from "react";

const Loader = ({ showLoad }) => {
  return (
    <>
      <div id="myDiv" className={`space text-center space-top ${showLoad}`}>
        <img src="/assets/Followup/img/review.gif" alt="" style={{ width: '100px', marginTop: '30px' }} />
        <h3>Analysing your application....</h3>
      </div>
    </>
  );
};

export default Loader;