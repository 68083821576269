import React, { useRef, useState } from "react";
import CheckBox from "../../../UI/CheckBox";

const WereAlreadyPaying = ({
  clsName,
  reff,
  questArr,
  onselectAnswer,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  checklistOptions,
  setChecklistOptions,
}) => {
  const [showError, setshowError] = useState("hide");
  const [btndisabled, setBtndisabled] = useState(false);
  const checkboxRefs = useRef({});

  const labelclicked = async (e) => {
    let date_index = e.target.id;
    let selectedCount = checklistOptions.length;
    if (selectedCount == 0) {
      setBtndisabled(false);
      setshowError("show");
    } else {
      setBtndisabled(true);
      setChecklistOptions(checklistOptions);
      onselectAnswer(e);
    }
  };

  const onSelectOption = (e) => {
    console.log(e.target.value);
    if (e.target.checked === true) {
      setshowError("hide");
      checklistOptions.push({ id: +e.target.value });
      // checkBoxDisable(e.target.value);
    } else {
      let deselect = e.target.value;
      const decre = checklistOptions.map((OptionArray, index) => {
        if (deselect == OptionArray.id) {
          checklistOptions.splice(index, 1);
          if (checklistOptions.length == 0) {
            checkBoxEnable();
          }
        }
      });
    }
  };

  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    e.target.value = e.target.value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  let checkArray = questArr.answers;

  const questionId = questArr.questionId ? questArr.questionId : 0;

  const lenderCheckBox = checkArray.map((checArr, index) => {
    let heightcls = checArr.value == "104" ? "heightnewcls" : "";
    return (
      <li className={`adjustbalewidfull widthVAQ ${heightcls}`} key={checArr.value}>
        <div
          className="optionbtn"
          ref={(e) => (checkboxRefs.current[checArr.value] = e)}
        >
          <CheckBox
            dataId={`wereAlreadyPaying ${checArr.value}`}
            name="chkBankName[]"
            onChange={onSelectOption}
            className=""
            CheckBoxText={checArr.label}
            value={checArr.value}
          />
        </div>
      </li>
    );
  });

  const checkBoxDisable = (checkBoxId) => {
    // if (checkBoxId != 155) {
    //   checkArray.map((checArr, index) => {
    //     checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
    //     checkboxRefs.current[checArr.value].childNodes[1].classList.remove(
    //       "disabledcheckbox"
    //     );
    //   });
    //   checkboxRefs.current[155].childNodes[0].disabled = true;
    //   checkboxRefs.current[155].childNodes[1].classList.add("disabledcheckbox");
    // } else if (checkBoxId == 155) {
    //   checkArray.map((checArr, index) => {
    //     checkboxRefs.current[checArr.value].childNodes[0].disabled = true;
    //     checkboxRefs.current[checArr.value].childNodes[1].classList.add(
    //       "disabledcheckbox"
    //     );
    //   });
    //   checkboxRefs.current[155].childNodes[0].disabled = false;
    //   checkboxRefs.current[155].childNodes[1].classList.remove(
    //     "disabledcheckbox"
    //   );
    // }
  };

  const checkBoxEnable = () => {
    checkArray.map((checArr, index) => {
      checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
      checkboxRefs.current[checArr.value].childNodes[1].classList.remove(
        "disabledcheckbox"
      );
    });
  };

  return (
    <>
      <div
        className={`radio-box animated fadeInUp text-center ${clsName}`}
        ref={reff}
      >
        <h3>{questArr.question}</h3>
        <div className="col-lg-12 mb-2">
          <ul className="p-0 m-0 option-grid justify-content-center listStyle">{lenderCheckBox}</ul>
        </div>
        <div className="col-lg-12 mb-2 mt-2">
          <span className={`error_msg ${showError}`} id="email_err">
            Please select at least one
          </span>
          <button
            type="button"
            className="btn-next"
            onClick={labelclicked}
            data-quest-id={79}
            data-ans-id={null}
            data-ans-label={null}
            name="wereAlreadyPaying"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};
export default WereAlreadyPaying;
