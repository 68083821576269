import React, { useContext } from "react";
import { Api, UserVerifyApi } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";
import { useErrorWebhook } from "./useErrorWebhook";
import VisitorParamsContext from "../Contexts/VisitorParams";


export const useUserVerify = () => { 

  // const formdata = JSON.parse(localStorage.getItem("formData"));
  // const txtEmail= formdata.txtEmail ? formdata.txtEmail : "";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const uuid = visitorParameters.visitor_parameters.uuid;
  // console.log('user Verify uuid::' + uuid);
  const { ErrorWebhookTrigger } = useErrorWebhook();

  // const email = visitorParameters.email ? visitorParameters.email : txtEmail;
  let email='';
  if (visitorParameters.email) { 
    email=visitorParameters.email
  } else {
    const formdata = JSON.parse(localStorage.getItem("formData"));
    if(formdata){
      email = formdata.txtEmail? formdata.txtEmail: '';
    }
  }

// console.log("final email: "+ email);
  // const email = formdata.txtEmail;
  let is_test= false;
  if(email){
  const atIndex = email.indexOf('@');
  const domain = email.slice(atIndex + 1);
  is_test = domain === '922.com';
  }


  const getUserVerfyValidation = async (
    useruuid,
    firstname,
    lastname,
    postcode,
    address1,
    dobYr,
    dobMon,
    dobDy,
    addressLine2,
    postTown,
    country,
    title,
    addressLine3,
    addressLine4,
    county,
  ) => {
    const response = await UserVerifyApi.post(
      "api/personsearch?ProjectCode=" +
      EnvConstants.AppAdtopiaUPID +
      "&environment=" +
      EnvConstants.AppEnv +
      "&firstname=" +
      firstname +
      "&lastname=" +
      lastname +
      "&postcode=" +
      postcode +
      "&address_line_1=" +
      address1 +
      "&dob_y=" +
      dobYr +
      "&dob_m=" +
      dobMon +
      "&dob_d=" +
      dobDy +
      "&address_line_2=" +
      addressLine2 +
      "&address_line_3=" +
      addressLine3 +
      "&address_line_4=" +
      addressLine4 +
      "&post_town=" +
      postTown +
      "&country=" +
      country +
      "&county=" +
      county +
      "&title=" +
      title +
      "&uuid=" +
      useruuid +
      "&is_test=" +
      is_test

    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'USER VERIFY VALIDATION',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const saveUserVerify = async (visitor_parameters, response_data, message_type, data, query_string, request_data) => {
    const response = await Api.post("api/v1/data-ingestion-pipeline", {
      visitor_parameters,
      response_data,
      message_type,
      data,
      query_string,
      request_data
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'USER VERIFY RESPONSE',
        errorReason: error.message,
        errorCode: error.code,
        uuid: visitor_parameters.uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  }

  const saveFollowupUserVerify = async (followup_data, response_data, message_type, query_string, request_data) => {
    const response = await Api.post("api/v1/data-ingestion-pipeline", {
      followup_data,
      response_data,
      message_type,
      query_string,
      request_data
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'USER VERIFY FOLLOWUP RESPONSE',
        errorReason: error.message,
        errorCode: error.code,
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  }

  return { getUserVerfyValidation, saveUserVerify, saveFollowupUserVerify };
};
