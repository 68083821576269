import React, { useReducer, useState } from "react";
import InputButton from "../../../UI/InputButton";
import Button from "../../../UI/Button";
import Textfield from "../../../UI/TextField";
 import { usePostCodeAddress } from  "../../../../Hooks/usePostCodeAddress";
 import HiddenField from "../../../UI/HiddenField";
 import  AddressTextField from "../../../UI/AddressTextField";
 import  AddressSelectBox from "../../../UI/AddressSelectBox";
 import Modal from 'react-bootstrap/Modal';

 const initialStatepre = {
  lookUppre: true,
  nextClickpre: false,
  checkSelectpre: false,
  getDetailspre: [],
  getAddresspre: [],
  showManualAddresspre: "hide",
  getPostcodepre: "#",
  clickManualLinkpre: 0,
};
const PrevpostcodeReducer = (state, action) => {
  switch (action.type) {
    case "validationSucesspre": {
      return {
        ...state,
        lookUppre: action.payload.lookUp,
        nextClickpre: action.payload.next_click,
        getPostcodepre: action.payload.postcode,
        getAddresspre: action.payload.get_address,
        checkSelectpre: action.payload.check_select,
        showManualAddresspre: action.payload.manual_address,
      };
    }
    case "getExtraAddressDetailspre": {
      return {
        ...state,
        getDetailspre: action.payload.get_details,
        showManualAddresspre: action.payload.manual_address,
      };
    }
    case "showManualDivpre": {
      return {
        ...state,
        showManualAddresspre: "show",
        lookUppre: false,
        nextClickpre: true,
        checkSelectpre: false,
        clickManualLinkpre: action.payload.manual_click,
      };
    }
    case "resetaddresspre": {
      return {
        ...state,
        getDetailspre: [],
        getAddresspre: [],
        showManualAddresspre: "hide",
        lookUppre: true,
        nextClickpre: false,
        checkSelectpre: false,
      };
    }
    default:
      return state;
  }
};

const PreviousPostcode = ({
  validation,
  validationMsg,
  clearErrors,
  setError,
  slideChange,
  trigger,
  className,
  backClick,
  scrollView,
  show,
  setPostgetModalone,
  setPreviousAddressData,
}) => {
  const { getPostcodeValidation, getPostcodeAddress, getSplitPostcodeAddress } = usePostCodeAddress();
  const [state, dispatch] = useReducer(PrevpostcodeReducer, initialStatepre);
  const [postCodeAddress, setPostCodeAddress] = useState([]);
  const [postCodeEntry, setPostCodeEntry] = useState("0");
  const [NextModal, showsetNextModal] = useState(false);
  const handleClosepostModal = () => setPostgetModalone(false);
  
  const handleShowNext = () => {
    setPostgetModalone(false);
    showsetNextModal(true); 
  };

  const checkValidationPrev = async (e) => {
    e.preventDefault();
    dispatch({
      type: "resetaddresspre",
      get_address: [],
    });
    const txtPrevPostCode = await trigger("txtPrevPostCode");
    if (txtPrevPostCode) {
      await getPostcodeAddress(e.target.value).then(res => {
        if (res.data.status === "0") {
          setError("txtPrevPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        }
        if (res.data.length > 0) {
          dispatch({
            type: "validationSucesspre",
            payload: {
              lookUppre: false,
              next_click: true,
              postcode: e.target.value,
              get_address: res.data,
              check_select: true,
              manual_address: "hide",
            },
          });
        }
      }).catch(
        function () {
          setError("txtPrevPostCode", {
            type: "manual",
            message: "Please Enter Valid Postcode",
          });
        }
      );
    }
  };
  const getValue = async (e) => {
    e.preventDefault();
    // document.getElementById("address1").classList.remove("anim_ylw");
    // document.getElementById("NextButton").classList.add("anim_ylw");
    clearErrors([
      "txtHouseNumberprev",
      "txtAddress3Prev",
      "txtCountyprev",
      "txtTownprev",
      "txtCountrypreb",
    ]);
    if(e.target.value != ""){
      const getData = await getSplitPostcodeAddress(
        e.target.value,
        state.getPostcodepre
      );
      dispatch({
        type: "getExtraAddressDetailspre",
        payload: { get_details: getData.data, manual_address: "show" },
      });
      getData.data.prevAddressId= e.target.value;
      setPostCodeAddress(getData.data);
      clearErrors([
        "txtHouseNumberprev",
        "txtAddress3prev",
        "txtCountyprev",
        "txtTownprev",
        "txtCountryprev",
      ]);
    }
    else{
      dispatch({
        type: "getExtraAddressDetailspre",
        payload: { get_details: [], manual_address: "hide" },
      });
      setPostCodeAddress([]);
    }
  };

  const lookupValidationpre = async (e) => {
    await trigger("txtPrevPostCode");
   
  };

  const checkPostCodeEntry = () => {
    const addressLine1 = document.getElementById("txtHouseNumberPrev").value;
    const addressLine2 = document.getElementById("txtAddress3prev").value;
    const town = document.getElementById("txtTownprev").value;
    const county = document.getElementById("txtCountyprev").value;
    const country = document.getElementById("txtCountryprev").value;
    setPreviousAddressData(state.getDetailspre);
    if (
      postCodeAddress.length === 0 ||
      postCodeAddress.Country !== country ||
      postCodeAddress.County !== county ||
      postCodeAddress.Line1 !== addressLine1 ||
      postCodeAddress.Line2 !== addressLine2 ||
      postCodeAddress.Town !== town
    ) {
      setPostCodeEntry("1");
    }
  };

  const addressValidationPre = async (e) => {
    let errorFlag = 0;
    const txtPrevPostCode = await trigger("txtPrevPostCode");
    var manual_txtHouseNumberPrev;
    var manual_txtTown;
    var manual_txtCountry;
    var txtAddress;
    if (txtPrevPostCode) {
      manual_txtHouseNumberPrev = await trigger("txtHouseNumberPrev");
      txtAddress = await trigger("addressprev1");
    } else {
      document.getElementById("txtPrevPostCode").focus();
      return false;
    }
    if (manual_txtHouseNumberPrev) {
      manual_txtTown = await trigger("txtTownprev");
    } else {
      return false;
    }
    if (manual_txtTown) {
      manual_txtCountry = await trigger("txtCountryprev");
    } else {
      return false;
    }
    if (
      !txtPrevPostCode ||
      !manual_txtHouseNumberPrev ||
      !manual_txtTown ||
      !manual_txtCountry
    ) {
      errorFlag = 1;
    }

    if (state.clickManualLinkpre === 0) {
      const addressprev1 = await trigger("addressprev1");
      if (!addressprev1) {
        errorFlag = 1;
      }
    }
  if (errorFlag === 0) {
    checkPostCodeEntry();
    showsetNextModal(false);
    slideChange(e);
  } else {
    return false;
  }
};

const noPrevPostcode = async (e) => {
  handleClosepostModal();
  slideChange(e);
};

const closePrevPostcode = async (e) => {
  showsetNextModal(false);
  setPostgetModalone(false);
  slideChange(e);
};

const HiddenFields = () => (
  <>
    <HiddenField
      name="txtUdprn"
      inputId="txtUdprn"
      inputValue={state.getDetailspre !== "#" ? state.getDetailspre.Udprn : ""}
    />
    <HiddenField
      name="txtDeliveryPointSuffix"
      inputId="txtDeliveryPointSuffix"
      inputValue={
        state.getDetailspre !== "#" ? state.getDetailspre.deliverypointsuffix : ""
      }
    />
    <HiddenField
      name="txtPz_mailsort"
      inputId="txtPz_mailsort"
      inputValue={
        state.getDetailspre !== "#" ? state.getDetailspre.pz_mailsort : ""
      }
    />
    <HiddenField
      name="txtStreet"
      inputId="txtStreet"
      inputValue={
        state.getDetailspre.line_3 !== "#" ? state.getDetailspre.line_3 : ""
      }
    />
  </>
);
return(
  <>
       <div className={className}>
         <HiddenField
         name="pCode_EntryType"
         inputId="pCode_EntryType"
         inputValue={postCodeEntry}
       />
       </div>

       {/* ****POPUP START **** */}
      <Modal
        size="md"
        show={show}
        onHide={() => setPostgetModalone(false)}
        backdrop="static"
        centered
        >
          <Modal.Body>
            <h5 className="text-center mb-3">Did you have any <strong  style={{ backgroundColor:'#ff0',color: '#1483ce', padding:'0'}}> previous addresses</strong> in the past</h5>  
            <div className="input-group prevname-Btnpop justify-content-center" >
            <InputButton 
                className="btn next08  regNextBtnNew newfont"  
                name="no_prev_post" 
                value="NO"
                btnType="button"
                onClick={noPrevPostcode}
              /> 

              <InputButton
                className="btn next08  regNextBtnNew newfont"  
                name="yes" 
                value="YES"
                btnType="button"
                onClick={handleShowNext}/>
                </div>
          </Modal.Body>
        </Modal>

                   {/* ***** modal for Previous Postcode*** */}
       <Modal
            size="md"
            show={NextModal}
            onHide={handleClosepostModal}
            centered 
            backdrop="static"
            >
            <Modal.Body>  
            <h3 className="text-center">Enter your previous postcode</h3> 
                  <div className="input-section">
                        <div className="form-group mb-3">
                              <div className="input-group post">
                                      <AddressTextField
                                        type="text"
                                          placeholder="Enter previous postcode"
                                          name="txtPrevPostCode"
                                          id="txtPrevPostCode"
                                          className="form-control d-block"
                                          dataId="txtPrevPostCode"
                                          onBlur={checkValidationPrev}
                                          autoComplete="off"
                                          validation={validation({
                                          required: "Please Enter Postcode",
                                          pattern: {
                                              value: /^(([A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2})|([A-Z]{1,2}[0-9][A-Z] ?[0-9][A-Z]{2,3}))$/i,
                                              message: "Please Enter a Valid Postcode",
                                          },
                                          minLength: {
                                              value: 5,
                                              message: "Please Enter a Valid Postcode",
                                          },
                                          })}
                                          validationMsg={
                                          validationMsg.txtPrevPostCode && validationMsg.txtPrevPostCode.message
                                          }
                                      />
                            </div>
                        </div>
                          <div
                            className="form-group col-12"
                            style={state.lookUppre ? { display: "block" } : { display: "none" }}
                            >
                            <div style={{ width: "100%" }} className="main_1">
                                  <InputButton
                                    style={{ float: "none" }}
                                    name="next"
                                    className="btn next08  regNextBtnNew d-block"
                                    value="Lookup Address"
                                    btnType="button"
                                    onClick={lookupValidationpre}
                                  />
                            </div>
                            <div className="input-group col-12 justify-content-center">
                              <InputButton
                                className="btn next08  regNextBtnNew text-center-btngreen"
                                name="no_prev_post"
                                value="Next"
                                btnType="button"
                                onClick={closePrevPostcode}
                              />
                            </div>
                          </div>
                          <div className={`form-group col-lg-12 col-12 p-0 adj_width`} id="currentAddressCollapse"
                                style={state.checkSelectpre ? { display: "block" } : { display: "none" }}>
                                <AddressSelectBox
                                          className="form-control"
                                          OptionValue={state.getAddresspre}
                                          name="addressprev1"
                                          id="addressprev1"
                                          onChange={getValue}
                                          myRef={validation({ required: "Please select address" })}
                                          validationMsg={
                                              validationMsg.addressprev1 && validationMsg.addressprev1.message
                                          }
                                          />
                            </div>
                                        <div
                                              className="col-12 text-center"
                                              style={
                                              state.getDetailspre.length === 0
                                                  ? { display: "block" }
                                                  : { display: "none" }
                                              }
                                      ></div>
                            <div className="form-group mb-3"></div>
                            <div className={`slide8_add row ${state.showManualAddresspre}`}>
                                  <div className={`col-lg-12 form-group required-field adj_width mb-3`}>
                                      <AddressTextField
                                          type="text"
                                          placeholder="Address Line 1 *"
                                          name="txtHouseNumberPrev"
                                          id="txtHouseNumberPrev"
                                          className="form-control d-block"
                                          dataId="txtHouseNumberPrev"
                                          autoComplete="off"
                                          readonly={true}
                                          validation={validation({
                                          required: "Please Enter Address Line 1 p",
                                          })}
                                          validationMsg={
                                          validationMsg.txtHouseNumberPrev &&
                                          validationMsg.txtHouseNumberPrev.message
                                          }
                                          value={state.getDetailspre.line_1}
                                      />
                                      <i
                                          className="validate validate_success"
                                          aria-hidden="true"
                                          style={{ display: "none" }}
                                      ></i>
                                  </div>
                                  <div className={`col-lg-12 form-group adj_width mb-3`}>
                                          <AddressTextField
                                          type="text"
                                          placeholder="Address Line 2"
                                          name="txtAddress3prev"
                                          id="txtAddress3prev"
                                          className="form-control d-block"
                                          dataId="txtAddress3prev"
                                          autoComplete="off"
                                          readonly={true}
                                          validation={validation()}
                                          validationMsg=""
                                          value={state.getDetailspre.line_2}
                                          />
                                          <i
                                          className="validate validate_success"
                                          aria-hidden="true"
                                          style={{ display: "none" }}
                                          ></i>
                                  </div>
                                  <div className={`col-lg-12 form-group adj_width mb-3`}>
                                  <AddressTextField
                                      type="text"
                                      placeholder="County"
                                      name="txtCountyprev"
                                      id="txtCountyprev"
                                      className="form-control d-block"
                                      dataId="txtCountyprev"
                                      autoComplete="off"
                                      readonly={true}
                                      validation={validation()}
                                      validationMsg=""
                                      value={state.getDetailspre.county}
                                      />
                                      <i
                                      className="validate validate_success"
                                      aria-hidden="true"
                                      style={{ display: "none" }}
                                      ></i>
                              </div>
                              <div className={`col-lg-12 form-group adj_width mb-3`}>
                                  <AddressTextField
                                      type="text"
                                      placeholder="Town *"
                                      name="txtTownprev"
                                      id="txtTownprev"
                                      className="form-control d-block"
                                      dataId="txtTownprev"
                                      autoComplete="off"
                                      readonly={true}
                                      validation={validation({ required: "Please Enter Town" })}
                                      validationMsg={
                                      validationMsg.txtTownprev && validationMsg.txtTownprev.message
                                      }
                                      value={state.getDetailspre.town}
                                  />
                                  <i
                                      className="validate validate_success"
                                      aria-hidden="true"
                                      style={{ display: "none" }}
                                  ></i>
                              </div>
                              <div className={`col-lg-12 form-group adj_width mb-3`}>
                                <AddressTextField
                                        type="text"
                                        placeholder="Country *"
                                        name="txtCountryprev"
                                        id="txtCountryprev"
                                        className="form-control d-block"
                                        dataId="txtCountryprev"
                                        autoComplete="off"
                                        readonly={true}
                                        validation={validation({ required: "Please Enter Country" })}
                                        validationMsg={
                                        validationMsg.txtCountryprev && validationMsg.txtCountryprev.message
                                        }
                                        value={state.getDetailspre.country}
                                    />
                                    <i
                                        className="validate validate_success"
                                        aria-hidden="true"
                                        style={{ display: "none" }}
                                    ></i>
                              </div>
                          {Object.keys(state.getDetailspre).length > 0 ? <HiddenFields /> : null}
                  </div>
              
                   <div className="col-lg-12 col-12 text-center p-0"
                      style={state.nextClickpre ? { display: "block" } : { display: "none" }} >
                          <div className="form-group col-12" >
                                <InputButton
                                  name="postcode"
                                  className="btn next08  regNextBtnNew text-center-btngreen"
                                  id="postcode"
                                  value="Next"
                                  btnType="button"
                                  onClick={addressValidationPre}
                                  style={{ float: "none" }}
                                />
                          </div>
                    </div>
              </div>
          </Modal.Body>
    </Modal>







  </>
  )  
}
export default PreviousPostcode;
