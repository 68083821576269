import React from "react";
import TextField from "../../../UI/TextField";

const Questionnaire4 = ({
  clsName,
  reff,
  questArr,
  saveSlide4,
  name,
  btnName,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
}) => {
  const questionId = questArr.questionId ? questArr.questionId : 0;

  const selectClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();
    let question31Result = await trigger("question_31");
    if (!question31Result) {
      errorFlag = 1;
      return false;
    }

    let question32Result = await trigger("question_32");
    if (!question32Result) {
      errorFlag = 1;
      return false;
    }

    let question33Result = await trigger("question_33");
    if (!question33Result) {
      errorFlag = 1;
      return false;
    }

    // let question34Result = await trigger("question_34");
    // if (!question34Result) {
    //   errorFlag = 1;
    //   return false;
    // }

    let question35Result = await trigger("question_35");
    if (!question35Result) {
      errorFlag = 1;
      return false;
    }

    if (errorFlag === 0) {
      saveSlide4(e);
      //alert('success');
    } 
  };

  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value =
        "£" + e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };
  return (
    <>
      <div
        className={`radio-box question animated fadeInUp ${clsName}`}
        ref={reff}
      >
        <h3><b>{questArr.question}</b></h3>
        {/* <p className="text-center text-success">{questArr.question_sub}</p> */}
        <p className="text-center text-success"> Please confirm the following MONTHLY / ANNUAL income and expenditure details as <b><u>at the time of the car finance</u></b>&nbsp;as accurately as possible:-</p>
       

        <div className="input-group mb-3">
          <span className="input-group-text input-mobile" id="basic-addon1">
          Monthly / Annual Income
          </span>
          <TextField
            type="tel"
            className="form-control"
            placeholder="Approximate Monthly / Annual Income (Net of Taxes)"
            name="question_31"
            onChange={handleChange}
            validation={validation({
              required: "Please Enter Monthly / Annual Income",
              pattern: {
                value:
                  /^[ A-Za-z0-9_@./#&+-£]*$/,
                message: "Monthly / Annual Income is Invalid. Please Recheck",
              },
            })}
            validationMsg={
              validationMsg.question_31 && validationMsg.question_31.message
            }
          ></TextField>
        </div>

        <div className="input-group mb-3">
          <span className="input-group-text input-mobile" id="basic-addon1">
            Mortgage\Rent Expense
          </span>
          <TextField
            type="tel"
            className="form-control"
            placeholder="Mortgage\Rent Expense"
            name="question_32"
            onChange={handleChange}
            validation={validation({
              required: "Please Enter MortgageRent Expense",
              pattern: {
                value:
                /^[ A-Za-z0-9_@./#&+-£]*$/,
                message: "MortgageRent Expense is Invalid. Please Recheck",
              },
            })}
            validationMsg={
              validationMsg.question_32 && validationMsg.question_32.message
            }
          ></TextField>
        </div>

        <div className="input-group mb-3">
          <span className="input-group-text input-mobile" id="basic-addon1">
           Car Finance Expense
          </span>
          <TextField
            type="tel"
            className="form-control"
            placeholder="MoneyBarn Car Finance Monthly Expense"
            name="question_33"
            onChange={handleChange}
            validation={validation({
              required: "Please Enter Car Finance Expense",
              pattern: {
                value:
                /^[ A-Za-z0-9_@./#&+-£]*$/,
                message: "Car Finance Expense is Invalid. Please Recheck",
              },
            })}
            validationMsg={
              validationMsg.question_33 && validationMsg.question_33.message
            }
          ></TextField>
        </div>

        {/* <div className="input-group mb-3">
          <span className="input-group-text input-mobile" id="basic-addon1">
            Car Insurance
          </span>
          <TextField
            type="tel"
            className="form-control"
            placeholder="Car Insurance"
            name="question_34"
            onChange={handleChange}
            validation={validation({
              required: "Please Enter Car Insurance",
              pattern: {
                value:
                /^[ A-Za-z0-9_@./#&+-£]*$/,
                message: "Car Insurance is Invalid. Please Recheck",
              },
            })}
            validationMsg={
              validationMsg.question_34 && validationMsg.question_34.message
            }
          ></TextField>
        </div> */}

        <div className="input-group mb-3">
          <span className="input-group-text input-mobile" id="basic-addon1">
           Other Monthly Credit Commitments
          </span>
          <TextField
            type="tel"
            className="form-control"
            placeholder="eg: Typical Amount Paid to Credit Cards & Loans"
            name="question_35"
            onChange={handleChange}
            validation={validation({
              required: "Please Enter Other Monthly Credit Commitments",
              pattern: {
                value:
                /^[ A-Za-z0-9_@./#&+-£]*$/,
                message:
                  "Other Monthly Credit Commitments is Invalid. Please Recheck",
              },
            })}
            validationMsg={
              validationMsg.question_35 && validationMsg.question_35.message
            }
          ></TextField>
        </div>

        {/* <SelectBox
					className="form-select"
					OptionValue={questArr.answers}
					name="question_13"
					id="question_13"
					myRef={validation({ required: "Please select an amount range" })}
					validationMsg={
						validationMsg.question_13 && validationMsg.question_13.message
					}
				/>	 */}
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button
            type="button"
            className="btn-next"
            onClick={selectClick}
            data-quest-id={questionId}
            data-ans-id={null}
            data-ans-label={null}
            name={btnName}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default Questionnaire4;
