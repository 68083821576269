import React from "react";
import { Amount } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
const HighestBalance = ({
     validation,
    validationMsg,
    trigger,
    saveInputAns,
    backClick
}) => {
    const handleClick = async (e) => {
        let highestBalance = await trigger("highest_balance");
        let errorFlag = 0;
        if (!highestBalance) {
            errorFlag = 1;
        }
        if (errorFlag === 0) {
            saveInputAns(e);
        }
        else {
            return false;
        }
    };
    return (
        <>
            <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3" >
                <div className="row">
                    <div className="col-lg-8 mb-2">
                        <SelectBox
                            className="form-select"
                            OptionValue={Amount}
                            name="highest_balance"
                            id="highest_balance"
                            myRef={validation({
                                required: "Please select amount"
                            })}
                            validationMsg={validationMsg.highest_balance && validationMsg.highest_balance.message}
                        />
                    </div>
                    <div className="col-lg-4 mb-2 ">
                        <button
                            type="button"
                            className="qb10 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
                            id="nextscroll"
                            name="high_balance"
                            onClick={handleClick}
                            data-quest-id={53}
                        >
                            Next &#8594;  </button>
                    </div>
                </div>
                <p className="text-center bck-marwid">
                    <button
                      name="employmentsts"
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>
            </div>
        </>
    )
}
export default HighestBalance;