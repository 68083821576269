import React from "react";

const CheckBox = ({
  value,
  CheckBoxText,
  name,
  checked,
  onChange,
  dataId,
  className,
  labelClassName,
  labelText,
  onClick

}) => {
  return (
    <>
      <input
        type="checkbox"
        className={className}
        value={value}
        id={dataId}
        name={name}
        checked={checked}
        onChange={onChange}
        onClick={onClick}
      />
      { labelText}
      <div className="bank_name">
          <label htmlFor={dataId} className="label">
            <span>{CheckBoxText}</span>
          </label>
        </div>
    </>
  );
};
export default CheckBox;
