import React from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import "../../../assets/css/MB_UBRF_1_1.scss";
import Header from "../../Includes/Layouts/MB_UBRF_1_1/Header";
import Footer from "../../Includes/Layouts/Common/Footer";
import Section1 from "../../Includes/Layouts/MB_UBRF_1_1/Section1";
import AccordSection from "../../Includes/Layouts/Common/AccordSection";
import FormMB_UBRF_1_1 from "../../Forms/FormMB_UBRF_1_1";
import FbViewPixel from "../../../Utility/FbViewPixel";

const MB_UBRF_1_1 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="MB_UBRF_1_1" />
      <FbViewPixel />
      <div className="MB_UBRF_1_1">
        <Header />
        <section className="form-part" id="firstform">
          <div className="container nopad">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 nopad">
              <FormMB_UBRF_1_1 />
            </div>
          </div>
        </section>
        <Section1 />
        <AccordSection />
        <Footer />
      </div>
    </>
  );
};

export default MB_UBRF_1_1;
