import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect";
import "../../../../assets/Signature/css/signature.scss";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import SignatureCanvas from "react-signature-canvas";
import CheckBox2 from "../../../UI/CheckBox2";
import { useHistory } from "react-router-dom";
import { useFollowupUserSignature } from "../../../../Hooks/useFollowupUserSignature";
import Footer from "../../../Includes/Layouts/Common/Footer";
import Linkimg from "../../../../assets/FollowupS2/img/link_1a.png"
import LOA from "../../../../assets/pdf/MoneyBarn_LOA.pdf"
import TOB from '../../../../assets/pdf/Business_Terms_TMS.pdf';
import CFD from '../../../../assets/pdf/Moneybarn_FOS.pdf';
import QUESMB from "../../../../assets/pdf/Moneybarn_Questionnare.pdf";
import DSAR from '../../../../assets/pdf/MoneyBarn_DSAR.pdf';   

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const SignatureForm = ({ allFollowupData, removeFromPending }) => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveFollowupUserSignatureDSAR, isLoading } = useFollowupUserSignature();
  const [isChecked, setIsChecked] = useState(true);
  const [isChecked2, setIsChecked2] = useState(true);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const signatureWrapRef = useRef(null);

  const handleClick = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      var dsar = (isChecked2 == true) ? 'Checked' : 'NotChecked';
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        const response = await saveFollowupUserSignatureDSAR(
          signPad.getCanvas().toDataURL("image/png"),
          allFollowupData,
          "followup_user_signature",
          dsar
        );
        if (response.data.status === "Success") {
          removeFromPending("signature");
        }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    if (isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  useEffect(() => {
    if (isChecked2) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked2]);

  useEffect(() => {
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <>
      <div className="tms-wrap2 bg-main-pr tmssignature_2023">
        {/* <div className="main main-div signature-section p-0" > */}
        <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2  text-center">
          <img
            height="65"
            src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
            alt=""
          />
        </div>

        <div className="tmsversion_signature">
          <div className="signature-section">
            <div className="container">
              <div className="row ">
                <div className="offset-lg-2 col-lg-8 col-12 sign-question">
                  <h2>
                    Great news, you are eligible to make a claim! <br />
                    Sign here to start your claim online NOW.
                  </h2>
                  <div className="offset-lg-2 col-lg-8 col-12">
                    <ul>
                      <li>Sign using your finger, mouse or stylus</li>
                      <li>Keep it fully contained within the box</li>
                      <li>Ensure it is a true likeness of your signature</li>
                    </ul>
                  </div>
                  <div className="sign-div">
                    <form>
                      <div className="form-group">
                        <div className="signature_canvaseWrap">
                          <div>
                            <div
                              className="signature-pad anim_bt d-lg-block web-canvas"
                              ref={signatureWrapRef}
                            >
                              <SignatureCanvas
                                canvasProps={{
                                  id: "myID",
                                  width: signatureWrapWidth,
                                  height: signatureWrapHeight,
                                }}
                                ref={signPadRef}
                                onEnd={() => {
                                  if (!signPadRef.current.isEmpty()) {
                                    dispatch({
                                      type: "signPadChange",
                                      payload: { value: false },
                                    });
                                    dispatch({
                                      type: "validSignature",
                                      payload: { value: false },
                                    });
                                    document
                                      .getElementById("acceptterms_flp")
                                      .classList.add("anim_ylw");
                                  }
                                }}
                              />
                            </div>

                            {state.isAgreed && (
                              <div className="sign_msg col-12 text-center">
                                Please tick to show your authorisation for us to
                                proceed
                              </div>
                            )}
                            {state.isCanvasEmpty && (
                              <div
                                className="sign_msg col-12 text-center sign_msg"
                                id="signatures_required"
                              >
                                Signature is required!
                              </div>
                            )}
                            {state.isValid && (
                              <div
                                className="sign_msg col-12 text-center sign_msg"
                                id="signatures_valid"
                              >
                                Draw valid signature!
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="form-check my-3 d-flex">
                        <CheckBox2
                          dataId="acceptterms_flp"
                          anchorText=""
                          anchorStyle=""
                          href=""
                          onChange={(e) => setIsChecked(!isChecked)}
                          className="form-check-input me-3"
                          labelClassName="form-check-label sign-custom-control-label"
                          value={isChecked}
                          checked={isChecked}
                          CheckBoxText="By clicking submit you agree to us appending your electronic signature to the claim documents and confirming that you accept the terms of this contract for TMS Legal to represent you in your Responsible Lending claim, and that you agree to pay us 45% commission if the claim is successful. Simultaneously, you hereby authorize the processing of your personal data to fulfill your Data Subject Access Request (DSAR)."
                        />
                      </div>
                      {/* <div className="form-check my-3 d-flex">
                        <CheckBox2
                          dataId="acceptterms"
                          anchorText=""
                          anchorStyle=""
                          href=""
                          onChange={(e) => setIsChecked2(!isChecked2)}
                          className="form-check-input me-3"
                          labelClassName="form-check-label sign-custom-control-label"
                          checked={isChecked2}
                          CheckBoxText="I hereby authorize the processing of my personal data to fulfill my Data Subject Access Request (DSAR)."
                        />
                      </div> */}
                      <div className="form-check">
                        <p className="form-check-label sign-custom-control-label">
                          Preview your unsigned documents before signing above:
                        </p>
                        <ul className="list-1a">
                          <li><a href={`${LOA}`} target="_blank"><img className="link_img_1a" src={Linkimg} alt="" /> Letter of Authority</a></li>
                          <li><a href={`${TOB}`} target="_blank"><img className="link_img_1a" src={Linkimg} alt="" /> Terms of Business</a></li>
                          <li><a href={`${QUESMB}`} target="_blank"><img className="link_img_1a" src={Linkimg} alt="" />  Questionnaire</a></li>
                          <li><a href={`${CFD}`} target="_blank"><img className="link_img_1a" src={Linkimg} alt="" /> Complaint Form &amp; Declaration</a></li>
                          <li><a href={`${DSAR}`} target="_blank"><img className="link_img_1a" src={Linkimg} alt="" /> DSAR</a></li>                          
                        </ul>
                        
                      </div>
                      <input
                        type="button"
                        id=""
                        name=""
                        className="btn-clear"
                        value="Clear"
                        onClick={clearSign}
                      />
                      <input
                        className="btn btn-submit"
                        type="button"
                        onClick={handleClick}
                        value="Submit"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-lg-5  pt-3  text-center ">
              <img
                height="50"
                className="safe-secure-img mb-lg-3 "
                src="assets/img/safe and secure.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};
export default SignatureForm;
