import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import InputButton from "../../../../UI/InputButton";
import Modal from "react-bootstrap/Modal";

const LatePayment = ({ onselectAnswer, reff, display, trigger }) => {

  const labelclicked = async (e) => {
      onselectAnswer(e);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3> Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company?</h3>
          <RadioButtonQuest
            key="No"
            className="radio-button"
            labelName="No"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="239"
            name="question_82"
            id="lateno"
            dataQuestId={82}
            dataAnsId={239}
            dataAnsLabel="No"            
          />
          <RadioButtonQuest
            key="Yes"
            className="radio-button"
            labelName="Yes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="236"
            name="question_82"
            id="lateyes"
            dataQuestId={82}
            dataAnsId={238}
            dataAnsLabel="Yes"
          />
        </div>
      </div>
    </>
  );
};

export default LatePayment;
