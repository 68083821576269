import React, { useState, useEffect, useRef, useReducer } from "react";
import { useForm } from "react-hook-form";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import {
  questinnare1,
  questinnare2Very,
  questinnaire76Vanquis,
  questinnaire36Vanquis,
  wereUalreadyPaying
} from "../../../../Constants/Questions";
import IncomeExpenditure from "./Vanquis/IncomeExpenditure";
import QuestionnaireFlpQ5 from "../Followup/QuestionnaireFlpQ5";
import QuestionnaireFlpFANUpload from "../Followup/QuestionnaireFlpFANUpload";
import { useHistory } from "react-router-dom";
import QuestionnaireFlpAgreementNo from "./Vanquis/QuestionnaireFlpAgreementNo";
import AccountCloseYear from "./Vanquis/AccountCloseYear";
import HighestBalance from "./Vanquis/HighestBalance";
import HowManyTimes from "./Vanquis/HowManyTimes";
import HowOften from "./Vanquis/HowOften";
import MinimumPayment from "./Vanquis/MinimumPayment";
import LoanModal from "./Vanquis/LoanModal";
import MonthlyModal from "./Vanquis/MonthlyModal";
import QuestionnaireEmail from "./Vanquis/QuestionnaireEmail";
import EmailConfirmation from "./Vanquis/EmailConfirmation";
import QuestionnaireSupportDoc from "./Vanquis/QuestionnaireSupportDoc";
import { useUserVerify } from "../../../../Hooks/useUserVerify";
import { userMilestoneUpdate } from "../../../../Hooks/userMilestoneUpdate";
import { queryString } from "../../../../Utility/QueryString";
import QuestionnaireFooter from "./QuestionnaireFooter";
import QuestionnaireFlpAgreementNoModalV2 from "../FollowupS1/QuestionnaireFlpAgreementNoModalV2";
import IsStillActive from "./Vanquis/IsStillActive";
import RecentCreditIncrease from "./Vanquis/RecentCreditLimit";
import WhatYearHappen from "./Vanquis/WhatYearHappen";
import SoldDebtOn from "./Vanquis/SoldDebtOn";
import Bankruptcy from "./Vanquis/Bankrupty";
import Filed from "./Vanquis/Filled";
import { useSkippedLeads } from "../../../../Hooks/useSkippedLeads";
import { logDOM } from "@testing-library/react";
import EmployementStatus from "./Vanquis/EmployementStatus";
import AnalyzeModal from "./AnalayzeModal";
import ConfirmSitution from "./Vanquis/ConfirmSitution";
import IncomeConfirmationVAQ_UB_4 from "./Vanquis/IncomeConfirmationVAQ_UB_4";
import IncomeExpense_VAQ_UB_4 from "./Vanquis/IncomeExpense_VAQ_UB_4";
import Question36 from "./Vanquis/Question36_VAQ_UB_4";
import { Api } from "../../../../api/Api";
import BankName from "./Vanquis/BankName";
import { obLender } from "../../../../Hooks/useOblender";
import Struggle from "./Vanquis/Struggle";
import DidReceiveIncome from "./Vanquis/DidReceiveIncome";
import CardMonthlyIncome from "./Vanquis/CardMonthlyIncome";
import WereAlreadyPaying from "./Vanquis/WereAlreadyPaying";
import IncomeAndExpV2 from "./Vanquis/IncomeAndExpV2";
import Dependents from "./Vanquis/Dependents";
import OldCard from "./Vanquis/OldCard";
import LatePayment from "./Vanquis/LatePayment";
import Difficulties from "./Vanquis/Difficulties";
import FinanceProblem from "./Vanquis/FinanceProblem";
import Aware from "./Vanquis/Aware";
import SortCode from "./Vanquis/SortCode";
import BankAccNo from "./Vanquis/BankAccNo";
import { useUpdateMilestone } from "../../../../Hooks/useUpdateMilestone";
import CreditcardOpenYr from "./Vanquis/CreditcardOpenYr";

const initialState = {
  Bankrupty: "hide",
  claimfilled: "hide",
  struggle :"hide",
  confirm_sitution :"hide",
  creditCardOpenYr :"hide",
  slide_stillactive: "hide",
  slide_recentcreditlimit: "hide",
  soldon_dept: "hide",
  whatyear_happen: "hide",
  employment_status: "hide",
  showSlide1: "hide",
  showSlide1a: "hide",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide",
  // incomeConfirmation:"hide",
  did_receive_income:"hide",
  card_monthly_income:"hide",
  dependent_status: "hide",
  were_already_paying:"hide",
  income_and_expenditure:"hide",
  showSlide7: "hide",
  showSlide8: "hide",
  showSlide9: "hide",
  bankSelection: "hide",
  showSlide9a: "hide",
  showSlide10: "hide",
  loanModal: "hide",
  monthlyModal: "hide",
  oldcard:"hide",
  aware:"hide",
  latepayment:"hide",
  difficulty:"hide",
  financeproblem:"hide",
  sortcode:"hide",
  accnum:"hide",
};
const QuestionnaireReducer = (state, action) => {
  switch (action.type) {
    case "showSlideChange": {
      if (action.payload == "Bankrupty") {
        return {
          ...state,
          Bankrupty: "show",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 10,
        };
      } else if (action.payload == "claimfilled") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "show",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 15,
        };
      } 
      else if (action.payload == "struggle") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"show",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",          
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 18,
        };
      } else if (action.payload == "creditCardOpenYr") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"show",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",          
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 20,
        };
      } else if (action.payload == "slide_stillactive") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "show",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",          
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 20,
        };
      } else if (action.payload == "slide_recentcreditlimit") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "show",
          struggle :"hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 25,
        };
      } else if (action.payload == "soldon_dept") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "show",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 30,
        };
      } else if (action.payload == "whatyear_happen") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "show",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 35,
        };
      } else if (action.payload == "employment_status") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "show",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 40,
        };
      }  else if (action.payload == "slide1") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          employment_status: "hide",
          showSlide1: "show",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 50,
        };
      } else if (action.payload == "slide1a") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          showSlide1: "hide",
          showSlide1a: "show",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 55,
        };
      } else if (action.payload == "slide2") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "show",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 60,
        };
      } else if (action.payload == "slide3") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "show",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 62,
        };
      } else if (action.payload == "slide4") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "show",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 65,
        };
      } else if (action.payload == "slide5") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "show",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 70,
        };
      // } else if (action.payload == "incomeConfirmation") {
      //   return {
      //     ...state,
      //     Bankrupty: "hide",
      //     claimfilled: "hide",
      //     struggle :"hide",
      //     confirm_sitution :"hide",
      //     slide_stillactive: "hide",
      //     slide_recentcreditlimit: "hide",
      //     soldon_dept: "hide",
      //     whatyear_happen: "hide",
      //     employment_status: "hide",
      //     showSlide1: "hide",
      //     showSlide1a: "hide",
      //     showSlide2: "hide",
      //     showSlide3: "hide",
      //     showSlide4: "hide",
      //     showSlide5: "hide",
      //     incomeConfirmation:"show",
      //     did_receive_income:"hide",
      //     card_monthly_income:"hide",
      //     dependent_status: "hide",
      //     were_already_paying:"hide",
      //     income_and_expenditure:"hide",   
      //     showSlide7: "hide",
      //     showSlide8: "hide",
      //     showSlide9: "hide",
      //     oldcard: "hide",
      //     latepayment:"hide",
      //     difficulty:"hide",
      //     financeproblem:"hide",
      //     bankSelection: "hide",
      //     showSlide9a: "hide",
      //     showSlide10: "hide",
      //     loanModal: "hide",
      //     monthlyModal: "hide",
      //     percentage: 72,
      //   };
       } else if (action.payload == "did_receive_income") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"show",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 75,
        };
      }
      else if (action.payload == "card_monthly_income") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"show",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 78,
        };
      }
      else if (action.payload == "dependent_status") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "show",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 80,
        };
      }
      else if (action.payload == "were_already_paying") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"show",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 82,
        };
      }
      else if (action.payload == "income_and_expenditure") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"show",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 85,
        };
      } 
      else if (action.payload == "slide7") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "show",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 88,
        };
      } else if (action.payload == "slide8") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "show",
          showSlide9: "hide",
          showSlide9a: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 90,
        };
      } else if (action.payload == "confirm_sitution") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"show",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 91,
        };
      } else if (action.payload == "slide9") {
        window.scrollTo(0,0);
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "show",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 92,
        };
      }else if (action.payload == "oldcard") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide9a: "hide",
          oldcard: "show",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide10: "hide",
          loanModal: "hide",
          percentage: 93,
        };
      } else if (action.payload == "aware") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide9a: "hide",
          oldcard: "hide",
          aware:"show",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide10: "hide",
          loanModal: "hide",
          percentage: 93,
        };
      } else if (action.payload == "latepayment") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide9a: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"show",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide10: "hide",
          loanModal: "hide",
          percentage: 94,
        };
      } else if (action.payload == "difficulty") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide9a: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"show",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide10: "hide",
          loanModal: "hide",
          percentage: 95,
        };
      } else if (action.payload == "financeproblem") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide9a: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"show",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide10: "hide",
          loanModal: "hide",
          percentage: 96,
        };
      } else if (action.payload == "bankSelection") {
        window.scrollTo(0,0);
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "show",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 97,
        };
      } else if (action.payload == "sortcode") {
        window.scrollTo(0,0);
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"show",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 98,
        };
      }  else if (action.payload == "accnum") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"show",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 98,
        };
      } else if (action.payload == "slide9a") {
        window.scrollTo(0,0);
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "show",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 98,
        };
      } else if (action.payload == "slide10") {
        window.scrollTo(0,0);
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "show",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 100,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "expenseModal": {
      if (action.payload == "loanModal") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "show",
          monthlyModal: "hide",
        };
      } else if (action.payload == "monthlyModal") {
        return {
          ...state,
          Bankrupty: "hide",
          claimfilled: "hide",
          struggle :"hide",
          confirm_sitution :"hide",
          creditCardOpenYr :"hide",
          slide_stillactive: "hide",
          slide_recentcreditlimit: "hide",
          soldon_dept: "hide",
          whatyear_happen: "hide",
          employment_status: "hide",
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          // incomeConfirmation:"hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          income_and_expenditure:"hide",   
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          oldcard: "hide",
          aware:"hide",
          latepayment:"hide",
          difficulty:"hide",
          financeproblem:"hide",
          bankSelection: "hide",
          sortcode:"hide",
          accnum:"hide",
          showSlide9a: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "show",
        };
      } else {
        return {
          ...state,
        };
      }
    }
  }
};
const VanquisQuestionFormTwoV4 = ({
  lender,
  questionsPop,
  allFollowupData,
  pendingQuestionnaire,
  splitName,
  currentUuid,
  documentType,
  docType,
  sett2aValidation,
  setPendingStatus,
  accClsYear,
  splitData,
  t2aApiReqData,
  t2aStatus,
  setDivShow,
  setCardopenYear,
  cardOpenYear,
  setChecklistOptions,
  checklistOptions,
  isLoan,
}) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const splitForm = useRef(null);
  const [optionSelected] = useState([]);
  const [optionSelected76] = useState([]);

  const { getUserVerfyValidation, saveFollowupUserVerify } = useUserVerify();
  const { updateMilestone } = userMilestoneUpdate();
  const [agNoShow, setAgNoShow] = useState("hide");
  const { skippedLeads, skippedLeadsFLP } = useSkippedLeads();
  const [t2aModal,SetT2aModal]=useState(false);
  const { getLenderlist } = obLender();
  const [SelectBank, setSelectBank] = useState([]);
  const [lenderList, setLenderList] = useState([]);
  const [sortCode, setSortCode] = useState(false);
  const [accNum, setAccNum] = useState(false);
  const { updateMilestone: updateMilestoneNew } = useUpdateMilestone();
  const flp_source = allFollowupData.atp_sub6;


  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
    const getLender = async () => {
      try {
          const response = await getLenderlist();
          console.log(response.data.providers.length);
          const dataLength = response.data.providers.length;
  
          if (dataLength > 0) {
              console.log("here");
              setLenderList(response.data.providers);
          }
      } catch (error) {
          console.error("Error fetching lender list:", error);
      }
  }
  
  
  useEffect(() => {
    getLender();
  }, []);
  useEffect(() => {
    setShow(questionsPop);
  }, [questionsPop]);

  useEffect(() => {
    slideChange(pendingQuestionnaire);
  }, [pendingQuestionnaire]);

  const slideChange = (pendingArr) => {
    let pendingCount = pendingArr.length;
    if (pendingCount == 0) {
      // const responsepdf = Api.get(
      //   "/api/v1/vaq-pdf-generate?uuid=" + currentUuid,
      //   {}
      // );
      sendT2aApiReqDataAPI();
      /* if(splitName == "VAQ_UB_4" || splitName == "VAQ_UB_4_2"){
        sendT2aApiReqDataAPI();
      } 
      setShow(false);
      handleT2AResult();
      */
      
    }

    pendingArr.every((element) => {
      dispatch({ type: "showSlideChange", payload: element });
    });
  };
  const storeFollowupQuestionnire = async (question_data) => {
    const response = await saveFollowupUserQuestions(
      question_data,
      allFollowupData,
      "followup_question_store"
    );
  };

  const storeFlpUnqualifiedQuestionnire = async (question_data) => {
    const response = await saveFollowupUserQuestions(
      question_data,
      allFollowupData,
      "unqualified_question_store_flp"
    );
  };

  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    let flage = false;
    let question_data = {};
    const valuesreg = getValues();

    let employment = valuesreg["employment"];
    let problem = valuesreg["finance"];

    if (e.target.name == "btn_qest_2" && employment) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: employment,
        answer_text: anslabel,
        input_answer: "",
      };
      removeFromPending("employment_status");
    }
    if (e.target.name == "question_9") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("Bankrupty");
    }
    if (e.target.name == "question_29") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("struggle");
    }
    if (e.target.name == "question_30") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("claimfilled");
    }
    if (e.target.name == "question_54") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("slide3");
    }
    if (e.target.name == "aware") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("aware");
    }
    if (e.target.name == "question_71") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      if (selectedAns == 206) {
        removeFromPending("soldon_dept");
      } else if (selectedAns == 207) {
        removeFromPending("soldon_dept");
        removeFromPending("whatyear_happen");
      }
    } else if (e.target.name == "question_26") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      if (selectedAns == 96) {
        removeFromPending("slide_stillactive");
        removeFromPending("slide_recentcreditlimit");
      } else if (selectedAns == 95) {
        removeFromPending("slide_stillactive");
        removeFromPending("slide1a");
      }
    } else if (e.target.name == "question_81") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      if (selectedAns == 237) {
        removeFromPending("oldcard");
        removeFromPending("aware");
        removeFromPending("latepayment");
        removeFromPending("difficulty");
        removeFromPending("financeproblem");
      } 
      else if (selectedAns == 236) {
        removeFromPending("oldcard");
      }
    } else if (e.target.name == "question_82") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      if (selectedAns == 238) {
        removeFromPending("latepayment");
      } 
      else if (selectedAns == 239) {
        removeFromPending("latepayment");
        removeFromPending("difficulty");
        removeFromPending("financeproblem");
      }
    } else if (e.target.name == "question_83") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("difficulty");
    } else if (e.target.name == "btn_qest_84") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: "",
          input_answer: problem.trim(),
        };
      }
      removeFromPending("financeproblem");
    // }  else if (e.target.name == "question_75") {
    //   if (selectedAns && selectedQuest) {
    //     flage = true;
    //     question_data = {
    //       question_id: selectedQuest,
    //       option_id: selectedAns,
    //       answer_text: anslabel,
    //       input_answer: "",
    //     };
    //   }
    //   if (selectedAns == 213) {
    //     removeFromPending("incomeConfirmation");
    //   } else if (selectedAns == 214) {
    //     removeFromPending("incomeConfirmation");
    //     removeFromPending("did_receive_income");
    //     removeFromPending("card_monthly_income");
    //     removeFromPending("dependent_status");
    //     removeFromPending("were_already_paying");
    //     removeFromPending("income_and_expenditure");
    //   }
    }  else if (e.target.name == "question_55") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("slide4");
    } 
    else if (e.target.name == "question_77") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      if (selectedAns == 226) {
        removeFromPending("did_receive_income");
      } else if (selectedAns == 227) {
        removeFromPending("did_receive_income");
        removeFromPending("card_monthly_income");
      }
    } 
    else if (e.target.name == "question_56") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("slide5");
    } else if (e.target.name == "btn_qest_36") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("slide9");
    } else if (e.target.name == "btn_qest_76") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 76,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected76,
        };
      }
      removeFromPending("confirm_sitution");
    } else if (e.target.name == "wereAlreadyPaying") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 79,
          option_id: null,
          answer_text: anslabel,
          input_answer: checklistOptions,
        };
      }
      removeFromPending("were_already_paying");
    } else if (e.target.name == "question_61") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
        if (selectedAns == 162) {
          removeFromPending("slide8");
        }
      }
      removeFromPending("slide7");
    } else if (e.target.name == "question_42") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
        if (selectedAns == "124") {
          setAgNoShow("show");
        } else if (selectedAns == "123") {
          pushPageChange();          
          removeFromPending("slide9a");
        } else {
          removeFromPending("slide9a");
        }
      }
      /* if(splitName == "VAQ_UB_4"){
        sendT2aApiReqDataAPI();
      } */
    }  else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };
  const handleT2AResult = () => {
    const t2aResult = localStorage.getItem("t2a_result");

    if (t2aResult) {
      if (t2aResult == 1) {
        history.push("/view_docs_flp?uuid=" + currentUuid);
      } else {
        setDivShow("idUpload");
      }
    } else {
      if (t2aStatus == 0) {
        setDivShow("idUpload");
      } else {
        history.push("/view_docs_flp?uuid=" + currentUuid);
      }
    }
  };

  const saveIncomeExpenditure = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    const values = getValues();
    setCompletedQuestArr([...completedQuestArr, selectedQuest, 32, 33, 35]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    // var question_57 = splitForm.current["question_57"].value;
    // var question_58 = splitForm.current["question_58"].value;
    // var question_59 = values["question_59"];
    // var question_60 = values["question_60"];

    // if (question_57 == 151 && (question_59 == undefined || question_59 == "")) {
    //   dispatch({ type: "expenseModal", payload: "loanModal" });
    //   return false;
    // } 
    // else if (
    //   question_58 == 153 &&
    //   (question_60 == undefined || question_60 == "")
    // ) {
    //   dispatch({ type: "expenseModal", payload: "monthlyModal" });
    //   return false;
    // }
    //  else {
      var slide_array = [
        ["31", answer31],
        ["32", answer32],
        ["33", answer33],
        // ["57", question_57],
        // ["58", question_58],
      ];

      // if (question_59 != "") {
      //   slide_array.push(["59", question_59]);
      // }
      // if (question_60 != "") {
      //   slide_array.push(["60", question_60]);
      // }

      var question_data = {
        slide_4: slide_array,
      };
      removeFromPending("income_and_expenditure");
      storeFollowupQuestionnire(question_data);

      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    // }
  };

  const saveSlide80 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
  
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
  
    const values = getValues();
    let slide_array = [];
  
    if (checklistOptions.some((option) => option.id === 228)) {
      let answer228 = splitForm.current["question_228_229"].value;
      slide_array.push(["228", answer228]);
    }
    if (checklistOptions.some((option) => option.id === 229)) {
      let answer229 = splitForm.current["question_228_229"].value;
      slide_array.push(["229", answer229]);
    }
    if (checklistOptions.some((option) => option.id === 230)) {
      let answer230 = splitForm.current["question_230"].value;
      slide_array.push(["230", answer230]);
    }
    if (checklistOptions.some((option) => option.id === 231)) {
      let answer231 = splitForm.current["question_231"].value;
      slide_array.push(["231", answer231]);
    }
    if (checklistOptions.some((option) => option.id === 232)) {
      let answer232 = splitForm.current["question_232"].value;
      slide_array.push(["232", answer232]);
    }
    if (checklistOptions.some((option) => option.id === 233)) {
      let answer233 = splitForm.current["question_233"].value;
      slide_array.push(["233", answer233]);
    }
    if (checklistOptions.some((option) => option.id === 234)) {
      let answer234 = splitForm.current["question_234"].value;
      slide_array.push(["234", answer234]);
    }
    if (checklistOptions.some((option) => option.id === 235)) {
      let answer235 = splitForm.current["question_235"].value;
      slide_array.push(["235", answer235]);
    }

  
    var question_data = {
      slide_80: ["80",JSON.stringify(slide_array)],
    };
  
    removeFromPending("income_and_expenditure");
    storeFollowupQuestionnire(question_data);
  
    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
    if (existingIndex !== -1) {
      _existingEntries.splice(existingIndex, 1);
    }
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  const saveInputAns = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    const valuesreg = getValues();
    let highestBalance = valuesreg["highest_balance"];
    let sprt = valuesreg["value"];
    let agreementNo = valuesreg["question_45"];
    let modalagreementNo = valuesreg["modal_question_45"];
    let email = valuesreg["question_48"];
    let accCloseYr = valuesreg["question_52"];
    let creditOpenYr = valuesreg["creditopenyears"];
    let creditIncrease = valuesreg["CreditIncrease"];
    let whatYearhappen = valuesreg["whatYearThisHappen"];
    let cardMonthlyIncomes = valuesreg["cardMonthlyIncome"];
    let dependents = valuesreg["dependents"];
    let aware_issue = valuesreg["aware_reason"];
    let sort_code = sortCode;
    let bud_lender = SelectBank.value;
    let flage = false;
    let question_data = {};

    if (selectedQuest == "70" && creditIncrease) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: creditIncrease,
      };
      removeFromPending("slide_recentcreditlimit");
    }else if (e.target.name == "dontRecall") {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: "I Don't Recall",
      };
      removeFromPending("slide_recentcreditlimit");
    }

    if (selectedQuest == "72" && whatYearhappen) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: whatYearhappen,
      };
      removeFromPending("whatyear_happen");
    }

    if (selectedQuest == "45" && modalagreementNo) {
      pushPageChange();
      removeFromPending("slide9a");
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: modalagreementNo,
      };
      setAgNoShow("hide");
      //removeFromPending("slide9a");
      //pushPageChange();
    }
    if (selectedQuest == "45" && agreementNo) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: agreementNo,
      };
      removeFromPending("slide1");
    }

    if (selectedQuest == "52" && accCloseYr) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: accCloseYr,
      };
      removeFromPending("slide1a");
    }
    if (selectedQuest == "88" && creditOpenYr) {
      setCardopenYear(creditOpenYr);
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: creditOpenYr,
      };
      removeFromPending("creditCardOpenYr");
    }
    if (selectedQuest == "53" && highestBalance) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: highestBalance,
      };
      removeFromPending("slide2");
    }
    if (selectedQuest == "48" && email) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: email,
      };
      removeFromPending("slide8");
    } 
    if (e.target.name == "awarenxt") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: "",
          input_answer: aware_issue.trim(),
        };
      }
        removeFromPending("aware");
    }
    if (e.target.name == "sortcode") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: sort_code,
        };
      }
        removeFromPending("sortcode");
    }
    if (e.target.name == "accnum") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: accNum,
        };
      }
        removeFromPending("accnum");
    }
    if (e.target.name == "bankname" && bud_lender) {
      flage = true;
      question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: bud_lender,
      };
      removeFromPending("bankSelection");
    } if (selectedQuest == "78" && cardMonthlyIncomes) {
    flage = true;
    question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: cardMonthlyIncomes,
    };
    removeFromPending("card_monthly_income");
    }
     if (selectedQuest == "3" && dependents) {
    flage = true;
    question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: dependents,
    };
    removeFromPending("dependent_status");
    }
  
    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const unQualifiedAnswer = (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let flag = false;
    let question_data = {};

    if (selectedQuest && selectedAns){
      flag = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: "",
        input_answer: null,
      };
   }

    if (flag == true) {
      storeFlpUnqualifiedQuestionnire(question_data);
    }
  };


  const removeFromPending = (slidename) => {
    const index = pendingQuestionnaire.indexOf(slidename);
    if (index > -1) {
      pendingQuestionnaire.splice(index, 1);
    }
    console.log("pendingQuestionnaire  " + pendingQuestionnaire);
    slideChange(pendingQuestionnaire);
  };

  const pushPageChange = () => {
    removeFromPending("slide10");
  };

  const sendT2aApiReqDataAPI = () => {
    SetT2aModal(true);
    const useruuid = t2aApiReqData.uuid;
    const fName = t2aApiReqData.fName;
    const lName = t2aApiReqData.lName;
    const pCode = t2aApiReqData.pCode;
    const addressLine1 = t2aApiReqData.addressLine1;
    const dobDay = t2aApiReqData.day;
    const dobMonth = t2aApiReqData.month;
    const dobYear = t2aApiReqData.year;
    const addressLine2 = t2aApiReqData.addressLine2;
    const postTown = t2aApiReqData.postTown;
    const country = t2aApiReqData.country;
    const title = t2aApiReqData.title;
    const addressLine3 = t2aApiReqData.addressLine3;
    const addressLine4 = t2aApiReqData.addressLine4;
    const county = t2aApiReqData.county;
    if (
      useruuid &&
      fName &&
      lName &&
      pCode &&
      addressLine1 &&
      t2aStatus == null
    ) {
      (async () => {
        const response = await getUserVerfyValidation(
          useruuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYear,
          dobMonth,
          dobDay,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
          addressLine4,
          county
        );
        storeT2aResponse(response.data);
        if (response.data.status == "success") {
          updateMilestoneNew(currentUuid,"kyc_check",flp_source,1);
          updateMilestoneNew(currentUuid,"questions",flp_source);
          SetT2aModal(false);
          localStorage.setItem("t2a_result", 1);
          localStorage.setItem("split_name", splitName);
          history.push("/view_docs_flp?uuid=" + currentUuid);
        } else {
          updateMilestoneNew(currentUuid,"kyc_check",flp_source,2);
          updateMilestoneNew(currentUuid,"questions",flp_source);
          SetT2aModal(false);
          localStorage.setItem("t2a_result", 0);
          setDivShow("idUpload");
        }
      })();
    } else {
      localStorage.setItem("t2a_result", t2aStatus);
      SetT2aModal(false);
      let email_skip = localStorage.getItem("email_doc");
      if (useruuid && email_skip != 1) {
        (async () => {
          const response = await updateMilestone(
            useruuid,
            t2aStatus,
            allFollowupData
          );
        })();
      }

      if (t2aStatus == 1) {
        updateMilestoneNew(currentUuid,"questions",flp_source);
        history.push("/view_docs_flp?uuid=" + currentUuid);
      } else {
        updateMilestoneNew(currentUuid,"questions",flp_source);
        setDivShow("idUpload");
      }
    }
  };

  const storeT2aResponse = async (t2a_data) => {
    const response = await saveFollowupUserVerify(
      allFollowupData,
      t2a_data,
      "person_verify_store_flp",
      queryString,
      t2aApiReqData
    );
  };
  const SkippedLeads = async (type,slide) => {
    const skippedLeadsResponse = await skippedLeads(type, currentUuid, splitName);
    removeFromPending(slide);
  }
  const skipAgNo = async (e) => {
    removeFromPending("slide9a");
    pushPageChange();
    setAgNoShow("hide");
    const skippedLeadsResponse = await skippedLeadsFLP(
      "SkipAgreementNoFlp",
      allFollowupData,
      splitName
    );
  };

  return (
    <>
      <div className="QuestionnaireV7">
        <div className="formsection">
          <div className="container">
            <div className="row">
              <form
                ref={splitForm}
                name="split_form"
                id="user_form"
                method="POST"
                autoComplete="off"
                //onSubmit={handleSubmit}
              >
                <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12">
                  <div
                    className={`question1 animated fadeInUp space-top ${state.Bankrupty}`}
                    id="breakdown012"
                  >
                    <Bankruptcy
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      unQualifiedAnswer={unQualifiedAnswer}
                    />
                  </div>

                  <div
                    className={`question1 animated fadeInUp space-top ${state.claimfilled}`}
                    id="breakdown013"
                  >
                    <Filed
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      unQualifiedAnswer={unQualifiedAnswer}
                    />
                  </div>
                  <div
                    className={`question1 animated fadeInUp space-top ${state.struggle}`}
                    id="breakdown013"
                  >
                    <Struggle
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      unQualifiedAnswer={unQualifiedAnswer}
                    />
                  </div>
                  <div
                    className={`question1 animated fadeInUp space-top ${state.creditCardOpenYr}`}
                    id="breakdown1a"
                  >
                    <CreditcardOpenYr
                      saveInputAns={saveInputAns}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      splitData={splitData}
                      isLoan = {isLoan}
                    />
                  </div>
                  <div
                    className={`question1 animated fadeInUp space-top ${state.slide_stillactive}`}
                    id="breakdown01bb"
                  >
                    <IsStillActive
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                  </div>
                  <div
                    className={`question1 animated fadeInUp space-top ${state.slide_recentcreditlimit}`}
                    id="breakdown01aa"
                  >
                    <RecentCreditIncrease
                      saveInputAns={saveInputAns}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      splitData={splitData}
                    />
                  </div>

                  <div
                    className={`question1 animated fadeInUp space-top ${state.showSlide1a}`}
                    id="breakdown1a"
                  >
                    <AccountCloseYear
                      lender={lender}
                      saveInputAns={saveInputAns}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      splitData={splitData}
                      cardOpenYear={cardOpenYear}
                    />
                  </div>

                  <div
                    className={`question1 animated fadeInUp space-top ${state.soldon_dept}`}
                    id="breakdown01ac"
                  >
                    <SoldDebtOn
                      lender={lender}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      splitData={splitData}
                    />
                  </div>

                  <div
                    className={`question1 animated fadeInUp space-top ${state.whatyear_happen}`}
                    id="breakdown01ad"
                  >
                    <WhatYearHappen
                      lender={lender}
                      saveInputAns={saveInputAns}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      splitData={splitData}
                    />
                  </div>
                  <div
                    className={`question1 animated fadeInUp space-top ${state.employment_status}`}
                    id="breakdown01ae"
                  >
                    <EmployementStatus
                      lender={lender}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      splitData={splitData}
                    />
                  </div>

                  <div
                    className={`question1 animated fadeInUp space-top ${state.showSlide1}`}
                    id="breakdown01"
                  >
                    <QuestionnaireFlpAgreementNo
                      questArr={questinnare1}
                      labelclicked={onselectAnswer}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      saveInputAns={saveInputAns}
                    />
                  </div>

                  <div
                    className={`question1 animated fadeInUp space-top ${state.showSlide2}`}
                    id="breakdown1"
                  >
                    <HighestBalance
                      lender={splitData.lender}
                      saveInputAns={saveInputAns}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                  </div>
                  <div
                    className={`question1 animated fadeInUp space-top2 ${state.showSlide3}`}
                    id="breakdown2"
                  >
                    <HowManyTimes
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                  </div>
                  <div
                    className={`question1 animated fadeInUp space-top2 ${state.showSlide4}`}
                    id="breakdown3"
                  >
                    <HowOften
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                  </div>
                  <div
                    className={`question1 animated fadeInUp space-top2 ${state.showSlide5}`}
                    id="breakdown4"
                  >
                    <MinimumPayment
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                  </div>
                  {/* <div
                    className={`question1 animated fadeInUp space-top ${state.incomeConfirmation}`}
                    id="breakdown012"
                  >
                    <IncomeConfirmationVAQ_UB_4
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                  </div> */}
                  <div
                    className={`question1 animated fadeInUp space-top ${state.did_receive_income}`}
                    id="breakdown012"
                  >
                    <DidReceiveIncome
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      isLoan = {isLoan}
                    />
                  </div>
                  <div
                    className={`question1 animated fadeInUp space-top ${state.card_monthly_income}`}
                    id="breakdown012"
                  >
                    <CardMonthlyIncome
                      saveInputAns={saveInputAns}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      isLoan = {isLoan}
                    />
                  </div>
                  <div
                    className={`question1 animated fadeInUp space-top ${state.dependent_status}`}
                    id="breakdown01ae"
                  >
                    <Dependents
                      saveInputAns={saveInputAns}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      splitData={splitData}
                      clearErrors={clearErrors}
                    />
                  </div>

                  <div
                    className={`question1 animated fadeInUp space-top ${state.were_already_paying}`}
                    id="breakdown012"
                  >
                    <WereAlreadyPaying
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      checklistOptions={checklistOptions}
                      setChecklistOptions={setChecklistOptions}
                      questArr={wereUalreadyPaying}
                      isLoan={isLoan}
                    />
                  </div>
                  <div
                    className={`question6 animated fadeInUp space-top2 ${state.income_and_expenditure}`}
                    id="breakdown5"
                  >
                    <IncomeAndExpV2
                      questArr={wereUalreadyPaying}
                      labelclicked={onselectAnswer}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      saveSlide80={saveSlide80}
                      splitForm={splitForm}
                      checklistOptions={checklistOptions}  
                      isLoan={isLoan}   
                    />
                  </div>
                  <div
                    className={`question7 animated fadeInUp space-top ${state.showSlide7}`}
                    id="breakdown9"
                  >
                    <EmailConfirmation
                      onselectAnswer={onselectAnswer}
                      userEmail={splitData.email}
                    />
                  </div>
                  <div
                    className={`question8 animated fadeInUp space-top ${state.showSlide8}`}
                    id="breakdown9"
                  >
                    <QuestionnaireEmail
                      validation={register}
                      getValues={getValues}
                      trigger={trigger}
                      validationMsg={errors}
                      saveInputAns={saveInputAns}
                      lender={splitData.lender}
                    />
                  </div>
                  <div
                    className={`question9 animated fadeInUp space-top2 ${state.confirm_sitution}`}
                    id="breakdow10"
                  >
                    <ConfirmSitution
                      questArr={questinnaire76Vanquis}
                      onselectAnswer={onselectAnswer}
                      optionSelected76={optionSelected76}
                    />
                  </div>
                  <div
                    className={`question9 animated fadeInUp space-top2 ${state.showSlide9}`}
                    id="breakdow10"
                  >
                    <Question36
                      questArr={questinnaire36Vanquis}
                      onselectAnswer={onselectAnswer}
                      optionSelected={optionSelected}
                    />
                  </div>
                  <div
                    className={`question1 animated fadeInUp space-top ${state.oldcard}`}
                    id="breakdown013"
                  >
                    <OldCard
                     onselectAnswer={onselectAnswer}
                     validation={register}
                     validationMsg={errors}
                     getValues={getValues}
                     trigger={trigger}
                     setError={setError}
                     isLoan={isLoan}
                    />
                  </div>
                  <div
                    className={`question9 animated fadeInUp space-top2 ${state.aware}`}
                    id="breakdow10"
                  >
                  <Aware
                      clsName={state.showSlide15}  
                      // reff={(el) => (scrollRef.current[85] = el) }
                      validation={register}
                      clearErrors={clearErrors}
                      trigger={trigger}
                      validationMsg={errors}
                      getValues={getValues}
                      // setValue={setValue}
                      setError={setError}
                      onselectAnswer={onselectAnswer}
                      saveInputAns={saveInputAns}
                    />
                    </div>
                  <div
                    className={`question1 animated fadeInUp space-top ${state.latepayment}`}
                    id="breakdown013"
                  >
                    <LatePayment
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                  </div>
                  <div
                    className={`question1 animated fadeInUp space-top ${state.difficulty}`}
                    id="breakdown013"
                  >
                    <Difficulties
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      unQualifiedAnswer={unQualifiedAnswer}
                    />
                  </div>
                  <div
                    className={`question1 animated fadeInUp space-top ${state.financeproblem}`}
                    id="breakdown013"
                  >
                    <FinanceProblem
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                  </div>
                  <div
                    className={`question9 animated fadeInUp space-top2 ${state.bankSelection}`}
                    id="breakdow10"
                  >
                    <BankName
                      saveInputAns={saveInputAns}
                      validation={register}
                      getValues={getValues}
                      validationMsg={errors}
                      trigger={trigger}
                      SelectBank={SelectBank}
                      setSelectBank={setSelectBank}
                      clearErrors={clearErrors}
                      splitForm={splitForm}
                      setError={setError}
                      lenderList={lenderList}
                    />
                  </div>
                  <div
                    className={`question9 animated fadeInUp space-top2 ${state.sortcode}`}
                    id="breakdow10"
                  >
                    <SortCode
                     clsName={state.showSlide15}  
                     validation={register}
                     clearErrors={clearErrors}
                     trigger={trigger}
                     validationMsg={errors}
                     getValues={getValues}
                     onselectAnswer={onselectAnswer}
                     saveInputAns={saveInputAns}
                     SkippedLeads={SkippedLeads}
                     setSortCode={setSortCode}
                    />
                  </div>
                  <div
                    className={`question9 animated fadeInUp space-top2 ${state.accnum}`}
                    id="breakdow10"
                  >
                    <BankAccNo
                     clsName={state.showSlide15}  
                     validation={register}
                     clearErrors={clearErrors}
                     trigger={trigger}
                     validationMsg={errors}
                     getValues={getValues}
                     onselectAnswer={onselectAnswer}
                     saveInputAns={saveInputAns}
                     SkippedLeads={SkippedLeads}
                     setAccNum={setAccNum}
                    />
                  </div>
                  <div
                    className={`question9 animated fadeInUp space-top ${state.showSlide9a}`}
                    id="breakdow10"
                  >
                    <QuestionnaireSupportDoc
                      labelclicked={onselectAnswer}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      saveInputAns={saveInputAns}
                      splitData={splitData}
                    />
                  </div>

                  <div
                    className={`question10 animated fadeInUp space-top2 ${state.showSlide10} radio-box`}
                    id="breakdown11"
                  >
                    <QuestionnaireFlpFANUpload
                      questArr={questinnare2Very}
                      labelclicked={onselectAnswer}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      split_name={splitName}
                      docType={docType}
                      documentType={documentType}
                      allFollowupData={allFollowupData}
                      sett2aValidation={sett2aValidation}
                      pushPageChange={pushPageChange}
                    />
                  </div>
                </div>
                <div
                  className={`fade modal blackover space-top2 ${state.loanModal}`}
                  id="breakdown7"
                >
                  <LoanModal
                    saveIncomeExpenditure={saveIncomeExpenditure}
                    validation={register}
                    validationMsg={errors}
                    getValues={getValues}
                    trigger={trigger}
                    setError={setError}
                  />
                </div>
                <div
                  className={`fade modal blackover space-top2 ${state.monthlyModal}`}
                  id="breakdown8"
                >
                  <MonthlyModal
                    saveIncomeExpenditure={saveIncomeExpenditure}
                    validation={register}
                    validationMsg={errors}
                    getValues={getValues}
                    trigger={trigger}
                    setError={setError}
                  />
                </div>
              </form>
            </div>
          </div>
          <QuestionnaireFlpAgreementNoModalV2
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            saveInputAns={saveInputAns}
            agNoShow={agNoShow}
            setDivShow={setDivShow}
            skipAgNo={skipAgNo}
            setAgNoShow={setAgNoShow}
          />
          <AnalyzeModal modalClass={t2aModal} />

        </div>
      </div>
      <QuestionnaireFooter percentage={state.percentage} />
    </>
  );
};

export default VanquisQuestionFormTwoV4;