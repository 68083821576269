import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import InputButton from "../../../../UI/InputButton";
import Modal from "react-bootstrap/Modal";

const Filled = ({ onselectAnswer, reff, display, trigger,lender }) => {
  const labelclicked = async (e) => {
    if (e.target.value === "101") {
      setshowClaimModal(true);
    }
    if (e.target.value === "102") {
      onselectAnswer(e);
    }
  };
  const [claimModalfilled, setshowClaimModal] = useState(false);

  const handleCloseclaimModal = (e) => {
    setshowClaimModal(false);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>
            Have you, (or a company on your behalf), ever filed a claim with
            {""} {lender}
          </h3>
          <RadioButtonQuest
            key="No"
            className="radio-button"
            labelName="No"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="102"
            name="question_30"
            id="x"
            dataQuestId={30}
            dataAnsId={102}
            dataAnsLabel="No"
          />
          <RadioButtonQuest
            key="Yes"
            className="radio-button"
            labelName="Yes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="101"
            name="question_30"
            id="y"
            dataQuestId={30}
            dataAnsId={101}
            dataAnsLabel="Yes"
          />
        </div>
      </div>
      <Modal
        size="md"
        show={claimModalfilled}
        onHide={handleCloseclaimModal}
        centered
        backdrop="static"
        className="claimdeinial"
      >
        <Modal.Body>
          <h3 className="text-center">Sorry, we cannot accept your claim</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Filled;
