import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const CardOverSixYears = ({
  clsName,
  onselectAnswer,
  reff
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");

  const optionSelected = async (e) => {
    if (e.target.value === "236") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      onselectAnswer(e);
    }
    if (e.target.value === "237") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      onselectAnswer(e);
    }
  }
  return (
    <>
      <div
        className={`radio-box animated fadeInUp space-top space-top2 ${clsName}`}
        id="slide-23"
        ref={reff}
      >
        <h3><b>
        Did you take the card over 6 years ago?
        </b></h3>
        <RadioButtonQuest
          className="radio-buttons"
          labelName="Yes"
          labelClassName="option_but next-2"
          onClick={(e) => optionSelected(e)}
          name="card_over_six_years"
          dataQuestId={81}
          dataAnsId={236}
          value="236"
          id="oldcardYes"
        />
        <RadioButtonQuest
          className="radio-buttons"
          labelName="No"
          labelClassName="option_but next-2"
          onClick={(e) => optionSelected(e)}
          name="card_over_six_years"
          dataQuestId={81}
          dataAnsId={237}
          value="237"
          id="oldcardNo"
        />
      </div>
    </>
  )
}
export default CardOverSixYears;

