import React,{ useState, useContext } from "react";
import lap_icon from "../../../../assets/Quest_VAQ_4/img/lap-icon.png";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { queryString } from "../../../../Utility/QueryString";
import { useIdUpload } from "../../../../Hooks/useIdUpload";
import { useSkippedLeads } from '../../../../Hooks/useSkippedLeads'; 
 const SupportingDocUpload = ({
    clsName,
    onSelectAnswer,
    saveInputAns,
  getValues,
  trigger,
  backClick,
  pushPageChange,
  docType,
  split_name,
  uuid
 }) => {
    const { saveIdUpload, isLoading } = useIdUpload();
  const { skippedLeads } = useSkippedLeads();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedFileType, setSelectedFileType] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [document, setDocument] = useState(false);
  const [activeClass, setActiveClass] = useState("disabled");
  const [loader, setLoader] = useState(false);
  const [btnDisabled, SetBtnDisabled] = useState(false);

  const userfile = {
    preview: undefined,
    title: undefined,
  };

  const changeCameraHandler = (event) => {
    setActiveClass("camera");
    SetBtnDisabled(false);
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      // if (selectedFile) {

      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage(
          "The uploaded Doc type should be an image in jpeg,png or jpg"
        );
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage("Please choose a file less than 20mb");
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const changeFilesHandler = (event) => {
    SetBtnDisabled(false);
    setActiveClass('fileupload');
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      console.log(extension);
      let imgsize = event.target.files[0].size;
      let allowedExtensions = [
        "image/jpeg",
        "image/png",
        "application/zip",
        "application/msword",
        "application/pdf"
      ];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage("The uploaded Doc type should be an image in jpeg,png,jpg,pdf,doc, or docx");
      } else
        if (imgsize > 1024 * 1024 * 20) {
          setIsInValidfFile(true);
          setErrorMessage("Please choose a file less than 20mb");
        } else {
          setSelectedFileType(event.target.name);
          setIsInValidfFile(false);
          //_handleFileRead.bind(event.target.files[0]);
        }
      setDocument(false);

      if ("nationalIDCard_doc" == event.target.name) {
        setDocument(true);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image(binaryString);
  };

  const savedocument = async () => {
    if (selectedFile) {
      setIsInValidfFile(false);
      try {
        if (visitorParameters.data !== null || visitorParameters.data !== undefined) {
          let uploadData = '';
          uploadData = {
            imageFile: base64Image,
            captureType: selectedFileType.substring(
              selectedFileType.indexOf("doc")
            ),
            documentType: docType
          };

          const response = saveIdUpload(
            uploadData,
            visitorParameters,
            formParameters,
            queryString,
            'support_docsv2'
          );
          pushPageChange();
        }
      } catch (e) {
        console.warn("error");
        console.warn(e);
        setIsInValidfFile(true);
        setErrorMessage("Please try again");
      }
    } else {
      setIsInValidfFile(true);
      setErrorMessage("Please choose an option");
    }
  };

  const onSelectOption = (e) => {
    console.log(e.target.name);
    setIsInValidfFile(false);
    SetBtnDisabled(false);
    if (e.target.name == 'FinanceAgreementDocEmail') {
      setSelectedFile(undefined);
      setActiveClass('email');
    } else if (e.target.name == 'FinanceAgreementDocSkip') {
      setSelectedFile(undefined);
      setActiveClass('skip');
    } else if (e.target.name == 'closefile') {
      setActiveClass('disabled');
      //SetBtnDisabled(true);
      setSelectedFile(undefined);
    }
  };

  const onProceed = (e) => {
    // setLoader(true);
    console.log(e,activeClass);
    if (activeClass == "fileupload") {
      savedocument();
    } else if (activeClass == "email") {
      SkipSupportDocs("EmailDoc");
    } else if (activeClass == "skip") {
      SkipSupportDocs("SkipDoc");
    } else {
      setIsInValidfFile(true);
      setErrorMessage("Please choose an option");
    }
    console.log(isInValidfFile);
  }


  const SkipSupportDocs = async (type) => {
    SetBtnDisabled(true);
    const skippedLeadsResponse = await skippedLeads(type, uuid, split_name);
    if (skippedLeadsResponse.data.status == "Success") {
      pushPageChange();
    }
  }
    return (
        <>
         <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3">
    <div className="row">
      <div className="col-lg-12 mb-2">
        <ul className="p-0 m-0 option-grid">
          <li className="adjustbalewidfull">
            <div className={`optionbtn `}>
            <label htmlFor="supportingDoc1" className= {`${activeClass == 'fileupload' && 'activeclass'}`}>
            
              <input 
              type="file" 
              id="supportingDoc1" 
                name="FinanceAgreementDoc"
                onChange={changeFilesHandler}
                onClick={(e) => (e.target.value = null)}
              />
               <img src={lap_icon} height={24} alt /> UPLOAD file from your device</label>
            </div>
          </li>
          <li className="adjustbalewidfull">
            <div className={`optionbtn `}>
            <label htmlFor="supportingDoc2" className={`${activeClass == 'email' && 'activeclass'}`}>
              <input
                  type="radio"
                  id="supportingDoc2"
                  className="take_picture"
                  name="FinanceAgreementDocEmail"
                  onClick={onSelectOption}
                />
              EMAIL file to "documents@themoneysolicitor.com"
              </label>
            </div>
          </li>

          <li className="adjustbalewidfull">
            <div className={`optionbtn `}>
            <label htmlFor="supportingDoc3" className={`${activeClass == 'skip' && 'activeclass'}`}>  
            <input
                  type="radio"
                  className="take_picture"
                  id="supportingDoc3"
                  name="FinanceAgreementDocSkip"
                  onClick={onSelectOption}
                />
              SKIP for now and follow up ASAP
            </label>
            </div>
          </li>
        </ul>
        {isInValidfFile && <span className="error_msg mt-2">{errorMessage}</span>}
      </div>
      <div className="col-lg-12 mb-2 mt-2">
      {selectedFile &&
            <div className="d-flex justify-content-center">
              {selectedFile.name}
              <button className="btn btn-secondary btn-sm ms-2" name="closefile" onClick={onSelectOption}>
                Remove
              </button>
            </div>}
        <button 
        type="button"
        name="support_doc" 
        disabled={btnDisabled} 
        onClick={onProceed} 
        className={`qb17 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${activeClass == 'disabled' && 'disabledclass'}`} 
        >
            Proceed <i className="bi bi-arrow-right" />
            {/* {loader &&
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only"></span>
              </div>}  */}
              </button>
        <p className="text-center"><small>Not handy? Don't Worry. You can conveniently email the supporting documents to "documents@themoneysolicitors.com"</small></p>
      </div>
    </div>
    <p className="text-center bck-marwid">
                    <button
                      name="question_42"
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>
  </div>
        </>
    )
 }
 export default SupportingDocUpload;