import React, { useState } from 'react';
import TextField from '../../../UI/TextField';

const QuestionnaireFlpQ4 = ({ 
    questArr,
    reff, 
    display,
		validation,
		validationMsg,
		getValues,
		trigger,
		setError,
		saveSlide4
}) => {
	const questionId = questArr.questionId ? questArr.questionId : 0;

	const selectClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();
    let question31Result = await trigger("question_31");
    if (!question31Result) {
      errorFlag = 1;
      return false;
    }

    let question32Result = await trigger("question_32");
    if (!question32Result) {
      errorFlag = 1;
      return false;
    }

    let question33Result = await trigger("question_33");
    if (!question33Result) {
      errorFlag = 1;
      return false;
    }

    // let question34Result = await trigger("question_34");
    // if (!question34Result) {
    //   errorFlag = 1;
    //   return false;
    // }

    let question35Result = await trigger("question_35");
    if (!question35Result) {
      errorFlag = 1;
      return false;
    }

    if (errorFlag === 0) {
      saveSlide4(e);
      //alert('success');
    } 
  };

	const handleClick  = async (e) => {
		let registration_no = await trigger("question_10");
    if (registration_no) {
      const fieldResult = getValues();
      let carReg = fieldResult["question_10"];
      let carRegNo = carReg.replace(/\s+/g, "");
			
    } else {
      return false;
    }
	};

	const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value =
        "£" + e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

	return (
		<>
			<div style={{ 'display': display }}>
				<div className="question animated fadeInUp" ref={reff} >  
					<h3 style={{color: "#444", textAlign: "center"}} className="mb-2">{questArr.question}</h3>
					<p className="text-center text-success"> Please confirm the following MONTHLY income and expenditure details as <b><u>at the time of the car finance </u></b>&nbsp; as accurately as possible:-</p>
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 input-group mb-3">
						<span className="input-group-text input-mobile" id="basic-addon1">
							Monthly Income
						</span>
						<TextField
							type="tel"
							className="form-control"
							placeholder="Approximate Monthly Income (Net of Taxes)"
							name="question_31"
							onChange={handleChange}
							validation={validation({
								required: "Please Enter Monthly Income",
								pattern: {
									value:
										/^[ A-Za-z0-9_@./#&+-£]*$/,
									message: "Monthly Income is Invalid. Please Recheck",
								},
							})}
							validationMsg={
								validationMsg.question_31 && validationMsg.question_31.message
							}
						></TextField>
					</div>

					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 input-group mb-3">
						<span className="input-group-text input-mobile" id="basic-addon1">
							Mortgage\Rent Expense
						</span>
						<TextField
							type="tel"
							className="form-control"
							placeholder="Mortgage\Rent Expense"
							name="question_32"
							onChange={handleChange}
							validation={validation({
								required: "Please Enter MortgageRent Expense",
								pattern: {
									value:
									/^[ A-Za-z0-9_@./#&+-£]*$/,
									message: "MortgageRent Expense is Invalid. Please Recheck",
								},
							})}
							validationMsg={
								validationMsg.question_32 && validationMsg.question_32.message
							}
						></TextField>
					</div>

					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 input-group mb-3">
						<span className="input-group-text input-mobile" id="basic-addon1">
						Car Finance Expense
						</span>
						<TextField
							type="tel"
							className="form-control"
							placeholder="MoneyBarn Car Finance Monthly Expense"
							name="question_33"
							onChange={handleChange}
							validation={validation({
								required: "Please Enter Car Finance Expense",
								pattern: {
									value:
									/^[ A-Za-z0-9_@./#&+-£]*$/,
									message: "Car Finance Expense is Invalid. Please Recheck",
								},
							})}
							validationMsg={
								validationMsg.question_33 && validationMsg.question_33.message
							}
						></TextField>
					</div>

					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 input-group mb-3">
						<span className="input-group-text input-mobile" id="basic-addon1">
						Other Monthly Credit Commitments
						</span>
						<TextField
							type="tel"
							className="form-control"
							placeholder="eg: Typical Amount Paid to Credit Cards & Loans"
							name="question_35"
							onChange={handleChange}
							validation={validation({
								required: "Please Enter Other Monthly Credit Commitments",
								pattern: {
									value:
									/^[ A-Za-z0-9_@./#&+-£]*$/,
									message:
										"Other Monthly Credit Commitments is Invalid. Please Recheck",
								},
							})}
							validationMsg={
								validationMsg.question_35 && validationMsg.question_35.message
							}
						></TextField>
					</div>

					<div className="col-xl-12 col-lg-12 text-center p-0" >
						<button 
							type="button" 
							className="btn-next" 
							onClick={selectClick} 
							data-quest-id={questionId}
            	data-ans-id="31"
            	data-ans-label={null}
							name="slide_4"
							>{`Next`}</button>
					</div>
				</div>
			</div>

		</>
	)
}

export default QuestionnaireFlpQ4;