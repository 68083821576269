import React, { useState, useEffect, useRef, useReducer } from 'react';
import Modal from 'react-bootstrap/Modal';
import { BrowserView, MobileView } from 'react-device-detect';
import SignatureCanvas from "react-signature-canvas";
import Button from "../../../UI/Button";
import { useFollowupUserSignature } from "../../../../Hooks/useFollowupUserSignature";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
}
const signatureReducer = (state, action) => {
  switch (action.type) {
    case 'signPadChange': {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      }
    }
    case 'validSignature': {
      return {
        ...state,
        isValid: action.payload.value,
      }
    }
    default:
      return state;
  }
}
const SignatureModal = ({
  popDisplay,
  allFollowupData,
  setPendingStatus,
  setLoader,
  hideLoader,
  showUserName,
  setUserSign
}) => {
  const handleClose = () => setShow(false);
  const [show, setShow] = useState(false);
  const signPadRef = useRef();
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveFollowupUserSignature, isLoading } = useFollowupUserSignature();
  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  }
  const handleClick = async () => {

    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: 'signPadChange', payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        const response = await saveFollowupUserSignature(
          signPad.getCanvas().toDataURL("image/png"),
          allFollowupData,
          'followup_user_signature'
        )
        setPendingStatus({ status_btn: 'signature', status_flag: 'showTick' });
        setShow(false);
        setPendingStatus({ status_btn: 'pending_question', status_flag: 'showButton' });
        setUserSign(signPad.getCanvas().toDataURL("image/png"));
      } else {
        return dispatch({ type: 'validSignature', payload: { value: true } });
      }
      const timer = setTimeout(() => hideLoader(signLength), 500);

    } catch (e) {
      console.warn(e);
    }
  }
  useEffect(() => {
    setShow(popDisplay);
  }, [popDisplay])
  return (
    <Modal show={show} onHide={handleClose} dialogClassName="FollowupContainer" backdrop="static"
      keyboard={false}>
      <Modal.Header>
        <Modal.Title><b>{showUserName}</b>, Please use your finger to draw your electronic signature in order to proceed</Modal.Title>
      </Modal.Header>
      <div className="modal-body sign-sec">
        <ul>
          <li> Sign using your finger, mouse or stylus</li>
          <li> Keep it fully contained within the box</li>
          <li> Ensure it is a true likeness of your signature</li>
        </ul>
        <div className="signature_canvaseWrap">

          <div className="">
            <BrowserView>
              <SignatureCanvas
                canvasProps={{
                  width: 425, height: 235, className: "signature-pad anim_bt d-lg-block anim_ylw ",
                }}
                ref={signPadRef}
                onEnd={() => {
                  if (!signPadRef.current.isEmpty()) {
                    dispatch({ type: 'signPadChange', payload: { value: false } });
                    dispatch({ type: 'validSignature', payload: { value: false } });
                  }
                }}
              />
            </BrowserView>
            <MobileView>
              <SignatureCanvas
                canvasProps={{
                  className: "signature-pad anim_bt d-lg-block",
                }}
                ref={signPadRef}
                onEnd={() => {
                  if (!signPadRef.current.isEmpty()) {
                    dispatch({ type: 'signPadChange', payload: { value: false } });
                    dispatch({ type: 'validSignature', payload: { value: false } });
                  }
                }}
              />
            </MobileView>
            {state.isCanvasEmpty && (
              <p id="signatures_required" className="sign_msg">
                Signature is required!
              </p>
            )}
            {state.isValid && (
              <p id="signatures_valid" className="sign_msg">
                Draw valid signature!
              </p>
            )}
          </div>
        </div>
        <div className="signfooter" style={{ display: "block" }}>
          <Button
            type="button"
            className="btn-clear"
            id="clear"
            onClick={clearSign}
            buttonText="Clear">
          </Button>
          <Button
            type="button"
            className="submitpop signmain"
            id="clear"
            onClick={handleClick}
            buttonText="Submit">
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default SignatureModal;