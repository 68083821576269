import { useContext, useState } from "react";
import { MonthlyIncome } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import ReducerContext from "../../../../Contexts/ReducerContext";


export default function CardMonthlyIncome({
  validation,
  validationMsg,
  saveInputAns,
  trigger,
}) {
  const state = useContext(ReducerContext);
  const [pulse, setPulse] = useState(true);

  const optionSelected = async (e) => {
    let errorFlag;
    let result = await trigger("cardMonthlyIncome");
    if (result) {

      saveInputAns(e);
      errorFlag = 0;

    } else {
      errorFlag = 1;
      return;
    }
  };
  const handleChange = (e) => {
    const { value } = e.target;
    if (value !== "") {
      setPulse(false);
    }
  };
  return (
    <div
      className={`radio-box animated fadeInUp space-top space-top2 ${state.showSlide5_1}`}
      id="slide-24"
      // ref={(el) =>
      // (scrollRef.current["cardMonthlyIncome"] = el)
      // }
    >
      <h3>
        <b>When you took out your card what was your monthly income?</b>
      </h3>
      <div className={`col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0  ${pulse && 'anim_ylw2'}`}>
        <SelectBox
          className="form-select form-control mb-1"
          OptionValue={MonthlyIncome}
          name="cardMonthlyIncome"
          id="cardMonthlyIncome"
          myRef={validation({
            required: "Please Select Amount",
          })}
          validationMsg={
            validationMsg.cardMonthlyIncome &&
            validationMsg.cardMonthlyIncome.message
          }
          onChange={handleChange}
        />
      </div>
      <div className="col-xl-12 col-lg-12 text-center p-0">
        <button
          type="button"
          className="btn-next"
          onClick={optionSelected}
          data-quest-id={78}
          data-ans-id={null}
          data-ans-label={null}
          name="cardMonthlyIncome"
        >NEXT</button>
      </div>
    </div>
  );
}
