import React, {
  useState,
  useEffect,
  useContext
} from "react";
import { Api } from "../../../../api/Api";
//import "../../../../assets/OpenBanking/css/styleV1.scss";
import "../../../../assets/FollowupFos/css/questionnairefos.scss";
import { useHistory } from "react-router-dom";
import { useExternalFollowup } from "../../../../Hooks/useExternalFollowup";
import { queryString } from "../../../../Utility/QueryString";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import * as EnvConstants from "../../../../Constants/EnvConstants";
import { AppDomain } from "../../../../Constants/EnvConstants";
import Modal from "react-bootstrap/Modal";
import InputButton from "../../../UI/InputButton";


const OB_AnalyzeFos = () => {

  const history = useHistory();
  const [userDetails, setUserDetails] = useState({});
  const [userDetails1, setUserDetails1] = useState({});
  const { getVisitorID, getAccessToken, createCustomer, getProviders, getAuthGatewayUrl, storeApiResponse, OpenBankVisit, callBackGateWay , customerConsentsApi,milestoneChase} = useExternalFollowup();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [visitorId, setVisitorId] = useState(0);
  const [loader, setLoader] = useState(1);
  const [userData, setUserData] = useState();
  const [modal, setModal] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/api/v1/user-details-external?query_string=" + queryString,
        {}
      );
      if (response.data.status === "Success") {
        let user_data = response.data.response.user_data;
        let uuid = response.data.response.uuid;
        let lender = response.data.response.lender;
        let source = response.data.response.source;
        let lstSalutation = response.data.response.lstSalutation;
        let txtFName = response.data.response.txtFName;
        let txtLName = response.data.response.txtLName;
        let txtEmail = response.data.response.txtEmail;
        let txtPhone = response.data.response.txtPhone;
        let is_test = response.data.response.is_test;
        let token = response.data.response.user_data.atp_sub2;
        let pixel = response.data.response.user_data.pixel;
        let txtPostcode = response.data.response.txtPostcode;
        let txtAddressline1 = response.data.response.txtAddressline1;
        let txtAddressline2 = response.data.response.txtAddressline2;
        let txtAddressline3 = response.data.response.txtAddressline3;
        let town = response.data.response.town;
        let dob = response.data.response.dob;
        let userId = response.data.response.flg_id;
        let obcreduser = response.data.response.obcreduser;
        let obstat = response.data.response.obstat;
        let credstat = response.data.response.credstat;
        let obclosestat = response.data.response.obclosestat;
        let agreementDate = response.data.response.agreement_date;
        let lenders = response.data.response.providers;
        let isComplete = response.data.response.is_completed;

        localStorage.setItem('is_test', is_test);
        localStorage.setItem('user_data', JSON.stringify(user_data));
        localStorage.setItem('user_id', userId);
        localStorage.setItem('source', source);
        localStorage.setItem('uuid', uuid);

        if (uuid == null) {
          //history.push("/thankyou?uuid=" + uuid);
        }

        setUserData(user_data);
        setUserDetails1({
          uuid: uuid,
          lender: lender,
          source: source,
          lstSalutation: lstSalutation,
          txtFName: txtFName,
          txtLName: txtLName,
          txtEmail: txtEmail,
          txtPhone: txtPhone,
          is_test: is_test,
          token: token,
          pixel: pixel,
          txtPostcode: txtPostcode,
          txtAddressline1: txtAddressline1,
          txtAddressline2: txtAddressline2,
          txtAddressline3: txtAddressline3,
          town: town,
          dob: dob,
          user_id: userId,
          obcreduser: obcreduser,
          obstat: obstat,
          credstat: credstat,
          obclosestat,
          agreementDate: agreementDate
        });

        let visitor_id = localStorage.getItem('visitorId');

        if (visitor_id === undefined || visitor_id === null || visitor_id == "") {
          const visitor = await getVisitorID(
            uuid,
            user_data,
            'new'
          )

          if (visitor.data.status == "success") {
            setVisitorId(visitor.data.visitorId);
          }
          localStorage.setItem('visitorId', visitorId);

        } else {
          setVisitorId(visitor_id);
        }
      } 

    })();

  }, []);

  useEffect(() => {

    (async () => {

      let urlPortion = 'title=' + userDetails1.lstSalutation + '&fname=' + userDetails1.txtFName + '&lname=' + userDetails1.txtLName + '&email=' + userDetails1.txtEmail + '&phone=' + userDetails1.txtPhone + '&dob=' + userDetails1.dob + '&abodenumber=' + '&straddr=' + userDetails1.txtAddressline2 + '&street=' + '&city=' + userDetails1.town + '&zip=' + userDetails1.txtPostcode + '&clientRefId=' + userDetails1.uuid;

      localStorage.setItem('url_portion', urlPortion);
      localStorage.setItem('uuid', userDetails1.uuid);
      localStorage.setItem('firstname', userDetails1.txtFName);
      localStorage.setItem('credStat', userDetails1.credstat);
      localStorage.setItem('agreementDate', userDetails1.agreementDate);


      if ((userDetails1.obcreduser == '1') && (userDetails1.obstat == '' || userDetails1.obstat == '0' || userDetails1.obstat == null) && (userDetails1.credstat == '' || userDetails1.credstat == '0' || userDetails1.credstat == null)) {

        history.push("/thankyou?uuid=" + userDetails1.uuid);
        return false;
      }

    })();
  }, [userDetails1]);


  const handleClosePreModal = () => {
    setModal(false);
  }

  const environment = EnvConstants.AppEnv;
  const urlSearchParams = new URLSearchParams(window.location.search);

  // Get the value of the 'paramName' parameter
  const status = urlSearchParams.get('status');
  const uuid = urlSearchParams.get('uuid');
  const task_id = urlSearchParams.get("task_id");
  const query_source = urlSearchParams.get("atp_sub6");
  var source = (query_source) ? query_source : "live";
  var customer_id = localStorage.getItem("customer_id");
  var payload = '';
  var is_test = JSON.parse(localStorage.getItem("is_test"));
  // var visitorId = localStorage.getItem("visitorId");

  let fail_lender = localStorage.getItem("bud_lender");
  let local_storage_user_id = localStorage.getItem("user_id");
  const query_user_id = urlSearchParams.get('user_id');
  var user_id = query_user_id ? query_user_id : local_storage_user_id;
 // var source = localStorage.getItem('source');

  useEffect(() => {
    setTimeout(() => {
    (async () => {
    if (status == "success") {
      setModal(false);
      payload = { "milestone_key": "bank_connection", "source": source, "value": 1 };
      proceedOb(1);
      await milestoneChase({ uuid: uuid },user_id,"open_banking_milestone",1 ,source);
    } else {
      await milestoneChase({ uuid: uuid },user_id,"open_banking_milestone",2 ,source);
      setModal(true);
      setLoader(0);
      //proceedOb(2);
      payload = { "milestone_key": "bank_connection", "source": source, "value": 2 };
    }

     await storeApiResponse(visitorParameters.visitor_parameters, "op_milestone_update_ext", uuid, payload);
     await callBackGateWay(uuid, source, status, customer_id, task_id, is_test, visitorId);
    payload = { "milestone_key": "callback_url", "source": source };
     await storeApiResponse(visitorParameters.visitor_parameters, "op_milestone_update_ext", uuid, payload);
     const custConsentRes = await customerConsentsApi(uuid,is_test); 
     if (custConsentRes.data.status == "success") {
      await storeApiResponse({uuid,source},"op_customer_consents_ext", uuid, custConsentRes.data.consents);
    }

  })();
}, 2000);
  }, []);

  const proceedOb = async (obstatus) =>{
    setModal(false);
    setLoader(1);
    setTimeout(() => {
      (async () => {

        const response = await Api.get(
          "/api/v1/ob-lender-status-ext?uuid=" + uuid,
          {}
        );
        if (response.data.status === "Success") {
          let user_data = response.data.response.user_data;
          let uuid = response.data.response.uuid;
          let lender = response.data.response.lender;
          let source = response.data.response.source;
          let lstSalutation = response.data.response.lstSalutation;
          let txtFName = response.data.response.txtFName;
          let txtLName = response.data.response.txtLName;
          let txtEmail = response.data.response.txtEmail;
          let txtPhone = response.data.response.txtPhone;
          let is_test = response.data.response.is_test;
          let token = response.data.response.user_data.atp_sub2;
          let pixel = response.data.response.user_data.pixel;
          let bud_lender = response.data.response.bud_lender;
          let ob_consent = response.data.response.ob_consent;
          let user_id = response.data.response.user_id;

          localStorage.setItem('is_test', is_test);
         // localStorage.setItem('user_data', JSON.stringify(user_data));


          if (bud_lender != 0 || obstatus == 2) {
            
            if(obstatus == 2){
              bud_lender = fail_lender;
            }

            if(bud_lender == 0){
              if (ob_consent == 1){
                history.push("/credit_check_fos?" + queryString);
              }else{
                history.push("/credit_check_fos?" + queryString);
              }
            }

            let lender_array = [];
  
            if (environment == "prod" && is_test === true) {
              lender_array = [];
            } else {
              lender_array = [bud_lender]
            }

            setUserDetails({
              uuid: uuid,
              lender: lender,
              source: source,
              lstSalutation: lstSalutation,
              txtFName: txtFName,
              txtLName: txtLName,
              txtEmail: txtEmail,
              txtPhone: txtPhone,
              is_test: is_test,
              token: token,
              pixel: pixel,
              budLender: lender_array,
              ob_consent: ob_consent,
              user_id:user_id
            });
          } else if (ob_consent == 1) {
            history.push("/credit_check_fos?" + queryString);
          } else {
            history.push("/credit_check_fos?" + queryString);
          }

          setTimeout(() => {
            // setLoader(0);
          }, 1500);

          let visitor_id = localStorage.getItem('visitorId');

          if (visitor_id === undefined || visitor_id === null || visitor_id == "") {
            const visitor = await getVisitorID(
              uuid,
              user_data,
              'new'
            )

            if (visitor.data.status == "success") {
              setVisitorId(visitor.data.visitorId);
            }
            localStorage.setItem('visitorId', visitorId);

          } else {
            setVisitorId(visitor_id);
          }

        } else {
          // setLoader(0);
          history.push("/thankyou?uuid=" + response.data.response.uuid);
          return false;
        }
      })();
    }, 3000);
  }

  useEffect(async () => {
    const response1 = await getAccessToken(userDetails.uuid, source, userDetails.is_test, visitorId);
    if (response1.data.status == "success") {
      //(response.data.access_token_ID);
      let payload = { "milestone_key": "access_token", "source": source,"lender": userDetails.budLender[0] };
      await storeApiResponse(userDetails, "op_milestone_update_ext", userDetails.uuid, payload);
      const customerResponse = await createCustomer(userDetails.uuid, source, userDetails.lstSalutation, userDetails.txtFName, userDetails.txtLName, userDetails.txtEmail, userDetails.txtPhone, userDetails.is_test, visitorId)
      if (customerResponse.data.status == "success") {
        localStorage.setItem('customer_id', customerResponse.data.customer_ID);
        let payload = { "milestone_key": "customer_create", "customer_id": customerResponse.data.customer_ID, 'source': source,"lender": userDetails.budLender[0]};
        await storeApiResponse(userDetails, "op_customer_store_ext", userDetails.uuid, payload);
        let redirectUrl = AppDomain+"/ob-analyze_fos?uuid="+ userDetails.uuid +"&user_id="+ userDetails.user_id +"&queryString="+queryString ;
        var response2 = await getAuthGatewayUrl(userDetails.uuid, customerResponse.data.customer_ID, redirectUrl, source, userDetails.is_test, visitorId, userDetails.budLender);
        if (response2.data.status == "success") {
          payload = { "milestone_key": "lender_redirection", 'source': source, "lender": userDetails.budLender[0] };
          await storeApiResponse(userDetails, "op_milestone_update_ext", userDetails.uuid, payload);
          localStorage.setItem('bud_lender', userDetails.budLender);
          let url = response2.data.gateway_url;
          window.location.href = url;
          //setLoader(0)
        }
      }
    }
  }, [userDetails]);

  return (
    <div className="FollowupFosV1">
  <div className={`tms-wrap bg-main-pr text-center loader-wrap loader2 ${loader == 1 ? 'showload' : 'hide'}`}>
     
     <div className="loader-logo">
       <img src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt=""/>
     </div>
     <div className="loader">
       <img src="assets/img/review.gif" alt="" />
       <div className="loader-text">
         <p className=" text-analys">
           Analysing your Results</p>   
       </div>
   </div>
     </div>
    

<Modal size="md" show={modal} onHide={handleClosePreModal}  centered backdrop="static">
<Modal.Body>
  <h5 className="text-center  mb-3">Are you sure that you want to cancel Open Banking with {fail_lender}?</h5>
  <div className="prevname-Btnpop justify-content-center">
    

    <InputButton
      className="option_butb2   surNameYes  regNextBtnNew newfont "
      name="yes"
      value="YES"
      btnType="button"
      onClick={() => proceedOb(1)}
    />

<InputButton
      className="option_butb2   surNameNo  regNextBtnNew newfont"
      name="no_prev_name"
      value="NO"
      btnType="button"
      onClick={() =>proceedOb(2)}
    />
  </div>
  
</Modal.Body>
</Modal>
</div>

  );
};

export default OB_AnalyzeFos;
