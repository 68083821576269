import React, { useState, useEffect } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import TextArea from "../../../../UI/TextArea";
import Button from "../../../../UI/Button";
const DidYouAware = ({
    className,
    onselectAnswer,
    saveInputAns,
    getValues,
    splitForm,
    validation,
    validationMsg,
    addPending,
    setError,
    trigger,
    clearErrors,
    setValue,
}) => {
    const [checkedThree, setCheckedThree] = useState("unchecked");
    const [textShow, setTextShow] = useState('hide');

    const labelclicked = async (e) => {
        if (e.target.value == "242" || e.target.value == "243") {
            setCheckedThree('');
            setTextShow('hide');
            setValue("aware_reason", null);
            onselectAnswer(e);
        } else {
            if (textShow == 'show') {
                setCheckedThree('');
                setTextShow('hide');
                setValue("aware_reason", null);
            } else {
                setCheckedThree('checked');
                setTextShow('show');
            }
        }
        clearErrors();
    }

    useEffect(() => {
        if (checkedThree == "checked") {
            const textarea = document.getElementById('aware_reasons');
            if (textarea) {
                window.scrollTo(250, 250);
                textarea.focus();
            }
        }
    }, [checkedThree]);

    const optionSelected = async (e) => {
        let errorFlag = 0;
        let result = await trigger("aware_reason");
        if (!result) {
            errorFlag = 1;
            return false;
        } else {
            const fieldResult = getValues();
            var reason = fieldResult["aware_reason"].trim();
            if (reason == "" || reason.length < 3) {
                setError("aware_reason", {
                    type: "manual",
                    message: "Please Enter Valid Description",
                });
                return false;
            } else {
                saveInputAns(e);
            }
        }
    };

    return (
        <>
            <div className={className} >
                <div className="row">
                    <div className="col-lg-6 offset-lg-3">
                        <div className="radio-box question animated fadeInUp">
                            <h1 className="title ">
                                When did you become aware you could make a claim against MoneyBarn?
                            </h1>

                            <div className="row mt-4">
                                <div className={`col-lg-12`}>
                                    <div className=" col-lg-12 mb-pd text-center ">
                                        <div className="btn-comm com-btn">


                                            <RadioButtonQuest
                                                className="radio-button"
                                                name="didYouAware"
                                                id="242"
                                                labelClassName={`option_but  Payslips`}
                                                value="242"
                                                labelName="When TMS Legal contacted me"
                                                onClick={labelclicked}
                                                dataQuestId={85}
                                                dataAnsId={242}
                                            />


                                            <RadioButtonQuest
                                                className="radio-button"
                                                name="didYouAware"
                                                id="243"
                                                labelClassName={`option_but  Payslips`}
                                                value="243"
                                                labelName="I saw their ad online"
                                                onClick={labelclicked}
                                                dataQuestId={85}
                                                dataAnsId={243}
                                            />




                                            <RadioButtonQuest
                                                className="radio-button"
                                                name="didYouAware"
                                                id="244"
                                                labelClassName={`option_but  Payslips`}
                                                value="244"
                                                labelName="Other"
                                                onClick={labelclicked}
                                                dataQuestId={85}
                                                dataAnsId={244}
                                            />

                                            <div
                                                className={`${textShow == 'hide' ? 'hide' : ''}`}
                                                id="slide-32"
                                            >
                                           
                                                    <h3>
                                                        Please specify:
                                                    </h3>
                                                    <TextArea
                                                        type="textarea"
                                                        className="form-control"
                                                        name="aware_reason"
                                                        id="aware_reasons"
                                                        row={3}
                                                        // placeholder={"Please specify"}
                                                        validation={validation({
                                                            required: "Please Enter the Description",
                                                            minLength: {
                                                                value: 3,
                                                                message: "Please Enter Valid Description",
                                                            },
                                                        })}
                                                        validationMsg={
                                                            validationMsg.aware_reason &&
                                                            validationMsg.aware_reason.message
                                                        }
                                                    ></TextArea>
                                                    <div className="col-lg-12 mb-2 mt-2">
                                                        <button
                                                            type="button"
                                                            className="btn-next w-100"
                                                            id="nextscroll"
                                                            name="didYouAware"
                                                            onClick={optionSelected}
                                                            data-quest-id={85}
                                                        >
                                                            Next
                                                        </button>
                                                    </div>
                                              
                                            </div>
                                            <div class="text-center back">
                                                <Button
                                                    className="btn btn-link mt-2 h2"
                                                    id="back1"
                                                    type="button"
                                                    buttonText="<< Previous"
                                                    onClick={() => addPending("didYouAware")}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
            
                </>
                )
}
                export default DidYouAware;