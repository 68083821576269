import React from 'react';

const CheckboxOutLabel = ({
    id,
    value,
    name,
    className,
    labelName,
    labelClassName,
    onClick,
    onLabelClick,
    validation,
    labelCountString,
    order,
    dataAnsId,
    dataQuestId
  }) => {
    return (
      <>
        <input type="checkbox" className={className} name={name} id={id} value={value} onClick={onClick} data-ans-id={dataAnsId} data-quest-id={dataQuestId} data-ans-label={dataAnsId} ref={validation}/>
        <label htmlFor={id} className={labelClassName} data-ans-id={dataAnsId} data-quest-id={dataQuestId}><span>{labelCountString}</span>{labelName}</label>
      </>
    );
}

export default CheckboxOutLabel;