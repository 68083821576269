import React, {
  useState,
  useEffect,
  useRef,
  useReducer,
  useContext,
} from "react";
import { blacklist } from "../../Hooks/blacklist";
import BankName from "../Includes/Layouts/MB_UFGN_1/BankName";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { useForm } from "react-hook-form";
import { FormData } from "../../Utility/FormData";
import { useHistory } from "react-router-dom";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import Verify from "../Includes/Layouts/MB_UFGN_1/Verify";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import * as EnvConstants from "../../Constants/EnvConstants";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import KeeperDate from "../Includes/Layouts/MB_UFGN_1/KeeperDate";
import Questionnaire5 from "../Includes/Layouts/MB_UFGN_1/Questionnaire5";
import VehicleReg from "../Includes/Layouts/MB_UFGN_1/VehicleReg";
import PersonalDetails from "../Includes/Layouts/MB_UFGN_1/PersonalDetails";
import PostCode from "../Includes/Layouts/MB_UFGN_1/PostCode";
import ContactDetails from "../Includes/Layouts/MB_UFGN_1/ContactDetails";
import YearOfPossession from "../Includes/Layouts/MB_UFGN_1/YearOfPossession";
import BankStmt from "../Includes/Layouts/MB_UFGN_1/BankStmt";
import { MonthNameArray } from "../../Constants/Constants";
import { questinnaire76MB_2, questinnare2MB } from "../../Constants/Questions";
import ConfirmSituation from "../Includes/Layouts/MB_UFGN_1/ConfirmSituation";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

const FormMB_UFGN_1 = () => {
  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide4a: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
    showSlide7: "hide",
    showSlide8: "hide",
    showSlide9: "hide",
    showSlide10: "hide",
    radioId: "",
    prevState: "",
  };
  const [latest, setLatest] = useState(false);
  const [prevStill, setPrevStill] = useState("");
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "verify") {
          GtmDataLayer({
            question: "Hardships or struggle",
          });
          return {
            ...state,
            showSlide1: "hide",
            showSlide10: "show",
          };
        } else if (action.payload.clickedSlide.slide === "btn_qest_36") {
          GtmDataLayer({
            question: "scenario",
          });
          return {
            ...state,
            showSlide9: "show",
            showSlide2: "hide",
          };
        } else if (action.payload.clickedSlide.slide === "btn_qest_76") {
          GtmDataLayer({
            'question': 'situation statements'
          });
          return {
            ...state,
            showSlide10: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "question_74") {
          GtmDataLayer({
            question: "whether bank statement",
          });
          return {
            ...state,
            showSlide9: "hide",
            showSlide3: "show",
          };
        } else if (action.payload.clickedSlide.slide === "VehRegNo") {
          GtmDataLayer({
            question: "vehicle reg",
          });
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "keeperDate") {
          if (state.radioId != 0) {
            GtmDataLayer({
              question: "date of reg",
            });
            setLatest(false);
            return {
              ...state,
              showSlide4: "hide",
              showSlide4a: "show",
            };
          } else {
            GtmDataLayer({
              question: "date of reg",
            });
            setLatest(true);

            return {
              ...state,
              showSlide3: "hide",
              showSlide4: "hide",
              showSlide5: "show",
            };
          }
        } else if (action.payload.clickedSlide.slide === "skipSlide") {
          GtmDataLayer({
            question: "Vehicle reg",
          });
          return {
            ...state,
            showSlide3: "hide",
            showSlide4a: "show",
          };
        } else if (action.payload.clickedSlide.slide === "yearposession") {
          GtmDataLayer({
            question: "time of loan",
          });
          return {
            ...state,
            showSlide4a: "hide",
            showSlide5: "show",
          };
        } else if (action.payload.clickedSlide.slide === "bankname") {
          GtmDataLayer({
            question: "income and expense",
          });
          return {
            ...state,
            showSlide5: "hide",
            showSlide6: "show",
          };
        } else if (action.payload.clickedSlide.slide === "no_prev_name") {
          GtmDataLayer({
            question: "name DOB",
          });
          return {
            ...state,
            showSlide6: "hide",
            showSlide7: "show",
          };
        } else if (action.payload.clickedSlide.slide === "personal") {
          GtmDataLayer({
            question: "name DOB",
          });
          return {
            ...state,
            showSlide6: "hide",
            showSlide7: "show",
          };
        } else if (action.payload.clickedSlide.slide === "personalvalidate") {
          GtmDataLayer({
            question: "name DOB",
          });
          return {
            ...state,
            showSlide6: "hide",
            showSlide7: "show",
          };
        } else if (action.payload.clickedSlide.slide === "postcodeone") {
          GtmDataLayer({
            question: "current address",
          });
          return {
            ...state,
            showSlide7: "hide",
            showSlide8: "show",
          };
        } else if (action.payload.clickedSlide.slide === "no_prev_post") {
          return {
            ...state,
            showSlide7: "hide",
            showSlide8: "show",
          };
        } else if (action.payload.clickedSlide.slide === "postcode") {
          return {
            ...state,
            showSlide7: "hide",
            showSlide8: "show",
          };
        } else {
          return state;
        }
      }
      case "previousSlides": {
        if (action.payload.previousSlide.slide == "prev") {
          return {
            ...state,
            showSlide2: "hide",
            showSlide10: "show",
          };
        } else if (action.payload.previousSlide.slide == "prev76") {
          return {
            ...state,
            showSlide10: "hide",
            showSlide1: "show",
          };
        } else if (action.payload.previousSlide.slide == "prevHardship") {
          return {
            ...state,
            showSlide9: "show",
            showSlide3: "hide",
          };
        } else if (action.payload.previousSlide.slide == "prevBankstmt") {
          return {
            ...state,
            showSlide2: "show",
            showSlide9: "hide",
          };
        } else if (action.payload.previousSlide.slide == "prevReg") {
          return {
            ...state,
            showSlide3: "show",
            showSlide4: "hide",
            showSlide4a: "hide",
          };
        } else if (action.payload.previousSlide.slide == "prevKeep") {
          if (state.radioId != 0) {
            return {
              ...state,
              showSlide4: "show",
              showSlide4a: "hide",
            };
          } else {
            return {
              ...state,
              showSlide3: "show",
              showSlide5: "hide",
              showSlide4a: "hide",
            };
          }
        } else if (action.payload.previousSlide.slide == "back05") {
          return {
            ...state,
            showSlide4a: "show",
            showSlide5: "hide",
          };
        } else if (action.payload.previousSlide.slide == "prevKeeper") {
          if (prevStill == "back05") {
            return {
              ...state,
              showSlide4a: "show",
              showSlide5: "hide",
            };
          } else {
            return {
              ...state,
              showSlide4: "show",
              showSlide5: "hide",
            };
          }
        } else if (action.payload.previousSlide.slide == "back10") {
          return {
            ...state,
            showSlide5: "show",
            showSlide6: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back11") {
          return {
            ...state,
            showSlide6: "show",
            showSlide7: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back12") {
          return {
            ...state,
            showSlide7: "show",
            showSlide8: "hide",
          };
        } else {
          return state;
        }
      }
      default: {
        return state;
      }
    }
  };
  const {updateMilestone} = useUpdateMilestone();
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const scrollfrmv2div = useRef([]);
  const scrollRef = useRef([]);
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const [previousName, setPreviousName] = useState("");
  const [previousAddressData, setPreviousAddressData] = useState({});
  const [Year, setYear] = useState("");
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const [optionSelected] = useState([]);
  const [optionSelected76] = useState([]);
  const history = useHistory();
  // const [quest, setQuest] = useState("");
  const splitForm = useRef(null);
  const [splitName, setSplitName] = useState();
  const [SelectBank, setSelectBank] = useState([]);
  const [blacklisted, setBlacklisted] = useState([]);
  const field = "pid";
  const { saveDataIngestion, formDataIngestion, isLoading } =
    useDataIngestion();
  const { queryString } = useContext(QueryStringContext);
  const saveVehicleDetails = (vehicleData) => {
    setVehicle(vehicleData);
  };
  const savetotalCarCheck = (totalCarCheck) => {
    settotalCarCheck(totalCarCheck);
  };
  const { SaveQuestionnaire } = useQuestionnaire();
  useEffect(() => {
    let responsesss = [];
    (async () => {
      const response = await getBlacklist();
      let dataLength = response.data.response.length;
      responsesss = response.data.response;
      if (dataLength > 0) {
        setBlacklisted(response.data.response);
      }
    })();
  }, []);

  const formSubmit = () => {
    GtmDataLayer({
      question: "email",
    });

    const form = splitForm.current;
    const values = getValues();
    const formData = values;

    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      const txtAddress3 = form["txtAddress3"].value;
      const txtDistrict = form["txtDistrict"].value;
      formData.txtDistrict = txtDistrict;
      formData.txtAddress3 = txtAddress3;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }

    //  ****Previous Name****
    formData.Previousname = previousName;

    //***previous_address ****/
    formData.previous_postcode = previousAddressData.postcode;
    formData.previous_address_line1 = previousAddressData.line_1;
    formData.previous_address_line2 = previousAddressData.line_2;
    formData.previous_address_line3 = previousAddressData.line_3;
    formData.previous_address_county = previousAddressData.county;
    formData.previous_address_town = previousAddressData.town;
    formData.previous_address_country = previousAddressData.country;
    formData.previous_district = previousAddressData.district;
    formData.previous_address_id = previousAddressData.prevAddressId;

    formData.question_63 = formData.livein;

    formData.question_26 = formData.stillactive;
    formData.question_27 = formData.possession;
    formData.question_2 = formData.employment;
    formData.question_3 = formData.dependent;
    formData.question_29 = formData.verify;
    formData.question_30 = formData.verify2;
    formData.question_9 = formData.bankruptcy;
    formData.question_36 = optionSelected;
    formData.question_76 = optionSelected76;
    formData.question_67 = "yes";
    formData.question_74 = formData.question_74;
    formData.regNoSkip = skip;
    if (skip == false) {
      formData.question_10 = formData.carRegNo;
      formData.question_11 = formData.keeperDate;
    }
    if (latest == false) {
      formData.question_65 = formData.YearOfPossession.concat(
        " ",
        formData.MonthOfPossession
      );
    }

    formData.lender = [SelectBank];
    formData.validKeeperDate = validKeeperDate;

    formData.bud_lender = SelectBank.value;

    formData.page_name = "MB_UFGN_1";
    formData.pCode_manual = form["pCode_EntryType"].value;
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      // let dobMonth = formData.DobMonth ? MonthNameArray[formData.DobMonth] : '';
      localStorage.setItem("fName", formData.txtFName);
      localStorage.setItem("lName", formData.txtLName);
      localStorage.setItem("pCode", formData.txtPostCode);
      localStorage.setItem("addressLine1", formData.txtHouseNumber);
      localStorage.setItem("dobYr", formData.DobYear);
      localStorage.setItem("dobMon", formData.DobMonth);
      localStorage.setItem("dobDy", formData.DobDay);
      localStorage.setItem("addressLine2", formData.txtAddress2);
      localStorage.setItem("postTown", formData.txtTown);
      localStorage.setItem("country", formData.txtCounty);
      localStorage.setItem("title", formData.lstSalutation);
      localStorage.setItem("addressLine3", formData.txtAddress3);
      // localStorage.setItem("addressLine4", addressLine4);
      localStorage.setItem("county", formData.txtCounty);
      localStorage.setItem(
        "district",
        formData.txtDistrict ? formData.txtDistrict : ""
      );

      let UcFname =
        formData.txtFName.charAt(0).toUpperCase() + formData.txtFName.slice(1);

      localStorage.setItem("UcFname", UcFname);

      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("queryString", queryString);
      const formSUbmitResult = formDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "split_form_submit",
        "MB_UFGN_1",
        queryString,
        visitorParameters.data
      );

      updateMilestone(visitorParameters.visitor_parameters.uuid,"lead","live");

      let blacklistFlag = false;
      blacklisted.map((value, index) => {
        if ("email" == value.type && value.value == formData.txtEmail) {
          blacklistFlag = true;
        }

        if ("phone" == value.type && value.value == formData.txtPhone) {
          blacklistFlag = true;
        }
      });

      if (blacklistFlag) {
        window.scrollTo(0, 0);
        history.push(
          "/thankyou-bl?uuid=" + visitorParameters.visitor_parameters.uuid
        );
      } else {
        if (queryString != null) {
          if (queryString.indexOf("&" + field + "=") !== -1) {
            if (EnvConstants.AppConversionPixel === "true") {
              history.push("/fbpixel?split_name=MB_UFGN_1"); // fb fixel firing
            } else {
              window.scrollTo(0, 0);
              history.push(
                "/signatureMB?split_name=MB_UFGN_1&uuid=" +
                  visitorParameters.visitor_parameters.uuid
              );
            }
          } else {
            window.scrollTo(0, 0);
            history.push(
              "/signatureMB?split_name=MB_UFGN_1&uuid=" +
                visitorParameters.visitor_parameters.uuid
            );
          }
        } else {
          window.scrollTo(0, 0);
          history.push(
            "/signatureMB?split_name=MB_UFGN_1&uuid=" +
              visitorParameters.visitor_parameters.uuid
          );
        }
      }
      // }
    }
    // pushPageChange();
  };
  const storeQuestionnire = async (question_data) => {
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      formdata
    );
  };
  const { getBlacklist } = blacklist();
  const [skip, setSkip] = useState(false);
  const [lenderList, setLenderList] = useState([]);
  const [validKeeperDate, setValidKeeperDate] = useState("");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [vehicle, setVehicle] = useState("");
  const [totalCarCheck, settotalCarCheck] = useState("");
  const [completedQuestArr, setCompletedQuestArr] = useState([]);

  const checkCarRegistration = (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    // let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    // let anslabel = e.target.getAttribute("data-ans-label");
    let selectedAns = null;
    let anslabel = splitForm.current["carRegNo"].value;
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    if (selectedQuest) {
      let question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: anslabel,
        input_answer: anslabel,
      };
      storeQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
    setClickedSlide({ slide: e.target.name });
  };

  const slideChange = (e) => {
    if (e.target.name == "keeperDate") {
      let keeper_date = e.target.value;
      let keeper_year = keeper_date.trim().split(" ").pop();
      setYear(keeper_year);
      state.radioId = e.target.id;
      if (e.target.id != 0) {
        setPrevStill("back05");
      } else {
        setPrevStill("");
      }
    }

    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setClickedSlide({ slide: e.target.name });
  };
  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();
  const previousSlideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };

  const slideSkip = () => {
    setClickedSlide({ slide: "skipSlide" });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: "previousSlides", payload: { previousSlide } });
    }
  }, [previousSlide]);

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  return (
    <>
      <form
        ref={splitForm}
        name="split_form"
        id="user_form"
        method="POST"
        autoComplete="off"
      >
        <div ref={scrollfrmv2div}>
          <Verify
            className={`col-12 top_minus ${state?.showSlide1}`}
            textHeading="Have you experienced any hardships or struggle in making the repayments to MoneyBarn on time while still meeting all your other commitments?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />
          <Questionnaire5
            clsName={`col-12 top_minus_1 ${state?.showSlide2}`}
            reff={(el) => (scrollRef.current[36] = el)}
            questArr={questinnare2MB}
            onselectAnswer={slideChange}
            validation={register}
            validationMsg={errors}
            getValues={getValues}
            trigger={trigger}
            setError={setError}
            optionSelected={optionSelected}
            backClick={previousSlideChange}
          />
          <ConfirmSituation
            clsName={`col-12 top_minus_1 ${state.showSlide10}`}
            reff={(el) => (scrollRef.current[76] = el)}
            questArr={questinnaire76MB_2}
            onselectAnswer={slideChange}
            validation={register}
            validationMsg={errors}
            getValues={getValues}
            trigger={trigger}
            setError={setError}
            backClick={previousSlideChange}
            optionSelected76={optionSelected76}
          />
          <BankStmt
            clsName={`col-12 top_minus_1 ${state.showSlide9}`}
            textHeading="Did Moneybarn ask you to provide any of the below when you bought the car?"
            reff={(el) => (scrollRef.current[74] = el)}
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />
          <VehicleReg
            className={`col-12 top_minus ${state.showSlide3}`}
            textHeading="Enter your Vehicle Registration to begin your refund check?"
            uuid={uuid}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            page_name={splitName}
            saveVehicleDetails={saveVehicleDetails}
            savetotalCarCheck={savetotalCarCheck}
            checkCarRegistration={checkCarRegistration}
            slideSkip={slideSkip}
            backClick={previousSlideChange}
            setSkip={setSkip}
            setLenderList={setLenderList}
          />
          <KeeperDate
            className={`col-12 top_minus ${state.showSlide4}`}
            textHeading="When did you start the finance?"
            uuid={visitorParameters.visitor_parameters.uuid}
            vehicle={vehicle}
            totalCarCheck={totalCarCheck}
            savetotalCarCheck={savetotalCarCheck}
            setValidKeeperDate={setValidKeeperDate}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
          />
          <YearOfPossession
            className={`col-12 top_minus ${state.showSlide4a}`}
            textHeadingLine1="Time you taken out the loan"
            validation={register}
            clearErrors={clearErrors}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
            trigger={trigger}
            validationMsg={errors}
            Year={Year}
          />
          <BankName
            className={`col-12 top_minus ${state.showSlide5}`}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            Year={Year}
            SelectBank={SelectBank}
            setSelectBank={setSelectBank}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
            lenderList={lenderList}
            skip={skip}
          />
          <PersonalDetails
            className={`col-12 top_minus ${state.showSlide6}`}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            getValues={getValues}
            splitForm={splitForm}
            slideChange={slideChange}
            backClick={previousSlideChange}
            setError={setError}
            setPreviousName={setPreviousName}
          />
          <PostCode
            className={`col-12 top_minus ${state.showSlide7}`}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            getValues={getValues}
            splitForm={splitForm}
            scrollView={scrollfrmv2div}
            slideChange={slideChange}
            backClick={previousSlideChange}
            setPreviousAddressData={setPreviousAddressData}
            GtmDataLayer={GtmDataLayer}
          />
          <ContactDetails
            className={`col-12 top_minus ${state.showSlide8}`}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            getValues={getValues}
            // setQuest={setQuest}
            formSubmit={formSubmit}
            backClick={previousSlideChange}
          />
        </div>
      </form>
    </>
  );
};

export default FormMB_UFGN_1;
