import React, { useEffect, useState } from "react";
import RadioButton from "../../../UI/RadioButton";

const AnalyzeModal = ({
  modalClass,
  modalClass1,
  modalClass2,
  modalClass3,
  validation,
  textHeading,
}) => {
  return (

    <div
      id="analayzeModal" className={`${modalClass}  analyseClass fadetoggle`}
      tabIndex="-1"
      role="dialog"
      aria-labelledby="TermsModalLabel"
      aria-hidden="true"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-lg formmodal" id="analyzemodal" role="dialog" aria-labelledby="exampleModalLabel" data-backdrop="static">
        <div className="modal-dialog" role="document">
          <div className="modal-content" id="modal-content">
            <div className="modal-body">
              <div id="slide6" className={`col-12 slide-pad text-center slide6 ${modalClass1}`} >
                <div className="form-group text-center" id="analize1">
                  <h2>Analysing Your Results</h2>
                  <img src="/assets/img/review.gif" alt="" />
                </div>
              </div>
              <div id="slide6nxt" className={`col-12 slide-pad text-center slide6nxt animated fadeInUp ${modalClass2}`} >
                <div className="form-group text-center" id="analize1">
                  <h2>Good News! </h2>
                  <p className="p_text">We are able to assist you further. Please give us some back information. <br />
                  (This will only take 1 minute)</p>
                  <img src="/assets/img/review.gif" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};
export default AnalyzeModal;
