import React, { useState } from "react";
import InputButton from "../../../UI/InputButton";
import TextField from "../../../UI/TextField";

const MonthlyModal = ({ saveIncomeExpenditure, reff, display, trigger, validation, validationMsg }) => {
  const labelclicked = async (e) => {
    let question_60 = await trigger("question_60");
    if (question_60) {
      saveIncomeExpenditure(e);
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value = "£" + e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="question animated fadeInUp" ref={reff}>
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h3 className="text-center fonts15">
                  <u>Provide your total other monthly expenses you have</u>
                </h3>
                <p>
                  <i>(This can be a total of Fuel, Clothing, Betting, Bills, Coffee/Tea etc)</i>
                </p>
                <div className="input-group mb-3 justify-content-center">
                  <div className="mb-3 mt-3 col-12 p-0">
                    <div className="input-group mb-3">
                      <TextField
                        type="tel"
                        className="form-control"
                        placeholder="Provide your total Loan and Credit card expenses"
                        name="question_60"
                        onChange={handleChange}
                        validation={validation({
                          required: "Please Enter Total Loan and Credit card expenses",
                          pattern: {
                            value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                            message: "Total Loan and Credit card expenses is Invalid. Please Recheck",
                          },
                        })}
                        validationMsg={validationMsg.question_60 && validationMsg.question_60.message}
                      ></TextField>
                    </div>
                    <InputButton
                      name="monthly_exp"
                      className="btn-next"
                      id=""
                      value="Next"
                      btnType="button"
                      onClick={labelclicked}
                      style={{ float: "none" }}
                      data-quest-id="next_60"
                      data-ans-id={null}
                      data-ans-label={null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MonthlyModal;
