import React from "react";

const Loader = ({ showLoad, content }) => {
  return (
    <>
      {/* <div className={`tms-wrap2 bg-main-pr ${showLoad}`}>
     <div className="text-center loader-wrap loader2" >
      <div className="loader-logo">
        <img src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt=""/>
      </div>

      <div className="loader">
        <img src="assets/BankStatemenFLPS3/img/loader-review2.gif" alt=""/>
        <div className="loader-text">
          <p className="fs-5 pt-0">
            {content}</p>

        </div>
      </div>
    </div> */}

      {/* <div className="main text-center main-div ">    
        <img height="50" className="safe-secure-img" src="assets/img/safe and secure.png" alt=""/>
    </div> */}
      {/* </div> */}


      {/* /// new? */}
      <div className={`slide  page-loader loader1 ${showLoad}`}>
        <div className="wrap-app mt-3 text-center">
          <div className="logo-two loader-logo text-center pt-3 mb-5">
            {" "}
            <img src="assets/BankStatemenFLPS3/img/logo.png" alt="" />
          </div>
          <div className="mb-5 mt-5 pb-5">
            <img
              height={100}
              className="mb-2 mt-5"
              src="assets/BankStatemenFLPS3/img/loader-review2.gif"
              alt=""
            />
            <p className="mb-5">Please Wait</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default Loader;