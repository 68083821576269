import React from "react";

const CheckBox = ({
  value,
  CheckBoxText,
  name,
  checked,
  onChange,
  dataId,
  className,
  labelClassName,
  labelText,
  onClick,
}) => {
  return (
    <>
      <div className="d-flex align-item-top sign-check mb-3 mt-3">
        <div>
        <input
          type="checkbox"
          className={className}
          value={value}
          id={dataId}
          name={name}
          checked={checked}
          onChange={onChange}
          onClick={onClick}
        />
        </div>
        
        <div>
        <label htmlFor={dataId}>
        {/* {labelText} */}
          {CheckBoxText}
        </label>
      </div>
      </div>
    </>
  );
};
export default CheckBox;
