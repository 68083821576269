import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import InputButton from "../../../UI/InputButton";

const LatePayment = ({
    backClick,
    onSelectAnswer,
    setlatePay,
}) => {
    const [checkedOne, setCheckedOne] = useState("unchecked");
    const [checkedTwo, setCheckedTwo] = useState("unchecked");

    const optionHandle = async (e) => {
        if (e.target.value === "238") {
            setCheckedOne("checked");
            setCheckedTwo("unchecked");
            setlatePay(238);
            onSelectAnswer(e);
        } else {
            setCheckedTwo("checked");
            setCheckedOne("unchecked");
            setlatePay(239);
            onSelectAnswer(e);
        }

    }
    return (
        <>
            <div className={`question-content mt-0 scale-down-ver-center   px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3`} >
                <div className="row">
                    <div className="col-6">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn  ${checkedTwo}`}
                                onClick={optionHandle}
                                value="238"
                                name="latepayment"
                                id="nextscroll"
                                data-quest-id={82}
                                data-ans-id={238}
                                data-ans-label={null}
                            >Yes
                            </button>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className=" mb-2">

                            <button
                                type="button"
                                className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 noissue  ${checkedOne}`}
                                onClick={optionHandle}
                                value="239"
                                name="latepayment"
                                id="nextscroll"
                                data-quest-id={82}
                                data-ans-id={239}
                                data-ans-label={null}
                            >No
                            </button>
                        </div>
                    </div>
                </div>
                <p className="text-center bck-marwid">
                    <button
                        name="aware"
                        className="btn btn-secondary backbtn1a"
                        id=""
                        value=""
                        type="button"
                        onClick={backClick}

                    >Go Back</button>
                </p>
            </div>

        </>
    )
}
export default LatePayment;