import React, { useState } from 'react';
import RadioButton from '../../../../UI/RadioButtonOutLabel';

const QuestionnaireFlpKdate = ({
	questArr,
	reff,
	display,
	onselectAnswer,
	vehicle,
	setValidKeeperDate,
	validKeeperDate,
	keeperDates
}) => {

	const nextSlide = async (e) => {
		let date_index = e.target.id;
		let dataNsId = e.target.getAttribute('data-ans-id') ? e.target.getAttribute('data-ans-id') : '';
		if (date_index == '0') {
			setValidKeeperDate(dataNsId);
		}
		onselectAnswer(e);
	};

	let keeperArray = [];
	let keeperData = [];
	if (typeof vehicle.keeper_dates !== 'undefined') {
		keeperData = JSON.parse(vehicle.keeper_dates);
		keeperArray = keeperData.keeper_dates;
	} else {
		keeperArray = keeperDates.keeper_dates;
	}
	return (
		<>
			<div style={{ 'display': display }}>
				<div className="question animated fadeInUp" ref={reff} >
					<h3>Please confirm the date you registered this vehicle from the list below</h3>
					<div className="col-lg-8 offset-lg-2 col-12 ">
						{keeperArray && keeperArray.map((text, index, { length }) => (
							<RadioButton
								key={text.toString()}
								id={index}
								value={text}
								name="question_11"
								className="radio-button"
								labelName={text}
								labelClassName="option_but next-2"
								onClick={nextSlide}
								dataAnsId={text}
								dataQuestId={11}
							/>
						))}
					</div>
				</div>
			</div>
		</>
	)
}

export default QuestionnaireFlpKdate;