import React, { useContext, useState } from "react";

import Email from "../../../UI/Email";
import Telephone from "../../../UI/Telephone";
import InputButton from "../../../UI/InputButton";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";
import { usePhoneValidation } from "../../../../Hooks/usePhoneValidation";
import Anchor from "../../../UI/Anchor";
import ProgressBar from "../../Layouts/Split_1/ProgressBar";

const ContactDetails = ({
  validation,
  validationMsg,
  setError,
  className,
  backClick,
  trigger,
  disabled,
  getValues,
  formSubmit
}) => {
  const { emailValidation } = useEmailValidation();
  const { phoneValidation } = usePhoneValidation();
  
  const validateSlide4 = async (e) => {
    
    e.preventDefault();
    const values = getValues();
    var emailVal = await trigger("txtEmail");
    var phoneVal;
    if(emailVal){
      phoneVal = await trigger("txtPhone");
    }else{
      document.getElementById("txtEmail").focus();
      return false;
    }
    
    
    if(emailVal && phoneVal){
      if ((await emailValidCheck()) === 1 && (await phoneValidCheck()) === 1) {
        formSubmit();
      }
    }
  }
  const phoneValidCheck = async () => {
    const values = getValues();
    const phone = values['txtPhone'];
    const email = values['txtEmail'];
    if(phone !== "" && email !== "" ){
      document.getElementById("formSubmitBtn").classList.add('anim_ylw');
    }else{

      document.getElementById("formSubmitBtn").classList.remove('anim_ylw');
    }
    if (phone !== "") {
      const getPhoneValidation = await phoneValidation(phone)
      console.log(getPhoneValidation.data);
      if (getPhoneValidation.data.status_code === 0) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Working Phone Number",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 2) {
        setError("txtPhone", {
          type: "manual",
          message: "Phone Number Already Exist",
        });
        return 0;
      } else if (getPhoneValidation.data.status_code === 3) {
        setError("txtPhone", {
          type: "manual",
          message: "Please Enter Valid Phone Number",
        });
        return 0;
      }
      else {
  
        return 1;
      }
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values['txtEmail'];
    const phone = values['txtPhone'];
    if(phone !== "" && email !== "" ){
      document.getElementById("formSubmitBtn").classList.add('anim_ylw');
    }else{
      document.getElementById("formSubmitBtn").classList.remove('anim_ylw');
    }
    if (email !== "") {
      const getEmailValidation = await emailValidation(email)
      if (getEmailValidation.data.status_code === 1) {
        return 1;
      } else {
        setError("txtEmail", {
          type: "manual",
          message: "Invalid Email",
        });
      }
    }
  };

  return (

      <div id="slide-7" className={`slidetop_adjust ${className}`}>
        <ProgressBar value="95"/>
        <h4 className="text-center">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry.
        </h4>

        <div className="input-section">
          <div className="input-group">
            <Email
              name="txtEmail"
              className="form-control"
              id="txtEmail"
              placeholder="Email Address"
              onBlur={emailValidCheck}
              validation={validation({
                required: "Please Enter Valid Email Address",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid Email Address",
                },
              })}
            
            ></Email>
            <span className="input-group-text privacyico">
              <img src="/assets/img/privacy.png" alt="" />
            </span>
          </div>
          {validationMsg.txtEmail && (
            <span
              className="error_msg "
              id="email_err"
            >
              {validationMsg.txtEmail.message}
            </span>
          )}
        </div>
        <div className="input-group">
          <Telephone
            name="txtPhone"
            id="txtphone"
            className="form-control nme_inpu"
            placeholder="Phone Number"
            onBlur={phoneValidCheck}
            onKeyPress={e => (!/[0-9]+$/.test(e.key)) && e.preventDefault()}
            maxlength="11"
            validation={validation({
              pattern: {
                value: /^[0-9]*$/i,
                message: "Please Enter Valid Phone Number",
              },
              required: "Please Enter Phone Number",
            })}
          ></Telephone>
          <span className="input-group-text privacyico">
            <img src="/assets/img/privacy.png" alt="" />
          </span>
          {validationMsg.txtPhone && (
            <span
              className="error_msg "
              id="phone_err"
            >
              {validationMsg.txtPhone && "Please Enter Valid Phone Number"}
            </span>
          )}
        </div>
        <div className="col-lg-12 col-12">
          <InputButton
            name="formSubmitBtn"
            className="btn next-bttn mb-3"
            id="formSubmitBtn"
            value="SUBMIT"
            btnType="submit"
            style={{ float: "none" }}
            disabled={disabled}
            onClick={validateSlide4}
          />
        </div>
        <p className="text-center">
          <span
            className="back06 back-btn"
            name="back06"
            children="<< Previous"
            onClick={backClick}
          ></span></p>
      </div>
  );
};
export default ContactDetails;
