import React, { useState } from "react";
import TextField from "../../../UI/TextField";
import InputButton from "../../../UI/InputButton";
// import {questionnaire59, questionnaire60 } from "../../../../Constants/Questions"; 

//import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const Questionnaire4Model = ({
  questArr,
  validation,
  validationMsg,
  trigger,
  
  monthlyExpShow,
  otherExpShow,

  handleChange,
  totalDebitValidation,
  totalOtherDebitValidation,
  showModel1,
  showModel2,
  quest59lenders,
  quest60lenders,
  control,
  register,

  monthlyExpFlage,
  otherExpFlage,
  skipTotalDebit,
  skipOtherDebit
}) => {
  const questionId = questArr.questionId ? questArr.questionId : 0;  
  const TotalInputSting = quest59lenders.map((checArr, index) => {
    return (
      <div className="col-lg-6 col-12 w-lg-50 input-group mb-3" key={checArr.id}>
        <span className="input-group-text input-mobile">
        {checArr.label}
        </span>
        <TextField
          type="tel"
          className="form-control form-sp-input h-45"
          placeholder={checArr.label}
          name={`lender[${index}].${checArr.label}`}
          onChange={handleChange}
          control={control}
          validation={validation({
            // required: "Please Enter expenses",
            pattern: {
              value: /^[ A-Za-z0-9_@./#&+-£]*$/,
              message:
                "Total expenses is Invalid. Please Recheck",
            },
          })}
          defaultValue=""
        ></TextField>
        {validationMsg?.lender?.[index] && (
          <span className="error_msg">Please Enter</span>
        )}
      </div>
    );
  });

  const OtherInputSting = quest60lenders.map((checArr, index) => {
    return (
      <div className="col-lg-6 col-12 w-lg-50 input-group mb-3" key={checArr.id}>
        <span className="input-group-text input-mobile">
          {checArr.label}
        </span>
        <TextField
          type="tel"
          className="form-control form-sp-input h-45"
          placeholder={checArr.label}
          name={`lenderother[${index}].${checArr.label}`}
          onChange={handleChange}
          control={control}
          validation={validation({
            // required: "Please Enter expenses",
            pattern: {
              value: /^[ A-Za-z0-9_@./#&+-£]*$/,
              message:
                "Total expenses is Invalid. Please Recheck",
            },
          })}
          defaultValue=""
        ></TextField>
        {validationMsg?.lenderother?.[index] && (
          <span className="error_msg">Please Enter</span>
        )}
      </div>
    );
  });

  
  return (
    <>
      <div className={`question10 quesmdl animated fadeInUp ${showModel1} radio-box`} id="breakdown11a"> 
      {/* <div role="dialog" className={`fade modal blackover ${showModel1}`}> */}
      <div className="question animated fadeInUp">
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="text-center fonts15"><u>Provide your <span className="text-warning">Loan and Credit Card expenses</span> you have with your Lenders</u></h3>
              <p><i>(Many of our customers have expenses with multiple lenders. So you can provide the expenses you pay with all Lenders)</i></p>
              <div className="input-group mb-3 justify-content-center">
                <div className="mb-3 mt-3 col-12 p-0">
                  <div className="row mb-3">
                    {TotalInputSting}
                    
                    {monthlyExpFlage && (
                      <span className="error_msg">Please Enter values</span>
                    )}
                  </div>
                  <InputButton
                    name="eeeee"
                    className="btn-next"
                    id=""
                    value="Next"
                    btnType="button"
                    onClick={totalDebitValidation}
                    style={{ float: "none" }}
                    data-quest-id={questionId}
                    data-ans-id={null}
                    data-ans-label={null}
                  />
                  <p className="text-center">
                    <InputButton
                      name="skip_total_debit"
                      className="btn btn-link btn-skip"
                      id=""
                      value="SKIP"
                      btnType="button"
                      onClick={skipTotalDebit}
                      style={{ float: "none" }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      
      <div className={`question11 quesmdl animated fadeInUp ${showModel2} radio-box`} id="breakdown11b">
      <div className="question animated fadeInUp">
      {/* <div role="dialog" className={`fade modal blackover ${showModel2}`}> */}
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <h3 className="text-center fonts15"><u>Provide your other <span className="text-warning">monthly expenses</span></u></h3>
              <p className="text-center"><i>(This can be a total of Fuel, Clothing, Betting, Bills, Coffee/Tea etc)</i></p>
              <div className="input-group mb-3 justify-content-center">
                <div className="mb-3 mt-3 col-12 p-0">
                  <div className="row mb-3">
                    {OtherInputSting}

                    {otherExpFlage && (
                      <span className="error_msg">Please Enter values</span>
                    )}
                  </div>
                  <InputButton
                    name="sssss"
                    className="btn-next"
                    id=""
                    value="Next"
                    btnType="button"
                    onClick={totalOtherDebitValidation}
                    style={{ float: "none" }}

                    data-quest-id={questionId}
                    data-ans-id={null}
                    data-ans-label={null}
                  />
                  <p className="text-center">
                    <InputButton
                      name="skip_other_debit"
                      className="btn btn-link btn-skip"
                      id=""
                      value="SKIP"
                      btnType="button"
                      onClick={skipOtherDebit}
                      style={{ float: "none" }}
                    />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default Questionnaire4Model;