import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const UnAffordable = ({ className, slideChange, textHeading, validation }) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const nextSlide = async (e) => {
    if (e.target.value === "1") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      slideChange(e);
    }
    if (e.target.value === "2") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      slideChange(e);
    }
  };
  return (
    <>
      <div id="slide-1" className={`${className}`}>
        <ProgressBar value="0" />

        <h3> {textHeading} </h3>
        <div className="d-flex">
          <img
            src="/assets/img/MB_UBRF_1_1/greenlight.png"
            height="36px;"
            alt=""
          />
          <h3 className="green-text">
            {" "}
            <i>
              {" "}
              A loan is only affordable if you can make the repayments on time,
              without hardship and still meeting your other commitments
            </i>{" "}
          </h3>
        </div>
        <div className="radio_buttons">
          <RadioButton
            value="1"
            name="unaffordable"
            className="radio regNextBtn"
            labelName="Yes"
            labelClassName={`radio ${checkedQuestionOne}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="2"
            name="unaffordable"
            className="radio regNextBtn"
            labelName="No"
            labelClassName={`radio ${checkedQuestionTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
      </div>
    </>
  );
};
export default UnAffordable;
