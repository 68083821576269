import React, { useReducer, useState } from "react";
import Modal from "react-bootstrap/Modal";

const CapitalPopup = ({ show, setstate, lender }) => {
  const [showSlide1, setShowSlide1] = useState("show");
  const [showSlide2, setShowSlide2] = useState("hide");
  const [showSlide3, setShowSlide3] = useState("hide");
  const [showSlide4, setShowSlide4] = useState("hide");
  const [showSlide5, setShowSlide5] = useState("hide");

  return (
    <>
      <div
        className={`modal fade overlaybg ${show}`}
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-1a">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                {lender}
              </h4>
              {/* <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
            </div>
            <div className="modal-body text-center py-4">
              <div className={`modal-slide-2a ${showSlide1}`}>
                <h5>
                  {" "}
                  How many times did the lender increase your credit limit?
                </h5>

                <div className="my-0 my-md-3 radio-grp-modal d-flex flex-column flex-md-row justify-content-center px-5">
                  <div className="mt-3">
                    <input
                      type="radio"
                      className="btn-check "
                      name="options"
                      id="option-modal-2a1"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide2("show");
                        setShowSlide1("hide");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-2a"
                      htmlFor="option-modal-2a1"
                    >
                      1-2
                    </label>
                  </div>

                  <div className="mt-3 mx-0 mx-md-3 ">
                    <input
                      type="radio"
                      className="btn-check "
                      name="options"
                      id="option-modal-2a2"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide2("show");
                        setShowSlide1("hide");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-2a"
                      htmlFor="option-modal-2a2"
                    >
                      3-5
                    </label>
                  </div>

                  <div className="mt-3">
                    <input
                      type="radio"
                      className="btn-check "
                      name="options"
                      id="option-modal-2a3"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide2("show");
                        setShowSlide1("hide");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-2a"
                      htmlFor="option-modal-2a3"
                    >
                      5 +
                    </label>
                  </div>
                </div>
              </div>

              <div className={`modal-slide-2b ${showSlide2}`}>
                <h5> Have you made a claim against this lender previously</h5>
                <div className="my-0 my-md-3 radio-grp-modal d-flex flex-column flex-md-row justify-content-center px-5">
                  <div className="mx-2 mt-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-1a4"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide3("show");
                        setShowSlide2("hide");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-2b"
                      htmlFor="option-modal-1a4"
                    >
                      yes
                    </label>
                  </div>
                  <div className="mx-2 mt-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-1a5"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide3("show");
                        setShowSlide2("hide");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-2b"
                      htmlFor="option-modal-1a5"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className={`modal-slide-2c ${showSlide3}`}>
                <h5>
                  {" "}
                  Please select the years you first received the credit card and
                  when it ended
                </h5>
                <p>
                  If you still have it then put the current year (Don't worry if
                  you cannot remember - an estimate will suffice)
                </p>
                <div className="row px-0 px-md-5 pb-3">
                  <div className="px-4 my-2 col-md-6">
                    <select className="form-select form-control">
                      <option value="">Select Year</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2011">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                    </select>
                  </div>

                  <div className=" px-4 my-2 col-md-6">
                    <select className="form-select form-control">
                      <option value="">Select Year</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2011">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                    </select>
                  </div>

                  <div className="mt-3">
                    <a
                      className="btn btn-primary  modal-nextbtn-2c"
                      htmlFor="option-modal-1a5"
                      onClick={() => {
                        setShowSlide4("show");
                        setShowSlide3("hide");
                      }}
                    >
                      NEXT
                    </a>
                  </div>
                </div>
              </div>

              <div className={`modal-slide-2d ${showSlide4}`}>
                <h5>
                  {" "}
                  <b> Credit Card Account Number </b>{" "}
                </h5>
                <p>
                  {" "}
                  If you have your Credit Card Account number it will speed up
                  your claim. If you don't have it you can still continue
                </p>
                <div className="px-2 px-md-5 mb-4">
                  <input
                    type="text"
                    className=" form-control"
                    id="exampleInputP1"
                    placeholder="Account Number"
                  />
                </div>
                <div className="mt-0 mt-md-3">
                  <a
                    className="btn btn-primary  modal-nextbtn-2d"
                    htmlFor="option-modal-1a5"
                    onClick={() => {
                      setShowSlide5("show");
                      setShowSlide4("hide");
                    }}
                  >
                    NEXT
                  </a>
                </div>
              </div>

              <div className={`modal-slide-2e ${showSlide5}`}>
                <h4> Please TICK AT LEAST ONE that applies to you </h4>

                <div className="form-check text-start">
                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck2a"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck2a"
                    />
                    I already had multiple other debts at the time I applied for
                    this credit facility
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck2b"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck2b"
                    />
                    The lender increased my credit limit without reassessing my
                    situation
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck2c"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck2c"
                    />
                    I was struggling to pay essentials (rent, bills, food)
                    because of my repayment
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck2d"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck2d"
                    />
                    I utilised the increased credit facility to the maximum each
                    time it was increased
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck2e"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck2e"
                    />
                    I used my credit facility to pay for gambling
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck2f"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck2f"
                    />
                    I had to borrow money from another lender to pay back a loan
                    or cover essentials
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck2g"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck2g"
                    />
                    My income changed between taking out and repaying the loan
                    but the lender didn’t check
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck2h"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck2h"
                    />
                    The lender didn’t ask me any questions to confirm that I
                    would be able to repay the loan in a sustainable way
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck2i"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck2i"
                    />
                    I regularly exceeded my credit limit but they still lent me
                    more money
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck2k"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck2k"
                    />
                    I could only afford to pay the minimum payments towards my
                    debt
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck2k"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck2k"
                    />
                    I regularly missed my monthly repayments
                  </label>
                </div>
                <div className="modal-footer-2a modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary rounded-0"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setstate("hide");
                      setShowSlide5("hide");
                      setShowSlide1("show");
                    }}
                  >
                    Close
                  </button>
                  <button
                    id="save-btn-modal5"
                    type="button"
                    className="btn btn-primary footer-btns"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setstate("hide");
                      setShowSlide5("hide");
                      setShowSlide1("show");
                    }}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CapitalPopup;
