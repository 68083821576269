import React , {
  useContext
} from "react";
import { useHistory } from "react-router-dom";
import "../../assets/viewdocs/css/docs.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GtmDataLayer from "../../Utility/GtmDataLayer";

  const Viewdocs =()=>{

    const { visitorParameters } = useContext(VisitorParamsContext);
    const history = useHistory();
    const getUuid = () => {
      const visitorData = visitorParameters.visitor_parameters;
      if (visitorData != "") {
        var uuid = visitorData.uuid;
      } else {
        const query = new URLSearchParams(window.location.search);
        
        const query_uuid = query.get("uuid");
        const local_storage_uuid = localStorage.getItem("uuid");
        var uuid = query_uuid ? query_uuid : local_storage_uuid;
      }
      return uuid;
    };
    const uuid = getUuid();
    console.log("UUID"+ uuid);
    
    const split_name = localStorage.getItem("split_name");
    const redirectPreview = () => {
      if (split_name=='LS_UB_1'|| split_name=='LS_UB_2' ) {
        history.push("/previewV3?uuid=" + visitorParameters.visitor_parameters.uuid);
      }
      else if (split_name=='VCC_UB_1' || split_name=='VCC_UB_3') {
        history.push("/previewV4?uuid=" + visitorParameters.visitor_parameters.uuid);
      }
      else if (split_name=='VCC_UB_2') {
        history.push("/previewV5?uuid=" + visitorParameters.visitor_parameters.uuid);
      }
      else if (split_name=='VAQ_UB_1' || split_name=='VAQ_UB_2') {
        history.push("/previewV6?uuid=" + visitorParameters.visitor_parameters.uuid);
      }
      else if (split_name=='VAQ_UB_3'||split_name=='VAQ_OB_UB_3_1') {
         GtmDataLayer({
          'question': 'view your doc'
        });
        history.push("/previewV8?uuid=" + visitorParameters.visitor_parameters.uuid);
      }
      else if (split_name=='NDY_UB_1'|| split_name=='NDY_UB_2' || split_name=='NDY_OB_UB_2_1') {
        GtmDataLayer({
          'question': 'View Your Documents'
        });
        history.push("/previewV7?uuid=" + visitorParameters.visitor_parameters.uuid);
      }
      else {
        history.push("/preview?uuid=" + visitorParameters.visitor_parameters.uuid);
      }
    }
    
    return(
      <>
      <div className ="view_doc">
    <div className ="suc_page">
      
    <section>
    <div className="container text-center" style={{margin:"auto"}}>
      <div className="row">
        <div className="col-lg-12">
          <div className="mt-3">
            <a href className="tms_logo">
              <img src="/assets/viewdocs/img/logo.png"/>
            </a>
          </div>
          <div className="suc_midle">
            <div className="loader">
              <img src="/assets/viewdocs/img/loader-review2.gif" className="image" />
            </div>
            <p className="text py-5">
              Thank you for submitting the documents to allow TMS Legal to represent you in your responsible lending claim.&nbsp;
              <span>
                We will be in touch shortly.</span>
            </p>
            <a href="javascript:void(0)" onClick={redirectPreview} className="btn-view bnt-style"> View Your Documents &gt; </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
  </div>
  
  </>
    )
  }
  export default Viewdocs;