import React from "react";
import { useFormContext } from "react-hook-form";


const RadioButton = ({
  id,
  value,
  name,
  className,
  labelName,
  htmlFor,
  onClick,
  labelClassName,
  validation,
  checked,
  dataQuestId,
  dataAnsId,
  dataAnsLabel,
  iclassName,
}) => {
  const { register, errors } = useFormContext();

  return (
    <>
    
      <input
        type="radio"
        className={className}
        name={name}
        id={id}
        value={value}
        ref={register({required: "Please select card type" })}
        checked={checked}
      />
      <label htmlFor={htmlFor} className={className} onClick={(e) => onClick(e,id,value)}>

        <div>
          <i className={iclassName} />
        </div>
        <div className={labelClassName}>
        {labelName}
        </div>
      </label>
      
    </>
  );
};
export default RadioButton;
