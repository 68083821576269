import React, { useEffect, useState } from "react";
import SelectBox from "../../../../UI/SelectBox";
import AnalyzeModal from "../AnalayzeModal";
import { MonthlyIncome } from "../../../../../Constants/Constants";

const CardMonthlyIncome = ({
  clsName,
  reff,
  validation,
  validationMsg,
  trigger,
  saveInputAns,
}) => {
  const [closeYear, setAccCloseyear] = useState([]);

  const optionSelected = async (e) => {
    let errorFlag = 0;
    let result = await trigger("cardMonthlyIncome");

    if (!result) {
      errorFlag = 1;
      return false;
    } else {
      saveInputAns(e);
    }
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp`} ref={reff}>
        <h3>
          <b>
          When you took out your card what was your monthly income?
          </b>
        </h3>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
          <SelectBox
            className="form-select form-control"
            OptionValue={MonthlyIncome}
            name="cardMonthlyIncome"
            id="cardMonthlyIncome"
            myRef={validation({
              required: "Please select your monthly income",
            })}
            validationMsg={
              validationMsg.cardMonthlyIncome &&
              validationMsg.cardMonthlyIncome.message
            }
          />
        </div>
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button
            type="button"
            className="btn-next"
            onClick={optionSelected}
            data-quest-id={78}
            data-ans-id={null}
            data-ans-label={null}
            name="btn_qest_78"
          >{`NEXT`}</button>
        </div>
      </div>
    </>
  );
};

export default CardMonthlyIncome;

