import react from "react";
import TextArea from "../../../UI/TextArea";
const FinanceProblem = ({
    validationMsg,
    clearErrors,
    validation,
    trigger,
    getValues,
    saveInputAns,
    reff,
    clsName,
    setError
}) => {

    const optionSelected = async (e) => {
        let errorFlag = 0;
        let result = await trigger("question_84");
        if (!result) {
            errorFlag = 1;
            return false;
        } else {
            const fieldResult = getValues();
            var reason = fieldResult["question_84"].trim();
            if (reason == "" || reason.length < 3) {
                setError("question_84", {
                  type: "manual",
                  message: "Please Enter Valid Reason",
            });
                return false;
            } else {
            saveInputAns(e);
            }
        }
    };
    return (
        <>
            <div
                className={`radio-box animated fadeInUp space-top space-top2 ${clsName}`}
                id="slide-23"
                ref={reff}
            >
                <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
                    <h3><b>
                        Please describe what really caused you to have problems in repaying / financially?
                    </b></h3>
                    <TextArea
                        type="textarea"
                        className="form-control2"
                        name="question_84"
                        validation={validation({
                            required: "Please Enter the Reason",
                            minLength: {
                                value: 3,
                                message: "Please Enter Valid Reason",
                            },
                        })}
                        validationMsg={
                            validationMsg.question_84 &&
                            validationMsg.question_84.message
                        }
                    ></TextArea>
                    {/* </div> */}
                    <div className="col-lg-12 mb-2 mt-2">
                        <button
                            type="button"
                            className="btn-next"
                            onClick={optionSelected}
                            data-quest-id={84}
                            name="problem"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default FinanceProblem;





