import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import ProgressBar from "../../Layouts/Split_1/ProgressBar";
import { useHistory } from "react-router-dom";
const BasicQuestionnaireThree = ({
  className,
  slideChange,
  textHeading,
  validation,
  backClick,
  questionId,
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const history = useHistory();
  const nextSlide = async (e) => {
    if (e.target.value === "10") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      slideChange(e);
    }
    if (e.target.value === "11") {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      setCheckedQuestionTwo("checked");
      setCheckedQuestionOne("unchecked");
      history.push('/unqualified?uuid='+query_uuid+'&questionId='+questionId+'&answerId='+3);
    }
  };
  return (
    <>
      <div id="slide-3" className={` slidetop_adjust ${className}`}>
       <ProgressBar value="30"/>
        <h4 className="text-center">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </h4>
        <div className="radio_buttons">
          <RadioButton
            value="10"
            name="question_3"
            className="radio"
            labelName="No"
            labelClassName={`radio ${checkedQuestionOne}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="11"
            name="question_3"
            className="radio"
            labelName="Yes"
            labelClassName={`radio ${checkedQuestionTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        {/* <p className="text-center">
          <span className="not_sure" id="not-sure">
            <u>Not Sure</u>
          </span>
        </p> */}
        <p className="text-center">
          <span
              className="back-btn"
              name="back02"
              children="<< Previous"
              onClick={backClick}
          ></span>
        </p>
      </div>


      
    </>
  );
};
export default BasicQuestionnaireThree;
