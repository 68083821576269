import React from "react";
import AnchorAdv from "../../../UI/AnchorAdv";

const AdvBanner = ({ textHead, bannerYears }) => {
  const textHeadDefault = textHead ? textHead : "How To Get Started";
  const years = bannerYears.map((year,index) => {
    return(
        <AnchorAdv dataId="MID_PARA_NUM" key={index}>
          {year}
        </AnchorAdv>
    );
  })
  return (
    <>
      <div className="col-xxl-12 col-xl-12">
        <div className="eligible_sec mb-4">
          <h2 className="py-2 fw-bold fs-3 text-light text-center">
            IT IS A LONG ESTABLISHED FACT THAT A READER WILL BE DISTRACTED
          </h2>
          <div className="py-4 px-4 text-center">
            {years}
          </div>
        </div>
      </div>
    </>
  );
};
export default AdvBanner;
