import React, { useState } from "react";
import ProgressBar from './ProgressBar';
import RadioButton from '../../../UI/RadioButton';

const Slide4 = ({
  className,
  slideChange,
  validation,
  trigger,
  clearErrors,
  getValues,
  setError,
  backClick
}) => {
  const [checked1,setChecked1] = useState('');
  const [checked2,setChecked2] = useState('');
  const [checked3,setChecked3] = useState('');
  const [checked4,setChecked4] = useState('');

  const nextSlide = async (e) => {
    if (e.target.value == 3) {
      setChecked1('activeclass');
    } else {
      setChecked1('');
    }

    if (e.target.value == 4) {
      setChecked2('activeclass');
    } else {
      setChecked2('');
    }

    if (e.target.value == 5) {
      setChecked3('activeclass');
    } else {
      setChecked3('');
    }

    if (e.target.value == 6) {
      setChecked4('activeclass');
    } else {
      setChecked4('');
    }

    slideChange(e);
  };
  return (
    <>
      <div id="slide4" className={`col-12 top_minus ${className}`}>
        <ProgressBar value={30} />
        <h3>At the time you secured finance… </h3>
        <div className="mb-3" id="slide-A">
          <h4>What was your employment status?</h4>

          <RadioButton
            htmlFor="previously"
            value="3"
            tabIndex="2"
            id="status-1"
            name="employmentStatus"
            className="radio-button"
            labelName="Employed"
            labelClassName={`option_but next-A ${checked1}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            htmlFor="previously"
            value="4"
            tabIndex="2"
            id="status-2"
            name="employmentStatus"
            className="radio-button"
            labelName="Self-employed"
            labelClassName={`option_but next-A ${checked2}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            htmlFor="previously"
            value="5"
            tabIndex="2"
            id="status-3"
            name="employmentStatus"
            className="radio-button"
            labelName="Student"
            labelClassName={`option_but next-A ${checked3}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            htmlFor="previously"
            value="6"
            tabIndex="2"
            id="status-4"
            name="employmentStatus"
            className="radio-button"
            labelName="Retired"
            labelClassName={`option_but next-A ${checked4}`}
            onClick={nextSlide}
            validation={validation()}
          />


          <p className="text-center"><a href="#" id="back03" name="back03" onClick={backClick} className="back03">{'<< Previous'}</a></p>
        </div>
      </div>
    </>
  );
};

export default Slide4;