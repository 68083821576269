import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import InputButton from "../../../../UI/InputButton";
import Modal from "react-bootstrap/Modal";

const Difficulties = ({ onselectAnswer, reff, display, trigger ,unQualifiedAnswer }) => {
  const labelclicked = async (e) => {
    if (e.target.value === "241") {
      unQualifiedAnswer(e);
      setshowClaimModal(true);  
    }
    if (e.target.value === "240") {
      onselectAnswer(e);
    }
  };
  const [claimModal, setshowClaimModal] = useState(false);

  const handleCloseclaimModal = (e) => {
    setshowClaimModal(false);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>When you received the communications from the lender, did you feel that any difficulties you had repaying / financially were:</h3>
          <RadioButtonQuest
            key="I thought that it was my fault / responsibility"
            className="radio-button"
            labelName="I thought that it was my fault / responsibility"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="240"
            name="question_83"
            id="diffone"
            dataQuestId={83}
            dataAnsId={240}
            dataAnsLabel="I thought that it was my fault / responsibility"
          />
          <RadioButtonQuest
            key="I thought that it was the Vanquis’s fault / responsibility"
            className="radio-button"
            labelName="I thought that it was the Vanquis’s fault / responsibility"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="241"
            name="question_83"
            id="difftwo"
            dataQuestId={83}
            dataAnsId={241}
            dataAnsLabel="I thought that it was the Vanquis’s fault / responsibility"
          />
        </div>
      </div>

      <Modal
        size="md"
        show={claimModal}
        onHide={handleCloseclaimModal}
        centered
        backdrop="static"
        className="claimdeinial"
      >
        <Modal.Body>
          <h3 className="text-center">Sorry we are unable to assist with this claim,<br /> as Vanquis are very likely to time-bar it,<br /> and not review it</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Difficulties;
