import React from "react";
import { Helmet } from "react-helmet";

const Header = () => {
  return (
    <>
     <Helmet>
        <script
          src="//b-js.ringba.com/CA16dbdc5a8ffa4916a62b95fcfb79014c"
          async
        ></script>
      </Helmet>
    </>
  )
}

export default Header