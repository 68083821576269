import React, { useState, useContext, useEffect } from "react";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";
//import TextField from "../../../../UI/TextField";
import { useIdUpload } from "../../Hooks/useIdUpload";
import InputButton from "../UI/InputButton";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
import { queryString,updateQueryString } from "../../Utility/QueryString";
import Loader from "../Includes/Layouts/FollowupS2/Loader";
import "../../assets/FollowupS2/css/custom.scss";
import "../../assets/FollowupS2/css/id_upload.scss";
import "../../assets/FollowupS2/css/openbank.scss";
import "../../assets/FollowupS2/css/signature.scss";
import "../../assets/FollowupS2/css/style.scss";
import { Api } from "../../api/Api";

const BankStmtUploadPageS2 = ({ display }) => {
  const history = useHistory();
  const { skippedLeads, skippedLeadsFLP } = useSkippedLeads();
  const { saveFLPIdUpload, isLoading } = useIdUpload();
  const split_name = localStorage.getItem("split_name");
  // const allFollowupData = localStorage.getItem("allFollowupData");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileType, setSelectedFileType] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [document, setDocument] = useState(false);
  const [file, setFile] = useState("");
  const [loanDate, setLoanDate] = useState("");
  const [activeClass, setActiveClass] = useState("disabled");
  const [btnDisabled, SetBtnDisabled] = useState(false);
  const [allFollowupData, setFollowupData] = useState(false);
  const [showLoad, setshowLoad] = useState("hide");
  // const loanDate = localStorage?.getItem("loanDate");
  const query_string = localStorage.getItem("queryString");
  const querystring = queryString;
  const queryStrings = query_string ? query_string : querystring;
  const [formattedDate, setFormattedDate] = useState(null);
  const [mothsBefore, setMothsBefore] = useState(null);



  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const response = await Api.get(
          "/api/v1/followup/get-pending-details-s2?query_string=" + queryStrings,
          {}
        );

        if (isMounted) {
          if (response.data.status === "Success") {
            let followupDetails = response.data.response.followup_data;
            let loanDate = response.data.response.loanDate;
            localStorage.setItem("queryString", queryString);
            
            let [year, month] = loanDate.split(" ");

            var months = [
              'January',
              'February',
              'March',
              'April',
              'May',
              'June',
              'July',
              'August',
              'September',
              'October',
              'November',
              'December'
            ];
            let monthArr = (months.indexOf(month) + 1); 
          
            monthArr =  String(monthArr).replace(/^(\d)$/, '0$1');
          
              // Format the result
            let parsedDate = `${year}/${monthArr}/15`;

            setLoanDate(loanDate);
            localStorage.setItem(
              "followupDetails",
              JSON.stringify(followupDetails)
            );
            setFollowupData(followupDetails);
            
            const newDate = new Date(parsedDate);

            let fromDate = newDate.toLocaleString('en-US', { month: 'long', year: 'numeric' });
            
            setFormattedDate(fromDate);
            // Subt ract 3 months
            
            newDate.setMonth(newDate.getMonth() - 3);
          
            // Format the new date as "MMMM yyyy"
            let beforeDate = newDate.toLocaleString('en-US', { month: 'long', year: 'numeric' });
            setMothsBefore(beforeDate);
          }
        }
      } catch (error) {
        console.warn(error);
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  const userfile = {
    preview: undefined,
    title: undefined,
  };

  const changeCameraHandler = (event) => {
    setActiveClass("camera");
    SetBtnDisabled(false);
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      // if (selectedFile) {

      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        SetBtnDisabled(true);
        setIsInValidfFile(true);
        setErrorMessage(
          "The uploaded Doc type should be an image in jpeg,png or jpg"
        );
      } else if (imgsize > 1024 * 1024 * 20) {
        SetBtnDisabled(true);
        setIsInValidfFile(true);
        setErrorMessage("Please choose a file less than 20mb");
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const changeFilesHandler = (event) => {
    setActiveClass("fileupload");
    SetBtnDisabled(false);
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = [
        "image/jpeg",
        "image/png",
        //"application/zip",
        "application/msword",
        "application/pdf",
      ];
      if (allowedExtensions.indexOf(extension) === -1) {
        SetBtnDisabled(true);
        setIsInValidfFile(true);
        setErrorMessage(
          "The uploaded Doc type should be an image in jpeg,png,jpg,pdf,doc, or docx"
        );
      } else if (imgsize > 1024 * 1024 * 20) {
        SetBtnDisabled(true);
        setIsInValidfFile(true);
        setErrorMessage("Please choose a file less than 20mb");
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
        //_handleFileRead.bind(event.target.files[0]);
      }
      setDocument(false);

      if ("nationalIDCard_doc" == event.target.name) {
        setDocument(true);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const onSelectOption = (e) => {
    setIsInValidfFile(false);
    SetBtnDisabled(false);
    if (e.target.name == "FinanceAgreementDocEmail") {
      setSelectedFile(undefined);
      setActiveClass("email");
    } else if (e.target.name == "FinanceAgreementDocSkip") {
      setSelectedFile(undefined);
      setActiveClass("skip");
    } else if (e.target.name == "closefile") {
      setActiveClass("disabled");
      SetBtnDisabled(true);
      setSelectedFile(undefined);
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image(binaryString);
  };

  const onProceed = (e) => {
    if (activeClass == "fileupload" || activeClass == "camera") {
      setshowLoad('show');
      savedocument(e);
    } else if (activeClass == "email") {
      SkipSupportDocs("EmailDoc");
    } else if (activeClass == "skip") {
      SkipSupportDocs("SkipBankStmt");
    } else {
      setIsInValidfFile(true);
      setErrorMessage("Please choose an option");
    }
  };

  const SkipSupportDocs = async (type) => {
    SetBtnDisabled(true);
    setActiveClass("disabled");
    setshowLoad('show');
    const skippedLeadsResponse = await skippedLeadsFLP(
      "SkipBankStmt",
      allFollowupData,
      split_name
    );
    if (skippedLeadsResponse.data.status == "Success") {
      localStorage.setItem("email_doc", 1);
      localStorage.setItem("skipBtn", 1);
      pushPageChange();
      // setShow(false);
      // setPendingStatus({ status_btn: 'pending_question', status_flag: 'showTick' })
    }
  };

  const pushPageChange = async () => {
    localStorage.setItem("BankStmt", 1);
    // let queryParams = localStorage.getItem("queryString");
    
    //const queryParams = new URLSearchParams(queryString);
    if (queryString.includes('welcome=true')) {
      
      const updatedQueryString = queryString.replace(/&welcome=true/g, '');
      updateQueryString(updatedQueryString);
      localStorage.setItem("queryString", updatedQueryString);
    }

    // const updatedURL = "/followup-s2" + "?" + queryParams + "&welcome=forward";
    const updatedURL = "/followup-s2" + "?" + queryString + "&welcome=forward";
    window.location.href = updatedURL;
  };

 

  const savedocument = async (e) => {
    if (selectedFile) {
      setIsInValidfFile(false);
      try {
        let uploadData = "";
        uploadData = {
          imageFile: base64Image,
          captureType: selectedFileType.substring(
            selectedFileType.indexOf("doc")
          ),
          documentType: "BankStmt",
        };

        const response = await saveFLPIdUpload(
          uploadData,
          allFollowupData,
          queryString,
          "flp_support_docs_store"
        );
        if (response.data.status == "Success") {
          console.log("Upload Status-" + response.data.status);
          localStorage.setItem("skipBnkStmt", 1);
          pushPageChange();
        }
      } catch (e) {
        console.warn("error");
        console.warn(e);
        setIsInValidfFile(true);
        setErrorMessage("Please try again");
      }
    } else {
      setIsInValidfFile(true);
      setErrorMessage("Please choose an option");
    }
  };

  const back = () =>{
    // let match_check = localStorage.getItem("match_check");
    // if(match_check == 1){
    //   const updatedURL = "/proceed-ob-s2" + "?" + queryString + "&welcome=true";
    //   window.location.href = updatedURL;
    // }
    // else{

      const updatedURL = "/followup-s2" + "?" + queryString + "&welcome=true";
      window.location.href = updatedURL;
    // }
  }

  return (
    <>
      <div className="followups2newFlow">
      <Loader content={"Please wait while we save your progress..."} showLoad={showLoad}></Loader>
        <div className={`tms-wrap2 bg-main-pr ${
              showLoad == 'show' ? "hide" : "show"
            }`}>
          <div className="questtmsloan">
            {/* <div className={`${reloader == 1 ? 'show' : 'hide'}`}> */}
            <div className={`main text-center main-div`}>
              <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
                <img height="65" src="assets/img/logo_1.png" alt="" />
              </div>
              <div className="main-form-wraper form-part">
                <div className="container">
                  <div className="item-cent-flx ">
                    <div className="openbank-slide2 mt-l-r pt-4">
                      <div className=" pt-lg-3 pt-md-3 pt-sm-3  pt-0    text-center">
                        <div className="row">
                          <div className="col-lg-6 offset-lg-3">
                           {mothsBefore ?(
                            <h1 className=" mb-4 mt-md-2 mt-sm-2 mt-0 h4">
                            To help us facilitate your financial situation when you took out your loan with MoneyBarn, please upload your Bank statements covering a period of three months, starting from <span>{mothsBefore}</span> up to <span>{formattedDate}</span>
                            </h1>
                            ) : null}
                            <div className="slot-filed mb-3 ">
                              <div className="d-flex align-items-center">
                                <input
                                  type="file"
                                  className="take_picture"
                                  id="FinanceAgreementDoc"
                                  name="FinanceAgreementDoc"
                                  onChange={changeFilesHandler}
                                  onClick={(e) => (e.target.value = null)}
                                />
                                <div className="icon">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="currentColor"
                                    className="bi bi-upload"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                  </svg>
                                </div>
                                <div className="ps-3">
                                  Upload files from your device
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row   mt-md-2 mt-sm-3 mt-0">
                          <div className="col-lg-6 offset-lg-3  mb-2 text-center">
                            {/* <div> <a><button class=" option_but skip-btn w-100 mb-3"> Skip for Now
                          </button></a> </div> */}
                            <div>
                              
                              {isInValidfFile && (
                                <span className="error_msg">{errorMessage}</span>
                              )}
                              <div className="col-xl-12 col-lg-12 text-center p-1 btn_proceed_outer">
                                {selectedFile && (
                                  <>
                                    {selectedFile.name}{" "}
                                    <button
                                      className="mb-1 btn btn-secondary btn-sm"
                                      name="closefile"
                                      onClick={onSelectOption}
                                    >
                                      Remove
                                    </button>
                                  </>
                                )}
                                <input
                                  type="button"
                                  name="btn_qest_45"
                                  disabled={btnDisabled}
                                  className={`btn-comm2  mt-0 second ${
                                    activeClass == "disabled" && "disabledclass"
                                  }`}
                                  onClick={onProceed}
                                  value="Proceed"
                                />
                              </div>
                              <div className="option_but2 skip-btn  skip-btn skipfor-now my-4">
                                &nbsp; Skip for Now
                                <input
                                  type="radio"
                                  className="take_picture "
                                  name="FinanceAgreementDocSkip"
                                  onClick={SkipSupportDocs}
                                />
                              </div>
                            </div>
                            <InputButton
                                className="btn btn-link"
                                value="<< Previous"
                                onClick={()=>back()}
                            />

                            {/* <div><button class=" btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 second" id="Proceed"> Proceed
                        </button> </div> */}
                            <p className="fs-6 mt-2"> </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-5  pt-3 ">
                  <img
                    height="50"
                    className="safe-secure-img"
                    src="assets/img/safe and secure.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankStmtUploadPageS2;
