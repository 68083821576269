export const Salutation = [
  {
    label: "Title",
    value: "",
  },
  {
    label: "Mr.",
    value: "Mr",
  },
  {
    label: "Mrs.",
    value: "Mrs",
  },
  {
    label: "Ms.",
    value: "Ms",
  },
  {
    label: "Dr.",
    value: "Dr",
  },
  {
    label: "Prof.",
    value: "Prof",
  },
];

export const DobDay = [{ label: "Day", value: "" }];
const startDay = 1;
const endDay = 31;
for (var i = startDay; i <= endDay; i++) {
  var objDobDay = {};
  objDobDay["label"] = i;
  objDobDay["value"] = i;
  DobDay.push(objDobDay);
}

export const DobMonth = [
  { label: "Month", value: "" },
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const DobMonthV2 = [
  { label: "Month", value: "" },
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

export const Months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DobYear = [{ label: "Year", value: "" }];
const startYear = 2002;
const endYear = 1910;
for (var k = startYear; k >= endYear; k--) {
  var objDob = {};
  objDob["label"] = k;
  objDob["value"] = k;
  DobYear.push(objDob);
}


export const Amount = [
  { label: "Please Select the Amount", value: "" },
  { label: "£500", value: "£500" },
  { label: "£1000", value: "£1000" },
  { label: "£1500", value: "£1500" },
  { label: "£2000", value: "£2000" },
  { label: "£2500", value: "£2500" },
  { label: "£3000", value: "£3000" },
  { label: "£3500", value: "£3500" },
  { label: "£4000", value: "£4000" },
  { label: "£4500", value: "£4500" },
  { label: "£5000", value: "£5000" },
  { label: "£5500", value: "£5500" },
  { label: "£6000", value: "£6000" },
  { label: "£6500", value: "£6500" },
  { label: "£7000", value: "£7000" },
  { label: "£7500", value: "£7500" },
  { label: "£8000", value: "£8000" },
  { label: "£8500", value: "£8500" },
  { label: "£9000", value: "£9000" },
  { label: "£9500", value: "£9500" },
  { label: "£10000", value: "£10000" },
];

export const YearPossession = [{ label: "Select Year", value: "" }];
const startYearOfPossession = 2023;
const endYearOfPossession = 2006;
for (var k = startYearOfPossession; k >= endYearOfPossession; k--) {
  var objYearOfPossession = {};
  objYearOfPossession["label"] = k;
  objYearOfPossession["value"] = k;
  YearPossession.push(objYearOfPossession);

}

export const RecentCreditIncreaseYear = [{ label: "Select Year", value: "" }];
const startRecentCreditIncrease = 2024;
const endRecentCreditIncrease = 2000;
for (var k = startRecentCreditIncrease; k >= endRecentCreditIncrease; k--) {
  var objYearOfPossession = {};
  objYearOfPossession["label"] = k;
  objYearOfPossession["value"] = k;
  RecentCreditIncreaseYear.push(objYearOfPossession);
}
RecentCreditIncreaseYear.push({ label: "Can't Remember", value: "Can't Remember" });

export const ClosedYears = [{ label: "Select Year", value: "" }];
const startClosedYears = 2024;
const endClosedYears = 2000;
for (var k = startClosedYears; k >= endClosedYears; k--) {
  var objYearOfPossession = {};
  objYearOfPossession["label"] = k;
  objYearOfPossession["value"] = k;
  ClosedYears.push(objYearOfPossession);
}
export const ClosedYears2 = [{ label: "Select Year", value: "" }];
const startClosedYear = 2024;
const endClosedYear = 2000;
for (var k = startClosedYears; k >= endClosedYears; k--) {
  var objYearOfPossession = {};
  objYearOfPossession["label"] = k;
  objYearOfPossession["value"] = k;
  ClosedYears2.push(objYearOfPossession);
}
ClosedYears2.push({ label: "Can't Remember", value: "Can't Remember" });

export const PossessionMonth = [
  { label: "Month", value: "" },
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const furterDe = [
  { label: "Month", value: "" },
  { label: "January", value: "January" },
  { label: "February", value: "February" },
  { label: "March", value: "March" },
  { label: "April", value: "April" },
  { label: "May", value: "May" },
  { label: "June", value: "June" },
  { label: "July", value: "July" },
  { label: "August", value: "August" },
  { label: "September", value: "September" },
  { label: "October", value: "October" },
  { label: "November", value: "November" },
  { label: "December", value: "December" },
];

export const Employmentsts = [
  { label: "Select employment status", value: "" },
  { label: "Employed", value: "3" },
  { label: "Unemployed", value: "199" },
  { label: "Self-employed", value: "4" },
  { label: "Student", value: "5" },
  { label: "Retired", value: "6" },
  { label: "Benefits main source of income", value: "200" },
  { label: "Part time job", value: "201" },
  { label: "Temporary worker", value: "202" },
  { label: "Long term sick", value: "203" },
  { label: "Maternity", value: "204" },
  { label: "Rolling/Zero hours contract", value: "205" },
];
export const EmploymentNDY = [
  { label: "Select employment status", value: "" },
  { label: "Employed", value: "3" },
  { label: "Unemployed", value: "199" },
  { label: "Self-employed", value: "4" },
  { label: "Student", value: "5" },
  { label: "Retired", value: "6" },
];

export const MonthlyIncome = [
  { label: "Select Monthly Income", value: "" },
  { label: "Less than £500", value: "Less than £500" },
  { label: "£500 - £1000", value: "£500 - £1000" },
  { label: "£1000 - £1500", value: "£1000 - £1500" },
  { label: "£1500 - £2000", value: "£1500 - £2000" },
  { label: "£2500 - £3000", value: "£2500 - £3000" },
  { label: "£3000 - £4000", value: "£3000 - £4000" },
  { label: "More than £4000", value: "More than £4000" },
];

export const Dependent = [
  { label: "Please Select", value: "" },
  { label: "0", value: "0" },
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "4+", value: "4+" },
];

const BannerYearAdv1 = [];
const start = 1926;
const end = 1989;
for (var i = start; i <= end; i++) {
  if (start === i) {
    BannerYearAdv1.push([-i]);
  } else {
    BannerYearAdv1.push([i]);
  }
}
export { BannerYearAdv1 };
export const signCheckText = `By signing and by pressing “Submit” below, you agree to Check for SSB Group <a href="https://www.ssblaw.co.uk/uploads/files/website-terms-of-use-and-privacy-policy-may-19.pdf" target="_blank">Terms and Conditions</a> and that we may use your signature on the <a style="cursor:pointer; color:#00aab1 !important;" href=""> Letter of Authority</a> that we send to your bank.  A copy of the <a href="https://www.ssblaw.co.uk/uploads/files/website-terms-of-use-and-privacy-policy-may-19.pdf" target="_blank">Terms and Conditions</a> and <a style="cursor:pointer; color:#00aab1 !important" href="">Letter of Authority</a> will be sent to you via email.
`;


export const faq =  [
  {
    id: 1,
    head: "What is a Packaged Bank Account (PBA)?",
    content:
      "<p>Customers have been charged a monthly fee for an account which has a bundle of extra insurances such as travel, smart phone and breakdown cover. However, it has been found that these insurances are not always suitable for the customer or that the customer might not always be able to make a claim on the policy, for example, if their health would prevent them from making a claim. It can sometimes be difficult for customers to make a claim on these policies due to restrictions in the small print. Some customers have found that they have no use for some or all of insurances sold but the bank failed to draw this to their attention.</p>",
  },
  {
    id: 2,
    head: "How do I know if I have a Packaged Bank Account?",
    content:
      "<p>A good place to start would be to look at your monthly bank statements to see if you have been paying a fee for your account. Different providers of Packaged Bank Accounts use different names to market this product so your statement will not necessarily always make it clear as to the nature of the account. If in any doubt over a charge you can clarify it with your bank.  If you previously held an account and have downgraded or closed it, you can still claim.</p",
  },
  {
    id: 3,
    head: "Will making a claim affect my relationship with the bank?",
    content:
      "<p>The claim will not affect the relationship you have with the bank. The bank has a duty of care to treat you fairly and therefore you would not be treated any differently for having made a claim.</p>",
  },
  {
    id: 4,
    head: "What fee is charged for the service?",
    content:
      "<p>The first point to make clear is that no fee is payable unless we are successful in winning compensation for you. Therefore, we work on a strictly no win, no fee basis. In the event that we secure you a positive outcome leading to compensation, our fee will be a 39.99% of any redress payment inclusive of VAT.</p>",
  },
  {
    id: 5,
    head: "How much compensation can I expect if the Packaged Bank Account was mis-sold?",
    content:
      "<p>If the Packaged Bank Account is established as mis-sold normal practice would be to refund either all or part of the premiums you have made for the account and add 8% interest on top.</p>",
  },
  {
    id: 1,
    head: "Can I make a claim if my Packaged Bank Account is closed?",
    content:
      "<p>Yes, just because an account is closed, or downgraded to a free account does not prevent you from claiming.</p>",
  },
  {
      id: 1,
      head: "How long will the claim take?",
      content:
        "<p>This depends of the provider of the account and the work load they are currently experiencing. Their regulator gives 8 weeks to give a response to your claim. If a response is not forthcoming within this time frame, the claim can be referred to the Financial Ombudsman Service.</p>",
  },
  {
      id: 1,
      head: "Can I make a claim myself?",
      content:
        "<p>Yes, you can go directly to your provider and lodge a claim if you wish. However, our service has been specifically designed to take the hassle and stress out of doing this by yourself. Our process will help you to present a claim that reflects all your key claim points to aid you win any potential redress. The aim is to make sure that your claim is taken seriously. Please note that the Financial Ombudsman Scheme could be used or any other mechanism such as legal expenses insurance to pursue a claim.</p>",
  },
];

export const accCloseYear = [{ label: "Select Year", value: "" }];
const startAccYear = 2023;
const endAccYear = 1980;
for (var k = startAccYear; k >= endAccYear; k--) {
  var objAccYear = {};
  objAccYear["label"] = k;
  objAccYear["value"] = k;
  accCloseYear.push(objAccYear);
}


export const VeryLenderArray = {
  133 :"Very",
  134 :"Shop Direct",
  135 :"Littlewoods",
};


export const MonthNumberArray = {
  "January":'01',
  "February":'02',
  "March":'03',
  "April":'04',
  "May":'05',
  "June":'06',
  "July":'07',
  "August":'08',
  "September":'09',
  "October":'10',
  "November":'11',
  "December":'12'
};

export const MonthNameArray = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  '10': 'October',
  '11': 'November',
  '12': 'December',
};


export const VanquisProducts = {
  164 :"Vanquis Classic Card",
  165 :"Vanquis Chrome Card",
  166 :"Vanquis Origin Card",
  167 :"Vanquis Personal Loan",
  168 :"Not Sure",
  169 :"John Lewis & Partners",
  170 :"Argos",
  171 :"AO",
  172 :"Newpay",
  173 :"Aquacard",
  174 :"Marbles",
  175 :"Bip",
  176 :"Fluid",
  177 :"Pulse",
  178 :"New Day Ltd"  //other
};
