import React from 'react';
import { useTotalCarCheck } from '../../../../Hooks/useTotalCarCheck';
import RadioButton from '../../../UI/RadioButtonOutLabel';

const Questionnaire2New = ({
	clsName,
	reff,
	page_name,
	uuid,
	vehicle,
	totalCarCheck,
	savetotalCarCheck,
	onselectAnswer,
	setValidKeeperDate	
}) => {
	const { totalCarCheckApi } = useTotalCarCheck();

	const nextSlide = async (e) => {
		let date_index = e.target.id;
		let dataNsId = e.target.getAttribute('data-ans-id')?e.target.getAttribute('data-ans-id'):'';
		if(date_index == '0') {
		setValidKeeperDate(dataNsId);
		}
		onselectAnswer(e);
		
		// showLoader('show');
		// setTimeout(() => {
		// 	showLoader('hide'); 
		// 	onselectAnswer(e);
		// }, 1000);    
	};

	let keeperArray = [];
  let keeperData  = [];
  if(typeof vehicle.keeper_dates !== 'undefined' ){
    keeperData = JSON.parse(vehicle.keeper_dates);
    keeperArray = keeperData.keeper_dates;
  }

	return (
		<>
			<div className={`radio-box animated fadeInUp text-center ${clsName}`} ref={reff} >
				<h3><b>When did you start the finance?</b></h3>

				<div className="mb-3 col-lg-8 offset-lg-2 col-12 ">   
					{keeperArray.map((text, index, {length}) =>(
						<RadioButton
							key={text.toString()}
							id={index}	
							value={text}
							name="question_11"
							className="radio-button"
							labelName={text}
							labelClassName="option_but next-2"
							onClick={nextSlide}
							dataAnsId={text} 
							dataQuestId={11}
          	/>
					))}	
				</div>
			</div>
		</>
	)
}

export default Questionnaire2New;