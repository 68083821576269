import React, { useReducer, useState } from "react";
import Modal from "react-bootstrap/Modal";

const GeorgeBancoPopup = ({ show, setstate, lender }) => {
  const [showSlide1, setShowSlide1] = useState("show");
  const [showSlide2, setShowSlide2] = useState("hide");
  const [showSlide3, setShowSlide3] = useState("hide");
  const [showSlide4, setShowSlide4] = useState("hide");
  const [showSlide5, setShowSlide5] = useState("hide");
  const [showSlide6, setShowSlide6] = useState("hide");
  const [showSlide7, setShowSlide7] = useState("hide");
  const [showSlide8, setShowSlide8] = useState("hide");

  return (
    <>
      <div
        className={`modal fade overlaybg ${show}`}
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-1a">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                {lender}{" "}
              </h4>
              {/* <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
            </div>
            <div className="modal-body text-center py-4">
              <div className={`modal-slide-4a ${showSlide1}`}>
                <h5> How many loans have you had with this lender?</h5>
                <p>
                  {" "}
                  If you had to roll over your loans with the same company in
                  order to repay the loan before. Please count each roll over as
                  a separate loan.{" "}
                </p>

                <div className="my-0 my-md-3 radio-grp-modal d-flex flex-column flex-md-row justify-content-center px-5">
                  <div className="mt-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-5a1"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide1("hide");
                        setShowSlide2("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-4a"
                      htmlFor="option-modal-5a1"
                    >
                      1-4
                    </label>
                  </div>

                  <div className="mt-3 mx-0 mx-md-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-5a2"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide1("hide");
                        setShowSlide2("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-4a"
                      htmlFor="option-modal-5a2"
                    >
                      5-10
                    </label>
                  </div>

                  <div className="mt-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-5a3"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide1("hide");
                        setShowSlide2("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-4a"
                      htmlFor="option-modal-5a3"
                    >
                      10 +
                    </label>
                  </div>
                </div>
              </div>

              <div className={`modal-slide-4b ${showSlide2}`}>
                <h5>
                  {" "}
                  Please select the years you took out your first and last loan
                </h5>
                <p>
                  If you have only had 1 loan, please input the same year in
                  both fields. (Don't worry if you cannot remember - an estimate
                  will suffice)
                </p>
                <div className="row px-5">
                  <div className="px-4 my-2 col-md-6">
                    <select className="form-select form-control">
                      <option value="">Select Year</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2011">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                    </select>
                  </div>

                  <div className=" px-4 my-2 col-md-6">
                    <select className="form-select form-control">
                      <option value="">Select Year</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2011">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                    </select>
                  </div>
                </div>

                <div className="mt-3">
                  <a
                    className="btn btn-primary  modal-nextbtn-4b"
                    htmlFor="option-modal-1a5"
                    onClick={() => {
                      setShowSlide2("hide");
                      setShowSlide3("show");
                    }}
                  >
                    NEXT
                  </a>
                </div>
              </div>

              <div className={`modal-slide-4c ${showSlide3}`}>
                <h5>
                  {" "}
                  <b> Loan Account Number</b>{" "}
                </h5>
                <p>
                  {" "}
                  If you have your Loan Account or Agreement number it will
                  speed up your claim. If you don't have it you can still
                  continue
                </p>
                <div className=" px-2 px-md-5 mb-4">
                  <input
                    type="text"
                    className=" form-control"
                    id="exampleInputP1"
                    placeholder="Account Number"
                  />
                </div>
                <div className="mt-3">
                  <a
                    className="btn btn-primary  modal-nextbtn-4c"
                    htmlFor="option-modal-1a5"
                    onClick={() => {
                      setShowSlide3("hide");
                      setShowSlide4("show");
                    }}
                  >
                    NEXT
                  </a>
                </div>
              </div>

              <div className={`modal-slide-4d ${showSlide4}`}>
                <h5> Are you the borrower or the guarantor? </h5>
                <div className="my-0 my-md-3 radio-grp-modal d-flex flex-column flex-md-row justify-content-center px-5">
                  <div className="mt-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-5a4"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide4("hide");
                        setShowSlide5("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-4d"
                      htmlFor="option-modal-5a4"
                    >
                      Borrower
                    </label>
                  </div>
                  <div className="mt-3 mx-0 mx-md-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-5a5"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide4("hide");
                        setShowSlide5("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-4d"
                      htmlFor="option-modal-5a5"
                    >
                      Guarantor
                    </label>
                  </div>
                </div>
              </div>

              <div className={`modal-slide-4e ${showSlide5}`}>
                <h5> What relationship is the guarantor to you?</h5>

                <div className="my-0 my-md-3 radio-grp-modal d-flex flex-column flex-md-row justify-content-center px-5">
                  <div className="mt-3 mx-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-5a6"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide5("hide");
                        setShowSlide6("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-4e"
                      htmlFor="option-modal-5a6"
                    >
                      Relative{" "}
                    </label>
                  </div>
                  <div className="mt-3 mx-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-5a7"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide5("hide");
                        setShowSlide6("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-4e"
                      htmlFor="option-modal-5a7"
                    >
                      Friend
                    </label>
                  </div>
                  <div className="mt-3 mx-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-5a8"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide5("hide");
                        setShowSlide6("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-4e"
                      htmlFor="option-modal-5a8"
                    >
                      Other
                    </label>
                  </div>
                </div>
              </div>

              <div className={`modal-slide-4f ${showSlide6}`}>
                <h5> Who was your guarantor? </h5>
                <div className="px-2 px-md-5 mb-3">
                  <input
                    type="text"
                    className=" form-control"
                    id="exampleInputP1"
                    placeholder="guarantor"
                  />
                </div>
                <div className="mt-3">
                  <a
                    className="btn btn-primary  modal-nextbtn-4f"
                    htmlFor="option-modal-1a5"
                    onClick={() => {
                      setShowSlide6("hide");
                      setShowSlide7("show");
                    }}
                  >
                    NEXT
                  </a>
                </div>
              </div>

              <div className={`modal-slide-4g ${showSlide7}`}>
                <h5> Did your Guarantor make payments towards your loan? </h5>
                <div className="radio-grp-modal d-flex flex-column flex-md-row justify-content-center px-5">
                  <div className="mt-3 mx-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-5a9"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide7("hide");
                        setShowSlide8("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-4g"
                      htmlFor="option-modal-5a9"
                    >
                      Yes{" "}
                    </label>
                  </div>
                  <div className="mt-3 mx-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-5a10"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide7("hide");
                        setShowSlide8("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-4g"
                      htmlFor="option-modal-5a10"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className={`modal-slide-4h ${showSlide8}`}>
                <h4 className="pt-2">
                  {" "}
                  Please TICK AT LEAST ONE that applies to you
                </h4>
                <div className="form-check text-start">
                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck5a"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck5a"
                    />
                    I already had multiple other debts at the time I applied for
                    this credit facility
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck5b"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck5b"
                    />
                    The lender increased my credit limit without reassessing my
                    situation
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck5c"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck5c"
                    />
                    I was struggling to pay essentials (rent, bills, food)
                    because of my repayment
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck5d"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck5d"
                    />
                    I utilised the increased credit facility to the maximum each
                    time it was increased
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck5e"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck5e"
                    />
                    I used my credit facility to pay for gambling
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck5f"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck5f"
                    />
                    I had to borrow money from another lender to pay back a loan
                    or cover essentials
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck5g"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck5g"
                    />
                    My income changed between taking out and repaying the loan
                    but the lender didn’t check
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck5h"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck5h"
                    />
                    The lender didn’t ask me any questions to confirm that I
                    would be able to repay the loan in a sustainable way
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck5h"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck5h"
                    />
                    I regularly exceeded my credit limit but they still lent me
                    more money
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck5h"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck5h"
                    />
                    I could only afford to pay the minimum payments towards my
                    debt
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck5h"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck5h"
                    />
                    I regularly missed my monthly repayments
                  </label>
                </div>
                <div className="modal-footer modal-footer-4a">
                  <button
                    type="button"
                    className="btn btn-secondary rounded-0"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setstate("hide");
                      setShowSlide8("hide");
                      setShowSlide1("show");
                    }}
                  >
                    Close
                  </button>
                  <button
                    id="save-btn-modal5"
                    type="button"
                    className="btn btn-primary footer-btns"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setstate("hide");
                      setShowSlide8("hide");
                      setShowSlide1("show");
                    }}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeorgeBancoPopup;
