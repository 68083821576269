import React, { useState, useEffect, useRef, useReducer } from "react";
import { useForm } from "react-hook-form";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import { questinnare1, questinnare2Very ,questinnaire36VanquisV2,questinnaire76VanquisV2} from "../../../../Constants/Questions";
import IncomeExpenditure from "./Vanquis/IncomeExpenditure";
import QuestionnaireFlpQ5 from "../Followup/QuestionnaireFlpQ5";
import QuestionnaireFlpFANUpload from "../Followup/QuestionnaireFlpFANUpload";
import { useHistory } from "react-router-dom";
import QuestionnaireFlpAgreementNo from "./Vanquis/QuestionnaireFlpAgreementNo";
import AccountCloseYear from "./Vanquis/AccountCloseYear";
import HighestBalance from "./Vanquis/HighestBalance";
import HowManyTimes from "./Vanquis/HowManyTimes";
import HowOften from "./Vanquis/HowOften";
import MinimumPayment from "./Vanquis/MinimumPayment";
import LoanModal from "./Vanquis/LoanModal";
import MonthlyModal from "./Vanquis/MonthlyModal";
import QuestionnaireEmail from "./Vanquis/QuestionnaireEmail";
import EmailConfirmation from "./Vanquis/EmailConfirmation";
import { useUserVerify } from "../../../../Hooks/useUserVerify";
import { userMilestoneUpdate } from "../../../../Hooks/userMilestoneUpdate";
import { queryString } from "../../../../Utility/QueryString";
import QuestionnaireFooter from "./QuestionnaireFooter";
import QuestionnaireFlpQ76 from "../Followup/QuestionnaireFlpQ76";
import BolsterYourComplaint from "./Vanquis/BolsterYourComplaint";

const initialState = {
  showSlide1: "hide",
  showSlide1a: "hide",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide",
  showSlide6a: "hide",
  showSlide6: "hide",
  showSlide7: "hide",
  showSlide8: "hide",
  showSlide9a: "hide",
  showSlide9: "hide",
  showSlide10: "hide",
  loanModal: "hide",
  monthlyModal: "hide",
};
const QuestionnaireReducer = (state, action) => {
  switch (action.type) {
    case "showSlideChange": {
      if (action.payload == "slide1") {
        return {
          ...state,
          showSlide1: "show",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6a: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9a: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 10,
        };
      } else if (action.payload == "slide1a") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "show",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6a: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9a: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 20,
        };
      } else if (action.payload == "slide2") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "show",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6a: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9a: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 30,
        };
      } else if (action.payload == "slide3") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "show",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6a: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9a: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 40,
        };
      } else if (action.payload == "slide4") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "show",
          showSlide5: "hide",
          showSlide6a: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9a: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 50,
        };
      } else if (action.payload == "slide5") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "show",
          showSlide6a: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9a: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 60,
        };
      } else if (action.payload == "slide6a") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6a: "show",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 70,
        };
      } else if (action.payload == "slide6") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6a: "hide",
          showSlide6: "show",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9a: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 75,
        };
      } else if (action.payload == "slide7") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6a: "hide",
          showSlide6: "hide",
          showSlide7: "show",
          showSlide8: "hide",
          showSlide9a: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 80,
        };
      } else if (action.payload == "slide8") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6a: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "show",
          showSlide9a: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 85,
        };
      } else if (action.payload == "slide9a") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9a: "show",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 87,
        };
      } else if (action.payload == "slide9") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6a: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9a: "hide",
          showSlide9: "show",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 90,
        };
      } else if (action.payload == "slide10") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6a: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9a: "hide",
          showSlide9: "hide",
          showSlide10: "show",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 95,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "expenseModal": {
      if (action.payload == "loanModal") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6a: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9a: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "show",
          monthlyModal: "hide",
        };
      } else if (action.payload == "monthlyModal") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6a: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9a: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "show",
        };
      } else {
        return {
          ...state,
        };
      }
    }
  }
};
const VanquisQuestionForm = ({
  lender,
  questionsPop,
  allFollowupData,
  pendingQuestionnaire,
  splitName,
  currentUuid,
  documentType,
  docType,
  sett2aValidation,
  setPendingStatus,
  accClsYear,
  splitData,
  t2aApiReqData,
  t2aStatus,
  setDivShow

}) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const splitForm = useRef(null);
  const [optionSelected] = useState([]);
  const [optionSelected76] = useState([]);
  const { getUserVerfyValidation,saveFollowupUserVerify } = useUserVerify();
  const { updateMilestone } = userMilestoneUpdate();

  const { register, errors, trigger, setError, clearErrors, getValues } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  useEffect(() => {
    setShow(questionsPop);
  }, [questionsPop]);

  useEffect(() => {
    slideChange(pendingQuestionnaire);
  }, [pendingQuestionnaire]);

  const slideChange = (pendingArr) => {
    let pendingCount = pendingArr.length;
    if (pendingCount == 0) {
      setShow(false);
      sendT2aApiReqDataAPI();
    }

    pendingArr.every((element) => {
      dispatch({ type: "showSlideChange", payload: element });
    });
  };
  const storeFollowupQuestionnire = async (question_data) => {
    const response = await saveFollowupUserQuestions(question_data, allFollowupData, "followup_question_store");
  };

  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    let flage = false;
    let question_data = {};
    if (e.target.name == "question_54") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("slide3");
    } else if (e.target.name == "question_55") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("slide4");
    } else if (e.target.name == "question_56") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("slide5");
    } else if (e.target.name == "btn_qest_76") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 76,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected76,
        };
      }
      removeFromPending("slide9a");
    } else if (e.target.name == "btn_qest_36") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("slide9");
    } else if (e.target.name == "question_61") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
        if (selectedAns == 162) {
          removeFromPending("slide8");
        }
      }
      removeFromPending("slide7");
    } else if (e.target.name == "question_75") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
        if (selectedAns == 214) {
          removeFromPending("slide6");
        }
      }
      removeFromPending("slide6a");
    } else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem("currentquestionData", JSON.stringify(question_data));
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const saveIncomeExpenditure = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    const values = getValues();
    setCompletedQuestArr([...completedQuestArr, selectedQuest, 32, 33, 31]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    // var question_57 = splitForm.current["question_57"].value;
    // var question_58 = splitForm.current["question_58"].value;
    // var question_59 = values["question_59"];
    // var question_60 = values["question_60"];

    // if (question_57 == 151 && (question_59 == undefined || question_59 == "")) {
    //   dispatch({ type: "expenseModal", payload: "loanModal" });
    //   return false;
    // } else if (question_58 == 153 && (question_60 == undefined || question_60 == "")) {
    //   dispatch({ type: "expenseModal", payload: "monthlyModal" });
    //   return false;
    // } else {
      var slide_array = [
        ["31", answer31],
        ["32", answer32],
        ["33", answer33],
        // ["57", question_57],
        // ["58", question_58],
      ];

      // if (question_59 != "") {
      //   slide_array.push(["59", question_59]);
      // }
      // if (question_60 != "") {
      //   slide_array.push(["60", question_60]);
      // }

      var question_data = {
        slide_4: slide_array,
      };
      removeFromPending("slide6");
      storeFollowupQuestionnire(question_data);

      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    // }
  };

  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    const valuesreg = getValues();
    let highestBalance = valuesreg["highest_balance"];
    let agreementNo = valuesreg["question_45"];
    let email = valuesreg["question_48"];
    let accCloseYr = valuesreg["question_52"];
    let flage = false;
    let question_data = {};
    if (selectedQuest == "52" && accCloseYr) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: accCloseYr,
      };
      removeFromPending("slide1a");
    }
    if (selectedQuest == "45" && agreementNo) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: agreementNo,
      };
      removeFromPending("slide1");
    }
    if (selectedQuest == "53" && highestBalance) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: highestBalance,
      };
      removeFromPending("slide2");
    }
    if (selectedQuest == "48" && email) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: email,
      };
      removeFromPending("slide8");
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem("currentquestionData", JSON.stringify(question_data));
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const removeFromPending = (slidename) => {
    const index = pendingQuestionnaire.indexOf(slidename);
    if (index > -1) {
      pendingQuestionnaire.splice(index, 1);
    }
    slideChange(pendingQuestionnaire);
  };

  const pushPageChange = () => {
    removeFromPending("slide10");
  };

  const sendT2aApiReqDataAPI = () => {
    const useruuid = t2aApiReqData.uuid;
    const fName = t2aApiReqData.fName;
    const lName = t2aApiReqData.lName;
    const pCode = t2aApiReqData.pCode;
    const addressLine1 = t2aApiReqData.addressLine1;
    const dobDay = t2aApiReqData.day;
    const dobMonth = t2aApiReqData.month;
    const dobYear = t2aApiReqData.year;
    const addressLine2 = t2aApiReqData.addressLine2;
    const postTown = t2aApiReqData.postTown;
    const country = t2aApiReqData.country;
    const title = t2aApiReqData.title;
    const addressLine3 = t2aApiReqData.addressLine3;
    const addressLine4 = t2aApiReqData.addressLine4;
    const county = t2aApiReqData.county;

    if (
      useruuid &&
      fName &&
      lName &&
      pCode &&
      addressLine1 &&
      t2aStatus == null
    ) {
      (async () => {
        const response = await getUserVerfyValidation(
          useruuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYear,
          dobMonth,
          dobDay,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
          addressLine4,
          county,
        );
        storeT2aResponse(response.data);

        if (response.data.status == "success") {
          localStorage.setItem("t2a_result", 1);
          localStorage.setItem("split_name", splitName);
          history.push("/view_docs_flp?uuid=" + currentUuid);
        } else {
          localStorage.setItem("t2a_result", 0);
          setDivShow("idUpload");
        }
      })();
    } else {
      localStorage.setItem("t2a_result", t2aStatus);
      let email_skip = localStorage.getItem("email_doc");
      if (useruuid && email_skip != 1) {
        (async () => {
          const response = await updateMilestone(
            useruuid,
            t2aStatus,
            allFollowupData
          );
        })();
      }

      if (t2aStatus == 1) {
        history.push("/view_docs_flp?uuid=" + currentUuid);
      } else {
        setDivShow("idUpload");
      }
    }
  };

  const storeT2aResponse = async (t2a_data) => {
    const response = await saveFollowupUserVerify(
      allFollowupData,
      t2a_data,
      "person_verify_store_flp",
      queryString,
      t2aApiReqData
    );
  };


  return (
    <>
      <div className="QuestionnaireV7">
        <div className="formsection">
          <div className="container">
            <div className="row">
              <form
                ref={splitForm}
                name="split_form"
                id="user_form"
                method="POST"
                autoComplete="off"
              //onSubmit={handleSubmit}
              >
                <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12">

                  <div className={`question1 animated fadeInUp space-top ${state.showSlide1}`} id="breakdown01">
                    <QuestionnaireFlpAgreementNo
                      questArr={questinnare1}
                      labelclicked={onselectAnswer}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      saveInputAns={saveInputAns}
                    />
                  </div>

                  <div className={`question1 animated fadeInUp space-top ${state.showSlide1a}`} id="breakdown1a">
                    <AccountCloseYear
                      lender={lender}
                      saveInputAns={saveInputAns}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      splitData={splitData}
                    />
                  </div>

                  <div className={`question1 animated fadeInUp space-top ${state.showSlide2}`} id="breakdown1">
                    <HighestBalance
                      lender={splitData.lender}
                      saveInputAns={saveInputAns}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                  </div>
                  <div className={`question1 animated fadeInUp space-top2 ${state.showSlide3}`} id="breakdown2">
                    <HowManyTimes
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                  </div>
                  <div className={`question1 animated fadeInUp space-top2 ${state.showSlide4}`} id="breakdown3">
                    <HowOften
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                  </div>
                  <div className={`question1 animated fadeInUp space-top2 ${state.showSlide5}`} id="breakdown4">
                    <MinimumPayment
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                  </div>
                  <div className={`question7 animated fadeInUp space-top ${state.showSlide6a}`} id="breakdown9">
                    <BolsterYourComplaint onselectAnswer={onselectAnswer} />
                  </div>
                  <div className={`question6 animated fadeInUp space-top2 ${state.showSlide6}`} id="breakdown5">
                    <IncomeExpenditure
                      questArr={questinnare1}
                      labelclicked={onselectAnswer}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      saveIncomeExpenditure={saveIncomeExpenditure}
                      splitForm={splitForm}
                    />
                  </div>
                  <div className={`question7 animated fadeInUp space-top ${state.showSlide7}`} id="breakdown9">
                    <EmailConfirmation onselectAnswer={onselectAnswer} userEmail={splitData.email} />
                  </div>
                  <div className={`question8 animated fadeInUp space-top ${state.showSlide8}`} id="breakdown9">
                    <QuestionnaireEmail
                      validation={register}
                      getValues={getValues}
                      trigger={trigger}
                      validationMsg={errors}
                      saveInputAns={saveInputAns}
                      lender={splitData.lender}
                    />
                  </div>
                  <div className={`question9 animated fadeInUp space-top2 ${state.showSlide9a}`} id="breakdow10">
                    <QuestionnaireFlpQ76
                      questArr={questinnaire76VanquisV2}
                      onselectAnswer={onselectAnswer}
                      optionSelected={optionSelected76}
                    />
                  </div>
                  <div className={`question9 animated fadeInUp space-top2 ${state.showSlide9}`} id="breakdow10">
                    <QuestionnaireFlpQ5
                      questArr={questinnaire36VanquisV2}
                      onselectAnswer={onselectAnswer}
                      optionSelected={optionSelected}
                    />
                  </div>
                  <div className={`question10 animated fadeInUp space-top2 ${state.showSlide10} radio-box`} id="breakdown11">
                    <QuestionnaireFlpFANUpload
                      questArr={questinnare2Very}
                      labelclicked={onselectAnswer}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      split_name={splitName}
                      docType={docType}
                      documentType={documentType}
                      allFollowupData={allFollowupData}
                      sett2aValidation={sett2aValidation}
                      pushPageChange={pushPageChange}
                    />
                  </div>
                </div>
                <div className={`fade modal blackover space-top2 ${state.loanModal}`} id="breakdown7">
                  <LoanModal
                    saveIncomeExpenditure={saveIncomeExpenditure}
                    validation={register}
                    validationMsg={errors}
                    getValues={getValues}
                    trigger={trigger}
                    setError={setError}
                  />
                </div>
                <div className={`fade modal blackover space-top2 ${state.monthlyModal}`} id="breakdown8">
                  <MonthlyModal
                    saveIncomeExpenditure={saveIncomeExpenditure}
                    validation={register}
                    validationMsg={errors}
                    getValues={getValues}
                    trigger={trigger}
                    setError={setError}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <QuestionnaireFooter
        percentage={state.percentage}
      />
    </>
  );
};
export default VanquisQuestionForm;
