import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import TextField from "../../../UI/TextField";

const Questionnaire45 = ({
  clsName,
  reff,
  saveInputAns,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
}) => {
  const selectClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();
    let question45Result = await trigger("question_45");
    if (!question45Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag === 0) {
      saveInputAns(e);
    }
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>Enter your Finance Agreement Number</h3>
        <div className="input-group mb-3">
          <TextField
            type="text"
            className="form-control"
            placeholder="Finance Agreement Number"
            name="question_45"
            // onChange={handleChange}
            validation={validation({
              required: "Please enter your Finance Agreement Number",
            //   pattern: {
            //     value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/,
            //     message:
            //       "Your Finance Agreement Number is Invalid. Please Recheck",
            //   },
            })}
            validationMsg={
              validationMsg.question_45 && validationMsg.question_45.message
            }
          ></TextField>
        </div>
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button
            type="button"
            className="btn-next"
            onClick={selectClick}
            data-quest-id={45}
            data-ans-id={null}
            data-ans-label={null}
            name="btn_qest_44"
          >
            Proceed
          </button>
        </div>
        <div className="col-xl-12 col-lg-12 links text-center">
          <a href="#">
            <p>
              <i>I'll Email the Document</i>
            </p>
          </a>

          <a href="#">
            <p>
              <i>I'll Follow Up Later</i>
            </p>
          </a>
        </div>
        <div className="clearfix"></div>
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <p className="font-italic text-center  ">
            (All for your convenience please email a copy of your Finance Agreement Number to documents@themoneysolicitor.com.)
          </p>
        </div>
      </div>
    </>
  );
};
export default Questionnaire45;
