import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const ProductClaim = ({
  className,
  slideChange,
  textHeading,
  validation,
  backClick,
  setCreditCard
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const [checkedQuestionThree, setCheckedQuestionThree] = useState("unchecked");
  const [checkedQuestionFour, setCheckedQuestionFour] = useState("unchecked");
  const [checkedQuestionFive, setCheckedQuestionFive] = useState("unchecked");
  const [checkedQuestionSix, setCheckedQuestionSix] = useState("unchecked");
  const [checkedQuestionSeven, setCheckedQuestionSeven] = useState("unchecked");
  const [checkedQuestionEight, setCheckedQuestionEight] = useState("unchecked");
  const [checkedQuestionNine, setCheckedQuestionNine] = useState("unchecked");
  const [checkedQuestionTen, setCheckedQuestionTen] = useState("unchecked");


  const nextSlide = async (e) => {
    if (e.target.value === "173") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      // setCheckedQuestionThree("unchecked");
      // setCheckedQuestionFour("unchecked");
      // setCheckedQuestionFive("unchecked");
      // setCheckedQuestionSix("unchecked");
      // setCheckedQuestionSeven("unchecked");
      // setCheckedQuestionEight("unchecked");
      // setCheckedQuestionNine("unchecked");
      setCheckedQuestionTen("unchecked");

      slideChange(e);
    }
    if (e.target.value === "174") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      // setCheckedQuestionThree("unchecked");
      // setCheckedQuestionFour("unchecked");
      // setCheckedQuestionFive("unchecked");
      // setCheckedQuestionSix("unchecked");
      // setCheckedQuestionSeven("unchecked");
      // setCheckedQuestionEight("unchecked");
      // setCheckedQuestionNine("unchecked");
      setCheckedQuestionTen("unchecked");
      slideChange(e);
    }
    // if (e.target.value === "175") {
    //   setCheckedQuestionOne("unchecked");
    //   setCheckedQuestionTwo("unchecked");
    //   setCheckedQuestionThree("checked");
    //   setCheckedQuestionFour("unchecked");
    //   setCheckedQuestionFive("unchecked");
    //   setCheckedQuestionSix("unchecked");
    //   setCheckedQuestionSeven("unchecked");
    //   setCheckedQuestionEight("unchecked");
    //   setCheckedQuestionNine("unchecked");
    //   slideChange(e);
    // }
    // if (e.target.value === "176") {
    //   setCheckedQuestionOne("unchecked");
    //   setCheckedQuestionTwo("unchecked");
    //   setCheckedQuestionThree("unchecked");
    //   setCheckedQuestionFour("checked");
    //   setCheckedQuestionFive("unchecked");
    //   setCheckedQuestionSix("unchecked");
    //   setCheckedQuestionSeven("unchecked");
    //   setCheckedQuestionEight("unchecked");
    //   setCheckedQuestionNine("unchecked");
    //   slideChange(e);
    // }
    // if (e.target.value === "169") {
    //   setCheckedQuestionOne("unchecked");
    //   setCheckedQuestionTwo("unchecked");
    //   setCheckedQuestionThree("unchecked");
    //   setCheckedQuestionFour("unchecked");
    //   setCheckedQuestionFive("checked");
    //   setCheckedQuestionSix("unchecked");
    //   setCheckedQuestionSeven("unchecked");
    //   setCheckedQuestionEight("unchecked");
    //   setCheckedQuestionNine("unchecked");
    //   slideChange(e);
    // }
    // if (e.target.value === "171") {
    //   setCheckedQuestionOne("unchecked");
    //   setCheckedQuestionTwo("unchecked");
    //   setCheckedQuestionThree("unchecked");
    //   setCheckedQuestionFour("unchecked");
    //   setCheckedQuestionFive("unchecked");
    //   setCheckedQuestionSix("checked");
    //   setCheckedQuestionSeven("unchecked");
    //   setCheckedQuestionEight("unchecked");
    //   setCheckedQuestionNine("unchecked");
    //   slideChange(e);
    // }
    // if (e.target.value === "170") {
    //   setCheckedQuestionOne("unchecked");
    //   setCheckedQuestionTwo("unchecked");
    //   setCheckedQuestionThree("unchecked");
    //   setCheckedQuestionFour("unchecked");
    //   setCheckedQuestionFive("unchecked");
    //   setCheckedQuestionSix("unchecked");
    //   setCheckedQuestionSeven("checked");
    //   setCheckedQuestionEight("unchecked");
    //   setCheckedQuestionNine("unchecked");
    //   slideChange(e);
    // }
    // if (e.target.value === "172") {
    //   setCheckedQuestionOne("unchecked");
    //   setCheckedQuestionTwo("unchecked");
    //   setCheckedQuestionThree("unchecked");
    //   setCheckedQuestionFour("unchecked");
    //   setCheckedQuestionFive("unchecked");
    //   setCheckedQuestionSix("unchecked");
    //   setCheckedQuestionSeven("unchecked");
    //   setCheckedQuestionEight("checked");
    //   setCheckedQuestionNine("unchecked");
    //   slideChange(e);
    // }
    // if (e.target.value === "177") {
    //   setCheckedQuestionOne("unchecked");
    //   setCheckedQuestionTwo("unchecked");
    //   setCheckedQuestionThree("unchecked");
    //   setCheckedQuestionFour("unchecked");
    //   setCheckedQuestionFive("unchecked");
    //   setCheckedQuestionSix("unchecked");
    //   setCheckedQuestionSeven("unchecked");
    //   setCheckedQuestionEight("unchecked");
    //   setCheckedQuestionNine("checked");
    //   slideChange(e);
    // }
    if (e.target.value === "178") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("unchecked");
      // setCheckedQuestionThree("unchecked");
      // setCheckedQuestionFour("unchecked");
      // setCheckedQuestionFive("unchecked");
      // setCheckedQuestionSix("unchecked");
      // setCheckedQuestionSeven("unchecked");
      // setCheckedQuestionEight("unchecked");
      // setCheckedQuestionNine("unchecked");
      setCheckedQuestionTen("checked");
      slideChange(e);
    }
    setCreditCard(e.target.value)
  };
  return (
    <>
      <div id="slide-1" className={`${className}`}>
        <ProgressBar value="10" />

        <h3> {textHeading} </h3>
        <div className="col-lg-12 mb-pd text-center">
          <div className="btn-comm">
            <RadioButton
              value="173"
              name="productclaim"
              className="radio regNextBtn"
              labelName="Aquacard"
              labelClassName={`option_but next1a ${checkedQuestionOne}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
        </div>

        <div className="col-lg-12 mb-pd text-center">
          <div className="btn-comm">
            <RadioButton
              value="174"
              name="productclaim"
              className="radio regNextBtn"
              labelName="Marbles"
              labelClassName={`option_but next1a ${checkedQuestionTwo}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
        </div>

        <div className="col-lg-12 mb-pd text-center">
          <div className="btn-comm">
            <RadioButton
              value="178"
              name="productclaim"
              className="radio regNextBtn"
              labelName="Other"
              labelClassName={`option_but next1a ${checkedQuestionTen}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
        </div>
        <div className="col-lg-12 mb-pd text-center">
          <p className="text-opt text-opt-lend"><i>(Others includes Bip, Fluid, John Lewis & Partners, AO, Argos, Newpay & Pulse)</i></p>
        </div>

        {/* <div className="input-group justify-content-center mb-3">
        <fieldset class="field-opt field-opt-lgnd">
              <legend class="title">
                One of their Merchant Offerings
              </legend>
                <RadioButton
                  value="169"
                  name="productclaim"
                  className="radio"
                  labelName="John Lewis & Partners"
                  labelClassName={`option_but next1a fieldoption ${checkedQuestionFive}`}
                  onClick={nextSlide}
                  validation={validation()}
                />

                  <RadioButton
                  value="175"
                  name="productclaim"
                  className="radio"
                  labelName="Bip"
                  labelClassName={`option_but next1a fieldoption ${checkedQuestionThree}`}
                  onClick={nextSlide}
                  validation={validation()}
                />

                <RadioButton
                  value="176"
                  name="productclaim"
                  className="radio"
                  labelName="Fluid"
                  labelClassName={`option_but next1a fieldoption ${checkedQuestionFour}`}
                  onClick={nextSlide}
                  validation={validation()}
                />
                <RadioButton
                  value="171"
                  name="productclaim"
                  className="radio"
                  labelName="AO"
                  labelClassName={`option_but next1a fieldoption ${checkedQuestionSix}`}
                  onClick={nextSlide}
                  validation={validation()}
                />
                <RadioButton
                  value="170"
                  name="productclaim"
                  className="radio"
                  labelName="Argos"
                  labelClassName={`option_but next1a fieldoption ${checkedQuestionSeven}`}
                  onClick={nextSlide}
                  validation={validation()}
                />
                  <RadioButton
                  value="172"
                  name="productclaim"
                  className="radio"
                  labelName="Newpay"
                  labelClassName={`option_but next1a fieldoption  ${checkedQuestionEight}`}
                  onClick={nextSlide}
                  validation={validation()}
                />
                <RadioButton
                  value="177"
                  name="productclaim"
                  className="radio"
                  labelName="Pulse"
                  labelClassName={`option_but next1a fieldoption ${checkedQuestionNine}`}
                  onClick={nextSlide}
                  validation={validation()}
                />
         </fieldset>
         </div> */}
      </div>
    </>
  );
};
export default ProductClaim;
