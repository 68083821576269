import React from 'react';
import ProgressBar from './ProgressBar';
import { Salutation } from '../../../../Constants/Questions';
import TextField from '../../../UI/TextField';
import SelectBox from '../../../UI/SelectBox';
import InputButton from '../../../UI/InputButton';
import { DateOfBirth } from '../../../../Utility/DateOfBirth';

const Slide12 = ({
  className,
  slideChange,
  validation,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  slideChangeAddress,
  splitForm,
  backClick,
  setError,
}) => {

  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = (e.target.name == 'txtFName') ? 'First Name' : 'Last Name';
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is Invalid. Please Recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };

  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    var lstSalutationResult = await trigger("lstSalutation");

    var txtFNameResult;
    var txtLNameResult;
    var lstDobDayResult;
    var lstDobMonthResult;
    var lstDobYearResult;
    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    }
    else {
      document.getElementById("lstSalutation").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    }
    else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      lstDobDayResult = await trigger("DobDay");
    }
    else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("DobMonth");
    }
    else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("DobYear");
    }
    else {
      document.getElementById("DobMonth").focus();
      return false;
    }
    if (lstDobYearResult) {

    } else {
      document.getElementById("DobYear").focus();
    }


    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      slideChange(e);
    } else {
      return false;
    }
  };


  return (
    <>
      <div id="slide12" className={`col-12 top_minus ${className}`}>
        <ProgressBar value={70} />
        <h3><b>Please enter your FULL NAME {"&"} DATE OF BIRTH{" "}</b></h3>
        <div className="mb-3 mt-3 col-12 p-0">
          <h4>This information is used to locate the car finance</h4>
          <SelectBox
            className="form-select form-control"
            OptionValue={Salutation}
            name="lstSalutation"
            id="lstSalutation"
            onChange={() => clearErrors("lstSalutation")}
            clearErrors={clearErrors}
            myRef={validation({ required: "Please Select Title" })}
            validationMsg={
              validationMsg.lstSalutation &&
              validationMsg.lstSalutation.message
            }
          ></SelectBox>

        </div>
        <div className="col-12 mb-3 mt-3 p-0">

          <div className="input-group ">
            <TextField
              type="text"
              className="form-control"
              placeholder="First Name"
              name="txtFName"
              id="txtFName"
              onBlur={namevalidate}
              validation={validation({
                required: "Please Enter First Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid First Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}

            ></TextField>
            <div className="input-group-append">
              <span className="input-group-text">
                <img src="/assets/MB_UBRF_2/img/privacy.png" alt="" />
              </span>
            </div>

          </div>
          {validationMsg.txtFName && (
            <span
              className="error_msg "
              id="email_err"
            >
              {validationMsg.txtFName.message}
            </span>
          )}

        </div>
        <div className="col-12 mb-3 mt-3 p-0">

          <div className="input-group ">
            <TextField
              type="text"
              className="form-control"
              placeholder="Last Name"
              name="txtLName"
              id="txtLName"
              onBlur={namevalidate}
              validation={validation({
                required: "Please Enter Last Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid Last Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}

            ></TextField>
            <div className="input-group-append">
              <span className="input-group-text">
                <img src="/assets/MB_UBRF_2/img/privacy.png" alt="" />
              </span>
            </div>
          </div>
          {validationMsg.txtLName && (
            <span
              className="error_msg "
              id="email_err"
            >
              {validationMsg.txtLName.message}
            </span>
          )}
        </div>

        <DateOfBirth
          textDob="Date Of Birth"
          validation={validation}
          validationMsg={validationMsg}
        />

        <div className="col-lg-12 mb-pd text-center ">
          <InputButton
            name="basicDetails"
            className="btn next08 regNextBtn"
            id=""
            value="NEXT"
            btnType="button"
            onClick={basicDetailsValidation}
          />
        </div>
        <p className="text-center">
          <a href="#" className="back11" id="back11" name="back11" onClick={backClick}>
            {'<< Previous'}
          </a>
        </p>
      </div>
    </>
  );
};

export default Slide12;