import React, { useEffect, useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import Select from "react-select";
import RadioButton from "../../../UI/RadioButton";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import { useOpenBanking } from "../../../../Hooks/useOpenBanking";
import { useHistory } from "react-router-dom";
import Button from "../../../UI/Button";

const BankName = ({
  validation,
  getValues,
  lenderList,
  setBankName,
  allFollowupData,
  loader,
  setLoader,
  userDetails,
  pushPageChange,
  setSelectBank,
  selectBank,
  matchCheckFn,
  addPending,
  className,
  saveInputAns
}) => {
  const [BankValidationMsg, setBankValidationMsg] = useState(false);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const agreementDate = localStorage?.getItem("agreementDate");
  const { getCustomerCheck, milestoneChase } = useOpenBanking();
  const [defaultBank, setDefaultBank] = useState(null);
  const history = useHistory();
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 50,
    }),
  };

  const localBudLender = localStorage.getItem("bud_lender");

  const formData = getValues();

  let selectedBank = null;

  const nextSlide = async (e) => {
    let LengthSelectedBank = selectBank.length;
    let ValueSelectedBank = selectBank.value;
    if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
      setBankValidationMsg("Please Select Bank");
    } else {
      localStorage.setItem("bud_lender", selectBank.value);
      matchCheckFn(selectBank);
      saveInputAns(e);
    }
  };

  function handleSelect(opt) {
    setSelectBank(opt);
    setDefaultBank(opt)
    setBankValidationMsg(false);
  }

  useEffect(() => {
    if (selectedBank != null) {
      let opt = { label: localBudLender, value: selectedBank };
      setDefaultBank(opt);
      setSelectBank(opt);
    }
  }, [selectedBank]);

  let BankList = [];

  if (lenderList.length > 0) {
    BankList = BankList.concat(
      lenderList
        .filter(function (val) {
          return (
            !val.display_name.endsWith("Card") &&
            !val.display_name.endsWith("Business") &&
            !val.display_name.endsWith("card") &&
            !val.display_name.endsWith("Cards")
          );
        })
        .map(function (val) {
          if (val.provider == localBudLender) {
            selectedBank = val.provider;
          }
          return {
            value: val.provider,
            label: val.display_name,
          };
        })
    );
  }

  return (
    <>
      {/* <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">
              At the time you secured your loan with MoneyBarn which bank(s) do
              you use to manage your income and expenditure?
            </h1>
            <div className="row mt-4">
              <div className="col-lg-12">
                <div className="mb-3 text-start">
                <Select
                  placeholder="Select Bank Name"
                  options={BankList}
                  aria-label="Username"
                  name="bankName"
                  id="bankName"
                  value={defaultBank}
                  onChange={handleSelect}
                  isSearchable={true}
                  styles={customStyles}
                />
                  {BankValidationMsg && (
                    <span className="error_msg">{BankValidationMsg}</span>
                  )}
                </div>

                <div>
                  <button
                    name="bankname"
                    type="button"
                    id={"bankname"}
                    className="com-btn btn-next w-100 mb-3"
                    labelName="Next"
                    labelClassName="com-btn w-100"
                    onClick={nextSlide}
                    validation={validation()}
                  >
                    {"Next"}
                  </button>
                </div>

                <div class="text-center back">
                    <Button 
                    className="btn btn-link mt-2 h2" 
                    id="back1" 
                    type="button"
                    buttonText="<< Previous"
                    onClick={()=>addPending("bank_select")}
                    />
                </div>
             
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* ...new  */}
      <div className={className}>
        <div className="item qs-item1 px-3 mt-4">
          <h2 className="fw-bold title mt-4 mb-4">
          Which bank were you with when you took the finance with Moneybarn?
          </h2>
          <div className="form-section">
            <div className="mb-3">
              <div className="input-section mb-4 drop-down-multi">
                <Select
                  placeholder="Select Bank Name"
                  options={BankList}
                  aria-label="Username"
                  name="bankName"
                  id="bankName"
                  value={defaultBank}
                  onChange={handleSelect}
                  isSearchable={true}
                  styles={customStyles}
                />
                {BankValidationMsg && (
                  <span className="error_msg">{BankValidationMsg}</span>
                )}
              </div>
              <div className="mb-4 col-lg-12 mb-pd text-center ">
                <div>
                  <button
                    type="button"
                    id={"bankname"}
                    className="btn-question nextvehl btn-comm w-100"
                    labelName="Next"
                    labelClassName="com-btn w-100"
                    onClick={nextSlide}
                    validation={validation()}
                    data-quest-id={64} 
                    data-ans-id={null}
                    data-ans-label={null}
                    name="question_64"
                  >
                    {"Next"}
                  </button>
          
                </div>
              </div>
              {/* <div class="text-center back">
                <Button
                  className="btn btn-link mt-2 h2"
                  id="back1"
                  type="button"
                  buttonText="<< Previous"
                  onClick={() => addPending("bank_select")}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BankName;
