import React,{useState} from "react";
import { ClosedYears2 } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import InputButton from "../../../UI/InputButton";
import {  Modal } from "react-bootstrap";
const WhatYearHappen = ({
    clsName,
    saveInputAns,
    validationMsg,
    clearErrors,
    validation,
    trigger,
    getValues,
    backClick
}) => {
    const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);

    const selectedYear = async (e) => {
        let errorFlag = 0;
        let yearresult = await trigger("whatYearThisHappen");

        if (!yearresult) {
            errorFlag = 1;
            return false;
        }
        const data = getValues();
        let yearSelected = data.whatYearThisHappen;
        // if (yearSelected < 2019) {
        //   setshowClaimModal(true);
        // } else {
            saveInputAns(e);
        // }
    }
    return (
        <>
            <div className="question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2">
                <div className="row">
                    <div className="col-lg-8 mb-2">
                        <SelectBox
                            className="form-select"
                            OptionValue={ClosedYears2}
                            name="whatYearThisHappen"
                            id="whatYearThisHappen"
                            onChange={() => clearErrors("whatYearThisHappen")}
                            clearErrors={clearErrors}
                            myRef={validation({ required: "Please select year" })}
                            validationMsg={
                                validationMsg.whatYearThisHappen && validationMsg.whatYearThisHappen.message
                            }
                        ></SelectBox>
                    </div>
                    <div className="col-lg-4 mb-2">
                        <button
                          type="button"
                          className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn " 
                          id="nextscroll"
                          name="WhatYearThisHappen"
                          onClick={selectedYear}
                          data-quest-id={72}
                          >
                        Next &#8594; 
                             </button>
                    </div>
                </div>
                <p className="text-center bck-marwid">
                    <button
                      name="SoldOnDebt"
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>
            </div>
            <Modal size="md" show={claimModal} onHide={handleCloseclaimModal} centered backdrop="static" className="modal fade " id="cannotClaim">
                      <div className="modal-content">
        <Modal.Body>
        <div className="text-center my-3">
  <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.5 9C8.22386 9 8 8.77614 8 8.5C8 8.22386 8.22386 8 8.5 8C8.77614 8 9 8.22386 9 8.5C9 8.77614 8.77614 9 8.5 9Z" fill="#EF8019" stroke="#EF8019" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M15.5 9C15.2239 9 15 8.77614 15 8.5C15 8.22386 15.2239 8 15.5 8C15.7761 8 16 8.22386 16 8.5C16 8.77614 15.7761 9 15.5 9Z" fill="#EF8019" stroke="#EF8019" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#EF8019" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.5 15.5C7.5 15.5 9 13.5 12 13.5C15 13.5 16.5 15.5 16.5 15.5" stroke="#EF8019" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
</div>

          <h4 className="text-center my-4">Sorry, we cannot accept your claim</h4>
          <div className="input-group  prevname-Btnpop justify-content-center">
            <InputButton
              className="btn-comm2modalreject py-lg-3 py-md-3 py-sm-2 py-2 w-100"
              name="modalBack"
              value="Close"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
          </Modal.Body>
          </div>
      </Modal> 
        </>
    )
}
export default WhatYearHappen;