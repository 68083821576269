import { Api } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";

export const userMilestoneUpdate = () => {
  const updateMilestone = async (
    uuid,
    t2aStatus,
    followup_data
  ) => {
    const response = await Api.post("api/v1/update-milestone", {
      uuid,
      t2a_status: t2aStatus,
      followup_data
    });
    return response;
  };
  return { updateMilestone };
};
