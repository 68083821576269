import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
const CardOverSixYears = ({ onSelectAnswer }) => {

  const optionSelected = async (e) => {
    onSelectAnswer(e);
  };
  return (
    <div className="radio-box question animated fadeInUp">
      <RadioButtonQuest
        className="radio-button"
        onClick={optionSelected}
        value="236"
        name="cardOverSixYears"
        id="cardOverSixYears"
        dataQuestId={81}
        dataAnsId={236}
        labelName={"Yes"}
        labelClassName="option_but next-2"
      />
      <RadioButtonQuest
        className="radio-button"
        onClick={optionSelected}
        value="237"
        name="cardOverSixYears"
        id="cardOverSixYear"
        dataQuestId={81}
        dataAnsId={237}
        labelName={"No"}
        labelClassName="option_but next-2"
      />
    </div>
  );
};
export default CardOverSixYears;
