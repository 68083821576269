import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const EmploymentStatusNEW_OPT = ({
    className,
    slideChange,
    validation,
    backClick,
    Year,
    getValues,
    skip
}) => {
    const [checkedOne, setCheckedOne] = useState("unchecked");
    const [checkedTwo, setCheckedTwo] = useState("unchecked");
    const [checkedThree, setCheckedThree] = useState("unchecked");
    const [checkedFour, setcheckedFour] = useState("unchecked");
    const [checkedFive, setcheckedFive] = useState("unchecked");
    const [checkedSix, setCheckedSix] = useState("unchecked");
    const [checkedSeven, setCheckedSeven] = useState("unchecked");
    const [checkedEight, setCheckedEight] = useState("unchecked");
    const [checkedNine, setCheckedNine] = useState("unchecked");
    const [checkedTen, setCheckedTen] = useState("unchecked");
    const [checkedEleven, setCheckedEleven] = useState("unchecked");


    const prevClick = (e) => {
        localStorage.removeItem("employment_status");
        backClick(e);
    
       }

    const nextSlide = async (e) => {

        console.log("employment_status: " + e.target.getAttribute('data-ans-label'));
        localStorage.setItem("employment_status", e.target.getAttribute('data-ans-label'));
        
        if (e.target.value === "3") {
            setCheckedOne("checked");
            slideChange(e);
        } else {
            setCheckedOne("unchecked");
        }

        if (e.target.value === "4") {
            setCheckedTwo("checked");
            slideChange(e);
        } else {
            setCheckedTwo("unchecked");
        }

        if (e.target.value === "5") {
            setCheckedThree("checked");
            slideChange(e);
        } else {
            setCheckedThree("unchecked");
        }

        if (e.target.value === "6") {
            setcheckedFour("checked");
            slideChange(e);
        } else {
            setcheckedFour("unchecked");
        }

        if (e.target.value === "199") {
            setcheckedFive("checked");
            slideChange(e);
        } else {
            setcheckedFive("unchecked");
        }

        if (e.target.value === "200") {
            setCheckedSix("checked");
            slideChange(e);
        } else {
            setCheckedSix("unchecked");
        }
        if (e.target.value === "201") {
            setCheckedSeven("checked");
            slideChange(e);
        } else {
            setCheckedSeven("unchecked");
        }
        if (e.target.value === "202") {
            setCheckedEight("checked");
            slideChange(e);
        } else {
            setCheckedEight("unchecked");
        }
        if (e.target.value === "203") {
            setCheckedNine("checked");
            slideChange(e);
        } else {
            setCheckedNine("unchecked");
        }
        if (e.target.value === "204") {
            setCheckedTen("checked");
            slideChange(e);
        } else {
            setCheckedTen("unchecked");
        }
        if (e.target.value === "205") {
            setCheckedEleven("checked");
            slideChange(e);
        } else {
            setCheckedEleven("unchecked");
        }


    };
    return (
        <>
            <div id="slide7" className={`${className}`}>
                <ProgressBar value="35" />

                {/* <h3> {textHeadingLine1} </h3> */}
                <h3>
                    {" "}
                    {skip == true
                        ? `At the time you secured finance, what was your employment status?`
                        : `In ${Year}, at the time you secured finance, what was your employment status?`}{" "}
                </h3>
                <div className="btn-comm">
                    <RadioButton
                        value="3"
                        name="employment"
                        className="radio-button"
                        labelName="Employed"
                        labelClassName={`option_but  next7 ${checkedOne}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Employed"
                    />

                    <RadioButton
                        value="199"
                        name="employment"
                        className="radio-button"
                        labelName="Unemployed"
                        labelClassName={`option_but  next7  ${checkedFive}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Unemployed"
                    />
                    <RadioButton
                        value="4"
                        name="employment"
                        className="radio-button"
                        labelName="Self-employed"
                        labelClassName={`option_but  next7 ${checkedTwo}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Self-employed"
                    />

                    <RadioButton
                        value="5"
                        name="employment"
                        className="radio-button"
                        labelName="Student"
                        labelClassName={`option_but  next7 ${checkedThree}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Student"
                    />

                    <RadioButton
                        value="6"
                        name="employment"
                        className="radio-button"
                        labelName="Retired"
                        labelClassName={`option_but  next7 ${checkedFour}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Retired"
                    />
          
                    <RadioButton
                        value="200"
                        name="employment"
                        className="radio-button"
                        labelName="Benefits main source of income"
                        labelClassName={`option_but  next7 ${checkedSix}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Benefits main source of income"
                    />

                    <RadioButton
                        value="201"
                        name="employment"
                        className="radio-button"
                        labelName="Part-time job"
                        labelClassName={`option_but  next7 ${checkedSeven}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Part-time job"
                    />

                    <RadioButton
                        value="202"
                        name="employment"
                        className="radio-button"
                        labelName="Temporary worker"
                        labelClassName={`option_but  next7 ${checkedEight}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Temporary worker"
                    />

                    <RadioButton
                        value="203"
                        name="employment"
                        className="radio-button"
                        labelName="Long Term Sick"
                        labelClassName={`option_but  next7 ${checkedNine}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Long Term Sick"
                    />

                    <RadioButton
                        value="204"
                        name="employment"
                        className="radio-button"
                        labelName="Maternity"
                        labelClassName={`option_but  next7 ${checkedTen}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Maternity"
                    />

                    <RadioButton
                        value="205"
                        name="employment"
                        className="radio-button"
                        labelName="Rolling/Zero hours contract"
                        labelClassName={`option_but  next7 ${checkedEleven}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Rolling/Zero hours contract"
                    />


                </div>
                <p className="text-center">
                    <a className="back01" name="back06" onClick={prevClick}>
                        {" "}
                        {"<< Previous"}{" "}
                    </a>
                </p>
            </div>
        </>
    );
};
export default EmploymentStatusNEW_OPT;
