import {Api} from '../api/Api';
import {useErrorWebhook} from "./useErrorWebhook";


export const useUnqualify = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
    const unqualifyUser = async (uuid,page_name) => {
      const response = await Api.post("api/v1/unqualify-user", {
        uuid,
        page_name
      }).catch(function (error) {
        const erroMessage = {
          serviceName : 'USER UNQUALIFIED',
          errorReason:error.message,
          errorCode:error.code,
          uuid:uuid
        }
        ErrorWebhookTrigger(erroMessage);
    });
      return response;
    };

    return { unqualifyUser };
  };
  