import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
const AccordSection = () => {
  return (
    <>
      <section className="faqsection py-5">
        <div className="container">   
          <div className="row title-sec">
            <div className="col-lg-2 col-md-2 col-sm-2 col-3 text-center">
              <img src="assets/MB_UFGN_4/img/icon-faq.png" alt="" />
            </div>
            <div className="col-lg-10 col-md-10 col-sm-10 col-9 ps-lg-0 ps-md-0 ">
              <h2 className="pt-lg-3 pt-md-3 pt-sm-3 pt-0">Your questions about TMS Responsible Lending answered</h2>
              <p>Your questions about TMS Responsible Lending answered Click on a question below to view the answer.</p>
            </div>
          </div>
          <div className="accordion my-lg-5 my-md-5 my-sm-4 my-3">
            <div className="col-12 experts1">
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    How much time does the claims process take?
                  </Accordion.Header>
                  <Accordion.Body>
                    Generally, it will take approximately 8 to 12 weeks from
                    receipt of your claim form until your payout. However, if a
                    lender disputes any part of a claim and it is referred to
                    the Financial Ombudsman Service (FOS), it could extend your
                    claim period up to 12 months.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>
                    Are there any upfront fees?
                  </Accordion.Header>
                  <Accordion.Body>
                    No, we will not ask for any money upfront. We’ll start your claim for FREE and if your claim is successful we charge 45% commission (VAT included).
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>
                    Is there a deadline to file a claim?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, the deadline to file a claim is 6 years from the date you had your loan, but there are
                    exceptions. Please note that if your lender has gone into administration it will affect the
                    amount you receive.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>
                    What evidence is required to get started?
                  </Accordion.Header>
                  <Accordion.Body>
                    Getting started is quite easy. We simply need you to provide
                    some basic information such as your full name, the address
                    where you resided during the term of the loan(s) and the
                    name of your lender. Bank statements can be very useful.
                    However, we can request these for you. A copy of your Credit Report can also be very useful which you can easily obtain by using the free tool.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>
                    Can I still file a claim if I’ve had loans from more than
                    one lender?
                  </Accordion.Header>
                  <Accordion.Body>
                    If the Packaged Bank Account is established as mis-sold normal practice would be to refund all the premiums you have made for the account and add 8% interest on top.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>
                    Will the claim impact my Credit Score?
                  </Accordion.Header>
                  <Accordion.Body>
                    In the event of a successful claim, we’ll ask the lender to
                    remove the loan from your credit record. Your lender is
                    required to contact the credit reference agency as we cannot
                    do it on their behalf.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="6">
                  <Accordion.Header>
                    Should I expect you to contact my employer?
                  </Accordion.Header>
                  <Accordion.Body>
                    No, we will never contact your employer regarding your
                    claim. Our claims process is confidential and we will only
                    share the information you provide with your lender(s),
                    Credit Reference Agencies and the Financial Ombudsman (FOS)
                    if required.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="7">
                  <Accordion.Header>
                    Do I need to continue paying the lender while the claim is
                    ongoing?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, we advise to continue making payments to your lender
                    during this time unless you’ve been told otherwise by the
                    lender or administrator. Failing to continue your payments
                    can result in a negative credit rating and the possibility
                    of debt collection agencies getting involved.
                    {/* Yes, you should continue making payments to your lender during this time unless you’ve been told otherwise by the lender or administrator. Failing to continue your payments can result in a negative credit rating and the possibility of debt collection agencies getting involved. */}
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="8">
                  <Accordion.Header>
                    Is it possible to get a refund without using a claims
                    company?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, you can file a claim for free without using a Claims
                    Management Company by writing directly to your lender(s) and
                    requesting a refund. If your complaint is rejected, you can
                    send your claim to the Financial Ombudsman Service.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="9">
                  <Accordion.Header>
                    Why should I use a claims company to file a claim?
                  </Accordion.Header>
                  <Accordion.Body>
                    Filing yourself can be such a headache! You can save
                    yourself from the hassle by using our service. We have an
                    experienced team of professionals that are ready to manage
                    your claim and provide you with the best chance for a
                    successful claim.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="10">
                  <Accordion.Header>
                    Will you keep me updated throughout the process?
                  </Accordion.Header>
                  <Accordion.Body>
                    Yes, our team will contact you during the initial 8 week
                    period to update you on the status of your claim. Once the
                    lender has contacted us, we’ll contact you again to let you
                    know. Our team will be ready to answer any questions you may
                    have throughout the process. In the event that your case
                    goes to the Financial Ombudsman Service (FOS), we will be in
                    touch to let you know what to expect.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>

      </section>
    </>
  );
};

export default AccordSection;
