import React, { useEffect, useState } from "react";
import "../../../../assets/FollowupFos/css/customfos.scss";
import "../../../../assets/FollowupFos/css/stylefos.scss";
import "../../../../assets/FollowupFos/css/homefos.scss";
import "../../../../assets/FollowupFos/css/questionnairefos.scss";
import "../../../../assets/FollowupFos/css/signaturefos.scss";

const ThankyouExtFos = () => {
  let user_id = localStorage.getItem('user_id');

  return (
    <>
      <div className="FollowupFosV1">
        <div className="tms-wrap bg-main-pr">
          <div className="main text-center main-div "  >
            <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
              <img height="65" src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt="" />
            </div>
            <div className="main-form-wraper claim-id-wraper ">
              <div><img height="150" src="assets/img/user.gif" alt="" /></div>
              <h3 className="">We have already received your application and our team is currently in the process of reviewing the submitted materials.</h3>
              <div className="claim-id my-4">  <span className="in">Your claim ID is <span className="text-danger">{user_id}</span> </span></div>
              <div>Please email info@themoneysolicitors.com for more information.</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankyouExtFos;