import { useHistory } from "react-router-dom";
import { UUID } from "./UUID";
import { urlParams } from "../Utility/QueryString";

export const CheckUUID = () => {
  const full_url = window.location.href;
  const params = Object.fromEntries(urlParams.entries());
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const splitName = query.get("split_name");
  const { fetchUuid } = UUID();
  const isCheck = () => {
    var flag = false;
    var localFlag = false;
    if (!localStorage.getItem('uuid')) {  ///
      const uuid = fetchUuid(); /// If localstorage missing uuid-fetch uuid
      if(uuid !=null){ ///
      localStorage.setItem("uuid", uuid);///
      } ///
    } ///
    if (!params.hasOwnProperty("uuid") || (params.hasOwnProperty("uuid") && !urlParams.get("uuid")) || (params.hasOwnProperty("uuid") && urlParams.get("uuid") === '""')) {
      flag = true;
    }
    if (!localStorage.getItem('uuid')) {
      localFlag = true; // if localStorage still  miss uuid 
    }
    if (flag && localFlag) {
      history.push("/Not_Found");
      return false;
    } else {
      const uuid = fetchUuid();
      const currentUrl = new URL(window.location.href);
      currentUrl.searchParams.set('uuid', uuid);
      if (splitName !== null) {
        currentUrl.searchParams.set('split_name', splitName);
      } 
      window.history.pushState({}, '', currentUrl);
      return true;
    }
  }
  return { isCheck }
}