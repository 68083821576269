import React, { useState } from "react";
import { ClosedYears2 } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import InputButton from "../../../UI/InputButton";
import { Modal } from "react-bootstrap";
import Select from "react-select";
const Banks = ({
  clsName,
  saveInputAns,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
  SelectBank,
  setSelectBank,
  lenderList,
  oldCard,
  latePay
}) => {
  const [BankValidationMsg, setBankValidationMsg] = useState(false);

  let toggle;
  if(oldCard == "237"){
    toggle = "card_over_six_years";
  } else if(latePay == "239"){
    toggle = "late_payment_letter"
  } else if(latePay == "238"){
    toggle = "problem"
  }
  const customStyles = {
    control: base => ({
      ...base,
      //height: 50,
      minHeight: 50
    })
  };

  const nextSlide = async (e) => {
    let LengthSelectedBank = SelectBank.length;
    let ValueSelectedBank = SelectBank.value;

    if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
      setBankValidationMsg("Please select your Bank");
    } else {
      localStorage.setItem("bud_lender", ValueSelectedBank);
      localStorage.setItem("lenderArray", JSON.stringify([SelectBank]));
      saveInputAns(e);
    }
  }
  function handleSelect(opt) {
    setSelectBank(opt);
    setBankValidationMsg(false);
  }
  let BankList = [];
  if (lenderList.length > 0) {
    BankList = BankList.concat(lenderList.filter(function (val) {
      return !val.display_name.endsWith("Card") &&
        !val.display_name.endsWith("Business") &&
        !val.display_name.endsWith("card") &&
        !val.display_name.endsWith("Cards");
    }).map(function (val) {
      return {
        value: val.provider,
        label: val.display_name
      };
    }));
  }
  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2">
        <div className="row">
          <div className="col-lg-8 mb-2 z-3">
            <Select
              placeholder="Select Bank"
              options={BankList}
              aria-label="Username"
              name="bankName"
              id="bankName"
              value={SelectBank}
              onChange={handleSelect}
              isSearchable={true}
              styles={customStyles}
            //isMulti

            />
            <span id="bank_err" className="error_msg">{BankValidationMsg}</span>
          </div>
          <div className="col-lg-4 mb-2">
            <button
              type="button"
              className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
              id="nextscroll"
              name="banks"
              onClick={nextSlide}
              data-quest-id={64}
            >
              Next &#8594;
            </button>
          </div>
        </div>
        <p className="text-center bck-marwid">
          <button
            name={toggle}
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}

          >Go Back</button>
        </p>
      </div>

    </>
  )
}
export default Banks;