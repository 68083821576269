import React from "react";
import loader_review2 from "../../../../assets/Quest_VAQ_4/img/loader-review2.svg";
const Loader = ({showUserName,showLoad}) => {
    return (
        <>
            <div className={`loader-wrap text-center text-light ${showLoad} `}>
                <div>
                    <img style={{height:80}} src={loader_review2} alt />
                    <h6 className="mt-3">{showUserName} we need a few more details before we can proceed.</h6>
                    <p>(Don't worry, it will only take a minute.)</p>
                </div>
            </div>

        </>
    )
}
export default Loader;