import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const Complaint = ({
  className,
  slideChange,
  textHeadingLine1,
  textHeadingLine2,
  validation,
  backClick,
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const nextSlide = async (e) => {
    if (e.target.value === "19") {
      setCheckedOne("checked");
      slideChange(e);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "20") {
      setCheckedTwo("checked");
      slideChange(e);
    } else {
      setCheckedTwo("unchecked");
    }
  };
  return (
    <>
      <div id="slide-8" className={`${className}`}>
        <ProgressBar value="65" />

        <h3> {textHeadingLine1} </h3>
        <h4> {textHeadingLine2} </h4>
        <div className="radio_buttons">
          <RadioButton
            value="19"
            name="complaint"
            className="radio regNextBtn"
            labelName="Yes"
            labelClassName={`radio ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="20"
            name="complaint"
            className="radio regNextBtn"
            labelName="No"
            labelClassName={`radio ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <p className="text-center">
          <span
            className="back-btn btn btn-link"
            name="back07"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div>
    </>
  );
};
export default Complaint;
