import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import InputButton from "../../../../UI/InputButton";
import Modal from "react-bootstrap/Modal";

const IncomeConfirmationVAQ_UB_4 = ({ onselectAnswer, reff, display, trigger }) => {
  const labelclicked = async (e) => {
      onselectAnswer(e);
  };



  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>
          Would you also like to share your income and expenditure at the time you took out the finance with Vanquis?
          </h3>
          <RadioButtonQuest
            key="Yes"
            className="radio-button"
            labelName="Yes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="213"
            name="question_75"
            id="inyes"
            dataQuestId={75}
            dataAnsId={213}
            dataAnsLabel="Yes"
          />
          <RadioButtonQuest
            key="No"
            className="radio-button"
            labelName="No"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="214"
            name="question_75"
            id="inno"
            dataQuestId={75}
            dataAnsId={214}
            dataAnsLabel="No"
          />
        </div>
      </div>
    </>
  );
};

export default IncomeConfirmationVAQ_UB_4;
