import {Api} from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const useFollowupUserQuestions = () => {
    const { ErrorWebhookTrigger } = useErrorWebhook();

    const saveFollowupUserQuestions = async (question_data,followup_data,message_type) => {
        const questionnaireSubmit = await Api.post("/api/v1/data-ingestion-pipeline", {
            question_data,
            followup_data,
            message_type,
        }).catch(function (error) {
            const erroMessage = {
                serviceName : 'Data Ingestion : '+message_type,
                errorReason:error.message,
                errorCode:error.code,
            }
                ErrorWebhookTrigger(erroMessage);
            })
        return questionnaireSubmit;
    }
    return { 
       
        saveFollowupUserQuestions
    }
}