import React, { useRef, useState } from "react";
import CheckBox from "../../../UI/CheckBox";
import InputButton from "../../../UI/InputButton";
const ConfirmSituation = ({
  clsName,
  reff,
  onselectAnswer,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  optionSelected76,
  questArr,
  backClick,
}) => {
  const [showError, setshowError] = useState("hide");
  const [btndisabled, setBtndisabled] = useState(false);
  const checkboxRefs = useRef({});

  const labelclicked = async (e) => {
    let date_index = e.target.id;
    let selectedCount = optionSelected76.length;
    console.log("count=" + selectedCount);
    if (selectedCount == 0) {
      setBtndisabled(false);
      setshowError("show");
    } else {
      setBtndisabled(true);

      onselectAnswer(e);
    }
  };

  const onSelectOption = (e) => {
    console.log(e.target.value);
    if (e.target.checked === true) {
      setshowError("hide");
      optionSelected76.push({ id: +e.target.value });
      checkBoxDisable(e.target.value);
    } else {
      let deselect = e.target.value;
      const decre = optionSelected76.map((questArray, index) => {
        if (deselect == questArray.id) {
          optionSelected76.splice(index, 1);
          if (optionSelected76.length == 0) {
            checkBoxEnable();
          }
        }
      });
    }
  };

  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    e.target.value = e.target.value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  let checkArray = questArr.answers;

  const questionId = questArr.questionId ? questArr.questionId : 0;

  const lenderCheckBox = checkArray.map((checArr, index) => {
    let heightcls = checArr.value == "104" ? "heightnewcls" : "";
    return (
      <li
        className={`popupLink ${heightcls}`}
        key={checArr.value}
        ref={(e) => (checkboxRefs.current[checArr.value] = e)}
      >
        <CheckBox
          dataId={`confirmSituation${checArr.value}`}
          name="chkBankName[]"
          onChange={onSelectOption}
          className="custom-checkbox"
          CheckBoxText={checArr.label}
          value={checArr.value}
        />
      </li>
    );
  });

  const checkBoxDisable = (checkBoxId) => {
    if (checkBoxId != 225) {
      checkArray.map((checArr, index) => {
        checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
        checkboxRefs.current[checArr.value].childNodes[1].classList.remove(
          "disabledcheckbox"
        );
      });
      checkboxRefs.current[225].childNodes[0].disabled = true;
      checkboxRefs.current[225].childNodes[1].classList.add("disabledcheckbox");
    } else if (checkBoxId == 225) {
      checkArray.map((checArr, index) => {
        checkboxRefs.current[checArr.value].childNodes[0].disabled = true;
        checkboxRefs.current[checArr.value].childNodes[1].classList.add(
          "disabledcheckbox"
        );
      });
      checkboxRefs.current[225].childNodes[0].disabled = false;
      checkboxRefs.current[225].childNodes[1].classList.remove(
        "disabledcheckbox"
      );
    }
  };

  const checkBoxEnable = () => {
    checkArray.map((checArr, index) => {
      checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
      checkboxRefs.current[checArr.value].childNodes[1].classList.remove(
        "disabledcheckbox"
      );
    });
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>
          <b>{questArr.question}</b>
        </h3>

        <ul className="options secondop">{lenderCheckBox}</ul>
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <span className={`error_msg ${showError}`} id="email_err">
            Please Select At Least One
          </span>
          <InputButton
            id="nextscroll"
            btnType="button"
            className="btn-next"
            value="Next"
            dataQuestId={questionId}
            data-ans-id={null}
            data-ans-label={null}
            disabled={btndisabled}
            //disabled={btndisabled}
            onClick={labelclicked}
            name="btn_qest_76"
          />
        </div>
      </div>
    </>
  );
};
export default ConfirmSituation;
