import React from "react";
const Privacy_PolicyFooter = () => {
    return (
        <>
        <footer>
   <div className="container">
      <div className="row">
         <div className="col-lg-8 col-12">
            <p>
               TMS Legal Ltd is a limited company registered in England and Wales under company no: 10315962 Its registered office is 1 Poole Road, Bournemouth , Dorset, BH2 5QQ It is authorised and regulated by the Solicitors Regulation Authority, Number: 645041
            </p>
            <p>
               <a href="https://www.thesun.co.uk/money/10979059/moneybarn-repays-30million-6000-customers-unfairly-loans/" target="_blank" style={{ textDecoration: "none" }}>
                  *https://www.thesun.co.uk/money/10979059/moneybarn-repays-30million-6000-customers-unfairly-loans/
               </a>
            </p>

         </div>
      </div>
   </div>
</footer>
        </>
    )
}
export default Privacy_PolicyFooter;