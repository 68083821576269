import React from 'react';

const QuestionnaireFooter = ({percentage,onDownClick,onUpClick}) => {
    return (
        <>
            <section className="statusbar">
                <div className="container">
                <div className="row">
                    <div className="col-3 col-sm-3">
                        <img className="sec_img" src="/assets/Questionnier/img/SSL-Secure.PNG" alt=""/>   
                    </div>
                    <div className="col-6 col-sm-6">
                        <div className="progress-value">{percentage}% completed</div>
                        <div id="myProgress">
                            <div id="myBar" className="myBar" style={{width:`${percentage}%`}}></div>
                        </div>
                    </div>
                    <div className="col-3 col-sm-3 pl-0">
                        <a id="arrowdown" className="arrow-down" onClick={onDownClick}></a>
                        <a id="arrowup" className="arrow-up"onClick={onUpClick}></a>
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default QuestionnaireFooter;