import React from "react";
import { DobYear, DobMonth, DobDay } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
import { useFormContext } from "react-hook-form";

const DateOfBirth = ({
  textDob,
  validation,
  validationMsg,
  dob_day,
  dob_month,
  dob_yr,
  notDobValid
}) => {
  const { register, errors } = useFormContext();

  const removeClass = () => {
    document.getElementById("DobDay").classList.remove("pulse2");
    document.getElementById("DobDay").classList.remove("shadow2");
  };
  return (
    <div id="date-birth">
      <div className=" mt-3">
        <fieldset className="p-2 px-3">
          <legend className="px-2 m-0">
            {textDob}
          </legend>
          <div className="date-birth">
            {/* <div className="title" id="title">
          {textDob}
        </div> */}
            <div className="row">
              <div className="  mb-2 col-4   ">
                <SelectBox
                  className="form-control dob"
                  OptionValue={DobDay}
                  defaultValue={dob_day}
                  name="DobDay"
                  onChange={removeClass}
                  id="DobDay"
                  myRef={register({
                    required: "Please select date",
                  })}
                  validationMsg={errors.DobDay && errors.DobDay.message}
                />
                <i className="tick fa" style={{ display: "none" }}></i>
                {/* <span id="dobDay_err" className="error_msg"></span> */}
              </div>
              <div className="  mb-2 col-4   ps-lg-0">
                <SelectBox
                  className="form-control dob"
                  OptionValue={DobMonth}
                  defaultValue={dob_month}
                  name="DobMonth"
                  id="DobMonth"
                  myRef={register({
                    required: "Please select month",
                  })}
                  validationMsg={errors.DobMonth && errors.DobMonth.message}
                />
                <i className="tick fa" style={{ display: "none" }}></i>
                {/* <span id="dobMonth_err" className="error_msg"></span> */}
              </div>
              <div className="  mb-2 col-4    ps-lg-0">
                <SelectBox
                  className="form-control dob"
                  OptionValue={DobYear}
                  defaultValue={dob_yr}
                  name="DobYear"
                  id="DobYear"
                  myRef={register({
                    required: "Please select year",
                  })}
                  validationMsg={errors.DobYear && errors.DobYear.message}
                />
                <i className="tick fa" style={{ display: "none" }}></i>
                {/* <span id="dobYear_err" className="error_msg"></span> */}
              </div>
              {/* <span id="dob_final_err" className="error_msg"></span> */}
              {notDobValid && (
                <span id="dob_final_err" className="error_msg">
                  Invalid date of birth provided.
                </span>
              )}
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default DateOfBirth;
