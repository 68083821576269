import React, { useEffect } from 'react';
import { getParameterByName } from './Helper';
import ReactPixel from 'react-facebook-pixel';

const FbViewPixel = () => {
  const field = 'pid';
  const full_url = window.location.href;
  const query_string_arr = full_url.split("?");
  var queryString = "";
  if (query_string_arr != null) {
    queryString = query_string_arr[1];
  }
  useEffect(() => {
    if (queryString != null) {
      if (queryString.indexOf('&' + field + '=') !== -1) {
        const pid = getParameterByName(field, queryString);
        const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };
        ReactPixel.init(pid, advancedMatching, options);
        ReactPixel.pageView();
      }

    }
  }, [queryString]);
  return (
    <>
    </>
  )
}

export default FbViewPixel;