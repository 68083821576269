import React, { useState, useEffect } from "react";
import TextArea from "../../../UI/TextArea";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const Aware = ({
    validationMsg,
    clearErrors,
    validation,
    trigger,
    getValues,
    backClick,
    saveInputAns,
    onselectAnswer,
    setValue,
    reff,
    clsName,
    lender,
    setError
}) => {

    const [checkedThree, setCheckedThree] = useState("unchecked");
    const [textShow, setTextShow] = useState('hide');

    const optionHandle = async (e) => {
        if (e.target.value == "242" || e.target.value == "243") {
            setCheckedThree('');
            setTextShow('hide');
            setValue("aware_reason", null);
            onselectAnswer(e);
        } else {
            if (textShow == 'show') {
                setCheckedThree('');
                setTextShow('hide');
                setValue("aware_reason", null);
            } else {
                setCheckedThree('checked');
                setTextShow('show');
            }
        }
        clearErrors();
    }

    useEffect(() => {
        if (checkedThree == "checked") {
            const textarea = document.getElementById('aware_reasons');
            if (textarea) {
                window.scrollTo(250, 250);
                textarea.focus();
            }
        }
    }, [checkedThree]);

    const optionSelected = async (e) => {
        let errorFlag = 0;
        let result = await trigger("aware_reason");
        if (!result) {
            errorFlag = 1;
            return false;
        } else {
            const fieldResult = getValues();
            var reason = fieldResult["aware_reason"].trim();
            if (reason == "" || reason.length < 3) {
                setError("aware_reason", {
                  type: "manual",
                  message: "Please Enter Valid Description",
            });
                return false;
            } else {
            saveInputAns(e);
            }
        }
    };
    return (
        <>

            <div
                className={`radio-box animated fadeInUp space-top space-top2 ${clsName}`}
                id="slide-15"
                ref={reff}
            >
                <h3><b>
                {`When did you become aware you could make a claim against ${lender}?`}
                </b></h3>
          
                <RadioButtonQuest
                    className="radio-buttons"
                    labelName="When TMS Legal contacted me"
                    labelClassName="option_but next-2"
                    onClick={optionHandle}
                    name="aware"
                    dataQuestId={85}
                    dataAnsId={242}
                    value="242"
                    id="nextscrolla"
                />
                <RadioButtonQuest
                    className="radio-buttons"
                    labelName="I saw their ad online"
                    labelClassName="option_but next-2"
                    onClick={optionHandle}
                    name="aware"
                    dataQuestId={85}
                    dataAnsId={243}
                    value="243"
                    id="nextscrollb"
                />
                <RadioButtonQuest
                    className="radio-buttons"
                    labelName="Other"
                    labelClassName={`option_but next-2 ${checkedThree}`}
                    onClick={optionHandle}
                    name="aware"
                    dataQuestId={85}
                    dataAnsId={244}
                    value="244"
                    id="nextscrollc"
                />

                <div
                    className={`radio-box ${textShow == 'hide' ? 'hide' : ''}`}
                    id="slide-32"
                    ref={reff}
                >
                    <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2">
                        <h3>
                            Please specify
                        </h3>
                        <TextArea
                            type="textarea"
                            className="form-control2"
                            name="aware_reason"
                            id="aware_reasons"
                            // placeholder={"Please specify"}
                            validation={validation({
                                required: "Please Enter the Description",
                                minLength: {
                                    value: 3,
                                    message: "Please Enter Valid Description",
                                },
                            })}
                            validationMsg={
                                validationMsg.aware_reason &&
                                validationMsg.aware_reason.message
                            }
                        ></TextArea>
                        <div className="col-lg-12 mb-2 mt-2">
                            <button
                                type="button"
                                className="btn-next"
                                id="nextscroll"
                                name="aware"
                                onClick={optionSelected}
                                data-quest-id={85}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Aware;
