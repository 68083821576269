import React, { useEffect, useState } from "react";
import InputButton from "../UI/InputButton";
import { useHistory } from "react-router-dom";
import { queryString } from "../../Utility/QueryString";

const DocPreviewS2 = ({
  className,
  setLoader,
  loader,
  reloader,
  btndisable,
  setReloader,
  setPreviewPage,
  setIdUpload,
  setEditDetails,
  previewPage,
}) => {
  const editpersonalDetails = (e) => {
    setPreviewPage("hide");
    setEditDetails("show");
  };
  const history = useHistory();

  const getUuid = () => {
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_storage_uuid = localStorage.getItem("uuid");
    var uuid = query_uuid ? query_uuid : local_storage_uuid;
    return uuid;
  };
  const uuid = getUuid();

  const query_string = localStorage.getItem("queryString");
  const querystring = queryString;
  const queryStrings = query_string ? query_string : querystring;


  const uploadProof = (e) => {
    // setPreviewPage("hide");
    // setIdUpload("show");
    history.push("/id-upload-followup-s2?query_string=" + queryStrings);
  };
  return (
    <>
      {/* <div className="IdUploadFollowupS2"> */}
      <div className={`tms-wrap2 bg-main-pr ${className}`}>

        <div className={`main text-center main-div ${className}`}>
          <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
            <img
              height="65"
              src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
              alt=""
            />
          </div>

          <div className="main-form-wraper">
            <div className="container">
              <div className="item-cent-flx ">
                <div className="chooseDocumType ">
                  <h1 className="  mt-md-2 mt-sm-2 mt-0 text-danger  ">
                    We are unable to Verify your identity using the details
                    provided.{" "}
                  </h1>
                  <p className="my-3">
                    {" "}
                    Please choose to edit your details, OR upload an ID proof
                    for us to validate.{" "}
                  </p>
                  <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                      <div className=" text-center">
                        <div className="mb-2 mt-4">
                          <div className="row">
                            <div className="col-lg-6">
                              <button
                                className="mb-3   btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 disablebtn"
                                onClick={editpersonalDetails}
                                disabled={btndisable}
                                data-btn-label="editDetails"
                                data-page-label="editDetails"
                              >
                                Edit My Details <span> {"›"}</span>
                              </button>
                            </div>
                            <div className="col-lg-6">
                              <button
                                className="btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 second disablebtn"
                                onClick={uploadProof}
                                disabled={btndisable}
                                data-btn-label="uploadProof"
                                data-page-label="uploadProof"
                              >
                                Upload My ID Proof <span> {"›"}</span>{" "}
                              </button>
                            </div>
                          </div>
                          <div className="  pt-3 pb-5 ">
                            <img
                              height="50"
                              className="safe-secure-img"
                              src="assets/img/safe and secure.png"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default DocPreviewS2;
