import React, { useContext, useEffect, useState } from "react";
import { CheckUUID } from "../../Utility/CheckUUID";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { userInfo } from "../../Hooks/userInfo";
import { FormData } from "../../Utility/FormData";
import { useHistory } from "react-router-dom";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import "../../assets/css/documentview.scss"

const Proceed = () => {
    const { isCheck, } = CheckUUID();
    const isCheckResponse = isCheck();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const history = useHistory();
    const [currentUuid, setCurrentUuid] = useState();
    const [bankName, setBankName] = useState();
    const { getUserInfo } = userInfo();
    const { setFormData } = FormData();

    function Capitalize(string){
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
        }
      
    const getUuid = () =>{
        const visitorData = visitorParameters.visitor_parameters.uuid;
        if(visitorData){
            var uuid = visitorData;
        }
        else{
            const query = new URLSearchParams(window.location.search);
            const query_uuid = query.get("uuid");
            const local_storage_uuid = localStorage.getItem('uuid');
            var uuid = (query_uuid)? query_uuid : local_storage_uuid;
        }
        return uuid;
    }
  
    const uuid = getUuid();

    useEffect(() => {
    const formdata = JSON.parse(localStorage.getItem('formData'));
    const userBank =  localStorage.getItem('bankName');
    setCurrentUuid(uuid);

    if(formdata ===  undefined || formdata === null || formdata == ''){
        (async () => {
            const response = await getUserInfo(
                uuid
            );
            const set_formdata = setFormData(
                response
            );
            let dataLength = (response.data.response).length;
            if(dataLength > 0) {
                localStorage.setItem('bankName',response.data.response[0].bank_name); 
                const bankName = (response.data.response[0].bank_name) ? Capitalize(response.data.response[0].bank_name) : '';
                setBankName(bankName);
            }
    
            })();
        }
    else
    {
    const bankName = Capitalize(userBank);
    setBankName(bankName);
    }
    }, [bankName]);
    
    const pushProceedLink = () => {
        const formdata = JSON.parse(localStorage.getItem('formData'));
        window.scrollTo(0, 0)
        history.push('/questionnaire?uuid='+uuid);
    }
    
    return (
        <>
        <GetVisitorsParams />
        <div className="preview">
        <div className="splash">
         <section className="py-4">
            <div className="container">
               <div className="row">
                  <div className="col-lg-12 col-12 text-center logo-part mt-5">
                     <img src="/assets/img/logo.png" alt="" className="opacity-25"/>
                  </div>
                  <div className="col-lg-12 col-12 text-center loader mt-4">
                     <img src="/assets/img/loader-review2.gif" alt=""/>
                     <p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.

                     </p>
                     <div className="col-lg-6 col-12 offset-lg-3 text-center">
                        <a onClick={pushProceedLink} className="btn-next"> Proceed </a>
                     </div>
                  </div>
               </div>
            </div>
         </section>
        </div>
        </div>
        </>
    )
}

export default Proceed;