import React, { useState } from 'react';
import TextField from '../../../../UI/TextField';

const QuestionnaireFlpAgreementNo = ({ 
    questArr,
    reff, 
    display,
		validation,
		validationMsg,
		getValues,
		trigger,
		setError,
		saveInputAns
}) => {
	const questionId = questArr.questionId ? questArr.questionId : 0;

	const selectClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();
    let question45Result = await trigger("question_45");
    if (!question45Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag === 0) {
      saveInputAns(e);
    } 
  };

	return (
		<>
			<div style={{ 'display': display }}>
				<div className="radio-box question animated fadeInUp" ref={reff} >  
					<h3 style={{color: "#444", textAlign: "center"}} className="mb-2">Your Credit Agreement number</h3>
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 input-group mb-3">
						<TextField
							type="text"
							className="form-control"
							placeholder="Please Enter Credit Agreement Number"
							name="question_45"
							// onChange={handleChange}
							validation={validation({
								required: "Please Enter Credit Agreement Number",
								minLength: {
									value: 3,
									message: "Please Enter Valid Credit Agreement Number",
								},
								pattern: {
									value: /^\d*[1-9]\d*$/,
									message:
										"Credit Agreement Number is Invalid. Please Recheck",
								},
							})}
							validationMsg={
								validationMsg.question_45 && validationMsg.question_45.message
							}
						></TextField>
					</div>

					<div className="col-xl-12 col-lg-12 text-center p-0" >
						<button 
							type="button" 
							className="btn-next" 
							onClick={selectClick} 
							data-quest-id={45}
                        	data-ans-id={null}
               	            data-ans-label={null}
							name="btn_qest_45"
							>{`Next`}</button>
					</div>
				</div>
			</div>

		</>
	)
}

export default QuestionnaireFlpAgreementNo;