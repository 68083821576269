import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

const BolsterYourComplaint = ({ onselectAnswer, reff, display, userEmail }) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>Would you also like to share your income and expenditure at the time you took out the finance with Vanquis?</h3>

          <RadioButtonQuest
            key="YES"
            className="radio-button"
            labelName="Yes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="1"
            name="question_75"
            id="twonethree"
            dataQuestId={75}
            dataAnsId={213}
          />

          <RadioButtonQuest
            key="NO"
            className="radio-button"
            labelName="No"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="NO"
            name="question_75"
            id="twonefour"
            dataQuestId={75}
            dataAnsId={214}
          />
        </div>
      </div>
    </>
  );
};

export default BolsterYourComplaint;
