import react from "react";
import { Dependent } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
const Dependents = ({
  clsName,
  onSelectAnswer,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
  quest77Previous
}) => {

  let toggle;
  if(quest77Previous == "226"){
    toggle = "cardMonthlyIncome";
  }else if(quest77Previous == "227"){
    toggle = "didReceiveIncome"
  }

  const optionSelected = async (e) => {
    let errorFlag = 0;
    let result = await trigger("dependents");

    if (!result) {
      errorFlag = 1;
      return false;
    } else {
      onSelectAnswer(e);
    }
  };
  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
        <div className="row">
          <div className="col-lg-8 mb-2">
            <SelectBox
              className="form-select"
              OptionValue={Dependent}
              name="dependents"
              id="dependents"
              onChange={() => clearErrors("dependents")}
              clearErrors={clearErrors}
              myRef={validation({ required: "Please select your dependent" })}
              validationMsg={
                validationMsg.dependents && validationMsg.dependents.message
              }
            ></SelectBox>
          </div>
          <div className="col-lg-4 mb-2 ">
            <button
              type="button"
              className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
              id="nextscroll"
              name="dependents"
              onClick={optionSelected}
              data-quest-id={3}
            >
              Next &#8594;
            </button>
          </div>
        </div>
        <p className="text-center bck-marwid">
          <button
            name={toggle}
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}
          >
            Go Back
          </button>
        </p>
      </div>
    </>
  );
};
export default Dependents;
