import React ,{useState} from 'react';
const Footer = () => {
  return (
    <> 
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>TMS Legal Ltd is a limited company registered in England and Wales under company no: 10315962 Its registered office is Arena, 9 Nimrod Way, Ferndown, BH21 7UH It is authorised and regulated by the Solicitors Regulation Authority, Number: 645041</p>
              <p>Copyright © 2022 TMS Legal Ltd - All rights reserved</p>
              </div>
            </div>
          </div>
      </footer>
    </>
  );
}

export default Footer;
