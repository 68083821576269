import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const VehiclePossession = ({
  className,
  slideChange,
  textHeadingLine1,
  textHeadingLine2,
  validation,
  backClick,
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const nextSlide = async (e) => {
    if (e.target.value === "97") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      slideChange(e);
    }
    if (e.target.value === "98") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      slideChange(e);
    }
  };
  return (
    <>
      <div id="slide-2" className={`${className}`}>
        <ProgressBar value="5" />

        <h3> {textHeadingLine1} </h3>
        <div className="radio_buttons">
          <RadioButton
            value="97"
            name="possession"
            className="radio regNextBtn"
            labelName="Yes"
            labelClassName={`radio ${checkedQuestionOne}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <RadioButton
            value="98"
            name="possession"
            className="radio regNextBtn"
            labelName="No"
            labelClassName={`radio ${checkedQuestionTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <p className="text-center">
          <span
            className="back-btn btn btn-link"
            name="back01"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div>
    </>
  );
};
export default VehiclePossession;