import React from 'react';
import tickgif from "../../../../assets/QuestionaireMBGN_4/img/5ZCggE3Tml.gif"


const LoaderOne = ({LoaderOne}) => {
	return (
		<>
		
    <div className={`loader-wrap succLoader text-center text-light ${LoaderOne} `}>
        <div>
          <img height={150} src={tickgif} alt="" />
          <h2 className="mt-3 succtext px-5 ">
            Your initial registration has been completed. <br />{" "}
            <small> Please wait...</small>
          </h2>
        </div>
      </div>
		</>
	)
}

export default LoaderOne;