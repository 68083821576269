import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const StillActive = ({
  className,
  slideChange,
  textHeading,
  validation,
  backClick,
  skip
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");

  const nextSlide = async (e) => {
    if (e.target.value === "95") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      slideChange(e);
    }
    if (e.target.value === "96") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      slideChange(e);
    }
  };
  let toggle;
  if (skip == true) {
    toggle = 'prevReg';
  } else {
    toggle = 'prevKeeper';
  }
  return (
    <>
      <div id="slide5" className={`${className}`}>
        <ProgressBar value="15" />

        <h3> {textHeading} </h3>
        <div className="btn-comm">
          <RadioButton
            value="95"
            name="stillactive"
            className="radio-button"
            labelName="Yes"
            labelClassName={`option_but  next1la ${checkedQuestionOne}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="96"
            name="stillactive"
            className="radio-button"
            labelName="No"
            labelClassName={`option_but  next1la ${checkedQuestionTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        {/* <p className="text-center"><a className="back01" name={toggle} onClick={backClick}> {'<< Previous'} </a></p> */}
        <p className="text-center"><a className="back01" name="prevBankStmt" onClick={backClick}> {'<< Previous'} </a></p>
      </div>
    </>
  );
};
export default StillActive;
