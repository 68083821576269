import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
//import { BankList } from "../../../../Constants/Lender";
import Select from 'react-select';


const BankName = ({
  clsName,
  saveInputAns,
  validation,
  SelectBank,
  setSelectBank,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  setError,
  backClick,
  textHeading,
  lenderList,
  reff,
  lender
}) => {

  const [BankValidationMsg, setBankValidationMsg] = useState(false);


  const customStyles = {
    control: base => ({
      ...base,
      //height: 50,
      minHeight: 50
    })
  };

  const nextSlide = async (e) => {
    let LengthSelectedBank = SelectBank.length;
    let ValueSelectedBank = SelectBank.value;

    if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
      setBankValidationMsg(true);
    } else {
      localStorage.setItem("bud_lender", ValueSelectedBank);
      localStorage.setItem("lenderArray", JSON.stringify([SelectBank]));
      saveInputAns(e);
    }
  }


  function handleSelect(opt) {
    setSelectBank(opt);
    setBankValidationMsg(false);
  }
  let BankList = []; 

  if (lenderList.length > 0) {
    BankList = BankList.concat(lenderList.filter(function (val) {
      return !val.display_name.endsWith("Card") &&
        !val.display_name.endsWith("Business") &&
        !val.display_name.endsWith("card") &&
        !val.display_name.endsWith("Cards");
    }).map(function (val) {
      return {
        value: val.provider,
        label: val.display_name
      };
    }));
  }
  return (
    <>
      <div className={`radio-box animated fadeInUp text-center ${clsName}`} ref={reff} >
        <h3><b>{`Which bank were you with when you took your credit with ${lender}?`}</b></h3>
        <div className="input-section mb-3">
          <Select
            placeholder="Select Bank Name"
            options={BankList}
            aria-label="Username"
            name="bankName"
            id="bankName"
            value={SelectBank}
            onChange={handleSelect}
            isSearchable={true}
            styles={customStyles}
          // isMulti
          />
          {BankValidationMsg && (<span id="bank_err" className="error_msg">Please select your Bank</span>)}
        </div>
        <div className="col-xl-12 col-lg-12 text-center p-0" >
          <button
            type="button"
            className="btn-next"
            onClick={nextSlide}
            data-quest-id={64}
            data-ans-id={null}
            data-ans-label={null}
            name="bankname"
          >{`NEXT`}</button>
        </div>

      </div>

    </>
  );
};

export default BankName;