import React, { useState, useEffect } from "react";
import "../../assets/OpenBanking/css/ObSplashCancel.scss";
import { useOpenBanking } from "../../Hooks/useOpenBanking";


const ObSplashCancel = () => { 
    const [loadCancel,setLoadCancel] = useState(1);
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_storage_uuid = localStorage?.getItem("uuid");
    var uuid = query_uuid ? query_uuid : local_storage_uuid;
    const query_source = query.get("atp_sub6");
    var source = query_source ? query_source : "live";
   
    const { storeClickResponse } = useOpenBanking();

      useEffect(()=>{ 
        (async () => {
        if (uuid) {

            let payload = { "page": 'OB_SPLASH_CANCEL',type:'visit', source };
            await storeClickResponse({uuid},"ob_creditcheck_history",uuid,payload);
      
          }
        })(); 

        setTimeout(() => {
          setLoadCancel(0);
        }, 2000);

      },[]);


 return (

    <div className="__ExpampleDemo ">
       <div className="loader_1">
     <div className={`text-center loader-wrap ${loadCancel == 1 ? 'showload' : 'hide'}`}>
        <div className="loader">
          <img src="assets/img/review.gif" alt="" />
        </div>
      </div> 
      </div>
        <div className={`thankyoupage ${loadCancel == 0 ? 'showload' : 'hide'}`}>
            <div className="thankyou1">
                <div className="smaller">
                   <img src="assets/img/splash_tick.gif" />
                </div>  
                
                <h2>Thanks</h2>
                <p>We have closed your file. There are not more actions needed from your side.</p>
                
            </div>
        </div>



        <div className="col-12 showcase showcase2">
            <div className="video-container ">
                {/* <video src="dist/img/production_id_4393393 (1080p).mp4" autoplay muted loop></video> */}
                 
                <video
                src="../../assets/img/production_id_4393393_(1080p).mp4"
                autoPlay
                muted
                loop
              ></video>

            </div>
            <div className="content">
            </div>
        </div>
       


     
    </div>
 );

}

export default ObSplashCancel;