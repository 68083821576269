import React from 'react';
import FbViewPixel from '../../../../Utility/FbViewPixel';

const Header = () => {
  return (
    <>
        <header className="text-center">
          <div className="col-xl-12 col-12 top_content">
            <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
          </div>
          <div className="container">
            <div className="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 offset-md-2 col-md-8">
              <a href="#">
                <img src="/assets/img/logo-white.png" alt="" className="logo" />
              </a>
              <h1>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has  dummy text</h1>
              <h2>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h2>
              <h3>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h3>
              <h6>Lorem Ipsum is simply dummy text</h6>
              <h6>Lorem Ipsum is simply dummy text</h6>
            </div>
          </div>
        </header>
    </>
  );
};

export default Header;