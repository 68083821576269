import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import AlertModal from "./AlertModal";
import AnalyzeModal from "./AnalayzeModal";
import { useTotalCarCheck } from "../../../../Hooks/useTotalCarCheck";

const Questionnaire3 = ({
  clsName,
  reff,
  onselectAnswer,
  vehicle,
  validKeeperDate,
  pushUnqualified,
  savetotalCarCheck,
  uuid,
  page_name,
}) => {
  const [showSlidepopup, setshowSlidepopup] = useState("hide");
  const { totalCarCheckApi } = useTotalCarCheck();
  const [loader, showLoader] = useState(false);

  const backpopup = (e) => {
    e.preventDefault();
    //scrollToElement('slide2');
    setshowSlidepopup("hide");
  };

  const labelclicked = async (e) => {
    let date_index = e.target.id;
    if (date_index == "Yes") {
      showLoader(true);   
      setTimeout(() => {
        showLoader(false);
      }, 1000);
      onselectAnswer(e);
      // if (validKeeperDate) {
      //   const totalCarCheckApiData = await totalCarCheckApi(
      //     vehicle.reg_no,
      //     uuid,
      //     page_name
      //   );
      //   savetotalCarCheck(totalCarCheckApiData.data.vehicleData);
      //   setTimeout(() => {
      //     showLoader(false);
      //   }, 1000);
      //   onselectAnswer(e);
      // } else {
      //   setTimeout(() => {
      //     showLoader(false);
      //   }, 1000);
      //   onselectAnswer(e);
      // }
    } else {
      setshowSlidepopup("show");
      //pushUnqualified();
    }
  };

  let vehicle_model = "";
  if (vehicle.vehicle_make) {
    vehicle_model = vehicle.vehicle_make;
  }
  if (vehicle.vehicle_make && vehicle.vehicle_model) {
    vehicle_model = vehicle_model + " " + vehicle.vehicle_model;
  }
  if (!vehicle.vehicle_make && vehicle.vehicle_model) {
    vehicle_model = vehicle.vehicle_model;
  }
  localStorage.setItem(
    "vehicle_make_model",vehicle_model
  );

  localStorage.setItem(
    "vehicle_make_model",vehicle_model
  );

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>
          Is this your vehicle <b>{vehicle_model}</b>
        </h3>

        <RadioButtonQuest
          key="YES"
          className="radio"
          labelName="Yes"
          labelClassName="radioa"
          labelCountString="a"
          onClick={labelclicked}
          value="YES"
          name="question_12"
          id="Yes"
          dataQuestId={12}
          dataAnsId={23}
          dataAnsLabel="Yes"
        />

        <RadioButtonQuest
          key="NO"
          className="radio"
          labelName="No"
          labelClassName="radioa"
          labelCountString="b"
          onClick={labelclicked}
          value="NO"
          name="question_12"
          id="No"
          dataQuestId={12}
          dataAnsId={24}
          dataAnsLabel="No"
        />
      </div>
      <AlertModal showSlidepopup={showSlidepopup} backpopup={backpopup} />
      <AnalyzeModal modalClass={loader} />
    </>
  );
};

export default Questionnaire3;
