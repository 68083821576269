import React from 'react';

const QuestionnaireFooter = ({percentage,onDownClick,onUpClick}) => {
	return (
		<>
			<div className="statusbar">
				<div className="row m-0">
						<div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-0">
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6  col-sm-6 col-8">
							<div className="progress-value" id="progress-value"> <span className="progressValue">{percentage}%</span> completed</div>
							<div id="myProgress">
									<div id="myBar" className="myBar" style={{width:`${percentage}%`}}></div>
							</div>
						</div>
						{/* <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
							<a id="arrowdown" className="arrow-down" data-href="1" onClick={onDownClick}></a>
							<a id="arrowup" className="arrow-up" data-href="1" onClick={onUpClick}></a>
						</div> */}
				</div>
			</div>	
		</>
	)
}

export default QuestionnaireFooter;