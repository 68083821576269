import React, {
  useState,
  useEffect,
} from "react";
import { Api } from "../../api/Api";
// import "../../assets/FollowupFos/css/customfos.scss";
import "../../assets/FollowupFos/css/stylefos.scss";
import "../../assets/FollowupFos/css/homefos.scss";
import "../../assets/FollowupFos/css/questionnairefos.scss";
import "../../assets/FollowupFos/css/signaturefos.scss";
import { queryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useExternalFollowup } from "../../Hooks/useExternalFollowup";
import InputButton from "../UI/InputButton";
import { AppDomain } from "../../Constants/EnvConstants";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import OB_AffordabilityCheckV2 from "../Includes/Layouts/FosFollowupV1/OB_AffordabilityCheckV2";
import YearOfPossession from "../Includes/Layouts/FosFollowupV1/YearOfPossession";
import OB_SplashV2 from "../Includes/Layouts/FosFollowupV1/OB_SplashV2";
import BankName from "../Includes/Layouts/FosFollowupV1/BankName";
import { useForm } from "react-hook-form";
import MatchCheckLoader from "../Includes/Layouts/FosFollowupV1/MatchCheckLoader";

const FosFollowupV1 = () => {
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(1);
  const [loader1, setLoader1] = useState(1);
  const [reloader, setReloader] = useState(1);
  const [affordabilityStat, setAffordabilityStat] = useState('hide');
  const [obCredStat, setObCredStat] = useState('hide');
  const [obSplashStat, setObSplashStat] = useState('hide');
  const history = useHistory();
  const { getVisitorID, getAccessToken, createCustomer, getProviders, getAuthGatewayUrl, storeApiResponse, OpenBankVisit, storeClickResponse, milestoneChase } = useExternalFollowup();
  const [btndisable, setBtnDisable] = useState(false);
  const [visitorId, setVisitorId] = useState(0);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [yearOfPossession, setYearOfPossession] = useState('hide');
  const [bankName, setBankName] = useState('hide');
  const [Year, setYear] = useState(2015);
  const [userData, setUserData] = useState();
  const [SelectBank, setSelectBank] = useState();
  const [lenderList, setLenderList] = useState([]);
  const [skip, setSkip] = useState(false);
  const [Uuid, setUuid] = useState();
  const [matchCheckLoader, setMatchCheckLoader] = useState('hide');
  const [agDate, setAgDate] = useState();
  const [backBtn, setBackBtn] = useState ('hide');

  let redirectUrl = '';
  let redirectlink = '';
  let btn_label = '';
  let page = '';
  const query = new URLSearchParams(window.location.search);
  const query_source = query.get("atp_sub6");
  var source = query_source ? query_source : "live";

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/api/v1/user-details-external?query_string=" + queryString,
        {}
      );
      if (response.data.status === "Success") {
        let user_data = response.data.response.user_data;
        let uuid = response.data.response.uuid;
        let lender = response.data.response.lender;
        let source = response.data.response.source;
        let lstSalutation = response.data.response.lstSalutation;
        let txtFName = response.data.response.txtFName;
        let txtLName = response.data.response.txtLName;
        let txtEmail = response.data.response.txtEmail;
        let txtPhone = response.data.response.txtPhone;
        let is_test = response.data.response.is_test;
        let token = response.data.response.user_data.atp_sub2;
        let pixel = response.data.response.user_data.pixel;
        let txtPostcode = response.data.response.txtPostcode;
        let txtAddressline1 = response.data.response.txtAddressline1;
        let txtAddressline2 = response.data.response.txtAddressline2;
        let txtAddressline3 = response.data.response.txtAddressline3;
        let town = response.data.response.town;
        let dob = response.data.response.dob;
        let userId = response.data.response.flg_id;
        let obcreduser = response.data.response.obcreduser;
        let obstat = response.data.response.obstat;
        let credstat = response.data.response.credstat;
        let obclosestat = response.data.response.obclosestat;
        let agreementDate = response.data.response.agreement_date;
        let splash_milestone = response.data.response.splash_milestone;
        let agreement_date_milestone = response.data.response.agreement_date_milestone;
        let bank_select_milestone = response.data.response.bank_select_milestone;
        let open_banking_milestone = response.data.response.open_banking_milestone;
        let file_upload_milestone = response.data.response.file_upload_milestone;
        let credit_check_milestone = response.data.response.credit_check_milestone;
        let valid_user = response.data.response.valid_user;
        let lenders = response.data.response.providers;
        let isComplete = response.data.response.is_completed;

        localStorage.setItem('is_test', is_test);
        localStorage.setItem('user_data', JSON.stringify(user_data));
        localStorage.setItem('user_id', userId);
        localStorage.setItem('source', source);

        if (uuid == null) {
          history.push("/thankyou?uuid=" + uuid);
        }

        setUserData(user_data);
        setUuid(uuid);
        setAgDate(agreementDate);
        setLenderList(lenders);

        setUserDetails({
          uuid: uuid,
          lender: lender,
          source: source,
          lstSalutation: lstSalutation,
          txtFName: txtFName,
          txtLName: txtLName,
          txtEmail: txtEmail,
          txtPhone: txtPhone,
          is_test: is_test,
          token: token,
          pixel: pixel,
          txtPostcode: txtPostcode,
          txtAddressline1: txtAddressline1,
          txtAddressline2: txtAddressline2,
          txtAddressline3: txtAddressline3,
          town: town,
          dob: dob,
          user_id: userId,
          obcreduser: obcreduser,
          obstat: obstat,
          credstat: credstat,
          obclosestat,
          agreementDate: agreementDate
        });

        setTimeout(() => {
          setDivShow(page, uuid);
          setLoader1(0);
          setLoader(0);
        }, 2000);


        const visitor = await getVisitorID(
          uuid,
          user_data,
          'new'
        )

        if (visitor.data.status == "success") {
          setVisitorId(visitor.data.visitorId);
        }

        const flpResponse = await OpenBankVisit(
          "open_bank_visit",
          uuid,
          user_data,
          "OpenBankingProceedV1",
          queryString,
          "followup-fos"
        );

        if (valid_user == 0) {
          page = "valid_user";
        } else if (isComplete == 1) {
          page = "isComplete";
        } else if (splash_milestone == null || splash_milestone == 2) {
          page = "splash";
        } else if (agreementDate == null) {
          page = "agreement";
        } else if (agreementDate != null && (bank_select_milestone == null || bank_select_milestone != null) && open_banking_milestone == null && credit_check_milestone == null ) {
          page = "bankSelect";
        } else if ((credit_check_milestone == null || credit_check_milestone == 1) && bank_select_milestone != null) {
          page = "creditCheck";
        } else if ((agreementDate == null || bank_select_milestone == null) && credit_check_milestone == 1) {
          page = "agreement";
        } else {
          console.log("no data");
        }

      } else {
        setLoader(0);
        history.push("/thankyou?uuid=" + response.data.response.uuid);
        return false;
      }

    })();

  }, []);

  const setDivShow = (page, split_name, uuid) => {
    if (page == "valid_user") {
      history.push("/thankyou?uuid=" + uuid);
    }

    if (page == "splash") {
      setObSplashStat('show');
    } else if (page == "agreement") {
      setObSplashStat('hide');
      setYearOfPossession('show');
    } else if (page == "bankSelect") {
      setObSplashStat('hide');
      setYearOfPossession('hide');
      setBankName('show');
    } else if (page == "OBpage") {
      setObSplashStat('hide');
      setBankName('hide')
      setAffordabilityStat('show');
    } else if (page == "creditCheck") {
      setObSplashStat('hide');
      history.push("/credit_check_fos?" + queryString)
    } else if (page == "isComplete") {
      history.push("/thankyou_external_fos")
    } else {
      //history.push("/thankyou?uuid=" + uuid);
    }
  };


  useEffect(() => {

    (async () => {

      let urlPortion = 'title=' + userDetails.lstSalutation + '&fname=' + userDetails.txtFName + '&lname=' + userDetails.txtLName + '&email=' + userDetails.txtEmail + '&phone=' + userDetails.txtPhone + '&dob=' + userDetails.dob + '&abodenumber=' + '&building_name=' + userDetails.txtAddressline1 +'&straddr=' + userDetails.txtAddressline2 + '&street=' + '&city=' + userDetails.town + '&zip=' + userDetails.txtPostcode + '&clientRefId=' + userDetails.uuid;

      localStorage.setItem('url_portion', urlPortion);
      localStorage.setItem('uuid', userDetails.uuid);
      localStorage.setItem('firstname', userDetails.txtFName);
      localStorage.setItem('credStat', userDetails.credstat);
      localStorage.setItem('agreementDate', userDetails.agreementDate);

      if (userDetails.uuid) {

        let payload = { "page": 'OB_SPLASH', type: 'visit', "source": userDetails.source };
        await storeClickResponse({ uuid: userDetails.uuid }, "ob_creditcheck_history_ext", userDetails.uuid, payload);

      }

      // if (userDetails.obclosestat == '1') {
      //   history.push("/ob_splash_cancel?uuid=" + userDetails.uuid + "&atp_sub6=" + source);
      //   return false;
      // }


      if ((userDetails.obcreduser == '1') && (userDetails.obstat == '' || userDetails.obstat == '0' || userDetails.obstat == null) && (userDetails.credstat == '' || userDetails.credstat == '0' || userDetails.credstat == null)) {

        history.push("/thankyou?uuid=" + userDetails.uuid);
        return false;

      }

    })();

  }, [userDetails]);


  const pushProceedLink = async (e) => {

    setBtnDisable(true);
    setLoader(1);
    //await milestoneChase({ uuid: userDetails.uuid }, userDetails.user_id, "open_banking_milestone", 1);
    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');

    let payload = { page, type: 'btn_click', btn_label, "source": userDetails.source, 'click_btn': '1' };
    await storeClickResponse({ uuid: userDetails.uuid }, "ob_creditcheck_history_ext", userDetails.uuid, payload);

    GtmDataLayer({
      'question': 'OPEN BANKING'
    });

    localStorage.setItem('visitorId', visitorId);
    const response1 = await getAccessToken(userDetails.uuid, userDetails.source, userDetails.is_test, visitorId);

    if (response1 == undefined || response1 == null || response1 == '') {
      setLoader(0);
      setReloader(0);
      return false;
    }

    if (response1.data.status == "success") {
      //(response.data.access_token_ID);
      let payload = { "milestone_key": "access_token", "source": userDetails.source };
      await storeApiResponse(userDetails, "op_milestone_update_ext", userDetails.uuid, payload);
      const customerResponse = await createCustomer(userDetails.uuid, userDetails.source, userDetails.lstSalutation, userDetails.txtFName, userDetails.txtLName, userDetails.txtEmail, userDetails.txtPhone, userDetails.is_test, visitorId)

      if (customerResponse == undefined || response1 == null || response1 == '') {
        setLoader(0);
        setReloader(0);
        return false;
      }


      if (customerResponse.data.status == "success") {
        localStorage.setItem('customer_id', customerResponse.data.customer_ID);
        let payload = { "milestone_key": "customer_create", "customer_id": customerResponse.data.customer_ID, 'source': userDetails.source };
        await storeApiResponse(userDetails, "op_customer_store_ext", userDetails.uuid, payload);
        // const providerResponse = await getProviders(userDetails.uuid,userDetails.source,userDetails.is_test);
        // if (providerResponse.data.status === "success") {
        //     const providers = providerResponse.data.providers;
        //     const optionData = providers.map((value, index) => {
        //         return (
        //           <option value={value.provider} key={index}>
        //             {value.display_name}
        //           </option>
        //         );
        //       });
        // }

        // redirectUrl = (userDetails.source) ? AppDomain + "/ob-analyze_fos?uuid=" + userDetails.uuid + "&atp_sub6=" + userDetails.source : AppDomain + "/ob-analyze_fos?uuid=" + userDetails.uuid;
        redirectUrl = (userDetails.source) ? AppDomain+"/ob-analyze_fos?uuid="+ userDetails.uuid +"&user_id="+ userDetails.user_id +"&queryString="+queryString : AppDomain+"/ob-analyze_fos?uuid="+ userDetails.uuid +"&user_id="+ userDetails.user_id +"&queryString="+queryString ;

        var bud_lender = localStorage.getItem('bud_lender');
        let lenderArray = (bud_lender == null) ? '' : [bud_lender];
        var response2 = await getAuthGatewayUrl(userDetails.uuid, customerResponse.data.customer_ID, redirectUrl, userDetails.source, userDetails.is_test, visitorId, lenderArray)

        if (response2 == undefined || response2 == null || response1 == '') {
          setLoader(0);
          setReloader(0);
          return false;
        }

        if (response2.data.status == "success") {
          payload = { "milestone_key": "lender_redirection", 'source': userDetails.source, "lender": lenderArray };
          await storeApiResponse(userDetails, "op_milestone_update_ext", userDetails.uuid, payload);
          let url = response2.data.gateway_url;
          window.location.href = url;
          //setLoader(0)
        }
      }
    }
  }

  const keepMyFileLinkOne = async (e) => {

    setBtnDisable(true);
    setLoader1(0);
    setLoader(1);

    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');


    let payload = { page, type: 'btn_click', "btn_label": btn_label, "source": userDetails.source, 'click_btn': '7' };
    await storeClickResponse({ uuid: userDetails.uuid }, "ob_creditcheck_history", userDetails.uuid, payload);
    await milestoneChase({ uuid: userDetails.uuid }, userDetails.user_id, "splash_milestone", 1, userDetails.source)

    // let payloadinitial = { "page": 'OB_LP', type: 'visit', "source": userDetails.source };
    // await storeClickResponse({ uuid: userDetails.uuid }, "ob_creditcheck_history_ext", userDetails.uuid, payloadinitial);

    if (agDate == null) {
      setDivShow('agreement');
    } else if (agDate != null) {
      setDivShow('bankSelect');
    }

    setBtnDisable(false);

    setTimeout(() => {
      setLoader(0);
    }, 1000);

  }

  return (
    <>
      <div className="FollowupFosV1">
        <OB_SplashV2
          obSplashStat={obSplashStat}
          btndisable={btndisable}
          keepMyFileLinkOne={keepMyFileLinkOne}
          loader={loader1}
          userDetails={userDetails}
        />

        <YearOfPossession
          className={`${yearOfPossession}`}
          validation={register}
          clearErrors={clearErrors}
          getValues={getValues}
          trigger={trigger}
          validationMsg={errors}
          Year={Year}
          allFollowupData={userData}
          setYearOfPossession={setYearOfPossession}
          setBankName={setBankName}
          Uuid={Uuid}
          loader={loader}
          setObSplashStat={setObSplashStat}
          setLoader={setLoader}
          userDetails={userDetails}
          setDivShow={setDivShow}
          setLoader1={setLoader1}
          setBackBtn={setBackBtn}
        />

        <BankName
          className={`${bankName}`}
          validation={register}
          getValues={getValues}
          validationMsg={errors}
          Year={Year}
          SelectBank={SelectBank}
          setSelectBank={setSelectBank}
          lenderList={lenderList}
          setBankName={setBankName}
          setMatchCheckLoader={setMatchCheckLoader}
          allFollowupData={userData}
          loader={loader}
          setLoader={setLoader}
          userDetails={userDetails}
          Uuid={Uuid}
          setDivShow={setDivShow}
          backBtn={backBtn}
        />

        <MatchCheckLoader
          matchCheckLoader={matchCheckLoader}
        />

        <OB_AffordabilityCheckV2
          setLoader={setLoader}
          loader={loader}
          reloader={reloader}
          btndisable={btndisable}
          setReloader={setReloader}
          pushProceedLink={pushProceedLink}
          affordabilityStat={affordabilityStat}
        />

      </div>
    </>

  );
};

export default FosFollowupV1;