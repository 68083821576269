import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import Button from "../../../../UI/Button";

const OldCard = ({ className, onselectAnswer, reff, display, trigger, addPending }) => {

  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3" ref={reff}>
            <div className="radio-box question animated fadeInUp">
              <h1 className="title ">Did you take the car finance over 6 years ago?</h1>
              <div className="row mt-4">
                <div className="col-lg-12">
                  <div className="btn-comm com-btn  ">
                    <RadioButtonQuest
                      key="No"
                      className="radio regNextBtn"
                      labelName="No"
                      labelClassName="com-btn btn-next w-100 mb-3 "
                      onClick={labelclicked}
                      value="237"
                      name="question_81"
                      id="oneaaa"
                      dataQuestId={81}
                      dataAnsId={237}
                      dataAnsLabel="No"
                    />
                    <RadioButtonQuest
                      key="Yes"
                      className="radio regNextBtn"
                      labelName="Yes"
                      labelClassName="com-btn btn-next w-100 mb-3 "
                      onClick={labelclicked}
                      value="236"
                      name="question_81"
                      id="twobbb"
                      dataQuestId={81}
                      dataAnsId={236}
                      dataAnsLabel="Yes"
                    />

                    <div class="text-center back">
                      <Button
                        className="btn btn-link mt-2 h2"
                        id="back1"
                        type="button"
                        buttonText="<< Previous"
                        onClick={() => addPending("oldcard")}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OldCard;
