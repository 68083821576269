import React, { useState } from "react";
import TextField from "../../../../UI/TextField";
import Button from "../../../../UI/Button";

const QuestionnaireFlpQ3a = ({
  questArr,
  reff,
  display,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  saveInputAns,
  onselectAnswer,
  className,
  addPending
}) => {
  const questionId = questArr.questionId ? questArr.questionId : 0;

  const selectClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();
    let question44Result = await trigger("question_44");
    if (!question44Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag === 0) {
      saveInputAns(e);
    }
  };
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };


  const handleClick = async (e) => {
    let registration_no = await trigger("question_10");
    if (registration_no) {
      const fieldResult = getValues();
      let carReg = fieldResult["question_10"];
      let carRegNo = carReg.replace(/\s+/g, "");
    } else {
      return false;
    }
  };

  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value =
        "£" + e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  return (
    <>
      <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">
              {" "}
              Please enter your Privately Registered Plate
            </h1>

            <div className="row mt-4">
              <div className="col-lg-12">
                <div className="mb-3">
                  <TextField
                    type="text"
                    className="form-control car-number"
                    placeholder="Enter Vehicle Registration Number"
                    name="question_44"
                    maxlength="12"
                    // onChange={handleChange}
                    validation={validation({
                      required: "Please enter your Privately Registered Plate",
                      // pattern: {
                      //   value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/,
                      //   message: "Your Privately Registered Plate is Invalid. Please Recheck",
                      // },
                    })}
                    validationMsg={
                      validationMsg.question_44 &&
                      validationMsg.question_44.message
                    }
                  ></TextField>
                </div>
                <div>
                  <button
                    type="button"
                    className="com-btn btn-next w-100 mb-3"
                    onClick={selectClick}
                    data-quest-id={44}
                    data-ans-id={null}
                    data-ans-label={null}
                    name="btn_qest_44"
                  >{`Next`}</button>
                </div>
                <p className="text-center">
                  <a className="skip-btn skip1" name="btn_qest_44a" data-quest-id={44}
                    data-ans-id={195}
                    data-ans-label={"Not sure"} onClick={labelclicked}
                    > Not Sure
                  </a>
                </p>
              </div>
              <div class="text-center back">
                    <Button 
                    className="btn btn-link mt-2 h2" 
                    id="back1" 
                    type="button"
                    buttonText="<< Previous"
                    onClick={()=>addPending("private_plate")}
                    />
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireFlpQ3a;
