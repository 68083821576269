import React, { useState } from 'react';
import Modal from "react-bootstrap/Modal";
import ProgressBar from '../Common/ProgressBar';
import TextField from '../../../UI/TextField';
import RadioButton from '../../../UI/RadioButton';
import review from "../../../../assets/MB_UFGN_1/img/review.gif";
//import { BankList } from "../../../../Constants/Lender";
import Select from 'react-select';


const BankName = ({
  className,
  slideChange,
  validation,
  SelectBank,
  setSelectBank,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  setError,
  Year,
  backClick,
  textHeading,
  lenderList,
  skip
}) => {
  const [BankValidationMsg, setBankValidationMsg] = useState(false);
  const [showFirstModal, setShowFirstModal] = useState(false);
  const handleShowFirst = () => setShowFirstModal(true);
  const handleCloseFirst = () => setShowFirstModal(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const handleShowSecond = () => setShowSecondModal(true);
  const handleCloseSecond = () => setShowSecondModal(false);

  const customStyles = {
    control: base => ({
      ...base,
      //height: 50,
      minHeight: 50
    })
  };

  const nextSlide = async (e) => {
    let LengthSelectedBank = SelectBank.length;
    let ValueSelectedBank = SelectBank.value;
   
    if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
      setBankValidationMsg("Please select your Bank");
    } else {
      handleShowFirst();
      setTimeout(() => {
        handleCloseFirst();
        handleShowSecond();
      }, 3000);
      setTimeout(() => {
        handleCloseSecond();
      }, 6000);
      slideChange(e);
    }
  }
  let toggle;
  if (skip == true) {
    toggle = 'back05';
  } else {
    toggle = 'prevKeeper';
  }

  function handleSelect(opt) {
    setSelectBank(opt);
    setBankValidationMsg(false);
  }
  let BankList = []; //[{ value: null,  label: "---Select Bank Name---" }];
  // if(lenderList.length >0){
  //   lenderList.map(function (val) {
  //     let lenderData = { value: val.provider, label:val.display_name}
  //     BankList.push(lenderData);
  //   })
  // }

  if (lenderList.length > 0) {
    BankList = BankList.concat(lenderList.filter(function (val) {
      return !val.display_name.endsWith("Card") &&
        !val.display_name.endsWith("Business") &&
        !val.display_name.endsWith("card") &&
        !val.display_name.endsWith("Cards");
    }).map(function (val) {
      return {
        value: val.provider,
        label: val.display_name
      };
    }));
  }
  return (
    <>
      <div id="slide3" className={`${className}`}>
        <ProgressBar value="50" />
        <h3>Which bank were you with when you took the finance with Moneybarn?</h3>

        <div className="input-section mb-3">
          <Select
            placeholder="Select Bank"
            options={BankList}
            aria-label="Username"
            name="bankName"
            id="bankName"
            value={SelectBank}
            onChange={handleSelect}
            isSearchable={true}
            styles={customStyles}
            //isMulti
          
          />
          <span id="bank_err" className="error_msg">{BankValidationMsg}</span>
        </div>
        <div className="btn-comm text-center">

          <RadioButton
            name="bankname"
            id={"bankname"}
            className="radio-button"
            labelName="Next"
            labelClassName={`regNextBtn  next3a`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <p className="text-center"><a className="back01" name={toggle} onClick={backClick}> {'<< Previous'} </a></p>
      </div>
      <Modal
        show={showFirstModal}
        onHide={handleCloseFirst}
        backdrop="static"
        keyboard={false}
        className="formmodal"
        contentClassName="modalcontent"
      >
        <Modal.Body className="modal-bordr">
          <div id="slide7" className="col-12 slide-pad text-center">
            <div className="form-group text-center" id="analize1">
              <h2>Analysing Your Results</h2>
              <img src={review} alt="" />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSecondModal}
        onHide={handleCloseSecond}
        backdrop="static"
        keyboard={false}
        className="formmodal"
        contentClassName="modalcontent"
      >
        <Modal.Body className="modal-bordr">
          <div id="slide7nxt" className="col-12 slide-pad text-center">
            <div className="form-group text-center" id="analize1">
              <h2>Good News! </h2>
              <p>
                We are able to assist you further. Please give us some back
                information. <br />
                (This will only take 1 minute)
              </p>
              <img src={review} alt="" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BankName;