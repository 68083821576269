import React, { useContext, useState } from "react";
import { Salutation } from "../../../../Constants/Questions";
import SelectBox from "../../../UI/SelectBox";
import TextField from "../../../UI/TextField";
import { DateOfBirth } from "../../../../Utility/DateOfBirth";
import InputButton from "../../../UI/InputButton";
import ProgressBar from "../../Layouts/Common/ProgressBar";
import Modal from "react-bootstrap/Modal";
import privacy from "../../../../assets/MB_OBV1/img/privacy.png";
import useValidDOB from "../../../../Hooks/useValidDOB";

const PersonalDetails = ({
  className,
  slideChange,
  validation,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  splitForm,
  backClick,
  setError,
  setPreviousName,
}) => {
  const [preModal, setshowPrevModal] = useState(false);
  const handleShowFirst = () => setshowPrevModal(true);
  const handleClosePreModal = () => setshowPrevModal(false);
  const [showNextModal, showsetNextModal] = useState(false);
  const [notDobValid, setNotDobValid] = useState(false);  
  const {validateDateOfBirth}= useValidDOB();

  const handleShowNext = () => {
    showsetNextModal(true);
    setshowPrevModal(false);
  };
  const handleClose = () => showsetNextModal(false);

  const form = splitForm.current;
  const question_3 = getValues();

  let bank = localStorage.getItem("bankName");
  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    var lstSalutationResult = await trigger("lstSalutation");

    var salutation = question_3.lstSalutation;
    var txtFNameResult;
    var txtLNameResult;
    var lstDobDayResult;
    var lstDobMonthResult;
    var lstDobYearResult;
    var dobday = document.getElementById("DobDay").value;
    var dobmonth = document.getElementById("DobMonth").value;
    var dobyear = document.getElementById("DobYear").value;

    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      lstDobDayResult = await trigger("DobDay");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }
    if (lstDobYearResult) {
      const dobIsValid = validateDateOfBirth(dobday, dobmonth, dobyear);

      if (!dobIsValid) {
        document.getElementById("DobDay").focus();
        setNotDobValid(true);
        return false;
      } else {
        setNotDobValid(false);
      }
    } else {
      document.getElementById("DobYear").focus();
    }

    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      if (salutation == "Mrs" || salutation == "Ms" || salutation == "Miss" || salutation == "Dame" || salutation == "Lady") {
        setshowPrevModal(true);
      } else {
        slideChange(e);
      }
      // slideChange(e);

    } else {
      return false;
    }
  };

  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is Invalid. Please Recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };

  const prevNameValidate = async (e) => {
    let errorFlag = 0;
    var prevnameResult = await trigger("txtPrevName");
    if (prevnameResult) {
    } else {
      document.getElementById("txtPrevName").focus();
    }
    if (!prevnameResult) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      handleClose();
      slideChange(e);
    } else {
      return false;
    }
  };

  const closePrevPopup = () => {
    showsetNextModal(false);
    setshowPrevModal(false);
  };

  const noPreviousName = async (e) => {
    setshowPrevModal(false);
    slideChange(e);
    // console.log(e.target.name);
  };

  return (
    <div id="slide12" className={`slidetop_adjust ${className}`}>
      <ProgressBar value="55" />
      <h3><b>Please enter your FULL NAME {"&"} DATE OF BIRTH{" "}</b></h3>
      <h4> This information is used to locate the car finance</h4>
      <div className="input-section">
        <div className="mb-3">
          <SelectBox
            className="form-select form-control"
            OptionValue={Salutation}
            name="lstSalutation"
            id="lstSalutation"
            onChange={() => clearErrors("lstSalutation")}
            clearErrors={clearErrors}
            myRef={validation({ required: "Please Select Title" })}
            validationMsg={
              validationMsg.lstSalutation && validationMsg.lstSalutation.message
            }
          ></SelectBox>
        </div>
        <div className="input-group mb-3">
          <TextField
            type="text"
            className="form-control"
            placeholder="First Name"
            name="txtFName"
            id="txtFName"
            onBlur={namevalidate}
            validation={validation({
              required: "Please Enter First Name",
              minLength: {
                value: 3,
                message: "Please Enter Valid First Name",
              },
              pattern: {
                value: /^[a-zA-Z\s]+$/,
                message: "Your Name is Invalid. Please Recheck",
              },
            })}
          ></TextField>
          <span className="input-group-text privacyico">
            <img src={privacy} alt="" />
          </span>
          {validationMsg.txtFName && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtFName.message}
            </span>
          )}
        </div>
        <div className="input-group mb-3">
          <TextField
            type="text"
            className="form-control"
            placeholder="Last Name"
            name="txtLName"
            id="txtLName"
            onBlur={namevalidate}
            validation={validation({
              required: "Please Enter Last Name",
              minLength: {
                value: 3,
                message: "Please Enter Valid Last Name",
              },
              pattern: {
                value: /^[a-zA-Z\s]+$/,
                message: "Your Name is Invalid. Please Recheck",
              },
            })}
          ></TextField>
          <span className="input-group-text privacyico">
            <img src={privacy} alt="" />
          </span>
          {validationMsg.txtLName && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtLName.message}
            </span>
          )}
        </div>
        <DateOfBirth
          textDob="Date Of Birth"
          validation={validation}
          validationMsg={validationMsg}
          notDobValid={notDobValid}
        />
        <InputButton
          name="personalvalidate"
          className="btn next08  regNextBtn"
          id=""
          value="Next"
          btnType="button"
          onClick={basicDetailsValidation}
          style={{ float: "none" }}
        />
      </div>
      <p className="text-center"><a className="back01" name="back10" onClick={backClick}> {'<< Previous'} </a></p>


      {/* **** PREVIOUS NAME POPUP START **** */}
      <Modal size="md" show={preModal} onHide={handleClosePreModal} centered backdrop="static">
        <Modal.Body>
          <h5 className="text-center  mb-3">Did you have a former surname at the time you took out finance with MoneyBarn?</h5>
          <div className="prevname-Btnpop justify-content-center">
            <InputButton
              className="option_butb2   surNameNo  regNextBtnNew newfont"
              name="no_prev_name"
              value="NO"
              btnType="button"
              onClick={noPreviousName}
            />

            <InputButton
              className="option_butb2   surNameYes  regNextBtnNew newfont "
              name="yes"
              value="YES"
              btnType="button"
              onClick={handleShowNext}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* *****second modal*** */}
      <Modal size="md" show={showNextModal} onHide={handleClose} centered backdrop="static">
        <Modal.Body>
          <h5 className="text-center mb-3">Enter your previous surname</h5>
          <div className="mb-3">
            <TextField
              type="text"
              className="form-control postCode2"
              placeholder="Previous Name"
              name="txtPrevName"
              id="txtPrevName"
              onChange={(event) => setPreviousName(event.target.value)}
              validation={validation({
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Previous Name is Invalid. Please Recheck",
                },
              })}
              validationMsg={
                validationMsg.txtPrevName && validationMsg.txtPrevName.message
              }
            />
          </div>
          <div className="mb-3">
            <InputButton
              className="btn next08  regNextBtnNew text-center-btngreen"
              name="personal"
              value="Next"
              btnType="button"
              onClick={prevNameValidate}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default PersonalDetails;
