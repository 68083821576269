import React, { useState } from "react";
import ProgressBar from './ProgressBar';
import RadioButton from '../../../UI/RadioButton';

const Slide10 = ({
  className,
  slideChange,
  validation,
  trigger,
  clearErrors,
  getValues,
  setError,
  backClick
}) => {

  const [checked1, setChecked1] = useState('');
  const [checked2, setChecked2] = useState('');

  const nextSlide = async (e) => {
    if (e.target.value == 19) {
      setChecked1('activeclass');
    } else {
      setChecked1('');
    }

    if (e.target.value == 20) {
      setChecked2('activeclass');
    } else {
      setChecked2('');
    }

    slideChange(e);
  };

  return (
    <>
      <div id="slide10" className={`col-12 top_minus ${className}`}>
        <ProgressBar value={60} />
        <h3>Have you, (or a company on your behalf), ever filed a complaint with MoneyBarn?</h3>

        <div className="mb-3 text-center">

          <RadioButton
            htmlFor="previously"
            value="19"
            tabIndex="2"
            id="complaintWithMB-1"
            name="complaintWithMB"
            className="radio-button"
            labelName="Yes"
            labelClassName={`option_but next05 ${checked1}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            htmlFor="previously"
            value="20"
            tabIndex="2"
            id="complaintWithMB-2"
            name="complaintWithMB"
            className="radio-button"
            labelName="No"
            labelClassName={`option_but next05 ${checked2}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <p className="text-center">
            <a href="#" className="back09" id="back09" name="back09" onClick={backClick}>
              {'<< Previous'}
            </a>
          </p>

        </div>

      </div>
    </>
  );
};

export default Slide10;