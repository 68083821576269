import React, { useState } from 'react';
import OtpInputBox from '../../../UI/OtpInputBox';
import secureSSLImg from '../../../../assets/Questionnaire21/img/secure_icon.png'

const AccountNumber = ({
    saveInputAns,
    backClick,
    setAccountNumber,
    SkippedLeads
}) => {
  const [acntNumber, setAcntNumber] = useState("");
  const [error, setError] = useState(false);

  const handleOtpChange = (acntNumber) => {
    setAcntNumber(acntNumber);
    setAccountNumber(acntNumber);
  };

  const handleNext = async (e) => {
    if(acntNumber.length != 8) {
        setError(true);
        return;
    } else if(parseInt(acntNumber) === 0){
        setError(true);
        return;
    } else {
        setError(false)
        saveInputAns(e);
    }
  };

  return (
      <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2">
          <div className="row">
              <div className="col-lg-8 mb-2">
                  <div className="otp-input">
                      <OtpInputBox
                          length={8}
                          onChange={handleOtpChange}
                          className={'otp-input-field'}
                      />
                  </div>
                  {error && (
                      <span className='error_msg mt-2 text-center'>Please enter a valid Account Number</span>
                  )}
              </div>
              <div className="col-lg-4 mb-2 ">
                  <button
                      type="button"
                      className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
                      id="nextscroll"
                      name="accountNumber"
                      onClick={handleNext}
                      data-quest-id={87}
                  >
                      Next &#8594;
                  </button>
              </div>
              <div className='text-center'>
                <span className='warning-text mb-2'>{"(Please confirm your Account Number to expedite your claim. If you prefer not to, you can skip this step.)"}</span>
                <br/>
                <img src={secureSSLImg} style={{width:"81px"}} />
              </div>
          </div>
          <div className="row">
              <div className="text-center mt-2">
                  <a className="backbtn skip-btn" name="skipAccountNumber" onClick={()=>{ SkippedLeads("skipAccountNumber") }}>
                      Skip
                  </a>
              </div>
          </div>
          <div className="row">
              <div className="text-center mt-2">
                  <div className="backbtn" name="sortCode" onClick={backClick}>
                      <i className="bi bi-arrow-left"></i> Back
                  </div>
              </div>
          </div>
      </div>
  );
};

export default AccountNumber;

