import React, {
    useRef,
    useState,
    useEffect,
    useContext,
    useReducer,
} from "react";
// import "../../assets/css/QuestionnaireV2.scss";
import "../../assets/AnalyseMB/css/AnalyseMb.scss"
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useUserVerify } from "../../Hooks/useUserVerify";
import { MonthNameArray } from "../../Constants/Constants";
import { queryString } from "../../Utility/QueryString";
import { userInfo } from "../../Hooks/userInfo";
import tms_logo from "../../assets/AnalyseMB/img/a00187b31dd5a4ece0c8f809b69962eb.png";
import loader_img from "../../assets/AnalyseMB/img/review.gif";
import { PossessionMonth, YearPossession } from "../../Constants/Constants";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { useLogRecord } from "../../Hooks/UseLogRecord";

const AnalyseT2a = () => {

    const { visitorParameters } = useContext(VisitorParamsContext);
    const { UserDataInfo } = userInfo();
    const { getUserVerfyValidation, saveUserVerify } = useUserVerify();
    const [verifyData, setVerifyData] = useState({});
    const [t2aStatus, setT2aStatus] = useState("");
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const [UserUUid, setUserUuid] = useState("");
    const [user_id, SetUser_id] = useState("");
    const history = useHistory();
    const splitForm = useRef(null);
    const { updateLogRecord } = useLogRecord();

    const [userDetails, setUserDetails] = useState([]);

    const { register, errors, trigger, setError, clearErrors, getValues } =
        useForm({
            mode: "onBlur",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });

    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");

    const query_string = localStorage.getItem("queryString");
    let useruuid;
    let fName;
    let lName;
    let pCode;
    let addressLine1;
    let dobYr;
    let dobDy;
    let addressLine2;
    let postTown;
    let country;
    let title;
    let addressLine3;
    let addressLine4;
    let county;
    let dobMon;
    let email;

    useruuid = localStorage.getItem("uuid");


    const getCurrentDateTime = () => {
        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const hours = currentDate.getHours().toString().padStart(2, '0');
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const seconds = currentDate.getSeconds().toString().padStart(2, '0');


        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };




    useEffect(() => {

        const formdata = JSON.parse(localStorage.getItem("formData"));
        if (formdata === undefined || formdata === null || formdata == "") {
            const query = new URLSearchParams(window.location.search);
            const uuid = query.get("uuid");
            // const getResult = async () => {
            //     let resp = await UserDataInfo({ uuid: uuid });
            //     var result = resp.data.response;
            //     return result;
            // };
            // getResult().then((res) => {
            //     console.log(res.first_name);
            //     fName = res.first_name;
            //     lName = res.last_name;
            //     pCode = res.postcode;
            //     addressLine1 = res.address_line1;
            //     dobYr = res.dob_yr;
            //     dobMon = res.dob_month;
            //     dobDy = res.dob_day;
            //     addressLine2 = res.address_line2;
            //     postTown = res.town;
            //     country = res.country;
            //     title = res.title;
            //     addressLine3 = res.address_line3;
            //     county = res.county;
            //     useruuid = res.uuid;
            //     addressLine4 = res.address_Line4;

            // });
            (async () => {
                const response = await UserDataInfo({ uuid: uuid });
                const res = response.data.response;
                console.log(res);

                fName = res.first_name;
                lName = res.last_name;
                pCode = res.postcode;
                addressLine1 = res.address_line1;
                dobYr = res.dob_yr;
                dobMon = res.dob_month;
                dobDy = res.dob_day;
                addressLine2 = res.address_line2;
                postTown = res.town;
                country = res.country;
                title = res.title;
                addressLine3 = res.address_line3;
                county = res.county;
                useruuid = res.uuid;
                addressLine4 = res.address_Line4;
                email = res.email;
                visitorParameters.email = email || false;

                veripyCall();

            })();

        } else {
            const lstSalutation = formdata ? formdata.lstSalutation : "";
            const addr1 = formdata ? formdata.txtHouseNumber : "";
            const addr2 = formdata ? formdata.txtAddress2 : "";
            const addr3 = formdata ? formdata.txtAddress3 : "";
            const dob_date = formdata ? formdata.DobDay : "";
            const dob_month = formdata ? formdata.DobMonth : "";
            const dob_year = formdata ? formdata.DobYear : "";
            const userCity = formdata ? formdata.txtTown : "";
            const userCounty = formdata ? formdata.txtCounty : "";
            const userCountry = formdata ? formdata.txtCountry : "";
            const ph = formdata ? formdata.txtPhone : "";
            const post = formdata ? formdata.txtPostCode.toUpperCase() : "";
            const firstName = formdata ? formdata.txtFName : "";
            const lastName = formdata ? formdata.txtLName : "";
            const addressLine_4 = formdata ? formdata?.addressLine4 : "";
            fName = firstName;
            lName = lastName;
            pCode = post;
            addressLine1 = addr1;
            dobYr = dob_year;
            dobMon = dob_month;
            dobDy = dob_date;
            addressLine2 = addr2;
            postTown = userCity;
            country = userCountry;
            title = lstSalutation;
            addressLine3 = addr3;
            county = userCounty;
            addressLine4 = addressLine_4;
            email = formdata ? formdata?.txtEmail : "";

            veripyCall();

        }
        console.log("here");

        console.log(useruuid);
        console.log(fName);
        console.log(lName);
        console.log(pCode);
        console.log(addressLine1);
        console.log(dobYr);
        console.log(dobMon);
        console.log(dobDy);

        // visitorParameters.email = email || false;



    }, []);

    const veripyCall = () => {

        if (useruuid && fName && lName && pCode && addressLine1 && dobYr && dobMon && dobDy) {
            const startTime = performance.now();
            let startDateTime = getCurrentDateTime();
            (async () => {
                const response = await getUserVerfyValidation(
                    useruuid,
                    fName,
                    lName,
                    pCode,
                    addressLine1,
                    dobYr,
                    dobMon,
                    dobDy,
                    addressLine2,
                    postTown,
                    country,
                    title,
                    addressLine3,
                    addressLine4,
                    county
                );
                let endDateTime = getCurrentDateTime();

                const endTime = performance.now();

                const Timems = endTime - startTime;

                const timeTaken = (Timems / 1000).toFixed(2);  // Convert milliseconds to seconds


                if (response.data.status == "success") {
                    localStorage.setItem("t2aValidation", 1);
                } else {
                    localStorage.setItem("t2aValidation", 0);
                }
                await storeT2aResponse(response.data);
                const LogRecord = await updateLogRecord(
                    useruuid,
                    "Person_Search",
                    timeTaken,
                    startDateTime,
                    endDateTime
                );
            })();
        }

    }


    const storeT2aResponse = async (t2a_data) => {
        try {
            const response = await saveUserVerify(
                visitorParameters.visitor_parameters,
                t2a_data,
                "person_verify_store",
                visitorParameters.data,
                query_string,
                {
                    useruuid: useruuid,
                    fName: fName,
                    lName: lName,
                    pCode: pCode,
                    addressLine1: addressLine1,
                    dobYr: dobYr,
                    dobDy: dobDy,
                    addressLine2: addressLine2,
                    postTown: postTown,
                    country: country,
                    title: title,
                    addressLine3: addressLine3,
                    addressLine4: addressLine4,
                    county: county,
                    dobMon: dobMon,
                });
            const t2a_Validation = localStorage.getItem("t2aValidation");

            if (t2a_Validation == '0' || t2a_Validation == 0) {
                window.scrollTo(0, 0);
                history.push("/DocsPreviewPageMB_UFGN_1?uuid=" + visitorParameters.visitor_parameters.uuid);
            } else {
                window.scrollTo(0, 0);
                history.push("/CreditCheck_MB_UFGN_1?uuid=" + visitorParameters.visitor_parameters.uuid);
            }
        } catch (error) {
            console.error("Error storing T2a response:", error);
        }
    };






    return (
        <>
            <GetVisitorsParams />
            <div className="Analyse_Mb">
                <div className="tms-wrap2 bg-main-pr">
                    <div className={`text-center loader-wrap loader1`}>
                        <div className="loader-logo">
                            <img src={tms_logo} alt="" />
                        </div>
                        <div className="loader">
                            <img src={loader_img} alt="" />
                            <div className="loader-text">
                                <p className=" text-analys">Please wait while we authenticate your personal information.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};

export default AnalyseT2a;