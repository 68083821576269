import React, { useContext, useState } from "react";
// import { lenders } from "../../../../Constants/Lenders";
import CheckboxOutLabel from "../../../UI/CheckboxOutLabel";
import ProgressBar from "../../Layouts/Common/ProgressBar";
import InputButton from "../../../UI/InputButton";
import Modal from "react-bootstrap/Modal";
import CapitalPopup from "../TMS_G_V3/CapitalPopup";
import MoneybarnPopup from "../TMS_G_V3/MoneybarnPopup";
import GeorgeBancoPopup from "../TMS_G_V3/GeorgeBancoPopup";

const Lenders = ({
  className,
  slideChange,
  validation,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  slideChangeAddress,
  splitForm,
  backClick,
  setError,
  setPreviousName,
}) => {
  const [capitalModal, setCapitalModal] = useState("hide");
  const [moneybarnModal, setMoneybarnModal] = useState("hide");
  const [georgeModal, setGeorgeModal] = useState("hide");
  const [lenderName, setLenderName] = useState("hide");

  const validateLender = async (e) => {
    var lenderResult = await trigger("lender");

    if (lenderResult) {
      slideChange(e);
    } else {
      document.getElementById("lender1").focus();
      return false;
    }
  };

  const showModal = async (e) => {
    clearErrors("lender");
    if (e.target.checked === true) {
      setLenderName(e.target.defaultValue);
      if (e.target.dataset.ansId == 1) {
        setCapitalModal("show");
      } else if (e.target.dataset.ansId == 2) {
        setMoneybarnModal("show");
      } else {
        setGeorgeModal("show");
      }
    }
  };

  return (
    <div id="slide-5" className={`slidetop_adjust ${className}`}>
      <ProgressBar value={65} />
      <h3 className="text-center">Select your Lenders</h3>
      <div className="input-section">
        <div className="mb-3 p-0 radio-grp-1a2 row">
          <div className="col-sm-4 text-center ">
            <CheckboxOutLabel
              id="lender1"
              value="Capital One"
              name="lender"
              className="btn-check"
              labelName="Capital One"
              labelClassName={`btn lenders_btn to-modal-btn-2a  ${""}`}
              validation={validation({ required: "Please Select Lender" })}
              dataAnsId={1}
              onClick={showModal}
            />
          </div>
          <div className="col-sm-4 text-center ">
            <CheckboxOutLabel
              id="lender2"
              value="Aqua"
              name="lender"
              className="btn-check"
              labelName="Aqua"
              labelClassName={`btn lenders_btn to-modal-btn-2a  ${""}`}
              validation={validation({ required: "Please Select Lender" })}
              dataAnsId={1}
              onClick={showModal}
            />
          </div>
          <div className="col-sm-4 text-center ">
            <CheckboxOutLabel
              id="lender3"
              value="Moneybarn"
              name="lender"
              className="btn-check"
              labelName="Moneybarn"
              labelClassName={`btn lenders_btn to-modal-btn-2a  ${""}`}
              validation={validation({ required: "Please Select Lender" })}
              dataAnsId={2}
              onClick={showModal}
            />
          </div>
          <div className="col-sm-4 text-center ">
            <CheckboxOutLabel
              id="lender4"
              value="Geroge Banco"
              name="lender"
              className="btn-check"
              labelName="Geroge Banco"
              labelClassName={`btn lenders_btn to-modal-btn-2a  ${""}`}
              validation={validation({ required: "Please Select Lender" })}
              dataAnsId={3}
              onClick={showModal}
            />
          </div>
          <div className="col-sm-4 text-center ">
            <CheckboxOutLabel
              id="lender5"
              value="Vanquis"
              name="lender"
              className="btn-check"
              labelName="Vanquis"
              labelClassName={`btn lenders_btn to-modal-btn-2a  ${""}`}
              validation={validation({ required: "Please Select Lender" })}
              dataAnsId={1}
              onClick={showModal}
            />
          </div>
          <div className="col-sm-4 text-center ">
            <CheckboxOutLabel
              id="lender6"
              value="Trust Two"
              name="lender"
              className="btn-check"
              labelName="Trust Two"
              labelClassName={`btn lenders_btn to-modal-btn-2a  ${""}`}
              validation={validation({ required: "Please Select Lender" })}
              dataAnsId={3}
              onClick={showModal}
            />
          </div>
          <div className="col-sm-4 text-center ">
            <CheckboxOutLabel
              id="lender7"
              value="Safetynet"
              name="lender"
              className="btn-check"
              labelName="Safetynet"
              labelClassName={`btn lenders_btn to-modal-btn-2a  ${""}`}
              validation={validation({ required: "Please Select Lender" })}
              dataAnsId={1}
              onClick={showModal}
            />
          </div>
          <div className="col-sm-4 text-center ">
            <CheckboxOutLabel
              id="lender8"
              value="Lendingstream"
              name="lender"
              className="btn-check"
              labelName="Lendingstream"
              labelClassName={`btn lenders_btn to-modal-btn-2a  ${""}`}
              validation={validation({ required: "Please Select Lender" })}
              dataAnsId={1}
              onClick={showModal}
            />
          </div>
          <div className="col-sm-4 text-center ">
            <CheckboxOutLabel
              id="lender9"
              value="Drafty"
              name="lender"
              className="btn-check"
              labelName="Drafty"
              labelClassName={`btn lenders_btn to-modal-btn-2a  ${""}`}
              validation={validation({ required: "Please Select Lender" })}
              dataAnsId={1}
              onClick={showModal}
            />
          </div>
          <div className="col-sm-4 text-center ">
            <CheckboxOutLabel
              id="lender10"
              value="MBNA"
              name="lender"
              className="btn-check"
              labelName="MBNA"
              labelClassName={`btn lenders_btn to-modal-btn-2a  ${""}`}
              validation={validation({ required: "Please Select Lender" })}
              dataAnsId={1}
              onClick={showModal}
            />
          </div>
          <div className="col-sm-4 text-center ">
            <CheckboxOutLabel
              id="lender11"
              value="Moneyboat"
              name="lender"
              className="btn-check"
              labelName="Moneyboat"
              labelClassName={`btn lenders_btn to-modal-btn-2a  ${""}`}
              validation={validation({ required: "Please Select Lender" })}
              dataAnsId={1}
              onClick={showModal}
            />
          </div>
          <div className="col-sm-4 text-center ">
            <CheckboxOutLabel
              id="lender12"
              value="UK Credit"
              name="lender"
              className="btn-check"
              labelName="UK Credit"
              labelClassName={`btn lenders_btn to-modal-btn-2a  ${""}`}
              validation={validation({ required: "Please Select Lender" })}
              dataAnsId={3}
              onClick={showModal}
            />
          </div>
        </div>
        {validationMsg.lender && (
          <span className="error_msg " id="email_err">
            {validationMsg.lender.message}
          </span>
        )}
      </div>
      <div className="col-lg-12 col-12">
        <InputButton
          name="lendervalidate"
          btnType="button"
          className="btn regNextBtn next10"
          id="formSubmitBtn"
          value="NEXT"
          style={{ float: "none" }}
          onClick={validateLender}
        />
      </div>
      <p className="text-center">
        <span
          className="back-btn btn btn-link"
          name="back04"
          children="<< Previous"
          onClick={backClick}
        ></span>
      </p>
      <CapitalPopup
        lender={lenderName}
        show={capitalModal}
        setstate={setCapitalModal}
      />
      <MoneybarnPopup
        lender={lenderName}
        show={moneybarnModal}
        setstate={setMoneybarnModal}
      />
      <GeorgeBancoPopup
        lender={lenderName}
        show={georgeModal}
        setstate={setGeorgeModal}
      />
    </div>
  );
};
export default Lenders;
