import React, { useState } from "react";

const Section1 = () => {
  return (
    <>
      <section className="howitworks">
        <div className="container text-center">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-12">
              <img src="/assets/VCC_UB_1/img/ico-1.png" alt="" />
              {/* <h4>Maximise Your Refund Amount</h4> */}
              <h4>Get your Mis-sold Catalogue Credit Fees and Charges Back</h4>
              {/* <p>
                Get your Mis-sold Catalogue Credit Fees and Charges Back. Many
                catalogues were not sold properly. You could claim back the fees
                and charges plus 8% interest.
              </p> */}
              <p>Many catalogues were not sold properly. You could claim back the fees and charges plus 8% interest.</p>
            </div>
            <div className="col-lg-4 col-md-4 col-12 bordr">
              <img src="/assets/VCC_UB_1/img/ico-2.png" alt="" />
              <h4>Your Claim Fought by Financial &amp; Legal Experts</h4>
              <p>
                We're ahead of the field, drawing on years of financial services
                and legal expertise for a strong claim
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <img src="/assets/VCC_UB_1/img/ico-3.png" alt="" />
              <h4>Respecting Your Information Security</h4>
              <p>
                We take security very seriously and follow the rules of the Data
                Protection Act
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section1;
