import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

const MinPayment = ({
  clsName,
  reff,
  onselectAnswer
}) => {

  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>How often did you only <strong>make the minimum payment due on the monthly account statement</strong> (rather than paying off a larger amount)?</h3>

        <RadioButtonQuest
          key="Always146"
          className="radio-buttons"
          labelName="Always"
          labelClassName="option_but next-2"
          // labelCountString="a"
          onClick={labelclicked}
          value="Always"
          name="question_56"
          id="opt146"
          dataQuestId={56}
          dataAnsId={146}
          dataAnsLabel="Always"
        />
        <RadioButtonQuest
          key="Sometimes147"
          className="radio-button"
          labelName="Sometimes"
          labelClassName="option_but next-2"
          // labelCountString="b"
          onClick={labelclicked}
          value="Sometimes"
          name="question_56"
          id="opt147"
          dataQuestId={56}
          dataAnsId={147}
          dataAnsLabel="Sometimes"
        />
        <RadioButtonQuest
          key="Twice148"
          className="radio-button"
          labelName="Twice"
          labelClassName="option_but next-2"
          // labelCountString="b"
          onClick={labelclicked}
          value="Twice"
          name="question_56"
          id="opt148"
          dataQuestId={56}
          dataAnsId={148}
          dataAnsLabel="Twice"
        />
        <RadioButtonQuest
          key="Once149"
          className="radio-button"
          labelName="Once"
          labelClassName="option_but next-2"
          // labelCountString="b"
          onClick={labelclicked}
          value="Once"
          name="question_56"
          id="opt149"
          dataQuestId={56}
          dataAnsId={149}
          dataAnsLabel="Once"
        />
        <RadioButtonQuest
          key="Never150"
          className="radio-button"
          labelName="Never"
          labelClassName="option_but next-2"
          // labelCountString="b"
          onClick={labelclicked}
          value="Never"
          name="question_56"
          id="opt150"
          dataQuestId={56}
          dataAnsId={150}
          dataAnsLabel="Never"
        />
      </div>
    </>
  );
};
export default MinPayment;