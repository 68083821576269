import React, { useState, useContext } from "react";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useQuestionnaire } from "../../../../Hooks/useQuestionnaire";
import { useHistory } from "react-router-dom";
import { queryString } from "../../../../Utility/QueryString";
import { useIdUpload } from "../../../../Hooks/useIdUpload";
import { useSkippedLeads } from '../../../../Hooks/useSkippedLeads';

const ImageThumb = ({ image, className }) => {
  return <img src={URL.createObjectURL(image)} className={className} />;
};

const Questionnaire46 = ({
  clsName,
  reff,
  saveInputAns,
  getValues,
  trigger,
  pushPageChange,
  docType,
  split_name,
  uuid
}) => {

  const history = useHistory();
  const { SaveQuestionnaire } = useQuestionnaire();
  const { saveIdUpload, isLoading } = useIdUpload();
  const { skippedLeads } = useSkippedLeads();
  const query_string = localStorage.getItem("querystring");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedFileType, setSelectedFileType] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [document, setDocument] = useState(false);
  const [file, setFile] = useState("");
  const [activeClass, setActiveClass] = useState("disabled");
  const [loader, setLoader] = useState(false);
  const [btnDisabled, SetBtnDisabled] = useState(true);

  const userfile = {
    preview: undefined,
    title: undefined,
  };

  const selectClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();
    let question45Result = await trigger("question_45");
    if (!question45Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag === 0) {
      saveInputAns(e);
    }
  };

  const changeCameraHandler = (event) => {
    setActiveClass("camera");
    SetBtnDisabled(false);
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
    // if (selectedFile) {
    
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage(
          "The uploaded Doc type should be an image in jpeg,png or jpg"
        );
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage("Please choose a file less than 20mb");
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const changeFilesHandler = (event) => {
    SetBtnDisabled(false);
    setActiveClass('fileupload');
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      console.log(extension);
      let imgsize = event.target.files[0].size;
      let allowedExtensions = [
        "image/jpeg",
        "image/png",
        "application/zip",
        "application/msword",
        "application/pdf"
      ];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage("The uploaded Doc type should be an image in jpeg,png,jpg,pdf,doc, or docx");
      } else
        if (imgsize > 1024 * 1024 * 20) {
          setIsInValidfFile(true);
          setErrorMessage("Please choose a file less than 20mb");
        } else {
          setSelectedFileType(event.target.name);
          setIsInValidfFile(false);
          //_handleFileRead.bind(event.target.files[0]);
        }
      setDocument(false);

      if ("nationalIDCard_doc" == event.target.name) {
        setDocument(true);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image(binaryString);
  };

  const savedocument = async () => {
    if (selectedFile) {
      setIsInValidfFile(false);
      try {
        if (visitorParameters.data !== null || visitorParameters.data !== undefined) {
          let uploadData = '';
          uploadData = {
            imageFile: base64Image,
            captureType: selectedFileType.substring(
              selectedFileType.indexOf("doc")
            ),
            documentType: docType
          };

          const response = saveIdUpload(
            uploadData,
            visitorParameters,
            formParameters,
            queryString,
            'support_docsv2'
          );
          pushPageChange();
        }
      } catch (e) {
        console.warn("error");
        console.warn(e);
        setIsInValidfFile(true);
        setErrorMessage("Please try again");
      }
    } else {
      setIsInValidfFile(true);
      setErrorMessage("Please choose an option");
    }
  };

  const onSelectOption = (e) => {
    setIsInValidfFile(false);
    SetBtnDisabled(false);
    if (e.target.name == 'FinanceAgreementDocEmail') {
      setSelectedFile(undefined);
      setActiveClass('email');
    } else if (e.target.name == 'FinanceAgreementDocSkip') {
      setSelectedFile(undefined);
      setActiveClass('skip');
    } else if (e.target.name == 'closefile') {
      setActiveClass('disabled');
      SetBtnDisabled(true);
      setSelectedFile(undefined);
    }
  };

  const onProceed = (e) => {
    setLoader(true);
    if (activeClass == "fileupload" || activeClass == "camera") {
      savedocument();
    } else if (activeClass == "email") {
      SkipSupportDocs("EmailDoc");
    } else if (activeClass == "skip") {
      SkipSupportDocs("SkipDoc");
    } else {
      setIsInValidfFile(true);
      setErrorMessage("Please choose an option");
    }
  }

  const SkipSupportDocs = async (type) => {
    SetBtnDisabled(true);
    const skippedLeadsResponse = await skippedLeads(type, uuid, split_name);
    if (skippedLeadsResponse.data.status == "Success") {
      pushPageChange();
    }
  }

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>Supporting Documentation</h3>
        <div className="row ps-1 mt-4">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ps-0 p-1" >
            <div className={`btn btn-lg btn-file w-100 ${activeClass == 'fileupload' && 'activeclass'}`}>
              <img src="assets/img/lap-icon.png" alt="" />
              &nbsp;
              UPLOAD file from your device
              <input
                type="file"
                className="take_picture"
                id="FinanceAgreementDoc"
                name="FinanceAgreementDoc"
                onChange={changeFilesHandler}
                onClick={(e) => (e.target.value = null)}
              />
            </div>
          </div>
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 ps-0 d-lg-none p-1">
            <div>
              <div className={`btn btn-lg btn-file w-100 btn-fileimp ${activeClass == 'camera' && 'activeclass'}`}>
                <img src="assets/img/camera-icon.png" alt="" />
                &nbsp;
                TAKE A PHOTO of the file from your device
                <input
                  type="file"
                  accept="image/*"
                  capture="camera"
                  className="take_picture"
                  name="FinanceAgreementDocCamera"
                  onChange={changeCameraHandler}
                  onClick={(e) => (e.target.value = null)}
                />
              </div>
            </div>
          </div>
          <div className="col-12 ps-0 p-1">
            <div>
              <div className={`btn btn-lg btn-file w-100 btn-fileimp ${activeClass == 'email' && 'activeclass'}`}>
                &nbsp;
                EMAIL file to "documents@themoneysolicitor.com"
                <input
                  type="radio"
                  className="take_picture"
                  name="FinanceAgreementDocEmail"
                  onClick={onSelectOption}
                />
              </div>
            </div>
          </div>
          <div className="col-12 ps-0 p-1">
            <div>
              <div className={`btn btn-lg btn-file w-100 btn-fileimp ${activeClass == 'skip' && 'activeclass'}`}>
                &nbsp;
                SKIP for now and follow up ASAP
                <input
                  type="radio"
                  className="take_picture"
                  name="FinanceAgreementDocSkip"
                  onClick={onSelectOption}
                />
              </div>
            </div>
          </div>
        </div>
        {isInValidfFile && <p className="error-msg">{errorMessage}</p>}
        <div className="row ps-1 text-center" >
          {selectedFile &&
            <div className="d-flex justify-content-center">
              {selectedFile.name}
              <button className="btn btn-secondary btn-sm ms-2" name="closefile" onClick={onSelectOption}>
                Remove
              </button>
            </div>}
          {/* <input type="button" name="support_doc" disabled={btnDisabled} onClick={onProceed} value="Proceed" className={`btn-next next-5 ${activeClass == 'disabled' && 'disabledclass'}`} /> */}
          <button type="button" name="support_doc" disabled={btnDisabled} onClick={onProceed} className={`btn-next next-5 ${activeClass == 'disabled' && 'disabledclass'}`} >
            Proceed
            {loader &&
              <div class="spinner-border text-primary ms-2" role="status">
                <span class="sr-only"></span>
              </div>}
          </button>
        </div>
        <div className="clearfix"></div>
        <div className="col-xl-12 col-lg-12 text-center p-0" >
          <p className="font-italic text-center">For your convenience, copies of supporting documents can be emailed "documents@themoneysolicitor.com"
          </p>
        </div>
      </div>
    </>
  );
};
export default Questionnaire46;
