import React, { useEffect, useState } from 'react';
import SelectBox from '../../../UI/SelectBox';
import { MonthlyIncome } from "../../../../Constants/Constants";



const Questionaire78 = ({
	clsName,
	reff,
	validation,
	validationMsg,
	trigger,
	getValues,
	saveInputAns,
	backClick
}) => {



	// const [loader, showLoader] = useState(false);

	const handleClick = async (e) => {
		let MonthlyIncome = await trigger("question_78");
		let errorFlag = 0;
		if (!MonthlyIncome) {
			errorFlag = 1;
		}
		if (errorFlag === 0) {
			// onselectAnswer(e);  
			saveInputAns(e);
		}
		else {
			return false;
		}
	};

	return (
		<>
			<div className={`${clsName}`} ref={reff} >
				<h3><b>When you took out your car finance what was your monthly income?</b></h3>
				<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
					<SelectBox
						className="form-select form-control"
						OptionValue={MonthlyIncome}
						name="question_78"
						id="question_78"
						myRef={validation({
							required: "Please Select Year"
						})}
						validationMsg={validationMsg.question_78 && validationMsg.question_78.message}
					/>
				</div>
				<div className="col-xl-12 col-lg-12 text-center p-0 mt-2" >
					<button
						type="button"
						className="btn-next"
						onClick={handleClick}
						data-quest-id={78}
						data-ans-id={null}
						data-ans-label={null}
						name="btn_qest_78"
					>{`NEXT`}</button>
				</div>
				<p className="text-center">
					<a href="#" className="backto77" name="backto77" onClick={backClick}>
						&lt;&lt; Previous
					</a>
				</p>
			</div>
			{/* <AnalyzeModal modalClass={loader} /> */}
		</>
	)
}

export default Questionaire78;
