import React , {
  useContext
} from "react";
import { useHistory } from "react-router-dom";
import "../../assets/viewdocs/css/docs.scss";

const ViewdocsFollowup_LS = () => {
  const history = useHistory();
  const getUuid = () => {
    const query = new URLSearchParams(window.location.search);
    const query_uuid = query.get("uuid");
    const local_storage_uuid = localStorage.getItem("uuid");
    var uuid = query_uuid ? query_uuid : local_storage_uuid;
    return uuid;
  };
  const uuid = getUuid();

  const split_name = localStorage.getItem("split_name");
  const redirectPreview = () => {
    if (split_name=='LS_UB_1') {
      history.push("/preview_flp_v3?uuid=" + uuid);
    } else {
      history.push("/preview_flp_v3?uuid=" + uuid);
    }
  } 
  return(
    <>
      <div className ="view_doc">
        <div className ="suc_page">
          <section>
            <div className="container text-center" style={{margin:"auto"}}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="mt-3">
                    <a href className="tms_logo">
                      <img src="/assets/viewdocs/img/logo.png"/>
                    </a>
                  </div>
                  <div className="suc_midle">
                    <div className="loader">
                      <img src="/assets/viewdocs/img/loader-review2.gif" className="image" />
                    </div>
                    <p className="text py-5">
                      Thank you for submitting the documents to allow TMS Legal to represent you in your unaffordable lending claim.&nbsp;
                      <span>
                        We will be in touch shortly.</span>
                    </p>
                    <a href="javascript:void(0)" onClick={redirectPreview} className="btn-view bnt-style"> View Your Documents &gt; </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
export default ViewdocsFollowup_LS;