import React, { useState, useEffect, useRef, useReducer } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import {
  questinnaire76MB,
  questinnare1, questinnare2Very,
  questinnare2VeryV2,
  questionnaire59,
  questionnaire60
} from "../../../../Constants/Questions";
import IncomeExpenditure from "./Very/IncomeExpenditure";
import IncomeExpenditureV2 from "./Very/IncomeExpenditureV2";
import QuestionnaireFlpQ5 from "../Followup/QuestionnaireFlpQ5";
import QuestionnaireFlpFANUpload from "../Followup/QuestionnaireFlpFANUpload";
import { useHistory } from "react-router-dom";
import QuestionnaireFlpAgreementNo from "./QuestionnaireFlpAgreementNo";
import ClosedYear from "./Very/ClosedYear";
import HighestBalance from "./Very/HighestBalance";
import LimitIncrease from "./Very/LimitIncrease";
import HowOften from "./Very/HowOften";
import MinimumPayment from "./Very/MinimumPayment";
import LoanModal from "./Very/LoanModal";
import MonthlyModal from "./Very/MonthlyModal";
import Questionnaire4Model from "./Very/Questionnaire4Modal";
import ConfirmSitution from "./Very/ConfirmSitution";
import QuestionnaireEmail from "../Followup/QuestionnaireEmail";
import { useUserVerify } from "../../../../Hooks/useUserVerify";
import { userMilestoneUpdate } from "../../../../Hooks/userMilestoneUpdate";
import { queryString } from "../../../../Utility/QueryString";
import "../../../../assets/css/QuestionnaireV7.scss";
import QuestionnaireFooter from "./QuestionnaireFooter";
import QuestionnaireSupportDoc from "./QuestionnaireSupportDoc";
import QuestionnaireAgreementNoModal from "./QuestionnaireAgreementNoModal";
import { useSkippedLeads } from "../../../../Hooks/useSkippedLeads";
import AnalyzeModal from "./AnalayzeModal";

const initialState = {
  showSlide1: "hide",
  showSlide1a: "hide",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide",
  showSlide6: "hide",
  showSlide6a: "hide",
  showSlide7: "hide",
  showSlide8: "hide",
  showSlide9: "hide",
  showSlide10: "hide",
  loanModal: "hide",
  monthlyModal: "hide",
  showModel1: "hide",
  showModel2: "hide",
  confirm_sitution :"hide",
};
const QuestionnaireReducer = (state, action) => {
  switch (action.type) {
    case "showSlideChange": {
      if (action.payload == "slide1") {
        return {
          ...state,
          showSlide1: "show",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          confirm_sitution :"hide",
          percentage: 10,
        };
      } else if (action.payload == "slide1a") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "show",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          confirm_sitution :"hide",
          percentage: 20,
        };
      } else if (action.payload == "slide2") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "show",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          confirm_sitution :"hide",
          percentage: 30,
        };
      } else if (action.payload == "slide3") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "show",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          confirm_sitution :"hide",
          percentage: 40,
        };
      } else if (action.payload == "slide4") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "show",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          confirm_sitution :"hide",
          percentage: 50,
        };
      } else if (action.payload == "slide5") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "show",
          showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          confirm_sitution :"hide",
          percentage: 60,
        };
      } else if (action.payload == "slide6") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "show",
          showSlide6a: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          confirm_sitution :"hide",
          percentage: 65,
        };
      }
      else if (action.payload == "slide6a") {  // For Very v2 Income and Expenditure Details slide
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "show",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          confirm_sitution :"hide",
          percentage: 70,
        };
      }
      else if (action.payload == "slide7") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "show",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          confirm_sitution :"hide",
          percentage: 75,
        };
      }else if (action.payload == "confirm_sitution") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "hide",
          confirm_sitution: "show",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          percentage: 78,
        };
      } else if (action.payload == "slide8") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "hide",
          showSlide8: "show",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          confirm_sitution :"hide",
          percentage: 80,
        };
      } else if (action.payload == "slide9") {
        window.scrollTo(0,0);
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "show",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          confirm_sitution :"hide",
          percentage: 90,
        };
      } else if (action.payload == "slide10") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "show",
          loanModal: "hide",
          monthlyModal: "hide",
          confirm_sitution :"hide",
          percentage: 95,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "expenseModal": {
      if (action.payload == "loanModal") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "show",
          monthlyModal: "hide",
          confirm_sitution :"hide",
        };
      } else if (action.payload == "monthlyModal") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "show",
          confirm_sitution :"hide",
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "showModel": {
      if (action.payload.clickedModel.model == "monthly" && action.payload.clickedModel.status == "hide") {
        return {
          ...state,

          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "show",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          showModel1: "hide",
          showModel2: "hide",
          confirm_sitution :"hide",


          // showModel1: "hide",
        };
      } else if (action.payload.clickedModel.model == "monthly" && action.payload.clickedModel.status == "show") {
        return {
          ...state,
          showModel1: "show",
          showModel2: "hide",
        };
      } else if (action.payload.clickedModel.model == "other" && action.payload.clickedModel.status == "hide") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide1a: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide6a: "show",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
          loanModal: "hide",
          monthlyModal: "hide",
          showModel1: "hide",
          showModel2: "hide",
          confirm_sitution :"hide",
        };
      } else if (action.payload.clickedModel.model == "other" && action.payload.clickedModel.status == "show") {
        return {
          ...state,
          showModel1: "hide",
          showModel2: "show",
        };
      }
    }
    default:
      return state;
  }
};
const VeryQuestionForm = ({
  lender,
  questionsPop,
  allFollowupData,
  pendingQuestionnaire,
  splitName,
  currentUuid,
  documentType,
  docType,
  sett2aValidation,
  setPendingStatus,
  accClsYear,
  t2aApiReqData,
  t2aStatus,
  setDivShow,
  splitData,
  modalT2a,
  setModalT2a
}) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const splitForm = useRef(null);
  const [optionSelected] = useState([]);
  const [optionSelected76] = useState([]);

  const [clickedSlide, setClickedSlide] = useState([]);
  const [monthlyExpShow, setMonthlyExpShow] = useState("hide");
  const [otherExpShow, setOtherExpShow] = useState("hide");
  const [clickedModel, setClickedModel] = useState([]);

  const [monthlyExpFlage, setMonthlyExpFlage] = useState(false);
  const [otherExpFlage, setOtherExpFlage] = useState(false);
  const [question57Option, setQuestion57Option] = useState('');
  const [question58Option, setQuestion58Option] = useState('');

  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const [checkedQuestionThree, setCheckedQuestionThree] = useState("unchecked");
  const [checkedQuestionFour, setCheckedQuestionFour] = useState("unchecked");
  const [agNoShow, setAgNoShow] = useState("hide");
  

  const { getUserVerfyValidation,saveFollowupUserVerify } = useUserVerify();
  const { updateMilestone } = userMilestoneUpdate();
  const { skippedLeads } = useSkippedLeads();

  const { control, register, formState: { errors }, trigger, setError, clearErrors, getValues } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      quest59s: questionnaire59.questions,
      quest60s: questionnaire60.questions,
    },
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const {
    fields: quest59lenders
  } = useFieldArray({ control, name: "quest59s", keyName: "ccc" });

  const {
    fields: quest60lenders
  } = useFieldArray({ control, name: "quest60s", keyName: "ccd" });


  useEffect(() => {
    setShow(questionsPop);
  }, [questionsPop]);

  useEffect(() => {
    slideChange(pendingQuestionnaire);
  }, [pendingQuestionnaire]);

  const slideChange = (pendingArr) => {
    let pendingCount = pendingArr.length;
    if (pendingCount == 0) {
      setShow(false);
      const t2aResult = localStorage.getItem("t2a_result");
      // if(t2aResult == 'null') {
        sendT2aApiReqDataAPI();
      // } else {
        // handleT2AResult();
      // }
    }

    pendingArr.every((element) => {
      dispatch({ type: "showSlideChange", payload: element });
    });
  };
  const storeFollowupQuestionnire = async (question_data) => {
    const response = await saveFollowupUserQuestions(question_data, allFollowupData, "followup_question_store");
  };

  const SkipAgreement = async () => {
    const skippedLeadsResponse = await skippedLeads('SkipAgreementNoFlp', currentUuid, splitName);
    removeFromPending('slide1');
  };

  const skipAgNo = async (e) => {
    pushPageChange();
    removeFromPending('slide9');
    setAgNoShow('hide');
    const skippedLeadsResponse = await skippedLeads('SkipAgreementNoFlp', currentUuid, splitName);
  }


  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    let flage = false;
    let question_data = {};
    if (e.target.name == "question_54") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("slide3");
    } else if (e.target.name == "question_55") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("slide4");
    } else if (e.target.name == "question_56") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("slide5");
    } else if (e.target.name == "btn_qest_36") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("slide8");
    } else if (e.target.name == "btn_qest_76") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 76,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected76,
        };
      }
      removeFromPending("confirm_sitution");
    }else if (e.target.name == "btn_qest_42") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 42,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      } 
      if (selectedAns == '124') {
        setAgNoShow('show');
      }
      else if (selectedAns == '123') {
        removeFromPending("slide9");
        pushPageChange();
      }
      else {
        removeFromPending("slide9");
      }
    } else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem("currentquestionData", JSON.stringify(question_data));
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };
  const handleT2AResult = () => {
    const t2aResult = localStorage.getItem("t2a_result");
    if (t2aResult) {
      if (t2aResult == 1) {
        history.push("/preview_flp_v4?uuid=" + currentUuid);
      } else {
        setDivShow("idUpload");
      }
    } else {
      if (t2aStatus == 0) {
        setDivShow("idUpload");
      } else {
        history.push("/preview_flp_v4?uuid=" + currentUuid);
      }
    }
  };

  const saveIncomeExpenditure = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    const values = getValues();
    setCompletedQuestArr([...completedQuestArr, selectedQuest, 32, 33, 35]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    var question_57 = splitForm.current["question_57"].value;
    var question_58 = splitForm.current["question_58"].value;
    var question_59 = values["question_59"];
    var question_60 = values["question_60"];

    if (question_57 == 151 && (question_59 == undefined || question_59 == "")) {
      dispatch({ type: "expenseModal", payload: "loanModal" });
      return false;
    } else if (question_58 == 153 && (question_60 == undefined || question_60 == "")) {
      dispatch({ type: "expenseModal", payload: "monthlyModal" });
      return false;
    } else {
      var slide_array = [
        ["31", answer31],
        ["32", answer32],
        ["33", answer33],
        ["57", question_57],
        ["58", question_58],
      ];

      if (question_59 != "") {
        slide_array.push(["59", question_59]);
      }
      if (question_60 != "") {
        slide_array.push(["60", question_60]);
      }

      var question_data = {
        slide_4: slide_array,
      };
      removeFromPending("slide6");
      storeFollowupQuestionnire(question_data);

      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    }
  };


  const saveSlide4 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");

    const values = getValues();
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    var answer31 = splitForm.current["question_31_v2"].value;
    var answer32 = splitForm.current["question_32_v2"].value;
    var answer33 = splitForm.current["question_33_v2"].value;
    // var answer34 = splitForm.current["question_34"].value;
    // var answer35 = splitForm.current["question_35"].value;

    let answer57 = splitForm.current["question_57_v2"].value;
    let answer58 = splitForm.current["question_58_v2"].value;
    // let answer59 = splitForm.current["question_59"].value;
    // let answer60 = splitForm.current["question_60"].value;

    var slide_array = [
      ["31", answer31],
      ["32", answer32],
      ["33", answer33],
      // ["34", answer34],
      // ["35", answer35],
      ["57", answer57],
      ["58", answer58]
    ];

    console.log("answer57:-" + answer57);
    console.log("answer58:-" + answer58);
    console.log(values);
    // if(answer59!='') {
    //   slide_array.push(["59", answer59]);
    // }
    // if(answer60!='') {
    //   slide_array.push(["60", answer60]);
    // }
    let lenderExps = [];
    values.lender.find((elm) => {
      if (Object.values(elm)[0]) {
        lenderExps.push(elm);
      }
    });
    let lenderOthers = [];
    values.lenderother.find((elm) => {
      if (Object.values(elm)[0]) {
        lenderOthers.push(elm);
      }
    })

    if (answer57 == '151') {
      // slide_array.push(["59", JSON.stringify(values.lender)]);
      slide_array.push(["59", JSON.stringify(lenderExps)]);
    }
    if (answer58 == '153') {
      // slide_array.push(["60", JSON.stringify(values.lenderother)]);
      slide_array.push(["60", JSON.stringify(lenderOthers)]);
    }
    var question_data = {
      slide_4: slide_array,
    };
    removeFromPending("slide6a");
    storeFollowupQuestionnire(question_data);

    // setClickedSlide({ slide: "slide_4" });
    // storeQuestionnire(question_data);

    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    const valuesreg = getValues();
    let highestBalance = valuesreg["highest_balance"];
    let agreementNo = valuesreg["question_45"];
    let email = valuesreg["question_48"];
    let accCloseYr = valuesreg["question_52"];
    let flage = false;
    let question_data = {};
    let modalAgreementNo = valuesreg["modal_question_45"];
    if (selectedQuest == "52" && accCloseYr) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: accCloseYr,
      };
      removeFromPending("slide1a");
    }
    if (selectedQuest == "45" && agreementNo) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: agreementNo,
      };
      removeFromPending("slide1");
    }

    if (selectedQuest == "45" && modalAgreementNo) {
      pushPageChange();
      removeFromPending("slide9");
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: modalAgreementNo,
      };
    }

    if (selectedQuest == "53" && highestBalance) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: highestBalance,
      };
      removeFromPending("slide2");
    }
    if (selectedQuest == "48" && email) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: email,
      };
      removeFromPending("slide7");
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem("currentquestionData", JSON.stringify(question_data));
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const removeFromPending = (slidename) => {
    const index = pendingQuestionnaire.indexOf(slidename);
    if (index > -1) {
      pendingQuestionnaire.splice(index, 1);
    }
    slideChange(pendingQuestionnaire);
  };

  const pushPageChange = () => {
    removeFromPending("slide10");
  };

  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value =
        "£" + e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const totalDebitValidation = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();

    // let question31Result = await trigger("question_59");
    // if (!question31Result) {
    //   errorFlag = 1;
    //   return false;
    // }

    if (values.lender.length > 0) {
      values.lender.map((elem) => {
        Object.keys(elem).map((el) => {
          if (elem[el] != "") {
            errorFlag = errorFlag + 1;
          }
        });
      });
    }

    if (errorFlag == 0) {
      setMonthlyExpFlage(true);
    } else {
      setMonthlyExpFlage(false);
    }
    if (errorFlag >= 1) {
      setQuestion57Option(151);
      let question58Result = splitForm.current["question_58_v2"].value;
      // if (question58Result=='153') {
      //   setClickedModel({ model: "monthly", status: "hide" });
      //   setClickedModel({ model: "other", status: "show" });
      // } else {
      //   setClickedModel({ model: "monthly", status: "hide" });
      //   saveSlide4(e);
      // }

      let question31Result = await trigger("question_31_v2");
      if (!question31Result) {
        errorFlag = 1;
      }

      let question32Result = await trigger("question_32_v2");
      if (!question32Result) {
        errorFlag = 1;
      }

      let question33Result = await trigger("question_33_v2");
      if (!question33Result) {
        errorFlag = 1;
      }

      if (question58Result == '') {
        errorFlag = 1;
      }

      if (errorFlag == 0) {
        setClickedModel({ model: "monthly", status: "hide" });
        saveSlide4(e);
      } else {
        setClickedModel({ model: "monthly", status: "hide" });
      }
    }
  }

  const totalOtherDebitValidation = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();

    // let question60Result = await trigger("question_60");
    // if (!question60Result) {
    //   errorFlag = 1;
    //   return false;
    // }

    if (values.lenderother.length > 0) {
      values.lenderother.map((elem) => {
        Object.keys(elem).map((el) => {
          if (elem[el] != "") {
            errorFlag = errorFlag + 1;
          }
        });
      });
    }
    if (errorFlag == 0) {
      setOtherExpFlage(true);
    } else {
      setOtherExpFlage(false);
    }
    if (errorFlag >= 1) {
      setQuestion58Option(153);
      // setClickedModel({ model: "monthly", status: "hide" });
      // setClickedModel({ model: "other", status: "hide" });
      // saveSlide4(e);

      let question31Result = await trigger("question_31_v2");
      if (!question31Result) {
        errorFlag = 1;
      }

      let question32Result = await trigger("question_32_v2");
      if (!question32Result) {
        errorFlag = 1;
      }

      let question33Result = await trigger("question_33_v2");
      if (!question33Result) {
        errorFlag = 1;
      }
      let question57Result = splitForm.current["question_57_v2"].value;
      if (question57Result == '') {
        errorFlag = 1;
      }

      if (errorFlag == 0) {

        setClickedModel({ model: "monthly", status: "hide" });
        setClickedModel({ model: "other", status: "hide" });
        saveSlide4(e);
      } else {
        setClickedModel({ model: "monthly", status: "hide" });
        setClickedModel({ model: "other", status: "hide" });
      }

    }
  }

   const skipTotalDebit = (e) => {
    setQuestion57Option(152);
    setCheckedQuestionOne("unchecked");
    setCheckedQuestionTwo("checked");
    setClickedModel({ model: "monthly", status: "hide" });
  };
  const skipOtherDebit = (e) => {
    setQuestion58Option(154);
    setCheckedQuestionThree("unchecked");
    setCheckedQuestionFour("checked");
    setClickedModel({ model: "other", status: "hide" });
  };

  const sendT2aApiReqDataAPI = () => {
    const useruuid = t2aApiReqData.uuid;
    const fName = t2aApiReqData.fName;
    const lName = t2aApiReqData.lName;
    const pCode = t2aApiReqData.pCode;
    const addressLine1 = t2aApiReqData.addressLine1;
    const dobDay = t2aApiReqData.day;
    const dobMonth = t2aApiReqData.month;
    const dobYear = t2aApiReqData.year;
    const addressLine2 = t2aApiReqData.addressLine2;
    const postTown = t2aApiReqData.postTown;
    const country = t2aApiReqData.country;
    const title = t2aApiReqData.title;
    const addressLine3 = t2aApiReqData.addressLine3;
    const addressLine4 = t2aApiReqData.addressLine4;
    const county = t2aApiReqData.county;

    if (
      useruuid &&
      fName &&
      lName &&
      pCode &&
      addressLine1 &&
      t2aStatus == null
    ) {
      setModalT2a(true);
      (async () => {
        const response = await getUserVerfyValidation(
          useruuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYear,
          dobMonth,
          dobDay,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
          addressLine4,
          county,
        );
        storeT2aResponse(response.data);

        if (response.data.status == "success") {
          setModalT2a(false);
          localStorage.setItem("t2a_result", 1);
          localStorage.setItem("split_name", splitName);
          history.push("/view_docs_flp?uuid=" + currentUuid);
        } else {
          setModalT2a(false);
          localStorage.setItem("t2a_result", 0);
          setDivShow("idUpload");
        }
      })();
    } else {
      setModalT2a(false);
      localStorage.setItem("t2a_result", t2aStatus);
      let email_skip = localStorage.getItem("email_doc");
      if (useruuid && email_skip != 1) {
        (async () => {
          const response = await updateMilestone(
            useruuid,
            t2aStatus,
            allFollowupData
          );
        })();
      }

      if (t2aStatus == 1) {
        history.push("/view_docs_flp?uuid=" + currentUuid);
      } else if (t2aStatus == 0){
        setDivShow("idUpload");
      }
    }
  };

  const storeT2aResponse = async (t2a_data) => {
    const response = await saveFollowupUserVerify(
      allFollowupData,
      t2a_data,
      "person_verify_store_flp",
      queryString,
      t2aApiReqData
    );
  };

  useEffect(() => {
    if (clickedModel) {
      dispatch({ type: "showModel", payload: { clickedModel } });
    }
  }, [clickedModel]);
  return (
    <>
      <div className="QuestionnaireV7">
        <div className="formsection">
          <div className="container">
            <div className="row">
              <form
                ref={splitForm}
                name="split_form"
                id="user_form"
                method="POST"
                autoComplete="off"
              //onSubmit={handleSubmit}
              >
                <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12">
                  <div className="radio-box animated fadeInUp">
                    <div className={`question1 animated fadeInUp space-top ${state.showSlide1}`} id="breakdown01">
                      <QuestionnaireFlpAgreementNo
                        questArr={questinnare1}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        saveInputAns={saveInputAns}
                        SkipAgreement={SkipAgreement}
                      />
                    </div>

                    <div className={`question2a animated fadeInUp space-top ${state.showSlide1a}`} id="breakdown1a">
                      <ClosedYear
                        lender={splitData.lender}
                        saveInputAns={saveInputAns}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        accClsYear={accClsYear}
                        splitData={splitData}
                      />
                    </div>

                    <div className={`question2 animated fadeInUp space-top ${state.showSlide2}`} id="breakdown1">
                      <HighestBalance
                        lender={splitData.lender}
                        saveInputAns={saveInputAns}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                      />
                    </div>
                    <div className={`question3 animated fadeInUp text-center space-top2 ${state.showSlide3}`} id="breakdown2">
                      <LimitIncrease
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                      />
                    </div>
                    <div className={`question4 animated fadeInUp space-top2 ${state.showSlide4}`} id="breakdown3">
                      <HowOften
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                      />
                    </div>
                    <div className={`question5 animated space-top2 fadeInUp  ${state.showSlide5}`} id="breakdown4">
                      <MinimumPayment
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                      />
                    </div>



                    <div className={`space-top2 ${state.showSlide6}`} id="breakdown5">
                      <IncomeExpenditure
                        questArr={questinnare1}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        saveIncomeExpenditure={saveIncomeExpenditure}
                        splitForm={splitForm}
                      />
                    </div>

                    <div className={`question6a animated fadeInUp space-top2 ${state.showSlide6a}`} id="breakdown5a">
                      {/* <IncomeExpenditureV2
              questArr={questinnare1}
              labelclicked={onselectAnswer}
              onselectAnswer={onselectAnswer}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
              saveIncomeExpenditure={saveIncomeExpenditure}
              splitForm={splitForm}
            /> */}




                      <IncomeExpenditureV2
                        // clsName={`space-top2 ${state.showSlide5}`}
                        clsName={`space-top2`}
                        // reff={(el) => (scrollRef.current[4] = el)}
                        questArr={questinnare1}
                        saveSlide4={saveSlide4}
                        name="question_31_35"
                        btnName="slide_4"
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        setClickedModel={setClickedModel}
                        monthlyExpShow={monthlyExpShow}
                        setMonthlyExpShow={setMonthlyExpShow}
                        otherExpShow={otherExpShow}
                        setOtherExpShow={setOtherExpShow}
                        question57Option={question57Option}
                        setQuestion57Option={setQuestion57Option}
                        question58Option={question58Option}
                        setQuestion58Option={setQuestion58Option}
                        checkedQuestionOne={checkedQuestionOne}
                        setCheckedQuestionOne={setCheckedQuestionOne}
                        checkedQuestionTwo={checkedQuestionTwo}
                        setCheckedQuestionTwo={setCheckedQuestionTwo}
                        checkedQuestionThree={checkedQuestionThree}
                        setCheckedQuestionThree={setCheckedQuestionThree}
                        checkedQuestionFour={checkedQuestionFour}
                        setCheckedQuestionFour={setCheckedQuestionFour}
                      />
                    </div>
                    <div className={`question7 animated fadeInUp space-top ${state.showSlide7}`} id="breakdown9">
                      <QuestionnaireEmail
                        validation={register}
                        getValues={getValues}
                        trigger={trigger}
                        validationMsg={errors}
                        saveInputAns={saveInputAns}
                        lender={splitData.lender}
                      />
                    </div>
                    <div
                    className={`question9 animated fadeInUp space-top2 ${state.confirm_sitution}`}
                    id="breakdow10"
                  >
                    <ConfirmSitution
                      questArr={questinnaire76MB}
                      onselectAnswer={onselectAnswer}
                      optionSelected76={optionSelected76}
                    />
                  </div>
                    <div className={`question8 animated fadeInUp space-top2 ${state.showSlide8}`} id="breakdow10">
                      <QuestionnaireFlpQ5
                        questArr={questinnare2VeryV2}
                        onselectAnswer={onselectAnswer}
                        optionSelected={optionSelected}
                      />
                    </div>
                    <div className={`question8 animated fadeInUp space-top2 ${state.showSlide9}`} id="breakdow10">
                      <QuestionnaireSupportDoc
                        questArr={questinnare2Very}
                        onselectAnswer={onselectAnswer}
                        optionSelected={optionSelected}
                        lender={splitData.lender}
                      />
                    </div>
                    <div className={`question9 animated fadeInUp space-top ${state.showSlide10} radio-box`} id="breakdown11">
                      <QuestionnaireFlpFANUpload
                        questArr={questinnare2Very}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        split_name={splitName}
                        docType={docType}
                        documentType={documentType}
                        allFollowupData={allFollowupData}
                        sett2aValidation={sett2aValidation}
                        pushPageChange={pushPageChange}
                      />
                    </div>

                  </div>
                </div>
                <div className={`fade modal blackover ${state.loanModal}`} id="breakdown7">
                  <LoanModal
                    saveIncomeExpenditure={saveIncomeExpenditure}
                    validation={register}
                    validationMsg={errors}
                    getValues={getValues}
                    trigger={trigger}
                    setError={setError}
                  />
                </div>
                <div className={`fade modal blackover ${state.monthlyModal}`} id="breakdown8">
                  <MonthlyModal
                    saveIncomeExpenditure={saveIncomeExpenditure}
                    validation={register}
                    validationMsg={errors}
                    getValues={getValues}
                    trigger={trigger}
                    setError={setError}
                  />
                </div>

                <Questionnaire4Model
                  questArr={questinnare1}
                  validation={register}
                  validationMsg={errors}
                  trigger={trigger}
                  monthlyExpShow={monthlyExpShow}
                  otherExpShow={otherExpShow}
                  handleChange={handleChange}
                  totalDebitValidation={totalDebitValidation}
                  totalOtherDebitValidation={totalOtherDebitValidation}

                  showModel1={state.showModel1}
                  showModel2={state.showModel2}

                  quest59lenders={quest59lenders}
                  quest60lenders={quest60lenders}
                  control={control}
                  register={register}
                  monthlyExpFlage={monthlyExpFlage}
                  otherExpFlage={otherExpFlage}
                  skipTotalDebit={skipTotalDebit}
                  skipOtherDebit={skipOtherDebit}
                />

                <QuestionnaireAgreementNoModal
                  validation={register}
                  validationMsg={errors}
                  trigger={trigger}
                  saveInputAns={saveInputAns}
                  agNoShow={agNoShow}
                  skipAgNo={skipAgNo}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
      <QuestionnaireFooter
        percentage={state.percentage}
      />
    </>
  );
};
export default VeryQuestionForm;
