import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import AlertModal from "./AlertModal";
import AnalyzeModal from "./AnalayzeModal";


const Questionnaire3 = ({
  clsName,
  reff,
  onselectAnswer,
  uuid,
  page_name,
}) => {
  
  const labelclicked = async (e) => {
      onselectAnswer(e); 
  };
  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>
        Approximately how many times was your credit limit increased on this account?
        </h3>

        <RadioButtonQuest
          key="YES"
          className="radio-button"
          labelName="1"
          labelClassName="option_but next-2"
          // labelCountString="a"
          onClick={labelclicked}
          value="1"
          name="question_54"
          id="one"
          dataQuestId={54}
          dataAnsId={137}
          dataAnsLabel="1"
        />

        <RadioButtonQuest
          key="NO"
          className="radio-button"
          labelName="2"
          labelClassName="option_but next-2"
          // labelCountString="b"
          onClick={labelclicked}
          value="NO"
          name="question_54"
          id="two"
          dataQuestId={54}
          dataAnsId={138}
          dataAnsLabel="2"
        />

        <RadioButtonQuest
          key="3"
          className="radio-button"
          labelName="3"
          labelClassName="option_but next-2"
          // labelCountString="c"
          onClick={labelclicked}
          value="3"
          name="question_54"
          id="three"
          dataQuestId={54}
          dataAnsId={139}
          dataAnsLabel="3"
        />
        <RadioButtonQuest
          key="4"
          className="radio-button"
          labelName="4 or more"
          labelClassName="option_but next-2"
          // labelCountString="d"
          onClick={labelclicked}
          value="4"
          name="question_54"
          id="four"
          dataQuestId={54}
          dataAnsId={140}
          dataAnsLabel="4 or more"
        />
      </div>
    </>
  );
};

export default Questionnaire3;
