import React, { useEffect, useState } from "react";
import SelectBox from "../../../../UI/SelectBox";
import AnalyzeModal from "../AnalayzeModal";
import { Employmentsts,EmploymentNDY } from "../../../../../Constants/Constants";

const EmployementStatus = ({
  clsName,
  reff,
  // checkCarRegistration,
  validation,
  validationMsg,
  trigger,
  getValues,
  setError,
  page_name,
  uuid,
  split_name,
  saveInputAns,
  onselectAnswer,
  lender,
  intialLoanYear,
  newDayData,
  yearUpdated
}) => {
  const optionSelected = async (e) => {
    let errorFlag = 0;
    let result = await trigger("employment");

    if (!result) {
      errorFlag = 1;
      return false;
    } else {
      onselectAnswer(e);
    }
  };

  const [userLoandate,SetUserLoanDate]=useState("");
  
  useEffect(() => {
	 let loanYear = newDayData.loan_year || intialLoanYear;
   SetUserLoanDate(loanYear);

	}, [yearUpdated]);
  return (
    <>
      <div className={`radio-box animated fadeInUp `} ref={reff}>
        <h3>
          <b>
          In {userLoandate}, at the time you took out the account with {lender}, what was your employment status?

          </b>
        </h3>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
          <SelectBox
            className="form-select form-control"
            OptionValue={EmploymentNDY}
            name="employment"
            id="employment"
            myRef={validation({
              required: "Please select your employement status",
            })}
            validationMsg={
              validationMsg.employment && validationMsg.employment.message
            }
          />
        </div>
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button
            type="button"
            className="btn-next"
            onClick={optionSelected}
            data-quest-id={2}
            data-ans-id={null}
            data-ans-label={null}
            name="btn_qest_2"
          >{`NEXT`}</button>
        </div>
      </div>
    </>
  );
};

export default EmployementStatus;
