import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useContext,
} from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import StillActive from "../Includes/Layouts/MB_UBRF_3/StillActive";
import VehiclePossession from "../Includes/Layouts/MB_UBRF_3/VehiclePossession";
import Dependents from "../Includes/Layouts/MB_UBRF_3/Dependents";
import YearOfPossession from "../Includes/Layouts/MB_UBRF_3/YearOfPossession";
import Verify from "../Includes/Layouts/MB_UBRF_3/Verify";
import MonthlyExpenses from "../Includes/Layouts/MB_UBRF_3/MonthlyExpenses";
import Verify2 from "../Includes/Layouts/MB_UBRF_3/Verify2";
import Complaint from "../Includes/Layouts/MB_UBRF_3/Complaint";
import Bankruptcy from "../Includes/Layouts/MB_UBRF_3/Bankruptcy";
import PersonalDetails from "../Includes/Layouts/MB_UBRF_3/PersonalDetails";
import PostCode from "../Includes/Layouts/MB_UBRF_3/PostCode";
import ContactDetails from "../Includes/Layouts/MB_UBRF_3/ContactDetails";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import * as EnvConstants from "../../Constants/EnvConstants";
import EmploymentStatus from "../Includes/Layouts/MB_UBRF_3/EmploymentStatus";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { blacklist } from "../../Hooks/blacklist";
import SupportingDocs from "../Includes/Layouts/MB_UBRF_3/SupportingDocs";
import EmploymentStatusNEW_OPT from "../Includes/Layouts/MB_UBRF_3/EmploymentStatusNEW_OPT";
import BankName from "../Includes/Layouts/MB_UBRF_3/BankName";
import BankStatement from "../Includes/Layouts/MB_UBRF_3/BankStatement";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

const FormMB_UBRF_3 = () => {
  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
    showSlide7: "hide",
    showSlide8: "hide",
    showSlide9: "hide",
    showSlide13: "hide",
    showSlide10: "hide",
    showSlide11: "hide",
    showSlide12: "hide",
    showSlide14: "hide", //bank statement question
  };
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "stillactive") {
          GtmDataLayer({
            'question': 'Still active?'
          });
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "possession") {
          GtmDataLayer({
            'question': 'Possession of the vehicle?'
          });
          return {
            ...state,
            showSlide2: "hide",
            // showSlide3: "show",
            showSlide14: "show",
          };
        }
        else if (action.payload.clickedSlide.slide === "bankStatement") {
          /* GtmDataLayer({
            'question': 'Possession of the vehicle?'
          }); */
          return {
            ...state,
            showSlide14: "hide",
            showSlide3: "show",
          };
        } 
         else if (action.payload.clickedSlide.slide === "yearposession") {
          GtmDataLayer({
            'question': 'finance agreement year'
          });
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "agreeYear") {
          GtmDataLayer({
            'question': 'finance agreement year'
          });
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "employment") {
          GtmDataLayer({
            'question': 'employment status?'
          });
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show",
          };
        } else if (action.payload.clickedSlide.slide === "dependent") {
          GtmDataLayer({
            'question': 'dependents'
          });          
          return {
            ...state,
            showSlide5: "hide",
            showSlide6: "show",
          };
        } else if (action.payload.clickedSlide.slide === "verify") {
          GtmDataLayer({
            'question': 'hardships or struggle'
          }); 
          return {
            ...state,
            showSlide6: "hide",
            showSlide8: "show",
          };
        // } else if (action.payload.clickedSlide.slide === "supportdocs") {
        //   GtmDataLayer({
        //     'question': 'supporting documentation'
        //   }); 
        //   return {
        //     ...state,
        //     showSlide7: "hide",
        //     showSlide8: "show",
        //   };  
        // } else if (action.payload.clickedSlide.slide === "skip_agree_no") {
        //   return {
        //     ...state,
        //     showSlide7: "hide",
        //     showSlide8: "show",
        //   };  
        } else if (action.payload.clickedSlide.slide === "verify2") {
          GtmDataLayer({
            'question': 'filed complaint?'
          }); 
          return {
            ...state,
            showSlide8: "hide",
            showSlide9: "show",
          };
        } 
         else if (action.payload.clickedSlide.slide === "bankruptcy") {
          GtmDataLayer({
            'question': 'Bankruptcy'
          });
          return {
            ...state,
            showSlide9: "hide",
            showSlide13: "show",
          };
        } 
        else if (action.payload.clickedSlide.slide === "bankname") {
          // GtmDataLayer({
          //   'question': 'Bankruptcy'
          // });
          return {
            ...state,
           showSlide13: "hide",
            showSlide10: "show",
          };
        }
        else if (action.payload.clickedSlide.slide === "no_prev_name") {
          GtmDataLayer({
            'question': 'Name, DOB'
          });
          return {
            ...state,
            showSlide10: "hide",
            showSlide11: "show",
          };
        } else if (action.payload.clickedSlide.slide === "personalvalidate") {
          GtmDataLayer({
            'question': 'Name, DOB'
          });
          return {
            ...state,
            showSlide10: "hide",
            showSlide11: "show",
          };
        } else if (action.payload.clickedSlide.slide === "personal") {
          GtmDataLayer({
            'question': 'Name, DOB'
          });
          return {
            ...state,
            showSlide10: "hide",
            showSlide11: "show",
          };
        } else if (action.payload.clickedSlide.slide === "no_prev_post") {
          GtmDataLayer({
            'question': 'Postcode'
          });
          return {
            ...state,
            showSlide11: "hide",
            showSlide12: "show",
          };
        }else if (action.payload.clickedSlide.slide === "postcode") {
          GtmDataLayer({
            'question': 'Postcode'
          });
          return {
            ...state,
            showSlide11: "hide",
            showSlide12: "show",
          };
        }
         else {
          return {
            ...state,
          };
        }
      }
      case "previousSlides": {
        if (action.payload.previousSlide.slide == "back01") {
          return {
            ...state,
            showSlide1: "show",
            showSlide2: "hide",
          };
        } 
        else if (action.payload.previousSlide.slide == "backToPossesion") {
          return {
            ...state,
            showSlide2: "show",
            showSlide14: "hide",
          };
        }
        else if (action.payload.previousSlide.slide == "back02") {
          return {
            ...state,
            // showSlide2: "show",
            showSlide14: "show",
            showSlide3: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back03") {
          return {
            ...state,
            showSlide3: "show",
            showSlide4: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back04") {
          return {
            ...state,
            showSlide4: "show",
            showSlide5: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back05") {
          return {
            ...state,
            showSlide5: "show",
            showSlide6: "hide",
          };  
        } 
        // else if (action.payload.previousSlide.slide == "back055") {
        //   return {
        //     ...state,
        //     showSlide6: "show",
        //     showSlide7: "hide",
        //   };
        // }
         else if (action.payload.previousSlide.slide == "back06") {
          return {
            ...state,
            showSlide6: "show",
            showSlide8: "hide",
          };
        } 
        else if (action.payload.previousSlide.slide == "back07") {
          return {
            ...state,
            showSlide8: "show",
            showSlide9: "hide",
          };
        } 
        else if (action.payload.previousSlide.slide == "back08") {
          return {
            ...state,
            showSlide13: "show",
            showSlide10: "hide",
          };
        }
        else if (action.payload.previousSlide.slide == "back11") {
          return {
            ...state,
            showSlide9: "show",
            showSlide13: "hide",
          };  
        } else if (action.payload.previousSlide.slide == "back09") {
          return {
            ...state,
            showSlide10: "show",
            showSlide11: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back10") {
          return {
            ...state,
            showSlide11: "show",
            showSlide12: "hide",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      default: {
      }
    }
  };
  const splitForm = useRef(null);
  const scrollfrmv2div = useRef([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const history = useHistory();
  const { saveDataIngestion,formDataIngestion, isLoading } = useDataIngestion();
  const { getBlacklist } = blacklist();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const [previousName, setPreviousName] = useState("");
  const [previousAddressData, setPreviousAddressData] = useState({});
  const [blacklisted, setBlacklisted] = useState([]);
  const [skipAgNo, setSkipAgNo] = useState(false);
  const [SelectBank, setSelectBank] = useState([]);
  const [lenderList, setLenderList] = useState([]);
  const {updateMilestone} = useUpdateMilestone();
  
  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const slideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setClickedSlide({ slide: e.target.name });
 
  };
  const slideChangeAddress = () => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setClickedSlide({ slide: "address" });
  };

  const previousSlideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: "previousSlides", payload: { previousSlide } });
    }
  }, [previousSlide]);

  useEffect(() => {
    let responsesss = [];
    (async () => {
      const response = await getBlacklist();
      let dataLength = response.data.response.length;
      responsesss = response.data.response;
      if (dataLength > 0) {
        setBlacklisted(response.data.response)
      }
    })();
  }, []);

  const formSubmit = () => {
    GtmDataLayer({
      'question': 'Email, Phone',
    });

      const form = splitForm.current;
      const values = getValues();
      const formData = values;

      if (formData.address1 !== "") {
        const txtUdprn = form["txtUdprn"].value;
        const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
        const txtPz_mailsort = form["txtPz_mailsort"].value;
        const txtStreet = form["txtStreet"].value;
        const txtAddress3 = form["txtAddress3"].value;
        const txtDistrict = form["txtDistrict"].value;
        formData.txtDistrict = txtDistrict;
        formData.txtAddress3 = txtAddress3;
        formData.txtUdprn = txtUdprn;
        formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
        formData.txtPz_mailsort = txtPz_mailsort;
        formData.txtStreet = txtStreet;
      }
    
      //  ****Previous Name****
      formData.Previousname = previousName;

      //***previous_address ****/
      formData.previous_postcode=previousAddressData.postcode;
      formData.previous_address_line1 = previousAddressData.line_1;
      formData.previous_address_line2 = previousAddressData.line_2;
      formData.previous_address_line3 = previousAddressData.line_3;
      formData.previous_address_county =previousAddressData.county;
      formData.previous_address_town=previousAddressData.town;
      formData.previous_address_country=previousAddressData.country;
      formData.previous_district=previousAddressData.district;
      formData.previous_address_id= previousAddressData.prevAddressId;
    
      //Sending Questiionaire Data
      formData.question_26 = formData.stillactive;
      formData.question_27 = formData.possession;
      formData.question_28 = formData.YearOfPossession;
      formData.question_2  = formData.employment;
      formData.question_3  = formData.dependent;
      formData.question_29 = formData.verify;
      formData.question_30 = formData.verify2;
      formData.question_9  = formData.bankruptcy;
      formData.question_42 = formData.supportdocs;
      formData.question_45 = formData.question_45;
      formData.skip_ag_no  = skipAgNo;
      formData.question_49 = formData.agreeYear;
      formData.question_74 = formData.bankStatement;  
      //Questiionaire Data Complete

      formData.lender = [SelectBank];
      formData.page_name = "MB_UBRF_3";
      formData.pCode_manual = form["pCode_EntryType"].value;
      
      if (
        visitorParameters.data !== null ||
        visitorParameters.data !== undefined
      ) {
        localStorage.setItem("formData", JSON.stringify(formData));
        localStorage.setItem("queryString", queryString);
        const formSUbmitResult = formDataIngestion(
          visitorParameters.visitor_parameters,
          formData,
          "split_form_submit",
          "MB_UBRF_3",
          queryString,
          visitorParameters.data
        );
        console.log('form submitted MB_UBRF_3');
        // if (formSUbmitResult.data.status === "Success") {

        updateMilestone(visitorParameters.visitor_parameters.uuid,"lead","live");

          let blacklistFlag = false;
          blacklisted.map((value, index) => {
            if ("email" == value.type && value.value == formData.txtEmail) {
              blacklistFlag = true;
            }

            if ("phone" == value.type && value.value == formData.txtPhone) {
              blacklistFlag = true;
            }
          });

          if (blacklistFlag) {
            window.scrollTo(0, 0);
            history.push(
              "/thankyou-bl?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          } else {
            if (queryString != null) {
              if (queryString.indexOf("&" + field + "=") !== -1) {
                if (EnvConstants.AppConversionPixel === "true") {
                  history.push("/fbpixel?split_name=MB_UBRF_3"); // fb fixel firing
                } else {
                  window.scrollTo(0, 0);
                  history.push(
                    "/signatureMB?split_name=MB_UBRF_3&uuid=" + visitorParameters.visitor_parameters.uuid
                  );
                }
              } else {
                window.scrollTo(0, 0);
                history.push(
                  "/signatureMB?split_name=MB_UBRF_3&uuid=" + visitorParameters.visitor_parameters.uuid
                );
              }
            } else {
              window.scrollTo(0, 0);
              history.push(
                "/signatureMB?split_name=MB_UBRF_3&uuid=" + visitorParameters.visitor_parameters.uuid
              );
            }
          }
        // }
      }
  };

  return (
    <>
      <form
        ref={splitForm}
        name="split_form"
        id="user_form"
        method="POST"
        autoComplete="off"
      >
        <div ref={scrollfrmv2div}>
          <StillActive 
            className={`col-12 top_minus_1 ${state.showSlide1}`}
            textHeading="	Is your finance agreement with Moneybarn still active?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
          />
          <VehiclePossession
            className={`col-12 top_minus_1 ${state.showSlide2}`}
            textHeadingLine1="Do you still have possession of the vehicle?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />
          <YearOfPossession
            className={`col-12 top_minus_1 ${state.showSlide3}`}
            textHeadingLine1="What year did you take out your finance agreement with Moneybarn?"
            validation={register}
            clearErrors={clearErrors}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
            trigger={trigger}
            validationMsg={errors}
            setLenderList={setLenderList}
          />

          <BankStatement
            className={`col-12 top_minus_1 ${state.showSlide14}`}
            textHeadingLine1="Did Moneybarn ask you to provide any of the below when you bought the car?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

           {/* <EmploymentStatus
            className={`col-12 top_minus_1 ${state.showSlide4}`}
            textHeadingLine1="when you secured finance,"
            textHeadingLine2="What was your employment status?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          /> */}
          <EmploymentStatusNEW_OPT
            className={`col-12 top_minus_1 ${state.showSlide4}`}
            textHeadingLine1="when you secured finance,"
            textHeadingLine2="What was your employment status?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          <Dependents
            className={`col-12 top_minus_1 ${state.showSlide5}`}
            textHeadingLine1="How many dependents did you have at that time?"
            // textHeadingLine2="How many dependents did you have?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />
          <Verify
            className={`col-12 top_minus_1 ${state.showSlide6}`}
            textHeadingLine1="Have you experienced any hardships or struggle in making the repayments to Moneybarn on time while still meeting all your other commitments?"
            // textHeadingLine2="Did MoneyBarn then verify those details?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />
          <SupportingDocs 
            className={`col-12 top_minus_1 ${state.showSlide7}`}
            textHeadingLine1="What supporting documentation are you able to provide to bolster your complaint with MoneyBarn?"
            textHeadingLine2=""
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
            trigger={trigger}
            validationMsg={errors}
            setSkipAgNo={setSkipAgNo}
          />
          {/* <MonthlyExpenses
            className={`col-12 top_minus_1 ${state.showSlide6}`}
            textHeadingLine1="At the time you secured finance…"
            textHeadingLine2="Did MoneyBark ask about your monthly expenses?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          /> */}
          <Verify2
            className={`col-12 top_minus_1 ${state.showSlide8}`}
            textHeadingLine1="Have you, (or a company on your behalf), ever filed a claim with MoneyBarn?"
            textHeadingLine2=""
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          {/* <Complaint
            className={`col-12 top_minus_1 ${state.showSlide8}`}
            textHeadingLine2="Have you, (or a company on your behalf), ever filed a complaint with MoneyBarn?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          /> */}

          <Bankruptcy
            className={`col-12 top_minus_1 ${state.showSlide9}`}
            textHeadingLine1="Are you currently subject to Bankruptcy or Trust deed?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />
          <BankName
            className={`col-12 top_minus ${state.showSlide13}`}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            // Year={Year}
            SelectBank={SelectBank}
            setSelectBank={setSelectBank}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
            lenderList={lenderList}
            // skip={skip}
          />

          <PersonalDetails
            className={`col-12 top_minus_1 ${state.showSlide10}`}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            getValues={getValues}
            splitForm={splitForm}
            slideChange={slideChange}
            slideChangeAddress={slideChangeAddress}
            backClick={previousSlideChange}
            setError={setError}
            setPreviousName={setPreviousName}
          />
          <PostCode
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            className={`${state.showSlide11}`}
            getValues={getValues}
            splitForm={splitForm}
            scrollView={scrollfrmv2div}
            slideChange={slideChange}
            backClick={previousSlideChange}
            setPreviousAddressData={setPreviousAddressData}
          />
          <ContactDetails
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            className={`col-12 top_minus_1 ${state.showSlide12}`}
            getValues={getValues}
            formSubmit={formSubmit}
            backClick={previousSlideChange}
          />
        </div>
        <div className="col-lg-12 text-center slide-logo">
          <img src="/assets/img/MB_UBRF_3/ssl.svg" alt="" />
        </div>
      </form>
    </>
  );
};

export default FormMB_UBRF_3;
