import { Api } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";

export const userMilestoneUpdateV2 = () => {
  const updateMilestoneV2 = async (
    uuid,
    t2aStatus  
  ) => {
    const response = await Api.post("api/v1/update-milestone-v2", {
      uuid,
      t2a_status: t2aStatus
    });
    return response;
  };
  return { updateMilestoneV2 };
};
