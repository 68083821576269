import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect";
import "../../../../assets/Signature/css/signature.scss";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import SignatureCanvas from "react-signature-canvas";
import CheckBox from "../../../UI/CheckBox";
import { useHistory } from "react-router-dom";
import { useFollowupUserSignature } from "../../../../Hooks/useFollowupUserSignature";
import Footer from "../../../Includes/Layouts/Common/Footer";


const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const SignatureForm = ({ allFollowupData, showQuestion, productId }) => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveFollowupUserSignature, isLoading } = useFollowupUserSignature();
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const signatureWrapRef = useRef(null);

  const handleClick = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        const response = await saveFollowupUserSignature(
          signPad.getCanvas().toDataURL("image/png"),
          allFollowupData,
          'followup_user_signature'
        )
        if (response.data.status === "Success") {
          showQuestion(productId);
        }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    if (isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  useEffect(() => {
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <>
      <div className="tmsversion_signature">
        <header className="d-none d-lg-block">
          <div className="container">
            <div className="row">
              <div className="logo-part col-lg-12 col-md-12 col-12  text-center">
                <img src="/assets/Signature/img/logo.png" alt="" />
              </div>
              <div
                className="col-lg-6 offset-lg-2 col-md-8 col-12 text-center d-none d-md-block "
              ></div>
            </div>
          </div>
        </header>
        <section className="signature-section">
          <div className="container">
            <div className="row ">
              <div className="offset-lg-2 col-lg-8 col-12 sign-question">
                <h2>
                  Great news, you are eligible to make a claim! <br />
                  Sign here to start your claim online NOW.
                </h2>
                <div className="offset-lg-2 col-lg-8 col-12">
                  <ul>
                    <li>Sign using your finger, mouse or stylus</li>
                    <li>Keep it fully contained within the box</li>
                    <li>Ensure it is a true likeness of your signature</li>
                  </ul>
                </div>
                <div className="sign-div">
                  <form>
                    <div className="form-group">
                      <div className="signature_canvaseWrap">
                        <div>
                          <div className="signature-pad anim_bt d-lg-block web-canvas" ref={signatureWrapRef}>
                            <SignatureCanvas
                              canvasProps={{
                                id: "myID",
                                width: signatureWrapWidth,
                                height: signatureWrapHeight,
                              }}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                  document
                                    .getElementById("acceptterms")
                                    .classList.add("anim_ylw");
                                }
                              }}
                            />
                          </div>

                          {state.isAgreed && (
                            <div className="sign_msg col-12 text-center">
                              Please tick to show your authorisation for us to
                              proceed
                            </div>
                          )}
                          {state.isCanvasEmpty && (
                            <div className="sign_msg col-12 text-center sign_msg" id="signatures_required">
                              Signature is required!
                            </div>
                          )}
                          {state.isValid && (
                            <div className="sign_msg col-12 text-center sign_msg" id="signatures_valid">
                              Draw valid signature!
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-check my-3">
                      <CheckBox
                        dataId="acceptterms"
                        anchorText=""
                        anchorStyle=""
                        href=""
                        onChange={(e) => setIsChecked(!isChecked)}
                        className="form-check-input"
                        labelClassName="form-check-label sign-custom-control-label"
                        value={isChecked}
                        CheckBoxText="By clicking submit you agree to us appending your electronic signature to the claim documents and confirming that you accept the terms of this contract for TMS Legal to represent you in your Responsible Lending claim, and that you agree to pay us 45% commission if the claim is successful."
                      />
                    </div>
                    <input
                      type="button"
                      id=""
                      name=""
                      className="btn-clear"
                      value="Clear"
                      onClick={clearSign}
                    />
                    <input
                      className="btn btn-submit"
                      type="button"
                      onClick={handleClick}
                      value="Submit"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};
export default SignatureForm;
