import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';



const Privacy = ({ showstatus, hidepopup }) => {
  return (
    <>
      <div className="modal-custom-div">
        <Modal show={showstatus} onHide={hidepopup} dialog size="xl" ClassName="modal fade privacykl" 
            aria-labelledby="example-custom-modal-styling-title" dialogClassName="privacykl" >
            
          <Modal.Header className="modal-header">
              <Modal.Title  id="privacykellerLabel">PRIVACY AND COOKIE POLICY</Modal.Title>
              <Button  className="close" onClick={hidepopup}>×</Button>
          </Modal.Header>
          <Modal.Body>
                  
            
               <div className="modal-body model-text">
            <h3>Privacy Policy</h3>
            <p>
              <strong>Identity</strong>
            </p>
            <p>TMS Legal Ltd is a legal practice with its registered office at I Poole Road, Bournemouth, Dorset , BH2 5QQ.</p>
            <p>&nbsp; <strong>What is personal data?</strong>
            </p>
            <p>Personal data is information that relates to you or allows us to identify you. This includes obvious things like your name, address and telephone number but can also include less obvious things such as analysis of your use of our website.</p>
            <p>
              <strong>Our responsibility to you</strong>
            </p>
            <p>We process your personal data in our capacity as a data controller. This means that we are responsible for ensuring that we comply with data protection laws when processing your personal data.</p>
            <p>
              <strong>Updating this privacy notice</strong>
            </p>
            <p>This notice may be updated from time to time.</p>
            <p>
              <strong>How we use your information</strong>
            </p>
            <p>This Privacy Notice sets out how we will collect and use your personal data.</p>
            <p>In common with most legal practices we collect, use and share information, including personal data, in connection with the provision of legal services and the running of our business. We use the data primarily for the provision of legal services to you and for related purposes including:</p>
            <ul>
              <li>Contacting you in relation to those legal services and responding to your enquiry</li>
              <li>Contacting and responding to third parties on your behalf in relation to those legal services</li>
              <li>Updating and enhancing client records</li>
              <li>Administration and billing</li>
              <li>Management of our business</li>PRIVACY AND COOKIE POLICY
              <li>Statutory returns</li>
              <li>Legal and regulatory compliance including auditing</li>
              <li>Debt collecting</li>
              <li>Prevention of fraud, money laundering and terrorism</li>
              <li>Verifying your identity and contact details</li>
              <li>Staff recruitment</li>
            </ul>
            <p>
              <strong>Why do we collect and store personal data?</strong>
            </p>
            <p>For the provision of legal services to you, to communicate with you and other parties such as banks, product providers and ombudsmen in relation thereto, to run our business, and to maintain our records.</p>
            <p>In any event, we are committed to ensuring that the information we collect and use is appropriate for this purpose , and does not constitute an invasion of privacy.</p>
            <p>
              <strong>Personal data categories we collect</strong>
            </p>
            <p>We may collect, use, store and transfer different kinds of personal data about you which we have &nbsp;&nbsp;&nbsp;categorised as follows:</p>
            <ul>
              <li>
                <strong>Individual details <br/>
                </strong>This includes name, address, previous addresses, other contact details (eg email and telephone &nbsp;&nbsp;&nbsp;&nbsp;numbers) date and place of birth, nationality, employer, job title and employment history, marital &nbsp;status
              </li>
            </ul>
            <ul>
              <li>
                <strong>Identification details <br/>
                </strong>Identification numbers and details issued by government bodies or agencies, such as your national insurance number, passport number, tax identification number and driving licence number
              </li>
            </ul>
            <ul>
              <li>
                <strong>Special Category Data</strong>&nbsp; <br/>This includes information about your health, and vulnerability status.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Claim details <br/>
                </strong>Information about you which is relevant to a matter on which we are advising you.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Financial Data <br/>
                </strong>This includes bank account, credit card, mortgage account and loan account numbers.&nbsp;
              </li>
            </ul>
            <ul>
              <li>
                <strong>Technical Data <br/>
                </strong>Information which can be traced back to you, such as an IP address and &nbsp;a website tracking code.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Compliance Data <br/>
                </strong>This includes the recording of calls for quality, training and compliance purposes and for the prevention of fraud. Information from external data bases is also retained for client due diligence checks, anti-fraud checks and for debt collecting.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Usage Data <br/>
                </strong>This includes information about how you use our website and services.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Marketing and Communications Data <br/>
                </strong>This includes your marketing and communication preferences.&nbsp;&nbsp;
              </li>
            </ul>
            <ul>
              <li>
                <strong>Aggregated Data <br/>
                </strong>This includes statistical or demographic data. Aggregated Data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity.
              </li>
            </ul>
            <p>
              <strong>Where do we collect your personal data from?</strong>
            </p>
            <p>We use various sources, including:</p>
            <ul>
              <li>
                <strong>From you <br/>
                </strong>Information that you have given us voluntarily over the phone, by email, text, post and by completing our on-line forms. This might be as a necessary part of our legal services, or when you give us feedback.
              </li>
            </ul>
            <ul>
              <li>
                <strong>From employers <br/>
                </strong>For example, references in relation to staff recruitment.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Third parties <br/>
                </strong>Correspondence from banks and other product providers, the Financial Ombudsman Service,&nbsp; debt collecting agents and bailiffs etc. Also,Technical Data from analytics providers such as Google.&nbsp;&nbsp;
              </li>
            </ul>
            <ul>
              <li>
                <strong>Anti-fraud databases, government agencies and publicly accessible registers <br/>
                </strong>These include GBG Connexus, Companies House.
              </li>
            </ul>
            <ul>
              <li>
                <strong>Call monitoring <br/>
                </strong>Recordings of phone calls with you.
              </li>
            </ul>
            <ul className="mb-5">
              <li>
                <strong>Collected automatically <br/>
                </strong>As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies, server logs and other similar technologies.
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>
              <strong>Why do we collect and use your personal data?</strong>
            </p>
            <ul>
              <li>Where it is necessary to perform the contract or contracts we have with you.</li>
              <li>Where it is necessary for the purposes of our legitimate interest.</li>
              <li>Where it is necessary to comply with a legal or regulatory obligation.</li>
              <li>Where we have obtained your consent.</li>
            </ul>
            <p>&nbsp;Where we rely on your consent to collect your personal data you have the right to withdraw your consent at any time. To withdraw your consent please email us at <a href="mailto:nick@moneysolicitor.com">info@moneysolicitor.com</a>&nbsp; <strong>&nbsp;</strong>
            </p>
            <p>
              <strong>Do we share your information?</strong>
            </p>
            <p>We may disclose your personal data to certain third parties, but only in the following limited circumstances:</p>
            <ul>
              <li>In the course of providing our legal services to you we have to send your personal data to your bank and other product providers, and we may have to send it to the Financial Ombudsman Service. In addition, we may require the assistance of external providers of professional services, such as barristers, accountants, translators. The use of these services might involve the service provider receiving your data from us.</li>
            </ul>
            <ul>
              <li>We use the services of third party service providers that process information on our behalf to help us fulfil our contractual obligations and operate our business efficiently, particularly in relation to IT systems and communications. Some of these services (such as email hosting and data back-ups) involve the service provider holding and using your personal data.</li>
            </ul>
            <ul>
              <li>To our professional advisers, including bankers, accountants and insurers, who provide their respective services to us.</li>
            </ul>
            <ul>
              <li>Where we are under a legal or regulatory obligation to disclose your personal data (e.g to HMRC, regulators or other authorities).</li>
            </ul>
            <ul>
              <li>Your data may also be reviewed in the course of a due diligence exercise relating to the sale or transfer of all or part of our business, the acquisition of another business or the acquisition of new business. If our business or assets are sold, transferred or merged to or with a third party, then your information will be transferred to the new owner who may use your personal data in the same way as set out in this privacy notice.</li>
            </ul>
            <ul>
              <li>Where you have contacted us in relation to a job vacancy, in which case, as part of our recruitment process we may contact third parties (including your referees, previous employers or educational establishments) to discuss the information you have provided to us.</li>
            </ul>
            <p>In each case where we share your information with one of our service providers, the service provider is required to keep it safe and secure. They are also not permitted to use your information for their own purposes.</p>
            <p>
              <strong>Keeping your information safe</strong>
            </p>
            <p>We take security issues seriously. We implement appropriate steps to help maintain the security of our information systems and processes and prevent the accidental destruction, loss or unauthorised disclosure of the personal information we process.</p>
            <p>
              <strong>Profiling and automated decision making &nbsp;</strong>
            </p>
            <p>&nbsp;We do not use profiling (where an electronic system uses personal data to try and predict something about you) or automated decision making (where an electronic system uses personal data to make a decision about you without human intervention).</p>
            <p>
              <strong>How long do we keep your personal data?</strong>
            </p>
            <p>We hold personal data for as long as is necessary to fulfil the purpose for which we collected such information (or for any compatible purpose), including for the purposes of satisfying any legal, accounting or regulatory requirement, and limitation periods for taking legal action.</p>
            <p>
              <strong>International Transfer of Data</strong>
            </p>
            <p>Sometimes it is necessary to transfer/process your personal data outside of the United Kingdom, and in these circumstances we will ensure that it is safeguarded by an approved adequacy mechanism.</p>
            <p>
              <strong>Your rights</strong>
            </p>
            <p>Under certain circumstances you have the following rights under data protection laws in relation to your personal information:</p>
            <ul>
              <li>Right of access to your personal information</li>
              <li>Right to rectification of your personal information</li>
              <li>Right to erasure of your personal information</li>
              <li>Right to restriction of processing of your personal information</li>
              <li>Right to portability of your personal information</li>
              <li>Right to object to processing of your personal information</li>
              <li>Right not to be subject to automated decision-making (including profiling)</li>
              <li>Right to withdraw consent to processing of your personal information</li>
            </ul>
            <p>To find out more about these rights, please visit the I.C.O’s website. If you wish to exercise any of these rights please contact info@moneysolicitor.com</p>
            <p>
              <strong>Your right to complain</strong>
            </p>
            <p>If you are not satisfied with our use of your personal information or our response to any request by you to exercise your rights, or if you think that we have breached any relevant data protection laws, then you have the right to complain to the authority that supervises our processing of your personal information, the Information Commissioner’s Office.</p>
            <p>Contact details are:</p>
            <ul>
              <li>Information Commissioner’s Office, Wycliff House, Water Lane, Wilmslow, Cheshire, SK9 5AF</li>
              <li>Tel: 0303 123 1113</li>
            </ul>
            <p>
              <strong>&nbsp;&nbsp;</strong>
            </p>
            <h3>Cookie Policy</h3>
            <p>
              <strong>What are cookies?</strong>
            </p>
            <p>A cookie is a small text file that is placed on your computer, tablet or smartphone by websites that you visit. They are widely used to make websites work, or work more efficiently, as well as to provide information to website owners.</p>
            <p>Only the areas of our site that you visit will be logged by the cookie. If you have not submitted any personal data to us via an on-line form then you can still browse our site anonymously. If you do not want a cookie you can disable non-essential cookies via your browser setting.</p>
            <p>
              <strong>What do we use cookies for? </strong>
            </p>
            <p>We use your data to ensure our services are tailored to your needs. We may also use your personal or website browsing data for statistical analysis to develop our web service and to utilise marketing tools such as remarketing.</p>
            <p>
              <strong>&nbsp;Type of cookies do we use? </strong>
            </p>
            <p>We use cookies to:</p>
            <ul>
              <li>to remember your browsing preferences when you visit our site so that we can give you a better experience.</li>
            </ul>
            <ul>
              <li>to collect <strong>anonymous</strong> statistical information about the way you use our website and its content so that we can improve the way the site works and the content we make available. </li>
            </ul>
            <ul>
              <li>for advertising and remarketing purposes using the following Google Analytics Advertising Features:</li>
            </ul>
            <ul>
              <li>Remarketing with Google Analytics</li>
            </ul>
            <ul>
              <li>Google Analytics Demographics and Interest Reporting</li>
            </ul>
            <ul>
              <li>Integrated services that require Google Analytics to collect data via advertising cookies and identifiers</li>
            </ul>
            <p>The use of Google Analytics Advertising Features enables Google Analytics to collect data via&nbsp; Google advertising cookies and identifiers, in addition to data collected through a standard Google Analytics implementation package.</p>
            <p>We use Google Analytics Advertising Features to collect information about visitor behaviour on our website and to show our adverts to users who have previously visited our site, as they browse other websites on the internet. Third party vendors, including Google, can show our adverts on sites across the internet, and will use cookies to serve adverts based on your prior visits to our website.</p>
            <p>
              <strong>Can I block or restrict the use of cookies on my device?</strong>
            </p>
            <p>
              <strong>&nbsp;YES, </strong>&nbsp;you can &nbsp;block or restrict the cookies which are set&nbsp; through your browser settings.
            </p>
            <ul>
              <li>The ‘Help’ function within your browser should tell you how.</li>
            </ul>
            <ul>
              <li>Alternatively, you may wish to visit <strong>
                  <a href="http://www.aboutcookies.org">aboutcookies.org</a>
                </strong> which contains comprehensive information on how you can do this on a wide variety of browsers. </li>
            </ul>
            <ul>
              <li>To opt out of being tracked by Google Analytics across all websites visit: <strong> &nbsp;</strong>
                <strong>
                  <u>
                    <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>
                  </u>
                </strong>
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>Please be aware that restricting cookies may impact on the functionality of the website and also your user experience.</p>
          </div>
            
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={hidepopup}>
                                Close
                            </Button>
                        </Modal.Footer>
                  
                </Modal>
            </div>
        </>
    )
}

export default Privacy;



