import React, { useEffect, useState } from "react";
import SelectBox from "../../../../UI/SelectBox";
import AnalyzeModal from "../AnalayzeModal";
import { ClosedYears2 } from "../../../../../Constants/Constants";
import Modal from "react-bootstrap/Modal";
import InputButton from "../../../../UI/InputButton";

const WhatYearHappen = ({
  clsName,
  reff,
  // checkCarRegistration,
  validation,
  validationMsg,
  trigger,
  getValues,
  setError,
  page_name,
  uuid,
  split_name,
  saveInputAns,
  splitData,
}) => {
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);

  const selectedYear = async (e) => {
    let errorFlag = 0;
    let yearresult = await trigger("whatYearThisHappen");

    if (!yearresult) {
      errorFlag = 1;
      return false;
    }
    const data = getValues();
    let yearSelected = data.whatYearThisHappen;
    if (yearSelected < 2019) {
      setshowClaimModal(true);
    } else {
      saveInputAns(e);
    }
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>
          <b>What year did this happen?</b>
        </h3>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
          <SelectBox
            className="form-select form-control"
            OptionValue={ClosedYears2}
            name="whatYearThisHappen"
            id="whatYearThisHappen"
            myRef={validation({
              required: "Please Select Year",
            })}
            validationMsg={
              validationMsg.whatYearThisHappen &&
              validationMsg.whatYearThisHappen.message
            }
          />
        </div>
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button
            type="button"
            className="btn-next"
            onClick={selectedYear}
            data-quest-id={72}
            data-ans-id={null}
            data-ans-label={null}
            name="btn_qest_72"
          >{`NEXT`}</button>
        </div>
      </div>

      <Modal
        size="md"
        show={claimModal}
        onHide={handleCloseclaimModal}
        centered
        backdrop="static"
        className="claimdeinial"
      >
        <Modal.Body>
          <h3 className="text-center">Sorry, we cannot accept your claim</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WhatYearHappen;
