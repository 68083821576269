import React from 'react';
import ProgressBar from './ProgressBar';
import TextField from '../../../UI/TextField';
import Button from '../../../UI/Button';

const Slide1 = ({
  className,
  slideChange,
  validation,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  setError,
}) => {
  const nextSlide = async (e) => {
    var carRegNo = await trigger("carRegNo");
    if (carRegNo) {
      slideChange(e);
    }
  }
  return (
    <>
      <div id="slide1" className={`col-12 top_minus_1 ${className}`}>
        <ProgressBar value={0} />
        <h3>Start your FREE Unaffordability Check by Entering your Registration Now</h3>


        <div className="offset-lg-1 col-lg-10 col-md-12 col-sm-12 col-12 p-0">
          <span className="gb" >
            <img src="/assets/MB_UBRF_2/img/gb.png" alt="" width="16px" />
            <p>GB</p>
          </span>
          <TextField
            type="text"
            className="car-reg"
            placeholder="e.g.ME12DAR"
            aria-label="Username"
            name="carRegNo"
            id="carRegNo"
            validation={validation({
              required: "Please Enter Registration",
              minLength: {
                value: 3,
                message: "Please Enter A Valid Registration",
              }
            })}
            validationMsg={validationMsg?.carRegNo?.message}
          />
        </div>
        <div className="offset-lg-1 col-lg-10 mb-pd text-center ">
          <input
            type="button"
            className="btn next01  regNextBtn"
            onClick={nextSlide}
            name="carRegNo"
            value="Start My Free Check >>"
          />
        </div>
        <div className="w-100 text-center pb-3">
          <a href="#">SKIP if not handy</a>
        </div>
      </div>
    </>
  );
};

export default Slide1;