import {useState} from 'react';
import {Api} from "../api/Api";
//import {Api,ApiFile} from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const useFollowupIdUpload = () => {
    const [isLoading,setIsLoading] = useState(false);
    const { ErrorWebhookTrigger } = useErrorWebhook();

    const saveFollowupIdUpload = async (uploadData,followup_data,message_type='followup_user_docs_store') => {
        setIsLoading(true);
        const userDocumentSubmit = await Api.post("api/v1/user_documents", {
            message_type,
            upload_data:uploadData,
            followup_data:followup_data
        }).catch(function (error) {
            const erroMessage = {
              serviceName : 'FOLLOWUP ID UPLOAD',
              errorReason:error.message,
              errorCode:error.code,
            }
            ErrorWebhookTrigger(erroMessage);
          });
        
        setIsLoading(false);
        return userDocumentSubmit;
    }
    return { 
        isLoading,
        saveFollowupIdUpload
    }
}

