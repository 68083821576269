import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import SelectBox from "../../../UI/SelectBox";
// import { PossessionYear } from "../../../../Constants/Constants";
import { PossessionMonth,YearPossession } from "../../../../Constants/Constants";
import InputButton from "../../../UI/InputButton";
import RadioButton from "../../../UI/RadioButton";

const YearOfPossession = ({
  className,
  slideChange,
  textHeadingLine1,
  validationMsg,
  clearErrors,
  validation,
  backClick,
  trigger,
  getValues,
  Year,
}) => {
  // const [agreeModalShow, setAgreeModalShow] = useState("hide");
  const formData = getValues();
  let yearSelected = formData.YearOfPossession;
  const nextSlide = async (e) => {
    let errorFlag = 0;
    let yearresult = await trigger("YearOfPossession");
    let monthresult = await trigger("MonthOfPossession");

    if (!yearresult || !monthresult) {
      return false;
    }
    // if (yearSelected < 2017) {
    //   setAgreeModalShow('show')
    // } else {
    slideChange(e);
    // }
  };

  // const AgreeSlide = async (e) => {
  //   slideChange(e);
  //   setAgreeModalShow('hide')
  // };

  // const PossessionYear = [{ label: "Select Year", value: "" }];
  // {
  //   const startofYear = 2023;
  //   const endofYear = Year;
  //   for (var k = startofYear; k >= endofYear; k--) {
  //     var objYearOfPossession = {};
  //     objYearOfPossession["label"] = k;
  //     objYearOfPossession["value"] = k;
  //     PossessionYear.push(objYearOfPossession);
  //   }
  // }

  return (
    <>
      <div id="slide-3" className={`${className}`}>
        <ProgressBar value="40" />

        <h3> {textHeadingLine1} </h3>
        <div className="input-section inX6gs_update mb-3">
          <div className="row">
            <div className="col-lg-6">
              <div className="mb-3  col-12 p-0">
                <SelectBox
                  className="form-select form-control"
                  OptionValue={PossessionMonth}
                  name="MonthOfPossession"
                  id="MonthOfPossession"
                  onChange={() => clearErrors("MonthOfPossession")}
                  clearErrors={clearErrors}
                  myRef={validation({ required: "Please Select Month" })}
                  validationMsg={
                    validationMsg.MonthOfPossession &&
                    validationMsg.MonthOfPossession.message
                  }
                ></SelectBox>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-3  col-12 p-0">
                <SelectBox
                  className="form-select form-control"
                  OptionValue={YearPossession}
                  name="YearOfPossession"
                  id="YearOfPossession"
                  onChange={() => clearErrors("YearOfPossession")}
                  clearErrors={clearErrors}
                  myRef={validation({ required: "Please Select Year" })}
                  validationMsg={
                    validationMsg.YearOfPossession &&
                    validationMsg.YearOfPossession.message
                  }
                ></SelectBox>
              </div>
            </div>
          </div>
        </div>

        <InputButton
          name="yearposession"
          className="btn next08  regNextBtn"
          id=""
          value="Next"
          btnType="button"
          onClick={nextSlide}
          style={{ float: "none" }}
        />

        <div>
          <p className="text-center">
            <a
              className="back1 back-btn btn-link"
              name="prevKeep"
              children="<< Previous"
              onClick={backClick}
            ></a>
          </p>
        </div>
      </div>
    </>
  );
};
export default YearOfPossession;
