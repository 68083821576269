import React from "react";

const Loader3 = ({ showLoad,fname }) => { 
  return (
    <>


       {/* <div className="text-center loader-wrap loader1"> */}
       <div className={`text-center loader-wrap loader1 ${showLoad}`}>
            <div className="loader-logo">
              <img
                src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
                alt=""
              />
            </div>

            <div className="loader2">
              <img src="assets/img/review.gif" alt="" />
              <div className="loader-text">
                {/* <p className=" text-analys">{fname}, Analysing your Results</p> */}
                <p className=" text-analys"> Analysing your Results</p>
              </div>
            </div>
          </div>
    </>
  );
};

export default Loader3;