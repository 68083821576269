import react, { useState } from "react";
import { MonthlyIncome } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
const CardMonthlyIncome = ({
  clsName,
  onSelectAnswer,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  backClick,
}) => {
  const [pulse, setPulse] = useState(true);
  const optionSelected = async (e) => {
    let errorFlag = 0;
    let result = await trigger("cardMonthlyIncome");

    if (!result) {
      errorFlag = 1;
      return false;
    } else {
      onSelectAnswer(e);
    }
  };
  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
        <div className="row">
          <div className="col-lg-8 mb-2">
            <SelectBox
              className={pulse == true ? 'form-select pulse3' : 'form-select'}
              OptionValue={MonthlyIncome}
              name="cardMonthlyIncome"
              id="cardMonthlyIncome"
              onChange={() => { setPulse(false); clearErrors("cardMonthlyIncome") }}
              clearErrors={clearErrors}
              myRef={validation({
                required: "Please select your monthly income",
              })}
              validationMsg={
                validationMsg.cardMonthlyIncome &&
                validationMsg.cardMonthlyIncome.message
              }
            ></SelectBox>
          </div>
          <div className="col-lg-4 mb-2 ">
            <button
              type="button"
              className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
              id="nextscroll"
              name="cardMonthlyIncome"
              onClick={optionSelected}
              data-quest-id={78}
            >
              Next &#8594;
            </button>
          </div>
        </div>
        <p className="text-center bck-marwid">
          <button
            name="didReceiveIncome"
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}
          >
            Go Back
          </button>
        </p>
      </div>
    </>
  );
};
export default CardMonthlyIncome;
