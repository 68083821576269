import React from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP.js";
import FormNDY_UB_4_2 from "../../Forms/FormNDY_UB_4_2.js";
import Footer from "../../Includes/Layouts/NDY_UB_4/Footer.js";
import FbViewPixel from "../../../Utility/FbViewPixel.js";
import "../../../assets/NDY_UB_4_2/scss/NDY_UB_4_2.scss";
import AccordSection from "../../Includes/Layouts/NDY_UB_4/AccordSection.js";

const NDY_UB_4_2 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="NDY_UB_4_2" />
      <FbViewPixel />
      <div className="NDY_UB_4_2">
        <FormNDY_UB_4_2 />
        <AccordSection />
        <Footer />
      </div>
    </>
  );
};

export default NDY_UB_4_2;
