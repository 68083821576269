import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import InputButton from "../../../UI/InputButton";
const Arrears = ({
  className,
  slideChange,
  textHeadingLine1,
  validation,
  backClick,
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);
  const nextSlide = async (e) => {
    if (e.target.value === "113") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      //setshowClaimModal(true);
      slideChange(e);
    } else {
      setCheckedQuestionOne("unchecked");
    }
    if (e.target.value === "114") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      slideChange(e);
    } else {
      setCheckedQuestionTwo("unchecked");
    }
  };
  return (
    <>
      <div id="slide-4" className={`${className}`}>
        <ProgressBar value="25" />
        <h3> {textHeadingLine1} </h3>
        <RadioButton
          value="114"
          name="question_40"
          className="radio-button"
          labelName="No"
          labelClassName={`option_but next3 ${checkedQuestionTwo}`}
          onClick={nextSlide}
          validation={validation()}
        />
        <RadioButton
          value="113"
          name="question_40"
          className="radio-button"
          labelName="Yes"
          labelClassName={`option_but next3 ${checkedQuestionOne}`}
          onClick={nextSlide}
          validation={validation()}
        />
        <p className="text-center">
          <span
            className="back2 back-btn btn-link"
            name="back04"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div>
      {/* **** cannot accept your claim popup start **** */}
      <Modal size="md" show={claimModal} onHide={handleCloseclaimModal} className="claimdeinial" centered backdrop="static">
        <Modal.Body>
          <h3 className="text-center fonts15">Sorry, we cannot accept your claim</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="back04"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Arrears;