import React from "react";
import "../../../assets/MB_UBRF_2/css/MB_UBRF_2.scss";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FbViewPixel from "../../../Utility/FbViewPixel";
import FormMB_UBRF_2 from "../../Forms/FormMB_UBRF_2";
import AccordSection from "../../Includes/Layouts/Common/AccordSection";
import Footer from "../../Includes/Layouts/Common/Footer";
import Header from "../../Includes/Layouts/MB_UBRF_2/Header";
import HowItWorks from "../../Includes/Layouts/MB_UBRF_2/HowItWorks";
import Missold from "../../Includes/Layouts/MB_UBRF_2/Missold";

const MB_UBRF_2 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="MB_UBRF_2" />
      <FbViewPixel />
      <div className="MB_UBRF_2">
        <div className="tmsversion02_2022">
          <Header />
          <section className="form-part" id="firstform">
            <div className="container nopad">
              <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 nopad">
                <FormMB_UBRF_2 />
              </div>
            </div>
          </section>
          <HowItWorks />
          <AccordSection />
          <Footer />
        </div>
      </div>
    </>
  )
}
export default MB_UBRF_2;