const OB_SplashV2 = ({
  obSplashStat,
  btndisable,
  keepMyFileLinkOne,
  loader,
  userDetails
}) => {

  return (
    <>
      <div className={`text-center tms-wrap  bg-main-pr loader-wrap loader1 ${loader === 1 ? 'showload' : 'hide'}`}>
        <div className="loader-logo">
          <img src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt="" />
        </div>
        <div className="loader">
          <img src="assets/img/review.gif" alt="" />
          <div className="loader-text">
            <p className=" text-analys">
              Analysing...</p>
          </div>
        </div>
      </div>
      <div className={`tms-wrap ${obSplashStat}`}>
        <div className={`container ${loader == 0 ? 'showload' : 'hide'}`}>
          <div className="row">
            <div className="col-12 showcase">
              <div className="video-container">
                <video src="../../assets/img/production_id_4393393_(1080p).mp4" autoPlay muted loop></video>
              </div>
              <div className="content">
                <h1 className=""> {userDetails.txtFName} , help your {userDetails.lender} , responsible lending claim by providing bank statements and a credit file. These documents will really help your case. </h1>
                <div className="row">
                  <div className="col-lg-10 offset-lg-1">
                    <h3 className="mb-lg-5 mb-md-5 mb-sm-4 mb-4">  To assist in safely securing supporting evidence we have partnered with leading financial technology companies to make the process quick, easy and free!</h3>
                  </div>
                </div>
                <div className="px-4">
                  <div className="row">
                    <div className="col-lg-8 offset-lg-2 px-lg-0 px-md-0 px-sm-0 ">
                      <div className="row">
                        <div className="col-lg-10 col-md-10 offset-lg-1 offset-md-1    text-center mb-3">
                          <div>
                            <button
                              id="KeepOpen1"
                              className="com-btn w-100"
                              onClick={keepMyFileLinkOne}
                              disabled={btndisable}
                              data-btn-label="keep_my_file_open"
                              data-page-label="OB_SPLASH"
                            >
                              Click Here to provide documents <i className="bi bi-arrow-right ms-3"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OB_SplashV2;
