import React, { useContext, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Salutation } from "../../../../Constants/Questions";
import { DateOfBirth } from "../../../../Utility/DateOfBirth";
import ProgressBar from "../../Layouts/Common/ProgressBar";
import SelectBox from "../../../UI/SelectBox";
import TextField from "../../../UI/TextField";
import InputButton from "../../../UI/InputButton";
import Anchor from "../../../UI/Anchor";

const PersonalDetails = ({
  className,
  slideChange,
  validation,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  slideChangeAddress,
  splitForm,
  backClick,
  setError,
  setPreviousName,
  lender
}) => {

  const [preModal, setshowPrevModal] = useState(false);
  const handleShowFirst = () => setshowPrevModal(true);
  const handleClosePreModal = () => setshowPrevModal(false);
  const [showNextModal, showsetNextModal] = useState(false);
  const handleShowNext = () => {
    showsetNextModal(true);
    setshowPrevModal(false);
  };
  const handleClose = () => showsetNextModal(false);
  const form = splitForm.current;
  const question_3 = getValues();
  let bank = localStorage.getItem("bankName");

  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    var lstSalutationResult = await trigger("lstSalutation");

    var salutation = question_3.lstSalutation;
    var txtFNameResult;
    var txtLNameResult;
    var lstDobDayResult;
    var lstDobMonthResult;
    var lstDobYearResult;
    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      lstDobDayResult = await trigger("DobDay");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }
    if (lstDobYearResult) {
    } else {
      document.getElementById("DobYear").focus();
    }

    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      if (salutation == "Mrs" || salutation == "Ms" || salutation == "Miss" || salutation == "Dame" || salutation == "Lady") {
        setshowPrevModal(true);
      } else {
        slideChange(e);
      }
      // slideChange(e);

    } else {
      return false;
    }
  };

  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is Invalid. Please Recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };

  const prevNameValidate = async (e) => {
    let errorFlag = 0;
    var prevnameResult = await trigger("txtPrevName");
    if (prevnameResult) {
    } else {
      document.getElementById("txtPrevName").focus();
    }
    if (!prevnameResult) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      handleClose();
      slideChange(e);
    } else {
      return false;
    }
  };

  const closePrevPopup = () => {
    showsetNextModal(false);
    setshowPrevModal(false);
  };

  const noPreviousName = async (e) => {
    setshowPrevModal(false);
    slideChange(e);
  };

  return (
    <div id="slide-9" className={`${className}`}>
      <ProgressBar value="75" />
      <h3><b>Please enter your FULL NAME {"&"} DATE OF BIRTH{" "}</b></h3>
      <div className="mb-3 mt-3 col-12 p-0">
        <SelectBox
          className="form-select form-control"
          OptionValue={Salutation}
          name="lstSalutation"
          id="lstSalutation"
          onChange={() => clearErrors("lstSalutation")}
          clearErrors={clearErrors}
          myRef={validation({ required: "Please Select Title" })}
          validationMsg={
            validationMsg.lstSalutation && validationMsg.lstSalutation.message
          }
        ></SelectBox>
        <div className="col-12 mb-3 mt-3 p-0">
          <div className="input-group ">
            <TextField
              type="text"
              className="form-control"
              placeholder="First Name"
              name="txtFName"
              id="txtFName"
              onBlur={namevalidate}
              validation={validation({
                required: "Please Enter First Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid First Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}
            ></TextField>
            <div className="input-group-append">
              <span className="input-group-text">
                <img src="/assets/LS_UB_1/img/privacy.png" alt="" />
              </span>
            </div>
          </div>
          {validationMsg.txtFName && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtFName.message}
            </span>
          )}
        </div>
        <div className="col-12 mb-3 mt-3 p-0">
          <div className="input-group mb-3">
            <TextField
              type="text"
              className="form-control"
              placeholder="Last Name"
              name="txtLName"
              id="txtLName"
              onBlur={namevalidate}
              validation={validation({
                required: "Please Enter Last Name",
                minLength: {
                  value: 3,
                  message: "Please Enter Valid Last Name",
                },
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Name is Invalid. Please Recheck",
                },
              })}
            ></TextField>
            <div className="input-group-append">
              <span className="input-group-text">
                <img src="/assets/LS_UB_1/img/privacy.png" alt="" />
              </span>
            </div>
          </div>
          {validationMsg.txtLName && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtLName.message}
            </span>
          )}
        </div>

        <DateOfBirth
          textDob="Date Of Birth"
          validation={validation}
          validationMsg={validationMsg}
        />

        <InputButton
          name="personalvalidate"
          className="btn next08 regNextBtn"
          id=""
          value="Next"
          btnType="button"
          onClick={basicDetailsValidation}
          style={{ float: "none" }}
        />
      </div>
      <p className="text-center">
        <span
          className="back5 back-btn btn-link"
          name="back09"
          children="<< Previous"
          onClick={backClick}
        ></span>
      </p>

      {/* **** PREVIOUS NAME POPUP START **** */}
      <Modal size="md" show={preModal} onHide={handleClosePreModal} centered backdrop="static" className="modaloutersec">
        <Modal.Body>
          <h5 className="text-center mb-3">Was your <strong>surname</strong> different when you took out the credit with Vanquis</h5>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="no_prev_name"
              value="No"
              btnType="button"
              onClick={noPreviousName}
            />

            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="yes"
              value="Yes"
              btnType="button"
              onClick={handleShowNext}
            />
          </div>
        </Modal.Body>
      </Modal>

      {/* *****second modal*** */}
      <Modal size="md" show={showNextModal} onHide={handleClose} centered backdrop="static" className="modaloutersec">
        <Modal.Body>
          <h5 className="text-center mb-3">Enter your previous surname</h5>
          <div className="input-group mb-3 prevname-outer">
            <TextField
              type="text"
              className="form-control"
              placeholder="Previous Name"
              name="txtPrevName"
              id="txtPrevName"
              onChange={(event) => setPreviousName(event.target.value)}
              validation={validation({
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Previous Name is Invalid. Please Recheck",
                },
              })}
              validationMsg={
                validationMsg.txtPrevName && validationMsg.txtPrevName.message
              }
            />
          </div>
          <div className="form-group col-12">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="personal"
              value="Next"
              btnType="button"
              onClick={prevNameValidate}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default PersonalDetails;
