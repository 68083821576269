import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import InputButton from "../../../../UI/InputButton";
import Modal from "react-bootstrap/Modal";

const Struggle = ({ onselectAnswer, reff, display, trigger,lender }) => {
  const labelclicked = async (e) => {
    if (e.target.value === "100") {
      setshowClaimModal(true);
    }
    if (e.target.value === "99") {
      onselectAnswer(e);
    }
  };
  const [claimModalfilled, setshowClaimModal] = useState(false);

  const handleCloseclaimModal = (e) => {
    setshowClaimModal(false);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>
            Have you experienced any hardships or struggle in making the repayments to
            {""}  {lender}  on time while still meeting all your other commitments?
          </h3>
          <RadioButtonQuest
            key="Yes"
            className="radio-button"
            labelName="Yes, I Have Struggled to Repay"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="99"
            name="question_29"
            id="ya"
            dataQuestId={29}
            dataAnsId={99}
            dataAnsLabel="Yes, I Have Struggled to Repay"
          />
          <RadioButtonQuest
            key="No"
            className="radio-button"
            labelName="No Issues Repaying"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="100"
            name="question_29"
            id="xss"
            dataQuestId={29}
            dataAnsId={100}
            dataAnsLabel="No Issues Repaying"
          />
        </div>
      </div>
      <Modal
        size="md"
        show={claimModalfilled}
        onHide={handleCloseclaimModal}
        centered
        backdrop="static"
        className="claimdeinial"
      >
        <Modal.Body>
          <h3 className="text-center">Sorry, we cannot accept your claim</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Struggle;
