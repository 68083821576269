import { AdtopiaApi } from "../api/AdtopiaApi";
import {useErrorWebhook} from "./useErrorWebhook";


export const useRedirection = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();

  const getRedirectionUrl = async (asset_id, upid) => {
    const response = await AdtopiaApi.post("asset", {
      asset_id,
      upid,
    }).catch(function (error) {
      const erroMessage = {
        serviceName : 'REDIRECTION',
        errorReason:error.message,
        errorCode:error.code
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };
  return { getRedirectionUrl };
};
