import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const EmploymentStatus = ({
  className,
  slideChange,
  textHeadingLine1,
  textHeadingLine2,
  validation,
  backClick,
  getValues,
  lender,

}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const [checkedFour, setcheckedFour] = useState("unchecked");
  const [checkedFive, setcheckedFive] = useState("unchecked");
  const [checkedSix, setcheckedSix] = useState("unchecked");
  const [checkedSeven, setcheckedSeven] = useState("unchecked");
  const [checkedEight, setcheckedEight] = useState("unchecked");
  const [checkedNine, setcheckedNine] = useState("unchecked");
  const [checkedTen, setcheckedTen] = useState("unchecked");
  const [checkedEleven, setcheckedEleven] = useState("unchecked");

  const formData = getValues();

  const nextSlide = async (e) => {
    if (e.target.value === "3") {
      setCheckedOne("checked");
      slideChange(e);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "199") {
      setcheckedFive("checked");
      slideChange(e);
    } else {
      setcheckedFive("unchecked");
    }

    if (e.target.value === "4") {
      setCheckedTwo("checked");
      slideChange(e);
    } else {
      setCheckedTwo("unchecked");
    }

    if (e.target.value === "5") {
      setCheckedThree("checked");
      slideChange(e);
    } else {
      setCheckedThree("unchecked");
    }

    if (e.target.value === "6") {
      setcheckedFour("checked");
      slideChange(e);
    } else {
      setcheckedFour("unchecked");
    }

    if (e.target.value === "200") {
      setcheckedSix("checked");
      slideChange(e);
    } else {
      setcheckedSix("unchecked");
    }

    if (e.target.value === "201") {
      setcheckedSeven("checked");
      slideChange(e);
    } else {
      setcheckedSeven("unchecked");
    }

    if (e.target.value === "202") {
      setcheckedEight("checked");
      slideChange(e);
    } else {
      setcheckedEight("unchecked");
    }

    if (e.target.value === "203") {
      setcheckedNine("checked");
      slideChange(e);
    } else {
      setcheckedNine("unchecked");
    }

    if (e.target.value === "204") {
      setcheckedTen("checked");
      slideChange(e);
    } else {
      setcheckedTen("unchecked");
    }

    if (e.target.value === "205") {
      setcheckedEleven("checked");
      slideChange(e);
    } else {
      setcheckedEleven("unchecked");
    }

  };


  return (
    <>
      <div id="slide-4" className={`${className}`}>
        <ProgressBar value="25" />

        <h3> {textHeadingLine1} </h3>
        <div className="radio_buttons">
          <div className="btn-comm">
            <RadioButton
              value="3"
              name="employment"
              className="radio-button"
              labelName="Employed"
              labelClassName={`option_but radio ${checkedOne}`}
              onClick={nextSlide}
              validation={validation()}
            />
            <RadioButton
              value="199"
              name="employment"
              className="radio-button"
              labelName="Unemployed"
              labelClassName={`option_but radio ${checkedFive}`}
              onClick={nextSlide}
              validation={validation()}
            />

          </div>
          <div className="btn-comm">
            <RadioButton
              value="4"
              name="employment"
              className="radio-button"
              labelName="Self-employed"
              labelClassName={`option_but radio ${checkedTwo}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <div className="btn-comm">
            <RadioButton
              value="5"
              name="employment"
              className="radio-button"
              labelName="Student"
              labelClassName={`option_but radio ${checkedThree}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <div className="btn-comm">
            <RadioButton
              value="6"
              name="employment"
              className="radio-button"
              labelName="Retired"
              labelClassName={`option_but radio ${checkedFour}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <div className="btn-comm">
            <RadioButton
              value="200"
              name="employment"
              className="radio-button"
              labelName="Benefits main source of income"
              labelClassName={`option_but radio ${checkedSix}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <div className="btn-comm">
            <RadioButton
              value="201"
              name="employment"
              className="radio-button"
              labelName="Part-time job"
              labelClassName={`option_but radio ${checkedSeven}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <div className="btn-comm">
            <RadioButton
              value="202"
              name="employment"
              className="radio-button"
              labelName="Temporary worker"
              labelClassName={`option_but radio ${checkedEight}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <div className="btn-comm">
            <RadioButton
              value="203"
              name="employment"
              className="radio-button"
              labelName="Long Term Sick"
              labelClassName={`option_but radio ${checkedNine}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <div className="btn-comm">
            <RadioButton
              value="204"
              name="employment"
              className="radio-button"
              labelName="Maternity"
              labelClassName={`option_but radio ${checkedTen}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
          <div className="btn-comm">
            <RadioButton
              value="205"
              name="employment"
              className="radio-button"
              labelName="Rolling/Zero hours contract"
              labelClassName={`option_but radio ${checkedEleven}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
        </div>
        <p className="text-center">
          <span
            className="back-btn btn-link"
            name="back04"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div>
    </>
  );
};
export default EmploymentStatus;
