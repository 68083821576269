import React from 'react'
import TextField from '../../../UI/TextField';
import Telephone from "../../../UI/Telephone";

const CheckboxWithInput = ({
  id,
  label,
  isChecked,
  placeholder,
  inputValue,
  error,
  errorMsg,
  handleCheckboxChange,
  handleInputChange,
}) => {

  const handleChange = (e, id) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      var value =
        "£" + e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      e.target.value = value;
    }
    handleInputChange(id, value);
  };

  return (
    <>
      <div className="optionbtn ">
        <input
          type="checkbox"
          id={`checkbox-${id}`}
          checked={isChecked}
          onChange={() => handleCheckboxChange(id)}
        />
        <label className="" for={`checkbox-${id}`}>
          {label}
        </label>

      </div>
      {isChecked && (
        <>
          <div className="input-group mb-3 mt-2">
            <span className="input-group-text whitespaceRemove input-mobile">
              {placeholder}
            </span>
            {/* <TextField
              type="text"
              className="form-control cursor-placeholder un-hide-input"
              placeholder="£"
              name={`input_${id}`}
              value={inputValue}
              onChange={(e) => handleChange(e, id)}
            /> */}
            <Telephone
              className="form-control cursor-placeholder un-hide-input"
              placeholder="£"
              name={`input_${id}`}
              value={inputValue}
              onChange={(e) => handleChange(e, id)}
            />
            {error && (
              <span className="error_msg mb-0">{errorMsg}</span>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default CheckboxWithInput