import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const EmploymentStatus = ({
  className,
  slideChange,
  textHeadingLine1,
  textHeadingLine2,
  validation,
  backClick,
  getValues,
  lender
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const [checkedFour, setcheckedFour] = useState("unchecked");
  const [checkedFive, setcheckedFive] = useState("unchecked");

  const formData = getValues();

  let yearSelected = formData.YearOfPossession;

  const nextSlide = async (e) => {
    if (e.target.value === "3") {
      setCheckedOne("checked");
      slideChange(e);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "4") {
      setCheckedTwo("checked");
      slideChange(e);
    } else {
      setCheckedTwo("unchecked");
    }

    if (e.target.value === "5") {
      setCheckedThree("checked");
      slideChange(e);
    } else {
      setCheckedThree("unchecked");
    }

    if (e.target.value === "6") {
      setcheckedFour("checked");
      slideChange(e);
    } else {
      setcheckedFour("unchecked");
    }

    if (e.target.value === "199") {
      setcheckedFive("checked");
      slideChange(e);
    } else {
      setcheckedFive("unchecked");
    }
  };
  return (
    <>
      <div id="slide-4" className={`${className}`}>
        <ProgressBar value="25" />

        {/* <h3> {textHeadingLine1} </h3> */}
        <h3> {`In ${yearSelected}, at the time you took out the account with Vanquis, what was your employment status?`} </h3>
        <div className="radio_buttons">
        <div className="btn-comm">
          <RadioButton
            value="3"
            name="employment"
            className="radio-button"
            labelName="Employed"
            labelClassName={`option_but radio ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
          <div className="btn-comm">
          <RadioButton
            value="199"
            name="employment"
            className="radio-button"
            labelName="Unemployed"
            labelClassName={`option_but radio ${checkedFive}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
          <div className="btn-comm">
          <RadioButton
            value="4"
            name="employment"
            className="radio-button"
            labelName="Self-employed"
            labelClassName={`option_but radio ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
          <div className="btn-comm">
          <RadioButton
            value="5"
            name="employment"
            className="radio-button"
            labelName="Student"
            labelClassName={`option_but radio ${checkedThree}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
          <div className="btn-comm">
          <RadioButton
            value="6"
            name="employment"
            className="radio-button"
            labelName="Retired"
            labelClassName={`option_but radio ${checkedFour}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
        </div>
        <p className="text-center">
          <span
            className="back-btn btn-link"
            name="back04"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div>
    </>
  );
};
export default EmploymentStatus;
