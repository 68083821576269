import React, { useState } from "react";
import "../../../assets/css/ADV_1.scss";
import AdvHeader from "../../Includes/Layouts/ADV_1/AdvHeader";
import AdvFooter from "../../Includes/Layouts/ADV_1/AdvFooter";
import AnchorAdv from "../../UI/AnchorAdv";
import AdtopiaLoadAP from "../../../Utility/AdtopiaLoadAP";
import { Months } from "../../../Constants/Constants";
import AdvBanner from "../../Includes/Layouts/ADV_1/AdvBanner"
import {BannerYearAdv1} from "../../../Constants/Constants"

const ADV_1 = () => {
  var now = new Date();
  const year = now.getFullYear();
  const date = now.getDate();
  const monthIndex = now.getMonth();
  const monthName = Months[monthIndex];
  const formatted = ` ${monthName} ${date}, ${year}`;
  return (
    <>
      <AdtopiaLoadAP pageType="AP" splitName="ADV_1" />
      <div className="ADV_1">
        <AdvHeader />
        <section className="fs-">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <h1 className="display-5 fw-bold py-3">
                  Neque porro quisquam est qui dolorem ipsum quia dolor sit amet
                </h1>
                <AnchorAdv href="#" dataId="PARA_IMAGE_1">
                <img
                    src="/assets/img/banner_1.jpg"
                    alt=""
                    className="img-fluid"
                  />
                </AnchorAdv>
                <p className="mt-3">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,{" "}
                  <AnchorAdv href="#" dataId="PARA_IMAGE_1">when an unknown printer took a galley of type
                  </AnchorAdv>
                </p>
                <p>
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  <AnchorAdv href="#" dataId="PARA_IMAGE_1">Where does it come from?</AnchorAdv> aContrary to popular
                  belief, Lorem Ipsum is not simply random text. It has roots in
                  a piece of classical.*
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
             <AdvBanner bannerYears ={BannerYearAdv1} textHead="IT IS A LONG ESTABLISHED FACT THAT A READER WILL BE DISTRACTED"/>
              <div className="col-xl-12 col-lg-12">
                <p>
                  {" "}
                  Want to protect your loved ones from bearing this burden?{" "}
                </p>
                <p>
                  {" "}
                  Lorem Ipsum has been the industry's standarda{" "}
                  <strong>
                    <u>
                      <AnchorAdv href="#" dataId="PARA_IMAGE_1">Lorem Ipsum
                      </AnchorAdv>
                    </u>
                  </strong>
                  .
                </p>
                <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour,*
                </p>
                <p>
                  Andomised words which don't look even slightly believable. If
                  you are going to use a passage of Lorem Ipsum, you need to be
                  sure there isn't anything embarrassing hidden in the middle of
                  text*
                </p>
                <p>
                  <b>Lorem Ipsum has been the industry's standard</b>
                </p>
                <ul>
                  <li>
                    There are many variations of passages of Lorem Ipsum
                    available, but the
                  </li>
                  <li>Lorem Ipsum has been the industry's standard</li>
                  <li>
                    Andomised words which don't look even slightly believable.
                  </li>
                  <li>
                    Andomised words which don't look even slightly believable.
                  </li>
                </ul>
                <p>
                  All the Lorem Ipsum generators on the{" "}
                  <b>
                    <u>INTERNET</u>
                  </b>{" "}
                  Internet tend to repeat predefined chunks as necessary, making
                  this the first true generator on the Internet Write-Off
                  Estimate.
                </p>
                <p>
                  Contrary to popular belief, Lorem Ipsum is not simply random
                  text
                </p>
                <p>
                  There are many variations of passages of Lorem Ipsum available
                  you NEED to take this <u>301 demo survey</u> There are many
                  variations of passages of Lorem Ipsum available
                </p>
                <p className="text-center">
                  <AnchorAdv className="btn btn-danger gt_btn1 py-3" href="#" dataId="PARA_IMAGE_1">Lorem Ipsum is simply dummy
                  </AnchorAdv>
                </p>
              </div>
            </div>
          </div>
        </section>
        <AdvFooter/>
      </div>
    </>
  );
};

export default ADV_1;
