import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import AnalyzeModal from "../../Layouts/Split_1/AnalayzeModal";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputButton from "../../../UI/InputButton";
const Bankruptcy = ({
  className,
  slideChange,
  textHeadingLine1,
  textHeadingLine2,
  validation,
  backClick,
}) => {
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);

  // const nextSlide = async (e) => {
  //   if (e.target.value === "") {
  //     setCheckedOne("checked");
  //     setCheckedTwo("unchecked");
  //     setshowClaimModal(true);
  //     return false;
  //     //slideChange(e);
  //   } else {
  //     setCheckedOne("unchecked");
  //   }

  //   handleShowFirst();
  //   setTimeout(() => {
  //     handleCloseFirst();
  //     handleShowSecond();
  //   }, 3000);
  //   setTimeout(() => {
  //     handleCloseSecond();
  //     if (e.target.value === "22") {
  //       setCheckedTwo("checked");
  //       slideChange(e);
  //     } else {
  //       setCheckedTwo("unchecked");
  //     }
  //     // slideChange(e);
  //   }, 6000);
  // };
  return (
    <>
      {/* <div id="slide-9" className={`${className}`}>
        <ProgressBar value="70" />

        <h3> {textHeadingLine1} </h3>
        <h4> {textHeadingLine2} </h4>
        <div className="radio_buttons">
          <RadioButton
            value="22"
            name="bankruptcy"
            className="radio regNextBtn"
            labelName="No"
            labelClassName={`radio ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <RadioButton
            value="21"
            name="bankruptcy"
            className="radio regNextBtn"
            labelName="Yes"
            labelClassName={`radio ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <p className="text-center">
          <span
            className="back-btn btn btn-link"
            name="back07"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div> */}
      <div className="d-flex align-items-start check-field">
        <input
          type="checkbox"
          className="me-1 mt-1"
          defaultChecked
          id="bankruptcy"
          value="yes"
        />
        <label htmlFor="bankruptcy">
          {" "}
          I confirm that I am not entitled for bankruptcy and have not filed any
          claims in the past.
        </label>
      </div>

      {/* **** cannot accept your claim popup start **** */}
      <Modal
        size="md"
        show={claimModal}
        onHide={handleCloseclaimModal}
        centered
        backdrop="static"
        className="claimdeinial"
      >
        <Modal.Body>
          <h3 className="text-center">Sorry, we cannot accept your claim</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
     
    </>
  );
};
export default Bankruptcy;
