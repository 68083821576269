import React, { useContext, useState } from "react";
import { Salutation } from "../../../../Constants/Questions";
import TextField from "../../../UI/TextField";
import { DateOfBirth } from "../../../../Utility/DateOfBirth";
import RadioButton from "../../../UI/RadioButtonOutLabel";
import InputButton from "../../../UI/InputButton";
import Anchor from "../../../UI/Anchor";
import ProgressBar from "../../Layouts/Common/ProgressBar";
import Modal from "react-bootstrap/Modal";

const PersonalDetails = ({
  className,
  slideChange,
  validation,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  slideChangeAddress,
  splitForm,
  backClick,
  setError,
  setPreviousName,
}) => {
  const [showNextModal, showsetNextModal] = useState(false);
  const handleShowNext = () => {
    showsetNextModal(true);
  };
  const handleClose = () => showsetNextModal(false);

  const form = splitForm.current;
  const question_3 = getValues();

  let bank = localStorage.getItem("bankName");
  const basicDetailsValidation = async (e) => {
    let errorFlag = 0;
    var lstSalutationResult = await trigger("lstSalutation");

    var salutation = question_3.lstSalutation;

    var txtFNameResult;
    var txtLNameResult;
    var lstDobDayResult;
    var lstDobMonthResult;
    var lstDobYearResult;
    if (lstSalutationResult) {
      txtFNameResult = await trigger("txtFName");
    } else {
      document.getElementById("lstSalutation").focus();
      return false;
    }
    if (txtFNameResult) {
      txtLNameResult = await trigger("txtLName");
    } else {
      document.getElementById("txtFName").focus();
      return false;
    }
    if (txtLNameResult) {
      lstDobDayResult = await trigger("DobDay");
    } else {
      document.getElementById("txtLName").focus();
      return false;
    }
    if (lstDobDayResult) {
      lstDobMonthResult = await trigger("DobMonth");
    } else {
      document.getElementById("DobDay").focus();
      return false;
    }
    if (lstDobMonthResult) {
      lstDobYearResult = await trigger("DobYear");
    } else {
      document.getElementById("DobMonth").focus();
      return false;
    }
    if (lstDobYearResult) {
    } else {
      document.getElementById("DobYear").focus();
    }

    if (
      !lstSalutationResult ||
      !txtFNameResult ||
      !txtLNameResult ||
      !lstDobDayResult ||
      !lstDobMonthResult ||
      !lstDobYearResult
    ) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      if (
        salutation == "Mrs" ||
        salutation == "Ms" ||
        salutation == "Miss" ||
        salutation == "Dame" ||
        salutation == "Lady"
      ) {
        showsetNextModal(true);
      } else {
        slideChange(e);
      }
      // slideChange(e);
    } else {
      return false;
    }
  };

  const namevalidate = async (e) => {
    if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
      var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
      setError(e.target.name, {
        type: "manual",
        message: "Your " + name + " is Invalid. Please Recheck",
      });
      if (e.target.value.trim().length == 0) {
        e.target.value = e.target.value.trim();
      }
    }
    return false;
  };

  const prevNameValidate = async (e) => {
    let errorFlag = 0;
    var prevnameResult = await trigger("txtPrevName");
    if (prevnameResult) {
    } else {
      document.getElementById("txtPrevName").focus();
    }
    if (!prevnameResult) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      handleClose();
      slideChange(e);
    } else {
      return false;
    }
  };

  const closePrevPopup = () => {
    showsetNextModal(false);
  };

  return (
    <div id="slide-1" className={`slidetop_adjust ${className}`}>
      <ProgressBar value="0" />
      <h3 className="text-center">Register your Claim Now</h3>
      <div className="input-section">
        <div className="mb-3 p-0 radio-grp-1a">
          <RadioButton
            id="lstSalutation"
            value="Mr"
            name="lstSalutation"
            className="btn-check"
            labelName="Mr"
            labelClassName={`btn btn-primary btn-1-radio margin-1 ${""}`}
            validation={validation({ required: "Please Select Title" })}
            onClick={() => clearErrors("lstSalutation")}
          />
          <RadioButton
            id="lstSalutation2"
            value="Mrs"
            name="lstSalutation"
            className="btn-check"
            labelName="Mrs"
            labelClassName={`btn btn-primary btn-1-radio margin-1 ${""}`}
            validation={validation({ required: "Please Select Title" })}
            onClick={() => clearErrors("lstSalutation")}
          />
          <RadioButton
            id="lstSalutation3"
            value="Miss"
            name="lstSalutation"
            className="btn-check"
            labelName="Miss"
            labelClassName={`btn btn-primary btn-1-radio margin-1 ${""}`}
            validation={validation({ required: "Please Select Title" })}
            onClick={() => clearErrors("lstSalutation")}
          />
          <RadioButton
            id="lstSalutation4"
            value="Ms"
            name="lstSalutation"
            className="btn-check"
            labelName="Ms"
            labelClassName={`btn btn-primary btn-1-radio ${""}`}
            validation={validation({ required: "Please Select Title" })}
            onClick={() => clearErrors("lstSalutation")}
          />
        </div>
        {validationMsg.lstSalutation && (
          <span className="error_msg " id="email_err">
            {validationMsg.lstSalutation.message}
          </span>
        )}
        <div className="input-group mb-3">
          <TextField
            type="text"
            className="form-control"
            placeholder="First Name"
            name="txtFName"
            id="txtFName"
            onBlur={namevalidate}
            validation={validation({
              required: "Please Enter First Name",
              minLength: {
                value: 3,
                message: "Please Enter Valid First Name",
              },
              pattern: {
                value: /^[a-zA-Z\s]+$/,
                message: "Your Name is Invalid. Please Recheck",
              },
            })}
          ></TextField>
          {validationMsg.txtFName && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtFName.message}
            </span>
          )}
        </div>
        <div className="input-group mb-3">
          <TextField
            type="text"
            className="form-control"
            placeholder="Last Name"
            name="txtLName"
            id="txtLName"
            onBlur={namevalidate}
            validation={validation({
              required: "Please Enter Last Name",
              minLength: {
                value: 3,
                message: "Please Enter Valid Last Name",
              },
              pattern: {
                value: /^[a-zA-Z\s]+$/,
                message: "Your Name is Invalid. Please Recheck",
              },
            })}
          ></TextField>
          {validationMsg.txtLName && (
            <span className="error_msg " id="email_err">
              {validationMsg.txtLName.message}
            </span>
          )}
        </div>
        <DateOfBirth
          textDob="Date Of Birth"
          validation={validation}
          validationMsg={validationMsg}
        />
        <InputButton
          name="personalvalidate"
          className="btn next08  regNextBtn"
          id=""
          value="NEXT"
          btnType="button"
          onClick={basicDetailsValidation}
          style={{ float: "none" }}
        />
      </div>

      {/* *****second modal*** */}
      <Modal
        size="md"
        show={showNextModal}
        onHide={handleClose}
        centered
        backdrop="static"
      >
        <Modal.Body className="modal-bordr">
          <h5 className="text-center">Do you have any previous surname?</h5>
          <div className="input-group mb-3 prevname-outer">
            <TextField
              type="text"
              className="form-control"
              placeholder="Surname"
              name="txtPrevName"
              id="txtPrevName"
              onChange={(event) => setPreviousName(event.target.value)}
              validation={validation({
                pattern: {
                  value: /^[a-zA-Z\s]+$/,
                  message: "Your Surname Name is Invalid. Please Recheck",
                },
              })}
              validationMsg={
                validationMsg.txtPrevName && validationMsg.txtPrevName.message
              }
            />
          </div>
          <div className="form-group col-12">
            <InputButton
              className="btn next7a regNextBtn"
              name="personal"
              value="Next"
              btnType="button"
              onClick={prevNameValidate}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default PersonalDetails;
