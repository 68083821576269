import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import TextField from "../../../UI/TextField";
import Button from "../../../UI/Button";

const QuestionnairDepandants = ({
  questArr,
  onselectAnswer,
  reff,
  display,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  saveSlide4,
  className,
  addPending
}) => {
  const questionId = questArr.questionId ? questArr.questionId : 0;

  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">
              How many dependents did you have at that time?{" "}
            </h1>

            <div className="row mt-4">
              <div className="col-lg-12">
                <div className=" col-lg-12 mb-pd text-center ">
                  <div className="btn-comm com-btn  ">
                    <RadioButton
                      key="dependent-0"
                      id="dependent-0"
                      value="0"
                      name="dependent"
                      className="radio-button"
                      labelName="0"
                      labelClassName="option_but ques1"
                      onClick={labelclicked}
                      dataAnsId={7}
                      dataQuestId={3}
                    />
                  </div>
                  <div className="btn-comm com-btn  ">
                    <RadioButton
                      key="dependent-1"
                      id="dependent-1"
                      value="1"
                      name="dependent"
                      className="radio-button"
                      labelName="1"
                      labelClassName="option_but next-2"
                      onClick={labelclicked}
                      dataAnsId={8}
                      dataQuestId={3}
                    />
                  </div>
                  <div className="btn-comm com-btn  ">
                    <RadioButton
                      key="dependent-2"
                      id="dependent-2"
                      value="2"
                      name="dependent"
                      className="radio-button"
                      labelName="2"
                      labelClassName="option_but next-2"
                      onClick={labelclicked}
                      dataAnsId={9}
                      dataQuestId={3}
                    />
                  </div>

                  <div className="btn-comm com-btn ">
                    <RadioButton
                      key="dependent-3"
                      id="dependent-3"
                      value="3"
                      name="dependent"
                      className="radio-button"
                      labelName="3 or more"
                      labelClassName="option_but next-2"
                      onClick={labelclicked}
                      dataAnsId={10}
                      dataQuestId={3}
                    />
                  </div>
                </div>
                <div class="text-center back">
                    <Button 
                    className="btn btn-link mt-2 h2" 
                    id="back1" 
                    type="button"
                    buttonText="<< Previous"
                    onClick={()=>addPending("dependants")}
                    />
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnairDepandants;
