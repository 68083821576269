import React from "react";

const ProgressBar = ({ value }) => {
  const percentageValue = value == "0" ? "START" : value + "%";
  const content = value == "0%" ? "" : "completed";
  if (value == "0") {
    return (
      <div className="progress progrss" data-percentage={value}>
        <span className="progress-left">
          <span className="progress-bar"></span>
        </span>
        <span className="progress-right">
          <span className="progress-bar" id="progressBar"></span>
        </span>
        <div className="progress-value">
          <div id="progressBar_content" className="next01">
            <span className="perce">{percentageValue}</span>
            <br />
            <span>
              <img src="/assets/img/arrow_bt.png" alt="" />
            </span>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="progress progrss" data-percentage={value}>
        <span className="progress-left">
          <span className="progress-bar"></span>
        </span>
        <span className="progress-right">
          <span className="progress-bar" id="progressBar"></span>
        </span>
        <div className="progress-value">
          <div id="progressBar_content" className="next01">
            <span className="perce">{percentageValue}</span>
            <br />
            <span>{content}</span>
          </div>
        </div>
      </div>
    );
  }
};
export default ProgressBar;
