import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import "../../assets/Signature/css/SignProceed.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { queryString } from "../../Utility/QueryString";
import SignatureCanvas from "react-signature-canvas";
import { useHistory } from "react-router-dom";
import { useSignature } from "../../Hooks/useSignature";
import { useUnqualify } from "../../Hooks/useUnqualify";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { usePreviewData } from "../../Hooks/usePreviewData";

const initialState = {
  showSlide1: "hide",
  showSlide2: "show",
  showSlide3: "hide",
  showSlide4: "hide",
};

const FormReducer = (state, action) => {
  switch (action.type) {
    case "showSlide": {
      if (action.payload.clickedSlide.slide == "accept") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
        };
      } else if (action.payload.clickedSlide.slide == "reject") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide3: "show",
        };
      }else if (action.payload.clickedSlide.slide == "cancelYes") {
        return {
          ...state,
          showSlide3: "hide",
          showSlide4: "show",
        };
      }else if (action.payload.clickedSlide.slide == "cancelNo") {
        return {
          ...state,
          showSlide3: "hide",
          showSlide2: "show",
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default: {
    }
  }
};


  const SignProceed = () => {

    const { saveSignature } = useSignature();
    const { unqualifyUser } = useUnqualify();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const message_type = "signature_store";
    const history = useHistory();
    const [state, dispatch] = useReducer(FormReducer, initialState);
    const [clickedSlide, setClickedSlide] = useState([]);
    const formParameters = JSON.parse(localStorage.getItem("formData"));
    let txtFName = formParameters?.txtFName;
    let signValue = formParameters?.user_sign;
    const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
    const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
    const signatureWrapRef = useRef(null);
    const signPadRef = useRef();
    const [canvasShow, setCanvasShow] = useState("hide");
    const [canvasHide, setCanvasHide] = useState("show");
    const [userSign, setUserSign] = useState("");
    const [editSign, setEditSign] = useState(false);
    const { savePreviewData } = usePreviewData();

    useEffect(() => {
      if (clickedSlide) {
        dispatch({ type: "showSlide", payload: { clickedSlide } });
      }
    }, [clickedSlide]);

    const slideChange = (e) => {
      setClickedSlide({ slide: e.target.name });
      
      if(e.target.name == 'cancelYes'){
        const response =  unqualifyUser(
          visitorParameters.visitor_parameters.uuid,
          'signature_update'
        );
      }
    };

    const canvasFn = () =>{
      if (signPadRef.current) signPadRef.current.clear();
      setCanvasShow('show');
      setCanvasHide('hide');
      setEditSign(true)
    }

    const signatureSubmit = () =>{
      if(editSign){
        handleClick();
      }else{
        history.push("/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid);
      }
    }

    const handleClick = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
        return false;
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        var sigData = signPad.getCanvas().toDataURL("image/png");
        var pdf_generation = 1;
        const response = await saveSignature(
          sigData,
          visitorParameters.visitor_parameters,
          formParameters,
          visitorParameters.data,
          queryString,
          message_type,
          pdf_generation
        );
        
        if (response.data.status === "Success") {
          formParameters.user_sign = sigData;
          localStorage.setItem("formData", JSON.stringify(formParameters));
          history.push("/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid);
        }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };


    useEffect(() => {

      const formParameters = JSON.parse(localStorage.getItem("formData"));
      let signValue = formParameters?.user_sign;
  
        if (formParameters === undefined || formParameters === null || formParameters == ""){
          let customUuid = "";
          const query = new URLSearchParams(window.location.search);
          const query_uuid = query.get("uuid");
          const local_uuid = localStorage.getItem("uuid");
          customUuid = query_uuid ? query_uuid : local_uuid;
          const getResult = async () => {
            let resp = await savePreviewData(customUuid);
            var result = resp.data.result;
            return result;
          }; getResult().then((res) => {
            setUserSign(res.signature);
          }); 
        }else{
          setUserSign(signValue);
        }

      function handleResize() {
        setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
        setSignatureWrapHeight('204');
      }
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight('204');
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);

 

    }, []);

    return (
      <>
      <GetVisitorsParams />
      <div className="SignProceed" >
      <div role="dialog" className="fade modal blackover show">
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body" >
              <div id="imporatant01" className={state.showSlide1}>
              <h2 className="text-center">IMPORTANT</h2>
              <p className="text-center f_name">
                <b> {txtFName}, we believe you have a strong complaint against Lending Stream. We advise you proceed as a matter of urgency to avoid disappointment. </b>
              </p>
              <p>Some companies use the advent of electronic documents and signatures to mislead their clients by encouraging them to agree to terms that are unfair.</p>
              <p>Not us! We require everyone of our clients to ensure they are 100% clear on our terms of business and fee's and as a result we have a very long list of very happy clients!</p>
              <p>
                <img src="assets/img/line.png" alt="" />
              </p>
              <ul className="options">
                <li className="popupLink">
                  <input type="submit" id="preDetails1" className="custom-checkbox1 bank-name" name="accept" onClick={slideChange}/>
                  <label htmlFor="preDetails1">SIGN & Proceed with Complaint {'>'}</label>
                </li>
                <li className="popupLink">
                  <input type="radio" id="preDetails2" className="custom-checkbox2 bank-name " name="reject" 
                  onClick={slideChange} />
                  <label htmlFor="preDetails2">Please CANCEL my Complaint </label>
                </li>
              </ul>
            </div>
          
            <div  id="signature01" className={state.showSlide2}>
              <div id="ref_div" ref={signatureWrapRef}>
              <p className="signtxt">Please sign your signature in the box below</p>
              <p className="signsubtxt">To prevent possible delay, do your best to mimic your handwritten signature to ensure it matches the lenders records.</p>
              
              <div className="web-canvas signature-pad anim_bt d-lg-block" >
                <div id="canvas" className={canvasShow} >
               <SignatureCanvas
                              canvasProps={{
                                id: "myID",
                                width: signatureWrapWidth,
                                height: signatureWrapHeight,
                              }}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                }
                              }}
                            />
                    </div>
                    <div id="preview" className={canvasHide}>
                      <img width="100%" src={userSign} />
                    </div>
              </div>
              {state.isCanvasEmpty && (
                            <div className="sign_msg col-12 text-center sign_msg mb-2" id="signatures_required">
                              Signature is required!
                            </div>
                          )}
                          {state.isValid && (
                            <div className="sign_msg col-12 text-center sign_msg mb-2" id="signatures_valid">
                              Draw valid signature!
                            </div>
                          )}

              <ul className="options11">
                 <li className="popupLink">
                   <input type="radio" id="retake01" className="custom-checkbox2 bank-name " value="Yes" 
                   onClick={canvasFn}/>
                   <label htmlFor="retake01">RETAKE </label>
                 </li>
                 <li className="popupLink">
                  <input type="submit" id="sbmit01" className="custom-checkbox1 bank-name" value="No" 
                  onClick={signatureSubmit}/>
                  <label htmlFor="sbmit01">SUBMIT</label>
                </li>
               </ul>
              </div>
           </div>
 
            <div  id="cancel01" className={state.showSlide3}>
               <p className="canceltxt">Are you sure you want to cancel the complaint?</p>
               <ul className="options1">
                  <li className="popupLink">
                    <input type="submit" id="yes01" name="cancelYes" className="custom-checkbox1 bank-name" value="yes" 
                     onClick={slideChange} />
                    <label htmlFor="yes01">YES</label>
                  </li>
                  <li className="popupLink">
                    <input type="radio" id="no01" name="cancelNo" className="custom-checkbox2 bank-name " value="Yes" 
                    onClick={slideChange}/>
                    <label htmlFor="no01">NO </label>
                  </li>
                </ul>
            </div>

            <div className={`text-center ${state.showSlide4}`} id="thankyou01">
               <img src="assets/img/success-tick.gif" className="thankimg" alt="" />
               <p className="thanktxt">Thankyou</p>
               
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
   
      </>
    );
  };
export default SignProceed;
  