import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

const Dependent = ({ onselectAnswer, reff, display, trigger }) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>How many dependents did you have at that time?</h3>
          <RadioButtonQuest
            key="0"
            className="radio-button"
            labelName="0"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="7"
            name="question_3"
            id="tenq"
            dataQuestId={3}
            dataAnsId={7}
            dataAnsLabel="0"
          />
          <RadioButtonQuest
            key="1"
            className="radio-button"
            labelName="1"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="1"
            name="question_3"
            id="tentww"
            dataQuestId={3}
            dataAnsId={8}
            dataAnsLabel="1"
          />
          <RadioButtonQuest
            key="2"
            className="radio-button"
            labelName="2"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="2"
            name="question_3"
            id="tentwd"
            dataQuestId={3}
            dataAnsId={9}
            dataAnsLabel="2"
          />

          <RadioButtonQuest
            key="3"
            className="radio-button"
            labelName="3&nbsp;or more"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="2"
            name="question_3"
            id="tentws"
            dataQuestId={3}
            dataAnsId={10}
            dataAnsLabel="3 or more"
          />
        </div>
      </div>
    </>
  );
};

export default Dependent;
