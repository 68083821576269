import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const StillActive = ({ className, slideChange, textHeading, validation,backClick }) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const nextSlide = async (e) => {
    if (e.target.value === "95") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      slideChange(e);
    }
    if (e.target.value === "96") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      slideChange(e);
    }
  };
  return (
    <>
      <div id="slide-3" className={`${className}`}>
        <ProgressBar value="15" />

        <h3> {textHeading} </h3>
        {/* <div className="radio_buttons"> */}
        <div className="col-lg-12 mb-pd text-center">
        <div className="btn-comm">
          <RadioButton
            value="95"
            name="stillactive"
            className="radio regNextBtn"
            labelName="Yes"
            labelClassName={`option_but next3a ${checkedQuestionOne}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
          </div>
          <div className="col-lg-12 mb-pd text-center">
        <div className="btn-comm">
          <RadioButton
            value="96"
            name="stillactive"
            className="radio regNextBtn"
            labelName="No"
            labelClassName={`option_but next3a ${checkedQuestionTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
          </div>
          <div>
          <p className="text-center">
            <span
              className="back1 back-btn btn-link"
              name="back03"
              children="<< Previous"
              onClick={backClick}
            ></span>
          </p>
        </div>
        {/* </div> */}
      </div>
    </>
  );
};
export default StillActive;
