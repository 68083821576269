import React from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import "../../../assets/TMS_G_V3/css/TMS_G_V3.scss";
import Header from "../../Includes/Layouts/TMS_G_V3/Header";
import Footer from "../../Includes/Layouts/Common/Footer";
import Section1 from "../../Includes/Layouts/TMS_G_V3/Section1";
import AccordSection from "../../Includes/Layouts/Common/AccordSection";
import FormTMS_G_V3 from "../../Forms/FormTMS_G_V3";
import FbViewPixel from "../../../Utility/FbViewPixel";

const TMS_G_V3 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="TMS_G_V3" />
      <FbViewPixel />
      <div className="TMS_G_V3">
        <Header />
        <section className="form-part" id="firstform">
          <div className="container nopad">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 nopad">
              <FormTMS_G_V3 />
            </div>
          </div>
        </section>
        <Section1 />
        <AccordSection />
        <Footer />
      </div>
    </>
  );
};

export default TMS_G_V3;
