import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const Dependents = ({
  className,
  slideChange,
  validation,
  backClick,
  Year,
  skip
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [checkedThree, setCheckedThree] = useState("unchecked");
  const [checkedFour, setcheckedFour] = useState("unchecked");
  const nextSlide = async (e) => {
    if (e.target.value === "7") {
      setCheckedOne("checked");
      slideChange(e);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "8") {
      setCheckedTwo("checked");
      slideChange(e);
    } else {
      setCheckedTwo("unchecked");
    }

    if (e.target.value === "9") {
      setCheckedThree("checked");
      slideChange(e);
    } else {
      setCheckedThree("unchecked");
    }

    if (e.target.value === "10") {
      setcheckedFour("checked");
      slideChange(e);
    } else {
      setcheckedFour("unchecked");
    }
  };
  return (
    <>
      <div id="slide-5" className={`${className}`}>
        <ProgressBar value="40" />

        <h3>{(skip==true) ? `How many dependents did you have at that time?`:`In ${Year}, how many dependents did you have at that time? `}</h3>
        <div className="btn-comm">
          <RadioButton
            value="7"
            name="dependent"
            className="radio-button"
            labelName="0"
            labelClassName={`option_but  next8 ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="8"
            name="dependent"
            className="radio-button"
            labelName="1"
            labelClassName={`option_but  next8 ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="9"
            name="dependent"
            className="radio-button"
            labelName="2"
            labelClassName={`option_but  next8 ${checkedThree}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="10"
            name="dependent"
            className="radio-button"
            labelName="3 or more"
            labelClassName={`option_but  next8 ${checkedFour}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <p className="text-center"><a  className="back01" name="back07" onClick={backClick}> {'<< Previous'} </a></p>

      </div>
    </>
  );
};
export default Dependents;
