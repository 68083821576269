import { useContext, useEffect, useState } from "react";
import { PhoneNumbers } from "../../../../Constants/PhoneNumberConstants";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useDataIngestion } from "../../../../Hooks/useDataIngestion";
// import useJourneyStats from "../../../../Hooks/useJourneyStats";
import { useQueryString } from "../../../../Hooks/useQueryString";
// import { useUserClicks } from "../../../../Hooks/useUserClicks";
// import { useUuid } from "../../../../Hooks/useUuid";
// import GtmDataLayer from "../../GtmDataLayer";

const CallButton = ({ clickToCall }) => {
  // const { saveUserClicks } = useUserClicks();
  const { saveDataIngestion } = useDataIngestion();
  // const { saveJourneyStats } = useJourneyStats();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { urlSearchParams } = useQueryString();
  // const { uuid } = useUuid();

  const splitName = localStorage.getItem("split_name");
  const utmName = urlSearchParams.get("utm_campaign");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    if (PhoneNumbers[utmName] == undefined) {
      setPhoneNumber(PhoneNumbers["default"]);
    } else {
      setPhoneNumber(PhoneNumbers[utmName]);
    }
  }, [utmName]);

  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");

  useEffect(() => {
    if (phoneNumber) {
      const digits = phoneNumber.replace(/\D/g, "");
      // if (digits.length === 10) {
      const formattedNumber = digits.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})/,
        "+$1-$2-$3-$4"
      );
      setFormattedPhoneNumber(formattedNumber);
      // }
    }
  }, [phoneNumber]);

  const handleClick = async (e, name) => {
    // GtmDataLayer({
    //   question: "Call now",
    //   answer: "",
    // });

    // await saveUserClicks(uuid, name, 1);

    // await saveJourneyStats(
    //   uuid,
    //   "button",
    //   "call_click",
    //   "",
    //   "click",
    //   splitName
    // );

    // const formData = [];
    // formData.lstSalutation = "";
    // formData.txtFName = "";
    // formData.txtLName = "";
    // formData.txtEmail = "";
    // formData.txtPhone = "";
    // formData.page_name = splitName;

    // await saveDataIngestion(
    //   visitorParameters.visitor_parameters,
    //   formData,
    //   "pixel_message",
    //   splitName,
    //   queryString,
    //   visitorParameters.data
    // );
  };

  return (
    <>
    
      <a
        className=""
        onClick={clickToCall}
        href={`tel: ${phoneNumber}`}
      >
        <button 
        className="btn-comm2  mt-sm-3 mt-0 second  anim_green1"
        data-btn-label = "click_to_call_button"
        data-page-label = "CLICK_TO_CALL"
        >
          <img src="assets/FLPClicktoCall/img/phone-icon_white.png" alt=""/> {formattedPhoneNumber}
         </button>
      </a>
    </>
  );
};

export default CallButton;
