import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useContext,
} from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import UnAffordable from "../Includes/Layouts/MB_UBRF_1_1/UnAffordable";
import EmploymentStatus from "../Includes/Layouts/MB_UBRF_1_1/EmploymentStatus";
import Dependents from "../Includes/Layouts/MB_UBRF_1_1/Dependents";
import MonthlyIncome from "../Includes/Layouts/MB_UBRF_1_1/MonthlyIncome";
import Verify from "../Includes/Layouts/MB_UBRF_1_1/Verify";
import MonthlyExpenses from "../Includes/Layouts/MB_UBRF_1_1/MonthlyExpenses";
import Verify2 from "../Includes/Layouts/MB_UBRF_1_1/Verify2";
import Complaint from "../Includes/Layouts/MB_UBRF_1_1/Complaint";
import Bankruptcy from "../Includes/Layouts/MB_UBRF_1_1/Bankruptcy";
import PersonalDetails from "../Includes/Layouts/MB_UBRF_1_1/PersonalDetails";
import PostCode from "../Includes/Layouts/MB_UBRF_1_1/PostCode";
import ContactDetails from "../Includes/Layouts/MB_UBRF_1_1/ContactDetails";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import * as EnvConstants from "../../Constants/EnvConstants";

const FormMB_UBRF_1_1 = () => {
  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
    showSlide7: "hide",
    showSlide8: "hide",
    showSlide9: "hide",
    showSlide10: "hide",
    showSlide11: "hide",
    showSlide12: "hide",
  };
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "unaffordable") {
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "employment") {
          return {
            ...state,
            showSlide2: "hide",
            showSlide3: "show",
          };
        } else if (action.payload.clickedSlide.slide === "dependent") {
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "monthly") {
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show",
          };
        } else if (action.payload.clickedSlide.slide === "verify") {
          return {
            ...state,
            showSlide5: "hide",
            showSlide6: "show",
          };
        } else if (action.payload.clickedSlide.slide === "monthly_expense") {
          return {
            ...state,
            showSlide6: "hide",
            showSlide7: "show",
          };
        } else if (action.payload.clickedSlide.slide === "verify2") {
          return {
            ...state,
            showSlide7: "hide",
            showSlide8: "show",
          };
        } else if (action.payload.clickedSlide.slide === "complaint") {
          return {
            ...state,
            showSlide8: "hide",
            showSlide9: "show",
          };
        } else if (action.payload.clickedSlide.slide === "bankruptcy") {
          return {
            ...state,
            showSlide9: "hide",
            showSlide10: "show",
          };
        } else if (action.payload.clickedSlide.slide === "personal") {
          return {
            ...state,
            showSlide10: "hide",
            showSlide11: "show",
          };
        } else if (action.payload.clickedSlide.slide === "postcode") {
          return {
            ...state,
            showSlide11: "hide",
            showSlide12: "show",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      case "previousSlides": {
        if (action.payload.previousSlide.slide == "back01") {
          return {
            ...state,
            showSlide1: "show",
            showSlide2: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back02") {
          return {
            ...state,
            showSlide2: "show",
            showSlide3: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back03") {
          return {
            ...state,
            showSlide3: "show",
            showSlide4: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back04") {
          return {
            ...state,
            showSlide4: "show",
            showSlide5: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back05") {
          return {
            ...state,
            showSlide5: "show",
            showSlide6: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back06") {
          return {
            ...state,
            showSlide6: "show",
            showSlide7: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back07") {
          return {
            ...state,
            showSlide7: "show",
            showSlide8: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back08") {
          return {
            ...state,
            showSlide8: "show",
            showSlide9: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back09") {
          return {
            ...state,
            showSlide9: "show",
            showSlide10: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back10") {
          return {
            ...state,
            showSlide10: "show",
            showSlide11: "hide",
          };
        } else if (action.payload.previousSlide.slide == "back11") {
          return {
            ...state,
            showSlide11: "show",
            showSlide12: "hide",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      default: {
      }
    }
  };
  const splitForm = useRef(null);
  const scrollfrmv2div = useRef([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const slideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setClickedSlide({ slide: e.target.name });
  };
  const slideChangeAddress = () => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setClickedSlide({ slide: "address" });
  };

  const previousSlideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: "previousSlides", payload: { previousSlide } });
    }
  }, [previousSlide]);

  const formSubmit = async () => {
    const form = splitForm.current;
    const values = getValues();
    const formData = values;

    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }

    //Sending Questiionaire Data
    formData.question_1 = formData.unaffordable;
    formData.question_2 = formData.employment;
    formData.question_3 = formData.dependent;
    formData.question_4 = formData.monthly;
    formData.question_5 = formData.verify;
    formData.question_6 = formData.monthly_expense;
    formData.question_7 = formData.verify2;
    formData.question_8 = formData.complaint;
    formData.question_9 = formData.bankruptcy;
    //Questiionaire Data Complete

    formData.page_name = "MB_UBRF_1_1";
    formData.pCode_manual = form["pCode_EntryType"].value;
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("queryString", queryString);
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "split_form_submit",
        "MB_UBRF_1_1",
        queryString,
        visitorParameters.data
      );
      if (formSUbmitResult.data.status === "Success") {
        if (queryString != null) {
          console.log("first if");
          console.log(queryString);
          if (queryString.indexOf("&" + field + "=") !== -1) {
            console.log("s");
            if (EnvConstants.AppConversionPixel === "true") {
              console.log("if fb");
              history.push("/fbpixel?split_name=MB_UBRF_1_1"); // fb fixel firing
            } else {
              console.log("else fb");
              window.scrollTo(0, 0);
              history.push(
                "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
              );
            }
          } else {
            console.log("another else fb");
            window.scrollTo(0, 0);
            history.push(
              "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          }
        } else {
          console.log("laset else  fb");
          window.scrollTo(0, 0);
          history.push(
            "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
          );
        }
      }
    }
  };

  return (
    <>
      <form
        ref={splitForm}
        name="split_form"
        id="user_form"
        method="POST"
        autoComplete="off"
      >
        <div ref={scrollfrmv2div}>
          <UnAffordable
            className={`col-12 top_minus_1 ${state.showSlide1}`}
            textHeading="Was your car finance with MoneyBarn unaffordable?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
          />
          <EmploymentStatus
            className={`col-12 top_minus_1 ${state.showSlide2}`}
            textHeadingLine1="At the time you secured finance…"
            textHeadingLine2="What was your employment status?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />
          <Dependents
            className={`col-12 top_minus_1 ${state.showSlide3}`}
            textHeadingLine1="At the time you secured finance…"
            textHeadingLine2="How many dependents did you have?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />
          <MonthlyIncome
            className={`col-12 top_minus_1 ${state.showSlide4}`}
            textHeadingLine1="At the time you secured finance…"
            textHeadingLine2="Did MoneyBarn ask you about your monthly income?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          <Verify
            className={`col-12 top_minus_1 ${state.showSlide5}`}
            textHeadingLine1="At the time you secured finance…"
            textHeadingLine2="Did MoneyBarn then verify those details?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          <MonthlyExpenses
            className={`col-12 top_minus_1 ${state.showSlide6}`}
            textHeadingLine1="At the time you secured finance…"
            textHeadingLine2="Did MoneyBark ask about your monthly expenses?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />
          <Verify2
            className={`col-12 top_minus_1 ${state.showSlide7}`}
            textHeadingLine1="At the time you secured finance…"
            textHeadingLine2="Did MoneyBarn then verify those details?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          <Complaint
            className={`col-12 top_minus_1 ${state.showSlide8}`}
            textHeadingLine2="Have you, (or a company on your behalf), ever filed a complaint with MoneyBarn?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          <Bankruptcy
            className={`col-12 top_minus_1 ${state.showSlide9}`}
            textHeadingLine2="Are you currently subject to Bankruptcy or Trust deed?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          <PersonalDetails
            className={`col-12 top_minus_1 ${state.showSlide10}`}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            getValues={getValues}
            splitForm={splitForm}
            slideChange={slideChange}
            slideChangeAddress={slideChangeAddress}
            backClick={previousSlideChange}
            setError={setError}
          />
          <PostCode
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            className={`${state.showSlide11}`}
            getValues={getValues}
            splitForm={splitForm}
            scrollView={scrollfrmv2div}
            slideChange={slideChange}
            backClick={previousSlideChange}
          />
          <ContactDetails
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            className={`col-12 top_minus_1 ${state.showSlide12}`}
            getValues={getValues}
            formSubmit={formSubmit}
            backClick={previousSlideChange}
          />
        </div>
        <div className="col-lg-12 text-center slide-logo">
          <img src="/assets/img/MB_UBRF_1_1/ssl.svg" alt="" />
        </div>
      </form>
    </>
  );
};

export default FormMB_UBRF_1_1;
