import React, { useState } from "react";
import ProgressBar from './ProgressBar';
import RadioButton from '../../../UI/RadioButton';

const Slide6 = ({
  className,
  slideChange,
  validation,
  trigger,
  clearErrors,
  getValues,
  setError,
  backClick
}) => {
  const [checked1, setChecked1] = useState('');
  const [checked2, setChecked2] = useState('');

  const nextSlide = async (e) => {
    if (e.target.value == 11) {
      setChecked1('activeclass');
    } else {
      setChecked1('');
    }

    if (e.target.value == 12) {
      setChecked2('activeclass');
    } else {
      setChecked2('');
    }

    slideChange(e);
  };
  return (
    <>
      <div id="slide6" className={`col-12 top_minus ${className}`}>
        <ProgressBar value={40} />
        <h3>At the time you secured finance… </h3>

        <div className="mb-3" id="slide-C">
          <h4>Did MoneyBarn ask you about your monthly income?</h4>

          <RadioButton
            htmlFor="previously"
            value="11"
            tabIndex="2"
            id="monthlyIncome-1"
            name="monthlyIncome"
            className="radio-button"
            labelName="Yes"
            labelClassName={`option_but next-C ${checked1}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            htmlFor="previously"
            value="12"
            tabIndex="2"
            id="monthlyIncome-2"
            name="monthlyIncome"
            className="radio-button"
            labelName="No"
            labelClassName={`option_but next-C ${checked2}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <p className="text-center">
            <a href="#" className="back-B" id="back05" name="back05" onClick={backClick}>
              {'<< Previous'}
            </a>
          </p>
        </div>

      </div>
    </>
  );
};

export default Slide6;