import { useContext, useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import ReducerContext from "../../../../Contexts/ReducerContext";

export default function DidReceiveIncome({ reff,onselectAnswer }) {
  const state=useContext(ReducerContext);
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");

  const optionSelected = (e) => {
    if (e.target.value == "226") {
      setCheckedOne("checked");
      onselectAnswer(e);
    }else {
      setCheckedTwo("checked");
      onselectAnswer(e);
    }
  };

  return (
      <div
        className={`radio-box animated fadeInUp space-top space-top2 ${state.showSlide5}`}
        id="slide-23"
        ref={reff}
      >
        <h3><b>
          In the year when you took the card - did you receive any income?
          </b></h3>
         <RadioButtonQuest
          className="radio-buttons"
          labelName="Yes"
          labelClassName="option_but next-2"
          onClick={(e)=>optionSelected(e)}
          name="didReceiveIncome"
          dataQuestId={77}
          dataAnsId={226}
          value="226"
          id="DidReceiveIncomeYes"
        />
        <RadioButtonQuest
          className="radio-buttons"
          labelName="No"
          labelClassName="option_but next-2"
          onClick={(e)=>optionSelected(e)}
          name="didReceiveIncome"
          dataQuestId={77}
          dataAnsId={227}
          value="227"
          id="DidReceiveIncomeNo"
        />
      </div>
  );
}
