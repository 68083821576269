const OB_Splash = ({
    obSplashStat,
    btndisable,
    keepMyFileLink,
    closeMyFileLink,
    loader,
    userDetails
}) => {
    const fname = localStorage?.getItem("firstname");
  return (
    <>
    
    <div className={`__ExpampleDemo ${obSplashStat}`}>
    <div className="loader_1">
     <div className={`text-center loader-wrap ${loader == 1 ? 'showload' : 'hide'}`}>
        <div className="loader">
          <img src="assets/img/review.gif" alt="" />
        </div>
      </div> 
      </div>
      <div className={`container ${loader == 0 ? 'showload' : 'hide'}`}>
        <div className="row">
          <div className="col-12 showcase">
            <div className="video-container">
              <video
                src="../../assets/img/production_id_4393393_(1080p).mp4"
                autoPlay
                muted
                loop
              ></video> 
            </div>
            <div className="content">
              <h1>
              {fname}, help your {userDetails?.lender} responsible lending claim by providing bank statements and a credit file. These documents will really help your case.
              </h1>
              <h3 className="mb-lg-5 mb-md-5 mb-sm-4 mb-4">
                To assist in safely and securing supporting evidence we have
                partnered with leading financial technology companies to make
                the process quick, easy, and free!
              </h3>

              <div className="col-lg-7 col-md-9 col-12 m-auto text-center mb-3">
                <div>

                  <a href="#">
                    <button 
                    className="btn click_accBb" 
                    onClick={keepMyFileLink}
                    disabled={btndisable}
                    data-btn-label = "keep_my_file_open"
                    data-page-label = "OB_SPLASH"
                    > Click Here to provide documents {'>'}</button>
                  </a>
                </div>
              </div>

              {/* <div className="cls_btn text-center">
                <a href="#">
                  <button 
                    className="close_bbt"
                    onClick={closeMyFileLink}
                    disabled={btndisable}
                    data-btn-label = "close_my_file"
                    data-page-label = "OB_SPLASH"
                    >Close My File {'>'}</button>
                </a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};
export default OB_Splash;
