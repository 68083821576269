import React from "react";

const Header = () => {
  return (
    <>
      {/* <section className="sun-content ">
        <div className="container-fluid">
          <div className="col-md-12">
            <p>
              <img src="/assets/LS_UB_1/img/sun.png" alt="" />
              Moneybarn repays £30 million to 6,000 customers
            </p>
          </div>
        </div>
      </section> */}
      <header id="hding">
        <div className="container">
          <div className="col-lg-8 offset-lg-2 col-12 text-center p-0" id="slide01">
            < img src="/assets/LS_UB_1/img/logo.png" alt="" />
            <h1>
              Taken Out Repeated High-Cost Credit from Lending Stream?
            </h1>
            <h2>
              Starting your claim is easy and 100% online. You could be owed £££thousands <br/> if you were mis-sold!
            </h2>
            {/* <h3>
                <strong>Our Average Refund for Lending Stream is £1,508.62</strong>
              </h3> */}
          </div>
          <div className="spacemob" style={{ display: "none" }}></div>
        </div>
      </header>
    </>
  );
};

export default Header;