import React, { useState, useEffect, useRef, useReducer } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import { questinnare1, questinnare2LS } from "../../../../Constants/Questions";
import IncomeExpenditure from "./LendingStream/IncomeExpenditure";
import QuestionnaireFlpQ5 from "../Followup/QuestionnaireFlpQ5";
import QuestionnaireFlpFANUpload from "../Followup/QuestionnaireFlpFANUpload";
import QuestionnaireFlpAgreementNo from "./QuestionnaireFlpAgreementNo";
import QuestionnaireEmail from "../Followup/QuestionnaireEmail";
import { useUserVerify } from "../../../../Hooks/useUserVerify";
import { userMilestoneUpdate } from "../../../../Hooks/userMilestoneUpdate";
import { queryString } from "../../../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useSkippedLeads } from '../../../../Hooks/useSkippedLeads';
import QuestionnaireFooter from "./QuestionnaireFooter";

const initialState = {
  showSlide1: "hide",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide",
};
const QuestionnaireReducer = (state, action) => {
  switch (action.type) {
    case "showSlideChange": {
      if (action.payload == "slide1") {
        return {
          ...state,
          showSlide1: "show",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          percentage:20,
        };
      } else if (action.payload == "slide2") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          percentage:40,
        };
      } else if (action.payload == "slide3") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "show",
          showSlide4: "hide",
          showSlide5: "hide",
          percentage:60,
        };
      } else if (action.payload == "slide4") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "show",
          showSlide5: "hide",
          percentage:80,
        };
      } else if (action.payload == "slide5") {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "show",
          percentage:90,
        };
      } else {
        return {
          ...state,
        };
      }
    }
  }
};
const LendingStreamQuestionForm = ({
  questionsPop,
  allFollowupData,
  pendingQuestionnaire,
  documentType,
  docType,
  sett2aValidation,
  t2aApiReqData,
  t2aStatus,
  currentUuid,
  setDivShow,
  splitName
}) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const [show, setShow] = useState(false);
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const splitForm = useRef(null);
  const [optionSelected] = useState([]);
  const { getUserVerfyValidation,saveFollowupUserVerify } = useUserVerify();
  const { updateMilestone } = userMilestoneUpdate();
  const { skippedLeads } = useSkippedLeads();

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  useEffect(() => {
    slideChange(pendingQuestionnaire);
  }, [pendingQuestionnaire]);

  const sendT2aApiReqDataAPI = () => {
    const useruuid = t2aApiReqData.uuid;
    const fName = t2aApiReqData.fName;
    const lName = t2aApiReqData.lName;
    const pCode = t2aApiReqData.pCode;
    const addressLine1 = t2aApiReqData.addressLine1;
    const dobDay = t2aApiReqData.day;
    const dobMonth = t2aApiReqData.month;
    const dobYear = t2aApiReqData.year;
    const addressLine2 = t2aApiReqData.addressLine2;
    const postTown = t2aApiReqData.postTown;
    const country = t2aApiReqData.country;
    const title = t2aApiReqData.title;
    const addressLine3 = t2aApiReqData.addressLine3;
    const addressLine4 = t2aApiReqData.addressLine4;
    const county = t2aApiReqData.county;

    if (
      useruuid &&
      fName &&
      lName &&
      pCode &&
      addressLine1 &&
      t2aStatus == null
    ) {
      (async () => {
        const response = await getUserVerfyValidation(
          useruuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYear,
          dobMonth,
          dobDay,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
          addressLine4,
          county,
        );
        storeT2aResponse(response.data);

        if (response.data.status == "success") {
          localStorage.setItem("t2a_result", 1);
          history.push("/view_docs_flp?uuid=" + currentUuid);
        } else {
          localStorage.setItem("t2a_result", 0);
          setDivShow("idUpload");
        }
      })();
    } else {
      localStorage.setItem("t2a_result", t2aStatus);
      let email_skip = localStorage.getItem("email_doc");
      if (useruuid && email_skip != 1) {
        (async () => {
          const response = await updateMilestone(
            useruuid,
            t2aStatus,
            allFollowupData
          );
        })();
      }

      if (t2aStatus == 1) {
        history.push("/view_docs_flp?uuid=" + currentUuid);
      } else {
        setDivShow("idUpload");
      }
    }
  };
  const slideChange = (pendingArr) => {
    let pendingCount = pendingArr.length;
    if (pendingCount == 0) {
      setShow(false);
      sendT2aApiReqDataAPI();
    }

    pendingArr.every((element) => {
      dispatch({ type: "showSlideChange", payload: element });
    });
  };
  const storeFollowupQuestionnire = async (question_data) => {
    const response = await saveFollowupUserQuestions(
      question_data,
      allFollowupData,
      "followup_question_store"
    );
  };

  const storeT2aResponse = async (t2a_data) => {
    const response = await saveFollowupUserVerify(
      allFollowupData,
      t2a_data,
      "person_verify_store_flp",
      queryString,
      t2aApiReqData
    );
  };

  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    let flage = false;
    let question_data = {};
    if (e.target.name == "btn_qest_36") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("slide4");
    } else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const saveIncomeExpenditure = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    const values = getValues();
    setCompletedQuestArr([...completedQuestArr, selectedQuest, 32, 33, 35]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    var answer35 = splitForm.current["question_35"].value;

    var slide_array = [
      ["31", answer31],
      ["32", answer32],
      ["33", answer33],
      ["35", answer35],
    ];

    var question_data = {
      slide_4: slide_array,
    };
    removeFromPending("slide2");
    storeFollowupQuestionnire(question_data);

    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    const valuesreg = getValues();
    let highestBalance = valuesreg["highest_balance"];
    let agreementNo = valuesreg["question_45"];
    let email = valuesreg["question_48"];
    let flage = false;
    let question_data = {};
    if (selectedQuest == "45" && agreementNo) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: agreementNo,
      };
      removeFromPending("slide1");
    }
    if (selectedQuest == "53" && highestBalance) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: highestBalance,
      };
      removeFromPending("slide2");
    }
    if (selectedQuest == "48" && email) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: email,
      };
      removeFromPending("slide3");
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const removeFromPending = (slidename) => {
    const index = pendingQuestionnaire.indexOf(slidename);
    if (index > -1) {
      pendingQuestionnaire.splice(index, 1);
    }
    slideChange(pendingQuestionnaire);
  };

  const SkipAgreement = async () => {
    const skippedLeadsResponse = await skippedLeads('SkipAgreementNoFlp', currentUuid, splitName);
    removeFromPending('slide1');
  };

  const pushPageChange = () => {
    removeFromPending("slide5");
  };

  return (
    <div className="container">
      <div className="row">
        <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12">
          <div className="radio-box animated fadeInUp">
            <form
              ref={splitForm}
              name="split_form"
              id="user_form"
              method="POST"
              autoComplete="off"
            >
              <div
                className={`question1 animated fadeInUp space-top ${state.showSlide1}`}
                id="breakdown01"
              >
                <QuestionnaireFlpAgreementNo
                  questArr={questinnare1}
                  labelclicked={onselectAnswer}
                  onselectAnswer={onselectAnswer}
                  validation={register}
                  validationMsg={errors}
                  getValues={getValues}
                  trigger={trigger}
                  setError={setError}
                  saveInputAns={saveInputAns}
                  SkipAgreement={SkipAgreement}
                />
              </div>
              <div
                className={`question2 animated fadeInUp space-top ${state.showSlide2}`}
                id="breakdown1"
              >
                <IncomeExpenditure
                  questArr={questinnare1}
                  labelclicked={onselectAnswer}
                  onselectAnswer={onselectAnswer}
                  validation={register}
                  validationMsg={errors}
                  getValues={getValues}
                  trigger={trigger}
                  setError={setError}
                  saveIncomeExpenditure={saveIncomeExpenditure}
                  splitForm={splitForm}
                />
              </div>
              <div
                className={`question3 animated fadeInUp text-center space-top ${state.showSlide3}`}
                id="breakdown2"
              >
                <QuestionnaireEmail
                  validation={register}
                  getValues={getValues}
                  trigger={trigger}
                  validationMsg={errors}
                  saveInputAns={saveInputAns}
                  lender={'Lending Stream'}
                />
              </div>
              <div
                className={`question4 animated fadeInUp space-top ${state.showSlide4}`}
                id="breakdown3"
              >
                <QuestionnaireFlpQ5
                  questArr={questinnare2LS}
                  onselectAnswer={onselectAnswer}
                  optionSelected={optionSelected}
                />
              </div>
              <div
                className={`question5 animated fadeInUp space-top ${state.showSlide5} radio-box`}
                id="breakdown4"
              >
                <QuestionnaireFlpFANUpload
                  questArr={questinnare2LS}
                  validation={register}
                  validationMsg={errors}
                  getValues={getValues}
                  trigger={trigger}
                  setError={setError}
                  docType={docType}
                  documentType={documentType}
                  allFollowupData={allFollowupData}
                  sett2aValidation={sett2aValidation}
                  pushPageChange={pushPageChange}
                  setShow={setShow}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <QuestionnaireFooter
              percentage={state.percentage}
            />
    </div>
  );
};
export default LendingStreamQuestionForm;
