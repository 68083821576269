import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import Modal from "react-bootstrap/Modal";
import InputButton from "../../../UI/InputButton";

const SoldOnDebt = ({
  className,
  slideChange,
  textHeadingLine1,
  validation,
  backClick,
  setPrevious
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);
 
  const nextSlide = async (e) => {
    if (e.target.value === "207") {
      setCheckedOne("checked");
      setPrevious("207");
      slideChange(e);
      // setshowClaimModal(true);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "206") {
      setCheckedTwo("checked");
      setPrevious("206");
      slideChange(e);
    } else {
      setCheckedTwo("unchecked");
    }
  };
  return (
    <>
      <div id="slide-6" className={`${className}`}>
        <ProgressBar value="20" />

        <h3> {textHeadingLine1} </h3>
        <div className="radio_buttons">
        <div className="btn-comm">
          <RadioButton
            value="206"
            name="SoldOnDebt"
            className="radio-button"
            labelName="Yes"
            labelClassName={`option_but radio ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
          <div className="btn-comm">
          <RadioButton
            value="207"
            name="SoldOnDebt"
            className="radio-button"
            labelName="No"
            labelClassName={`option_but radio ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
        </div>
        <p className="text-center">
          <span
            className="back-btn btn-link"
            name="back03d"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div>
       {/* **** cannot accept your claim popup start **** */}
       <Modal size="md" show={claimModal} onHide={handleCloseclaimModal} centered backdrop="static" className="modaloutersec">
        <Modal.Body>
         <h5 className="text-center mb-3">Sorry, we cannot accept your claim</h5>
          <div className="input-group prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08 regNextBtnNew regNxtBtnNw text-center"
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SoldOnDebt;
