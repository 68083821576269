import React, { useState } from "react";
import InputButton from "../../../UI/InputButton";
const OB_AffordabilityCheckV2 = ({
  className,
  setLoader,
  loader,
  reloader,
  btndisable,
  setReloader,
  pushProceedLink,
  proceedManually,
  affordStat,
}) => {
  return (
    <>
      <div className="OpenBankingMb2">
      <div className={`main-page-btn ${loader == 0 ? 'show' : 'hide'}`} id="page1">
        <section id="main-dv" className="btn-info_section">
          <div className="container btn-info_part">
            <div className="row justify-content-center ">
              <div className="col-lg-10 col-11">
                <div className={`space text-center ${reloader == 1 ? 'show' : 'hide'}`}>
                  <img className="logo_img" src="assets/img/logo_1.png" alt="" />
                  <p className="htext">
                    <strong> One of the main reasons Moneybarn rejects claims is the complaint was not accompanied by bank statements that verify income and expense at the time of the initial lending.</strong>
                  </p>
                  <p className="sub-text">Connect your bank account using <strong>OPEN BANKING</strong> to download your bank statements directly</p>
                  <p className="info-italic">It's safe, secure, private and FREE!</p>
                  <div className="button _1 anim_blue">
                    <InputButton
                      name="proceed"
                      className="btn_proceed"
                      id=""
                      value="Connect My Bank Account >"
                      btnType="button"
                      onClick={pushProceedLink}
                      disabled={btndisable}
                    />
                    <div className="back"></div>
                  </div>
                  
                



                  <div className="mt-4">
                  <img className="api-logo p-2" src="assets/img/bud-api.png" alt="" />
                  <img className="api-logo p-2" src="assets/img/fca-logo.png" alt="" />
                  </div>

                </div>

                <div className={`space text-center ${reloader == 0 ? 'show' : 'hide'}`}>
                  <div className="button _1 w-100 anim_blue refresh_btn_outer">
                    <InputButton
                      name="proceedd"
                      className="btn_proceed refresh_btn"
                      id="bb"
                      value="Refresh"
                      btnType="button"
                      onClick={pushProceedLink}
                    />
                    <div className="back"></div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </section>
      </div>
      <div className={`loader text-center ${loader == 1 ? 'showload' : 'hide'}`} id="page2">
        <div className="loader-anime">
          <img src="assets/img/loading-23.gif" alt="" />
        </div>
      </div>
    </div>
    </>
  );
};
export default OB_AffordabilityCheckV2;
