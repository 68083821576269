import React, {
    useRef,
    useState,
    useEffect,
    useContext,
    useReducer,
} from "react";
// import "../../assets/AnalyseMB/css/AnalyseMb.scss"
import "../../assets/AnalyseMB_GN_NEW/css/AnalyseMB_GN_NEW.scss";

import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useUserVerify } from "../../Hooks/useUserVerify";
import { MonthNameArray } from "../../Constants/Constants";
import { queryString } from "../../Utility/QueryString";
import { userInfo } from "../../Hooks/userInfo";
import { useTotalCarCheck } from "../../Hooks/useTotalCarCheck";
import { useOpenBanking } from "../../Hooks/useOpenBanking";
import tms_logo from "../../assets/AnalyseMB_GN_NEW/img/logo.png";
import loader_img from "../../assets/AnalyseMB_GN_NEW/img/ring-gif.gif";
import SelectBox from "../UI/SelectBox";
import { PossessionMonth, YearPossession } from "../../Constants/Constants";
import InputButton from "../UI/InputButton";
import { useForm } from "react-hook-form";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { useHistory } from "react-router-dom";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { useLogRecord } from "../../Hooks/UseLogRecord";

const AnalyseMB_UFGN_4 = () => {

    const { visitorParameters } = useContext(VisitorParamsContext);
    const { UserDataInfo } = userInfo();
    const { getUserVerfyValidation, saveUserVerify } = useUserVerify();
    const [verifyData, setVerifyData] = useState({});
    const { totalCarCheckApi } = useTotalCarCheck();
    const [totalCarCheck, settotalCarCheck] = useState(null);
    const [t2aStatus, setT2aStatus] = useState("");
    const { getCustomerCheck, milestoneChase } = useOpenBanking();
    const [showLoader, SetLoader] = useState("show");
    const [loandateDiv, SetLoandateDiv] = useState("hide");
    const { isLoading, SaveQuestionnaire } = useQuestionnaire();
    const formdata = JSON.parse(localStorage.getItem("formData"));
    const [UserUUid, setUserUuid] = useState("");
    const [user_id, SetUser_id] = useState("");
    const [User_bud_lender, Set_user_lender] = useState({});
    const history = useHistory();
    const splitForm = useRef(null);
    const [loaderContent, setLoaderContent] = useState("");
    const [showLoad, setshowLoad] = useState("show");
    const query = new URLSearchParams(window.location.search);
    const uuid = query.get("uuid");
    const { updateLogRecord } = useLogRecord();
    const [index, setIndex] = useState(0);

    let loan_date;

    const { register, errors, trigger, setError, clearErrors, getValues } =
        useForm({
            mode: "onBlur",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });


    const contentArray = [
        "We assure you that your privacy and security are our top priorities.",
        "You will be directed to a securely encrypted open banking page where you can confidently input your important details.",
        "Rest assured, we are committed to safeguarding your bank information",
    ];

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoaderContent(contentArray[index]);
            setIndex((index + 1) % contentArray.length);
        }, 1000);

        return () => clearTimeout(timer);
    }, [index]);





    const getCurrentDateTime = () => {
        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');
        const hours = currentDate.getHours().toString().padStart(2, '0');
        const minutes = currentDate.getMinutes().toString().padStart(2, '0');
        const seconds = currentDate.getSeconds().toString().padStart(2, '0');


        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const query_string = localStorage.getItem("queryString");


    const getUnqualifedTaxdata = async () => {


        let fname = '';
        let lname = '';
        let postcode = '';
        let address_line1 = '';
        let valid_keeper_date = '';
        let car_reg_no = '';
        let dob_day = '';
        let dob_month = '';
        let dob_yr = '';
        let email = '';
        let phone = '';
        let address_line2 = '';
        let town = '';
        let country_name = '';
        let title_user = '';
        let address_Line3 = '';
        let address_Line4 = '';
        let county_user = '';
        let enteredLoanDate = '';
        let split_name = '';
        let t2a_result = '';
        let bud_lender = '';
        let user_id = '';
        let totalCarloanDate = '';
        let OnlyOtherLender = '';
        let matchCheckCount = '';
        let is_checked_tcc = '';
        let lenderArray = '';
        let lenderlist;

        const formdata = JSON.parse(localStorage.getItem("formData"));

        if (formdata === undefined || formdata === null || formdata == "") {
            (async () => {
                const response = await UserDataInfo(
                    { uuid: uuid }

                );
                if (response.data.status === "Success") {
                    fname = response.data.response.first_name;
                    lname = response.data.response.last_name;
                    postcode = response.data.response.postcode;
                    address_line1 = response.data.response.address_line1;
                    valid_keeper_date = response.data.response.valid_keeper_date;
                    car_reg_no = response.data.response.car_reg_no;
                    dob_day = response.data.response.dob_day;
                    dob_month = response.data.response.dob_month;
                    dob_yr = response.data.response.dob_yr;
                    email = response.data.response.email;
                    phone = response.data.response.phone;
                    address_line2 = response.data.response.address_line2;
                    town = response.data.response.town;
                    country_name = response.data.response.country;
                    title_user = response.data.response.title;
                    address_Line3 = response.data.response.address_line3;
                    address_Line4 = response.data.response.address_line4;
                    county_user = response.data.response.county;
                    split_name = response.data.response.split_name;
                    t2a_result = response.data.response.t2a_status;
                    user_id = response.data.response.user_id;
                    totalCarloanDate = response.data.response.totalCarloanDate;
                    OnlyOtherLender = response.data.response.OnlyOtherLender;
                    matchCheckCount = response.data.response.matchCheckCount;
                    is_checked_tcc = response.data.response.is_checked_tcc;
                    lenderArray = response.data.response.bud_lender;
                    enteredLoanDate = response.data.response.enteredLoanDate;
                    localStorage.setItem("user_id", user_id);

                    const savetotalCarCheck = (totalCarCheck) => {
                        settotalCarCheck(totalCarCheck);
                    };
                    if (bud_lender == "others") {
                        localStorage.setItem("MatchStatus", 0);
                        setTimeout(() => {
                            history.push(
                                "/CheckListGN_4?uuid=" + visitorParameters.visitor_parameters.uuid
                            );
                        }, 3000);
                    } else if (valid_keeper_date == 1) {
                        setTimeout(async () => {
                            const startTime = performance.now();
                            let startDateTime = getCurrentDateTime();

                            const totalCarCheckApiData = await totalCarCheckApi(
                                car_reg_no,
                                uuid,
                                split_name
                            );

                            savetotalCarCheck(totalCarCheckApiData.data.vehicleData);

                            let endDateTime = getCurrentDateTime();

                            const endTime = performance.now();

                            const Timems = endTime - startTime;

                            const timeTaken = (Timems / 1000).toFixed(2);  // Convert milliseconds to seconds
                            const LogRecord = await updateLogRecord(
                                uuid,
                                "Total_car_check",
                                timeTaken,
                                startDateTime,
                                endDateTime
                            );

                            let tcc_lender = totalCarCheckApiData.data.vehicleData.finance_company;
                            let agreement_date = totalCarCheckApiData.data.vehicleData.agreement_date;

                            if (tcc_lender === "Moneybarn" && (agreement_date !== null || agreement_date !== "")) {
                                var dateObj = new Date(agreement_date);
                                var year = dateObj.getFullYear();
                                var month = dateObj.toLocaleString('default', { month: 'long' });
                                var formattedDate = year + " " + month;
                                loan_date = formattedDate;
                                localStorage.setItem("LoanDate", loan_date);

                                matchCheck(lenderArray, loan_date);
                            }
                            else {
                                setshowLoad("hide");
                                SetLoandateDiv("show");
                            }
                        }, 5000);
                    } else {
                        matchCheck(lenderArray, enteredLoanDate);
                    }

                } else {
                    console.log('Invalid User')
                }
            }
            )();
        } else {

            fname = formdata ? formdata.txtFName : '';
            lname = formdata ? formdata.txtLName : '';
            postcode = formdata ? formdata.txtPostCode : '';
            address_line1 = formdata ? formdata.txtHouseNumber : '';
            dob_day = formdata ? formdata.DobDay : '';
            dob_month = formdata ? formdata.DobMonth : '';
            dob_yr = formdata ? formdata.DobYear : '';
            email = formdata ? formdata.txtEmail : '';
            phone = formdata ? formdata.txtPhone : '';
            address_line2 = formdata ? formdata.txtAddress2 : '';
            town = formdata ? formdata.txtTown : '';
            country_name = formdata ? formdata.txtCountry : '';
            title_user = formdata ? formdata.lstSalutation : '';
            address_Line3 = formdata ? formdata.txtAddress3 : '';
            //address_Line4 = response.data.response.address_line4;
            county_user = formdata ? formdata.txtCounty : '';
            enteredLoanDate = localStorage.getItem("enteredLoanDate");
            split_name = formdata ? formdata.page_name : '';
            valid_keeper_date = localStorage.getItem("ValidKeeper");
            matchCheckCount = localStorage.getItem("MatchStatus");
            is_checked_tcc = localStorage.getItem("TccStatus");
            bud_lender = localStorage.getItem("bud_lender");
            lenderlist = localStorage.getItem("lenderArray");
            lenderArray = JSON.parse(lenderlist);

                try {
                // Parsing the JSON string to get the object
                lenderArray = JSON.parse(lenderlist);
                } catch (error) {
                // Handle the error if parsing fails
                console.error('Error parsing JSON:', error);
                }
            car_reg_no = localStorage.getItem("Vehicle_RegNo");;
            if (bud_lender == "others") {
                OnlyOtherLender = 1;
            } else {
                OnlyOtherLender = 0
            }
            Set_user_lender(lenderArray);


            const savetotalCarCheck = (totalCarCheck) => {
                settotalCarCheck(totalCarCheck);
            };
            if (bud_lender == "others") {
                localStorage.setItem("LoanDate", enteredLoanDate);
                localStorage.setItem("MatchStatus", 0);
                setTimeout(() => {
                    history.push(
                        "/CheckListGN_4?uuid=" + visitorParameters.visitor_parameters.uuid
                    );
                }, 3000);
            } else if (valid_keeper_date == 1) {
                (async () => {
                    let startDateTime = getCurrentDateTime();

                    const startTime = performance.now();

                    const totalCarCheckApiData = await totalCarCheckApi(
                        car_reg_no,
                        uuid,
                        split_name
                    );

                    savetotalCarCheck(totalCarCheckApiData.data.vehicleData);

                    let endDateTime = getCurrentDateTime();

                    console.log("endDateTime : " + endDateTime);

                    const endTime = performance.now();

                    const Timems = endTime - startTime;

                    const timeTaken = (Timems / 1000).toFixed(2); // Convert milliseconds to seconds

                    const LogRecord = await updateLogRecord(
                        uuid,
                        "Total_car_check",
                        timeTaken,
                        startDateTime,
                        endDateTime
                    );

                    let tcc_lender = totalCarCheckApiData.data.vehicleData.finance_company;
                    let agreement_date = totalCarCheckApiData.data.vehicleData.agreement_date;

                    if (tcc_lender === "Moneybarn" && (agreement_date !== null || agreement_date !== "")) {
                        var dateObj = new Date(agreement_date);
                        var year = dateObj.getFullYear();
                        var month = dateObj.toLocaleString('default', { month: 'long' });
                        var formattedDate = year + " " + month;
                        loan_date = formattedDate;
                        localStorage.setItem("LoanDate", loan_date);

                        matchCheck(lenderArray, loan_date);
                    }
                    else {
                        setTimeout(() => {
                            setshowLoad("hide");
                            SetLoandateDiv("show");
                        }, 3000);
                    }
                })();
            } else {
                localStorage.setItem("LoanDate", enteredLoanDate);
                matchCheck(lenderArray, enteredLoanDate);
            }
        }
    }

    useEffect(() => {
        getUnqualifedTaxdata();
    }, []);


    // Loan date For TCC CHECK NOT EQUAL TO MONEYBARN

    const nextSlide = async (e) => {

        let yearresult = await trigger("YearOfPossession");
        let monthresult = await trigger("MonthOfPossession");

        if (!yearresult || !monthresult) {
            return false;
        }
        else {
            GtmDataLayer({
                question: "time of loan",
            });
            setshowLoad("show");
            SetLoandateDiv("hide");
            saveInputAns(e);
        }
    };

    const storeQuestionnire = async (question_data) => {
        const response = await SaveQuestionnaire(
            visitorParameters.visitor_parameters,
            question_data,
            "question_store",
            visitorParameters.data,
            query_string,
            formdata
        );
    };
    const saveInputAns = async (e) => {
        let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
        let question_data = {};
        const formDataQuest = getValues();

        let yearSelected = formDataQuest.YearOfPossession.concat(" ", formDataQuest.MonthOfPossession);;
        let flage = false;
        if (selectedQuest == '65' && yearSelected) {
            flage = true;
            question_data = {
                question_id: selectedQuest,
                option_id: null,
                answer_text: "",
                input_answer: yearSelected,
            };

        }
        loan_date = yearSelected;
        localStorage.setItem("LoanDate", loan_date);
        if (flage) {
            SetLoandateDiv("hide");
            storeQuestionnire(question_data);
            var existingEntries = JSON.parse(localStorage.getItem("questionData"));
            if (existingEntries == null) existingEntries = [];
            localStorage.setItem(
                "currentquestionData",
                JSON.stringify(question_data)
            );
            existingEntries.push(question_data);
            localStorage.setItem("questionData", JSON.stringify(existingEntries));
        }
        matchCheck(User_bud_lender, loan_date);
    };

    const matchCheck = async (bud_lender, loan_date) => {
        let matchCheck = await getCustomerCheck({ uuid: uuid }, user_id, loan_date, bud_lender);

        if (matchCheck.data.status === "success") {
            let budLender = matchCheck.data.provider;
            localStorage.setItem('bud_lender', budLender);
            localStorage.setItem("MatchStatus", 1);
            setTimeout(() => {
                history.push(
                    "/CheckListGN_4?uuid=" + uuid
                );
            }, 3000);
        } else {
            localStorage.setItem("MatchStatus", 0);
            setTimeout(() => {
                history.push(
                    "/CheckListGN_4?uuid=" + uuid
                );
            }, 3000);
        }
    }






    return (
        <>
            <GetVisitorsParams />
            <div className="AnalyseMB_GN_NEW">
                <div className={`splashbg ${showLoad}`}>
                    <div className="wrap-loader">
                        <div className="logo text-center pt-lg-3 pt-md-3 pt-sm-4 pt-4">
                            {" "}
                            <img src={tms_logo} alt="" />
                        </div>
                        <div className={`panel-banner ${showLoad}`}>
                            <div className="loader-panel align-items-center h-100">
                                <div className=" text-center">
                                    <div className="p-4 loader ">
                                        <div className="mb-4">
                                            <img src={loader_img} alt="" />
                                        </div>
                                        <p className="loader-text1">{loaderContent}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={`${loandateDiv}`}>
                    <div className="wrap-loader">
                        <div className="logo text-center pt-lg-3 pt-md-3 pt-sm-4 pt-4">
                            {" "}
                            <img src={tms_logo} alt="" />
                        </div>
                        <div className="container">
                            <div className="col-lg-12  col-md-12  col-12 nopad mt-5 d-flex justify-content-center">
                                <form
                                    ref={splitForm}
                                    name="split_form"
                                    id="user_form"
                                    method="POST"
                                    autoComplete="off"
                                >
                                    <div className="pt-lg-2 pt-md-3 pt-sm-3  pt-3 text-center">
                                        <h3 className="mt-md-2 mt-sm-2 mt-0 text_red_3a">
                                            We were unable to locate your loan with Moneybarn.{" "}
                                        </h3>
                                        <h3 className=" my-4 mt-md-2 mt-sm-2 mt-0 text_red_2a ">
                                            Please confirm the approximate date you took this loan out.{" "}
                                        </h3>
                                    </div>
                                    <div className="input-section inX6gs_update mb-3">
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="mb-3  col-12 p-0">
                                                        <SelectBox
                                                            className="form-select form-control form-select-lg"
                                                            OptionValue={PossessionMonth}
                                                            name="MonthOfPossession"
                                                            id="MonthOfPossession"
                                                            onChange={() => clearErrors("MonthOfPossession")}
                                                            clearErrors={clearErrors}
                                                            myRef={register({ required: "Please Select Month" })}
                                                        ></SelectBox>
                                                        <span className="error_msg">{errors.MonthOfPossession &&
                                                            errors.MonthOfPossession.message}</span>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="mb-3  col-12 p-0">
                                                        <SelectBox
                                                            className="form-select form-control form-select-lg"
                                                            OptionValue={YearPossession}
                                                            name="YearOfPossession"
                                                            id="YearOfPossession"
                                                            onChange={() => clearErrors("YearOfPossession")}
                                                            clearErrors={clearErrors}
                                                            myRef={register({ required: "Please Select Year" })}
                                                        >
                                                        </SelectBox>
                                                        <span className="error_msg">{errors.YearOfPossession &&
                                                            errors.YearOfPossession.message}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className=" col-lg-12 mb-pd text-center ">
                                                <div className="mb-1">
                                                    <button
                                                        type="button"
                                                        className="btn-next option_but radio-button"
                                                        onClick={nextSlide}
                                                        data-quest-id={65}
                                                        data-ans-id={null}
                                                        data-ans-label={null}
                                                        name="btn_qest_10"
                                                    >{`NEXT`}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
};

export default AnalyseMB_UFGN_4;