import React, { useState, useContext } from "react";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";
//import TextField from "../../../../UI/TextField";
import { useIdUpload } from "../../../../Hooks/useIdUpload";
import { useSkippedLeads } from '../../../../Hooks/useSkippedLeads';
import { queryString } from "../../../../Utility/QueryString";
import { useOpenBanking } from "../../../../Hooks/useOpenBanking";
import InputButton from "../../../UI/InputButton";

const BankStmtUploadV2 = ({
    reff,
    display,
    split_name,
    allFollowupData,
    className,
    loader,
    userDetails,
    removeFromPending
}) => {
    const history = useHistory();
    const { skippedLeads, skippedLeadsFLP } = useSkippedLeads();
    const { saveFLPIdUpload, isLoading } = useIdUpload();
    const query_string = localStorage.getItem("querystring");
    const { visitorParameters } = useContext(VisitorParamsContext);
    const formParameters = JSON.parse(localStorage.getItem("formData"));
    const [selectedFile, setSelectedFile] = useState("");
    const [selectedFileType, setSelectedFileType] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isInValidfFile, setIsInValidfFile] = useState(false);
    const [base64Image, setBase64Image] = useState();
    const [errorMessage, setErrorMessage] = useState("");
    const [document, setDocument] = useState(false);
    const [file, setFile] = useState("");
    const [activeClass, setActiveClass] = useState("disabled");
    const [btnDisabled, SetBtnDisabled] = useState(true);
    const [skipActiveClass, setSkipActiveClass] = useState("disabled");
    const { milestoneChase } = useOpenBanking();
    const agreementDate = localStorage?.getItem("agreementDate");

    const parsedDate = new Date(agreementDate);
    const formattedDate = parsedDate.toLocaleString('default', { month: 'long', year: 'numeric' });

    // Subtract 3 months
    const newDate = new Date(formattedDate);
    newDate.setMonth(newDate.getMonth() - 2);

    // Format the new date as "MMMM yyyy"
    const MothsBefore = newDate.toLocaleString('default', { month: 'long', year: 'numeric' });

    const userfile = {
        preview: undefined,
        title: undefined,
    };

    const changeCameraHandler = (event) => {
        setActiveClass("camera");
        SetBtnDisabled(false);
        setSelectedFile(event.target.files[0]);
        let imageFile = event.target.files[0];
        if (imageFile) {
            // if (selectedFile) {

            setIsFilePicked(true);
            let extension = event.target.files[0].type;
            let imgsize = event.target.files[0].size;
            let allowedExtensions = ["image/jpeg", "image/png"];
            if (allowedExtensions.indexOf(extension) === -1) {
                setIsInValidfFile(true);
                setErrorMessage(
                    "The uploaded Doc type should be an image in jpeg,png or jpg"
                );
            } else if (imgsize > 1024 * 1024 * 20) {
                setIsInValidfFile(true);
                setErrorMessage("Please choose a file less than 20mb");
            } else {
                setSelectedFileType(event.target.name);
                setIsInValidfFile(false);
            }

            userfile.preview = URL.createObjectURL(imageFile);
            userfile.title = imageFile.name;

            if (imageFile) {
                let reader = new FileReader();
                reader.onload = _handleReaderLoaded.bind(this);
                reader.readAsDataURL(imageFile);
            }
        } else {
            setIsFilePicked(false);
            userfile.preview = undefined;
            userfile.title = undefined;
        }
    };

    const changeFilesHandler = (event) => {
        setActiveClass("fileupload");
        SetBtnDisabled(false);
        setSelectedFile(event.target.files[0]);
        let imageFile = event.target.files[0];
        if (imageFile) {
            setIsFilePicked(true);
            let extension = event.target.files[0].type;
            let imgsize = event.target.files[0].size;
            let allowedExtensions = [
                "image/jpeg",
                "image/png",
                "application/zip",
                "application/msword",
                "application/pdf",
            ];
            if (allowedExtensions.indexOf(extension) === -1) {
                setIsInValidfFile(true);
                setErrorMessage(
                    "The uploaded Doc type should be an image in jpeg,png,jpg,pdf,doc, or docx"
                );
            } else if (imgsize > 1024 * 1024 * 20) {
                setIsInValidfFile(true);
                setErrorMessage("Please choose a file less than 20mb");
            } else {
                setSelectedFileType(event.target.name);
                setIsInValidfFile(false);
                //_handleFileRead.bind(event.target.files[0]);
            }
            setDocument(false);

            if ("nationalIDCard_doc" == event.target.name) {
                setDocument(true);
            }

            userfile.preview = URL.createObjectURL(imageFile);
            userfile.title = imageFile.name;

            if (imageFile) {
                let reader = new FileReader();
                reader.onload = _handleReaderLoaded.bind(this);
                reader.readAsDataURL(imageFile);
            }
        } else {
            setIsFilePicked(false);
            userfile.preview = undefined;
            userfile.title = undefined;
        }
    };

    const onSelectOption = (e) => {
        console.log("Option selected:", e.target.name);
        setIsInValidfFile(false);
        SetBtnDisabled(false);
        if (e.target.name == "FinanceAgreementDocEmail") {
            setSelectedFile(undefined);
            setActiveClass("email");
        } else if (e.target.name == "FinanceAgreementDocSkip") {
            setSelectedFile(undefined);
            setActiveClass("skip");
        } else if (e.target.name == "closefile") {
            setActiveClass('disabled');
            SetBtnDisabled(true);
            setSelectedFile(undefined);
        }
    };

    const _handleReaderLoaded = (readerEvt) => {
        let binaryString = readerEvt.target.result;
        setBase64Image(binaryString);
    };

    const onProceed = async (e) => {
        if (activeClass == "fileupload" || activeClass == "camera") {
            savedocument(e);
        } else if (activeClass == "email") {
            SkipSupportDocs("OB_FLP_EmailDoc");
        } else if (activeClass == "skip") {
            SkipSupportDocs("SkipBankStmtCheck");
        } else {
            console.log("exit");
            setIsInValidfFile(true);
            setErrorMessage("Please choose an option");
        }
    }

    const SkipSupportDocs = async (type) => {
        SetBtnDisabled(true);
        setActiveClass('disabled');
        const skippedLeadsResponse = await skippedLeadsFLP(type, allFollowupData, split_name);
        if (skippedLeadsResponse.data.status == "Success") {
            localStorage.setItem('email_doc', 1);

            removeFromPending('bankStmtUpload');
            // setShow(false);
            // setPendingStatus({ status_btn: 'pending_question', status_flag: 'showTick' })
        }
    }

    const savedocument = async (e) => {
        if (selectedFile) {
            setIsInValidfFile(false);
            try {
                let uploadData = '';
                uploadData = {
                    imageFile: base64Image,
                    captureType: selectedFileType.substring(
                        selectedFileType.indexOf("doc")
                    ),
                    documentType: "BankStmt"
                };

                const response = saveFLPIdUpload(
                    uploadData,
                    allFollowupData,
                    queryString,
                    'flp_support_docs_store'
                );
                removeFromPending('bankStmtUpload');
            } catch (e) {
                console.warn("error");
                console.warn(e);
                setIsInValidfFile(true);
                setErrorMessage("Please try again");
            }
        } else {
            setIsInValidfFile(true);
            setErrorMessage("Please choose an option");
        }
    };

    return (
        <>
            <div className={`bg-main-pr `} style={{ height: 'auto' }}>

                {/* Loader component (commented out for brevity) */}
                <div className={`main text-center main-div`}>

                    <div className="mt-val">
                        <div className="container">
                            <div className="item-cent-flx">
                                <div className="slide05">
                                    <div className="pt-md-3 pt-sm-3 pt-3 text-center">
                                        <div className="col-lg-8 offset-lg-2 px-4 mb-2 text-center ">
                                            <h1 className="mb-4 mt-md-2 mt-sm-2 mt-0 h4">
                                                Please upload your Bank statements
                                            </h1>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-6 offset-lg-3">
                                                <div className="slot-filed mb-3 anim_ylw">
                                                    <div className={`d-flex align-items-center ${activeClass == 'camera' && 'activeclass'}`}>
                                                        <input
                                                            type="file"
                                                            id="FinanceAgreementDoc"
                                                            name="FinanceAgreementDoc"
                                                            onChange={changeFilesHandler}
                                                            onClick={(e) => (e.target.value = null)}
                                                        />
                                                        <div className="icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
                                                                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                                                                <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                                                            </svg>
                                                        </div>
                                                        <div className="ps-3">Click here to Upload files from your device</div>
                                                    </div>
                                                </div>
                                                {selectedFile && (
                                                    <div className="mb-3">
                                                        <p>{selectedFile.name}</p>
                                                        <button className="btn btn-secondary btn-sm" name="closefile" onClick={onSelectOption}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                )}
                                                <div className="slot-filed slot-email mb-3">
                                                    <div className={`d-flex align-items-center ${activeClass == 'email' && 'activeclass'}`}>
                                                        <input
                                                            type="radio"
                                                            className="take_picture"
                                                            name="FinanceAgreementDocEmail"
                                                            onClick={onSelectOption}
                                                        />
                                                        <div className="icon">
                                                            <i className="bi bi-envelope-arrow-up"></i>
                                                        </div>
                                                        <div className="ps-3">Email file to "documents@themoneyslicitor.com"</div>
                                                    </div>
                                                </div>
                                                {/* <div> */}
                                                {/* <div className={`com-btn skip-btn w-100 ${activeClass == 'skip' && 'activeclass'}`}>
                                                         &nbsp; Skip for Now
                                                            <button
                                                                type="radio"
                                                                className="take_picture"
                                                                name="FinanceAgreementDocSkip"
                                                                onClick={onSelectOption}
                                                            />
                                                        </div> */}
                                                {/* </div> */}


                                                <button
                                                    className={`com-btn skip-btn w-100 ${activeClass == 'skip' && 'activeclass'}`}
                                                    type="radio"
                                                    name="FinanceAgreementDocSkip"
                                                    onClick={onSelectOption}
                                                >
                                                    &nbsp; 
                                                </button>

                                                <InputButton
                                                        name="FinanceAgreementDocSkip"
                                                        className={`com-btn skip-btn w-100 ${activeClass == 'skip' && 'activeclass'}`}
                                                        value="Skip for Now"
                                                        btnType="button"
                                                        onClick={onSelectOption}
                                                        style={{ float: "none" }}
                                                        />


                                            </div>
                                        </div>

                                        {isInValidfFile && <p className="error-msg">{errorMessage}</p>}
                                        <div className="row mt-md-2 mt-sm-3 mt-0">
                                            <div className="col-lg-6 offset-lg-3 mb-2 text-center">
                                                {/* {selectedFile && (
                                                        <div>
                                                            <p>{selectedFile.name}</p>
                                                            <button className="btn btn-secondary btn-sm" name="closefile" onClick={onSelectOption}>
                                                                Remove
                                                            </button>
                                                        </div>
                                                    )} */}
                                                <div>

                                                    <InputButton
                                                        name="btn_qest_45"
                                                        className={`com-btn w-100 btn-next next-5 ${activeClass === 'disabled' ? 'disabledclass' : ''}`}
                                                        value="Proceed"
                                                        btnType="button"
                                                        onClick={onProceed}
                                                        style={{ float: "none" }}
                                                        />


                                                </div>
                                                <p className="fs-6 mt-2">
                                                    For your convenience, copies of supporting documents can be emailed "documents@themoneysolicitors.com"
                                                </p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-lg-2 mb-md-5 mb-sm-3 mb-3 mt-lg-4  mt-md-2 mt-sm-2">
                        <img height="50" src="assets/OpenBankingV2/img/safe and secure.png" alt="" />
                    </div>
                </div>

            </div>


        </>
    );
};

export default BankStmtUploadV2;