import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

const IsStillActive = ({ onselectAnswer, reff, display, trigger,lender }) => {

  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>Is your account with {""} {lender} still active?</h3>
          <RadioButtonQuest
            key="YES"
            className="radio-button"
            labelName="Yes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="95"
            name="question_26"
            id="ten"
            dataQuestId={26}
            dataAnsId={95}
            dataAnsLabel="Yes"
          />
          <RadioButtonQuest
            key="NO"
            className="radio-button"
            labelName="NO"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="NO"
            name="question_26"
            id="tentwo"
            dataQuestId={26}
            dataAnsId={96}
            dataAnsLabel="No"
          />
        </div>
      </div>
    </>
  );
};

export default IsStillActive;
