import React,{useState,useEffect} from "react";
import TextField from "../../../UI/TextField";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import InputButton from "../../../UI/InputButton";
const AgreementNoModal = ({
    validation,
  validationMsg,
  trigger, 
  agNoShow,
  saveInputAns,
  skipAgNo,
  setAgNoShow
}) => {
    const [htmlState, setHtmlState] = useState('');
    const agreementValidation = async (e) => {
        let agreement_no = await trigger("question_45");
        if (agreement_no) {
        saveInputAns(e);
        // setAgNoShow('hide');
        } else {
        return false;
        }
    };
    
    const skip_agree_no = async (e) => {
        skipAgNo(e);   
    };
    useEffect(() => {
        if (agNoShow == 'show' ) {
            setHtmlState('<div class="modal-backdrop fade show"></div>')
        } else {
            setHtmlState('')
        }
    }, [agNoShow]);

    return(
        <>
        <div className={`modal fade ${agNoShow}`} id="CredCardAgreement" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-body">
        <div className="row align-items-center text-center">
          <h5>Enter your Credit Agreement Number</h5>     
          <div className="input-group mb-3 justify-content-center">
            <div className="mb-3 mt-3 col-12 p-0">
              <div className="input-group mb-3">
                <TextField
                        type="tel"
                        className="form-control p-3"
                        placeholder="Enter your Credit Agreement Number"
                        // name="question_51"
                        name="question_45"
                        validation={validation({
                          required: "Please Enter Agreement Number",
                          minLength: {
                            value: 3,
                            message: "Please Enter Valid Agreement Number",
                          },
                          pattern: {
                            value: /^\d*[1-9]\d*$/,
                            message:
                              "Agreement Number is Invalid. Please Recheck",
                          },
                        })}
                        validationMsg={
                          validationMsg.question_45 &&
                          validationMsg.question_45.message
                        }
                      ></TextField>
              </div>
              <button
                      name="agreement_no"
                      className="btn-comm2 w-100"
                      id=""
                      value="Next"
                      type="button"
                      onClick={agreementValidation}
                      data-quest-id = "45"
                    >Next
                    </button>
            </div>
            <p className="text-center">
                    <button
                      name="skip_agree_no"
                      className="btn btn-link"
                      id=""
                      value=""
                      type="button"
                      onClick={skip_agree_no}
              
                    >SKIP for now and follow up ASAP</button>
                    </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div dangerouslySetInnerHTML={{ __html: htmlState }} />

        </>
    )
}
export default AgreementNoModal;