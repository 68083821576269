import { Api } from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const useFollowupLoad = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const loadFollowup = async (
    message_type,
    followup_data,
    page_name,
    query_string,
    type
  ) => {
    const FollowupLoad = await Api.post("/api/v1/data-ingestion-pipeline", {
      message_type,
      followup_data,
      page_name,
      query_string,
      type,
    }).catch(function (error) {
      const erroMessage = {
        serviceName : 'Data Ingestion : '+message_type,
        errorReason:error.message,
        errorCode:error.code,
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return FollowupLoad;
  };
  return {
    loadFollowup,
  };
};
