import "../../assets/css/FollowupNDY.scss";

import { useContext, useEffect, useState } from "react";
import Loader from "../Includes/Layouts/FollowupNDY/Loader";
import SignatureForm from "../Includes/Layouts/FollowupNDY/SignatureForm";
import { Api } from "../../api/Api";
import { queryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useFollowupLoad } from "../../Hooks/useFollowupLoad";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import NewDayV2QuestionForm from "../Includes/Layouts/FollowupNDY/NewDayV2QuestionForm";
import IdFollowup from "../Includes/Layouts/FollowupNDY/IdFollowup";
import NewDayQuestionForm from "../Includes/Layouts/FollowupNDY/NewDayQuestionForm";
import AnalyzeModal from "../Includes/Layouts/FollowupNDY/AnalayzeModal";


export default function FollowupNDY() {
    const history = useHistory();
    const { loadFollowup } = useFollowupLoad();
    const { visitorParameters } = useContext(VisitorParamsContext);
    const [showLoad, setShowLoad] = useState('show');
    const [signDiv, setSignDiv] = useState(false);
    const [FollowupData, setFollowupData] = useState({});
    const [splitName, setSplitName] = useState('');
    const [userData, setUserData] = useState({});
    const [userUuid, setUserUuid] = useState('');
    const [t2aApiReqData, setT2aApiReqData] = useState({});
    const [t2aStatus, setT2aStatus] = useState("");
    const [qstnDiv, setQstnDiv] = useState(false);
    const [idUploadFlp, setIdUploadFlp] = useState(false);
    const [pendingQuestionnaire, setPendingQuestionnaire] = useState([]);
    const [newDay, setNewDay] = useState(false);
    const [newDayData, setNewDayData] = useState();
    const [previewPage, setPreviewPage] =useState("thankyou");
    const [analyze,setAnalyze ] = useState(false);
    const [newDayTwo, setNewDayTwo] = useState(false);


    useEffect(() => {
        getFollowupData();
    }, [])

    const setDivShow = (page, split_name, uuid) => {
        if (page == "complete") {
            history.push("/thankyou?uuid=" + uuid);
        }

        if (page == "signature") {
            showSigForm();
        } else if (page == "question") {
            setSignDiv(false);
            showQuestion();
        } else if (page == "idUpload") {
            setQstnDiv(false);
            setIdUploadFlp(true);
        } else {
            history.push("/thankyou?uuid=" + uuid);
        }
    };
    const getFollowupData = async () => {
        const response = await Api.get(
            `/api/v1/followup/get-pending-details-ndy?${queryString}`,
            {}
        );
        if (response.data.status === "Success") {
            let followupDetails = response.data.response.followup_data;
            let uuid = response.data.response.uuid;
            let completed = response.data.response.completed;
            let signStatus = response.data.response.signed_status;
            let slidePending = response.data.response.qstn_slides;
            let pendingLength = slidePending?.length;
            let qstnStatus = response.data.response.question_status;
            let split_name = response.data.response.split_name;
            let fname = response.data.response.first_name;
            let lname = response.data.response.last_name;
            let postcode = response.data.response.postcode;
            let address_line1 = response.data.response.address_line1;
            let t2a_result = response.data.response.t2a_status;
            let dob_day = response.data.response.dob_day;
            let dob_month = response.data.response.dob_month;
            let dob_yr = response.data.response.dob_yr;
            let page = "complete";
            let loan_yr = response.data.response.loan_year;
            let email = response.data.response.email;
            let lender = response.data.response.lender;
            let address_line2 = response.data.response.address_line2;
            let town = response.data.response.town;
            let country = response.data.response.country;
            let title = response.data.response.title;
            let addressLine3 = response.data.response.address_line3;
            let addressLine4 = response.data.response.address_line4;
            let county = response.data.response.county;
            let user_name = response.data.response.user_name;
            let sign_img = response.data.response.signature;

            localStorage.setItem("t2a_result", t2a_result);
            localStorage.setItem('allFollowupData', JSON.stringify(followupDetails));
            localStorage.setItem("uuid", uuid);

            visitorParameters.email = email || false;


            setT2aApiReqData({
                uuid: uuid,
                fName: fname,
                lName: lname,
                pCode: postcode,
                addressLine1: address_line1,
                day: dob_day,
                month: dob_month,
                year: dob_yr,
                addressLine2: address_line2,
                postTown: town,
                country: country,
                title: title,
                addressLine3: addressLine3,
                addressLine4: addressLine4,
                county: county
            });

            setNewDayData({
                loan_year: loan_yr,
                email: email,
                lender: lender
            })

            setUserData({
                loan_year: loan_yr,
                email: email,
                lender: lender,
                user_name: user_name,
                signImg: sign_img,
                signStatus: signStatus
            })

            setT2aStatus(t2a_result);
            setFollowupData(followupDetails);
            setSplitName(split_name);
            setPendingQuestionnaire(slidePending);
            setUserUuid(uuid);


            if (completed == 1) {
                page = "complete";
            } else {
                page = "signature";
            }

            if (signStatus == 1) {
                localStorage.setItem('signature_preview', 1);
            }

            setTimeout(() => {
                setDivShow(page, split_name, uuid);
                setShowLoad("hide");
            }, 2000);

            const flpResponse = await loadFollowup(
                "followup_load",
                followupDetails,
                split_name,
                queryString,
                "FlpNdy"
            );
        } else {
            history.push("/thankyou?uuid=" + response.data.response.uuid);
            return false;
        }
    }


    const showSigForm = () => {
        setSignDiv(true);
    }

    const showQuestion = (split_name) => {
      setSignDiv(false);
      setQstnDiv(true);
      setPreviewPage("preview_flp_v7");
      if (splitName == "NDY_UB_1") {
        setNewDay(true);
      }
      if (splitName == "NDY_UB_2" || splitName == "NDY_OB_UB_2_1"){
        setNewDayTwo(true);
      }
    };

    const setLoader = (e) => {
        setShowLoad("show");
    };

    const hideLoader = (e) => {
        setShowLoad("hide");
    };

    return (
      <>
        <div className="FollowupNDY">
          <Loader showLoad={showLoad} />

          {signDiv && (
            <SignatureForm
              allFollowupData={FollowupData}
              showQuestion={showQuestion}
              split_name={splitName}
              splitData={userData}
              uuid={userUuid}
            />
          )}

          {newDay && qstnDiv && (
            <NewDayQuestionForm
              allFollowupData={FollowupData}
              pendingQuestionnaire={pendingQuestionnaire}
              setLoader={setLoader}
              hideLoader={hideLoader}
              currentUuid={userUuid}
              t2aApiReqData={t2aApiReqData}
              setPendingQuestionnaire={setPendingQuestionnaire}
              t2aStatus={t2aStatus}
              setDivShow={setDivShow}
              splitName={splitName}
              newDayData={newDayData}
              setAnalyze={setAnalyze}
            />
          )}
          {newDayTwo && qstnDiv  && (
            <NewDayV2QuestionForm
              allFollowupData={FollowupData}
              pendingQuestionnaire={pendingQuestionnaire}
              setLoader={setLoader}
              hideLoader={hideLoader}
              currentUuid={userUuid}
              t2aApiReqData={t2aApiReqData}
              setPendingQuestionnaire={setPendingQuestionnaire}
              t2aStatus={t2aStatus}
              setDivShow={setDivShow}
              splitName={splitName}
              newDayData={newDayData}
              setAnalyze={setAnalyze}
            />
          )}
        </div>
        {idUploadFlp && (
          <IdFollowup
            allFollowupData={FollowupData}
            currentUuid={userUuid}
            previewPage={previewPage}
          />
        )}
        <AnalyzeModal modalClass={analyze} />
      </>
    );
}
