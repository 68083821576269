import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import TextField from "../../../UI/TextField";

const Questionnaire32 = ({
  clsName,
  reff,
  saveInputAns,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  
}) => {
  const selectClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();
    let question44Result = await trigger("question_44");
    if (!question44Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag === 0) {
    
      saveInputAns(e);
    } 
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>Please enter your Privately Registered Plate</h3>
        <div className="input-group mb-3">
          <TextField
            type="text"
            className="form-control"
            placeholder="Please enter your Privately Registered Plate"
            name="question_44"
            maxlength="12"
            // onChange={handleChange}
            validation={validation({
              required: "Please enter your Privately Registered Plate",
              // pattern: {
              //   value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/,
              //   message: "Your Privately Registered Plate is Invalid. Please Recheck",
              // },
            })}
            validationMsg={
              validationMsg.question_44 && validationMsg.question_44.message
            }
          ></TextField>
        </div>
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button 
						type="button" 
						className="btn-next" 
						onClick={selectClick} 
						data-quest-id={44} 
						data-ans-id={null}
						data-ans-label={null}
						name="btn_qest_44"
						>
            Next
          </button>
        </div>
      </div>
    </>
  );
};
export default Questionnaire32;