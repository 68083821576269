import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import InputButton from "../../../UI/InputButton";
const Deed = ({
  className,
  slideChange,
  textHeadingLine1,
  validation,
  backClick,
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const [claimModal, setshowClaimModal] = useState(false);
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  
  const handleShowFirst = () => setShowFirstModal(true);
  const handleCloseFirst = () => setShowFirstModal(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);
  const handleShowSecond = () => setShowSecondModal(true);
  const handleCloseSecond = () => setShowSecondModal(false);

  const nextSlide = async (e) => {
    if (e.target.value === "89") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      setshowClaimModal(true);
      //slideChange(e);
    } else {
      setCheckedQuestionOne("unchecked");
    }
    if (e.target.value === "90") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      handleShowFirst();
      setTimeout(() => {
        handleCloseFirst();
        handleShowSecond();
      }, 3000);
      setTimeout(() => {
        handleCloseSecond();
        slideChange(e);
      }, 6000);
    } else {
      setCheckedQuestionTwo("unchecked");
    }
  };
  return (
    <>
      <div id="slide-10" className={`${className}`}>
        <ProgressBar value="75" />
        <h3> {textHeadingLine1} </h3>
        <RadioButton
          value="90"
          name="question_23"
          className="radio-button"
          labelName="No"
          labelClassName={`option_but next5 ${checkedQuestionTwo}`}
          onClick={nextSlide}
          validation={validation()}
        />
        <RadioButton
          value="89"
          name="question_23"
          className="radio-button"
          labelName="Yes"
          labelClassName={`option_but next5 ${checkedQuestionOne}`}
          onClick={nextSlide}
          validation={validation()}
        />
        <p className="text-center">
          <span
            className="back3 back-btn btn-link"
            name="back10"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div>
      <Modal
        show={showFirstModal}
        onHide={handleCloseFirst}
        backdrop="static"
        keyboard={false}
        className="formmodal"
        contentClassName="modalcontent"
      >
        <Modal.Body className="modal-bordr">
          <div id="slide7" className="col-12 slide-pad text-center">
            <div className="form-group text-center" id="analize1">
              <h2>Analysing Your Results</h2>
              <img src="/assets/MB_UBRF_1/img/review.gif" alt="" />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* **** popup start **** */}
      <Modal
        show={showSecondModal}
        onHide={handleCloseSecond}
        backdrop="static"
        keyboard={false}
        className="formmodal"
        contentClassName="modalcontent"
      >
        <Modal.Body className="modal-bordr">
          <div id="slide7nxt" className="col-12 slide-pad text-center">
            <div className="form-group text-center" id="analize1">
              <h2>Good News! </h2>
              <p>
                We are able to assist you further. Please give us some back
                information. <br />
                (This will only take 1 minute)
              </p>
              <img src="/assets/MB_UBRF_1/img/review.gif" alt="" />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* **** cannot accept your claim popup start **** */}
      <Modal
        size="md"
        show={claimModal}
        onHide={handleCloseclaimModal}
        centered
        backdrop="static"
      >
        <Modal.Body>
          <h3 className="text-center fonts15">
            Sorry, we cannot accept your claim
          </h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="back01"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Deed;
