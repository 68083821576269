import React,{useState} from "react";
const LatePaymentLetters = ({
    clsName,
    onSelectAnswer,
    backClick,
    setlatePay
}) => {
    const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
    const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
    
    const optionSelected = async(e) => {
        if (e.target.value === "238") {
            setCheckedQuestionOne("checked");
            setCheckedQuestionTwo("unchecked");
            setlatePay("238");
            onSelectAnswer(e);
          }
          if (e.target.value === "239") {
            setCheckedQuestionOne("unchecked");
            setCheckedQuestionTwo("checked");
            setlatePay("239");
            onSelectAnswer(e);
          }
    }
    return (
        <>
        <div className={`question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3 ${clsName}`}>
                      <div className="row">
                        <div className="col-6">
                          <div className=" mb-2"> 
                            <button
                                type="button"
                                className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedQuestionOne}`}
                                onClick={optionSelected}
                                value="238"
                                name="late_payment_letter"
                                id="late_payment_letter"
                                data-quest-id={82}
                                data-ans-id={238}
                                data-ans-label={null}
                            >Yes
                            </button>
                            </div>
                        </div>
                        <div className="col-6">
                          <div className=" mb-2"> 
                            <button
                                type="button"
                                className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn ${checkedQuestionTwo}`}
                                onClick={optionSelected}
                                value="239"
                                name="late_payment_letter"
                                id="late_payment_letter"
                                data-quest-id={82}
                                data-ans-id={239}
                                data-ans-label={null}
                            >No
                            </button>
                            </div>
                        </div>
                      </div>
                      <p className="text-center bck-marwid">
                    <button
                      name="did_you_aware"
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>
                    </div>
        </>
    )
}
export default LatePaymentLetters;