import React, { useState } from "react";
import RadioButtonV2 from "./RadioButtonV2";
import { useFormContext } from "react-hook-form";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";


const Claim = (e) => {
  const { register, errors, clearErrors } = useFormContext();
  const [checkedOption, setCheckedOption] = useState("");
  const handleRadioButtonClick = (e, id, value) => {
    GtmDataLayer({
      question: "select product",
    });
    clearErrors(["productclaim"])
    setCheckedOption(id);
    const targetInput = document.getElementById("sign-div");
    if (targetInput) {
      targetInput.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  return (
    <div>
      <div className="mt-3">
        <h5 className="title mb-3">
          Which product would you like to register a claim against?
        </h5>
        <div className="row">
          <div className="col-lg-4 col-md-3 col-sm-6 col-12 mb-2">
            <div className="card-wrap text-center">
              <RadioButtonV2
                id="card1"
                name="productclaim"
                value="173"
                labelName="Aquacard"
                labelClassName="ms-2"
                className="d-flex card-item p-lg-2 p-md-2 p-sm-4 p-3 align-items-center"
                iclassName="bi bi-credit-card-2-back-fill"
                checked={checkedOption === "card1"}
                onClick={handleRadioButtonClick}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-3 col-sm-6 col-12 ps-lg-0 mb-2">
            <div className="card-wrap">
              <RadioButtonV2
                id="card2"
                name="productclaim"
                value="174"
                labelName="Marbles"
                labelClassName="ms-2"
                className="d-flex card-item  card2 p-lg-2 p-md-2 p-sm-4 p-3 align-items-center"
                iclassName="bi bi-credit-card-2-back-fill"
                checked={checkedOption === "card2"}
                onClick={handleRadioButtonClick}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-3 col-sm-6 col-12 ps-lg-0 mb-2">
            <div className="card-wrap">
              <RadioButtonV2
                id="card3"
                name="productclaim"
                value="178"
                labelName="Other"
                labelClassName="ms-2"
                className="d-flex card-item p-lg-2 p-md-2 p-sm-4 p-3 align-items-center"
                iclassName="bi bi-credit-card-2-back-fill"
                checked={checkedOption === "card3"}
                onClick={handleRadioButtonClick}
              />
            </div>
          </div>
        </div>
        {errors.productclaim && (
          <>
            <span className="error_msg">{errors.productclaim.message}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default Claim;
