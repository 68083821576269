import React from 'react';
import tickgif from "../../../../assets/QuestionaireMBGN_4/img/loader-review2.svg"


const LoaderTwo = ({ LoaderTwo }) => {
  return (
    <>

      <div
        className={`loader-wrap loader2 text-center text-light ${LoaderTwo}`}

      >
        <div className="loaderrdiv">
          <img height={80} src={tickgif} alt="" />
          <h6 className="mt-3 text">
            We may need some additional information to expedite your claims
            process.
          </h6>
        </div>
      </div>
    </>
  )
}

export default LoaderTwo;