import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const Questionnaire31 = ({
  clsName,
  reff,
  onselectAnswer,
}) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>Have you registered a Private Plate?</h3>
        <RadioButtonQuest
          key="YES"
          className="radio"
          labelName="Yes"
          labelClassName="radioa"
          labelCountString="a"
          onClick={labelclicked}
          value="YES"
          name="question_43"
          id="Yes1"
          dataQuestId={43}
          dataAnsId={121}
          dataAnsLabel="Yes"
        />
        <RadioButtonQuest
          key="NO"
          className="radio"
          labelName="No"
          labelClassName="radioa"
          labelCountString="b"
          onClick={labelclicked}
          value="NO"
          name="question_43"
          id="No1"
          dataQuestId={43}
          dataAnsId={122}
          dataAnsLabel="No"
        />
      </div>
    </>
  );
};
export default Questionnaire31;