import React, { useContext, useState } from "react";
// import { lenders } from "../../../../Constants/Lenders";
import RadioButton from "../../../UI/RadioButtonOutLabel";
import ProgressBar from "../../Layouts/Common/ProgressBar";
import InputButton from "../../../UI/InputButton";
import Modal from "react-bootstrap/Modal";
import SignaturePopup from "../TMS_G_V3/SignaturePopup";

const Claim = ({
  className,
  slideChange,
  validation,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  slideChangeAddress,
  splitForm,
  backClick,
  setError,
  setPreviousName,
}) => {
  const [showSignature, setShowSignature] = useState("hide");

  return (
    <>
      <div id="slide-6" className={`slidetop_adjust ${className}`}>
        <ProgressBar value={85} />
        <h3 className="text-center">You're almost there ...</h3>
        <div className="col-lg-12 col-12">
          <InputButton
            name="signaturemodal"
            btnType="button"
            className="btn regNextBtn next10"
            id="formSubmitBtn"
            value="Start My Claim"
            style={{ float: "none" }}
            onClick={() => setShowSignature("show")}
          />
        </div>
        <p className="text-center">
          <span
            className="back-btn btn btn-link"
            name="back05"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
        <SignaturePopup
          show={showSignature}
          setShowSignature={setShowSignature}
          getValues={getValues}
        />
      </div>
    </>
  );
};
export default Claim;
