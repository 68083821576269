import React, { useState, useEffect, useReducer } from 'react';
import { answerArray, supportDocTypeArray } from "../../../Constants/Questions";
import { queryString } from "../../../Utility/QueryString";
import "../../../assets/css/followup.scss";
import InputButton from '../../UI/InputButton';
import SignatureModal from '../../Includes/Layouts/Followup/SignatureModal';
import { Api } from "../.././../api/Api";
import { useFollowupLoad } from "../../../Hooks/useFollowupLoad";
import QuestionsModal from "../../Includes/Layouts/Followup/QuestionsModal";
import Loader from "../../Includes/Layouts/Followup/Loader";
import { Link, useHistory } from "react-router-dom";
// import ReviewModalV1 from '../../Includes/Layouts/Followup/ReviewModalV1';
//import { CheckUUID } from "../../../Utility/CheckUUID";
import { useT2aValidation } from "../../../Hooks/useT2aValidation";
import { userMilestoneUpdate } from "../../../Hooks/userMilestoneUpdate";
import IdUploadModal from '../../Includes/Layouts/Followup/IdUploadModal';

var complete_arr = [];
const initialState = {
  showButton1: "hide",
  showButton2: "hide",
  showButton3: "hide",
  showButton4: "hide",
  showTick1: "hide",
  showTick2: "hide",
  showTick3: "hide",
  showTick4: "hide"
};
const FormReducer = (state, action) => {

  switch (action.type) {
    case "showButton": {
      if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showButton1: "show",
          showTick1: "hide",
        };
      } else if (action.payload.pendingStatus.status_btn == "pending_question") {
        return {
          ...state,
          showButton2: "show",
          showTick2: "hide",
        };
      } else if (action.payload.pendingStatus.status_btn == "review") {
        return {
          ...state,
          showButton3: "show",
          showTick3: "hide",
        };
      } else if (action.payload.pendingStatus.status_btn == "id_upload") {
        return {
          ...state,
          showButton4: "show",
          showTick3: "hide",
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
    case "showTick": {
      if (action.payload.pendingStatus.status_btn == "signature") {
        return {
          ...state,
          showTick1: "show",
          showButton1: "hide",
        };
      } else if (action.payload.pendingStatus.status_btn == "pending_question") {
        return {
          ...state,
          showTick2: "show",
          showButton2: "hide",
          showButton3: "show",
        };
      } else if (action.payload.pendingStatus.status_btn == "review") {
        return {
          ...state,
          showTick3: "show",
          showButton3: "hide",
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
    case "hideButton":{
      if (action.payload.pendingStatus.status_btn == "pending_question") {
        return {
          ...state,
          showTick2: "hide",
          showButton2: "hide",
        };
      }else if (action.payload.pendingStatus.status_btn == "review") {
        return {
          ...state,
          showTick3: "hide",
          showButton3: "hide",
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
  }
};
const Followup = (props) => {
  // const { isCheck} = CheckUUID();
  // isCheck();
  const [signatureClass, SetSignatureClass] = useState(false);
  const [pendingStatus, setPendingStatus] = useState([]);
  const [pendingQuestionnaire, setPendingQuestionnaire] = useState([]);
  const [showLoad, setshowLoad] = useState("show");
  const [showPage, setshowPage] = useState("hide");
  const [showUserName, setUserName] = useState("");
  const [showSpouseName, setSpouseName] = useState("");
  const [partnerSignatureClass, SetPartnerSignatureClass] = useState(false);
  const [allFollowupData, setFollowupData] = useState();
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const { loadFollowup, isuseFollowupLoad } = useFollowupLoad();
  const [questionnaireClass, SetQuestionnaireClass] = useState(false);
  const [t2aStatus, setT2aStatus] = useState('');
  const [pending, setPending] = useState(true);
  const [isJoint, setIsJoint] = useState('');
  const [followupStatus, setFollowupStatus] = useState('Pending');
  const [arrowStatus, setArrowStatus] = useState('hide');
  const [arrowStatusJoint, setArrowStatusJoint] = useState('hide');
  const [currentUuid, setCurrentUuid] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(0);
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const userToken = query.get("atp_sub2");
  const atpSub6 = (query.get("atp_sub6")) ? query.get("atp_sub6") : 'FLP';
  localStorage.setItem('user_token', userToken);
  localStorage.setItem('atp_sub6', atpSub6);
  const [userSign, setUserSign] = useState();
  const [t2aApiReqData, setT2aApiReqData] = useState({});
  const { getT2aValidation, saveFollowupT2aResponse } = useT2aValidation();
  const { updateMilestone } = userMilestoneUpdate();

  const [identityClass, SetIdentityClass] = useState(false);
  const [splitName, setSplitName] = useState();
  const [validKeeperDate, setValidKeeperDate] = useState("");
  const [userVehicle, setUserVehicle] = useState([]);
  const [userCompleted, setUserCompleted] = useState(false);
  const [isIdUpload, setIsIdUpload] = useState(false);
  const [keeperDates, setKeeperDates] = useState([]);

  const [userBank, setUserBank] = useState();
  const [skippedUser, setSkippedUser] = useState(null);
  const [documentTypeId, setDocumentTypeId] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [docType, setDocType] = useState("");



  const setLoader = (e) => {
    setshowLoad("show");
  };
  const hideLoader = (e) => {
    setshowLoad("hide");
    setshowPage("show");
  };
  const showSplash = (uuid) => {
    history.push('/thankyou?uuid=' + uuid + '&user_token=' + userToken + '&source=' + atpSub6);
  };
  const showThankYou = (uuid) => {
    history.push('/thankyou?uuid=' + uuid + '&user_token=' + userToken + '&source=' + atpSub6);
  };
  const storeT2aResponse = async (t2a_data) => {
    const response = await saveFollowupT2aResponse(
      allFollowupData,
      t2a_data,
      "t2a_followup_store",
      queryString,
      t2aApiReqData
    );
  };

  const sendT2aApiReqDataAPI = () => {
    const useruuid = t2aApiReqData.uuid;
    const fName = t2aApiReqData.fName;
    const lName = t2aApiReqData.lName;
    const pCode = t2aApiReqData.pCode;
    const addressLine1 = t2aApiReqData.addressLine1;
    const dobDay = t2aApiReqData.day;
    const dobMonth = t2aApiReqData.month;
    const dobYear = t2aApiReqData.year;

    if (useruuid && fName && lName && pCode && addressLine1 && t2aStatus == null) {
      (async () => {
        const response = await getT2aValidation(
          fName,
          lName,
          pCode,
          addressLine1,
          dobYear,
          dobMonth,
          dobDay
        );
        if (response.data.status == "success") {
          localStorage.setItem('t2a_result', 1);
          // Preiverw 
          // setPendingStatus({ status_btn: 'review', status_flag: 'showButton' });
          // setIsIdUpload(false);
        } else {
          localStorage.setItem('t2a_result', 0);
          // setIsIdUpload(true);
          // setPendingStatus({ status_btn: 'review', status_flag: 'showButton' });
          // ID Upload
          // setPendingStatus({ status_btn: 'id_upload', status_flag: 'showButton' });
          // SetIdentityClass(true);
        }
        storeT2aResponse(response.data);

      })();
    } else {
      localStorage.setItem('t2a_result', t2aStatus);
      let email_skip = localStorage.getItem('email_doc');
      if (useruuid && email_skip != 1){
        (async () => {
          const response = await updateMilestone(
            useruuid,
            t2aStatus,
            allFollowupData
          );
        })();

      }
    }
  }
  const sett2aValidation = () => {

    const useruuid = t2aApiReqData.uuid;
    const fName = t2aApiReqData.fName;
    const lName = t2aApiReqData.lName;
    const pCode = t2aApiReqData.pCode;
    const addressLine1 = t2aApiReqData.addressLine1;
    if (useruuid && fName && lName && pCode && addressLine1) {
      (async () => {
        const response = await getT2aValidation(
          fName,
          lName,
          pCode,
          addressLine1,
        );

        if (response.data.status == "success") {
          localStorage.setItem("t2aValidation", 1);
          history.push("/view_docs_flp?uuid=" + currentUuid);
        } else {
          localStorage.setItem("t2aValidation", 0);
          // window.scrollTo(0, 0);
          history.push("/id-upload?uuid=" + currentUuid);
        }
        window.scrollTo(0, 0);
        history.push("/view_docs_flp?uuid=" + currentUuid);
        storeT2aResponse(response.data);
      })();
    }
  }
  useEffect(() => {
    (async () => {
      const response = await Api.get("/api/v1/followup/get-pending-detailsV2?query_string=" + queryString, {}
      );
      if (response.data.status === "Success") {
        let followupDetails = response.data.response.followup_data;

        setFollowupData(followupDetails);
        setTimeout(() => {
          hideLoader();
        }, 2000);

        const flpResponse = await loadFollowup(
          "followup_load",
          followupDetails,
          "Split_1",
          queryString,
          "v2"
        )
        followupDetails = JSON.stringify(followupDetails)
        localStorage.setItem('followup_data', followupDetails);

        let uName = response.data.response.user_name;
        let signStatus = response.data.response.signed_status;
        let qstnStatus = response.data.response.question_status;
        let slidePending = response.data.response.qstn_slides;
        let user_uuid = response.data.response.uuid;
        let fname = response.data.response.first_name;
        let lname = response.data.response.last_name;
        let postcode = response.data.response.postcode;
        let address_line1 = response.data.response.address_line1;
        let t2a_result = response.data.response.t2a_status;
        let completed = response.data.response.completed;
        let keeper_dates = response.data.response.keeper_date;
        let vehicle = response.data.response.vehicle_name;
        let dob_day = response.data.response.dob_day;
        let dob_month = response.data.response.dob_month;
        let dob_yr = response.data.response.dob_yr;

        if (completed == 1) {
          history.push('/thankyou?uuid=' + user_uuid);
        }

        let pendingLength = slidePending.length;

        setT2aApiReqData({
          uuid: user_uuid,
          fName: fname,
          lName: lname,
          pCode: postcode,
          addressLine1: address_line1,
          day: dob_day,
          month: dob_month,
          year: dob_yr
        });

        setT2aStatus(t2a_result);
        setCurrentUuid(user_uuid);
        setPendingQuestionnaire(slidePending);
        setUserName(uName)
        setKeeperDates(keeper_dates);
        setUserVehicle(vehicle);

        if (signStatus == 0) {
          setPendingStatus({ status_btn: 'signature', status_flag: 'showButton' });
          setPendingStatus({ status_btn: 'pending_question', status_flag: 'hideButton' });
          setPendingStatus({ status_btn: 'review', status_flag: 'hideButton' });
          return false;
        } else {
          setPendingStatus({ status_btn: 'signature', status_flag: 'showTick' });
        }

        if (qstnStatus == 1 || pendingLength < 1) {
          setPendingStatus({ status_btn: 'pending_question', status_flag: 'showTick' });
          setPendingStatus({ status_btn: 'review', status_flag: 'showButton' });
          sendT2aApiReqDataAPI();
          return false;
        } else {
          setPendingStatus({ status_btn: 'pending_question', status_flag: 'showButton' });
          setPendingStatus({ status_btn: 'review', status_flag: 'hideButton' });
        }


      } else {
        history.push('/thankyou?uuid=' + response.data.response.uuid);
        return false;
      }

    })();
  }, []);

  const previewPage = () => {
    history.push('/preview_flp?uuid=' + currentUuid);
  }

  useEffect(() => {
    if (pendingStatus) {
      dispatch({ type: pendingStatus.status_flag, payload: { pendingStatus } });
    }
  }, [pendingStatus]);

  useEffect(() => {
    let cancel = false;
    if (userCompleted == 1) {
      history.push('/thankyou?uuid=' + currentUuid);
    }
    return () => {
      cancel = true;
    }
  }, [userCompleted]);
  return (
    <>
      <div className='FollowupContainer'>

        <div className='Followup'>
          <section className="questionspart">
            <div className="container">
              <div className="row">
                <div className="col-12 col-sm-12 l_bg">
                  <form>
                    <Loader showLoad={showLoad}></Loader>

                    <div id="myDiv2" className={`slide2 ${showPage}`} style={{ margin: "20px 0 0" }}>
                      <div className="blue-bg col-12">
                        <h4>Claim Status: {followupStatus}</h4>
                        <p>Almost done! We need a few more details to start processing the claim.</p>
                      </div>
                    </div>
                    <div className={`col-12 data-div mb20 animated fadeInUp ${showPage}`}>
                      <div className=" col-12 nopad">
                        <table className="table table-bordered">
                          <thead className="thead-light">
                            <tr>
                              <th className="align-middle" width="60%">Account Holder </th>
                              <th width="40%">{showUserName ? showUserName : null}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{ fontSize: "16px" }}>Signature</td>
                              {state && state.showButton1 && (
                                <td className={state ? `text-center align-middle blink_me  ${state.showButton1}` : `hide`}>
                                  <InputButton
                                    id=""
                                    value="Sign here"
                                    btnType="button"
                                    onClick={() => SetSignatureClass(true)}
                                    className="add-btn signbut"
                                  />
                                </td>
                              )}
                              {state && state.showTick1 && (
                                <td className={state ? `text-center align-middle signyes ${state.showTick1}` : `hide`}>
                                  <img
                                    src="/assets/Followup/img/y-but.png"
                                    alt=""
                                  />
                                </td>
                              )}
                            </tr>

                            <tr>
                              <td style={{ fontSize: "16px" }}>Questionnaire</td>
                              {state && state.showButton2 && (
                                <td className={state ? `text-center align-middle blink_me ${state.showButton2}` : `hide`}>
                                  <InputButton
                                    id=""
                                    value="Finish Answering"
                                    btnType="button"
                                    onClick={() => SetQuestionnaireClass(true)}
                                    className="add-btn qbut"
                                  />
                                </td>
                              )}
                              {state && state.showTick2 && (
                                <td className={state ? `text-center align-middle signyes ${state.showTick2}` : `hide`}>
                                  <img
                                    src="/assets/Followup/img/y-but.png"
                                    alt=""
                                  />
                                </td>
                              )}
                            </tr>

                            <tr style={{ borderBottom: "solid 4px #2f3996" }}>
                              <td style={{ fontSize: "16px" }}>Document Verification</td>

                              {state && state.showButton3 && (
                                <td className={state ? `text-center align-middle blink_me ${state.showButton3}` : `hide`}>
                                  <InputButton
                                    id=""
                                    value="Review"
                                    btnType="button"
                                    onClick={previewPage}
                                    className="add-btn josignbut"
                                  />
                                </td>
                              )}

                              {state && state.showTick3 && (
                                <td className={state ? `text-center align-middle signyes ${state.showTick3}` : `hide`}>
                                  &nbsp;55555
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>

        <SignatureModal
          popDisplay={signatureClass}
          allFollowupData={allFollowupData}
          setPendingStatus={setPendingStatus}
          setLoader={setLoader}
          hideLoader={hideLoader}
          showUserName={showUserName}
          setUserSign={setUserSign}
        />

        <QuestionsModal
          questionsPop={questionnaireClass}
          allFollowupData={allFollowupData}
          setPendingStatus={setPendingStatus}
          pendingQuestionnaire={pendingQuestionnaire}
          setLoader={setLoader}
          hideLoader={hideLoader}
          sendT2aApiReqDataAPI={sendT2aApiReqDataAPI}
          splitName={splitName}
          currentUuid={currentUuid}
          setValidKeeperDate={setValidKeeperDate}
          validKeeperDate={validKeeperDate}
          keeperDates={keeperDates}
          userVehicle={userVehicle}
          userBank={userBank}
          skippedUser={skippedUser}
          setPendingQuestionnaire={setPendingQuestionnaire}
          documentType={documentType}
          docType={docType}
          sett2aValidation={sett2aValidation}
        />

        {/* <ReviewModalV1
            questionsPop={reviewClass}
            setUserSign={setUserSign}
            userSign={userSign}
            queryString={queryString}
            currentUuid={currentUuid}
            isIdUpload={isIdUpload}
            SetIdentityClass={SetIdentityClass}
            SetReviewClass={SetReviewClass}
          /> */}

        <IdUploadModal
          idDisplay={identityClass}
          allFollowupData={allFollowupData}
          setPendingStatus={setPendingStatus}
          SetIdentityClass={SetIdentityClass}
          currentUuid={currentUuid}
          setUserCompleted={setUserCompleted}
        />

      </div>
    </>
  )
}

export default Followup;