import React, { useReducer, useState } from "react";
import Modal from "react-bootstrap/Modal";

const MoneybarnPopup = ({ show, setstate, lender }) => {
  const [showSlide1, setShowSlide1] = useState("show");
  const [showSlide2, setShowSlide2] = useState("hide");
  const [showSlide3, setShowSlide3] = useState("hide");
  const [showSlide4, setShowSlide4] = useState("hide");
  const [showSlide5, setShowSlide5] = useState("hide");
  const [showSlide6, setShowSlide6] = useState("hide");
  const [showSlide7, setShowSlide7] = useState("hide");

  return (
    <>
      <div
        className={`modal fade overlaybg ${show}`}
        id="exampleModal2"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-1a">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                {lender}
              </h4>
              {/* <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button> */}
            </div>
            <div className="modal-body text-center py-4">
              <div className={`modal-slide-3a ${showSlide1}`}>
                <h5> Have you made a claim against this lender previously</h5>
                <div className="my-0 my-md-3 radio-grp-modal d-flex flex-column flex-md-row justify-content-center px-5">
                  <div className="mx-2 pt-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-4a1"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide2("show");
                        setShowSlide1("hide");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-3a"
                      htmlFor="option-modal-4a1"
                    >
                      yes
                    </label>
                  </div>
                  <div className="mx-2 pt-3">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-4a2"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide2("show");
                        setShowSlide1("hide");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-3a"
                      htmlFor="option-modal-4a2"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className={`modal-slide-3b ${showSlide2}`}>
                <h5> Please select the year you took out the car finance</h5>
                <p>
                  Don't worry if you cannot remember - an estimate will suffice
                </p>
                <div className="row px-5 pb-2">
                  <div className=" px-4 my-2 col-md-6 m-auto">
                    <select className="form-select form-control">
                      <option value="">Select Year</option>
                      <option value="2022">2022</option>
                      <option value="2021">2021</option>
                      <option value="2020">2020</option>
                      <option value="2019">2019</option>
                      <option value="2018">2018</option>
                      <option value="2017">2017</option>
                      <option value="2016">2016</option>
                      <option value="2015">2015</option>
                      <option value="2014">2014</option>
                      <option value="2013">2013</option>
                      <option value="2012">2012</option>
                      <option value="2011">2011</option>
                      <option value="2010">2010</option>
                      <option value="2009">2009</option>
                      <option value="2008">2008</option>
                      <option value="2007">2007</option>
                      <option value="2006">2006</option>
                      <option value="2005">2005</option>
                      <option value="2004">2004</option>
                      <option value="2003">2003</option>
                      <option value="2002">2002</option>
                      <option value="2001">2001</option>
                      <option value="2000">2000</option>
                    </select>
                  </div>
                </div>
                <div className="mt-0">
                  <a
                    className="btn btn-primary  modal-nextbtn-3b"
                    htmlFor="option-modal-1a5"
                    onClick={() => {
                      setShowSlide2("hide");
                      setShowSlide3("show");
                    }}
                  >
                    NEXT
                  </a>
                </div>
              </div>

              <div className={`modal-slide-3c ${showSlide3}`}>
                <h5>
                  {" "}
                  <b> Loan Account Number </b>{" "}
                </h5>
                <p>
                  If you have your Car Finance Agreement number it will speed up
                  your claim. If you don't have it you can still continue.
                </p>
                <div className="px-0 px-md-5 mb-4">
                  <input
                    type="text"
                    className=" form-control"
                    id="exampleInputP1"
                    placeholder="Account Number"
                  />
                </div>
                <div className="mt-0">
                  <a
                    className="btn btn-primary  modal-nextbtn-3c"
                    htmlFor="option-modal-1a5"
                    onClick={() => {
                      setShowSlide3("hide");
                      setShowSlide4("show");
                    }}
                  >
                    NEXT
                  </a>
                </div>
              </div>

              <div className={`modal-slide-3d ${showSlide4}`}>
                <h5>
                  {" "}
                  What kind of employment were you in at the time your took the
                  car finance?{" "}
                </h5>

                <div className=" mt-3 radio-grp-modal row px-4">
                  <div className=" col-12 col-sm-4 p-0 my-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-4a3"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide4("hide");
                        setShowSlide5("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-3d"
                      htmlFor="option-modal-4a3"
                    >
                      Full Time
                    </label>
                  </div>
                  <div className="col-12 col-sm-4 p-0 my-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-4a4"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide4("hide");
                        setShowSlide5("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-3d"
                      htmlFor="option-modal-4a4"
                    >
                      Part Time
                    </label>
                  </div>
                  <div className="col-12 col-sm-4 p-0 my-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-4a5"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide4("hide");
                        setShowSlide5("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-3d"
                      htmlFor="option-modal-4a5"
                    >
                      Temporary
                    </label>
                  </div>
                  <div className="col-12 col-sm-4 p-0 my-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-4a6"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide4("hide");
                        setShowSlide5("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-3d"
                      htmlFor="option-modal-4a6"
                    >
                      Zero hours
                    </label>
                  </div>

                  <div className="col-12 col-sm-4 p-0 my-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-4a7"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide4("hide");
                        setShowSlide5("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-3d"
                      htmlFor="option-modal-4a7"
                    >
                      Self Employed
                    </label>
                  </div>

                  <div className="col-12 col-sm-4 p-0 my-2">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="option-modal-4a8"
                      autoComplete="off"
                      onClick={() => {
                        setShowSlide4("hide");
                        setShowSlide5("show");
                      }}
                    />
                    <label
                      className="btn btn-primary btn-1-radio-modal modal-nextbtn-3d"
                      htmlFor="option-modal-4a8"
                    >
                      Unemployed
                    </label>
                  </div>
                </div>
              </div>

              <div className={`modal-slide-3e ${showSlide5}`}>
                <h5>
                  {" "}
                  How many dependants did you have at the time you took the car
                  finance?{" "}
                </h5>
                <div className="row px-5 pb-3">
                  <div className=" px-4 my-2 col-md-8 m-auto">
                    <select className="form-select form-control">
                      <option value="">Select </option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                    </select>
                  </div>
                </div>
                <div className="mt-0">
                  <a
                    className="btn btn-primary  modal-nextbtn-3e"
                    htmlFor="option-modal-1a5"
                    onClick={() => {
                      setShowSlide5("hide");
                      setShowSlide6("show");
                    }}
                  >
                    NEXT
                  </a>
                </div>
              </div>

              <div className={`modal-slide-3f ${showSlide6}`}>
                <h5> Income and Expenditure Details </h5>
                <p>
                  {" "}
                  Please confirm the following MONTHLY income and expenditure
                  details as at the time of the car finance as accurately as
                  possible:-
                </p>
                <div className="px-5 ">
                  <div className="input-group flex-nowrap my-2">
                    <span className="input-group-text" id="addon-wrapping">
                      Monthly Income{" "}
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      aria-label="Income"
                      aria-describedby="addon-wrapping"
                    />
                  </div>

                  <div className="input-group flex-nowrap my-2">
                    <span className="input-group-text" id="addon-wrapping">
                      Mortgage/Rent
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      aria-label="Income"
                      aria-describedby="addon-wrapping"
                    />
                  </div>

                  <div className="input-group flex-nowrap my-2">
                    <span className="input-group-text" id="addon-wrapping">
                      Car Finance Payment{" "}
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      aria-label="Income"
                      aria-describedby="addon-wrapping"
                    />
                  </div>

                  <div className="input-group flex-nowrap my-2">
                    <span className="input-group-text" id="addon-wrapping">
                      Car Insurance{" "}
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      aria-label="Income"
                      aria-describedby="addon-wrapping"
                    />
                  </div>

                  <div className="input-group flex-nowrap my-2">
                    <span className="input-group-text" id="addon-wrapping">
                      Other Debt Payments
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      placeholder=""
                      aria-label="Income"
                      aria-describedby="addon-wrapping"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <a
                    className="btn btn-primary  modal-nextbtn-3f"
                    htmlFor="option-modal-1a5"
                    onClick={() => {
                      setShowSlide6("hide");
                      setShowSlide7("show");
                    }}
                  >
                    NEXT
                  </a>
                </div>
              </div>

              <div className={`modal-slide-3g ${showSlide7}`}>
                <h4 className="py-3">
                  {" "}
                  Please TICK AT LEAST ONE that applies to you{" "}
                </h4>

                <div className="form-check text-start">
                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck4a"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck4a"
                    />
                    I had to take out additional lending to pay for the car
                    finance to avoid it being repossessed
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck4b"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck4b"
                    />
                    The lender didn’t ask me any questions to confirm that I
                    would be able to repay the loan in a sustainable way
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck4c"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck4c"
                    />
                    I already had multiple other loans at the time I applied for
                    this loan
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck4d"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck4d"
                    />
                    I was in arrears with other lenders when I took the finance
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck4e"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck4e"
                    />
                    I was struggling to pay essentials (rent, bills, food)
                    because of my repayment
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck4f"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck4f"
                    />
                    I was in arrears with my household bills when I took the
                    finance
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck4g"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck4g"
                    />
                    My income changed between taking out and repaying the loan
                    but the lender didn’t check
                  </label>

                  <label
                    className="form-check-label px-3"
                    htmlFor="defaultCheck4h"
                  >
                    <input
                      className="form-check-input "
                      type="checkbox"
                      value=""
                      id="defaultCheck4h"
                    />
                    I regularly missed my monthly repayments
                  </label>
                </div>
                <div className="modal-footer-3a modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary rounded-0"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setstate("hide");
                      setShowSlide7("hide");
                      setShowSlide1("show");
                    }}
                  >
                    Close
                  </button>
                  <button
                    id="save-btn-modal5"
                    type="button"
                    className="btn btn-primary footer-btns"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setstate("hide");
                      setShowSlide7("hide");
                      setShowSlide1("show");
                    }}
                  >
                    Save changes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MoneybarnPopup;
