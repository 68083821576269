import React from "react";

const Section1 = () => {
  return (
    <>
      <section className="welcome-highlights py-lg-5 py-md-0 pb-lg-0 pb-md-5 pb-sm-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
              <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                <img src="assets/MB_UFGN_4/img/icon01.svg" alt="" />
                <h2 className="mt-3">  Get your Mis-sold Car Finance Fees and Charges Back</h2>
                <p>Many car finances were not sold properly. You could claim back the fees and charges plus 8% interest.

                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
              <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                <img src="assets/MB_UFGN_4/img/icon02.svg" alt="" />
                <h2 className="mt-3">Your Claim Fought by Financial & Legal Experts</h2>
                <p>Many loans were not sold properly. You could claim back the fees and charges plus 8% interest.</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
              <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                <img src="assets/MB_UFGN_4/img/icon03.svg" alt="" />
                <h2 className="mt-3">Respecting Your Information Security</h2>
                <p>We take security very seriously and follow the rules of the Data Protection Act</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section1;
