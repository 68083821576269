import { BudsApi, Api, ApiTimeDelay } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";
import { useErrorWebhook } from "./useErrorWebhook";


export const useOpenBanking = () => {

  const { ErrorWebhookTrigger } = useErrorWebhook();

  
  const getVisitorID = async (uuid,userDetails,triggerType) => {
    const response = await BudsApi.post("/v1/visitors", {
      "ProjectCode": EnvConstants.AppAdtopiaUPID,
      "Environment": EnvConstants.AppEnv,
      "UUID": uuid,
      "source": userDetails.atp_sub6,
      "token" :userDetails.atp_sub2,
      "pixelId" :userDetails.pixel,
      "triggerType" :triggerType,
    }
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING GET VISITORS',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const getAccessToken = async (uuid, source,is_test,visitorId) => {
    
    const response = await BudsApi.post("/v1/access-token", {
      // const response = await ApiTimeDelay.get("/sleep", {
      "ProjectCode": EnvConstants.AppAdtopiaUPID,
      "Environment": EnvConstants.AppEnv,
      "UUID": uuid,
      "is_test": is_test,
      "source": source,
      "visitor_id" :visitorId,
    }

    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING GET ACCESS TOKEN',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
   
    });
    return response;
  };

  const createCustomer = async (uuid, source, title, firstname, lastname, email, phone,is_test,visitorId) => {
    //console.log(uuid, source, title, firstname, lastname, email, phone);
    const response = await BudsApi.post("/v1/customer", { 
    //  const response = await ApiTimeDelay.get("/sleep", {
      "ProjectCode": EnvConstants.AppAdtopiaUPID,
      "UUID": uuid,
      "Environment": EnvConstants.AppEnv,
      "source": source,
      "is_test": is_test,
      "visitor_id" :visitorId,
      "customer_context": {
        "type": "personal",
        "region": "GB",
        "locale": "en-GB"
      },
      "customer_details": {
        "salutation": title,
        "firstname": firstname,
        "lastname": lastname,
        "email": email,
        "phonenumber": phone
      }
    }
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING CREATE CUSTOMER',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const getProviders = async (uuid, source,is_test,visitorId) => {
    const response = await BudsApi.post("/v1/providers", {
      "ProjectCode": EnvConstants.AppAdtopiaUPID,
      "Environment": EnvConstants.AppEnv,
      "source": source,
      "UUID": uuid,
      "is_test": is_test,
      "visitor_id" :visitorId,
    }
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING GET PROVIDER LIST',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const getAuthGatewayUrl = async (uuid, customer_id, redirect_url, source,is_test,visitorId,provider) => {
    const response = await BudsApi.post("/v1/lender-authorisation-gateway-url", {
      "ProjectCode": EnvConstants.AppAdtopiaUPID,
      "Environment": EnvConstants.AppEnv,
      "UUID": uuid,
      "customer_id": customer_id,
      "redirect_url": redirect_url,
      "is_test": is_test,
      "providers": provider,
      "source": source,
      "visitor_id" :visitorId,
    }
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING AUTHORISATION GATEWAY URL',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const callBackGateWay = async (uuid, source, status, customer_id, task_id,is_test,visitorId) => {
    const response = await BudsApi.post("/v1/callback-gateway-url", {
      "ProjectCode": EnvConstants.AppAdtopiaUPID,
      "Environment": EnvConstants.AppEnv,
      "UUID": uuid,
      "source": source,
      "status": status,
      "is_test": is_test,
      "customer_id": customer_id,
      "connection_task_id": task_id,
      "visitor_id" :visitorId,
    }
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING CALLBACK GATEWAY URL',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const fetchAccountDetails = async (uuid, customer_id, source,is_test,visitorId) => {
    const response = await BudsApi.post("/v1/retrieve-accounts", {
      "ProjectCode": EnvConstants.AppAdtopiaUPID,
      "Environment": EnvConstants.AppEnv,
      "UUID": uuid,
      "customer_id": customer_id,
      "is_test": is_test,
      "source": source,
      "visitor_id" :visitorId,
    }
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING RETRIEVE ACCOUNTS',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  }

  const fetchTransactions = async (uuid, customer_id, from, to, source,is_test,visitorId) => {
    const response = await BudsApi.post("/v1/transactions", {
      "ProjectCode": EnvConstants.AppAdtopiaUPID,
      "Environment": EnvConstants.AppEnv,
      "UUID": uuid,
      "customer_id": customer_id,
      "is_test": is_test,
      "source": source,
      "date_from": from,
      "date_to": to,
      "visitor_id" :visitorId,
    }
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING GET TRANSACTIONS',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  }

  const storeApiResponse = async (visitor_parameters, message_type, uuid, open_banking_data) => {
    const response = await Api.post("/api/v1/data-ingestion-pipeline", {
      visitor_parameters,
      message_type,
      uuid,
      open_banking_data
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'Data Ingestion : ' + message_type,
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const OpenBankVisit = async (message_type, uuid, followup_data, page_name, query_string, type) => {
    const response = await Api.post("/api/v1/data-ingestion-pipeline", {
      message_type,
      uuid,
      followup_data,
      page_name,
      query_string,
      type,
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'Data Ingestion : ' + message_type,
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  const customerConsentsApi = async (uuid,is_test) => {
    const response = await BudsApi.post("/v1/customer-consents", {
      "ProjectCode": EnvConstants.AppAdtopiaUPID,
      "Environment": EnvConstants.AppEnv,
      "UUID": [uuid],
      "is_test": is_test
    }
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'OPEN BANKING CUSTOMER CONSENTS',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  }


  const storeClickResponse = async (visitor_parameters,message_type,uuid,data) => {
    const response = await Api.post("/api/v1/data-ingestion-pipeline", {
      visitor_parameters,
      message_type,
      uuid,
      data
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'Data Ingestion : ' + message_type,
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };


  const getUserDataForOB = async (uuid) => {
    const response = await Api.get("api/v1/user-info-ob?uuid=" + uuid, {});
    return response;
  };

  const milestoneChase = async (visitor_parameters,user_id,message_type,value) => {
    const response = await Api.post("/api/v1/update-chase-milestone", {
      visitor_parameters,
      user_id,
      'milestone_name' : message_type,
      'milestone_value' : value
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'Chase Milestone : ' + message_type,
        errorReason: error.message,
        errorCode: error.code,
        uuid: visitor_parameters.uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response; 
  };

  const getCustomerCheck = async (visitor_parameters,user_id,agreement_date,lenders) => {
    const response = await Api.post("/api/v1/customer_match", {
      visitor_parameters,
      user_id,
      agreement_date,
      lenders
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'Customer Match Check',
        errorReason: error.message,
        errorCode: error.code,
        uuid: visitor_parameters.uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };


  const storeFosLenders = async (visitor_parameters, message_type, uuid, lender_lists) => {
    const response = await Api.post("/api/v1/data-ingestion-pipeline", {
      visitor_parameters,
      message_type,
      uuid,
      lender_lists
    }).catch(function (error) {
      const erroMessage = {
        serviceName: 'Data Ingestion : ' + message_type,
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      ErrorWebhookTrigger(erroMessage);
    });
    return response;
  };

  return { getVisitorID,getAccessToken, createCustomer, getProviders, getAuthGatewayUrl, callBackGateWay, storeApiResponse, fetchAccountDetails, fetchTransactions, OpenBankVisit, customerConsentsApi,storeClickResponse,getUserDataForOB,milestoneChase,getCustomerCheck,storeFosLenders};
};