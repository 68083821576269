import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";

const SoldDebtOn = ({ onselectAnswer, reff, display, trigger }) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="radio-box question animated fadeInUp" ref={reff}>
          <h3>
            Has Vanquis sold on your debt, if any, to a company such as Lowell
            Portfolio / Arrow Global?
          </h3>
          <RadioButtonQuest
            key="YES"
            className="radio-button"
            labelName="Yes"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="95"
            name="question_71"
            id="onetwo"
            dataQuestId={71}
            dataAnsId={206}
            dataAnsLabel="Yes"
          />
          <RadioButtonQuest
            key="NO"
            className="radio-button"
            labelName="NO"
            labelClassName="option_but next-2"
            onClick={labelclicked}
            value="NO"
            name="question_71"
            id="onethree"
            dataQuestId={71}
            dataAnsId={207}
            dataAnsLabel="No"
          />
        </div>
      </div>
    </>
  );
};

export default SoldDebtOn;
