import React from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FormVAQ_UB_4_2 from "../../Forms/FormVAQ_UB_4_2.js";
import Footer from "../../Includes/Layouts/VAQ_UB_4_2/Footer.js";
import FbViewPixel from "../../../Utility/FbViewPixel";
import "../../../assets/VAQ_UB_4_2/scss/VAQ_UB_4_2.scss";
import AccordSection from "../../Includes/Layouts/VAQ_UB_4_2/AccordSection";

const VAQ_UB_4_2 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="VAQ_UB_4_2" />
      <FbViewPixel />
      <div className="VAQ_UB_4_2">
        <FormVAQ_UB_4_2 />
        <AccordSection />
        <Footer />
      </div>
    </>
  );
};

export default VAQ_UB_4_2;
