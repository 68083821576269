import React from "react";
import logo from "../../../assets/Privacy_policy/img/logo.png";
 const Privacy_PolicyHeader = () => {
     return (
         <>
         <header id="hding">
      <div className="container">
         <div className="col-lg-8 offset-lg-2 col-12 text-center p-4" id="slide01">
            <img src={logo} alt=""/>
         </div>
      </div>
   </header>
         </>
     )
 }
 export default Privacy_PolicyHeader;