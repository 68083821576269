import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import SignatureCanvas from "react-signature-canvas";
import { CheckUUID } from "../../../../Utility/CheckUUID";
import { useSignature } from "../../../../Hooks/useSignature";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { queryString } from "../../../../Utility/QueryString";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
import InputButton from "../../../UI/InputButton";
import { useFormContext } from "react-hook-form";
import CheckBox2 from "./Checkbox2";

import LOA from '../../../../assets/pdf/LOA_Vanquis.pdf';
import TOB from '../../../../assets/pdf/Business_Terms_TMS.pdf';
import CFD from '../../../../assets/pdf/FOS_Vanquis.pdf';
import VQ from '../../../../assets/pdf/Questionnaire_Vanquis.pdf';


const Sign = ({ formSubmit, pageChange,basicDetailsValidation,openPrevPopup,setSignatureDataURL,titleQuest }) => {
  const initialState = {
    isChecked: false,
    isAgreed: false,
    isCanvasEmpty: false,
    isValid: false,
  };
  const signatureReducer = (state, action) => {
    switch (action.type) {
      case "signPadChange": {
        return {
          ...state,
          isCanvasEmpty: action.payload.value,
        };
      }
      case "privacyAgreed": {
        return {
          ...state,
          isAgreed: action.payload.value,
        };
      }
      case "validSignature": {
        return {
          ...state,
          isValid: action.payload.value,
        };
      }
      default:
        return state;
    }
  };
  const { trigger, errors } = useFormContext();
  // const { isCheck } = CheckUUID();
  // isCheck();
  const splitForm = useRef(null);
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const [isChecked, setIsChecked] = useState(true);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const [isDisable,setIsDisable] = useState(false)
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const signatureWrapRef = useRef(null);
  
  

  const handleClick = async (e) => {
    const validcheck = await basicDetailsValidation();
    if (validcheck) {
      try {
        e.preventDefault();
        const signPad = signPadRef.current;
        if (signPad.isEmpty()) {
          return dispatch({ type: "signPadChange", payload: { value: true } });
        }
        if (!isChecked) {
          return dispatch({ type: "privacyAgreed", payload: { value: true } });
        }
        const rpdata = signPad.toData();
        var signLength = 0;
        for (var i = 0; i < rpdata.length; i++) {
          signLength += Object.keys(rpdata[i]).length;
        }
        if (signLength > 13) {
          // const canvasDataURL = signPadRef.current.getCanvas().toDataURL("image/png");
          // setSignatureDataURL(canvasDataURL);
          if (titleQuest == "Mrs" || titleQuest == "Ms" || titleQuest == "Miss" || titleQuest == "Dame" || titleQuest == "Lady")
          {
          openPrevPopup();
          } else {
              formSubmit();
              setIsDisable(true);
          }
          
          // const res = await formSubmit();

            // const response = await saveSignature(
            //   signPad.getCanvas().toDataURL("image/png"),
            //   visitorParameters.visitor_parameters,
            //   formParameters,
            //   visitorParameters.data,
            //   queryString,
            //   message_type
            // );

            GtmDataLayer({
              question: "signature",
            });

            // if (response.data.status === "Success") {
            //   const formdata = JSON.parse(localStorage.getItem("formData"));
            //   if (formdata === undefined || formdata === null || formdata == "") {
            //     localStorage.setItem("formData", JSON.stringify(formdata));
            //   } else {
            //     formdata.user_sign = signPad.getCanvas().toDataURL("image/png");
            //     localStorage.setItem("formData", JSON.stringify(formdata));
            //   }
            //   pageChange();
            //   // history.push(
            //   //   "/AnalyseMB?uuid=" + visitorParameters.visitor_parameters.uuid
            //   // );
            // }
          
        } else {
          return dispatch({ type: "validSignature", payload: { value: true } });
        }
      } catch (e) {
        console.warn(e);
      }


    }
  };

  useEffect(() => {
    if (!isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  useEffect(() => {
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <div>
      <div className="sign-sec" id="sign-div">
        <h5 className=" mb-3 mt-4">
          Please sign your signature in the box below to confirm you understand
          the above
        </h5>
        <div className="signbox" ref={signatureWrapRef}>
          <SignatureCanvas
            canvasProps={{
              id: "myID",
              width: signatureWrapWidth,
              height: 200,
            }}
            ref={signPadRef}
            onEnd={() => {
              if (!signPadRef.current.isEmpty()) {
                dispatch({
                  type: "signPadChange",
                  payload: { value: false },
                });
                dispatch({
                  type: "validSignature",
                  payload: { value: false },
                });
                const canvasDataURL = signPadRef.current.getCanvas().toDataURL("image/png");
                setSignatureDataURL(canvasDataURL);
              }
              
            }}
          />
        </div>
        {state.isCanvasEmpty && (
          <div
            className="sign_msg col-12 text-center sign_msg mb-3"
            id="signatures_required"
          >
            Signature is required!
          </div>
        )}
        {state.isValid && (
          <div
            className="sign_msg col-12 text-center sign_msg"
            id="signatures_valid"
          >
            Draw valid signature!
          </div>
        )}
        <input
          type="button"
          id=""
          name=""
          className="clearbutton"
          value="Clear"
          onClick={clearSign}
        />
        <div className="row mt-2 pdf-doc-links">
            <div className="col-md-5">
              <ul className="m-0 p-0">
                <li><a href={LOA} target="_blank"><i className="bi bi-arrow-up-right-square" /> Letter of Authority</a></li>
                <li><a href={TOB} target="_blank"><i className="bi bi-arrow-up-right-square" /> Terms of Business</a></li>
              </ul>  
            </div>
            <div className="col-md-7">
              <ul className="m-0 p-0 mb-2">
                <li><a href={VQ} target="_blank"><i className="bi bi-arrow-up-right-square" /> Questionnaire</a></li>
                <li><a href={CFD} target="_blank"><i className="bi bi-arrow-up-right-square" /> Complaint Form &amp; Declaration</a></li>
              </ul> 
            </div>
          </div>
        <div>
          {" "}
          <CheckBox2
            dataId="checkbox1"
            anchorText=""
            anchorStyle=""
            href=""
            // onChange={(e) => setIsChecked(!isChecked)}
            onChange={() => {
              setIsChecked(!isChecked);
              dispatch({
                type: "privacyAgreed",
                payload: { value: isChecked },
              });
            }}
            className="checkinput me-2"
            value={isChecked}
            checked={isChecked}
            CheckBoxText="By clicking submit you agree to us appending your electronic signature to the claim
              documents and confirming that you accept the terms of this contract for TMS Legal to represent you in
              your Responsible Lending claim, and that you agree to pay us 45% commission if the claim is
              successful."
          />
        </div>
        {state.isAgreed && (
          <div className="sign_msg col-12 text-center mb-3">
            Please tick to show your authorisation for us to proceed
          </div>
        )}
      </div>
      <div>
        {/* <InputButton
          name="formSubmitBtn"
          className=" btn-comm2 p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3"
          id="formSubmitBtn"
          value="Start"
          btnType="button"
          onClick={handleClick}
        /> */}
        <button
          className=" btn-comm2 p-2 w-100 mb-lg-0 mb-md-0 mb-sm-0 mb-3 py-3" id="formSubmitBtn" name="formSubmitBtn" type="button" onClick={handleClick} disabled={isDisable}><span>Start</span> </button>
      </div>
    </div>
  );
};

export default Sign;
