import React, { useState, useEffect, useRef, useReducer, useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
// import GtmDataLayer from "../../Utility/GtmDataLayer";
import LiveIn from "../Includes/Layouts/MB_OBV1/LiveIn";
import VehicleReg from "../Includes/Layouts/MB_OBV1/VehicleReg";
import KeeperDate from "../Includes/Layouts/MB_OBV1/KeeperDate";
import YearOfPossession from "../Includes/Layouts/MB_OBV1/YearOfPossession";
import BankName from "../Includes/Layouts/MB_OBV1/BankName";
import StillActive from "../Includes/Layouts/MB_OBV1/StillActive";
import Possession from "../Includes/Layouts/MB_OBV1/Possession";
import EmploymentStatus from "../Includes/Layouts/MB_OBV1/EmploymentStatus";
import privacy from "../../assets/MB_OBV1/img/privacy.png";
import ssl from "../../assets/MB_OBV1/img/ssl.svg";
import Dependents from "../Includes/Layouts/MB_OBV1/Dependents";
import Verify from "../Includes/Layouts/MB_OBV1/Verify";
import Verify2 from "../Includes/Layouts/MB_OBV1/Verify2";
import Bankruptcy from "../Includes/Layouts/MB_OBV1/Bankruptcy";
import PersonalDetails from "../Includes/Layouts/MB_OBV1/PersonalDetails";
import PostCode from "../Includes/Layouts/MB_OBV1/PostCode";
import ContactDetails from "../Includes/Layouts/MB_OBV1/ContactDetails";
import { useTotalCarCheck } from "../../Hooks/useTotalCarCheck";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import * as EnvConstants from "../../Constants/EnvConstants";
import { blacklist } from "../../Hooks/blacklist";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import EmploymentStatusNEW_OPT from "../Includes/Layouts/MB_OBV1/EmploymentStatusNEW_OPT";
import BankStatement from "../Includes/Layouts/MB_OBV1/BankStatement";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";


const FormMB_OBV1 = () => {
   const initialState = {
      showSlide1: "show",
      showSlide2: "hide",
      showSlide3: "hide",
      showSlide3a: "hide",
      showSlide4: "hide",
      showSlide5: "hide",
      showSlide6: "hide",
      showSlide7: "hide",
      showSlide8: "hide",
      showSlide9: "hide",
      showSlide10: "hide",
      showSlide11: "hide",
      showSlide12: "hide",
      showSlide13: "hide",
      showSlide14: "hide",
      showSlide15: "hide",
      radioId: "-1",
      prevState: "",
   };

   const {updateMilestone} = useUpdateMilestone();
   const [latest, setLatest] = useState(false);
   const FormReducer = (state, action) => {
      switch (action.type) {
         case "showSlide": {
            // if (action.payload.clickedSlide.slide === "livein") {
            //    //   GtmDataLayer({
            //    //     'question': 'Still active?'
            //    //   });
            //    return {
            //       ...state,
            //       showSlide1: "hide",
            //       showSlide2: "show",
            //    };
            // }
            if (action.payload.clickedSlide.slide === "verify") {
               GtmDataLayer({
                  'question': 'Hardships or struggle'
               });
               return {
                  ...state,
                  showSlide1: "hide",
                  showSlide2: "show",
               };
            } else if (action.payload.clickedSlide.slide === "VehRegNo") {
               GtmDataLayer({
                  'question': 'Vehicle reg.'
               });
               return {
                  ...state,
                  showSlide2: "hide",
                  showSlide3: "show",
               };
            } else if (action.payload.clickedSlide.slide === "keeperDate") {

               if (state.radioId != 0) {
                  GtmDataLayer({
                     'question': 'Approx. vehicle reg. date'
                  });
                  setLatest(false);
                  return {
                     ...state,
                     showSlide3: "hide",
                     showSlide3a: "show",
                  }
               } else {
                  GtmDataLayer({
                     'question': 'Approx. vehicle reg. date'
                  });
                  setLatest(true);
                  return {
                     ...state,
                     showSlide2: "hide",
                     showSlide3: "hide",
                     // showSlide4: "show",
                     showSlide15: "show",
                  };
               }
            }
            else if (action.payload.clickedSlide.slide === "skipSlide") {
               GtmDataLayer({
                  'question': 'Vehicle reg.'
               });
               if(action.payload.clickedSlide.radioId) {
                  state.radioId = action.payload.clickedSlide.radioId;
               }
               return {
                  ...state,
                  showSlide2: "hide",
                  // showSlide4: "show",
                  showSlide15: "show",
               };
            }
            else if (action.payload.clickedSlide.slide === "yearposession") {

               return {
                  ...state,
                  showSlide3a: "hide",
                  // showSlide4: "show",
                  showSlide15: "show",
               };
            } else if (action.payload.clickedSlide.slide === "bankStatement") {
               /* GtmDataLayer({
                 'question': 'Possession of the vehicle?'
               }); */
               return {
                 ...state,
                 showSlide15: "hide",
                 showSlide4: "show",
               };
            } else if (action.payload.clickedSlide.slide === "stillactive") {
               GtmDataLayer({
                  'question': 'Still active'
               });

               return {
                  ...state,
                  showSlide4: "hide",
                  showSlide5: "show",
               };
            } else if (action.payload.clickedSlide.slide === "possession") {
               GtmDataLayer({
                  'question': 'Still have the vehicle'
               });
               return {
                  ...state,
                  showSlide5: "hide",
                  showSlide6: "show",
               };
            }
            else if (action.payload.clickedSlide.slide === "bankname") {
               GtmDataLayer({
                  'question': 'Bank'
               });
               return {
                  ...state,
                  showSlide6: "hide",
                  showSlide7: "show",
               };
            } else if (action.payload.clickedSlide.slide === "employment") {
               GtmDataLayer({
                  'question': 'Employment status'
               });
               return {
                  ...state,
                  showSlide7: "hide",
                  showSlide8: "show",
               };
            } else if (action.payload.clickedSlide.slide === "dependent") {
               GtmDataLayer({
                  'question': 'Dependents'
               });
               return {
                  ...state,
                  showSlide8: "hide",
                  showSlide9: "show",
               };
            } else if (action.payload.clickedSlide.slide === "verify2") {
               GtmDataLayer({
                  'question': 'Filed a claim'
               });
               return {
                  ...state,
                  showSlide9: "hide",
                  showSlide10: "show",
               };
            } else if (action.payload.clickedSlide.slide === "bankruptcy") {
               GtmDataLayer({
                  'question': 'Bankruptcy'
               });
               return {
                  ...state,
                  showSlide10: "hide",
                  showSlide11: "show",
               };
            }
            else if (action.payload.clickedSlide.slide === "no_prev_name") {
               return {
                  ...state,
                  showSlide11: "hide",
                  showSlide12: "show",
               };
            } else if (action.payload.clickedSlide.slide === "personalvalidate") {
               GtmDataLayer({
                  'question': 'Name, DOB'
               });
               return {
                  ...state,
                  showSlide11: "hide",
                  showSlide12: "show",
               };
            } else if (action.payload.clickedSlide.slide === "personal") {
               return {
                  ...state,
                  showSlide11: "hide",
                  showSlide12: "show",
               };
            } else if (action.payload.clickedSlide.slide === "no_prev_post") {
               return {
                  ...state,
                  showSlide12: "hide",
                  showSlide13: "show",
               };
            }
            else if (action.payload.clickedSlide.slide === "postcode") {
               GtmDataLayer({
                  'question': 'Postcode'
               });

               return {
                  ...state,
                  showSlide12: "hide",
                  showSlide13: "show",
               };
            }
            else {
               return {
                  ...state,
               };
            }
         }
         case "previousSlides": {
            if (action.payload.previousSlide.slide == "prevHardship") {
               return {
                  ...state,
                  showSlide1: "show",
                  showSlide2: "hide",
               };
            } else if (action.payload.previousSlide.slide === 'prevReg') {
               return {
                  ...state,
                  showSlide2: "show",
                  showSlide3: "hide",
                  showSlide4: "hide",
                  showSlide15: "hide",
               }
            } else if (action.payload.previousSlide.slide === 'prevBankStmt') {
               return {
                  ...state,
                  // showSlide2: "show",
                  showSlide15: "show",
                  showSlide3: "hide",
                  showSlide4: "hide",
               }
            } else if (action.payload.previousSlide.slide === 'prevKeeper') {
               if (state.radioId != 0) {
                  return {
                     ...state,
                     showSlide3a: "show",
                     showSlide4: "hide",
                     showSlide15: "hide"
                  }
               } else {
                  return {
                     ...state,
                     showSlide3: "show",
                     showSlide4: "hide",
                     showSlide15: "hide"
                  }
               }
            }
            else if (action.payload.previousSlide.slide === 'prevKeep') {
               return {
                  ...state,
                  showSlide3: "show",
                  showSlide3a: "hide"
               }
            } else if (action.payload.previousSlide.slide == "backToReg") {

               if (state.radioId == 1) {
                  return {
                     ...state,
                     showSlide3a: "show",
                     showSlide4: "hide",
                     showSlide15: "hide"
                  }
               } else if (state.radioId == 0) {
                  return {
                     ...state,
                     showSlide3: "show",
                     showSlide4: "hide",
                     showSlide15: "hide"
                  }
               } else {
                  return {
                     ...state,
                     showSlide2: "show",
                     showSlide15: "hide"
                  }
               }
            } else if (action.payload.previousSlide.slide === 'back04') {
               return {
                  ...state,
                  showSlide4: "show",
                  showSlide5: "hide"
               }
            } else if (action.payload.previousSlide.slide === 'back05') {
               return {
                  ...state,
                  showSlide5: "show",
                  showSlide6: "hide"
               }
            } else if (action.payload.previousSlide.slide === 'back06') {
               return {
                  ...state,
                  showSlide6: "show",
                  showSlide7: "hide"
               }
            } else if (action.payload.previousSlide.slide === 'back07') {
               return {
                  ...state,
                  showSlide7: "show",
                  showSlide8: "hide"
               }
            } else if (action.payload.previousSlide.slide === 'back08') {
               return {
                  ...state,
                  showSlide8: "show",
                  showSlide9: "hide"
               }
            } else if (action.payload.previousSlide.slide === 'back09') {
               return {
                  ...state,
                  showSlide9: "show",
                  showSlide10: "hide"
               }
            } else if (action.payload.previousSlide.slide === 'back10') {
               return {
                  ...state,
                  showSlide10: "show",
                  showSlide11: "hide"
               }
            } else if (action.payload.previousSlide.slide === 'back11') {
               return {
                  ...state,
                  showSlide11: "show",
                  showSlide12: "hide"
               }
            } else if (action.payload.previousSlide.slide === 'back12') {
               return {
                  ...state,
                  showSlide12: "show",
                  showSlide13: "hide"
               }
            } else if (action.payload.previousSlide.slide === 'back13') {
               return {
                  ...state,
                  showSlide13: "show",
                  showSlide14: "hide"
               }
            }
            else {
               return {
                  ...state,
               };
            }
         }
         default: {
         }
      }
   }
   const splitForm = useRef(null);
   const scrollfrmv2div = useRef([]);
   const { SaveQuestionnaire } = useQuestionnaire();
   const history = useHistory();
   const [state, dispatch] = useReducer(FormReducer, initialState);
   const { saveDataIngestion, formDataIngestion, isLoading } = useDataIngestion();
   const [completedQuestArr, setCompletedQuestArr] = useState([]);
   const { getBlacklist } = blacklist();
   const field = "pid";
   const { visitorParameters } = useContext(VisitorParamsContext);
   const { queryString } = useContext(QueryStringContext);
   const [splitName, setSplitName] = useState();
   const [vehicle, setVehicle] = useState("");
   const [validKeeperDate, setValidKeeperDate] = useState("");
   const { totalCarCheckApi } = useTotalCarCheck();
   const [totalCarCheck, settotalCarCheck] = useState("");
   const [clickedSlide, setClickedSlide] = useState([]);
   const [previousSlide, setPreviousSlide] = useState([]);
   const [skip, setSkip] = useState(false);
   const [previousName, setPreviousName] = useState("");
   const [previousAddressData, setPreviousAddressData] = useState({});
   const [blacklisted, setBlacklisted] = useState([]);
   const [SelectBank, setSelectBank] = useState([]);
   const [Year, setYear] = useState("");
   const [lenderList, setLenderList] = useState([]);
   const [prevStill, setPrevStill] = useState('prevKeeper');




   const { register, errors, trigger, setError, clearErrors, getValues } =
      useForm({
         mode: "onBlur",
         reValidateMode: "onChange",
         defaultValues: {},
         resolver: undefined,
         context: undefined,
         criteriaMode: "firstError",
         shouldFocusError: true,
         shouldUnregister: true,
      });
   const getUuid = () => {
      const visitorData = visitorParameters.visitor_parameters;
      if (visitorData != "") {
         var uuid = visitorData.uuid;
      } else {
         const query = new URLSearchParams(window.location.search);
         const query_uuid = query.get("uuid");
         const local_storage_uuid = localStorage.getItem("uuid");
         var uuid = query_uuid ? query_uuid : local_storage_uuid;
      }
      return uuid;
   };
   const uuid = getUuid();
   const saveVehicleDetails = (vehicleData) => {
      setVehicle(vehicleData);
   };
   const savetotalCarCheck = (totalCarCheck) => {
      settotalCarCheck(totalCarCheck);
   };
   const slideChange = (e) => {
      if (e.target.name == 'keeperDate') {
         let keeper_date = e.target.value;
         let keeper_year = keeper_date.trim().split(' ').pop();
         setYear(keeper_year);
         state.radioId = e.target.id;
         // console.log("radiostate"+state.radioId);
         if (e.target.id != 0) {
            setPrevStill('prevKeep')
         }
      }
      setTimeout(() => {
         scrollfrmv2div.current.scrollIntoView({
            behavior: "smooth",
            top: 0,
         });
      }, 50);
      setClickedSlide({ slide: e.target.name });
   };
   const previousSlideChange = (e) => {
      console.log(e.target.getAttribute("name"));
      setTimeout(() => {
         scrollfrmv2div.current.scrollIntoView({
            behavior: "smooth",
            top: 0,
         });
      }, 50);
      setPreviousSlide({ slide: e.target.getAttribute("name") });
   };

   const slideSkip = () => {
      setClickedSlide({ slide: 'skipSlide', radioId: -1 });
   };
   useEffect(() => {
      if (clickedSlide) {
         dispatch({ type: "showSlide", payload: { clickedSlide } });
      }
   }, [clickedSlide]);

   useEffect(() => {
      if (previousSlide) {
         dispatch({ type: "previousSlides", payload: { previousSlide } });
      }
   }, [previousSlide]);

   // const pushPageChange = () => {

   //    if (validKeeperDate) {
   //       (async () => {
   //          const totalCarCheckApiData = await totalCarCheckApi(
   //             vehicle.reg_no,
   //             visitorParameters.visitor_parameters.uuid,
   //             "MB_OBV1"
   //          );
   //          savetotalCarCheck(totalCarCheckApiData.data.vehicleData);
   //          var lender = totalCarCheckApiData.data.vehicleData.finance_company;
   //          localStorage.setItem("lenderName", lender);
   //       })();

   //    }

   // };

   useEffect(() => {
      let responsesss = [];
      (async () => {
         const response = await getBlacklist();
         let dataLength = response.data.response.length;
         responsesss = response.data.response;
         if (dataLength > 0) {
            setBlacklisted(response.data.response)
         }
      })();
   }, []);

   const formSubmit = () => {
      GtmDataLayer({
         'question': 'Contact Details'
      });

      const form = splitForm.current;
      const values = getValues();
      const formData = values;

      if (formData.address1 !== "") {
         const txtUdprn = form["txtUdprn"].value;
         const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
         const txtPz_mailsort = form["txtPz_mailsort"].value;
         const txtStreet = form["txtStreet"].value;
         const txtAddress3 = form["txtAddress3"].value;
         const txtDistrict = form["txtDistrict"].value;
         formData.txtDistrict = txtDistrict;
         formData.txtAddress3 = txtAddress3;
         formData.txtUdprn = txtUdprn;
         formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
         formData.txtPz_mailsort = txtPz_mailsort;
         formData.txtStreet = txtStreet;
      }

      //  ****Previous Name****
      formData.Previousname = previousName;

      //***previous_address ****/
      formData.previous_postcode = previousAddressData.postcode;
      formData.previous_address_line1 = previousAddressData.line_1;
      formData.previous_address_line2 = previousAddressData.line_2;
      formData.previous_address_line3 = previousAddressData.line_3;
      formData.previous_address_county = previousAddressData.county;
      formData.previous_address_town = previousAddressData.town;
      formData.previous_address_country = previousAddressData.country;
      formData.previous_district = previousAddressData.district;
      formData.previous_address_id = previousAddressData.prevAddressId;

      formData.question_63 = formData.livein;

      formData.question_74 = formData.bankStatement;
      formData.question_26 = formData.stillactive;
      formData.question_27 = formData.possession;
      formData.question_2 = formData.employment;
      formData.question_3 = formData.dependent;
      formData.question_29 = formData.verify;
      formData.question_30 = formData.verify2;
      formData.question_9 = formData.bankruptcy;
      formData.regNoSkip = skip;
      if (skip == false) {
         formData.question_10 = formData.carRegNo;
         formData.question_11 = formData.keeperDate;
         if (latest == false) {
            formData.question_65 = formData.YearOfPossession.concat(" ", formData.MonthOfPossession);
         }
      }


      formData.lender = [SelectBank];
      formData.validKeeperDate = validKeeperDate;

      // console.log(SelectBank);


      formData.page_name = "MB_OBV1";
      formData.pCode_manual = form["pCode_EntryType"].value;
      if (
         visitorParameters.data !== null ||
         visitorParameters.data !== undefined
      ) {
         localStorage.setItem("formData", JSON.stringify(formData));
         localStorage.setItem("queryString", queryString);
         const formSUbmitResult = formDataIngestion(
            visitorParameters.visitor_parameters,
            formData,
            "split_form_submit",
            "MB_OBV1",
            queryString,
            visitorParameters.data
         );

         updateMilestone(visitorParameters.visitor_parameters.uuid,"lead","live");

         let blacklistFlag = false;
         blacklisted.map((value, index) => {
            if ("email" == value.type && value.value == formData.txtEmail) {
               blacklistFlag = true;
            }

            if ("phone" == value.type && value.value == formData.txtPhone) {
               blacklistFlag = true;
            }
         });

         if (blacklistFlag) {
            window.scrollTo(0, 0);
            history.push(
               "/thankyou-bl?uuid=" + visitorParameters.visitor_parameters.uuid
            );

         } else {
            if (queryString != null) {
               if (queryString.indexOf("&" + field + "=") !== -1) {
                  if (EnvConstants.AppConversionPixel === "true") {
                     history.push("/fbpixel?split_name=MB_OBV1"); // fb fixel firing
                  } else {
                     window.scrollTo(0, 0);
                     history.push(
                        "/signatureMB?split_name=MB_OBV1&uuid=" + visitorParameters.visitor_parameters.uuid
                     );
                  }
               } else {
                  window.scrollTo(0, 0);
                  history.push(
                     "/signatureMB?split_name=MB_OBV1&uuid=" + visitorParameters.visitor_parameters.uuid
                  );
               }
            } else {
               window.scrollTo(0, 0);
               history.push(
                  "/signatureMB?split_name=MB_OBV1&uuid=" + visitorParameters.visitor_parameters.uuid
               );
            }
         }
         // }
      }
      // pushPageChange();
   };
   const storeQuestionnire = async (question_data) => {
      const response = await SaveQuestionnaire(
         visitorParameters.visitor_parameters,
         question_data,
         "question_store",
         visitorParameters.data,
      );
   };
   const checkCarRegistration = (e) => {
      let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
      // let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
      // let anslabel = e.target.getAttribute("data-ans-label");
      let selectedAns = null;
      let anslabel = splitForm.current["carRegNo"].value;
      setCompletedQuestArr([...completedQuestArr, selectedQuest]);

      if (selectedQuest) {
         let question_data = {
            question_id: selectedQuest,
            option_id: selectedAns,
            answer_text: anslabel,
            input_answer: anslabel,
         };
         storeQuestionnire(question_data);
         var existingEntries = JSON.parse(localStorage.getItem("questionData"));
         if (existingEntries == null) existingEntries = [];
         localStorage.setItem(
            "currentquestionData",
            JSON.stringify(question_data)
         );
         existingEntries.push(question_data);
         localStorage.setItem("questionData", JSON.stringify(existingEntries));
      }
      setClickedSlide({ slide: e.target.name });
   };

   return (
     <>
       <form
         ref={splitForm}
         name="split_form"
         id="user_form"
         method="POST"
         autoComplete="off"
       >
         <div ref={scrollfrmv2div}>
           {/* <LiveIn
                  className={`col-12 top_minus_1 ${state.showSlide1}`}
                  textHeading="Do you live in the UK?"
                  validation={register}
                  slideChange={slideChange}
                  getValues={getValues}
               /> */}
           <Verify
             className={`col-12 top_minus ${state.showSlide1}`}
             textHeading="Have you experienced any hardships or struggle in making the repayments to MoneyBarn on time while still meeting all your other commitments?"
             validation={register}
             slideChange={slideChange}
             getValues={getValues}
             backClick={previousSlideChange}
           />

           <VehicleReg
             className={`col-12 top_minus ${state.showSlide2}`}
             textHeading="Enter your Vehicle Registration to begin your refund check"
             uuid={uuid}
             validation={register}
             getValues={getValues}
             slideChange={slideChange}
             validationMsg={errors}
             trigger={trigger}
             clearErrors={clearErrors}
             splitForm={splitForm}
             setError={setError}
             page_name={splitName}
             saveVehicleDetails={saveVehicleDetails}
             savetotalCarCheck={savetotalCarCheck}
             checkCarRegistration={checkCarRegistration}
             slideSkip={slideSkip}
             backClick={previousSlideChange}
             setSkip={setSkip}
             setLenderList={setLenderList}
           />
           <KeeperDate
             className={`col-12 top_minus ${state.showSlide3}`}
             textHeading="When did you start the finance?"
             uuid={visitorParameters.visitor_parameters.uuid}
             vehicle={vehicle}
             totalCarCheck={totalCarCheck}
             savetotalCarCheck={savetotalCarCheck}
             setValidKeeperDate={setValidKeeperDate}
             validation={register}
             getValues={getValues}
             slideChange={slideChange}
             validationMsg={errors}
             trigger={trigger}
             clearErrors={clearErrors}
             splitForm={splitForm}
             setError={setError}
             backClick={previousSlideChange}
           />
           <YearOfPossession
             className={`col-12 top_minus ${state.showSlide3a}`}
             textHeadingLine1="Approximately when did you take out the loan with MoneyBarn?"
             validation={register}
             clearErrors={clearErrors}
             slideChange={slideChange}
             getValues={getValues}
             backClick={previousSlideChange}
             trigger={trigger}
             validationMsg={errors}
             Year={Year}
           />

           <BankStatement
             className={`col-12 top_minus ${state.showSlide15}`}
             textHeadingLine1="Did Moneybarn ask you to provide any of the below when you bought the car?"
             validation={register}
             slideChange={slideChange}
             getValues={getValues}
             backClick={previousSlideChange}
           />

           <StillActive
             className={`col-12 top_minus ${state.showSlide4}`}
             textHeading="Is your finance agreement with MoneyBarn still active?"
             validation={register}
             slideChange={slideChange}
             getValues={getValues}
             backClick={previousSlideChange}
             skip={skip}
             prevButton={prevStill}
           />
           <Possession
             className={`col-12 top_minus ${state.showSlide5}`}
             textHeading="Do you still have the vehicle?"
             validation={register}
             slideChange={slideChange}
             getValues={getValues}
             backClick={previousSlideChange}
           />
           <BankName
             className={`col-12 top_minus ${state.showSlide6}`}
             validation={register}
             getValues={getValues}
             slideChange={slideChange}
             validationMsg={errors}
             trigger={trigger}
             Year={Year}
             SelectBank={SelectBank}
             setSelectBank={setSelectBank}
             clearErrors={clearErrors}
             splitForm={splitForm}
             setError={setError}
             backClick={previousSlideChange}
             lenderList={lenderList}
             skip={skip}
           />
           {/* <EmploymentStatus
                  className={`col-12 top_minus ${state.showSlide7}`}
                  validation={register}
                  slideChange={slideChange}
                  getValues={getValues}
                  Year={Year}
                  backClick={previousSlideChange}
                  skip={skip}
               /> */}

           <EmploymentStatusNEW_OPT
             className={`col-12 top_minus ${state.showSlide7}`}
             validation={register}
             slideChange={slideChange}
             getValues={getValues}
             Year={Year}
             backClick={previousSlideChange}
             skip={skip}
           />

           <Dependents
             className={`col-12 top_minus ${state.showSlide8}`}
             validation={register}
             slideChange={slideChange}
             getValues={getValues}
             Year={Year}
             backClick={previousSlideChange}
             skip={skip}
           />

           <Verify2
             className={`col-12 top_minus ${state.showSlide9}`}
             textHeading="Have you, (or a company on your behalf), ever filed a claim with MoneyBarn?"
             validation={register}
             slideChange={slideChange}
             getValues={getValues}
             backClick={previousSlideChange}
           />
           <Bankruptcy
             className={`col-12 top_minus ${state.showSlide10}`}
             textHeading="Are you currently subject to Bankruptcy or Trust deed?"
             validation={register}
             slideChange={slideChange}
             getValues={getValues}
             backClick={previousSlideChange}
           />
           <PersonalDetails
             className={`col-12 top_minus ${state.showSlide11}`}
             validation={register}
             validationMsg={errors}
             trigger={trigger}
             clearErrors={clearErrors}
             getValues={getValues}
             splitForm={splitForm}
             slideChange={slideChange}
             backClick={previousSlideChange}
             setError={setError}
             setPreviousName={setPreviousName}
           />
           <PostCode
             className={`col-12 top_minus ${state.showSlide12}`}
             validation={register}
             validationMsg={errors}
             trigger={trigger}
             clearErrors={clearErrors}
             setError={setError}
             getValues={getValues}
             splitForm={splitForm}
             scrollView={scrollfrmv2div}
             slideChange={slideChange}
             backClick={previousSlideChange}
             setPreviousAddressData={setPreviousAddressData}
             GtmDataLayer={GtmDataLayer}
           />
           <ContactDetails
             className={`col-12 top_minus ${state.showSlide13}`}
             validation={register}
             validationMsg={errors}
             trigger={trigger}
             clearErrors={clearErrors}
             setError={setError}
             getValues={getValues}
             formSubmit={formSubmit}
             backClick={previousSlideChange}
           />
         </div>
         <div className="col-lg-12 text-center slide-logo pt-2">
           <img src={ssl} alt="" />
         </div>
       </form>
     </>
   );
};

export default FormMB_OBV1;