import React from "react";
const Header = () => {
  return (
    <>
       <div>
  <section className="sun-content ">
    <div className="container-fluid">
      <div className="col-md-12">
      <p><img src="/assets/MB_UFGN_1/img/sun.png" alt="" />
          MoneyBarn repays £30 million to 6,000 customers
        </p>
      </div>
    </div>
  </section>
  <header id="hding">
    <div className="container">
      <div className="col-lg-8 offset-lg-2 col-12 text-center p-0" id="slide01">
        <img src="/assets/MB_UFGN_1/img/logo.png" alt="" />
        <h1>
          Have you taken out an expensive loan with MoneyBarn to finance a vehicle?
        </h1>
        <h2>
          See if you are eligible to pursue a responsible credit mis-selling claim today (it only takes 60 seconds
          and is 100% online)
        </h2>
        <h3>
          <strong>Average Payouts of £5,000!*</strong>
        </h3>
      </div>
      <div className="spacemob" style={{display: 'none'}} />
    </div>
  </header>
</div>

    </>
  );
};

export default Header;
