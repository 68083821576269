import React, { useState, useContext } from "react";
import VisitorParamsContext from "../../../../../Contexts/VisitorParams";
import { useHistory } from "react-router-dom";
import TextField from "../../../../UI/TextField";
import { useIdUpload } from "../../../../../Hooks/useIdUpload";
import { useSkippedLeads } from '../../../../../Hooks/useSkippedLeads';
import { queryString } from "../../../../../Utility/QueryString";

const QuestionnaireFlpFANUpload = ({
  reff,
  display,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  split_name,
  docType,
  documentType,
  allFollowupData,
  pushPageChange,
  setShow,
  setPendingStatus
}) => {
  const history = useHistory();
  const { skippedLeads, skippedLeadsFLP } = useSkippedLeads();
  const { saveFLPIdUpload, isLoading } = useIdUpload();
  const query_string = localStorage.getItem("querystring");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileType, setSelectedFileType] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [isInValidfFile, setIsInValidfFile] = useState(false);
  const [base64Image, setBase64Image] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [document, setDocument] = useState(false);
  const [file, setFile] = useState("");
  const [activeClass, setActiveClass] = useState("disabled");
  const [btnDisabled, SetBtnDisabled] = useState(true);

  const userfile = {
    preview: undefined,
    title: undefined,
  };

  const changeCameraHandler = (event) => {
    setActiveClass("camera");
    SetBtnDisabled(false);
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      // if (selectedFile) {

      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage(
          "The uploaded Doc type should be an image in jpeg,png or jpg"
        );
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage("Please choose a file less than 20mb");
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const changeFilesHandler = (event) => {
    setActiveClass("fileupload");
    SetBtnDisabled(false);
    setSelectedFile(event.target.files[0]);
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      console.log(extension);
      let imgsize = event.target.files[0].size;
      let allowedExtensions = [
        "image/jpeg",
        "image/png",
        "application/zip",
        "application/msword",
        "application/pdf",
      ];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage(
          "The uploaded Doc type should be an image in jpeg,png,jpg,pdf,doc, or docx"
        );
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage("Please choose a file less than 20mb");
      } else {
        setSelectedFileType(event.target.name);
        setIsInValidfFile(false);
        //_handleFileRead.bind(event.target.files[0]);
      }
      setDocument(false);

      if ("nationalIDCard_doc" == event.target.name) {
        setDocument(true);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const onSelectOption = (e) => {
    setIsInValidfFile(false);
    SetBtnDisabled(false);
    if (e.target.name == "FinanceAgreementDocEmail") {
      setSelectedFile(undefined);
      setActiveClass("email");
    } else if (e.target.name == "FinanceAgreementDocSkip") {
      setSelectedFile(undefined);
      setActiveClass("skip");
    } else if (e.target.name == "closefile") {
      setActiveClass('disabled');
      SetBtnDisabled(true);
      setSelectedFile(undefined);
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image(binaryString);
  };

  const onProceed = (e) => {
    if (activeClass == "fileupload" || activeClass == "camera") {
      savedocument(e);
    } else if (activeClass == "email") {
      SkipSupportDocs("EmailDoc");
    } else if (activeClass == "skip") {
      SkipSupportDocs("SkipDoc");
    } else {
      setIsInValidfFile(true);
      setErrorMessage("Please choose an option");
    }
  }

  const SkipSupportDocs = async (type) => {
    SetBtnDisabled(true);
    setActiveClass('disabled');
    const skippedLeadsResponse = await skippedLeadsFLP(type, allFollowupData, split_name);
    if (skippedLeadsResponse.data.status == "Success") {
      localStorage.setItem('email_doc', 1);
      pushPageChange();
      // setShow(false);
      // setPendingStatus({ status_btn: 'pending_question', status_flag: 'showTick' })
    }
  }

  const savedocument = async (e) => {
    if (selectedFile) {
      console.log(base64Image);
      setIsInValidfFile(false);
      try {
        let uploadData = '';
        uploadData = {
          imageFile: base64Image,
          captureType: selectedFileType.substring(
            selectedFileType.indexOf("doc")
          ),
          documentType: docType
        };

        const response = saveFLPIdUpload(
          uploadData,
          allFollowupData,
          queryString,
          'flp_support_docs_store'
        );
        pushPageChange();
      } catch (e) {
        console.warn("error");
        console.warn(e);
        setIsInValidfFile(true);
        setErrorMessage("Please try again");
      }
    } else {
      setIsInValidfFile(true);
      setErrorMessage("Please choose an option");
    }
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="question animated fadeInUp" ref={reff}>
          <h3 style={{ color: "#444", textAlign: "center" }} className="mb-2">
            Supporting Documentation
          </h3>
          <div className="row mt-4">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-2">
              <div className={`btn btn-lg btn-file w-100 btn-fileimp ${activeClass == 'fileupload' && 'activeclass'}`}>

                <img src="assets/Followup/img/lap-icon.png" alt="" /> &nbsp;
                UPLOAD file from your device
                <input
                  type="file"
                  className="take_picture"
                  id="FinanceAgreementDoc"
                  name="FinanceAgreementDoc"
                  onChange={changeFilesHandler}
                  onClick={(e) => (e.target.value = null)}
                />
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-lg-none mb-2">
              <div className={`btn btn-lg btn-file w-100 btn-fileimp ${activeClass == 'camera' && 'activeclass'}`}>
                <img src="assets/Followup/img/camera-icon.png" alt="" />
                &nbsp; TAKE A PHOTO of the file from your device
                <input
                  type="file"
                  accept="image/*"
                  capture="camera"
                  className="take_picture"
                  name="FinanceAgreementDocCamera"
                  onChange={changeCameraHandler}
                  onClick={(e) => (e.target.value = null)}
                />
              </div>
            </div>

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <div className={`btn btn-lg btn-file w-100 btn-fileimp ${activeClass == 'email' && 'activeclass'}`}>
                &nbsp; EMAIL file to
                "documents@themoneysolicitor.com"
                <input
                  type="radio"
                  className="take_picture"
                  name="FinanceAgreementDocEmail"
                  onClick={onSelectOption}
                />
              </div>
            </div>
            {/* <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 ps-0 p-1">
              <div>
                <div className="btn btn-lg btn-file w-100 btn-fileimp">
                  &nbsp; SKIP for now and follow up ASAP.
                  <input
                    type="radio"
                    className="take_picture"
                    name="FinanceAgreementDocSkip"
					          onClick={onSelectOption}
                  />
                </div>
              </div>
            </div> */}
          </div>
          {isInValidfFile && <p className="error-msg">{errorMessage}</p>}
          <div className="col-xl-12 col-lg-12 text-center p-1 btn_proceed_outer">
            {selectedFile && <>
              {selectedFile.name} <button className="btn btn-secondary btn-sm" name="closefile" onClick={onSelectOption}>Remove</button>
            </>
            }
            <input type="button" name="btn_qest_45" disabled={btnDisabled} className={`btn-next next-5 ${activeClass == 'disabled' && 'disabledclass'}`} onClick={onProceed} value="Proceed" />
          </div>
          <div className="clearfix"></div>
          <div className="col-xl-12 col-lg-12 text-center p-0">
            <p className="font-italic text-center  ">
              For your convenience, copies of supporting documents can be
              emailed "documents@themoneysolicitor.com"
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireFlpFANUpload;
