import React, { useState, useEffect } from "react";
import { Api } from "../../api/Api";
// import "../../assets/MB_UFGN_1/css/openbank.scss";
import "../../assets/MB_UFGN_1/css/Open_Bank_new.scss";
import { queryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useOpenBanking } from "../../Hooks/useOpenBanking";
import InputButton from "../UI/InputButton";
import { AppDomain } from "../../Constants/EnvConstants";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import OB_AffordabilityCheck from "../Includes/Layouts/MB_UFGN_1/OB_AffordabilityCheck";
import * as EnvConstants from "../../Constants/EnvConstants";
import { useLogRecord } from "../../Hooks/UseLogRecord";

const OpenBankingProceedMB1 = () => {
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(1);
  const [reloader, setReloader] = useState(1);
  const [affordabilityStat, setAffordabilityStat] = useState("show");
  const history = useHistory();
  const {
    getVisitorID,
    getAccessToken,
    createCustomer,
    getProviders,
    getAuthGatewayUrl,
    storeApiResponse,
    OpenBankVisit,
    storeClickResponse,
  } = useOpenBanking();
  const [btndisable, setBtnDisable] = useState(false);
  const [visitorId, setVisitorId] = useState(0);
  const [iframeUrl, setIframeUrl] = useState(null);
  let redirectUrl = "";
  let redirectlink = "";
  let btn_label = "";
  let page = "";
   
  const { updateLogRecord } = useLogRecord();
  const query = new URLSearchParams(window.location.search);
  const query_uuid = query.get("uuid");
  const local_storage_uuid = localStorage.getItem('uuid');
  var uuid = (query_uuid)? query_uuid : local_storage_uuid;
  var source = "live";
  const environment = EnvConstants.AppEnv;
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const bankname = formdata && formdata.lender && formdata.lender[0] ? formdata.lender[0].label : '';


  const getCurrentDateTime = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const hours = currentDate.getHours().toString().padStart(2, '0');
    const minutes = currentDate.getMinutes().toString().padStart(2, '0');
    const seconds = currentDate.getSeconds().toString().padStart(2, '0');


    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};



  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/api/v1/user-basic-details?uuid=" + uuid,
        {}
      );
      if (response.data.status === "Success") {
        let user_data = response.data.response.user_data;
        let uuid = response.data.response.uuid;
        let lender = response.data.response.lender;
        let source = response.data.response.source;
        let lstSalutation = response.data.response.lstSalutation;
        let txtFName = response.data.response.txtFName;
        let txtLName = response.data.response.txtLName;
        let txtEmail = response.data.response.txtEmail;
        let txtPhone = response.data.response.txtPhone;
        let is_test = response.data.response.is_test;
        let token = response.data.response.user_data.atp_sub2;
        let pixel = response.data.response.user_data.pixel;
        let txtPostcode = response.data.response.txtPostcode;
        let txtAddressline1 = response.data.response.txtAddressline1;
        let txtAddressline2 = response.data.response.txtAddressline2;
        let txtAddressline3 = response.data.response.txtAddressline3;
        let town = response.data.response.town;
        let dob = response.data.response.dob;
        let userId = response.data.response.user_id;
        let obcreduser = response.data.response.obcreduser;
        let obstat = response.data.response.obstat;
        let credstat = response.data.response.credstat;
        let obclosestat = response.data.response.obclosestat;
        let bud_lender = response.data.response.bud_lender;

        localStorage.setItem("is_test", is_test);
        localStorage.setItem("user_data", JSON.stringify(user_data));
        
        // if (environment == "prod" && is_test === true) {
        //   bud_lender = [];
        // } else {
        //   bud_lender = [bud_lender]
        // }

        bud_lender = [bud_lender]
        
        if (uuid == null) {
          history.push("/thankyou?uuid=" + uuid);
        }

        setUserDetails({
          uuid: uuid,
          lender: lender,
          source: source,
          lstSalutation: lstSalutation,
          txtFName: txtFName,
          txtLName: txtLName,
          txtEmail: txtEmail,
          txtPhone: txtPhone,
          is_test: is_test,
          token: token,
          pixel: pixel,
          txtPostcode: txtPostcode,
          txtAddressline1: txtAddressline1,
          txtAddressline2: txtAddressline2,
          txtAddressline3: txtAddressline3,
          town: town,
          dob: dob,
          user_id: userId,
          obcreduser: obcreduser,
          obstat: obstat,
          credstat: credstat,
          obclosestat,
          budLender:bud_lender
        });

        setTimeout(() => {
          setLoader(0);
        }, 500);

        const visitor = await getVisitorID(uuid, user_data, "new");

        if (visitor.data.status == "success") {
          setVisitorId(visitor.data.visitorId);
        }

        const flpResponse = await OpenBankVisit(
          "open_bank_visit_live",
          uuid,
          user_data,
          "OpenBankingProceedV1",
          queryString,
          "proceed-ob-mb1"
        );
      } else {
        setLoader(0);
        window.reload();
        //history.push("/thankyou?uuid=" + response.data.response.uuid);
        return false;
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let urlPortion =
        "title=" +
        userDetails.lstSalutation +
        "&fname=" +
        userDetails.txtFName +
        "&lname=" +
        userDetails.txtLName +
        "&email=" +
        userDetails.txtEmail +
        "&phone=" +
        userDetails.txtPhone +
        "&dob=" +
        userDetails.dob +
        "&abodenumber=" +
        "&straddr=" +
        userDetails.txtAddressline2 +
        "&street=" +
        "&city=" +
        userDetails.town +
        "&zip=" +
        userDetails.txtPostcode +
        "&clientRefId=" +
        userDetails.uuid;

      localStorage.setItem("url_portion", urlPortion);
      localStorage.setItem("uuid", userDetails.uuid);
      localStorage.setItem("firstname", userDetails.txtFName);
      localStorage.setItem("credStat", userDetails.credstat);

      // if (userDetails.uuid) {
      //   let payload = {
      //     page: "OB_SPLASH",
      //     type: "visit",
      //     source: userDetails.source,
      //   };
      //   await storeClickResponse(
      //     { uuid: userDetails.uuid },
      //     "ob_creditcheck_history",
      //     userDetails.uuid,
      //     payload
      //   );
      // }

      // if (userDetails.obclosestat == "1") {
      //   history.push(
      //     "/ob_splash_cancel?uuid=" + userDetails.uuid + "&atp_sub6=" + source
      //   );
      //   return false;
      // }

      if (
        userDetails.obcreduser == "1" &&
        (userDetails.obstat == "" ||
          userDetails.obstat == "0" ||
          userDetails.obstat == null) &&
        (userDetails.credstat == "" ||
          userDetails.credstat == "0" ||
          userDetails.credstat == null)
      ) {
        history.push("/thankyou?uuid=" + userDetails.uuid);
        return false;
      }
    })();
  }, [userDetails]);

  const pushProceedLink = async (e) => {
    let startDateTime = getCurrentDateTime();
    console.log("startDateTime :" + startDateTime);

    const startTime = performance.now();

    setBtnDisable(true);
    setLoader(1);
    btn_label = e.target.getAttribute("data-btn-label");
    page = e.target.getAttribute("data-page-label");
    
    let payload = {
      page,
      type: "btn_click",
      btn_label,
      source: userDetails.source,
      click_btn: "1",
    };
    await storeClickResponse(
      { uuid: userDetails.uuid },
      "ob_creditcheck_history",
      userDetails.uuid,
      payload
    );

    GtmDataLayer({
      question: "proceed ob",
    });

    localStorage.setItem("visitorId", visitorId);
    const response1 = await getAccessToken(
      userDetails.uuid,
      userDetails.source,
      userDetails.is_test,
      visitorId
    );

    if (response1 == undefined || response1 == null || response1 == "") {
      setLoader(0);
      setReloader(0);
      return false;
    }

    if (response1.data.status == "success") {
      //(response.data.access_token_ID);
      let payload = {
        milestone_key: "access_token",
        source: userDetails.source,
        lender: userDetails.budLender[0] 
      };
      await storeApiResponse(
        userDetails,
        "op_milestone_update",
        userDetails.uuid,
        payload
      );
      const customerResponse = await createCustomer(
        userDetails.uuid,
        userDetails.source,
        userDetails.lstSalutation,
        userDetails.txtFName,
        userDetails.txtLName,
        userDetails.txtEmail,
        userDetails.txtPhone,
        userDetails.is_test,
        visitorId
      );

      if (
        customerResponse == undefined ||
        response1 == null ||
        response1 == ""
      ) {
        setLoader(0);
        setReloader(0);
        return false;
      }

      if (customerResponse.data.status == "success") {
        localStorage.setItem("customer_id", customerResponse.data.customer_ID);
        let payload = {
          milestone_key: "customer_create",
          customer_id: customerResponse.data.customer_ID,
          source: userDetails.source,
          lender: userDetails.budLender[0]
        };
        await storeApiResponse(
          userDetails,
          "op_customer_store",
          userDetails.uuid,
          payload
        );
        redirectUrl = AppDomain + "/ob-analyse-mb1?uuid=" + userDetails.uuid;

        // var bud_lender = localStorage.getItem("bud_lender");
        // let lenderArray = bud_lender == null ? "" : [bud_lender];

        var response2 = await getAuthGatewayUrl(
          userDetails.uuid,
          customerResponse.data.customer_ID,
          redirectUrl,
          userDetails.source,
          userDetails.is_test,
          visitorId,
          userDetails.budLender
        );

        if (response2.data.status == "failed") {
          setLoader(0);
          //setReloader(0);
          history.push("/ob_bank_stmt_upload-mb1?uuid=" + uuid);
          return false;
        }
        if (response2.data.status == "success") {
          payload = {
            milestone_key: "lender_redirection",
            source: userDetails.source,
            lender: userDetails.budLender[0],
          };
          await storeApiResponse(
            userDetails,
            "op_milestone_update",
            userDetails.uuid,
            payload
          );

          const endTime = performance.now();

          let endDateTime = getCurrentDateTime();
          console.log("endDateTime :" + endDateTime);

          const Timems = endTime - startTime;
          const timeTaken = (Timems / 1000).toFixed(2);
          console.log("OPEN BANKING API :" + timeTaken + " seconds, UUID: " + userDetails.uuid);   
          const LogRecord =  await updateLogRecord(
            userDetails.uuid,
            "Connect_bank_mb1",
            timeTaken,
            startDateTime,
            endDateTime
          );

          let url = response2.data.gateway_url;
          window.location.href = url;
          //setLoader(0)
        }
      }
    }
  };

  const proceedManually = async (e) => {
    setBtnDisable(true);
    setLoader(1);
    GtmDataLayer({
      question: "upload manually",
    });

    btn_label = e.target.getAttribute("data-btn-label");
    page = e.target.getAttribute("data-page-label");

    let payload = {
      page,
      type: "btn_click",
      btn_label: btn_label,
      source: userDetails.source,
      click_btn: "2",
    };
    await storeClickResponse(
      { uuid: userDetails.uuid },
      "ob_creditcheck_history",
      userDetails.uuid,
      payload
    );

    const flpResponse = await OpenBankVisit(
      "envelopes_stats",
      userDetails.uuid,
      userDetails,
      "proceed-open-banking",
      queryString,
      "v1"
    );

    redirectlink = "/ob_bank_stmt_upload-mb1?uuid=" + uuid;

    setTimeout(() => {
      setLoader(0);
      history.push(redirectlink);
    }, 1500);
  };

  const goBack = () =>{
    let spliName = localStorage.getItem("split_name");
    if (spliName == "MB_UFGN_4" || spliName == "MB_UFGN_4_1") {
      history.push("/CheckListGN_4?uuid=" + uuid);
    }
    else{
    history.push("/CheckList?uuid=" + uuid);
    }
  }

  return (
    <>
      <OB_AffordabilityCheck
        setLoader={setLoader}
        loader={loader}
        reloader={reloader}
        btndisable={btndisable}
        setReloader={setReloader}
        pushProceedLink={pushProceedLink}
        proceedManually={proceedManually}
        affordStat={affordabilityStat}
        goBack={goBack}
        bankname={bankname}
      />
    </>
  );
};

export default OpenBankingProceedMB1;
