const GtmDataLayer = ({ question }) => {
  //GTM DataLayer
  if (question) {
    console.log("Question-" + question);
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "slideclick",
      question: question,
    });
  }
  return <></>;
};

export default GtmDataLayer;
