import react from "react";
import { Dependent } from "../../../../Constants/Constants";
import SelectBox from "../../../UI/SelectBox";
const DependentsV2 = ({
  clsName,
  reff,
  saveInputAns,
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
}) => {
  const handleClick = async (e) => {
    let value = await trigger("dependents");
    let errorFlag = 0;
    if (!value) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      saveInputAns(e);
    } else {
      return false;
    }
  };
  return (
    <>
      <div
        className={`radio-box animated fadeInUp text-center ${clsName}`}
        ref={reff}
      >
        <h3> How many dependents did you have at that time?</h3>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
          <SelectBox
            className="form-select form-control"
            OptionValue={Dependent}
            name="dependents"
            id="dependents"
            onChange={() => clearErrors("dependents")}
            clearErrors={clearErrors}
            myRef={validation({ required: "Please select your dependent" })}
            validationMsg={
              validationMsg.dependents && validationMsg.dependents.message
            }
          />
        </div>
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button
            type="button"
            className="btn-next"
            onClick={handleClick}
            data-quest-id={3}
            data-ans-id={null}
            data-ans-label={null}
            name="dependents"
          >{`NEXT`}</button>
        </div>
      </div>
      {/* <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
        <div className="row">
          <div className="col-lg-8 mb-2">
            <SelectBox
              className="form-select"
              OptionValue={Dependent}
              name="dependents"
              id="dependents"
              onChange={() => clearErrors("dependents")}
              clearErrors={clearErrors}
              myRef={validation({ required: "Please select your dependent" })}
              validationMsg={
                validationMsg.dependents && validationMsg.dependents.message
              }
            ></SelectBox>
          </div>
          <div className="col-lg-4 mb-2 ">
            <button
              type="button"
              className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
              id="nextscroll"
              name="dependents"
              onClick={optionSelected}
              data-quest-id={3}
            >
              Next &#8594;
            </button>
          </div>
        </div>
        <div className="row">
          <div className="text-center mt-2">
            <div className="backbtn" 
            name={didReceiveIncome == 226 ? 'cardMonthlyIncome' : 'didReceiveIncome'} 
            onClick={backClick}>
              <i className="bi bi-arrow-left"></i> Back
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default DependentsV2;
