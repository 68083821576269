import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButtonQuest";
import TextField from "../../../UI/TextField";

const QuestionnaireFinanceActive = ({
  clsName,
  onselectAnswer,
  reff,
}) => {


  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>
          Is your finance agreement with MoneyBarn still active?
        </h3>

        <RadioButton
          key="YES"
          id="yes11"
          value="YES"
          name="stillactive"
          className="radio"
          labelCountString="a"
          labelName="Yes"
          labelClassName="radioa next-5"
          onClick={labelclicked}
          dataAnsId={95}
          dataQuestId={26}
        />
        <RadioButton
          key="NO"
          id="yes12"
          value="NO"
          name="stillactive"
          className="radio"
          labelCountString="b"
          labelName="No"
          labelClassName="radioa next-5"
          onClick={labelclicked}
          dataAnsId={96}
          dataQuestId={26}
        />
        <RadioButton
          key="Not sure"
          id="yes13"
          value="Not sure"
          name="stillactive"
          labelCountString="c"
          className="radio"
          labelName="Not sure"
          labelClassName="radioa next-5"
          onClick={labelclicked}
          dataAnsId={195}
          dataQuestId={26}
        />
      </div>

    </>
  );
};

export default QuestionnaireFinanceActive;
