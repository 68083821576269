import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const ProductClaim = ({
  className,
  slideChange,
  textHeading,
  validation,
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const [checkedQuestionThree,setCheckedQuestionThree] = useState("unchecked");
  const [checkedQuestionFour,setCheckedQuestionFour] = useState("unchecked");
  const [checkedQuestionFive,setCheckedQuestionFive] = useState("unchecked");

  const nextSlide = async (e) => {
    if (e.target.value === "164") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      setCheckedQuestionThree("unchecked");
      setCheckedQuestionFour("unchecked");
      setCheckedQuestionFive("unchecked");
      slideChange(e);
    }
    if (e.target.value === "165") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      setCheckedQuestionThree("unchecked");
      setCheckedQuestionFour("unchecked");
      setCheckedQuestionFive("unchecked");
      slideChange(e);
    }
    if (e.target.value === "166") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("unchecked");
      setCheckedQuestionThree("checked");
      setCheckedQuestionFour("unchecked");
      setCheckedQuestionFive("unchecked");
      slideChange(e);
    }
    if (e.target.value === "167") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("unchecked");
      setCheckedQuestionThree("unchecked");
      setCheckedQuestionFour("checked");
      setCheckedQuestionFive("unchecked");
      slideChange(e);
    }
    if (e.target.value === "168") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("unchecked");
      setCheckedQuestionThree("unchecked");
      setCheckedQuestionFour("unchecked");
      setCheckedQuestionFive("checked");
      slideChange(e);
    }
  };
  return (
    <>
      <div id="slide-1" className={`${className}`}>
        <ProgressBar value="0" />

        <h3> {textHeading} </h3>
        <div className="col-lg-12 mb-pd text-center">
          <div className="btn-comm">
            <RadioButton
              value="164"
              name="productclaim"
              className="radio regNextBtn"
              labelName="Vanquis Classic Card"
              labelClassName={`option_but next1a ${checkedQuestionOne}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
        </div>

        <div className="col-lg-12 mb-pd text-center">
          <div className="btn-comm">
            <RadioButton
              value="165"
              name="productclaim"
              className="radio regNextBtn"
              labelName="Vanquis Chrome Card"
              labelClassName={`option_but next1a ${checkedQuestionTwo}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
        </div>

        <div className="col-lg-12 mb-pd text-center">
          <div className="btn-comm">
            <RadioButton
              value="166"
              name="productclaim"
              className="radio regNextBtn"
              labelName="Vanquis Origin Card"
              labelClassName={`option_but next1a ${checkedQuestionThree}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
        </div>

        <div className="col-lg-12 mb-pd text-center">
          <div className="btn-comm">
            <RadioButton
              value="167"
              name="productclaim"
              className="radio regNextBtn"
              labelName="Vanquis Personal Loan"
              labelClassName={`option_but next1a ${checkedQuestionFour}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
        </div>

        {/* <div className="col-lg-12 mb-pd text-center">
          <div className="btn-comm">
            <RadioButton
              value="168"
              name="productclaim"
              className="radio regNextBtn"
              labelName="Not Sure"
              labelClassName={`option_but next1a ${checkedQuestionFive}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
        </div> */}

      </div>
    </>
  );
};
export default ProductClaim;
