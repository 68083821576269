import React, { useState,useEffect } from "react";
import TextArea from "../../../UI/TextArea";

const Aware = ({
    validationMsg,
    clearErrors,
    validation,
    trigger,
    getValues,
    backClick,
    saveInputAns,
    onSelectAnswer,
    setValue,
    setError
}) => {

    const [checkedOne, setCheckedOne] = useState("unchecked");
    const [textShow, setTextShow] = useState('hide');

    const optionHandle = async (e) => {
        if (e.target.value == "242" || e.target.value == "243") {
            setCheckedOne('');
            setTextShow('hide');
            setValue("aware_reason", null);
            onSelectAnswer(e);
        } else {
            if (textShow == 'show') {
                setCheckedOne('');
                setTextShow('hide');
                setValue("aware_reason", null);
            } else {
                setCheckedOne('clickclass');
                setTextShow('show');
            }
        }
        clearErrors();
    }
    useEffect(() => {
        if (textShow != "hide") {
            const textarea = document.getElementById('aware_reasona');
            if (textarea) {
                textarea.focus();
            }
        }
    }, [textShow]);
    const optionSelected = async (e) => {
        let errorFlag = 0;
        let result = await trigger("aware_reason");
        if (!result) {
            errorFlag = 1;
            return false;
        } else {
            const fieldResult = getValues();
            var reason = fieldResult["aware_reason"].trim();
            if (reason == "" || reason.length < 3) {
                setError("aware_reason", {
                  type: "manual",
                  message: "Please Enter Valid Description",
            });
                return false;
            } else {
            saveInputAns(e);
            }
        }
    };
    return (
        <>
            <div className={`question-content mt-0 scale-down-ver-center   px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3`} >
                <div className="row">
                    <div className="col-lg-4 col-md-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                                onClick={optionHandle}
                                value="242"
                                name="aware"
                                id="nextscrolla"
                                data-quest-id={85}
                                data-ans-id={242}
                                data-ans-label={null}
                            >When TMS Legal contacted me
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className=" mb-2">

                            <button
                                type="button"
                                className="btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 noissue"
                                onClick={optionHandle}
                                value="243"
                                name="aware"
                                id="nextscrollb"
                                data-quest-id={85}
                                data-ans-id={243}
                                data-ans-label={null}
                            >I saw their ad online
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 noissue  ${checkedOne}`}
                                onClick={optionHandle}
                                value="244"
                                name="aware"
                                id="nextscrollc"
                                data-quest-id={85}
                                data-ans-id={244}
                                data-ans-label={null}
                            >Other
                            </button>
                        </div>
                    </div>
                </div>
                <div className={`slide-down mt-3 ${textShow == 'hide' ? 'hide' : ''}`}>
                    <h1 className="mb-2">
                        Please specify
                    </h1>
                    <TextArea
                        type="textarea"
                        className="form-control"
                        name="aware_reason"
                        id="aware_reasona"
                        // placeholder={"Please specify"}
                        validation={validation({
                            required: "Please Enter the Description",
                            minLength: {
                                value: 3,
                                message: "Please Enter Valid Description",
                            },
                        })}
                        validationMsg={
                            validationMsg.aware_reason &&
                            validationMsg.aware_reason.message
                        }
                    ></TextArea>
                    <div className="col-lg-4 mb-2 col-lg-12 mb-2 mt-2">
                        <button
                            type="button"
                            className="qb14 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                            id="nextscroll"
                            name="aware"
                            onClick={optionSelected}
                            data-quest-id={85}
                        >
                            Next &#8594;
                        </button>
                    </div>
                </div>
                <p className="text-center bck-marwid">
                    <button
                        name="oldcard"
                        className="btn btn-secondary backbtn1a"
                        id=""
                        value=""
                        type="button"
                        onClick={backClick}

                    >Go Back</button>
                </p>
            </div>

        </>
    )
}
export default Aware;