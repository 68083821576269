import React ,{useState,useRef} from "react";
import CheckBox from "../../../UI/CheckBox";


const Confirmsituation = ({ 
    onSelectAnswer,
    validation,
    validationMsg,
    getValues,
    trigger,
    setError,
    optionSelected76,
    questArr,
    backClick
}) => {

    const [showError, setshowError] = useState("hide");
    const [btndisabled, setBtndisabled] = useState(false);
    const checkboxRefs = useRef({});


    const labelclicked = async (e) => {
        let date_index = e.target.id;
        let selectedCount = optionSelected76.length;
        if (selectedCount == 0) {
            setBtndisabled(false);
            setshowError("show");
        } else {
            //   setBtndisabled(true);
            onSelectAnswer(e);
            // }
        }
    };

    const onSelectOption = (e) => {
        if (e.target.checked === true) {
            if (e.target.value == '155') {
                optionSelected76.length = 0;
                optionSelected76.push({ id: +e.target.value });
                checkBoxDisable(e.target.value);
            } else {
                setshowError("hide");
                optionSelected76.push({ id: +e.target.value });
            }
        } else {
            let deselect = e.target.value;
            const decre = optionSelected76.map((OptionArray, index) => {
                if (deselect == OptionArray.id) {
                    optionSelected76.splice(index, 1);
                    if (optionSelected76.length == 0) {
                        checkBoxEnable();
                    }
                }
            });
        }
    };


    const handleChange = (e) => {
        // const re = /^[0-9\b]+$/;
        const re = /^[1-9]\d*$/;
        if (e.target.value !== "" && !re.test(e.target.value)) {
            e.target.value = e.target.value.replace(/\D/g, "") * 1;
        }
        e.target.value = e.target.value
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
    let checkArray = questArr.answers;

    const questionId = questArr.questionId ? questArr.questionId : 0;

    const lenderCheckBox = checkArray.map((checArr, index) => {
        let heightcls = checArr.value == "104" ? "heightnewcls" : "";

        return (
            <ul
                className={`p-0 m-0 list mb-3 ${heightcls}`}
                key={checArr.value}
            >
                <div className="optionbtn" ref={(e) => checkboxRefs.current[checArr.value] = e}>
                    <CheckBox
                        dataId={checArr.value}
                        name="chkBankName[]"
                        onChange={onSelectOption}
                        className=""
                        CheckBoxText={`<span>${checArr.label}</span>`}
                        value={checArr.value}
                    />
                </div>

            </ul>
        );
    });


    const checkBoxDisable = (checkBoxId) => {
        checkArray.forEach((checArr, index) => {
            if (checkBoxId != 155) {
                checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
                checkboxRefs.current[checArr.value].childNodes[1].classList.remove(
                    "disabledcheckbox"
                );
            } else {
                checkboxRefs.current[checArr.value].childNodes[0].checked = false;
                checkboxRefs.current[checArr.value].childNodes[0].disabled = true;
                checkboxRefs.current[checArr.value].childNodes[1].classList.remove(
                    "disabledcheckbox"
                );

                checkboxRefs.current[155].childNodes[0].disabled = false;
                checkboxRefs.current[155].childNodes[0].checked = true;
            }
        });
    };

    const checkBoxEnable = () => {
        checkArray.map((checArr, index) => {
            checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
            checkboxRefs.current[checArr.value].childNodes[1].classList.remove(
                "disabledcheckbox"
            );
        });
    };

    return (
        <>
            <div
                className={`question-content mt-0 scale-down-ver-center px-3  py-lg-3 py-md-3 py-sm-2 py-2`}>
                <div className="col-lg-12">
                <ul  className={`p-0 m-0 list mb-3 `} >
                            <li>
                                {lenderCheckBox}
                            </li>
                        </ul>
                    <div className="col-lg-12 mb-2 mt-1">
                    <span className={`error_msg mb-2 ${showError}`} id="email_err">
                            Please select at least one
                        </span>
                        <button className=" btn-comm2   py-3 w-100  goChecklist"
                        type="button"
                        name="btn_qest_36"
                        id="q76"
                        onClick={labelclicked}
                        data-quest-id={36}

                        >
                            {/* <span> */}
                                {/* Next <i className="bi bi-arrow-right" /> */}
                                Next &#8594;
                            {/* </span> */}
                        </button>
                    </div>
                    <div className="  text-center mt-4 ">
                        <button className="backbtn" type="button" onClick={backClick} name="prevfurther">
                            {" "}
                            <i className="bi bi-arrow-left" /> Back
                        </button>
                    </div>
                </div>
            </div>
        </>

    );
}
export default Confirmsituation;