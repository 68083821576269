import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButtonOutLabel";
import TextField from "../../../UI/TextField";
import Button from "../../../UI/Button";

const QuestionnaireStillHave = ({
  questArr,
  onselectAnswer,
  reff,
  display,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  saveSlide4,
  className,
  addPending
}) => {
  const questionId = questArr.questionId ? questArr.questionId : 0;

  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">Do you still have the vehicle?</h1>

            <div className="row mt-4">
              <div className="col-lg-12">
                <div className=" col-lg-12 mb-pd text-center ">
                  <div className="btn-comm com-btn  ">
                    <RadioButton
                      key="YES"
                      id="stillVehicleYes"
                      value="YES"
                      name="stillVehicle"
                      className="radio-button"
                      labelName="Yes"
                      labelClassName="option_but vehicleYes"
                      onClick={labelclicked}
                      dataAnsId={97}
                      dataQuestId={16}
                    />
                    <RadioButton
                      key="NO"
                      id="stillVehicleNo"
                      value="NO"
                      name="stillVehicle"
                      className="radio-button"
                      labelName="No"
                      labelClassName="option_but next-2"
                      onClick={labelclicked}
                      dataAnsId={98}
                      dataQuestId={16}
                    />
                    <RadioButton
                      key="Not sure"
                      id="notSureStillVehicle"
                      value="Not sure"
                      name="stillVehicle"
                      className="radio-button"
                      labelName="Not sure"
                      labelClassName="option_but notsure-btn plateYes"
                      onClick={labelclicked}
                      dataAnsId={195}
                      dataQuestId={16}
                    />
                  </div>
                  <div class="text-center back">
                    <Button 
                    className="btn btn-link mt-2 h2" 
                    id="back1" 
                    type="button"
                    buttonText="<< Previous"
                    onClick={()=>addPending("still_have_vehicle")}
                    />
                </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireStillHave;
