import React, { useState, useEffect, useRef, useReducer } from 'react';
import { useForm } from "react-hook-form";
import Modal from 'react-bootstrap/Modal';
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import {
  questinnare14, questinnare15, questinnare16, questinnare1, questinnare2
} from '../../../../Constants/Questions';
import QuestionnaireFlpVehicle from './QuestionnaireFlpVehicle';
import QuestionnaireFlpKdate from './QuestionnaireFlpKdate';
import QuestionnaireFlpVehicleMake from './QuestionnaireFlpVehicleMake';
import QuestionnaireFlpQ4 from './QuestionnaireFlpQ4';
import QuestionnaireFlpQ5 from './QuestionnaireFlpQ5';
import QuestionnaireFlpFANUpload from './QuestionnaireFlpFANUpload';
import QuestionnaireFlpQ3 from './QuestionnaireFlpQ3';
import QuestionnaireFlpQ3a from './QuestionnaireFlpQ3a';
import { useTotalCarCheck } from "../../../../Hooks/useTotalCarCheck";
import { useHistory } from "react-router-dom";
import QuestionnaireFlpAgreementNo from './QuestionnaireFlpAgreementNo';
import QuestionnaireEmailMoneyBarn from './QuestionnaireEmail_MoneyBarn';

const initialState = {
  showSlide1: "hide",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide",
  showSlide6: "hide",
  showSlide7: "hide",
  showSlide8: "hide",
  showSlide9: "hide",
  showSlide10: "hide",
}
const QuestionnaireReducer = (state, action) => {
  switch (action.type) {
    case 'showSlideChange': {
      if (action.payload == 'slide1') {
        return {
          ...state,
          showSlide1: "show",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
        }
      } else if (action.payload == 'slide2') {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
        }
      } else if (action.payload == 'slide3') {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "show",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
        }
      } else if (action.payload == 'slide4') {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "show",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
        }
      } else if (action.payload == 'slide5') {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "show",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
        }
      } else if (action.payload == 'slide6') {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "show",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
        }
      } else if (action.payload == 'slide7') {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "show",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "hide",
        }
      } else if (action.payload == 'slide8') {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "show",
          showSlide9: "hide",
          showSlide10: "hide",
        }
      } else if (action.payload == 'slide9') {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "show",
          showSlide10: "hide",
        }
      } else if (action.payload == 'slide10') {
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10: "show",
        }
      } else {
        return {
          ...state
        }
      }
    }
  }
}
const QuestionsModal = ({
  questionsPop,
  allFollowupData,
  pendingQuestionnaire,
  splitName,
  currentUuid,
  setValidKeeperDate,
  validKeeperDate,
  userVehicle,
  keeperDates,
  documentType,
  docType,
  sett2aValidation,
  setPendingStatus,
  sendT2aApiReqDataAPI,
  setSkippedUser
}) => {
  const history = useHistory();
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [checkboxValues, SetCheckboxValues] = useState([]);
  const [modalSub1Ans, setModalSub1Ans] = useState(null);
  const [modalSub2Ans, setModalSub2Ans] = useState(null);
  const [totalCarCheck, settotalCarCheck] = useState(null);
  const [pendingArray, setPendingArray] = useState([]);
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const [questSkip, setQestSkip] = useState(null);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const splitForm = useRef(null);

  const [vehicle, setVehicle] = useState("");
  const [optionSelected] = useState([]);
  const { totalCarCheckApi } = useTotalCarCheck();


  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  useEffect(() => {
    setShow(questionsPop);
  }, [questionsPop]);

  useEffect(() => {
    slideChange(pendingQuestionnaire);
  }, [pendingQuestionnaire]);

  const slideChange = (pendingArr) => {
    let pendingCount = pendingArr.length;
    if (pendingCount == 0) {
      setShow(false);
      sendT2aApiReqDataAPI();
      setPendingStatus({ status_btn: 'pending_question', status_flag: 'showTick' })
    } else {
      setPendingStatus({ status_btn: 'pending_question', status_flag: 'showButton' });
    }

    pendingArr.every(element => {
      dispatch({ type: 'showSlideChange', payload: element });
    });

  }
  const storeFollowupQuestionnire = async (question_data) => {
    const response = await saveFollowupUserQuestions(
      question_data,
      allFollowupData,
      'followup_question_store'
    )
  };

  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute('data-ans-id'));
    let selectedQuest = parseInt(e.target.getAttribute('data-quest-id'));
    let anslabel = e.target.getAttribute("data-ans-label");

    let flage = false;
    let question_data = {};
    if (e.target.name == "btn_qest_36") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending('slide9');
    } else if (e.target.name == "question_11") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: e.target.value,
          valid_keeper_date: e.target.id,
        };
      }
      removeFromPending('slide3');
    } else if (e.target.name == "question_12") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending('slide4');
    } else if (e.target.name == "question_43") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending('slide5');
      if(selectedAns == 122){
        removeFromPending('slide6');
      }
    } else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }

    // if(e.target.name == 'question_43' && selectedAns == '121') {
    //   setQuest43('yes');
    // }
  }

  const nextOnClick = () => {
    if (checkboxValues.length > 0) {
      const selectedQuest = 11;
      setCompletedQuestArr([...completedQuestArr, selectedQuest]);
      const checkBoxArr = checkboxValues.map(async (answer, index) => {
        let question_data = '';
        if (answer === '27') {
          question_data = {
            "question_id": "11",
            "option_id": answer,
            "input_answer": modalSub1Ans
          }
        } else if (answer === '32') {
          question_data = {
            "question_id": "11",
            "option_id": answer,
            "input_answer": modalSub2Ans
          }
        } else {
          question_data = {
            "question_id": "11",
            "option_id": answer,
            "input_answer": ""
          }
        };
        const response = await saveFollowupUserQuestions(
          question_data,
          allFollowupData,
          'followup_question_store'
        )
      })
    }
    else {
      //SetDisplayError('d-block');
    }
  }

  const handleCheckBoxChange = (e) => {
    let checkVal = e.target.value;
    if (e.target.checked == true) {
      SetCheckboxValues([...checkboxValues, checkVal]);
    } else {
      SetCheckboxValues(checkboxValues.filter((e) => (e !== checkVal)))
    }
  }

  const onModalClick = (e) => {
    let selectedAns = e.target.getAttribute('data-ans-id');
    let selectedQuest = e.target.getAttribute('data-quest-id');
    if (selectedQuest === 'sub1') {
      setModalSub1Ans(selectedAns);
    } else if (selectedQuest === 'sub2') {
      setModalSub2Ans(selectedAns);
    }
  }

  const saveVehicleDetails = (vehicleData) => {
    setVehicle(vehicleData);
  };
  const savetotalCarCheck = (totalCarCheck) => {
    settotalCarCheck(totalCarCheck);
  };

  const checkCarRegistration = (e) => {
    removeFromPending('slide2');
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    // let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    // let anslabel = e.target.getAttribute("data-ans-label");
    let selectedAns = null;
    let anslabel = splitForm.current["question_10"].value;
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    if (selectedQuest) {
      let question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: anslabel,
        input_answer: anslabel,
      };
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const saveSlide4 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");

    setCompletedQuestArr([...completedQuestArr, selectedQuest, 32, 33, 35]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    //var answer34 = splitForm.current["question_34"].value;
    var answer35 = splitForm.current["question_35"].value;

    var slide_array = [
      ["31", answer31],
      ["32", answer32],
      ["33", answer33],
      //["34", answer34],
      ["35", answer35],
    ];

    var question_data = {
      slide_4: slide_array,
    };
    removeFromPending('slide7');
    storeFollowupQuestionnire(question_data);

    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    const valuesreg = getValues();
    let regPlate = valuesreg["question_44"];
    let agreementNo = valuesreg["question_45"];
    let question_48 = valuesreg["question_48"];
    let flage = false;
    let question_data = {};

    if (selectedQuest == '44' && regPlate) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: regPlate,
      };
      removeFromPending('slide6');
    }
    if (selectedQuest == '45' && agreementNo) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: agreementNo,
      };
      removeFromPending('slide1');
    }
    if (selectedQuest == '48' && question_48) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: question_48,
      };
      removeFromPending('slide8');
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  }

  const removeFromPending = (slidename) => {
    const index = pendingQuestionnaire.indexOf(slidename);
    if (index > -1) {
      pendingQuestionnaire.splice(index, 1);
    }
    slideChange(pendingQuestionnaire);
  }
  const slideSkip = () => {
    removeFromPending('slide2');
    removeFromPending('slide3');
    removeFromPending('slide4');
    removeFromPending('slide5');
    removeFromPending('slide6');
  };

  const pushPageChange = () => {
    if (validKeeperDate) {
      (async () => {
        const totalCarCheckApiData = await totalCarCheckApi(
          vehicle.reg_no,
          allFollowupData,
          splitName
        );
        savetotalCarCheck(totalCarCheckApiData.data.vehicleData);
        // var lender = totalCarCheckApiData.data.vehicleData.finance_company;
        // localStorage.setItem("lenderName", lender);
        // if(lender != "Moneybarn" && lender != undefined && lender != null && lender != ""){
        //   window.scrollTo(0, 0);
        //   history.push("/unqualified?uuid=" + currentUuid);
        // }else{
        //   sett2aValidation();
        // }
        removeFromPending('slide10');
      })();
    } else {
      removeFromPending('slide10');
      //sett2aValidation();
    }
  };

  return (
    <Modal show={show} onHide={handleClose} dialogClassName="FollowupContainer modal-lg modal-content-q modal-dialog-centered" backdrop="static"
      keyboard={false}
    >
      <div className="modal-body mob-pad5">
        <form
          ref={splitForm}
          name="split_form"
          id="user_form"
          method="POST"
          autoComplete="off"
        //onSubmit={handleSubmit}
        >
          <div className={`question1 animated fadeInUp ${state.showSlide1}`} id="breakdown01" >
            <QuestionnaireFlpAgreementNo
              questArr={questinnare1}
              labelclicked={onselectAnswer}
              onselectAnswer={onselectAnswer}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
              saveInputAns={saveInputAns}
            />
          </div>

          <div className={`question2 animated fadeInUp ${state.showSlide2}`} id="breakdown1" >
            <QuestionnaireFlpVehicle
              questArr={questinnare14}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
              checkCarRegistration={checkCarRegistration}
              saveVehicleDetails={saveVehicleDetails}
              splitName={splitName}
              currentUuid={currentUuid}
              slideSkip={slideSkip}
            />
          </div>
          <div className={`question3 animated fadeInUp text-center ${state.showSlide3}`} id="breakdown2" >
            <QuestionnaireFlpKdate
              questArr={questinnare15}
              onselectAnswer={onselectAnswer}
              setValidKeeperDate={setValidKeeperDate}
              vehicle={vehicle}
              validKeeperDate={validKeeperDate}
              keeperDates={keeperDates}
            />
          </div>
          <div className={`question4 animated fadeInUp ${state.showSlide4}`} id="breakdown3" >
            <QuestionnaireFlpVehicleMake
              questArr={questinnare16}
              onselectAnswer={onselectAnswer}
              vehicle={vehicle}
              validKeeperDate={validKeeperDate}
              userVehicle={userVehicle}
            />
          </div>
          <div className={`question5 animated fadeInUp ${state.showSlide5}`} id="breakdown4" >
            <QuestionnaireFlpQ3
              questArr={questinnare16}
              onselectAnswer={onselectAnswer}
            />
          </div>
          <div className={`question6 animated fadeInUp ${state.showSlide6}`} id="breakdown5" >
            <QuestionnaireFlpQ3a
              questArr={questinnare1}
              labelclicked={onselectAnswer}
              onselectAnswer={onselectAnswer}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
              saveInputAns={saveInputAns}
            />
          </div>
          <div className={`question7 animated fadeInUp ${state.showSlide7}`} id="breakdown6" >
            <QuestionnaireFlpQ4
              questArr={questinnare1}
              labelclicked={onselectAnswer}
              onselectAnswer={onselectAnswer}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
              saveSlide4={saveSlide4}
            />
          </div>
          <div className={`question8 animated fadeInUp ${state.showSlide8}`} id="breakdown6a" >
            <QuestionnaireEmailMoneyBarn
              onselectAnswer={onselectAnswer}
              optionSelected={optionSelected}
              validation={register}
              getValues={getValues}
              trigger={trigger}
              validationMsg={errors}
              saveInputAns={saveInputAns}
            />
          </div>

          <div className={`question9 animated fadeInUp ${state.showSlide9}`} id="breakdown7" >
            <QuestionnaireFlpQ5
              questArr={questinnare2}
              onselectAnswer={onselectAnswer}
              optionSelected={optionSelected}
            />
          </div>

          <div className={`question10 animated fadeInUp ${state.showSlide10} radio-box`} id="breakdown8" >
            <QuestionnaireFlpFANUpload
              questArr={questinnare2}
              labelclicked={onselectAnswer}
              onselectAnswer={onselectAnswer}
              validation={register}
              validationMsg={errors}
              getValues={getValues}
              trigger={trigger}
              setError={setError}
              split_name={splitName}
              docType={docType}
              documentType={documentType}
              allFollowupData={allFollowupData}
              sett2aValidation={sett2aValidation}
              pushPageChange={pushPageChange}
              setShow={setShow}
              setPendingStatus={setPendingStatus}
            />
          </div>

        </form>
      </div>
    </Modal>
  )
}
export default QuestionsModal;