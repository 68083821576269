import RadioButtonQuest from "../../../../UI/RadioButtonQuest";


const LatePaymentLetters = ({ onSelectAnswer}) => {

  const optionSelected = async (e) => {
    onSelectAnswer(e);
  };
  return (
    <div className="radio-box question animated fadeInUp">
      <RadioButtonQuest
        type="button"
        className="radio-button"
        onClick={optionSelected}
        value="238"
        name="latePaymentLetters"
        id="latePaymentLetter"
        dataQuestId={82}
        dataAnsId={238}
        labelName={"Yes"}
        labelClassName="option_but next-2"
      />
      <RadioButtonQuest
        type="button"
        className="radio-button"
        onClick={optionSelected}
        value="239"
        name="latePaymentLetters"
        id="latePaymentLetters"
        dataQuestId={82}
        dataAnsId={239}
        labelName={"No"}
        labelClassName="option_but next-2"
      />
    </div>
  );
};
export default LatePaymentLetters;
