import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import { useTotalCarCheck } from "../../../../Hooks/useTotalCarCheck";

const KeeperDate = ({
  className,
  slideChange,
  validation,
  reff,
  trigger,
  clearErrors,
  getValues,
  setError,
  vehicle,
  backClick,
  textHeading,
  setValidKeeperDate,
}) => {
  const { totalCarCheckApi } = useTotalCarCheck();

  const nextSlide = (e) => {
    let date_index = e.target.id;
    let dataNsId = e.target.getAttribute("data-ans-id")
      ? e.target.getAttribute("data-ans-id")
      : "";
      console.log("tis",dataNsId);
      console.log("tis2",date_index);
    if (date_index == "0") {
      localStorage.setItem('ValidKeeper',1);
      setValidKeeperDate(dataNsId);
    } else {
      localStorage.setItem('ValidKeeper',0);
      setValidKeeperDate("");
    }
    slideChange(e);
  };
  let keeperArray = [];
  let keeperData = [];
  if (typeof vehicle.keeper_dates !== "undefined") {
    keeperData = JSON.parse(vehicle.keeper_dates);
    keeperArray = keeperData.keeper_dates;
  }
  return (
    <>
      <div id="slide3" className={`col-12 top_minus ${className}`} ref={reff}>
        <ProgressBar value="30" />
        <h3>{textHeading}</h3>
        <div className="btn-comm mb-1">
          {keeperArray.map((text, index, { length }) => (
            <RadioButton
              key={text.toString()}
              id={index}
              value={text}
              htmlFor="previously"
              tabIndex="1"
              name="keeperDate"
              className="radio-button"
              labelName={text.slice(2)}
              labelClassName={`option_but next2aa`}
              onClick={nextSlide}
              dataAnsId={text}
              dataQuestId={11}
              validation={validation()}
            />
          ))}
          <RadioButton
            key="Other"
            id="Other"
            value="Other"
            htmlFor="previously"
            name="keeperDate"
            className="radio-button"
            labelName="Other"
            labelClassName="option_but next-2aa"
            onClick={nextSlide}
            dataAnsId="80other"
            dataQuestId={11}
            validation={validation()}
          />
          <p className="text-center">
            <a className="back01" name="prevReg" onClick={backClick}>
              {" "}
              {"<< Previous"}{" "}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default KeeperDate;
