import React ,{useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Anchor from '../../../UI/Anchor';

const Footer = () => {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookie, setShowCookie] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);
  const modalClick = (clickValue) => {
    switch (clickValue) {
     
      case "showPrivacy": {
        setShowPrivacy(true);
        break;
      }
      case "exitPrivacy": {
        setShowPrivacy(false);
        break;
      }
      case "showCookie": {
        setShowCookie(true);
        break;
      }
      case "exitCookie": {
        setShowCookie(false);
        break;
      }
      case "showComplaint": {
        setShowComplaint(true);
        break;
      }
      case "exitComplaint": {
        setShowComplaint(false);
        break;
      }
    }
  };
  return (
    <>
 
      <footer>
          <div className="container">
            <div className="col-xl-8 col-lg-8 text-start">
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
              <ul>
                <li><span className = "footer-document" onClick={() => modalClick("showPrivacy")}>Privacy and Cookie Policy</span></li>
                <li><span className = "footer-document" onClick={() => modalClick("showCookie")}>Terms and Conditions</span></li>
                <li><span className = "footer-document" onClick={() => modalClick("showComplaint")}> Complaints Procedure</span></li>
              </ul>
            </div>
          </div>
        </footer>
        <div className="modal fade slideone-modal text-center" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                </p>
                <button type="button" className="btn modal-bttn" data-bs-dismiss="modal"> {`<<`} Back </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade analyz-modal" id="anliseModal">
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content ">
              <div className="modal-body">
                <div id="analize1" className="col-xl-12 col-lg-12  text-center">
                  <h2>Analysing Your Results</h2>
                  <img src="/assets/img/review.gif" alt="" />
                </div>
                <div id="analize2" className="col-xl-12 col-lg-12 text-center animated fadeInUp" style={{ display: 'none' }}>
                  <h2>Good News! </h2>
                  <p className="p_text">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                  </p>
                  <img src="/assets/img/review.gif" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={showPrivacy} size="xl" id="privacy"  className="modal fade">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">PRIVACY AND COOKIE POLICY</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"  onClick={() => modalClick("exitPrivacy")}></button>
              </div>
              <div className="modal-body">
                <h3>Privacy Policy</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal"  onClick={() => modalClick("exitPrivacy")}>Close</button>
              </div>
            </div>
        </Modal>
        <Modal show={showCookie} size="xl" className="modal fade" id="terms">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Terms and Conditions</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => modalClick("exitCookie")}></button>
              </div>
              <div className="modal-body">
                <h3>Terms and Conditions</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => modalClick("exitCookie")}>Close</button>
              </div>
            </div>
        </Modal>
        <Modal show={showComplaint} size="xl" className="modal fade" id="complaints">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">COMPLAINTS PROCEDURE</h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => modalClick("exitComplaint")}></button>
              </div>
              <div className="modal-body">
                <h3>Complaints Procedure</h3>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                </p>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={() => modalClick("exitComplaint")}>Close</button>
              </div>
            </div>
        </Modal>
    </>
  );
}

export default Footer;