import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import Button from "../../../../UI/Button";
// import AlertModal from "./AlertModal";
// import AnalyzeModal from "./AnalayzeModal";

const QuestionnaireBankStmtConfirm = ({
  clsName,
  reff,
  onselectAnswer,
  userEmail,
  className,
  addPending,
  hidePrev
}) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };
  return (
    <>
      <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">
              {" "}
              Did you provide the Bank Statements to Moneybarn at the time you bought the car?
            </h1>

            <div className="row mt-4">
              <div className="col-lg-12">
                <div className=" col-lg-12 mb-pd text-center ">
                  <div className="btn-comm com-btn  ">
                    <RadioButtonQuest
                      key="YES"
                      className="radio-button"
                      labelName="Yes"
                      labelClassName="option_but  bankstmtconfirmYes"
                      // labelCountString="a"
                      onClick={labelclicked}
                      value="1"
                      name="question_73"
                      id="bankstmtconfirmYes"
                      dataQuestId={73}
                      dataAnsId={208}
                      dataAnsLabel="Yes"
                    />
                    <RadioButtonQuest
                      key="NO"
                      className="radio-button"
                      labelName="No"
                      labelClassName="option_but  bankstmtconfirmYes"
                      // labelCountString="b"
                      onClick={labelclicked}
                      value="NO"
                      name="question_73"
                      id="bankstmtconfirmNo"
                      dataQuestId={73}
                      dataAnsId={209}
                      dataAnsLabel="No"
                    />
                  
                  </div>
                  <div class={`text-center back ${hidePrev}`}>
                    <Button 
                    className="btn btn-link mt-2 h2" 
                    id="back1" 
                    type="button"
                    buttonText="<< Previous"
                    onClick={()=>addPending("income_and_expenditure")}
                    />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireBankStmtConfirm;
