import React, { useState } from "react";
import InputButton from "../../../UI/InputButton";

const OB_CreditCheck = ({
  className,
  setLoader,
  loader,
  reloader,
  btndisable,
  obCredStat,
  pushProceedLink,
  redirectTankyou,
}) => {
  const fname = localStorage.getItem("firstname");
  // setTimeout(() => {
  //     setLoader(0);
  //   }, 1500);


  return (
    <>
      <div className={`${obCredStat} OpenBankingV1`}>
        <div
          className={`text-center loader-wrap ${
            loader == 1 ? "showload" : "hide"
          }`}
        >
          <div className="loader">
            <img src="assets/img/review.gif" alt="" />
          </div>
        </div>
        <div className={`${reloader == 1 ? 'show' : 'hide'}`}>
        <div
          className={`main text-center main-div  ${
            loader == 0 ? "showload" : "hide"
          }`}
        >
          <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
            <img
              height="65"
              src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
              alt=""
            />
          </div>
          <div className="container">
            <div className="item-cent-flx ">
              <div className="pt-lg-5 pt-md-3 pt-sm-3  pt-3 text-center">
                <h3 className="mt-lg-5 mt-md-2 mt-sm-2 mt-0 step_head">
                  Step 1 of 2
                </h3>
                <h1 className="mt-2">
                  {fname}, we require your recent bank statements and credit
                  report to complete our comprehensive investigation and
                  finalise your complaint.
                </h1>
                <p className="my-3 sub_head_til">
                  {" "}
                  We do this by connecting to your Bank Account using the FCA
                  regulated Open
                  <br /> Banking platform via Bud Financial.{" "}
                </p>
                <div className="row mt-lg-4 mt-md-2 mt-sm-3 mt-0">
                  <div className="col-lg-6 px-4 mb-2">
                    <div>
                      <button
                        onClick={pushProceedLink}
                        disabled={btndisable}
                        data-btn-label = "connect_bank_account"
                        data-page-label = "OB_LP"
                        className="btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 second anim_green disablebtn"
                      >
                        {" "}
                        Connect Your Bank Account <span> ›</span>{" "}
                      </button>
                      <p className="mt-3 text2b mb-0">
                        <i className="fa fa-lock"></i> &nbsp; Safe & Secure via
                        FCA Regulated Open Banking
                      </p>
                      <p className="mt-0 text3b">
                        <i className="fa fa-clock-o"> </i> Estimated Processing
                        Time: 2 minutes
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 px-4">
                    <div>
                      <button
                        onClick={redirectTankyou}
                        disabled={btndisable}
                        data-btn-label = "pre_paid_envelopes"
                        data-page-label = "OB_LP"
                        className="  btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 disablebtn"
                      >
                        {" "}
                        Pre-Paid Envelopes <span> ›</span>{" "}
                      </button>
                      <p className="mt-3 text2b mb-0">
                        Post your bank statements securely to us via the Royal
                        Mail
                      </p>
                      <p className="mt-0 text3b">
                        <i className="fa fa-clock-o"> </i> Estimated Processing
                        time: 6-8 weeks{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mb-lg-5 mb-md-5 mb-sm-3 mb-3 mt-5 mt-lg-3 mt-md-5 mt-sm-5 ">
                  <img
                    height="50"
                    src="assets/img/safe and secure.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        
        <section id="main-dv" className={`btn-info_section ${reloader == 0 ? 'show' : 'hide'}`}>
        <div className="inside_refresh">
          <div className="container btn-info_part">
            <div className="row justify-content-center ">
              <div className="col-lg-10 col-11">
                <div className={`space text-center`}>
                    <div className="button _1 w-100 anim_blue refresh_btn_outer">
                      <InputButton
                        name="proceedd"
                        className="btn_proceed refresh_btn"
                        id="bb"
                        value="Refresh"
                        btnType="button"
                        onClick={pushProceedLink}
                        dataBtnLabel = "refresh_btn"
                        dataPageLabel = "OB_LP"
                      />
                      <div className="back"></div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          </div>
      </section>

      </div>
    </>
  );
};
export default OB_CreditCheck;
