import React ,{useState,useContext} from 'react';
import {Api} from "../api/Api";
//import {Api,ApiFile} from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";
import VisitorParamsContext from "../Contexts/VisitorParams";

export const useIdUpload = () => {

    const { visitorParameters } = useContext(VisitorParamsContext);
    const uuid=visitorParameters.visitor_parameters.uuid;
    const { ErrorWebhookTrigger } = useErrorWebhook();
    const [isLoading,setIsLoading] = useState(false);

    const saveIdUpload = async (uploadData,visitor_parameters,formParameters,query_string,message_type='user_docs_store') => {
        setIsLoading(true);
        const userDocumentSubmit = await Api.post("api/v1/user_documents", {
            message_type,
            upload_data:uploadData,
            visitor_parameters:visitor_parameters.visitor_parameters,
            form_data:formParameters,
            data:visitor_parameters.data,
            query_string:query_string
        }).catch(function (error) {
            const erroMessage = {
              serviceName : 'ID UPLOAD',
              errorReason:error.message,
              errorCode:error.code,
              uuid:visitor_parameters.uuid
            }
            ErrorWebhookTrigger(erroMessage);
          });
        
        setIsLoading(false);
        return userDocumentSubmit;
    }

    const saveFLPIdUpload = async (uploadData,followup_data,query_string,message_type='flp_support_docs_store') => {
        setIsLoading(true);
        const userDocumentSubmit = await Api.post("api/v1/user_documents", {
            message_type,
            upload_data:uploadData,
            followup_data,
            query_string:query_string
        }).catch(function (error) {
            const erroMessage = {
              serviceName : 'FOLLOWUP ID UPLOAD',
              errorReason:error.message,
              errorCode:error.code,
            }
            ErrorWebhookTrigger(erroMessage);
          });

        setIsLoading(false);
        return userDocumentSubmit;
    }

    const saveFLPUserIdUpload = async (uploadData,followup_data,query_string,message_type='followup_user_docs_store') => {
        setIsLoading(true);
        const userDocumentSubmit = await Api.post("api/v1/user_documents", {
            message_type,
            upload_data:uploadData,
            followup_data:followup_data,
            query_string:query_string
        }).catch(function (error) {
            const erroMessage = {
              serviceName : 'FOLLOWUP ID UPLOAD',
              errorReason:error.message,
              errorCode:error.code,
            }
            ErrorWebhookTrigger(erroMessage);
          });
        
        setIsLoading(false);
        return userDocumentSubmit;
    }

    const saveIDFollowup = async (
        uploadData,
        query_string,
        followup_data,
        message_type='id_followup'
    ) => {
        setIsLoading(true);
        const userDocumentSubmit = await Api.post("api/v1/id-followup-pipeline", {
            message_type,
            upload_data:uploadData,
            followup_data:followup_data,
            query_string:query_string
        }).catch(function (error) {
            const erroMessage = {
              serviceName : 'FOLLOWUP ID UPLOAD',
              errorReason:error.message,
              errorCode:error.code,
            }
            ErrorWebhookTrigger(erroMessage);
          });
        
        setIsLoading(false);
        return userDocumentSubmit;
    }
    return { 
        isLoading,
        saveIdUpload,
        saveFLPIdUpload,
        saveFLPUserIdUpload,
        saveIDFollowup
    }
}