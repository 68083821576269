import React, { useState, useEffect, useRef } from "react";
import "../../../../assets/IdFollowup/css/IdFollowup.scss";
import "../../../../assets/IdFollowup/css/animate.css";
import { Api } from "../../../../api/Api";
import { queryString } from "../../../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useFollowupLoad } from "../../../../Hooks/useFollowupLoad";
import UploadSection from "../../../Includes/Layouts/IdFollowup/UploadSection";

const IdUpload = {
  Initial: {
    name: "",
    uploadName: "",
    class1: "",
    class2: "",
    class3: "",
  },
  IdCard: {
    name: "National ID Card",
    uploadName: "nationalIDCard_doc",
    class1: "",
    class2: "d-none d-lg-block",
    class3: "d-none d-lg-block",
  },
  Drivers: {
    name: "Driver’s Licence",
    uploadName: "driversLicense_doc",
    class1: "d-none d-lg-block",
    class2: "",
    class3: "d-none d-lg-block",
  },
  Passport: {
    name: "Passport",
    uploadName: "passport_doc",
    class1: "d-none d-lg-block",
    class2: "d-none d-lg-block",
    class3: "",
  },
};

const IdFollowup = (props) => {
  const [idShow, SetIdShow] = useState("hide");
  const [uploadSection, setUploadSection] = useState("hide");
  const [showLoad, SetShowLoad] = useState("show");
  const [lender, setLender] = useState(false);
  const [idUploadState, setIdUploadState] = useState(IdUpload.Initial);
  const { loadFollowup } = useFollowupLoad();
  const scrollRef = useRef(null);
  const history = useHistory();

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       window.scrollTo(0, 0);
  //       const response = await Api.get(
  //         "/api/v1/followup/id-followup?query_string=" + queryString,
  //         {}
  //       );

  //       if (response.data.status === "Success") {
  //         let followupDetails = response.data.response.followup_data;

  //         const flpResponse = await loadFollowup(
  //           "followup_load",
  //           followupDetails,
  //           "Split_1",
  //           queryString,
  //           "v2"
  //         );

  //         setLender(response.data.response.lender);
  //         setTimeout(() => {
  //           SetShowLoad("hide");
  //           if (response.data.response.completed == 1) {
  //             history.push("/thankyou?uuid=" + followupDetails.uuid);
  //           } else {
  //             SetIdShow("show");
  //           }
  //         }, 2000);
  //         SetFollowupData(followupDetails);
  //       } else {
  //         history.push("/thankyou");
  //       }
  //     } catch (error) {
  //       console.warn(error);
  //       history.push("/thankyou?query_string=" + queryString);
  //     }
  //   })();
  // }, []);

  const scrollToBottom = () => {
    setTimeout(() => {
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }, 200);
    clearTimeout();
  };

  const onSelectUpload = (data) => {
    setUploadSection("show");
    switch (data) {
      case "IdCard":
        setIdUploadState(IdUpload.IdCard);
        scrollToBottom();
        break;
      case "Drivers":
        setIdUploadState(IdUpload.Drivers);
        scrollToBottom();
        break;
      case "Passport":
        setIdUploadState(IdUpload.Passport);
        scrollToBottom();
        break;
      default:
        setIdUploadState(IdUpload.Initial);
        window.scrollTo(0, 0);
        setUploadSection("hide");
        break;
    }
  };

  return (
    <div className="id_followup">
      <section className="tms_id_upload bg_img text-center">
        <div className="container text-center tmsbodysec">
          <img
            className="img-here"
            src="/assets/IdFollowup/img/logo.png"
            alt=""
          />
          <div className="tms_main">
            <div className="row tms_main_row">
              <div className="col-12">
                <h3 className="ques-title">
                  To satisfy the Solicitors Regulation Authority, law firms
                  must be sure they know who they are representing & perform
                  ID checks to prevent fraud (also known as KYC "Know Your
                  Customer" checks).
                </h3>
                <p className="sub_title">
                  To proceed with your complaint against {lender}, we must
                  receive a picture of your official ID. Please select from
                  the below:
                </p>
              </div>
            </div>
            <div className="row g-2 justify-content-evenly select_box_combo">
              <button
                className={`col-lg-3 col-md-3 col-sm 12 col-12 button-transparent ${idUploadState.class1}`}
                onClick={() => {
                  onSelectUpload("IdCard");
                }}
              >
                {" "}
                <div className="select_box_1 hover-eff " id="s_box_1">
                  <p>
                    National <br />
                    ID Card
                  </p>
                  <div className="tik_box1 text-end">
                    <div className="icon_sec">
                      <img
                        src="/assets/IdFollowup/img/arrow-mark.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </button>
              <button
                className={`col-lg-3 col-md-3 col-sm 12 col-12 button-transparent ${idUploadState.class2}`}
                onClick={() => {
                  onSelectUpload("Drivers");
                }}
              >
                <div className="select_box_2 hover-eff" id="s_box_2">
                  <p>
                    Driver’s <br />
                    Licence
                  </p>
                  <div className="tik_box2 text-end">
                    <div className="icon_sec">
                      <img
                        src="/assets/IdFollowup/img/arrow-mark.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </button>
              <button
                className={`col-lg-3 col-md-3 col-sm 12 col-12 button-transparent ${idUploadState.class3}`}
                onClick={() => {
                  onSelectUpload("Passport");
                }}
              >
                {" "}
                <div className="select_box_3 hover-eff">
                  <p>Passport</p>
                  <div className="tik_box3 text-end">
                    <div className="icon_sec">
                      <img
                        src="/assets/IdFollowup/img/arrow-mark.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </button>
            </div>
            <UploadSection
              show={uploadSection}
              followupData={props.allFollowupData}
              scrollRef={scrollRef}
              idUploadState={idUploadState}
              backHide={onSelectUpload}
              uuid={props.currentUuid}
              preview={'preview_flp_v4'}
            />
          </div>
          <footer>
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-12 p-0 text-lg-end text-center">
                  <p className="m-0 br-ryt px-3">
                    {" "}
                    Document should be Clear & Legible
                  </p>
                </div>
                <div className="col-lg-6 col-12 p-0 text-lg-start text-center">
                  <p className="m-0 px-3">
                    Entire Document should be Captured
                  </p>
                </div>
              </div>
            </div>
          </footer>

        </div>
      </section>{" "}
    </div>
  );
};

export default IdFollowup;
