import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
// import AlertModal from "./AlertModal";
// import AnalyzeModal from "./AnalayzeModal";

const QuestionnaireEmailConfirmation = ({
  clsName,
  reff,
  onselectAnswer,
  userEmail,
}) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };
  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
            <h3>
              {" "}
              Is this the email address "{userEmail}" that you registered with
              MoneyBarn
            </h3>

                    <RadioButtonQuest
                      key="YES"
                      className="radio"
                      labelName="Yes"
                      labelClassName="radioa"
                      labelCountString="a"
                      onClick={labelclicked}
                      value="1"
                      name="question_61"
                      id="sixone"
                      dataQuestId={61}
                      dataAnsId={162}
                    />
                    <RadioButtonQuest
                      key="NO"
                      className="radio"
                      labelName="No"
                      labelClassName="radioa"
                      labelCountString="b"
                      onClick={labelclicked}
                      value="NO"
                      name="question_61"
                      id="sixtwo"
                      dataQuestId={61}
                      dataAnsId={163}
                    />
                    <RadioButtonQuest
                      key="NotSure"
                      className="radio"
                      labelName="Not sure"
                      labelClassName="radioa"
                      labelCountString="c"
                      onClick={labelclicked}
                      value="Not Sure"
                      name="question_61"
                      id="sixthree"
                      dataQuestId={61}
                      dataAnsId={195}
                    />
                  </div>
               
           
          
         
       
    
    </>
  );
};

export default QuestionnaireEmailConfirmation;
