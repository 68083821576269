import React,{useContext, useEffect, useState}  from 'react'
import IdUploadS2 from './IdUploadS2';
import DocPreviewS2 from './DocPreviewS2';
// import "../../assets/MB_UFGN_1_KYC/scss/custom.scss";
import "../../assets/MB_UFGN_1_KYC/scss/id_upload.scss";
import "../../assets/MB_UFGN_1_KYC/scss/openbank.scss";
import "../../assets/MB_UFGN_1_KYC/scss/style.scss";
import EditPersonalDetailsS2 from './EditPersonalDetailsS2';
import IdUpload_MB_UFGN_1 from './IdUpload_MB_UFGN_1';
import DocPreview_MB_UFGN_1 from './DocPreview_MB_UFGN_1';
import EditPersonalDetails_MB_UFGN_1 from './EditPersonalDetails_MB_UFGN_1';
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GetVisitorsParams from '../../Utility/GetVisitorsParams';
import Loader3 from '../Includes/Layouts/FollowupS2/Loader3';


const DocsPreviewPageMB_UFGN_1 = () => {
  const [previewPage,setPreviewPage] = useState('hide');
  const [IdUpload,setIdUpload] = useState('hide');
  const [editDetails,setEditDetails] = useState('hide');

  const { visitorParameters } = useContext(VisitorParamsContext);

  const [showfirstloader, setShowfirstloader] = useState("show");
  // setTimeout(() => {
  //   setShowloader("hide");
  //   // setPreviewPage("show");
  // }, 2000);

  // const uuid=visitorParameters.visitor_parameters.uuid;

  return (
    <>
    <GetVisitorsParams />
    
    <div className='MB_UFGN_1_KYC'>
     
    <div className={`tms-wrap2 bg-main-pr ${showfirstloader}`}>
        <Loader3 
        // fname={UcFname} 
        showLoad={showfirstloader} 
        />
        </div>

      <DocPreview_MB_UFGN_1 
      className={previewPage}
      setIdUpload={setIdUpload}
      setEditDetails={setEditDetails}
      setPreviewPage={setPreviewPage}
      previewPage={previewPage}
      />

     

      <IdUpload_MB_UFGN_1 
      className={IdUpload}
    //   setPreviewPage={setPreviewPage}
    //   setIdUpload={setIdUpload}
    //   setEditDetails={setEditDetails}
      />

     

      <EditPersonalDetails_MB_UFGN_1 
      className={editDetails}
      setPreviewPage={setPreviewPage}
      setIdUpload={setIdUpload}
      setEditDetails={setEditDetails}
      setShowfirstloader={setShowfirstloader}
      />

    </div>
    </>
  )
}

export default DocsPreviewPageMB_UFGN_1
