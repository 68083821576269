import React from "react";
import "../../../assets/MB_UFGN_4_1/scss/modules/MB_UFGN_4_1.scss";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import Header from "../../Includes/Layouts/MB_UFGN_4_1/Header";
import FormMB_UFGN_4_1 from "../../Forms/FormMB_UFGN_4_1";
import Section1 from "../../Includes/Layouts/MB_UFGN_4_1/Section1";
import AccordSection from "../../Includes/Layouts/MB_UFGN_4_1/AccordSection";
import Footer from "../../Includes/Layouts/MB_UFGN_4_1/Footer";
import FbViewPixel from "../../../Utility/FbViewPixel";
const MB_UFGN_4_1 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="MB_UFGN_4_1" />
      <FbViewPixel />
      <div className="MB_UFGN_4_1">
        <Header />
        <FormMB_UFGN_4_1 />
        <Section1 />
        <AccordSection />
        <Footer />
      </div>
    </>
  );
};
export default MB_UFGN_4_1;