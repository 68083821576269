import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import RadioButton from "../../../UI/RadioButton";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const Filed = ({
    onSelectAnswer,
    validationMsg,
    clearErrors,
    validation,
    trigger,
    getValues,
    splitForm,
    backClick
}) => {
    const [checkedOne, setCheckedOne] = useState("unchecked");
    const [checkedTwo, setCheckedTwo] = useState("unchecked");
    const [checkedThree, setCheckedThree] = useState("unchecked");
    const [questResult, setQuestResult] = useState(false);


    const selectedClick = async (e) => {
        console.log(e.target.name);
        e.preventDefault();
        let errorFlag = 0;
        let optionselect = splitForm.current["bankstmt"].value;
        if (optionselect == '') {
            errorFlag = 1;
            setQuestResult(true);
            return false;
        } else {
            onSelectAnswer(e);
        }
    }


    const labelclicked = async (e) => {
        console.log("did_Bank_Stmt_provide: " + e.target.value);
        localStorage.setItem("did_Bank_Stmt_provide", e.target.value);
        if (e.target.value === "210") {
            setCheckedOne("checked");
            localStorage.setItem("OBStatement", 0);
            localStorage.setItem("BankStatement", 0);
        } else {
            setCheckedOne("unchecked");
        }

        if (e.target.value === "211") {
            setCheckedTwo("checked");
            localStorage.setItem("OBStatement", 1);
            localStorage.setItem("BankStatement", 1);
        } else {
            setCheckedTwo("unchecked");
        }

        if (e.target.value === "212") {
            setCheckedThree("checked");
            localStorage.setItem("OBStatement", 0);
            localStorage.setItem("BankStatement", 0);
        } else {
            setCheckedThree("unchecked");
        }
        setQuestResult(false);

    }
    return (
        <>
            <div className={`question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2`}>
                <div className="row">
                    <div className="col-lg-12 mb-2">
                        <ul className="p-0 m-0 option-grid doculist">
                            <li>
                                <div className="optionbtn">
                                    <RadioButtonQuest
                                        name="bankstmt"
                                        id="optionzero11"
                                        labelClassName={`${checkedOne}`}
                                        value="210"
                                        labelName="Payslips"
                                        onClick={labelclicked}
                                        dataQuestId={74}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="optionbtn">
                                    <RadioButtonQuest
                                        name="bankstmt"
                                        id="optionzero12"
                                        labelClassName={`${checkedTwo}`}
                                        value="211"
                                        labelName="Bank Statements"
                                        onClick={labelclicked}
                                        dataQuestId={74}
                                    />
                                </div>
                            </li>
                            <li>
                                <div className="optionbtn">

                                    <RadioButtonQuest
                                        name="bankstmt"
                                        id="optionzero13"
                                        labelClassName={`${checkedThree}`}
                                        value="212"
                                        labelName="Can't remember"
                                        onClick={labelclicked}
                                        dataQuestId={74}
                                    />
                                </div>
                            </li>
                        </ul>
                        {questResult && (
                            <span className="error_msg m-0 mt-2">Please select an option</span>
                        )}
                    </div>
                    <div className="col-lg-12 mb-2 mt-2">
                        <button className=" btn-comm2 py-3 w-100 nextBtn"
                            name="bankstatement"
                            id="nextscroll"
                            type="button"
                            onClick={selectedClick}
                            data-quest-id={74}
                        >
                            {/* <span> */}
                            Next &#8594;
                            {/* </span> */}
                            {/* Next &#8594;  */}
                        </button>
                    </div>
                    <div className=" text-center mt-2">
                        <button className="backbtn" type="button" name="struggle" onClick={backClick}>
                            {" "}
                            <i className="bi bi-arrow-left" /> Back
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Filed;