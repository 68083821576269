import TextArea from "../../../../UI/TextArea";

const FinanceProblem = ({
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  saveInputAns,
  setError
}) => {
  const optionSelected = async (e) => {
    let errorFlag = 0;
    let result = await trigger("financeProblem");
    if (!result) {
      errorFlag = 1;
      return false;
    } else {
      const fieldResult = getValues();
      var reason = fieldResult["financeProblem"].trim();
      if (reason == "" || reason.length < 3) {

       setError("financeProblem", {
          type: "manual",
          message: "Please Enter Valid Reason",
       });
       return false;
      } else {
       saveInputAns(e);
      }   
    }
  };
  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
        <TextArea
          type="textarea"
          className="form-control"
          name="financeProblem"
          validation={validation({
            required: "Please Enter the Reason",
            minLength: {
              value: 3,
              message: "Please Enter Valid Reason",
            },
          })}
          validationMsg={
            validationMsg.financeProblem && validationMsg.financeProblem.message
          }
        />
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button
            type="button"
            className="btn-next"
            onClick={optionSelected}
            data-quest-id={84}
            name="problem"
            id="nextscroll"
          >
            NEXT
          </button>
        </div>
      </div>
    </>
  );
};
export default FinanceProblem;
