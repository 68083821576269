import React, { useState, useRef } from 'react';
import TextField from '../../../../UI/TextField';
import CheckBox from '../../../../UI/CheckBox';

const QuestionnaireFlpQ5 = ({
  questArr,
  reff,
  display,
  onselectAnswer,
  optionSelected,
}) => {
  const [showError, setshowError] = useState("hide");
  const checkboxRefs = useRef({});

  const labelclicked = async (e) => {
    let date_index = e.target.id;
    let selectedCount = optionSelected.length;
    if (selectedCount == 0) {
      setshowError("show");
    } else {
      // GtmDataLayer({
      //   'question': 'select one'
      // });
      onselectAnswer(e);
    }
  };

  const onSelectOption = (e) => {
    if (e.target.checked === true) {
      setshowError("hide");
      optionSelected.push({ id: +e.target.value });
      checkBoxDisable(e.target.value);
    } else {
      let deselect = e.target.value;
      const decre = optionSelected.map((OptionArray, index) => {
        if (deselect == OptionArray.id) {
          optionSelected.splice(index, 1);
        }
        if (optionSelected.length == 0) {
          checkBoxEnable();
        }
      });
    }
  };

  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    e.target.value = e.target.value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  let checkArray = questArr.answers;
  const questionId = questArr.questionId ? questArr.questionId : 0;
  const lenderCheckBox = checkArray.map((checArr, index) => {
    return (
      <li className="popupLink" key={checArr.value} ref={(e) => checkboxRefs.current[checArr.value] = e}>
        <CheckBox
          dataId={checArr.value}
          name="chkBankName[]"
          onChange={onSelectOption}
          className="custom-checkbox"
          CheckBoxText={checArr.label}
          value={checArr.value}
        />
      </li>
    );
  });

  const checkBoxDisable = (checkBoxId) => {
    if (checkBoxId != 155) {
      checkArray.map((checArr, index) => {
        checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
        checkboxRefs.current[checArr.value].childNodes[1].classList.remove("disabledcheckbox");
      })
      checkboxRefs.current[155].childNodes[0].disabled = true;
      checkboxRefs.current[155].childNodes[1].classList.add("disabledcheckbox");
    } else if (checkBoxId == 155) {
      checkArray.map((checArr, index) => {
        checkboxRefs.current[checArr.value].childNodes[0].disabled = true;
        checkboxRefs.current[checArr.value].childNodes[1].classList.add("disabledcheckbox");
      })
      checkboxRefs.current[155].childNodes[0].disabled = false;
      checkboxRefs.current[155].childNodes[1].classList.remove("disabledcheckbox");
    }
  }

  const checkBoxEnable = () => {
    checkArray.map((checArr, index) => {
      checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
      checkboxRefs.current[checArr.value].childNodes[1].classList.remove("disabledcheckbox");
    })
  }

  return (
    <>
      <div style={{ 'display': display }}>
        <div className="question animated fadeInUp" ref={reff} >
          <h3 style={{ color: '#69a2d7' }} className='text-center'><b>{questArr.question}</b></h3>
          <ul className="options secondop">{lenderCheckBox}</ul>

          <div className="col-xl-12 col-lg-12 text-center p-0" >
            <span className={`error_msg ${showError}`} id="email_err">
              Please select at least one
            </span>
            <button
              type="button"
              className="btn-next"
              onClick={labelclicked}
              data-quest-id={questionId}
              data-ans-id={null}
              data-ans-label={null}
              name="btn_qest_36"
            >{`Next`}</button>
          </div>
        </div>
      </div>

    </>
  )
}

export default QuestionnaireFlpQ5;