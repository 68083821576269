import React, { useState, useRef } from 'react';
import TextField from '../../../../UI/TextField';
import CheckBox2 from '../../../../UI/CheckBox2';
import Button from '../../../../UI/Button';

const QuestionnaireFlpQ5 = ({
  questArr,
  reff,
  display,
  onselectAnswer,
  optionSelected,
  addPending,
  className
}) => {
  const [showError, setshowError] = useState("hide");
  const checkboxRefs = useRef({});

  const labelclicked = async (e) => {
    let date_index = e.target.id;
    let selectedCount = optionSelected.length;
    if (selectedCount == 0) {
      setshowError("show");
    } else {
      // GtmDataLayer({
      //   'question': 'select one'
      // });
      onselectAnswer(e);
    }
  };

  const onSelectOption = (e) => {
    if (e.target.checked === true) {
      setshowError("hide");
      optionSelected.push({ id: +e.target.value });
      checkBoxDisable(e.target.value);
    } else {
      let deselect = e.target.value;
      const decre = optionSelected.map((OptionArray, index) => {
        if (deselect == OptionArray.id) {
          optionSelected.splice(index, 1);
        }
        if (optionSelected.length == 0) {
          checkBoxEnable();
        }
      });
    }
  };

  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    e.target.value = e.target.value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  let checkArray = questArr.answers;
  const questionId = questArr.questionId ? questArr.questionId : 0;
  const lenderCheckBox = checkArray.map((checArr, index) => {
    return (
        <div className="slect_bank_item" key={checArr.value} ref={(e) => checkboxRefs.current[checArr.value] = e}>
          <CheckBox2
            dataId={checArr.value}
            name="chkBankName[]"
            onChange={onSelectOption}
            className="hiden_chekbox label"
            CheckBoxText={checArr.label}
            value={checArr.value}
          />
        </div>
    );
  });

  const checkBoxDisable = (checkBoxId) => {
    if (checkBoxId != 155) {
      checkArray.map((checArr, index) => {
        checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
        checkboxRefs.current[checArr.value].childNodes[1].classList.remove("label");
      })
      checkboxRefs.current[155].childNodes[0].disabled = true;
      checkboxRefs.current[155].childNodes[1].classList.add("label");
    } else if (checkBoxId == 155) {
      checkArray.map((checArr, index) => {
        checkboxRefs.current[checArr.value].childNodes[0].disabled = true;
        checkboxRefs.current[checArr.value].childNodes[1].classList.add("label");
      })
      checkboxRefs.current[155].childNodes[0].disabled = false;
      checkboxRefs.current[155].childNodes[1].classList.remove("label");
    }
  }

  const checkBoxEnable = () => {
    checkArray.map((checArr, index) => {
      checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
      checkboxRefs.current[checArr.value].childNodes[1].classList.remove("label");
    })
  }

  return (
    <>
      <div className={className}>
        <div className="col-lg-6 offset-lg-3" ref={reff} >
          <h1> At the time you secured your finance with MoneyBarn, which of the below statements accurately describe your situation</h1>
          <div className="form-box  mt-4 ">{lenderCheckBox}</div>

          <div className="col-xl-12 col-lg-12 text-center p-0" >
            <span className={`error_msg ${showError}`} id="email_err">
              Please select at least one
            </span>
            <button
              type="button"
              className="btn-next w-100"
              onClick={labelclicked}
              data-quest-id={questionId}
              data-ans-id={null}
              data-ans-label={null}
              name="btn_qest_36"
            >{`Next`}</button>
          </div>
          <div class="text-center back">
              <Button 
              className="btn btn-link mt-2 h2" 
              id="back1" 
              type="button"
              buttonText="<< Previous"
              onClick={()=>addPending("q36")}
              />
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionnaireFlpQ5;