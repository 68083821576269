import React, { useState } from "react";
import Email from "../../../UI/Email";
import { useEmailValidation } from "../../../../Hooks/useEmailValidation";

const QuestionnaireEmail = ({
  clsName,
  reff,
  onselectAnswer,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError
}) => {

  const { emailValidation } = useEmailValidation();

  const selectClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();
    let question48Result = await trigger("question_48");
    if (!question48Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag === 0) {
      onselectAnswer(e);
    }
  };

  const emailValidCheck = async () => {
    const values = getValues();
    const email = values["question_48"];

    if (email !== "") {
      const getEmailValidation = await emailValidation(email);
      if (getEmailValidation.data.status_code === 1) {
        return 1;
      } else {
        setError("question_48", {
          type: "manual",
          message: getEmailValidation.data.message,
        });
      }
    }
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>Please provide the e-mail address you registered with MoneyBarn</h3>
        <div className="input-group mb-3">
          <Email
            name="question_48"
            className="form-control"
            id="question_48"
            placeholder="Email Address"
            // onBlur={emailValidCheck}
            validation={validation({
              required: "Please Enter Valid Email Address",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid Email Address",
              },
            })}
          ></Email>
          {validationMsg.question_48 && (
            <span className="error_msg " id="email_err">
              {validationMsg.question_48.message}
            </span>
          )}
        </div>
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button
            type="button"
            className="btn-next"
            onClick={selectClick}
            data-quest-id={48}
            data-ans-id={null}
            data-ans-label={null}
            name="btn_qest_48"
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};
export default QuestionnaireEmail;