import React, { useState } from 'react';
import ProgressBar from '../Common/ProgressBar';
import TextField from '../../../UI/TextField';
import RadioButton from '../../../UI/RadioButton';
//import { BankList } from "../../../../Constants/Lender";
import Select from 'react-select';


const BankName = ({
  className,
  slideChange,
  validation,
  SelectBank,
  setSelectBank,
  validationMsg,
  trigger,
  clearErrors,
  getValues,
  setError,
  Year,
  backClick,
  textHeading,
  lenderList,
  skip
}) => {

  const [BankValidationMsg, setBankValidationMsg] = useState(false);

  const customStyles = {
    control: base => ({
      ...base,
      //height: 50,
      minHeight: 50
    })
  };

  const nextSlide = async (e) => {
    let LengthSelectedBank = SelectBank.length;
    let ValueSelectedBank = SelectBank.value;
    if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
      setBankValidationMsg("Please Select Bank");
    } else {
      slideChange(e);
    }
  }

  function handleSelect(opt) {
    setSelectBank(opt);
    setBankValidationMsg(false);
  }
  let BankList = []; //[{ value: null,  label: "---Select Bank Name---" }];
  // if(lenderList.length >0){
  //   lenderList.map(function (val) {
  //     let lenderData = { value: val.provider, label:val.display_name}
  //     BankList.push(lenderData);
  //   })
  // }

  if (lenderList.length > 0) {
    BankList = BankList.concat(lenderList.filter(function (val) {
      return !val.display_name.endsWith("Card") &&
        !val.display_name.endsWith("Business") &&
        !val.display_name.endsWith("card") &&
        !val.display_name.endsWith("Cards");
    }).map(function (val) {
      return {
        value: val.provider,
        label: val.display_name
      };
    }));
  }
  return (
    <>
      <div id="slide3" className={`${className}`}>
        <ProgressBar value="30" />
        {/* <h3>{(skip==true) ? `At the time you secured finance, which bank did you use to manage your income and expenditures?` : `In ${Year}, at the time you secured finance, which bank did you use to manage your income and expenditures?`}</h3> */}
        <h3>Which bank were you with when you took the finance with Moneybarn?</h3>

        <div className="input-section mb-3">
          <Select
            placeholder="Select Bank Name"
            options={BankList}
            aria-label="Username"
            name="bankName"
            id="bankName"
            value={SelectBank}
            onChange={handleSelect}
            isSearchable={true}
            styles={customStyles}
          //  isMulti
          // validation={validation({
          //   required: "Please Enter Bank Name",
          //   minLength: {
          //     value: 3,
          //     message: "Please Enter valid A Bank Name",
          //   }
          // })}
          // validationMsg={validationMsg?.bankName?.message}
          />
          <span id="bank_err" className="error_msg">{BankValidationMsg}</span>
        </div>
        <div className="btn-comm">

          <RadioButton
            name="bankname"
            id={"bankname"}
            className="radio-button"
            labelName="Next"
            labelClassName={`option_but  next3a`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <p className="text-center"><a className="back01" name="back05" onClick={backClick}> {'<< Previous'} </a></p>
      </div>
    </>
  );
};

export default BankName;