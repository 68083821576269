import React, { useContext, useEffect, useRef, useState } from "react";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import InputButton from "../UI/InputButton";
import TextField from "../UI/TextField";
import { DateOfBirth } from "../../Utility/DateOfBirth";
import { useForm } from "react-hook-form";
import PostCode from "../Includes/Layouts/FollowupS2/MoneyBarn/PostCode";
import Loader from "../Includes/Layouts/FollowupS2/Loader";
import { useUserVerify } from "../../Hooks/useUserVerify";
import { useHistory } from "react-router-dom";
import Loader2 from "../Includes/Layouts/FollowupS2/Loader2";
import TextField2 from "../UI/TextField2";
import PostCode2 from "./PostCodeMB_GN_4";
import SelectBox from "../UI/SelectBox";
import { Salutation } from "../../Constants/Questions";
import AddressTextField from "../UI/AddressTextField";
import GetVisitorsParams from '../../Utility/GetVisitorsParams';
import { Api } from "../../api/Api";
import { urlParams } from "../../Utility/QueryString";
import Loader3 from "../Includes/Layouts/FollowupS2/Loader3";
import { useLogRecord } from "../../Hooks/UseLogRecord";
import { DobYear, DobMonth, DobDay } from '../../Constants/Constants';
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

const EditKycDetailsMB_UFGN_4 = ({
    className,
    setEditKycDiv,
    setShowfirstloader,
    setOptionDiv
}) => {

    const { register, errors, trigger, setError, clearErrors, getValues } =
        useForm({
            mode: "onBlur",
            reValidateMode: "onChange",
            defaultValues: {},
            resolver: undefined,
            context: undefined,
            criteriaMode: "firstError",
            shouldFocusError: true,
            shouldUnregister: true,
        });

    const splitForm = useRef(null);
    const query_string = localStorage.getItem("querystring");
    const { visitorParameters } = useContext(VisitorParamsContext);
    const getCurrentDateTime = () => {
        const currentDate = new Date();

        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate();
        const hours = currentDate.getHours();
        const minutes = currentDate.getMinutes();
        const seconds = currentDate.getSeconds();

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    //   const uuid = localStorage.getItem("uuid");
    // uuid=urlParams.get("uuid");

    let uuid = visitorParameters.visitor_parameters.uuid ? visitorParameters.visitor_parameters.uuid : urlParams.get("uuid");
    localStorage.setItem("uuid", uuid);

    const [local_lName, setLocalLName] = useState('');
    const [local_fName, setLocalFName] = useState('');
    const [local_pCode, setLocalPCode] = useState('');
    const [local_address_line1, setLocalAddressLine1] = useState('');
    const [local_dob_yr, setLocalDobYr] = useState('');
    const [local_dob_month, setLocalDobMonth] = useState('');
    const [local_dob_day, setLocalDobDay] = useState('');
    const [local_address_line2, setLocalAddressLine2] = useState('');
    const [local_town, setLocalTown] = useState('');
    const [local_country, setLocalCountry] = useState('');
    const [local_title, setLocalTitle] = useState('');
    const [local_addressLine3, setLocalAddressLine3] = useState('');
    const [local_county, setLocalCounty] = useState('');
    const [local_district, setLocalDistrict] = useState('');
    const [local_addressLine4, setLocalAddressLine4] = useState('');
    const [UcFname, setUcFname] = useState('');

    const {updateMilestone} = useUpdateMilestone();


    useEffect(() => {
        (async () => {
            if (uuid) {
                const response = await Api.get(
                    "/api/v1/user-basic-details-s2?uuid=" + uuid,
                    {}
                );
                if (response.data.status === "Success") {



                    let dob = response.data.response.dob;

                    const dateParts = dob.split('/');


                    const dobDayformat = dateParts[0].replace(/^0+/, ''); // Day
                    const monthNumeric = dateParts[1]; // Numeric value of the month
                    const dobYearformat = dateParts[2]; // Year

                    const getMonthName = (month) => {
                        const monthNames = [
                            'January', 'February', 'March', 'April', 'May', 'June',
                            'July', 'August', 'September', 'October', 'November', 'December'
                        ];
                        return monthNames[parseInt(month, 10) - 1]; // Subtract 1 as array is zero-indexed
                    };

                    // Get the name of the month
                    const dobMonthNameformat = getMonthName(monthNumeric);

                    // Now you have day, month name, and year separately
                    console.log('Day:', dobDayformat);
                    console.log('Month Name:', dobMonthNameformat);
                    console.log('Year:', dobYearformat);


                    setLocalLName(response.data.response.txtLName);
                    setLocalFName(response.data.response.txtFName);
                    setLocalPCode(response.data.response.txtPostcode);
                    setLocalAddressLine1(response.data.response.txtAddressline1);
                    setLocalDobYr(dobYearformat);
                    setLocalDobMonth(dobMonthNameformat);
                    setLocalDobDay(dobDayformat);
                    setLocalAddressLine2(response.data.response.txtAddressline2);
                    setLocalTown(response.data.response.town);
                    setLocalCountry(response.data.response.country);
                    setLocalTitle(response.data.response.lstSalutation);
                    setLocalAddressLine3(response.data.response.txtAddressline3);
                    setLocalCounty(response.data.response.county);
                    setLocalDistrict(response.data.response.district);

                    let Uc_fname = response.data.response.txtFName.charAt(0).toUpperCase() + response.data.response.txtFName.slice(1);
                    setUcFname(Uc_fname);
                    localStorage.setItem("UcFname", Uc_fname);
                }
            } else {
                // history.push("/thankyou");
                console.log("api call failed");
            }
        })();
    }, []);

    // let local_fName = localStorage.getItem("fName");
    // let local_lName = localStorage.getItem("lName");
    // let local_pCode = localStorage.getItem("pCode");
    // let local_address_line1 = localStorage.getItem("addressLine1");
    // let local_dob_yr = localStorage.getItem("dobYr");
    // let local_dob_month = localStorage.getItem("dobMon");
    // let local_dob_day = localStorage.getItem("dobDy");
    // let local_address_line2 = localStorage.getItem("addressLine2");
    // let local_town = localStorage.getItem("postTown");
    // let local_country = localStorage.getItem("country");
    // let local_title = localStorage.getItem("title");
    // let local_addressLine3 = localStorage.getItem("addressLine3");
    // let local_addressLine4 = localStorage.getItem("addressLine4");
    // let local_county = localStorage.getItem("county");
    // let UcFname = localStorage.getItem("UcFname");
    // let local_district = localStorage.getItem("district");


    // const [t2aApiReqData, setT2aApiReqData] = useState({});

    //   const { getUserVerfyValidation, saveFollowupUserVerify } = useUserVerify();
    const { getUserVerfyValidation, saveUserVerify } = useUserVerify();
    const [showloader, setShowloader] = useState("hide");
    const history = useHistory();
    const allFollowupData = localStorage.getItem("followupData");
    const [confirmClicked, setConfirmClicked] = useState(false);
    const { updateLogRecord } = useLogRecord();
    const [displayPostcode, setDisplayPostcode] = useState("hide");
    console.log("state", displayPostcode);
    const commaSeparatedString = [
        local_address_line1,
        local_address_line2,
        local_addressLine3,
        // local_addressLine4,
        local_county,
        local_town,
        local_country,
    ]
        .filter(Boolean)
        .join(",");

    const [notDobValid, setNotDobValid] = useState(false);


    const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    };
        
    const isValidDayInMonth = (day, month, year) => {
    const daysInMonth = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        return day >= 1 && day <= daysInMonth[month - 1];
    };
        
    const validateDateOfBirth = (dobDay, dobMonth, dobYear) => {
        const monthNames = [
            "January", "February", "March", "April",
            "May", "June", "July", "August",
            "September", "October", "November", "December"
        ];
        
        const isValidMonth = monthNames.includes(dobMonth);
        const isValidYear = dobYear >= 1910;
        
        if (isValidMonth && isValidYear && isValidDayInMonth(dobDay, monthNames.indexOf(dobMonth) + 1, dobYear)) {
            return true; 
        } else {
            return false;
          }
    };

    const basicDetailsValidation = async (e) => {

        if (displayPostcode === "hide") {

            let errorFlag = 0;

            let salutation = await trigger("lstSalutation");
            let txtFNameResult;
            let txtLNameResult;
            let lstDobDayResult;
            let lstDobMonthResult;
            let lstDobYearResult;
            let PostCodeResult;
            let addressSelect;
            let Lookup_Address_Btn;
            var dobday = document.getElementById("DobDay").value;
            var dobmonth = document.getElementById("DobMonth").value;
            var dobyear = document.getElementById("DobYear").value;

            if (salutation) {
                txtFNameResult = await trigger("txtFName");
            } else {
                document.getElementById("lstSalutation").focus();
                return false;
            }
            if (txtFNameResult) {
                txtLNameResult = await trigger("txtLName");
            } else {
                document.getElementById("txtFName").focus();
                return false;
            }
            if (txtLNameResult) {
                lstDobDayResult = await trigger("DobDay");
            } else {
                document.getElementById("txtLName").focus();
                return false;
            }
            if (lstDobDayResult) {
                lstDobMonthResult = await trigger("DobMonth");
            } else {
                document.getElementById("DobDay").focus();
                return false;
            }
            if (lstDobMonthResult) {
                lstDobYearResult = await trigger("DobYear");
            } else {
                document.getElementById("DobMonth").focus();
                return false;
            }
            if (lstDobYearResult) {
                const dobIsValid = validateDateOfBirth(dobday, dobmonth, dobyear);
                if (!dobIsValid) {
            
                  setNotDobValid(true);
                  document.getElementById("DobDay").focus();
                  return false;
                }else{
                  setNotDobValid(false);
                }
            } else {
                document.getElementById("DobYear").focus();
                return false;
            }


            if (
                !salutation ||
                !txtFNameResult ||
                !txtLNameResult ||
                !lstDobDayResult ||
                !lstDobMonthResult ||
                !lstDobYearResult 
                // !PostCodeResult ||
                // !addressSelect
            ) {
                errorFlag = 1;
            }
            if (errorFlag === 0) {

                console.log("Post code validation triggered!(without Address edit)");
                // const form = splitForm.current;
                const values = getValues();
                const formdata = values;
                let t2aApiReqData = {};

                // let dobMonth = formdata.DobMonth ? MonthNumberArray[formdata.DobMonth] : '';

                let fName = formdata.txtFName;
                let lName = formdata.txtLName;
                let pCode = local_pCode;
                let addressLine1 = local_address_line1;
                let dobYr = formdata.DobYear;
                // let dobMon = dobMonth;
                let dobMon = formdata.DobMonth;
                let dobDy = formdata.DobDay;
                let addressLine2 = local_address_line2;
                let postTown = local_town;
                let country = local_country;
                let title = formdata.lstSalutation ? formdata.lstSalutation : "";
                let addressLine3 = local_addressLine3 ? local_addressLine3 : "";
                let addressLine4 = local_addressLine4 ? local_addressLine4 : "";
                let county = local_county;
                let District = local_district ? local_district : "";



                t2aApiReqData = {
                    uuid: uuid,
                    fName: fName,
                    lName: lName,
                    pCode: pCode,
                    addressLine1: addressLine1,
                    dob_y: dobYr,
                    dob_m: dobMon,
                    dob_d: dobDy,
                    addressLine2: addressLine2,
                    postTown: postTown,
                    country: country,
                    title: title,
                    addressLine3: addressLine3,
                    addressLine4: addressLine4 ? addressLine4 : "",
                    county: county,
                    district: District ? District : "",
                };

                if (
                    uuid &&
                    fName &&
                    lName &&
                    pCode &&
                    addressLine1 &&
                    dobYr &&
                    dobMon &&
                    dobDy
                ) {
                    showLoaderdiv();
                    const startTime = performance.now();
                    let startDateTime = getCurrentDateTime();
                    (async () => {
                        const response = await getUserVerfyValidation(
                            uuid,
                            fName,
                            lName,
                            pCode,
                            addressLine1,
                            dobYr,
                            dobMon,
                            dobDy,
                            addressLine2,
                            postTown,
                            country,
                            title,
                            addressLine3,
                            addressLine4,
                            county
                        );
                        let endDateTime = getCurrentDateTime();

                        const endTime = performance.now();

                        const Timems = endTime - startTime;

                        const timeTaken = (Timems / 1000).toFixed(2);

                        if (response.data.status == "success") {
                            updateMilestone(visitorParameters.visitor_parameters.uuid,"kyc_check","live",1);
                            localStorage.setItem("Kyc", 1);
                            history.push("/CheckListGN_4?uuid=" + uuid);
                        } else {
                            updateMilestone(visitorParameters.visitor_parameters.uuid,"kyc_check","live",2);
                            reEditKyc();
                        }
                        storeT2aResponse(response.data);
                        const LogRecord = await updateLogRecord(
                            uuid,
                            "Person_Search_KYC",
                            timeTaken,
                            startDateTime,
                            endDateTime
                        );
                    })();
                }

                const storeT2aResponse = async (t2a_data) => {
                    //   const response = await saveFollowupUserVerify(
                    //     JSON.parse(allFollowupData),
                    //     t2a_data,
                    //     "person_verify_store",
                    //     query_string,
                    //     t2aApiReqData
                    //   );
                    const response = await saveUserVerify(
                        visitorParameters.visitor_parameters,
                        t2a_data,
                        "person_verify_store_mb",
                        visitorParameters.data,
                        query_string,
                        t2aApiReqData
                    );
                };

            } else {
                return false;
            }
            return;
        }

        let errorFlag = 0;

        let salutation = await trigger("lstSalutation");
        let txtFNameResult ;
        let txtLNameResult;
        let lstDobDayResult;
        let lstDobMonthResult;
        let lstDobYearResult;
        let PostCodeResult;
        let addressSelect;
        let Lookup_Address_Btn;
        var dobday = document.getElementById("DobDay").value;
        var dobmonth = document.getElementById("DobMonth").value;
        var dobyear = document.getElementById("DobYear").value;

        if (salutation) {
            txtFNameResult = await trigger("txtFName");
        } else {
            document.getElementById("lstSalutation").focus();
            return false;
        }
        if (txtFNameResult) {
            txtLNameResult = await trigger("txtLName");
        } else {
            document.getElementById("txtFName").focus();
            return false;
        }
        if (txtLNameResult) {
            lstDobDayResult = await trigger("DobDay");
        } else {
            document.getElementById("txtLName").focus();
            return false;
        }
        if (lstDobDayResult) {
            lstDobMonthResult = await trigger("DobMonth");
        } else {
            document.getElementById("DobDay").focus();
            return false;
        }
        if (lstDobMonthResult) {
            lstDobYearResult = await trigger("DobYear");
        } else {
            document.getElementById("DobMonth").focus();
            return false;
        }
        if (lstDobYearResult) {
            PostCodeResult = await trigger("txtPostCode");
        } else {
            document.getElementById("DobYear").focus();
            return false;
        }
        if (PostCodeResult) {
            // Lookup_Address_Btn=await trigger("Lookup_Address_Btn");
            addressSelect = await trigger("address1");
            // if (!addressSelect) {
            //   setConfirmClicked(e);
            // }
        } else {
            document.getElementById("txtPostCode").focus();
            return false;
        }
        if (addressSelect) {
            const dobIsValid = validateDateOfBirth(dobday, dobmonth, dobyear);

            if (!dobIsValid) {
              document.getElementById("DobDay").focus();
              setNotDobValid(true);
              return false;
            } else {
              setNotDobValid(false);
            }
        } else {
            document.getElementById("address1").focus();
            return false;
        }

        if (
            !salutation ||
            !txtFNameResult ||
            !txtLNameResult ||
            !lstDobDayResult ||
            !lstDobMonthResult ||
            !lstDobYearResult ||
            !PostCodeResult ||
            !addressSelect
        ) {
            errorFlag = 1;
        }
        if (errorFlag === 0) {
            // if(salutation == "Mrs" || salutation == "Ms" || salutation == "Miss" || salutation == "Dame" || salutation == "Lady" ){
            //   setshowPrevModal(true);
            // }else{
            //   slideChange(e);
            // }
            //    slideChange(e);
            validatePostCode();
        } else {
            return false;
        }
    };

    const validatePostCode = async () => {
        // setShowloader("show");
        console.log("Post code validation triggered!");
        const form = splitForm.current;
        const values = getValues();
        const formdata = values;
        let t2aApiReqData = {};

        // let dobMonth = formdata.DobMonth ? MonthNumberArray[formdata.DobMonth] : '';

        let fName = formdata.txtFName;
        let lName = formdata.txtLName;
        let pCode = formdata.txtPostCode;
        let addressLine1 = formdata.txtHouseNumber;
        let dobYr = formdata.DobYear;
        // let dobMon = dobMonth;
        let dobMon = formdata.DobMonth;
        let dobDy = formdata.DobDay;
        let addressLine2 = formdata.txtAddress2;
        let postTown = formdata.txtTown;
        let country = formdata.txtCountry;
        let title = formdata.lstSalutation ? formdata.lstSalutation : "";
        let addressLine3 = formdata.txtAddress3 ? formdata.txtAddress3 : "";
        let addressLine4 = formdata.txtAddress4 ? formdata.txtAddress4 : "";
        let county = formdata.txtCounty;
        let District = form["txtDistrict"].value;
        // let District=formdata.txtDistrict;

        t2aApiReqData = {
            uuid: uuid,
            fName: fName,
            lName: lName,
            pCode: pCode,
            addressLine1: addressLine1,
            dob_y: dobYr,
            dob_m: dobMon,
            dob_d: dobDy,
            addressLine2: addressLine2,
            postTown: postTown,
            country: country,
            title: title,
            addressLine3: addressLine3,
            addressLine4: addressLine4 ? formdata.txtAddress4 : "",
            county: county,
            district: District ? District : "",
        };

        if (
            uuid &&
            fName &&
            lName &&
            pCode &&
            addressLine1 &&
            dobYr &&
            dobMon &&
            dobDy
        ) {
            showLoaderdiv();
            (async () => {
                const response = await getUserVerfyValidation(
                    uuid,
                    fName,
                    lName,
                    pCode,
                    addressLine1,
                    dobYr,
                    dobMon,
                    dobDy,
                    addressLine2,
                    postTown,
                    country,
                    title,
                    addressLine3,
                    addressLine4,
                    county
                );

                if (response.data.status == "success") {
                    updateMilestone(visitorParameters.visitor_parameters.uuid,"kyc_check","live",1);
                    localStorage.setItem("Kyc", 1);
                    history.push("/CheckListGN_4?uuid=" + uuid);
                } else {
                    updateMilestone(visitorParameters.visitor_parameters.uuid,"kyc_check","live",2);
                    reEditKyc();
                }
                storeT2aResponse(response.data);
            })();
        }

        const storeT2aResponse = async (t2a_data) => {
            //   const response = await saveFollowupUserVerify(
            //     JSON.parse(allFollowupData),
            //     t2a_data,
            //     "person_verify_store",
            //     query_string,
            //     t2aApiReqData
            //   );

            const response = await saveUserVerify(
                visitorParameters.visitor_parameters,
                t2a_data,
                "person_verify_store_mb",
                visitorParameters.data,
                query_string,
                t2aApiReqData
            );
        };
    };

    const namevalidate = async (e) => {
        if (e.target.value.trim() == "" || e.target.value.trim().length < 3) {
            var name = e.target.name == "txtFName" ? "First Name" : "Last Name";
            setError(e.target.name, {
                type: "manual",
                message: "Your " + name + " is Invalid. Please Recheck",
            });
            if (e.target.value.trim().length == 0) {
                e.target.value = e.target.value.trim();
            }
        }
        return false;
    };


    const reEditKyc =()=>{
        setOptionDiv("show");
        setShowfirstloader("hide");
        setEditKycDiv("hide");
    };

    const showLoaderdiv =()=>{
        setOptionDiv("hide");
        setShowfirstloader("show");
        setEditKycDiv("hide");
    };
    return (
        <>


            {/* /// new code */}
            <form
                ref={splitForm}
            >
                <div className={`tab-pane fade active ${className}`} id="editdet">
                    <h3 className="title text-start mt-4 mb-3" >
                        Edit Your Personal Details
                    </h3>
                    <div className="bg-white p-lg-4 p-md-4 p-sm-4 p-2 rounded-4 border border-dark-subtle">
                          <div className="mb-3">
                        {local_title ?(
                              <SelectBox
                                className="form-control"
                                OptionValue={Salutation}
                                defaultValue={local_title}
                                name="lstSalutation"
                                id="lstSalutation"
                                onChange={() => clearErrors("lstSalutation")}
                                clearErrors={clearErrors}
                                myRef={register({
                                  required: "Please Select Title",
                                })}
                              ></SelectBox>
                              ) : null}
                            {errors.lstSalutation && (
                                <span className="error_msg " id="email_err">
                                    {errors.lstSalutation.message}
                                </span>
                            )}
                        </div>
                        <div className="mb-3">

                            <TextField2
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                                value={local_fName}
                                name="txtFName"
                                id="txtFName"
                                onBlur={namevalidate}
                                validation={register({
                                    required: "Please Enter First Name",
                                    minLength: {
                                        value: 3,
                                        message: "Please Enter Valid First Name",
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z\s]+$/,
                                        message:
                                            "Your Name is Invalid. Please Recheck",
                                    },
                                })}
                            ></TextField2>
                            {errors.txtFName && (
                                <span className="error_msg " id="email_err">
                                    {errors.txtFName.message}
                                </span>
                            )}
                        </div>
                        <div className="mb-3">
                            <TextField2
                                type="text"
                                className="form-control "
                                placeholder="Last Name"
                                value={local_lName}
                                name="txtLName"
                                id="txtLName"
                                onBlur={namevalidate}
                                validation={register({
                                    required: "Please Enter Last Name",
                                    minLength: {
                                        value: 3,
                                        message: "Please Enter Valid Last Name",
                                    },
                                    pattern: {
                                        value: /^[a-zA-Z\s]+$/,
                                        message:
                                            "Your Name is Invalid. Please Recheck",
                                    },
                                })}
                            ></TextField2>
                            {errors.txtLName && (
                                <span className="error_msg " id="email_err">
                                    {errors.txtLName.message}
                                </span>
                            )}

                        </div>
                        <div className="mb-3">
                            <fieldset className="p-3 pb-4 px-lg-4 px-md-4 px-sm-4 px-2">
                                <legend>Date Of Birth</legend>
                                <div className="row">
                                    <div className="col-4">
                                        {local_dob_day ? (
                                            <SelectBox
                                                className="form-select form-control"
                                                OptionValue={DobDay}
                                                defaultValue={local_dob_day}
                                                name="DobDay"
                                                id="DobDay"
                                                myRef={register({
                                                    required: "Please Select Date"
                                                })}
                                            />
                                        ) : null}
                                        <span className="error_msg">{errors.DobDay &&
                                            errors.DobDay.message}</span>
                                    </div>
                                    <div className="col-4">
                                        {local_dob_month ? (
                                            <SelectBox
                                                className="form-select form-control"
                                                OptionValue={DobMonth}
                                                defaultValue={local_dob_month}
                                                name="DobMonth"
                                                id="DobMonth"
                                                myRef={register({
                                                    required: "Please Select Date"
                                                })}

                                            />
                                        ) : null}
                                        <span className="error_msg">{errors.DobMonth &&
                                            errors.DobMonth.message}</span>
                                    </div>
                                    <div className="col-4">
                                        {local_dob_yr ? (
                                            <SelectBox
                                                className="form-select form-control"
                                                OptionValue={DobYear}
                                                defaultValue={local_dob_yr}
                                                name="DobYear"
                                                id="DobYear"
                                                myRef={register({
                                                    required: "Please Select Date"
                                                })}
                                            />
                                        ) : null}
                                        <span className="error_msg">{errors.DobYear &&
                                            errors.DobYear.message}</span>

                                    </div>
                                    {notDobValid && (
                                        <span id="dob_final_err" className="error_msg">
                                         Invalid date of birth provided.
                                        </span>
                                    )}
                                </div>
                            </fieldset>

                        </div>

                        <div className={`scheduler-border text-start lookupadd w-100 ${displayPostcode === "new-lookupadd"
                            ? "hide"
                            : ""
                            }`}>

                            <div>
                                <AddressTextField
                                    type="text"
                                    value={commaSeparatedString}
                                    name="existing_address"
                                    id="existing_address"
                                    className="form-control frm_name1"
                                    dataId="txtPostCode"
                                    readonly={true}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="mb-4">
                                <InputButton
                                    // style={{ float: "none" }}
                                    style={{ background: '#43c116', fontWeight: 600, fontSize: '21px', color: '#fff' }}
                                    name="Address_edit_Btn"
                                    // validation={validation()}
                                    className="form-control mt-2"
                                    value="Edit"
                                    btnType="button"
                                    onClick={() =>
                                        setDisplayPostcode("new-lookupadd")
                                    }
                                />
                            </div>
                        </div>
                        <PostCode2
                            validation={register}
                            validationMsg={errors}
                            trigger={trigger}
                            clearErrors={clearErrors}
                            setError={setError}
                            className={displayPostcode}
                            getValues={getValues}
                            pCode={local_pCode}
                            address_line1={local_address_line1}
                            address_line2={local_address_line2}
                            town={local_town}
                            country={local_country}
                            addressLine3={local_addressLine3}
                            addressLine4={local_addressLine4}
                            county={local_county}
                            confirmClicked={confirmClicked}
                            splitForm={splitForm}
                        />


                        <div className="mt-4 mb-3">
                            {/* <a href="" className="btn-comm2 btn-confrm text-center  w-100" onClick={basicDetailsValidation}> */}
                            <InputButton
                                name="confirm"
                                className="btn-comm2 btn-confrm text-center  w-100"
                                value="Confirm"
                                btnType="button"
                                onClick={basicDetailsValidation}
                            />

                            {/* <span> Confirm</span> */}
                            {/* </a> */}
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default EditKycDetailsMB_UFGN_4;