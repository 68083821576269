import React, { useState, useEffect, useRef, useReducer } from "react";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { useFollowupUserQuestions } from "../../../../Hooks/useFollowupUserQuestions";
import { questinnare1, questinnare2Very,questinnare2VeryV2,questinnaire76MB,wereUalreadyPaying } from "../../../../Constants/Questions";
import QuestionnaireFlpQ5 from "../Followup/QuestionnaireFlpQ5";
import QuestionnaireFlpFANUpload from "../Followup/QuestionnaireFlpFANUpload";
import QuestionnaireFlpAgreementNo from "./QuestionnaireFlpAgreementNo";
import QuestionnaireEmail from "../Followup/QuestionnaireEmail";
import QuestionnaireSupportDoc from "./QuestionnaireSupportDoc";
import "../../../../assets/css/QuestionnaireV12.scss";
import { useUserVerify } from "../../../../Hooks/useUserVerify";
import { userMilestoneUpdate } from "../../../../Hooks/userMilestoneUpdate";
import { queryString } from "../../../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useSkippedLeads } from '../../../../Hooks/useSkippedLeads';
import QuestionnaireFlpAgreementNoModalV2 from "../FollowupS1/QuestionnaireFlpAgreementNoModalV2";
import HighestBal from "./NewDay/HighestBal";
import ClosedYear from "./NewDay/ClosedYear";
import LimitIncrease from "./NewDay/LimitIncrease";
import CreditLimit from "./NewDay/CreditLimit";
import MinPayment from "./NewDay/MinPayment";
import Amounts from "./NewDay/Amounts";
import EmailQuestion from "./NewDay/EmailQuestion";
import QuestionnaireFooter from "./QuestionnaireFooter";
import AmountModal from "./NewDay/AmountModal";
import QuestionnaireFlpQ76 from "../Followup/QuestionnaireFlpQ76";
import AnalyzeModal from "./AnalayzeModal";
import IntialYear from "./NewDay/IntialYear";
import Struggle from "./NewDay/Struggle";
import Filled from "./NewDay/Filled";
import Bankruptcy from "./NewDay/Bankrupty";
import StillActive from "./NewDay/IsStillActive";
import EmployementStatus from "./NewDay/EmploymentStatus";
import DependentStatus from "./NewDay/Dependents";
import AgreeModal from "./NewDay/AgreeModal";
// import IncomeConfirmation from "./NewDay/IncomeConfirmation";
import AmountNDY_UB_4 from "./NewDay/AmountNDY_UB_4";
// import NDY_UB_2 from "../../../Pages/Splits/NDY_UB_2";
import DidReceiveIncome from "./NewDay/DidReceiveIncome";
import CardMonthlyIncome from "./NewDay/CardMonthlyIncome";
import DependentStatusNew from "./NewDay/DependentsNDY";
import WereAlreadyPaying from "./NewDay/Werealreadypaying";
import CardOverSixYears from "./NewDay/CardOverSixYears";
import LatePaymentLetters from "./NewDay/LatePaymentLetters";
import Difficulties from "./NewDay/Difficulties";
import FinanceProblem from "./NewDay/FinanceProblem";
import Aware from "./NewDay/Aware";
import BankAccNo from "./NewDay/BankAccNo";
import SortCode from "./NewDay/SortCode";
import BankName from "./NewDay/BankName";
import { useUpdateMilestone } from "../../../../Hooks/useUpdateMilestone";
const initialState = {
  intialyear:"hide",
  struggle:"hide",
  claimfilled:"hide",
  bankrupty:"hide",
  stillactive:"hide",
  showSlide1: "hide",
  showSlide2: "hide",
  employment_status:"hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide",
  showSlide6: "hide",
  showSlide7: "hide",
  showSlide7a: "hide",
  did_receive_income:"hide",
  card_monthly_income:"hide",
  dependent_status: "hide",
  were_already_paying:"hide",
  showSlide8: "hide",
  showSlide9: "hide",
  showSlide10a: "hide",
  showSlide10: "hide",
  showSlide11: "hide",
  showSlide12: "hide",
  cardOverSixYears:"hide",
  latePaymentLetters:"hide",
  difficulties:"hide",
  financeProblem:"hide",
  aware:"hide",
  accno:"hide",
  sortCode:"hide",
  bankName:"hide",
};
const QuestionnaireReducer = (state, action) => {
  switch (action.type) {
    case "showSlideChange": {
      if (action.payload == "intialyear") {
        return {
          ...state,
          intialyear:"show",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
  sortCode:"hide",
  bankName:"hide",
          percentage: 10,
        };
      }else if (action.payload == "struggle") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"show",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
  sortCode:"hide",
  bankName:"hide",
          percentage: 10,
        };
      } else if (action.payload == "claimfilled") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          bankrupty:"hide",
          claimfilled:"show",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",  
          did_receive_income:"hide",
          card_monthly_income:"hide",   
          were_already_paying:"hide",     
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
  sortCode:"hide",
  bankName:"hide",
          percentage: 10,
        };
      } else if (action.payload == "bankrupty") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          bankrupty:"show",
          claimfilled:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
  sortCode:"hide",
  bankName:"hide",
          percentage: 10,
        };
      }else if (action.payload == "stillactive") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          bankrupty:"hide",
          claimfilled:"hide",
          stillactive:"show",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",  
          were_already_paying:"hide",        
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
  sortCode:"hide",
  bankName:"hide",
          percentage: 10,
        };
      } else if (action.payload == "slide1") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "show",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 10,
        };
      } else if (action.payload == "slide2") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "show",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 20,
        };
      } else if (action.payload == "employment_status") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          bankrupty:"hide",
          claimfilled:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"show",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 30,
        };
      }else if (action.payload == "slide3") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          bankrupty:"hide",
          claimfilled:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "show",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 30,
        };
      } else if (action.payload == "slide4") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          bankrupty:"hide",
          claimfilled:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "show",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 40,
        };
      } else if (action.payload == "slide5") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "show",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 50,
        };
      } else if (action.payload == "slide6") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          claimfilled:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "show",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 60,
        };
      }else if (action.payload == "slide7" && action.splitName=="NDY_UB_4") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          bankrupty:"hide",
          claimfilled:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          were_already_paying:"hide",
          showSlide7: "hide",
          showSlide7a: "show",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 70,
        };
      } else if (action.payload == "slide7" && action.splitName=="NDY_UB_2") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          bankrupty:"hide",
          claimfilled:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7a: "hide",
          showSlide7: "show",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 70,
        };
      } 
      else if (action.payload == "did_receive_income") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"show",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 80,
        };
      }
      else if (action.payload == "card_monthly_income") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"show",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 80,
        };
      }
      else if (action.payload == "dependent_status") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          bankrupty:"hide",
          claimfilled:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "show",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 80,
        };
      }
      else if (action.payload == "were_already_paying") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"show",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 80,
        };
      } 
      else if (action.payload == "slide8") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "show",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 80,
        };
      } else if (action.payload == "slide9") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "show",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 85,
        };
      } else if (action.payload == "slide10a") {
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "show",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 88,
        };
      } else if (action.payload == "slide10") {
        window.scrollTo(0,0);
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "show",
          showSlide11: "hide",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 90,
        };
      } else if (action.payload == "slide11") {
        window.scrollTo(0,0);
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "show",
          showSlide12: "hide",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 90,
        };
      } else if (action.payload == "slide12") {
        window.scrollTo(0,0);
        return {
          ...state,
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          dependent_status: "hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "show",
           latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 90,
        };
      }else if (action.payload == "cardOverSixYears") {
          return {
            intialyear:"hide",
            struggle:"hide",
            claimfilled:"hide",
            bankrupty:"hide",
            stillactive:"hide",
            showSlide1: "hide",
            showSlide2: "hide",
            employment_status:"hide",
            showSlide3: "hide",
            showSlide4: "hide",
            showSlide5: "hide",
            showSlide6: "hide",
            showSlide7: "hide",
            showSlide7a: "hide",
            did_receive_income:"hide",
            card_monthly_income:"hide",
            dependent_status: "hide",
            were_already_paying:"hide",
            showSlide8: "hide",
            showSlide9: "hide",
            showSlide10a: "hide",
            showSlide10: "hide",
            showSlide11: "hide",
            showSlide12: "hide",
            latePaymentLetters:"hide",
            cardOverSixYears:"show",
            difficulties:"hide",
            financeProblem:"hide",
            aware:"hide",
            accno:"hide",
            sortCode:"hide",
            bankName:"hide",
            percentage: 90,
          };
      }else if (action.payload == "aware") {
        return {
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
          latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"show",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 90,
        };
      }else if (action.payload == "latePaymentLetters") {
        return {
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
          latePaymentLetters:"show",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 90,
        };
      }else if (action.payload == "difficulties") {
        return {
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
          latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"show",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 90,
        };
      }else if (action.payload == "financeProblem") {
        return {
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
          latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"show",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"hide",
          percentage: 90,
        };
      }else if (action.payload == "bankName") {
        return {
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
          latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"hide",
          bankName:"show",
          percentage: 90,
        };
      }else if (action.payload == "accountNo") {
        return {
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
          latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide", 
          accno:"show",
          sortCode:"hide",
          bankName:"hide",
          percentage: 90,
        };
      }else if (action.payload == "sortcode") {
        return {
          intialyear:"hide",
          struggle:"hide",
          claimfilled:"hide",
          bankrupty:"hide",
          stillactive:"hide",
          showSlide1: "hide",
          showSlide2: "hide",
          employment_status:"hide",
          showSlide3: "hide",
          showSlide4: "hide",
          showSlide5: "hide",
          showSlide6: "hide",
          showSlide7: "hide",
          showSlide7a: "hide",
          did_receive_income:"hide",
          card_monthly_income:"hide",
          dependent_status: "hide",
          were_already_paying:"hide",
          showSlide8: "hide",
          showSlide9: "hide",
          showSlide10a: "hide",
          showSlide10: "hide",
          showSlide11: "hide",
          showSlide12: "hide",
          latePaymentLetters:"hide",
          cardOverSixYears:"hide",
          difficulties:"hide",
          financeProblem:"hide",
          aware:"hide",
          accno:"hide",
          sortCode:"show",
          bankName:"hide",
          percentage: 90,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    default:return {...state};
  }
};
const NewDayV2QuestionForm = ({
  questionsPop,
  allFollowupData,
  pendingQuestionnaire,
  documentType,
  docType,
  sett2aValidation,
  t2aApiReqData,
  t2aStatus,
  currentUuid,
  setDivShow,
  splitName,
  newDayData,
  setChecklistOptions,
  checklistOptions
}) => {
  const flp_source = allFollowupData.atp_sub6;
  const history = useHistory();
  const [state, dispatch] = useReducer(QuestionnaireReducer, initialState);
  const [show, setShow] = useState(false);
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { saveFollowupUserQuestions, isLoading } = useFollowupUserQuestions();
  const splitForm = useRef(null);
  const modalValue = useRef(null);
  const [optionSelected] = useState([]);
  const [optionSelected76] = useState([]);
  const [agNoShow, setAgNoShow] = useState("hide");
  const { skippedLeads, skippedLeadsFLP } = useSkippedLeads();
  const { getUserVerfyValidation,saveFollowupUserVerify } = useUserVerify();
  const { updateMilestone } = userMilestoneUpdate();
  const [clickedModel, setClickedModel] = useState([]);
  const [monthlyExpShow, setMonthlyExpShow] = useState("hide");
  const [otherExpShow, setOtherExpShow] = useState("hide");
  const [t2aModal,SetT2aModal]=useState(false);
  const [intialLoanYear,SetIntialLoanYear]=useState("");
  const [yearUpdated,setYearUpdated]=useState("");
  const [agreeModalShow, setAgreeModalShow] = useState("hide");
  const [inputListValues, setInputListValues] = useState([]);

  const [accountNo, setAccountNo] = useState('');
  const [sortCode, setSortCode] = useState('');
  const [selectBank, setSelectBank] = useState([]);
  const { updateMilestone: updateMilestoneNew } = useUpdateMilestone();

  const { register, errors, trigger, setError, clearErrors, getValues,setValue } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  useEffect(() => {
    console.log('slideChange',pendingQuestionnaire);
    slideChange(pendingQuestionnaire);
  }, [pendingQuestionnaire]);

  useEffect(() => {
    setYearUpdated(1);   
  }, [pendingQuestionnaire]);

  const sendT2aApiReqDataAPI = async () => {
    SetT2aModal(prev=> !prev);
    const useruuid = t2aApiReqData.uuid;
    const fName = t2aApiReqData.fName;
    const lName = t2aApiReqData.lName;
    const pCode = t2aApiReqData.pCode;
    const addressLine1 = t2aApiReqData.addressLine1;
    const dobDay = t2aApiReqData.day;
    const dobMonth = t2aApiReqData.month;
    const dobYear = t2aApiReqData.year;
    const addressLine2 = t2aApiReqData.addressLine2;
    const postTown = t2aApiReqData.postTown;
    const country = t2aApiReqData.country;
    const title = t2aApiReqData.title;
    const addressLine3 = t2aApiReqData.addressLine3;
    const addressLine4 = t2aApiReqData.addressLine4;
    const county = t2aApiReqData.county;

    if (
      useruuid &&
      fName &&
      lName &&
      pCode &&
      addressLine1 &&
      t2aStatus == null
    ) {
        const response = await getUserVerfyValidation(
          useruuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYear,
          dobMonth,
          dobDay,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
          addressLine4,
          county,
        );
        storeT2aResponse(response.data);

        if (response.data.status == "success") {
          updateMilestoneNew(currentUuid,"kyc_check",flp_source,1);
          SetT2aModal(false);
          localStorage.setItem("t2a_result", 1);
          localStorage.setItem("split_name", splitName);
          // history.push("/view_docs_flp?uuid=" + currentUuid);
        } else {
          updateMilestoneNew(currentUuid,"kyc_check",flp_source,2);
          SetT2aModal(false);
          localStorage.setItem("t2a_result", 0);
          // setDivShow("idUpload");
        }
      return response;
    } else {
      SetT2aModal(false);
      localStorage.setItem("t2a_result", t2aStatus);
      let email_skip = localStorage.getItem("email_doc");
      if (useruuid && email_skip != 1) {
        (async () => {
          const response = await updateMilestone(
            useruuid,
            t2aStatus,
            allFollowupData
          );
        })();
      }

      if (t2aStatus == 1) {
        updateMilestoneNew(currentUuid,"questions",flp_source);
        history.push("/view_docs_flp?uuid=" + currentUuid);
      } else {
        updateMilestoneNew(currentUuid,"questions",flp_source);
        setDivShow("idUpload");
      }
    }
  };
  const slideChange = (pendingArr) => {
    let pendingCount = pendingArr.length;
    if (pendingCount == 0) {
      updateMilestoneNew(currentUuid,"questions",flp_source);
      setShow(false);
      handleT2AResult();
    }

    pendingArr.every((element) => {
      console.log("showSlideChange",element);
      dispatch({ type: "showSlideChange", payload: element,splitName:splitName });
    });
  };
  const storeFollowupQuestionnire = async (question_data) => {
    const response = await saveFollowupUserQuestions(
      question_data,
      allFollowupData,
      "followup_question_store"
    );
  };

  const storeT2aResponse = async (t2a_data) => {
    const response = await saveFollowupUserVerify(
      allFollowupData,
      t2a_data,
      "person_verify_store_flp",
      queryString,
      t2aApiReqData
    );
  };

  const pushPageChange = () => {
    removeFromPending("slide12");
  };


  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    const valuesreg = getValues();
    let employment = valuesreg["employment"];

    let flage = false;
    let question_data = {};
    if (e.target.name == "question_54") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 54,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("slide4");
    }
    if (e.target.name == "question_55") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 55,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("slide5");
    }
    if (e.target.name == "question_56") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 56,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("slide6");
        // if(splitName==="NDY_UB_4"){
        // }
        // else if (splitName==="NDY_UB_2"){
        // removeFromPending("slide6");
        // removeFromPending("incomeConfirmation");
        // }
    }
    if (e.target.name == "question_61") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 61,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      if (selectedAns == 162) {
        removeFromPending("slide8");
        removeFromPending("slide9");
      } else {
        removeFromPending("slide8");
      }

    }
     if (e.target.name == "btn_qest_42") {

      if(splitName == "NDY_UB_4"){
        const response = await sendT2aApiReqDataAPI();
      }
      
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
        if (selectedAns == '124') {
          setAgNoShow('show');
          // removeFromPending("slide12");
        }
        
        if (selectedAns == '123') {
          // removeFromPending("slide11");
          removeFromPending("slide12");
          // pushPageChange();

        }

        removeFromPending("slide11");
        
      }
    }
    
    if (e.target.name == "btn_qest_76") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 76,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected76,
        };
      }
      removeFromPending("slide10a");
    }
    if (e.target.name == "btn_qest_36") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      removeFromPending("slide10");
      if(splitName != "NDY_UB_4"){
        sendT2aApiReqDataAPI();
        }
    }     
    if (e.target.name == "agreeYear") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 49,
          option_id:  e.target.value,
          answer_text: anslabel,
          input_answer:null,
        };
      }
      removeFromPending("intialyear");
    }

    if (e.target.name == "question_29") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 29,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: null,
        };
      }
      removeFromPending("struggle");
    }
    if (e.target.name == "question_30") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 30,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: null,
        };
      }
      removeFromPending("claimfilled");
    }
    if (e.target.name == "question_9") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 9,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: null,
        };
      }
      removeFromPending("bankrupty");
    }

    if (e.target.name == "question_26") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      if (selectedAns == 96) {
        removeFromPending("stillactive");
      } else if (selectedAns == 95) {
        removeFromPending("stillactive");
        removeFromPending("slide2");
      }
    }

    if (e.target.name == "btn_qest_2" && employment) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: employment,
        answer_text: anslabel,
        input_answer: "",
      };
      removeFromPending("employment_status");
    }
    if (e.target.name == "question_3") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      removeFromPending("dependent_status");
    }else if (e.target.name == "question_77") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
      if (selectedAns == 226) {
        removeFromPending("did_receive_income");
      } else if (selectedAns == 227) {
        removeFromPending("did_receive_income");
        removeFromPending("card_monthly_income");
      }
    }else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }
    if (e.target.name == "cardOverSixYears") {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: anslabel,
        input_answer: "",
      };

      removeFromPending("cardOverSixYears");
      if(selectedAns===237){
        removeFromPending("aware");
        removeFromPending("latePaymentLetters");
        removeFromPending("difficulties");
        removeFromPending("financeProblem");
      }
    }
    if (e.target.name == "latePaymentLetters") {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: anslabel,
        input_answer: "",
      };


      removeFromPending("latePaymentLetters");
      
      if(selectedAns===239){
        removeFromPending("difficulties");
        removeFromPending("financeProblem");
      }
    }
    if (e.target.name == "difficulties") {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: anslabel,
        input_answer: "",
      };
      removeFromPending("difficulties");
    }

    if (e.target.name == "aware") {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: anslabel,
        input_answer: "",
      };

      removeFromPending("aware");
    }


    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };
  const handleT2AResult = () => {
    const t2aResult = localStorage.getItem("t2a_result");

    if (t2aResult) {

      if (t2aResult == 1) {
        history.push("/view_docs_flp?uuid=" + currentUuid);
      } else if (t2aResult == 0) {
        setDivShow("idUpload");
      }else{
        setDivShow("idUpload");
      }
    
    } else {
       if (t2aStatus == 0) {
        setDivShow("idUpload");
       } else {
        history.push("/view_docs_flp?uuid=" + currentUuid); 
       }        
    }
    
  }

  const saveIncomeExpenditure = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    const values = getValues();
    setCompletedQuestArr([...completedQuestArr, selectedQuest, 32, 33, 35]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    var answer35 = splitForm.current["question_35"].value;

    var slide_array = [
      ["31", answer31],
      ["32", answer32],
      ["33", answer33],
      ["35", answer35],
    ];

    var question_data = {
      slide_4: slide_array,
    };
    removeFromPending("slide2");
    storeFollowupQuestionnire(question_data);

    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  //
  const saveIncomeExpenditureNDY_UB_4 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");

    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    var answer31 = splitForm.current["question31"].value;
    var answer32 = splitForm.current["question32"].value;
    var answer33 = splitForm.current["question33"].value;
   
    var slide_array = [
      ["31", answer31],
      ["32", answer32],
      ["33", answer33],
    

    ];


      var question_data = {
        slide_4: slide_array,
      };
      removeFromPending("slide7");
      storeFollowupQuestionnire(question_data);

      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));
    // }
  };




  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    const valuesreg = getValues();
    let highestBalance = valuesreg["highest_balance"];
    let modalagreementNo = valuesreg["modal_question_45"];
    let agreementNo = valuesreg["question_45"];
    let email = valuesreg["question_48"];
    let closeYr = valuesreg["question_52"];
    let IntialYear = valuesreg["question_28"];
    let cardMonthlyIncomes = valuesreg["cardMonthlyIncome"];
    let dependents = valuesreg["dependents"];
    let awareReason = valuesreg["awareReason"];
    let financeProblem=valuesreg['financeProblem'];



    let flage = false;
    let question_data = {};

    if (selectedQuest == "45" && modalagreementNo) {
      removeFromPending("slide11");
      pushPageChange();
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: modalagreementNo,
      };
      setAgNoShow('hide');
      //removeFromPending("slide11");  
      //pushPageChange();  
    }
    if (selectedQuest == "45" && agreementNo) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: agreementNo,
      };
      removeFromPending("slide1");
    }
    if (selectedQuest == "52" && closeYr) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: closeYr,
      };
      removeFromPending("slide2");
    }
    if (selectedQuest == "53" && highestBalance) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: highestBalance,
      };
      removeFromPending("slide3");
    }
    if (selectedQuest == "48" && email) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: email,
      };
      removeFromPending("slide9");
    }

    if (selectedQuest == "28" && IntialYear) {
      if(IntialYear >= 2016){
      removeFromPending("intialyear");
      }
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: IntialYear,
      };
    }
    if (selectedQuest == "78" && cardMonthlyIncomes) {
      flage = true;
      question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: cardMonthlyIncomes,
      };
      removeFromPending("card_monthly_income");
      }
    if (selectedQuest == "3" && dependents) {
        flage = true;
        question_data = {
            question_id: selectedQuest,
            option_id: null,
            answer_text: "",
            input_answer: dependents,
        };
        removeFromPending("dependent_status");
    }

    if (selectedQuest == "85" && awareReason) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: 244,
        answer_text: "",
        input_answer: awareReason.trim(),
      };
      removeFromPending("aware");
    }


    if (selectedQuest == "84" && financeProblem) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: financeProblem.trim(),
      };
      removeFromPending("financeProblem");
    }

    if (selectedQuest == "87" && accountNo !=='') {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: accountNo,
      };
      removeFromPending("accountNo");
    }

    if (selectedQuest == "86" && sortCode !=='') {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: sortCode,
      };
      removeFromPending("sortcode");
    }
    if (selectedQuest == "64" && ("value" in selectBank|| selectBank?.value)) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: selectBank?.value,
      };
      removeFromPending("bankName");
    }

    if (flage) {
      storeFollowupQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const removeFromPending = (slidename) => {
    const index = pendingQuestionnaire.indexOf(slidename);
    if (index > -1) {
      pendingQuestionnaire.splice(index, 1);
    }
    console.log("removeFromPending",pendingQuestionnaire);
    slideChange(pendingQuestionnaire);
  };
  
  const SkipAgreement = async () => {
    const skippedLeadsResponse = await skippedLeads('SkipAgreementNoFlp', currentUuid, splitName);
    removeFromPending('slide1');
  };

  

  const saveSlide4 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");

    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    // var answer34 = splitForm.current["question_34"].value;
    //   var answer35 = splitForm.current["question_35"].value;

    let answer57 = splitForm.current["question_57"].value;
    let answer58 = splitForm.current["question_58"].value;
    let answer59 = splitForm.current["question_59"].value;
    let answer60 = splitForm.current["question_60"].value;

    var slide_array = [
      ["31", answer31],
      ["32", answer32],
      ["33", answer33],
      // ["34", answer34],
      // ["35", answer35],
      ["57", answer57],
      ["58", answer58]
    ];
    if (answer59 != '') {
      slide_array.push(["59", answer59]);
    }
    if (answer60 != '') {
      slide_array.push(["60", answer60]);
    }

    var question_data = {
      slide_4: slide_array,
    };
    removeFromPending("slide7");
    storeFollowupQuestionnire(question_data);

    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };


  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value =
        "£" + e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const totalDebitValidation = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();

    let question31Result = await trigger("question_59");
    if (!question31Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag == 0) {
      let question58Result = splitForm.current["question_58"].value;
      if (question58Result == '153') {
        setMonthlyExpShow('hide');
        setOtherExpShow('show');

      } else {
        setMonthlyExpShow('hide');
        saveSlide4(e);
      }
    }
  }

  const totalOtherDebitValidation = async (e) => {
    e.preventDefault();
    let errorFlag = 0;

    let question60Result = await trigger("question_60");
    if (!question60Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag == 0) {
      setOtherExpShow('hide');
      setMonthlyExpShow('hide');
      // setClickedModel({ model: "monthly", status: "hide" });
      // setClickedModel({ model: "other", status: "hide" });
      saveSlide4(e);
    }
  }
  const skipAgNo = async (e) => {
    removeFromPending('slide11');
    pushPageChange();    
    setAgNoShow('hide');
    const skippedLeadsResponse = await skippedLeadsFLP('SkipAgreementNoFlp',allFollowupData, splitName);
 }

 const saveIncomeAndExpense = (checklistOptions, inputListValues) => {
  var question_data_79 = {
    question_id: 79,
    option_id: null,
    answer_text: null,
    input_answer: checklistOptions,
  };
  var question_data_80 = {
    slide_80: ["80", JSON.stringify(inputListValues)],
  };


  removeFromPending("were_already_paying");
  storeFollowupQuestionnire(question_data_79);
  storeFollowupQuestionnire(question_data_80);
  setDataToLocalStorage(79, question_data_79);
  setDataToLocalStorage(80, question_data_80);
};

const setDataToLocalStorage = (quest_id, quest_data) => {
  let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
  if (_existingEntries == null) _existingEntries = [];
  let existingIndex;
  if(quest_id == 80) {
    existingIndex = _existingEntries.findIndex(entry => (entry?.slide_80));
  } else {
    existingIndex = _existingEntries.findIndex(entry => entry.question_id == quest_id);
  }
  if (existingIndex !== -1) {
    _existingEntries.splice(existingIndex, 1);
  }
  _existingEntries.push(quest_data);
  localStorage.setItem("questionData", JSON.stringify(_existingEntries));
};


const skipLeadStore = async (e,skipLeadContent,removeSlide) => {
  const skippedLeadsResponse = await skippedLeads(skipLeadContent,currentUuid, splitName);
  removeFromPending(removeSlide);
}

  return (
    <>
      <div className="QuestionnaireV7">
        <div className="formsection">
          <div className="container">
            <div className="row">
              <form
                ref={splitForm}
                name="split_form"
                id="user_form"
                method="POST"
                autoComplete="off"
              >
                <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12">
                  <div className="radio-box animated fadeInUp">
                    <div
                      className={`question1 animated fadeInUp space-top ${state.intialyear}`}
                      id="breakdown01"
                    >
                      <IntialYear
                        questArr={questinnare1}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        newDayData={newDayData}
                        saveInputAns={saveInputAns}
                        SetIntialLoanYear={SetIntialLoanYear}
                        setAgreeModalShow={setAgreeModalShow}
                      />
                    </div>

                    <div
                      className={`question1 animated fadeInUp space-top ${state.struggle}`}
                      id="breakdown01"
                    >
                      <Struggle
                        questArr={questinnare1}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        newDayData={newDayData}
                        saveInputAns={saveInputAns}
                        lender={newDayData.lender}
                      />
                    </div>
                    <div
                      className={`question1 animated fadeInUp space-top ${state.claimfilled}`}
                      id="breakdown01"
                    >
                      <Filled
                        questArr={questinnare1}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        newDayData={newDayData}
                        saveInputAns={saveInputAns}
                        lender={newDayData.lender}
                      />
                    </div>
                    <div
                      className={`question1 animated fadeInUp space-top ${state.bankrupty}`}
                      id="breakdown01"
                    >
                      <Bankruptcy
                        questArr={questinnare1}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        newDayData={newDayData}
                        saveInputAns={saveInputAns}
                        lender={newDayData.lender}
                      />
                    </div>
                    <div
                      className={`question1 animated fadeInUp space-top ${state.stillactive}`}
                      id="breakdown01"
                    >
                      <StillActive
                        questArr={questinnare1}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        newDayData={newDayData}
                        saveInputAns={saveInputAns}
                        lender={newDayData.lender}
                      />
                    </div>
                    <div
                      className={`question1 animated fadeInUp space-top ${state.showSlide1}`}
                      id="breakdown01"
                    >
                      <QuestionnaireFlpAgreementNo
                        questArr={questinnare1}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        saveInputAns={saveInputAns}
                        SkipAgreement={SkipAgreement}
                      />
                    </div>
                    <div
                      className={`question2 animated fadeInUp space-top ${state.showSlide2}`}
                      id="breakdown1"
                    >
                      <ClosedYear
                        questArr={questinnare1}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        saveIncomeExpenditure={saveIncomeExpenditure}
                        splitForm={splitForm}
                        newDayData={newDayData}
                        saveInputAns={saveInputAns}
                        intialLoanYear={intialLoanYear}
                      />
                    </div>
                    <div
                      className={`question1 animated fadeInUp space-top ${state.employment_status}`}
                      id="breakdown01ae"
                    >
                      <EmployementStatus
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        lender={newDayData.lender}
                        intialLoanYear={intialLoanYear}
                        newDayData={newDayData}
                        yearUpdated={yearUpdated}
                      />
                    </div>
                    <div
                      className={`question2 animated fadeInUp space-top ${state.showSlide3}`}
                      id="breakdown1"
                    >
                      <HighestBal
                        questArr={questinnare1}
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        saveIncomeExpenditure={saveIncomeExpenditure}
                        splitForm={splitForm}
                        newDayData={newDayData}
                        saveInputAns={saveInputAns}
                      />
                    </div>
                    <div
                      className={`question3 animated fadeInUp text-center ${state.showSlide4}`}
                      id="breakdown2"
                    >
                      <LimitIncrease
                        validation={register}
                        getValues={getValues}
                        trigger={trigger}
                        validationMsg={errors}
                        onselectAnswer={onselectAnswer}
                      />
                    </div>
                    <div
                      className={`question3 animated fadeInUp text-center ${state.showSlide5}`}
                      id="breakdown2"
                    >
                      <CreditLimit
                        validation={register}
                        getValues={getValues}
                        trigger={trigger}
                        validationMsg={errors}
                        onselectAnswer={onselectAnswer}
                      />
                    </div>
                    <div
                      className={`question4 animated fadeInUp ${state.showSlide6}`}
                      id="breakdown3"
                    >
                      <MinPayment
                        validation={register}
                        getValues={getValues}
                        trigger={trigger}
                        validationMsg={errors}
                        onselectAnswer={onselectAnswer}
                      />
                    </div>

                    <div
                      className={`question4 animated fadeInUp ${state.showSlide7}`}
                      id="breakdown3"
                    >
                      <Amounts
                        questArr={questinnare1}
                        saveSlide4={saveSlide4}
                        name="question_31_35"
                        btnName="slide_4"
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        setClickedModel={setClickedModel}
                        setOtherExpShow={setOtherExpShow}
                        setMonthlyExpShow={setMonthlyExpShow}
                      />
                    </div>

                    <div
                      className={`question4 animated fadeInUp ${state.showSlide7a}`}
                      id="breakdown3"
                    >
                      <AmountNDY_UB_4
                        questArr={questinnare1}
                        saveIncomeExpenditureNDY_UB_4={
                          saveIncomeExpenditureNDY_UB_4
                        }
                        name="question_31_35"
                        btnName="slide_4"
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        setClickedModel={setClickedModel}
                        setOtherExpShow={setOtherExpShow}
                        setMonthlyExpShow={setMonthlyExpShow}
                      />
                    </div>
                    <div
                      className={`question1 animated fadeInUp space-top ${state.did_receive_income}`}
                      id="breakdown012"
                    >
                      <DidReceiveIncome
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                      />
                    </div>
                    <div
                      className={`question1 animated fadeInUp space-top ${state.card_monthly_income}`}
                      id="breakdown012"
                    >
                      <CardMonthlyIncome
                        saveInputAns={saveInputAns}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                      />
                    </div>
                    <div
                      className={`question1 animated fadeInUp space-top ${state.dependent_status}`}
                      id="breakdown01ae"
                    >
                      <DependentStatusNew
                        saveInputAns={saveInputAns}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        clearErrors={clearErrors}
                      />
                    </div>
                    <div
                      className={`question1 animated fadeInUp space-top ${state.were_already_paying}`}
                      id="breakdown012"
                    >
                      <WereAlreadyPaying
                        saveIncomeAndExpense={saveIncomeAndExpense}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        checklistOptions={checklistOptions}
                        setChecklistOptions={setChecklistOptions}
                        questArr={wereUalreadyPaying}
                      />
                    </div>
                    <div
                      className={`question4 animated fadeInUp space-top ${state.showSlide8}`}
                      id="breakdown3"
                    >
                      <EmailQuestion
                        onselectAnswer={onselectAnswer}
                        newDayData={newDayData}
                      />
                    </div>
                    <div
                      className={`question4 animated fadeInUp space-top ${state.showSlide9}`}
                      id="breakdown3"
                    >
                      <QuestionnaireEmail
                        validation={register}
                        getValues={getValues}
                        trigger={trigger}
                        validationMsg={errors}
                        saveInputAns={saveInputAns}
                        lender={newDayData.lender}
                      />
                    </div>
                    <div
                      className={`question9 animated fadeInUp space-top2 ${state.showSlide10a}`}
                      id="breakdow10"
                    >
                      <QuestionnaireFlpQ76
                        questArr={questinnaire76MB}
                        onselectAnswer={onselectAnswer}
                        optionSelected={optionSelected76}
                      />
                    </div>
                    <div
                      className={`question4 animated fadeInUp space-top2 ${state.showSlide10}`}
                      id="breakdown3"
                    >
                      <QuestionnaireFlpQ5
                        questArr={questinnare2VeryV2}
                        onselectAnswer={onselectAnswer}
                        optionSelected={optionSelected}
                      />
                    </div>
                    <div
                      className={`question9 animated fadeInUp space-top2 ${state.showSlide11}`}
                      id="breakdow10"
                    >
                      <QuestionnaireSupportDoc
                        labelclicked={onselectAnswer}
                        onselectAnswer={onselectAnswer}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        saveInputAns={saveInputAns}
                      />
                    </div>
                    <div
                      className={`question5 animated fadeInUp space-top2 ${state.showSlide12} radio-box`}
                      id="breakdown4"
                    >
                      <QuestionnaireFlpFANUpload
                        questArr={questinnare2VeryV2}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        docType={docType}
                        documentType={documentType}
                        allFollowupData={allFollowupData}
                        sett2aValidation={sett2aValidation}
                        pushPageChange={pushPageChange}
                        setShow={setShow}
                        split_name={splitName}
                      />
                    </div>

                    <div
                      class={`radio-box animated fadeInUp space-top space-top2 ${state.cardOverSixYears}`}
                      id="slide-23"
                    >
                      <h3>
                        <b>Did you take the card over 6 years ago?</b>
                      </h3>
                      <CardOverSixYears onSelectAnswer={onselectAnswer} />
                    </div>


                    <Aware  
                      validation={register}
                      validationMsg={errors}
                      clearErrors={clearErrors}
                      clsName={state.aware}
                      trigger={trigger}
                      setValue={setValue}
                      onselectAnswer={onselectAnswer}
                      lender={newDayData.lender}
                      saveInputAns={saveInputAns}
                      getValues={getValues}
                      setError={setError}
                    />


                    <div
                      className={`radio-box animated fadeInUp space-top space-top2 ${state.latePaymentLetters}`}

                      // className={`radio-box animated fadeInUp space-top space-top2 show`}
                      id="slide-25"
                      // ref={(el) => (scrollRef.current["late_payment_letter"] = el)}
                    >
                      <h3>
                        <b>
                          Did you receive any late payment letters / any other
                          notifications from them or was your account passed to
                          a debt company?
                        </b>
                      </h3>
                      <LatePaymentLetters
                      onSelectAnswer={onselectAnswer}
                      />
                    </div>

                    <div
                      className={`radio-box animated fadeInUp space-top space-top2 ${state.difficulties} z-3`}
                      
                      id="slide-26"
                      // ref={(el) => (scrollRef.current["difficulty"] = el)}
                    >
                      <h3 >
                        <b>
                        When you received the communications from the lender,
                        did you feel that any difficulties you had repaying /
                        financially were:
                        </b>
                      </h3>

                      <Difficulties
                      onSelectAnswer={onselectAnswer}
                      />
                    </div>

                    <div
                      className={`radio-box animated fadeInUp space-top space-top2  ${state.financeProblem}`}
                      
                      id="slide-27"
                      // ref={(el) => (scrollRef.current["problem"] = el)}
                    >
                      <h3 >
                        <b>
                        Please describe what really caused you to have problems
                        in repaying / financially?
                        </b>
                      </h3>
                      <FinanceProblem
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        saveInputAns={saveInputAns}
                        setError={setError}
                      />
                    </div>

                    <BankAccNo 
                    clsName={state.accno}
                    setAccountNo={setAccountNo}
                    saveInputAns={saveInputAns}
                    skippedLeadsFLP={skipLeadStore}
                    />
                    <SortCode 
                    clsName={state.sortCode}
                    setSortCode={setSortCode}
                    saveInputAns={saveInputAns}
                    skippedLeadsFLP={skipLeadStore}
                    />
                    <BankName 
                    clsName={state.bankName} 
                    lender={newDayData.lender}
                    saveInputAns={saveInputAns}
                    SelectBank={selectBank}
                    setSelectBank={setSelectBank}
                    />
                  </div>
                  
                </div>
                <AmountModal
                  forwardRef={splitForm}
                  validation={register}
                  validationMsg={errors}
                  handleChange={handleChange}
                  showModel1={monthlyExpShow}
                  showModel2={otherExpShow}
                  totalDebitValidation={totalDebitValidation}
                  totalOtherDebitValidation={totalOtherDebitValidation}
                  newDayData={newDayData}
                />
                <AgreeModal
                  agreeModalShow={agreeModalShow}
                  setAgreeModalShow={setAgreeModalShow}
                  validation={register}
                  validationMsg={errors}
                  onselectAnswer={onselectAnswer}
                />
              </form>
            </div>
          </div>
          <QuestionnaireFlpAgreementNoModalV2
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            saveInputAns={saveInputAns}
            agNoShow={agNoShow}
            setDivShow={setDivShow}
            skipAgNo={skipAgNo}
            setAgNoShow={setAgNoShow}
          />
          <AnalyzeModal modalClass={t2aModal} />
        </div>
      </div>
      <QuestionnaireFooter percentage={state.percentage} />
    </>
  );
};
export default NewDayV2QuestionForm;
