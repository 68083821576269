import { useEffect } from "react";

const OB_SplashV2 = ({
  obSplashStat,
  btndisable,
  keepMyFileLinkOne,
  keepMyFileLinkTwo,
  closeMyFileLink,
  loader,
  userDetails,
  setMatchCheckLoader,
}) => {
  useEffect(() => {
    if (obSplashStat === "show") {
      setMatchCheckLoader("hide");
    }
  }, [obSplashStat]);
  const fname = localStorage?.getItem("firstname");
  const agreementDate = localStorage?.getItem("agreementDate");
  return (
    <>

      <div className={`__ExpampleDemo1 ${obSplashStat}`}>
        <div className="loader_1">
          <div className={`text-center loader-wrap2 ${loader == 1 ? 'showload' : 'hide'}`}>
            <div className="loader">
              <img src="assets/img/review.gif" alt="" />
            </div>
          </div>
        </div>
        <div className={`container ${loader == 0 ? 'showload' : 'hide'}`}>
          <div className="row">
            <div className="col-12 showcase">
          <div className="splash-logo-top">
            <img height="65" src="assets/OpenBankingV2/img/tms-logo-white.png" alt="" />
            </div>
              <div className="video-container">
                <video
                  src="../../assets/img/production_id_4393393_(1080p).mp4"
                  autoPlay
                  muted
                  loop
                ></video>
              </div>
              <div className="content">
             
                <h1>
                  {fname}, help your {userDetails.bankName} responsible lending claim by providing bank statements and a credit file. These documents will really help your case.
                </h1>
                <div className="row">
                  <div className="col-lg-10 offset-lg-1">
                    <h3 className="mb-lg-5 mb-md-5 mb-sm-4 mb-4">  To assist in safely securing supporting evidence we have partnered with leading financial technology companies to make the process quick, easy and free!</h3>
                  </div>
                </div>
                {/* <div className="row"> */}
                <div className="row">
                  <div className="col-lg-8 offset-lg-2 px-lg-0 px-md-0 px-sm-0">
                    <div className="row">
                      <div className="col-lg-10 col-md-10 offset-lg-1 offset-md-1    text-center mb-3">
                        
                        <div>
                          <button
                            id="KeepOpen1"
                            className="com-btn w-100"
                            onClick={keepMyFileLinkOne}
                            disabled={btndisable}
                            data-btn-label="keep_my_file_open"
                            data-page-label="OB_SPLASH"
                          >
                             Click Here to provide documents<i className="bi bi-arrow-right ms-3"></i>
                          </button>
                        </div>
                      </div>
                      {/* <div className="col-lg-6 col-md-6 col-sm-12 text-center mb-3">

                        <div>
                          <button
                            id="KeepOpen2"
                            className="com-btn w-100"
                            onClick={keepMyFileLinkTwo}
                            disabled={btndisable}
                            data-btn-label="keep_my_file_open"
                            data-page-label="OB_SPLASH"
                          >
                            Click Here to provide documents  {'>'}<i className="bi bi-arrow-right ms-3"></i>
                          </button>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* <div className="cls_btn text-center">
                  <a href="#">
                    <button
                      className="close_bbt"
                      onClick={closeMyFileLink}
                      disabled={btndisable}
                      data-btn-label="close_my_file"
                      data-page-label="OB_SPLASH"
                    >Close My File {'>'}</button>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default OB_SplashV2;
