import React, {useContext} from "react";
import { Api, CommonPhoneValidation,CommonEmailValidateApiTimeDelay} from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";
import { useErrorWebhook } from "./useErrorWebhook";
import VisitorParamsContext from "../Contexts/VisitorParams";

export const usePhoneValidation = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const uuid=visitorParameters.visitor_parameters.uuid;
  
  const phoneValidation = async (phone) => {
    let phoneVal = 1;
    const response = await CommonPhoneValidation.post("/api/validate", {
    "ProjectCode":EnvConstants.AppAdtopiaUPID,
    "Environment": EnvConstants.AppEnv,
    "Phone": phone,
    "uuid": uuid
    })
    // const response = await CommonEmailValidateApiTimeDelay.get(
    //   "sleep?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&phone='+phone,
    //   {})
    .catch(function (error) {
      const erroMessage = {
        serviceName: 'PHONE VALIDATION',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      phoneVal = 0;
      ErrorWebhookTrigger(erroMessage);
    });
    if (phoneVal == 0) {
      let phoneFormat = /^[0-9]*$/i;
      if ((phone.length < 11) || (!phone.match(phoneFormat))) {
        return {
          data: {
            status_code: 0
          }
        }
      } else {
        return {
          data: {
            status_code: 1
          }
        }
      }
    } else {
      return response;
    }
  };
  return { phoneValidation };
};
