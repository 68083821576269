import { Api } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";

export const userMilestoneLive = () => {
  const updateMilestone = async (
    uuid,
    milestone,
    source,
    value=1
  ) => {
    const response = await Api.post("api/v1/update-milestone-live", {
      uuid,
      milestone,
      source,
      milestone_value : value
    });
    return response;
  };
  return { updateMilestone };
};
