import React, { useState } from "react";
import TextArea from "../../../UI/TextArea";


const DescribeProblem = ({ 
    saveInputAns, 
    backClick,
    validation,
    validationMsg,
    trigger,
    getValues,
    setError
}) => {

    const handleClick = async (e) => {
        // let problem = await trigger("descProblem");
        // if (problem) {
        //     saveInputAns(e);
        // } else {
        //     return false;
        // }
       let errorFlag = 0;
       let result = await trigger("descProblem");
       if (!result) {
           errorFlag = 1;
           return false;
       } else {
        const fieldResult = getValues();
        var reason = fieldResult["descProblem"].trim();
        if (reason == "" || reason.length < 3) {
            setError("descProblem", {
              type: "manual",
              message: "Please Enter Valid Reason",
        });
            return false;
        } else {
        saveInputAns(e);
        }
       }
    };

    return (
        <>
            <div
                className={`question-content mt-0 scale-down-ver-center px-3  py-lg-3 py-md-3 py-sm-2 py-2 `}
            >
                   
                   <TextArea
                            type="textarea"
                            className="form-control"
                            name="descProblem"
                            validation={validation({
                                required: "Please Enter the Reason",
                                minLength: {
                                    value: 3,
                                    message: "Please Enter Valid Reason",
                                },
                            })}
                            validationMsg={
                                validationMsg.descProblem &&
                                validationMsg.descProblem.message
                            }
                        />
                   
                    <div className="col-lg-12 mb-2 mt-2">
            <button
              className="qb14 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
              type="button"
              onClick={handleClick}
              data-quest-id={84}
              data-ans-id={null}
              name="describeProblem"
            >
              Next <i className="bi bi-arrow-right" />
            </button>
          </div>
      

                <p className="text-center bck-marwid">
                    <button
                      name="difficulties"
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>


            </div>





        </>

    );
}


export default DescribeProblem;