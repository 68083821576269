import React, { useState } from "react";
import ProgressBar from './ProgressBar';
import RadioButton from '../../../UI/RadioButton';

const Slide8 = ({
  className,
  slideChange,
  validation,
  trigger,
  clearErrors,
  getValues,
  setError,
  backClick
}) => {

  const [checked1, setChecked1] = useState('');
  const [checked2, setChecked2] = useState('');

  const nextSlide = async (e) => {
    if (e.target.value == 15) {
      setChecked1('activeclass');
    } else {
      setChecked1('');
    }

    if (e.target.value == 16) {
      setChecked2('activeclass');
    } else {
      setChecked2('');
    }

    slideChange(e);
  };

  return (
    <>
      <div id="slide8" className={`col-12 top_minus ${className}`}>
        <ProgressBar value={50} />
        <h3>At the time you secured finance… </h3>

        <div className="mb-3" id="slide-E">
          <h4>Did MoneyBark ask about your monthly expenses?</h4>

          <RadioButton
            htmlFor="previously"
            value="15"
            tabIndex="2"
            id="monthlyExpense-1"
            name="monthlyExpense"
            className="radio-button"
            labelName="Yes"
            labelClassName={`option_but next-E ${checked1}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            htmlFor="previously"
            value="16"
            tabIndex="2"
            id="monthlyExpense-2"
            name="monthlyExpense"
            className="radio-button"
            labelName="No"
            labelClassName={`option_but next-E ${checked2}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <p className="text-center">
            <a href="#" className="back-D" id="back07" name="back07" onClick={backClick}>
              {'<< Previous'}
            </a>
          </p>
        </div>

      </div>
    </>
  );
};

export default Slide8;