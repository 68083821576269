import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Api } from "../../api/Api";
// import "../../../../assets/OpenBanking/css/styleV1.scss";
import "../../assets/FollowupFos/css/customfos.scss";
import "../../assets/FollowupFos/css/stylefos.scss";
import "../../assets/FollowupFos/css/homefos.scss";
import "../../assets/FollowupFos/css/questionnairefos.scss";
import "../../assets/FollowupFos/css/signaturefos.scss";
import { queryString } from "../../Utility/QueryString";
import { useOpenBanking } from "../../Hooks/useOpenBanking";

const FOSCreditCheckFLP = () => {
  const [loader, setLoader] = useState(1);
  const [credLoader, setCredLoader] = useState(0);
  const [proceedLoader, sePproceedLoader] = useState(0);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [btndisable, setBtnDisable] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [clientRefId, setClientRefId] = useState();

  const history = useHistory();
  const { storeClickResponse } = useOpenBanking();
  const query = new URLSearchParams(window.location.search);
  const query_source = query.get("atp_sub6");
  var source = query_source ? query_source : "FLP_469";
  let btn_label = '';
  let page = '';
  const userId = localStorage.getItem("user_id");
  let uuid = '';
  let user_flg_id = '';
  useEffect(() => {
    (async () => {
      const response = await Api.get(
        "/api/v1/fos-user-basic-details?query_string=" + queryString,
        {}
      );
      if (response.data.status === "Success") {
        let user_data = response.data.response.user_data;
        let lender = response.data.response.lender;
        let lstSalutation = response.data.response.lstSalutation;
        let txtFName = response.data.response.txtFName;
        let txtLName = response.data.response.txtLName;
        let txtEmail = response.data.response.txtEmail;
        let txtPhone = response.data.response.txtPhone;
        let is_test = response.data.response.is_test;
        let token = response.data.response.user_data.atp_sub2;
        let pixel = response.data.response.user_data.pixel;
        let txtPostcode = response.data.response.txtPostcode;
        let txtAddressline1 = response.data.response.txtAddressline1;
        let txtAddressline2 = response.data.response.txtAddressline2;
        let txtAddressline3 = response.data.response.txtAddressline3;
        let town = response.data.response.town;
        let dob = response.data.response.dob;
        let userId = response.data.response.user_id;
        let clientRefId = response.data.response.client_ref_id
        let lenders = response.data.response.providers;
        let isComplete = response.data.response.is_completed;
        uuid = response?.data?.response?.uuid;
        user_flg_id = response?.data?.response?.user_flg_id;
        if (uuid == '') {
          uuid = user_flg_id;
        }

        localStorage.setItem('is_test', is_test);
        localStorage.setItem('user_data', JSON.stringify(user_data));
        localStorage.setItem('user_id', userId);
        setClientRefId(clientRefId);



        if (clientRefId === null) {
          history.push("/thankyou?clientRefId=" + clientRefId);
        }
        setUserDetails({
          lender: lender,
          source: source,
          lstSalutation: lstSalutation,
          txtFName: txtFName,
          txtLName: txtLName,
          txtEmail: txtEmail,
          txtPhone: txtPhone,
          is_test: is_test,
          token: token,
          pixel: pixel,
          txtPostcode: txtPostcode,
          txtAddressline1: txtAddressline1,
          txtAddressline2: txtAddressline2,
          txtAddressline3: txtAddressline3,
          town: town,
          dob: dob,
          user_id: userId,
          isComplete: isComplete,
          clientRefId: clientRefId
        });
        setTimeout(() => {
          setLoader(0);
        }, 2000);

        if (isComplete == 1) {
          setLoader(0);
          history.push("/thankyou?uuid=" + uuid);
        }
      } else {
        setLoader(0);
        history.push("/thankyou?uuid=" + uuid);
        return false;
      }

    })();


  }, []);


  useEffect(() => {
    const timer = setTimeout(() => {
      renderTiming(userDetails, clientRefId);
    }, 2000)

    return () => {
      clearTimeout(timer);
    };

  }, [userDetails, clientRefId]);

  const renderTiming = async () => {

    let urlPortion = 'title=' + userDetails.lstSalutation + '&fname=' + userDetails.txtFName + '&lname=' + userDetails.txtLName + '&email=' + userDetails.txtEmail + '&phone=' + userDetails.txtPhone + '&dob=' + userDetails.dob + '&abodenumber=' + '&building_name=' + userDetails.txtAddressline1 +'&straddr=' + userDetails.txtAddressline2 + '&street=' + '&city=' + userDetails.town + '&zip=' + userDetails.txtPostcode + '&clientRefId=' + userDetails.clientRefId;

    localStorage.setItem('url_portion', urlPortion);
    localStorage.setItem('firstname', userDetails.txtFName);

    if (clientRefId && userDetails.isComplete == 0) {
      let payload = { "page": 'CREDIT_CHECK', type: 'visit', source };
      await storeClickResponse({ uuid: clientRefId }, "ob_fos_creditcheck_history", clientRefId, payload);

      setTimeout(() => {
        setLoader(0);
      }, 1500);

      let iframUrl = "https://uk.rspcdn.com/xprr/red/PID/3916?" + urlPortion;
      console.log(iframUrl);
      setIframeUrl(iframUrl);

    } else {
      history.push("/thankyou?uuid=" + clientRefId);
    }

  }

  const redirectCreditCheck = async (e) => {

    setBtnDisable(true);
    setLoader(1);
    // setCredLoader(1);

    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');

    let payload = { page, type: 'btn_click', btn_label, source, 'click_btn': '3' };
    await storeClickResponse({ uuid: clientRefId }, "ob_fos_creditcheck_history", clientRefId, payload);

    setTimeout(() => {
      setLoader(0);
      setCredLoader(1);
    }, 1500);
  };

  return (
    <>
      <div className="FollowupFosV1">
        <div className="tms-wrap bg-main-pr">
          <div className={`text-center loader-wrap loader1 ${loader === 1 ? 'showload' : 'hide'}`}>
            {/* <div className="loader-logo">
              <img src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt="" />
            </div> */}
            <div className="loader">
              <img src="assets/img/review.gif" alt="" />
            </div>
          </div>
          <div className={`${credLoader == 0 ? "showload" : "hide"}`}>
            <div
              className={`main text-center main-div  ${loader == 0 ? "showload" : "hide"
                }`}
            >
              <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
                <img
                  height="65"
                  src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
                  alt=""
                />
              </div>

              <div className="main-form-wraper" style={{ marginTop: '3rem' }}>
                <div className="container">
                  <div className="item-cent-flx ">

                    <div className='slide4' >
                      <div className="  text-center">
                        <div className="col-lg-8 offset-lg-2 px-4 mb-2 text-center">
                          <h1 className="  mt-md-2 mt-sm-2 mt-0 text-danger">We require your credit report to complete our
                            comprehensive investigation and finalise your complaint </h1>
                          <p style={{ maxWidth: '800px' }} className="my-3 ">We have partnered with UK Credit Ratings to offer a FREE,
                            comprehensive, and simple - to -use service. </p>

                        </div>

                        <div className="row   mt-md-2 mt-sm-3 mt-0">
                          <div className="col-lg-6 offset-lg-3 px-4 mb-2 text-center">

                            <div>

                              <button
                                onClick={redirectCreditCheck}
                                disabled={btndisable}
                                data-btn-label="access_free_credit_report"
                                data-page-label="CREDIT_CHECK"
                                className="btn-comm2 second disablebtn"
                              >
                                {" "}
                                Access Free Credit Report <span style={{ lineHeight: '21px !important' }}> {'›'}</span>{" "}
                              </button>


                              <p className="mt-3 text2b mb-2"> 24/7 Monitoring, Individual Credit Review. Correct Credit Errors.</p>
                              <p className="mt-0 text3b text-danger"><img src="assets/img/clock.png" style={{ width: '3%' }} /> Estimated Processing 1 minutes
                              </p>
                            </div>
                          </div>

                        </div>
                        <div className="text-center mt-4"><img src="assets/FollowupFos/img/uk-credits.png" alt="" /></div>
                        <div className="pb-lg-1 pb-md-1 pb-sm-1 pb-1 mt-lg-2 mt-lg-2 mt-md-2 mt-sm-2 ">
                          <img height="50" src="assets/FollowupFos/img/safe and secure.png" alt="" />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div
            className={`${credLoader == 1 ? "showload" : "hide"
              } iframe-container`}
          >
            <iframe src={iframeUrl} frameborder="0"></iframe>
          </div>
        </div>
      </div>
    </>
  );
};
export default FOSCreditCheckFLP;
