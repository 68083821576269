import React,{useState} from 'react';
import Modal from 'react-bootstrap/Modal';
 

export default function Complaints({showComplaint,modalClick}){
  return (
    <Modal
      show={showComplaint}
      size="xl"
      className="modal fade"
      id="complaints"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">COMPLAINTS PROCEDURE</h4>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => modalClick("exitComplaint")}
          ></button>
        </div>
        <div className="modal-body">
          <h3>Complaints Procedure</h3>
          <ul>
            <li>
              We endeavour to provide a good quality service with efficiency and
              courtesy. It is our aim that our clients should be satisfied with
              all aspects of the work undertaken by us.
            </li>
            <li>
              We aim to resolve complaints as quickly and efficiently as
              possible while at the same time gaining a complete understanding
              of the client’s complaint points and addressing each one.
            </li>
            <li>
              A complaint can be made verbally or in writing. For example it can
              be by letter, telephone, e-mail or by any other contact medium we
              have available.
            </li>
          </ul>
          <ul>
            <li>
              Our contact details are:
              <ul>
                <li>
                  By Post: TMS Legal Ltd, Arena, 9 Nimrod Way, Ferndown, BH21
                  7UH
                </li>
                <li>
                  email:
                  <a href="mailto:info@moneysolicitor.com">
                    info@moneysolicitor.com
                  </a>
                </li>
                <li>By phone: 0800 0073 777</li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              Once a complaint has been received a letter or electronic
              acknowledgement of the complaint will be sent within 
              <strong> 5 business days</strong> by Simona Zaharia, the Operations
              Manager, who is responsible for investigating your complaint.
            </li>
            <li>
              Within 4 weeks of the complaint being received we will send either
              a final response letter addressing all your complaint points, or a
              holding response advising you as to why the complaint has not been
              resolved.
            </li>
            <li>
              If a holding response is sent an indication will be given as to
              when our final response letter will be sent to you, but it will be
              within 8 weeks from the date of the receipt of the original
              complaint.
            </li>
            <li>
              Within 8 weeks from the date of receipt of your complaint we will
              send you a final response letter addressing all your complaint
              points.
            </li>
            <li>
              If we have not resolved the complaint to your satisfaction you
              have the right to complain to the Legal Ombudsman, an independent
              complaints body established under the Legal Services Act 2007.
            </li>
            <li>
              The Legal Ombudsman expects complaints to be made within one year
              of the act or omission about which you are concerned, or within
              one year of you realising that there was a concern.
            </li>
            <li>
              You must also refer your your concerns to the Legal Ombudsman
              within 6 months of the date of our final response letter to you.
            </li>
          </ul>
          <ul>
            <li>
              The Legal Ombudsman can be contacted:
              <ul>
                <li>By post: P.O Box 6167, Slough, SL1 0EH</li>
                <li>
                  By email:
                  <a href="mailto:enquiries@legalombudsman.org.uk">
                    enquiries@legalombudsman.org.uk
                  </a>
                </li>
                <li>By phone: 0300 555 0333</li>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              Alternative complaint bodies exist that are competent to deal with
              complaints about legal services should both you and TMS Legal wish
              to use such a scheme. A list of those that the company recommends
              are available on request.
            </li>
            <li>
              Please note that the Legal Ombudsman is there to deal with
              concerns about the level of service received. If your concern is
              related to behaviour, for example dishonesty, or that you have
              been treated unfairly because of your age, disability, or other
              characteristic, you can raise your concerns with the Solicitors
              Regulation Authority. Full details on how to do this can be found
              on their website: www.sra.org.uk
			  {/* <a href='https://www.sra.org.uk/'>www.sra.org.uk</a> */}
            </li>
          </ul>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            data-bs-dismiss="modal"
            onClick={() => modalClick("exitComplaint")}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
}