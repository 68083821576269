import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButton";
import TextField from "../../../UI/TextField";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import ProgressBar from "../Common/ProgressBar";

const BankStmt = ({
  clsName,
  slideChange,
  validation,
  reff,
  textHeading,
  backClick
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");

  const labelclicked = async (e) => {
    console.log("did_Bank_Stmt_provide: " + e.target.value);
    localStorage.setItem("did_Bank_Stmt_provide", e.target.value);
    if (e.target.value === "211") {
      setCheckedOne("checked");
      localStorage.setItem("OBStatement", 1);
      localStorage.setItem("BankStatement", 1);
      slideChange(e);
    
      
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "210" || e.target.value === "212") {
      setCheckedTwo("checked");
      localStorage.setItem("OBStatement", 0);
      localStorage.setItem("BankStatement", 0);
      slideChange(e);
    } else {
      setCheckedTwo("unchecked");
    }
  };

//   const notSureclicked = async (e) => {
//     console.log("employment_status: " + "Not Sure");
//     localStorage.setItem("employment_status", "Not Sure");
//     onselectAnswer(e);
//   };

  return (
    <>
      <div className={`col-12 top_minus_1 mb-5 ${clsName}`} ref={reff}>
      <ProgressBar value="15" />
        <h3>{textHeading}</h3>
        <div className="btn-comm">

        {/* <div className="mb-3 col-lg-8 offset-lg-2 col-12 ">  */}


                    <RadioButton
                      key="question_74_payslips"
                      className="RadioButton"
                      labelName="Payslips"
                      onClick={labelclicked}
                      value="210"
                      name="question_74"
                      id="question_74_payslips"
                      labelClassName={`option_but   next9 ${checkedOne}`}
                      validation={validation()}
                    />

                    <RadioButton
                      key="question_74_bank_Stmt"
                      className="radio-button"
                      labelName="Bank Statements"
                      onClick={labelclicked}
                      value="211"
                      name="question_74"
                      id="question_74_bank_Stmt"
                      labelClassName={`option_but   next9 ${checkedOne}`}
                      validation={validation()}
                    />



                    <RadioButton
                      key="question_74_cant_remember"
                      className="RadioButton"
                      labelName="Can't remember"
                      onClick={labelclicked}
                      value="212"
                      name="question_74"
                      id="question_74_cant_remember"
                      labelClassName={`option_but   next9 ${checkedOne}`}
                      validation={validation()}
                    />
                    </div>

          {/* <p className="text-center">
            <a className="skip-btn skip1"
              id="Not sure"
              value="Not sure"
              name="employment"
              data-ans-id={195}
              data-quest-id={2}
              onClick={notSureclicked}>Not sure</a>
          </p> */}
        {/* </div> */}
        <p className="text-center"><a className="back01" name="prevBankstmt" onClick={backClick}> {'<< Previous'} </a></p>
      </div>
    </>
  );
};

export default BankStmt;
