import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import Modal from "react-bootstrap/Modal";
import InputButton from "../../../UI/InputButton";
const Verify2 = ({
  className,
  slideChange,
  textHeading,
  validation,
  backClick,
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);
  const nextSlide = async (e) => {
    if (e.target.value === "101") {
      setCheckedOne("checked");
      setshowClaimModal(true);
      //slideChange(e);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "102") {
      setCheckedTwo("checked");
      slideChange(e);
    } else {
      setCheckedTwo("unchecked");
    }
  };
  return (
    <>
      <div id="slide10" className={`${className}`}>
        <ProgressBar value="45" />

        <h3> {textHeading} </h3>
        <div className="btn-comm">
          <RadioButton
            value="102"
            name="verify2"
            className="radio-button"
            labelName="No"
            labelClassName={`option_but    next10 ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <RadioButton
            value="101"
            name="verify2"
            className="radio-button"
            labelName="Yes"
            labelClassName={`option_but    next10 ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <p className="text-center"><a  className="back01" name="back08" onClick={backClick}> {'<< Previous'} </a></p>

      </div>
      {/* **** cannot accept your claim popup start **** */}
      <Modal size="md" show={claimModal} onHide={handleCloseclaimModal} centered backdrop="static" className="claimdeinial">
        <Modal.Body>
          <h3 className="text-center">Sorry, we cannot accept your claim</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Verify2;
