import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

// import privacy from "../../../../assets/VAQ_UB_4/img/privacy.png";
import Select from 'react-select';

const Banks = ({
    lenderList,
    SelectBank,
    setSelectBank,
    BankValidationMsg,
    setBankValidationMsg,
    divRefOne}) => {
        const { register, errors, getValues, setError } = useFormContext();
    
        const customStyles = {
            control: base => ({
              ...base,
              //height: 50,
              minHeight: 50
            })
          };

          function handleSelect(opt) {
            setSelectBank(opt);
            setBankValidationMsg("");
          }
          let BankList = []; //[{ value: null,  label: "---Select Bank Name---" }];
          // if(lenderList.length >0){
          //   lenderList.map(function (val) {
          //     let lenderData = { value: val.provider, label:val.display_name}
          //     BankList.push(lenderData);
          //   })
          // }
        
          if (lenderList.length > 0) {
            BankList = BankList.concat(lenderList.filter(function (val) {
              return !val.display_name.endsWith("Card") &&
                !val.display_name.endsWith("Business") &&
                !val.display_name.endsWith("card") &&
                !val.display_name.endsWith("Cards");
            }).map(function (val) {
              return {
                value: val.provider,
                label: val.display_name
              };
            }));
          }

    return (
        <>
            <div id="bankNamediv" className=" mt-4"  ref={divRefOne}>
                <fieldset className="p-2 px-3">
                    <legend className="px-2 m-0">Which bank were you with when you took the finance with Moneybarn?
</legend>
                    <div className="row">

                        <div className="col-lg-12 mb-3  ">
                            <div className="">
                                <Select
                                    placeholder="Select Bank"
                                    options={BankList}
                                    aria-label="Username"
                                    name="bankName"
                                    id="bankName"
                                    // className="form-select"
                                    value={SelectBank}
                                    onChange={handleSelect}
                                    isSearchable={true}
                                    styles={customStyles}
                                    // isMulti
                                />
                                <span id="bank_err" className="error_msg">{BankValidationMsg}</span>

                            </div>
                        </div>
                    </div>
                </fieldset>

            </div>
        </>
    );
};
export default Banks;
