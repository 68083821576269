import React ,{useState} from "react";
const AdvFooter = () => {
  const [showPrivacy, setShowPrivacy] = useState("hide");
  const [showCookie, setShowCookie]   =  useState("hide");
  const modalClick = ((clickValue) => {
    switch (clickValue) {
      case 'showPrivacy' : {
        setShowPrivacy("show");
        break;
      }
      case 'exitPrivacy':{
        setShowPrivacy("hide");
        break;
      }
      case 'showCookie' :{
        setShowCookie("show");
        break;
      }
      case 'exitCookie':{
        setShowCookie("hide");
        break;
      }
    }
  })
  return (
    <>
      <footer className="text-center contact-footer">
        <p>
          DISCLAIMER: There are many variations of passages of Lorem Ipsum
          available.
        </p>
        <p className="m-0">
          <b>Labor statistic sources:</b>
        </p>
        <p className="m-0">
          <a
            href="https://ec.europa.eu/eurostat/databrowser/view/gov_10dd_edpt1/default/table?lang=en"
            target="_blank"
          >
            Eurostat
          </a>{" "}
        </p>
        <p>
          <a href="https://themoneycharity.org.uk/" target="_blank">
            Lorem Ipsum
          </a>{" "}
        </p>
        <p className="text-center mb-0 mt-3">
          Copyright © Lorem Ipsum. 2022 All Rights Reserved{" "}
        </p>
        <p className="text-center">Lorem Ipsum.com</p>
        <ul>
          <li>
            <a data-bs-toggle="modal" data-bs-target="#prvcy" onClick={() => modalClick("showPrivacy")}>
              Privacy Policy
            </a>
          </li>
          <li>
            <a data-bs-toggle="modal" data-bs-target="#cooky" onClick={() => modalClick("showCookie")}>
              Cookies
            </a>
          </li>
          <li>
            <a href={"/ADV1/contact"} target="_blank">
              Contact Us
            </a>
          </li>
        </ul>
      </footer>
      <div class={`modal popup-modal ${showPrivacy}`} id="prvcy">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">PRIVACY POLICY</h4>
              <button type="button" class="close" data-bs-dismiss="modal" onClick={()=>modalClick("exitPrivacy")}>
                ×
              </button>
            </div>
            <div class="modal-body">
              <p>
                {" "}
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of the word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                in section 1.10.32. The standard chunk of Lorem Ipsum used since
                the 1500s is reproduced below for those interested. Sections
                1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by
                Cicero are also reproduced in their exact original form,
                accompanied by English versions from the 1914 translation by H.
                Rackham.
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={()=>modalClick("exitPrivacy")}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class={`modal popup-modal ${showCookie}`} id="cooky">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Cookies</h4>
              <button type="button" class="close" data-bs-dismiss="modal" onClick={()=>modalClick("exitCookie")}>
                ×
              </button>
            </div>
            <div class="modal-body">
              <h3> COOKIE POLICY </h3>
              <p>
                Contrary to popular belief, Lorem Ipsum is not simply random
                text. It has roots in a piece of classical Latin literature from
                45 BC, making it over 2000 years old. Richard McClintock, a
                Latin professor at Hampden-Sydney College in Virginia, looked up
                one of the more obscure Latin words, consectetur, from a Lorem
                Ipsum passage, and going through the cites of the word in
                classical literature, discovered the undoubtable source. Lorem
                Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus
                Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero,
                written in 45 BC. This book is a treatise on the theory of
                ethics, very popular during the Renaissance. The first line of
                Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line
                in section 1.10.32. The standard chunk of Lorem Ipsum used since
                the 1500s is reproduced below for those interested. Sections
                1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by
                Cicero are also reproduced in their exact original form,
                accompanied by English versions from the 1914 translation by H.
                Rackham.
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={()=>modalClick("exitCookie")}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdvFooter;
