import React, { useEffect, useState } from "react";
import CheckboxWithInput from "./CheckboxWithInput.js";


const IncomeAndExpV3 = ({
  backClick,
  checklistOptions,
  setChecklistOptions,
  inputListValues,
  setInputListValues,
  saveIncomeAndExpense
}) => {
  const [state, setState] = useState({
    checkboxes: {},
    inputs: {},
    errors: {}
  });

  const [selectOneError, setSelectOneError] = useState(false);
  const [checkedCheckBoxCount, setCheckedCheckBoxCount] = useState(1);

  const handleCheckboxChange = (id) => {
    setSelectOneError(false);
    setState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [id]: false,
      },
    }));
    setState((prevState) => ({
      ...prevState,
      checkboxes: {
        ...prevState.checkboxes,
        [id]: !prevState.checkboxes[id],
      },
    }));
    setState((prevState) => ({
      ...prevState,
      inputs: {
        ...prevState.inputs,
        [id]: '',
      },
    }));
  };

  const handleInputChange = (id, value) => {
    setState((prevState) => ({
      ...prevState,
      inputs: {
        ...prevState.inputs,
        [id]: value,
      },
    }));
  };

  const checkboxes = [
    { label: "Mortgage / Rent", value: "229", placeholder: "My monthly Mortgage / Rent were:", errorMsg: "Please Enter Monthly Mortgage / Rent" },
    { label: "Utility Bills", value: "230", placeholder: "My monthly TOTAL utility bills were", errorMsg: "Please Enter Monthly TOTAL utility bills" },
    { label: "Loans", value: "231", placeholder: "My monthly loan repayments were:", errorMsg: "Please Enter Monthly loan repayments" },
    { label: "Credit Cards", value: "232", placeholder: "My monthly credit card repayments were:", errorMsg: "Please Enter Monthly credit card repayments" },
    { label: "Car Finance", value: "233", placeholder: "My monthly car finance payments were:", errorMsg: "Please Monthly car finance payments" },
    { label: "Store Cards", value: "234", placeholder: "My monthly store card payments were:", errorMsg: "Please Enter Monthly store card payments" },
    { label: "Other", value: "235", placeholder: "My other monthly payment commitments were:", errorMsg: "Please Enter Other monthly payment" }
  ];

  const handleNext = (e) => {
    var checkedCheckBoxCount = 0;
    var checklistOptions = [];
    var inputListValues = [];
    Object.entries(state.checkboxes).map(([checkboxId, checkboxValue]) => {
      if (checkboxValue == true) {
        checkedCheckBoxCount += 1;
        Object.entries(state.inputs).map(([inputId, inputValue]) => {
          if (checkboxId == inputId) {
            var errorStatus = (inputValue == '£0' || inputValue == '') ? true : false;
            setState((prevState) => ({
              ...prevState,
              errors: {
                ...prevState.errors,
                [inputId]: errorStatus,
              },
            }));
            if (!errorStatus) {
              checklistOptions.push({ id: parseInt(checkboxId) });
              inputListValues.push([inputId, inputValue]);
            }
          }
        })
      }
    });
    /* setChecklistOptions(checklistOptions);
    setInputListValues(inputListValues); */
    setCheckedCheckBoxCount(checkedCheckBoxCount);
    if (inputListValues.length > 0 && checkedCheckBoxCount == inputListValues.length) {
      if (checkedCheckBoxCount > 0) {
        setSelectOneError(false);
        saveIncomeAndExpense(checklistOptions, inputListValues);
      } else {
        setSelectOneError(true);
        return false;
      }
    }
  }

  useEffect(() => {
    if (checkedCheckBoxCount > 0) {
      setSelectOneError(false);
    } else {
      setSelectOneError(true);
    }
  }, [checkedCheckBoxCount])


  return (
    <>
      <div className="question-content mt-0  scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
        <div className="row">
          <div className="col-lg-12 mb-2">
            <ul className="p-0 m-0 option-grid-iande">
              {checkboxes.map(({ label, value, placeholder, errorMsg }) => (
                <>
                  <li className="adjustbalewidfull">
                    <CheckboxWithInput
                      key={value}
                      id={value}
                      label={label}
                      isChecked={state.checkboxes[value] || false}
                      inputValue={state.inputs[value] || ""}
                      error={state.errors[value] || false}
                      errorMsg={errorMsg}
                      placeholder={placeholder}
                      handleCheckboxChange={handleCheckboxChange}
                      handleInputChange={handleInputChange}
                    />
                  </li>
                </>
              ))}
            </ul>
          </div>
          <div className="col-lg-12 mb-2 mt-2">
            {selectOneError && (
              <span className="error_msg">
                Please select at least one
              </span>
            )}
            <button
              className="qb3 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
              name="wereAlreadyPaying"
              type="button"
              id="nextscroll"
              data-quest-id={79}
              onClick={handleNext}
            >
              Next &#8594;{" "}
            </button>
          </div>
        </div>

        <p className="text-center bck-marwid">
          <button
            name="dependents"
            className="btn btn-secondary backbtn1a"
            id=""
            value=""
            type="button"
            onClick={backClick}
          >
            Go Back
          </button>
        </p>
      </div>
    </>
  );
};

export default IncomeAndExpV3;

