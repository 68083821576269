import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const EmploymentStatusNEW_OPT = ({
    className,
    slideChange,
    textHeadingLine1,
    textHeadingLine2,
    validation,
    backClick,
    getValues
}) => {
    const [checkedOne, setCheckedOne] = useState("unchecked");
    const [checkedTwo, setCheckedTwo] = useState("unchecked");
    const [checkedThree, setCheckedThree] = useState("unchecked");
    const [checkedFour, setcheckedFour] = useState("unchecked");
    const [checkedFive, setcheckedFive] = useState("unchecked");
    const [checkedSix, setCheckedSix] = useState("unchecked");
    const [checkedSeven, setCheckedSeven] = useState("unchecked");
    const [checkedEight, setCheckedEight] = useState("unchecked");
    const [checkedNine, setCheckedNine] = useState("unchecked");
    const [checkedTen, setCheckedTen] = useState("unchecked");
    const [checkedEleven, setCheckedEleven] = useState("unchecked");

    const formData = getValues();

    let yearSelected = formData.YearOfPossession;

    const prevClick = (e) => {
        localStorage.removeItem("employment_status");
        backClick(e);
    
       }

    const nextSlide = async (e) => {

        console.log("employment_status: " + e.target.getAttribute('data-ans-label'));
        localStorage.setItem("employment_status", e.target.getAttribute('data-ans-label'));

        if (e.target.value === "3") {
            setCheckedOne("checked");
            slideChange(e);
        } else {
            setCheckedOne("unchecked");
        }

        if (e.target.value === "4") {
            setCheckedTwo("checked");
            slideChange(e);
        } else {
            setCheckedTwo("unchecked");
        }

        if (e.target.value === "5") {
            setCheckedThree("checked");
            slideChange(e);
        } else {
            setCheckedThree("unchecked");
        }

        if (e.target.value === "6") {
            setcheckedFour("checked");
            slideChange(e);
        } else {
            setcheckedFour("unchecked");
        }

        if (e.target.value === "199") {
            setcheckedFive("checked");
            slideChange(e);
        } else {
            setcheckedFive("unchecked");
        }

        if (e.target.value === "200") {
            setCheckedSix("checked");
            slideChange(e);
        } else {
            setCheckedSix("unchecked");
        }
        if (e.target.value === "201") {
            setCheckedSeven("checked");
            slideChange(e);
        } else {
            setCheckedSeven("unchecked");
        }
        if (e.target.value === "202") {
            setCheckedEight("checked");
            slideChange(e);
        } else {
            setCheckedEight("unchecked");
        }
        if (e.target.value === "203") {
            setCheckedNine("checked");
            slideChange(e);
        } else {
            setCheckedNine("unchecked");
        }
        if (e.target.value === "204") {
            setCheckedTen("checked");
            slideChange(e);
        } else {
            setCheckedTen("unchecked");
        }
        if (e.target.value === "205") {
            setCheckedEleven("checked");
            slideChange(e);
        } else {
            setCheckedEleven("unchecked");
        }

    };
    return (
        <>
            <div id="slide-4" className={`${className}`}>
                <ProgressBar value="20" />

                {/* <h3> {textHeadingLine1} </h3> */}
                <h3> {`In ${yearSelected}, at the time you secured finance, what was your employment status?`} </h3>
                <div className="radio_buttons">
                    <RadioButton
                        value="3"
                        name="employment"
                        className="radio regNextBtn"
                        labelName="Employed"
                        labelClassName={`radio ${checkedOne}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Employed"
                    />
                    <RadioButton
                        value="199"
                        name="employment"
                        className="radio regNextBtn"
                        labelName="Unemployed"
                        labelClassName={`radio ${checkedFive}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Unemployed"
                    />
                    <RadioButton
                        value="4"
                        name="employment"
                        className="radio regNextBtn"
                        labelName="Self-employed"
                        labelClassName={`radio ${checkedTwo}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Self-employed"
                    />

                    <RadioButton
                        value="5"
                        name="employment"
                        className="radio regNextBtn"
                        labelName="Student"
                        labelClassName={`radio ${checkedThree}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Student"
                    />

                    <RadioButton
                        value="6"
                        name="employment"
                        className="radio regNextBtn"
                        labelName="Retired"
                        labelClassName={`radio ${checkedFour}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Retired"
                    />

                    <RadioButton
                        value="200"
                        name="employment"
                        className="radio regNextBtn"
                        labelName="Benefits main source of income"
                        labelClassName={`radio ${checkedSix}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Benefits main source of income"
                    />

                    <RadioButton
                        value="201"
                        name="employment"
                        className="radio regNextBtn"
                        labelName="Part-time job"
                        labelClassName={`radio ${checkedSeven}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Part-time job"
                    />

                    <RadioButton
                        value="202"
                        name="employment"
                        className="radio regNextBtn"
                        labelName="Temporary worker"
                        labelClassName={`radio ${checkedEight}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Temporary worker"
                    />

                    <RadioButton
                        value="203"
                        name="employment"
                        className="radio regNextBtn"
                        labelName="Long Term Sick"
                        labelClassName={`radio ${checkedNine}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Long Term Sick"
                    />

                    <RadioButton
                        value="204"
                        name="employment"
                        className="radio regNextBtn"
                        labelName="Maternity"
                        labelClassName={`radio ${checkedTen}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Maternity"
                    />

                    <RadioButton
                        value="205"
                        name="employment"
                        className="radio regNextBtn"
                        labelName="Rolling/Zero hours contract"
                        labelClassName={`radio ${checkedEleven}`}
                        onClick={nextSlide}
                        validation={validation()}
                        dataAnsLabel="Rolling/Zero hours contract"
                    />


                </div>
                <p className="text-center">
                    <span
                        className="back-btn btn btn-link"
                        name="back03"
                        children="<< Previous"
                        onClick={prevClick}
                    ></span>
                </p>
            </div>
        </>
    );
};
export default EmploymentStatusNEW_OPT;
