import React, {
    Fragment,
    useState,
    useContext,
    useRef
} from "react";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Accordion from "react-bootstrap/Accordion";
import { useIdUpload } from "../../Hooks/useIdUpload";
import { queryString } from "../../Utility/QueryString";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

const userfile = {
    preview: undefined,
    title: undefined,
};

const Idupload_MB_UFGN_4 = ({ className, setIdUploadDiv }) => {
    const history = useHistory();
    const formParameters = JSON.parse(localStorage.getItem("formData"));
    const split_name = localStorage.getItem("split_name");
    // const { saveFLPUserIdUpload, isLoading } = useIdUpload();
    const { saveIdUpload, isLoading } = useIdUpload();

    const [clickedSlide, setClickedSlide] = useState([]);
    // const [state, dispatch] = useReducer(FormReducer, initialState);
    const [selectedDocType, setSelectedDocType] = useState();
    const [selectedFile, setSelectedFile] = useState();
    const [selectedFileType, setSelectedFileType] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);
    const [isInValidfFile, setIsInValidfFile] = useState(false);
    const [documentModalShow, setDocumentModalShow] = useState(false);
    const [nationalIDCard, setNationalIDCard] = useState(false);
    const [driversLicense, setDriversLicense] = useState(false);
    const [passport, setPassport] = useState(false);
    const [base64Image, setBase64Image] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const scrollRef = useRef(null);
    const { visitorParameters } = useContext(VisitorParamsContext);
    const {updateMilestone} = useUpdateMilestone();

    const followup_data = JSON.parse(localStorage.getItem("followup_data"));
    const user_token = localStorage.getItem("user_token");
    const [currentUuid, setCurrentUuid] = useState();

    const selectDocId = (e) => {
        retakeClick();
        // setSelectedDocType(e.target.value);
    };

    const changeCameraHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        let imageFile = event.target.files[0];
        if (imageFile) {
            setIsFilePicked(true);
            let extension = event.target.files[0].type;
            let imgsize = event.target.files[0].size;
            let allowedExtensions = ["image/jpeg", "image/png"];
            if (allowedExtensions.indexOf(extension) === -1) {
                setIsInValidfFile(true);
                setErrorMessage({
                    txt: "The uploaded ID type should be an image in jpeg,png or jpg",
                });
            } else if (imgsize > 1024 * 1024 * 20) {
                setIsInValidfFile(true);
                setErrorMessage({ txt: "Please choose a file less than 20mb" });
            } else {
                setSelectedFileType(event.target.name);
                setIsInValidfFile(false);
            }

            setNationalIDCard(false);
            setDriversLicense(false);
            setPassport(false);

            if ("nationalIDCard_doc_cam" == event.target.name) {
                setNationalIDCard(true);
            } else if ("driversLicense_doc_cam" == event.target.name) {
                setDriversLicense(true);
            } else if ("passport_doc_cam" == event.target.name) {
                setPassport(true);
            }

            userfile.preview = URL.createObjectURL(imageFile);
            userfile.title = imageFile.name;

            if (imageFile) {
                let reader = new FileReader();
                reader.onload = _handleReaderLoaded.bind(this);
                //reader.readAsBinaryString(imageFile);
                reader.readAsDataURL(imageFile);
            }
        } else {
            setIsInValidfFile(true);
            setIsFilePicked(false);
            userfile.preview = undefined;
            userfile.title = undefined;
        }
    };

    const changeFilesHandler = (event) => {
        setSelectedFile(event.target.files[0]);
        let imageFile = event.target.files[0];
        if (imageFile) {
            setIsFilePicked(true);
            let extension = event.target.files[0].type;
            let imgsize = event.target.files[0].size;
            //console.log(imgsize);
            //console.log(1024 * 1024 * 10);
            let allowedExtensions = ["image/jpeg", "image/png"];
            if (allowedExtensions.indexOf(extension) === -1) {
                setIsInValidfFile(true);
                setErrorMessage({
                    txt: "The uploaded ID type should be an image in jpeg,png or jpg",
                });
            } else if (imgsize > 1024 * 1024 * 20) {
                setIsInValidfFile(true);
                setErrorMessage({ txt: "Please choose a file less than 20mb" });
            } else {
                setSelectedFileType(event.target.name);
                setIsInValidfFile(false);
                //_handleFileRead.bind(event.target.files[0]);
            }

            setNationalIDCard(false);
            setDriversLicense(false);
            setPassport(false);

            if ("nationalIDCard_doc" == event.target.name) {
                setNationalIDCard(true);
            } else if ("driversLicense_doc" == event.target.name) {
                setDriversLicense(true);
            } else if ("passport_doc" == event.target.name) {
                setPassport(true);
            }
            userfile.preview = URL.createObjectURL(imageFile);
            userfile.title = imageFile.name;

            if (imageFile) {
                let reader = new FileReader();
                reader.onload = _handleReaderLoaded.bind(this);
                //reader.readAsBinaryString(imageFile);
                reader.readAsDataURL(imageFile);
            }
        } else {
            setIsInValidfFile(true);
            setIsFilePicked(false);
            userfile.preview = undefined;
            userfile.title = undefined;
        }
    };

    const _handleReaderLoaded = (readerEvt) => {
        let binaryString = readerEvt.target.result;
        //binaryString = binaryString.replace(";base64", `;name=${selectedFile.name};base64`);
        // setBase64Image({
        //   //base64TextString : btoa(binaryString)
        //   base64TextString : btoa(encodeURIComponent(binaryString).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        //     return String.fromCharCode('0x' + p1);
        //   }))
        // });

        // setBase64Image({
        //   base64TextString: binaryString,
        // });
        setBase64Image(binaryString);

    };

    const retakeClick = () => {
        setDocumentModalShow(false);

        setNationalIDCard(false);
        setDriversLicense(false);
        setPassport(false);

        setClickedSlide({ slide: "retake" });
        setSelectedFile(null);
        setIsFilePicked(false);
    };

    // const savedocument = async () => {
    //   console.log(followup_data);
    //   try {
    //     //console.log(base64Image.base64TextString);
    //     if (
    //       followup_data !== null ||
    //       followup_data !== undefined ||
    //       followup_data.atp_sub6 !== null
    //     ) {
    //       console.log(currentUuid);
    //       console.log(user_token);
    //       GtmDataLayer({
    //         'question': 'Verify Your identity'
    //       });
    //       let uploadData = '';
    //       uploadData = {
    //         imageFile: base64Image.base64TextString,
    //         captureType: selectedFileType.substring(
    //           selectedFileType.indexOf("doc")
    //         ),
    //         documentType: selectedFileType.substr(
    //           0,
    //           selectedFileType.indexOf("_")
    //         ),
    //       };
    //       const response = saveFLPUserIdUpload(
    //         uploadData,
    //         followup_data,
    //         queryString
    //       );

    //       window.scrollTo(0, 0);
    //       history.push("/thankyou?uuid=" + currentUuid);
    //     }
    //   } catch (e) {
    //     console.warn("error");
    //     console.warn(e);
    //   }
    // };


    const savedocument = async () => {
        if (selectedFile) {
            setIsInValidfFile(false);
            try {
                if (visitorParameters.data !== null || visitorParameters.data !== undefined) {
                    let uploadData = '';
                    uploadData = {
                        imageFile: base64Image,
                        captureType: selectedFileType.substring(
                            selectedFileType.indexOf("doc")
                        ),
                        documentType: selectedFileType.substr(
                            0,
                            selectedFileType.indexOf("_")
                        ),
                    };

                    const response = saveIdUpload(
                        uploadData,
                        visitorParameters,
                        formParameters,
                        queryString,

                    );
                    // pushPageChange();
                    updateMilestone(visitorParameters.visitor_parameters.uuid,"id_upload","live");
                    localStorage.setItem("Kyc", 1);
                    GtmDataLayer({
                        question: "verify your identity",
                    });
                    history.push("/CheckListGN_4?uuid=" + visitorParameters.visitor_parameters.uuid);
                }
            } catch (e) {
                console.warn("error");
                console.warn(e);
                setIsInValidfFile(true);
                setErrorMessage("Please try again");
            }
        } else {
            setIsInValidfFile(true);
            setErrorMessage("Please choose an option");
        }
    };


    return (
        <>
            <Fragment>
                {/* <GetVisitorsParams /> */}
                <div className={`tab-pane fade ${className}`} id="idupld">
                    <h3 className="title text-start mt-4 mb-3 idupld">Verify Your Identity</h3>
                    <p className="mb-0  text-start fs-6">
                        <i>Tip - Please provide a photo of one of the following:&nbsp;</i>
                    </p>
                    <p className="text-danger  text-start fs-6">
                        <i>National ID Card, Driver's License, Passport</i>
                    </p>
                    <div className="id-uploads">
                        <Accordion
                            className="accordion accordion-flush mb-3"
                            id="faqs"
                        >
                            <Accordion.Item
                                eventKey="1"
                                className="accordion-item"
                                onClick={selectDocId}
                            >
                                <Accordion.Button className="accordion-button  idone nationalID mb-2">
                                    National ID Card
                                </Accordion.Button>
                                <Accordion.Body>
                                    <div className="accordion-body pt-2 pb-2  p-3 mb-2 bg-body rounded panel p-2">
                                        {/* Upload Area */}
                                        <div className="row align-items-center cent-item">
                                            <div className="col-lg-6 col-md-6 col-sm-6 text-center">
                                                <div className="body-id">

                                                    {nationalIDCard && !isInValidfFile ? (
                                                        <img src={userfile.preview} alt={userfile.title} className="img-fluid id-image1" />
                                                    ) : (
                                                        <img src="assets/CheckList/img/idUpload.jpg" className="img-fluid id-image1" alt="" />
                                                    )}
                                                </div>
                                            </div>
                                            {!nationalIDCard ? (
                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                    <div className="btn-uploads mt-2 ">
                                                        <div className=" btn btn-outline-primary p-3 w-100 brows mb-2 fw-bold browsID">
                                                            <span className="text-span">
                                                                <i className="bi bi-search me-1" /> Browse from
                                                                Device
                                                            </span>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                id="idTypeFile"
                                                                name="nationalIDCard_doc"
                                                                onChange={changeFilesHandler}
                                                                onClick={(e) => (e.target.value = null)}
                                                            /></div>
                                                        <div className="btn btn-outline-success p-3 w-100 fw-bold brows">
                                                            <span className="text-span">
                                                                <i className="bi bi-camera me-1" /> Take picture of
                                                                your ID
                                                            </span>
                                                            <input
                                                                // className="btn btn-outline-success p-3 w-100 fw-bold"
                                                                type="file"
                                                                accept="image/*"
                                                                capture="camera"
                                                                id="idTypeFileCamera"
                                                                name="nationalIDCard_doc_cam"
                                                                onChange={changeCameraHandler}
                                                                onClick={(e) => (e.target.value = null)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                 {isInValidfFile && <p className="error-msg">{errorMessage.txt}</p>}
                                                 {isInValidfFile && (
                                                <div className="col-lg-6 col-md-6 col-sm-6 cont-center">
                                                    <div className="btn-uploads mt-2 ">
                                                        <div className="btn p-3 w-100 brows mb-2 fw-bold browsID btn-danger">
                                                            <span className="text-span">
                                                                <i className="bi bi-arrow-clockwise" /> Retake Image
                                                            </span>
                                                            <input
                                                                type="button"
                                                                // className="btn btn-outline-success p-3 w-100 fw-bold brows"
                                                                // name="nationalIDCard_doc_cam"
                                                                // defaultValue="Retake Photo"
                                                                onClick={retakeClick}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                 )}
                                                {!isInValidfFile && (
                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                    <div className="btn-uploads mt-2 ">
                                                        <div className="btn p-3 w-100 brows mb-2 fw-bold browsID btn-danger">
                                                            <span className="text-span">
                                                                <i className="bi bi-arrow-clockwise" /> Retake Image
                                                            </span>
                                                            <input
                                                                type="button"
                                                                // className="btn btn-outline-success p-3 w-100 fw-bold brows"
                                                                // name="nationalIDCard_doc_cam"
                                                                // defaultValue="Retake Photo"
                                                                onClick={retakeClick}
                                                            />
                                                        </div>
                                                        <div className="btn btn-success p-3 w-100 fw-bold brows">
                                                            <span className="text-span">
                                                                Proceed
                                                            </span>
                                                            <input
                                                                type="button"
                                                                // className="btn btn-outline-primary p-3 w-100 brows mb-2 fw-bold browsID"
                                                                onClick={savedocument}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                 )}
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item
                                eventKey="2"
                                className="accordion-item"
                                onClick={selectDocId}
                            >
                                <Accordion.Button className="accordion-button  idtwo btn-licence mb-2">
                                    Driver's License                                            </Accordion.Button>
                                <Accordion.Body>
                                    <div className="accordion-body pt-2 pb-2  p-3 mb-2 bg-body rounded panel p-2">
                                        {/* Upload Area */}
                                        <div className="row align-items-center cent-item">
                                            <div className="col-lg-6 col-md-6 col-sm-6 text-center">
                                                <div className="body-id">

                                                    {driversLicense && !isInValidfFile ? (
                                                        <img src={userfile.preview} alt={userfile.title} className="img-fluid id-image1" />
                                                    ) : (
                                                        <img src="assets/CheckList/img/idUpload.jpg" className="img-fluid id-image1" alt="" />
                                                    )}
                                                </div>
                                            </div>
                                            {!driversLicense ?(
                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                    <div className="btn-uploads mt-2 ">
                                                        <div className=" btn btn-outline-primary p-3 w-100 brows mb-2 fw-bold browsID">
                                                            <span className="text-span">
                                                                <i className="bi bi-search me-1" /> Browse from
                                                                Device
                                                            </span>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                id="idTypeFile"
                                                                name="driversLicense_doc"
                                                                onChange={changeFilesHandler}
                                                                onClick={(e) => (e.target.value = null)}
                                                            />                                                                </div>
                                                        <div className="btn btn-outline-success p-3 w-100 fw-bold brows">
                                                            <span className="text-span">
                                                                <i className="bi bi-camera me-1" /> Take picture of
                                                                your ID
                                                            </span>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                capture="camera"
                                                                id="idTypeFileCamera"
                                                                name="driversLicense_doc_cam"
                                                                onChange={changeCameraHandler}
                                                                onClick={(e) => (e.target.value = null)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                {isInValidfFile && <p className="error-msg">{errorMessage.txt}</p>}
                                                {isInValidfFile && (
                                                <div className="col-lg-6 col-md-6 col-sm-6 cont-center">
                                                    <div className="btn-uploads mt-2 ">
                                                        <div className="btn p-3 w-100 brows mb-2 fw-bold browsID btn-danger">
                                                            <span className="text-span">
                                                                <i className="bi bi-arrow-clockwise" /> Retake Image
                                                            </span>
                                                            <input
                                                                type="button"
                                                                // className="btn btn-outline-success p-3 w-100 fw-bold brows"
                                                                // name="nationalIDCard_doc_cam"
                                                                // defaultValue="Retake Photo"
                                                                onClick={retakeClick}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                 )}
                                                {!isInValidfFile && (

                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                    <div className="btn-uploads mt-2 ">
                                                        <div className="btn p-3 w-100 brows mb-2 fw-bold browsID btn-danger">
                                                            <span className="text-span">
                                                                <i className="bi bi-arrow-clockwise" /> Retake Image
                                                            </span>
                                                            <input
                                                                type="button"
                                                                className="btn btn-outline-success p-3 w-100 fw-bold brows"
                                                                defaultValue="Retake Photo"
                                                                onClick={retakeClick}
                                                            />
                                                        </div>
                                                        <div className="btn btn-success p-3 w-100 fw-bold brows">
                                                            <span className="text-span">
                                                                Proceed
                                                            </span>
                                                            <input
                                                                type="button"
                                                                // className="btn btn-outline-primary p-3 w-100 brows mb-2 fw-bold browsID"
                                                                onClick={savedocument}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                )}
                                                </>
                                            )}

                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item
                                eventKey="3"
                                className="accordion-item"
                                onClick={selectDocId}
                            >
                                <Accordion.Button className="accordion-button  idthree btnpassport">
                                    Passport
                                </Accordion.Button>
                                <Accordion.Body>
                                    <div className="accordion-body pt-2 pb-2  p-3 mb-2 bg-body rounded panel p-2">
                                        {/* Upload Area */}
                                        <div className="row align-items-center cent-item">
                                            <div className="col-lg-6 col-md-6 col-sm-6 text-center">
                                                <div className="body-id">

                                                    {passport && !isInValidfFile ? (
                                                        <img src={userfile.preview} alt={userfile.title} className="img-fluid id-image1" />
                                                    ) : (
                                                        <img src="assets/CheckList/img/idUpload.jpg" className="img-fluid id-image1" alt="" />
                                                    )}
                                                </div>
                                            </div>
                                            {!passport ? (
                                                <div className="col-lg-6 col-md-6 col-sm-6">
                                                    <div className="btn-uploads mt-2 ">
                                                        <div className=" btn btn-outline-primary p-3 w-100 brows mb-2 fw-bold browsID">
                                                            <span className="text-span">
                                                                <i className="bi bi-search me-1" /> Browse from
                                                                Device
                                                            </span>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                id="idTypeFile"
                                                                name="passport_doc"
                                                                onChange={changeFilesHandler}
                                                                onClick={(e) => (e.target.value = null)}
                                                            />                                                                </div>
                                                        <div className="btn btn-outline-success p-3 w-100 fw-bold brows">
                                                            <span className="text-span">
                                                                <i className="bi bi-camera me-1" /> Take picture of
                                                                your ID
                                                            </span>
                                                            <input
                                                                type="file"
                                                                accept="image/*"
                                                                capture="camera"
                                                                id="idTypeFileCamera"
                                                                name="passport_doc_cam"
                                                                onChange={changeCameraHandler}
                                                                onClick={(e) => (e.target.value = null)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                           ) : (
                                            <>
                                            {isInValidfFile && <p className="error-msg">{errorMessage.txt}</p>}
                                            {isInValidfFile && (
                                                <div className="col-lg-6 col-md-6 col-sm-6 cont-center">
                                                    <div className="btn-uploads mt-2 ">
                                                        <div className="btn p-3 w-100 brows mb-2 fw-bold browsID btn-danger">
                                                            <span className="text-span">
                                                                <i className="bi bi-arrow-clockwise" /> Retake Image
                                                            </span>
                                                            <input
                                                                type="button"
                                                                // className="btn btn-outline-success p-3 w-100 fw-bold brows"
                                                                // name="nationalIDCard_doc_cam"
                                                                // defaultValue="Retake Photo"
                                                                onClick={retakeClick}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                 )}
                                            {!isInValidfFile && (

                                            <div className="col-lg-6 col-md-6 col-sm-6">
                                                <div className="btn-uploads mt-2 ">
                                                    <div className="btn p-3 w-100 brows mb-2 fw-bold browsID btn-danger">
                                                        <span className="text-span">
                                                            <i className="bi bi-arrow-clockwise" /> Retake Image
                                                        </span>
                                                        <input
                                                            type="button"
                                                            className="btn btn-outline-success p-3 w-100 fw-bold brows"
                                                            defaultValue="Retake Photo"
                                                            onClick={retakeClick}
                                                        />
                                                    </div>
                                                    <div className="btn btn-success p-3 w-100 fw-bold brows">
                                                        <span className="text-span">
                                                            Proceed
                                                        </span>
                                                        <input
                                                            type="button"
                                                            // className="btn btn-outline-primary p-3 w-100 brows mb-2 fw-bold browsID"
                                                            onClick={savedocument}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            )}
                                            </>
                                        )}


                                        </div>

                                     
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <p className="mt-2 text-start">
                            Document should be Clear &amp; Legible | Entire Document should be
                            Captured
                        </p>

                    </div>
                </div>
            </Fragment>
        </>
    )
}

export default Idupload_MB_UFGN_4;