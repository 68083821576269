import React, { useState } from 'react';
import TextField from '../../../../UI/TextField';

const QuestionnaireFlpQ3a = ({
	questArr,
	reff,
	display,
	validation,
	validationMsg,
	getValues,
	trigger,
	setError,
	saveInputAns
}) => {
	const questionId = questArr.questionId ? questArr.questionId : 0;

	const selectClick = async (e) => {
		e.preventDefault();
		let errorFlag = 0;
		const values = getValues();
		let question44Result = await trigger("question_44");
		if (!question44Result) {
			errorFlag = 1;
			return false;
		}
		if (errorFlag === 0) {
			saveInputAns(e);
		}
	};

	const handleClick = async (e) => {
		let registration_no = await trigger("question_10");
		if (registration_no) {
			const fieldResult = getValues();
			let carReg = fieldResult["question_10"];
			let carRegNo = carReg.replace(/\s+/g, "");

		} else {
			return false;
		}
	};

	const handleChange = (e) => {
		// const re = /^[0-9\b]+$/;
		const re = /^[1-9]\d*$/;
		if (e.target.value !== "" && !re.test(e.target.value)) {
			e.target.value = e.target.value.replace(/\D/g, "") * 1;
		}
		if (e.target.value !== "") {
			e.target.value =
				"£" + e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		}
	};

	return (
		<>
			<div style={{ 'display': display }}>
				<div className="question animated fadeInUp" ref={reff} >
					<h3 style={{ color: "#444", textAlign: "center" }} className="mb-2">Please enter your Privately Registered Plate</h3>
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 input-group mb-3">
						<TextField
							type="text"
							className="form-control"
							placeholder="Please enter your Privately Registered Plate"
							name="question_44"
							maxlength="12"
							// onChange={handleChange}
							validation={validation({
								required: "Please enter your Privately Registered Plate",
								// pattern: {
								//   value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/,
								//   message: "Your Privately Registered Plate is Invalid. Please Recheck",
								// },
							})}
							validationMsg={
								validationMsg.question_44 && validationMsg.question_44.message
							}
						></TextField>
					</div>

					<div className="col-xl-12 col-lg-12 text-center p-0" >
						<button
							type="button"
							className="btn-next"
							onClick={selectClick}
							data-quest-id={44}
							data-ans-id={null}
							data-ans-label={null}
							name="btn_qest_44"
						>{`Next`}</button>
					</div>
				</div>
			</div>

		</>
	)
}

export default QuestionnaireFlpQ3a;