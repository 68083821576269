import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import DifficultyModal from "./DifficultyModal";


const Difficulties = ({ onSelectAnswer }) => {

  const [claimModal, setshowClaimModal] = useState(false);
  
  const handleCloseclaimModal = () => setshowClaimModal(false);
  
  const optionSelected = async (e) => {
    if (e.target.value === "241") {
      setshowClaimModal(true);
    } 
    if (e.target.value === "240") {
      onSelectAnswer(e);
    } 
  };
  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center pt-0 px-3 py-lg-3 py-md-3 py-sm-2 pt-lg-0 pt-md-0 pt-1 py-2 pb-3">
        <ul className="listStyle mb-3 ms-2">
          <li>
            The lender will claim that you should have known that <b> they </b>
            were at fault when you received these communications, and too much
            time has now passed.
          </li>
          <li>
            If you consider that any financial difficulties you had were 
            <b> your fault / responsibility</b>, not theirs we can argue your
            case with them and still do your claim.
          </li>
        </ul>
        </div>

        <div className="radio-box question animated fadeInUp">
          <RadioButtonQuest
            value="240"
            className="radio-button"
            name="difficulties"
            onClick={optionSelected}
            dataQuestId={83}
            dataAnsId={240}
            id="noid2"
            labelName="I thought that it was my fault / responsibility"
            labelClassName="option_but next-2"
          />
          <RadioButtonQuest
            value="241"
            className="radio-button"
            name="difficulties"
            onClick={optionSelected}
            dataQuestId={83}
            dataAnsId={241}
            id="noid3"
            labelName={
              "I thought that it was the NewDay's fault / responsibility"
            }
            labelClassName="option_but next-2"
          />
        </div>
      <DifficultyModal
        claimModal={claimModal}
        handleCloseclaimModal={handleCloseclaimModal}
      />
    </>
  );
};
export default Difficulties;
