import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import SelectBox from "../../../UI/SelectBox";
import InputButton from "../../../UI/InputButton";
const Borrow = ({
  className,
  textHeadingLine1,
  questionnaire,
  questionId,
  slideChange,
  validationMsg,
  clearErrors,
  validation,
  backClick,
  trigger
}) => {
  let answers = questionnaire.answers;
  const nextSlide = async (e) => {
    let errorFlag = 0;
    let questionResult = await trigger("question_39");
    if (!questionResult) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      slideChange(e);
    } else {
      return false;
    }
  };
  return (
    <>
      <div id="slide-2" className={`${className}`}>
        <ProgressBar value="10" />

        <h3>{textHeadingLine1}</h3>
        <div className="mb-3 mt-3 col-12 p-0">
          <SelectBox
            className="form-select form-control"
            OptionValue={answers}
            name="question_39"
            onChange={() => clearErrors("question_39")}
            clearErrors={clearErrors}
            myRef={validation({ required: "Please Select the Amount" })}
            validationMsg={
              validationMsg.question_39 && validationMsg.question_39.message
            }
          ></SelectBox>
        </div>
        <div className="col-lg-12 mb-pd text-center ">
          <InputButton
            name="question39"
            className="btn next2 regNextBtn"
            id=""
            value="NEXT"
            btnType="button"
            onClick={nextSlide}
            style={{ float: "none" }}
          />
        </div>
        <p className="text-center">
          <span
            className="back1 back-btn btn-link"
            name="back02"
            children="<< Previous"
            onClick={backClick}
          >
          </span>
        </p>
      </div>
    </>
  );
};
export default Borrow;