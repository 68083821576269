import React, { useEffect, useState } from 'react';
import SelectBox from '../../../../UI/SelectBox';
import AnalyzeModal from '../AnalayzeModal';
import { accCloseYear } from "../../../../../Constants/Constants";
import { YearPossession, VeryLenderArray } from "../../../../../Constants/Constants";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";


const IntialYear = ({
	clsName,
	reff,
	// checkCarRegistration,
	validation,
	validationMsg,
	trigger,
	getValues,
	setError,
	page_name,
	uuid,
	split_name,
	saveInputAns,
	newDayData,
	onselectAnswer,
	SetIntialLoanYear,
	setAgreeModalShow
}) => {


	// const [agreeModalShow, setAgreeModalShow] = useState("hide");






	const nextSlide = async (e) => {
		let errorFlag = 0;
		let yearresult = await trigger("question_28");

		if (!yearresult) {
			errorFlag = 1;
			return false;
		}
		const formData = getValues();
		let yearSelected = formData.question_28;
		SetIntialLoanYear(yearSelected);
		if (yearSelected < 2016) {
			saveInputAns(e);
			setAgreeModalShow('show');
		} else {
			saveInputAns(e);
		}



	};


	const AgreeSlide = async (e) => {
		console.log(e.target.value);
		onselectAnswer(e);
		setAgreeModalShow('hide');
	};

	return (
		<>
			<div className={`radio-box animated fadeInUp`} ref={reff} >
				<h3><b>What year did you initially receive your credit card?</b></h3>
				<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
					<SelectBox
						className="form-select form-control"
						OptionValue={YearPossession}
						name="question_28"
						id="question_28"
						myRef={validation({
							required: "Please Select Year"
						})}
						validationMsg={validationMsg.question_28 && validationMsg.question_28.message}
					/>
				</div>
				<div className="col-xl-12 col-lg-12 text-center p-0" >
					<button
						type="button"
						className="btn-next"
						onClick={nextSlide}
						data-quest-id={28}
						data-ans-id={null}
						data-ans-label={null}
						name="btn_qest_28"
					>{`NEXT`}</button>
				</div>
			</div>
			{/* <AnalyzeModal modalClass={loader} /> */}

			
			{/* <div role="dialog" className={`fade modal blackover ${agreeModalShow}`}>
				<div className="modal-dialog modal-md modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-body">
							<h3><b>Please confirm if you agree with the statement</b></h3>
							<h3> I only recently understood I had cause to complain when TMS Legal represented my interests regarding irresponsible lending. I considered my financial issues to be my problem and didn't link it to any irresponsible lending by the lender.</h3>
							<div className="input-group mb-3 prevname-Btnpop justify-content-center">



								<RadioButtonQuest
									key="YES"
									className="radio-button"
									labelName="AGREE"
									labelClassName="option_but next-2"
									// labelCountString="a"
									onClick={AgreeSlide}
									value="131"
									name="agreeYear"
									id="onethre"
									dataQuestId={49}
									dataAnsId={131}
									dataAnsLabel="AGREE"
								/>


								<RadioButtonQuest
									key="YES"
									className="radio-button"
									labelName="DISAGREE"
									labelClassName="option_but next-2"
									// labelCountString="a"
									onClick={AgreeSlide}
									value="132"
									name="agreeYear"
									id="onethre"
									dataQuestId={49}
									dataAnsId={132}
									dataAnsLabel="DISAGREE"
								/>


							</div>
						</div>
					</div>
				</div>
			</div> */}

		
		</>
	)
}

export default IntialYear;
