import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import "../../assets/Thankyou/css/Thankyou.scss";
import Footer from '../Includes/Layouts/Common/Footer';
const ThankyouBL = () => {
  localStorage.clear();

  return (
    <>
      <div className="thankyou">
        <header>
          <div className="container">
            <div className="col-lg-12 col-12 text-center">
              <img src="/assets/img/logo.png" alt="" />
            </div>
          </div>
        </header>
          <div className="container ">
            <div className="row ">
              <div className="col-lg-10 offset-lg-1 col-sm-02 col-12 mp0  text-center animated bounceInDown">
                <img src="thumb.png" alt="" />
                <h1 className="animated zoomInUp">Thank you</h1>
                <p className="thankuparag">You have successfully submitted your claim</p>
              </div>
            </div>
          </div>
        <div className='footerclass'>
          <Footer />
        </div>
      </div>
    </>
  )
}

export default ThankyouBL;
