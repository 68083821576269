import {  WebBookErrorPage } from "../api/Api";
import { currentdate, currenttime } from "../Utility/CurrentDateTime";
import {
  AppEnv
} from "../Constants/EnvConstants";

export const useErrorNotFoundHook = () => {
    
    const ErrorWebhookPost = (content) => {
      const fullURL = window.location.href;
      const { serviceName, urlParams, localStroageData, referalUrl } = content;
      const message = `
    {
        Service Name : ${serviceName}
        URLParameters: ${JSON.stringify(urlParams)}
        Local Storage Data : ${JSON.stringify(localStroageData)}
        Referal URL: ${referalUrl}
        Full URL:${fullURL}
        Date time : ${currentdate} ${currenttime}
        Environment : ${AppEnv}
    }
    `;
      // console.log("error content",content,"message:",message,'url:',window.location.href,'location:',window.location );
      // if (AppGmailWebhookError == "true") {
        const response = WebBookErrorPage.post("", {
          text: message,
        });
      // console.log("erterd");
      // }
    };
  return { ErrorWebhookPost };
};
