import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButtonOutLabel";
import TextField from "../../../UI/TextField";
import Button from "../../../UI/Button";

const QuestionnairEmployment = ({
  questArr,
  onselectAnswer,
  reff,
  display,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  saveSlide4,
  className,
  addPending
}) => {
  const questionId = questArr.questionId ? questArr.questionId : 0;

  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">
              At the time you secured finance, what was your employment status?
            </h1>

            <div className="row mt-4">
              <div className="col-lg-12">
                <div className=" col-lg-12 mb-pd text-center ">
                  <div className="btn-comm com-btn">
                    <RadioButton 
                      key="Employed"
                      id="Employed"
                      value="Employed"
                      name="employment"
                      className="radio-button"
                      labelName="Employed"
                      labelClassName="option_but  employStatus"
                      onClick={labelclicked}
                      dataAnsId={3}
                      dataQuestId={2}
                    />

                    <RadioButton 
                      key="Unemployed"
                      id="Unemployed"
                      value="Unemployed"
                      name="employment"
                      className="radio-button"
                      labelName="Unemployed"
                      labelClassName="option_but  employStatus"
                      onClick={labelclicked}
                      dataAnsId={199}
                      dataQuestId={2}
                    />
                  </div>

                  <div className="btn-comm com-btn">
                    <RadioButton
                      key="Self-employed"
                      id="Self-employed"
                      value="Self-employed"
                      name="employment"
                      className="radio-button"
                      labelName="Self-employed"
                      labelClassName="option_but  employStatus"
                      onClick={labelclicked}
                      dataAnsId={4}
                      dataQuestId={2}
                    />
                  </div>
                  <div className="btn-comm com-btn">
                    <RadioButton
                      key="Student"
                      id="Student"
                      value="Student"
                      name="employment"
                      className="radio-button"
                      labelName="Student"
                      labelClassName="option_but  employStatus"
                      onClick={labelclicked}
                      dataAnsId={5}
                      dataQuestId={2}
                    />
                  </div>
                  <div className="btn-comm com-btn">
                    <RadioButton
                      key="Retired"
                      id="Retired"
                      value="Retired"
                      name="employment"
                      className="radio-button"
                      labelName="Retired"
                      labelClassName="option_but  employStatus"
                      onClick={labelclicked}
                      dataAnsId={6}
                      dataQuestId={2}
                    />

                    <RadioButton 
                      key="Benefits"
                      id="Benefits"
                      value="Benefits main source of income"
                      name="employment"
                      className="radio-button"
                      labelName="Benefits main source of income"
                      labelClassName="option_but  employStatus"
                      onClick={labelclicked}
                      dataAnsId={200}
                      dataQuestId={2}
                    />

                    <RadioButton 
                      key="Part_time"
                      id="Part_time"
                      value="Part-time job"
                      name="employment"
                      className="radio-button"
                      labelName="Part-time job"
                      labelClassName="option_but  employStatus"
                      onClick={labelclicked}
                      dataAnsId={201}
                      dataQuestId={2}
                    />

                    <RadioButton 
                      key="Temporary"
                      id="Temporary"
                      value="Temporary worker"
                      name="employment"
                      className="radio-button"
                      labelName="Temporary worker"
                      labelClassName="option_but  employStatus"
                      onClick={labelclicked}
                      dataAnsId={202}
                      dataQuestId={2}
                    />

                    <RadioButton 
                      key="Long_Term"
                      id="Long_Term"
                      value="Long Term Sick"
                      name="employment"
                      className="radio-button"
                      labelName="Long Term Sick"
                      labelClassName="option_but  employStatus"
                      onClick={labelclicked}
                      dataAnsId={203}
                      dataQuestId={2}
                    />

                    <RadioButton 
                      key="Maternity"
                      id="Maternity"
                      value="Maternity"
                      name="employment"
                      className="radio-button"
                      labelName="Maternity"
                      labelClassName="option_but  employStatus"
                      onClick={labelclicked}
                      dataAnsId={204}
                      dataQuestId={2}
                    />

                    <RadioButton 
                      key="Rolling"
                      id="Rolling"
                      value="Rolling/Zero hours contract"
                      name="employment"
                      className="radio-button"
                      labelName="Rolling/Zero hours contract"
                      labelClassName="option_but  employStatus"
                      onClick={labelclicked}
                      dataAnsId={205}
                      dataQuestId={2}
                    />


                  </div>
                  <div className="btn-comm com-btn  ">
                    <RadioButton
                      key="Not sure"
                      id="NotSureEmp"
                      value="Not sure"
                      name="employment"
                      className="radio-button"
                      labelName="Not sure"
                      labelClassName="option_but notsure-btn plateYes"
                      onClick={labelclicked}
                      dataAnsId={195}
                      dataQuestId={2}
                    />
                  </div>
                </div>
                <div class="text-center back">
                    <Button 
                    className="btn btn-link mt-2 h2" 
                    id="back1" 
                    type="button"
                    buttonText="<< Previous"
                    onClick={()=>addPending("employment_status")}
                    />
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnairEmployment;
