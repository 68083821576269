import React from "react";
const Header = () => {
  return (
    <>
      <div>
        <section className="sun-strip">
          <div className="container">
            <div className="d-flex justify-content-center align-items-center">
              <div> <img height="35" src="assets/img/sun.png" alt="" /> </div>
              <div> <p className="text-start pb-0 mb-0 ">
                Moneybarn repays £30 million to 6,000 customers
              </p></div>
            </div>
          </div>
        </section>
      </div>

    </>
  );
};

export default Header;
