import { Api } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";

export const useLogRecord = () => {
  const updateLogRecord = async (
    uuid,
    api_name,
    timeTakenApi,
    startDateTime,
    endDateTime
  ) => {
    const response = await Api.post("api/v1/api-time-taken-log", {
        uuid,
        api_name,
        timeTakenApi,
        startDateTime,
        endDateTime
    });
    return response;
  };
  return { updateLogRecord };
};
