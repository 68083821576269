import React, { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/OpenBanking/css/thankyou.scss";
import { useOpenBanking } from "../../Hooks/useOpenBanking";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import InputButton from "../../../src/Components/UI/InputButton";
import { AppDomain } from "../../Constants/EnvConstants";

const OB_Thankyou02 = () => {
  const history = useHistory();
  // localStorage.clear();
  const [status, setStatus] = useState("");
  const [loader, setLoader] = useState(1);
  const [callBackStatus, setCallbackStatus] = useState(false);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const {
    getVisitorID,
    callBackGateWay,
    storeApiResponse,
    fetchAccountDetails,
    fetchTransactions,
    getAuthGatewayUrl,
    customerConsentsApi,
    storeClickResponse,
    getUserDataForOB
  } = useOpenBanking();
  const [btndisable, setBtnDisable] = useState(false);
  const query = new URLSearchParams(window.location.search);
  const task_id = query.get("task_id");
  const query_uuid = query.get("uuid");
  const local_storage_uuid = localStorage.getItem("uuid");
  var uuid = query_uuid ? query_uuid : local_storage_uuid;
  const query_source = query.get("atp_sub6");
  var source = query_source ? query_source : "live";
  const query_status = query.get("status");
  var customer_id = localStorage.getItem("customer_id");
  if (localStorage.getItem("is_test")) {
    var is_test = JSON.parse(localStorage.getItem("is_test"));
  }
  var user_data_obj = localStorage.getItem("user_data");
  let user_data = JSON.parse(user_data_obj);
  var visitorId = localStorage.getItem("visitorId");

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  let year_minus = year - 6;

  month = month < 10 ? `0${month}` : `${month}`;

  let today = year + "-" + month + "-" + date;
  let from = year_minus + "-" + month + "-" + date;

  let from_date = from;
  let to_date = today;
  let payload = {};
  let btn_label = '';
  let page = '';

  useEffect(() => {
    (async () => {

      if (uuid) {
        let payload = { "page": 'OB_TY_V2',type:'visit', source };
        await storeClickResponse({uuid:uuid},"ob_creditcheck_history",uuid,payload);
      }



      let status = query_status && query_status == "success" ? 1 : 2;
      payload = {
        milestone_key: "bank_connection",
        source: source,
        value: status,
      };
      if (query_status && query_status == "success") {
        setStatus("Fetching Account Details......");
        await storeApiResponse(
          visitorParameters.visitor_parameters,
          "op_milestone_update",
          uuid,
          payload
        );
      } else if (query_status && query_status == "failure") {
        setLoader(0);
        await storeApiResponse(
          visitorParameters.visitor_parameters,
          "op_milestone_update",
          uuid,
          payload
        );
      }
     
      if ( (customer_id === undefined || customer_id === null || customer_id == '') || (is_test === undefined || is_test === null || is_test == '')) {
       
        const dataresponse = await getUserDataForOB(uuid);
           
        if (dataresponse.data.status == "Success") { 

          is_test =  dataresponse.data.response.is_test;
          customer_id  = dataresponse.data.response.customer_id;

        }

      } 


      const response = await callBackGateWay(
        uuid,
        source,
        query_status,
        customer_id,
        task_id,
        is_test,
        visitorId
      );
      if (response.data.status == "success") {
        // setCallbackStatus(true);
        setStatus("Fetching Transactions......");
        payload = { milestone_key: "callback_url", source: source };
        const callbackResponse = await storeApiResponse(
          visitorParameters.visitor_parameters,
          "op_milestone_update",
          uuid,
          payload
        );

        const custConsentRes = await customerConsentsApi(uuid, is_test);

        if (custConsentRes.data.status == "success") {
          await storeApiResponse(
            {uuid,source},
            "op_customer_consents",
            uuid,
            custConsentRes.data.consents
          );
        }

        // if (task_id != null && callbackResponse.data.status == "Success") {
        //   const accountResponse = await fetchAccountDetails(uuid, customer_id, source,is_test,visitorId);
        //   if (accountResponse.data.status == "Success") {
        //     payload = { "milestone_key": "account_fetch", "source": source };
        //     const storeAccount = await storeApiResponse(visitorParameters.visitor_parameters, "op_milestone_update", uuid, payload);
        //     if (storeAccount.data.status == "Success") {
        //       const transactionResponse = await fetchTransactions(uuid, customer_id, from_date, to_date, source,is_test,visitorId);
        //       if (transactionResponse.data.status == "Success") {
        //         setLoader(0);
        //         payload = { "milestone_key": "transaction_fetch", "source": source };
        //         await storeApiResponse(visitorParameters.visitor_parameters, "op_milestone_update", uuid, payload);
        //       }
        //     }
        //   }

        // }
      }
      
    })();
    setTimeout(() => {
      setLoader(0);
      console.log(year_minus);
    }, 1500);
  }, []);

  const pushProceedLink = async (e) => {
    setBtnDisable(true);

    setLoader(1);
     
    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');

    let payload = { page,type:'btn_click', btn_label, source ,'click_btn':'4'};
    await storeClickResponse({uuid:uuid},"ob_creditcheck_history",uuid,payload);

    const visitor = await getVisitorID(uuid, user_data, "re_visit");
    if (visitor.data.status == "success") {
      let visitor_id = visitor.data.visitorId;
      localStorage.setItem("visitorId", visitor_id);

      let payload = { milestone_key: "access_token", source: source };
      await storeApiResponse(
        visitorParameters.visitor_parameters,
        "op_milestone_update",
        uuid,
        payload
      );

      let payloadCust = { milestone_key: "customer_create", source: source };
      await storeApiResponse(
        visitorParameters.visitor_parameters,
        "op_milestone_update",
        uuid,
        payloadCust
      );


      if ( (customer_id === undefined || customer_id === null || customer_id == '') || (is_test === undefined || is_test === null || is_test == '')) {
       
        const dataresponse = await getUserDataForOB(uuid);
           
        if (dataresponse.data.status == "Success") { 

          is_test =  dataresponse.data.response.is_test;
          customer_id  = dataresponse.data.response.customer_id;

        }

      } 

      let redirectUrl = source
        ? AppDomain + "/ob-thankyou02?uuid=" + uuid + "&atp_sub6=" + source
        : AppDomain + "/ob-thankyou02?uuid=" + uuid;
      var response2 = await getAuthGatewayUrl(
        uuid,
        customer_id,
        redirectUrl,
        source,
        is_test,
        visitor_id
      );
      if (response2.data.status == "success") {
        payload = { milestone_key: "lender_redirection", source: source };
        await storeApiResponse(
          visitorParameters.visitor_parameters,
          "op_milestone_update",
          uuid,
          payload
        );

        let url = response2.data.gateway_url;
        window.location.href = url;
        //setLoader(0)
      }
    } else {
      console.log(visitor);
    }
  };

  const proccedToFinalStep = async(e) => {
    setBtnDisable(true);
    setLoader(1);
    
    btn_label = e.target.getAttribute('data-btn-label');
    page = e.target.getAttribute('data-page-label');

    let payload = { page,type:'btn_click', btn_label, source,'click_btn':'5' };
    await storeClickResponse({uuid:uuid},"ob_creditcheck_history",uuid,payload);
   
    let queryString = "uuid=" + uuid + "&atp_sub6=" + source;
    history.push("/credit_check?" + queryString);
    return false;
  };

  return (
    <>
      <div className="OB_Thankyou02">
        <div
          className={`main text-center main-div ${
            loader == 0 ? "show" : "hide"
          }`}
        >
          <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
            <img
              height="65"
              src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png"
              alt=""
            />
          </div>
          <div className="container btn-info_part">
            <div className="row justify-content-center m-0">
              <div className="col-lg-12 col-11">
                <div className="space text-center">
                  <p className="htext fs-2">
                    <strong> Thank you </strong>
                  </p>
                  <img
                    src="assets/img/thankyou.png"
                    alt=""
                    className="tq-img bounce-in"
                  />
                  <p className="_sub">
                    <strong>
                      {" "}
                      You can also take advantage of the chance to leverage any
                      other bank accounts you had open at the time the loan was
                      acquired. Or you can continue with your credit check by
                      moving on to the final step.
                    </strong>
                  </p>
                </div>
              </div>
              <div className="mt-2 col-lg-6 col-12 lg-offset-4">
                <button
                  onClick={pushProceedLink}
                  disabled={btndisable}
                  className="  btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 second disablebtn"
                  data-btn-label = "connect_another_bank"
                  data-page-label = "OB_TY_V2"
                >
                  {" "}
                  Connect Another Bank <span> ›</span>{" "}
                </button>
              </div>
              <div className="hr_tag">
                <hr />
                &nbsp;&nbsp; OR &nbsp;&nbsp;
                <hr />
              </div>
              <div className="mb-5 col-lg-6 col-12 lg-offset-4">
                <button
                  onClick={proccedToFinalStep}
                  disabled={btndisable}
                  data-btn-label = "proceed_to_final_step"
                  data-page-label = "OB_TY_V2"
                  className=" btn-comm2 mt-lg-3 mt-md-3 mt-sm-3 mt-0 second_1 anim_blue disablebtn"
                >
                  {" "}
                  Proceed to Final Step <span> ›</span>{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`loader text-center ${loader == 1 ? "showload" : "hide"}`}
          id="page2"
        >
          <div className="loader-anime">
            <img src="assets/img/loading-23.gif" alt="" />
          </div>
        </div>
      </div>
    </>
  );
};
export default OB_Thankyou02;
