import React,  {useState }from "react";
import  "../../../assets/TMSV_SQL_V1/css/custom.scss";
// import  "../../../assets/TMSV_SQL_V1/css/dashboard.scss";
// import "../../../assets/TMSV_SQL_V1/css/main.scss";
import btn_arrow from "../../../assets/TMSV_SQL_V1/img/btn-arrow.png";
import AnchorAdv from "../../UI/AnchorAdv";
import AdtopiaLoadAP from "../../../Utility/AdtopiaLoadAP";
import AdvFooter from "../../Includes/Layouts/TMSV_SQL_V1/AdvFooter";
import { Months } from "../../../Constants/Constants";

const TMSV_SQL_V1 = () => {
  var now = new Date();
  const year = now.getFullYear();
  const date = now.getDate();
  const monthIndex = now.getMonth();
  const monthName = Months[monthIndex];
  const formatted = ` ${monthName} ${date}, ${year}`;
  
  return (
    <>
<AdtopiaLoadAP pageType="AP" splitName="TMSV_SQL_V1" />
  <div className="TMSV_SQL_V1">

    <main className="section_main ">
      <div className="container">
      <div className="text-center mb-5 px-3">
        <p className="text-adver pt-3">Advertorial</p>
        <h1>Ever had repeated credit from Very, Shop Direct or Littlewoods Catalogues?</h1>
        <h3>You could be owed £1,000s for mis-sold credit!</h3>
      </div>
      <div className="row sec_middle">
        <div className="col-5 col-lg-6 col-md-6 col-sm-6 text-lg-end text-md-center text-sm-center text-center pb-lg-5">
          <img width=" " className="img-mid" src="/assets/TMSV_SQL_V1/img/young.png" alt="" />
        </div>
        <div className="col-7 col-lg-6 col-md-6 col-sm-6 d-lg-block d-flex align-items-center">
          <div className="mt-lg-5 text-center text-sm-center text-md-center text-lg-start">
            {/* <a href="" className="com_bnt">Start My Check <img src={btn_arrow} alt=""/>
            </a> */}
             <AnchorAdv href="#" dataId="MAIN_CTA_BTN"  className="com_bnt">Start My Check<img src={btn_arrow} alt=""/></AnchorAdv>
              
                  
                  
               
          </div>
        </div>
      </div>
    </div>
    </main>
    <AdvFooter/>
  
 
  </div>
    </>
  );
};

export default TMSV_SQL_V1;

