import React from 'react';
import SelectBox from '../../../UI/SelectBox';
import {Amount} from "../../../../Constants/Constants";

const Questionnaire2 = ({
	clsName,
	reff,
	page_name,
	uuid,
	onselectAnswer,
	validation,
	validationMsg,
	trigger,
	getValues,
	setError,
	saveInputAns,
	lender
}) => {
	const handleClick = async (e) => {
	let highestBalance = await trigger("highest_balance");
	let errorFlag = 0;
	if(!highestBalance) {
	 errorFlag=1;
	 }
		if(errorFlag === 0){    
			saveInputAns(e);   
		} 
		else 
		{
		    return false;
		}	
};
	return (
		<>
			<div className={`radio-box animated fadeInUp text-center ${clsName}`} ref={reff} >
				<h3><b>{`Approximately what was the highest balance you ever had with ${lender} credit account?`}</b></h3>

				<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">  
						<SelectBox
                                className="form-select form-control"
                                OptionValue={Amount}
                                name="highest_balance"
                                id="highest_balance"
                                myRef={validation({
                                    required: "Please Select Amount"
                                })}
                                validationMsg={validationMsg.highest_balance && validationMsg.highest_balance.message}
                            />
				</div>
				<div className="col-xl-12 col-lg-12 text-center p-0" >
					<button 
						type="button" 
						className="btn-next" 
						onClick={handleClick} 
						data-quest-id={53} 
						data-ans-id={null}
						data-ans-label={null}
						name="question_53"
						>{`NEXT`}</button>
				</div>
			</div>
		</>
	)
}

export default Questionnaire2;