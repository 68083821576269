import React, { useEffect, useState } from "react";
import SelectBox from "../../../../UI/SelectBox";
import AnalyzeModal from "../AnalayzeModal";
import { RecentCreditIncreaseYear } from "../../../../../Constants/Constants";

const RecentCreditLimit = ({
  clsName,
  reff,
  // checkCarRegistration,
  validation,
  validationMsg,
  trigger,
  getValues,
  setError,
  page_name,
  uuid,
  split_name,
  saveInputAns,
  splitData,
}) => {
  const [closeYear, setAccCloseyear] = useState([]);

  const selectedYear = async (e) => {
    if(e.target.name == "dontRecall"){
      saveInputAns(e);
    }else{
    let errorFlag = 0;
    let yearresult = await trigger("CreditIncrease");

    if (!yearresult) {
      errorFlag = 1;
      return false;
    }
    // const data = getValues();
    // let yearSelected = data.RecentCreditIncrease;
    // if (yearSelected < 2016) {
    //   setshowClaimModal(true);
    // } 
    else {
      saveInputAns(e);
    }
  }
  }

  return (
    <>
      <div className={`radio-box animated fadeInUp`} ref={reff}>
        <h3>
          <b>
            What year was the last credit limit increase on your account, if
            ever?
          </b>
        </h3>
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
          <SelectBox
            className="form-select form-control"
            OptionValue={RecentCreditIncreaseYear}
            name="CreditIncrease"
            id="CreditIncrease"
            myRef={validation({
              required: "Please Select Year",
            })}
            validationMsg={
              validationMsg.CreditIncrease &&
              validationMsg.CreditIncrease.message
            }
          />
        </div>
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button
            type="button"
            className="btn-next"
            onClick={selectedYear}
            data-quest-id={70}
            data-ans-id={null}
            data-ans-label={null}
            name="dontRecall"
          >{`I Don't Recall `}</button>
        </div>
        <div className="col-xl-12 col-lg-12 text-center p-0">
          <button
            type="button"
            className="btn-next"
            onClick={selectedYear}
            data-quest-id={70}
            data-ans-id={null}
            data-ans-label={null}
            name="btn_qest_70"
          >{`NEXT`}</button>
        </div>
      </div>
    </>
  );
};

export default RecentCreditLimit;
