import { Api } from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";

export const useClaimReg = () => {
  const updateClaimReg = async (
    uuid,
    page_name,
    claim_msg
  ) => {
    const response = await Api.post("api/v1/claim_reg_click", {
        uuid,
        page_name,
        claim_msg
        
    });
    return response;
  };

  const clickPreviewVisit = async (
    uuid,
    page_name,
    claim_msg
  ) => {
    const response = await Api.post("api/v1/preview_visit_click", {
        uuid,
        page_name,
        claim_msg
        
    });
    return response;
  };
  const clickPreviewVisitFlp = async (
    uuid,
    page_name,
    claim_msg,
    source
  ) => {
    const response = await Api.post("api/v1/preview_visit_click_flp", {
        uuid,
        page_name,
        claim_msg,
        source,
        cakepost : 0,
    });
    return response;
  };
  const signatureConfirmFlp = async (
    uuid,
    page_name,
    message,
    source
  ) => {
    const response = await Api.post("api/v1/signature-confirm", {
        uuid,
        page_name,
        message,
        source
    });
    return response;
  };
  return { updateClaimReg,clickPreviewVisit,clickPreviewVisitFlp,signatureConfirmFlp };
};
