import React, { useState } from "react";
import ProgressBar from "../../Common/ProgressBar";
import RadioButton from "../../../../UI/RadioButton";
import Modal from "react-bootstrap/Modal";
import InputButton from "../../../../UI/InputButton";
import Button from '../../../../UI/Button';

const QuestionnaireFlpVerify = ({
  backClick,
  onselectAnswer,
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);
  const nextSlide = async (e) => {
    if (e.target.value === "99") {
      setCheckedOne("checked");
      onselectAnswer(e);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "100") {
      setCheckedTwo("checked");
      setshowClaimModal(true);
      // slideChange(e);
    } else {
      setCheckedTwo("unchecked");
    }
  };
  return (
    <>
      <div className="qs-slide1  openbank-slide1">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1>
              Have you experienced any hardships or struggle in making the
              repayments to MoneyBarn on time while still meeting all your other
              commitments?
            </h1>

            <div className="row mt-4">
              <div className="col-lg-12">
              <div className="btn-comm com-btn  ">
                  <RadioButton
                    value="99"
                    name="verify"
                    className="radio regNextBtn"
                    labelName="Yes, I Have Struggled to Repay"
                    labelClassName={`com-btn btn-next w-100 mb-3 ${checkedOne}`}
                    onClick={nextSlide}
                    dataAnsId={99}
                    dataQuestId={29}
                   // validation={validation()}
                  />

                  <RadioButton
                    value="100"
                    name="verify"
                    className="radio regNextBtn"
                    labelName="No Issues Repaying"
                    labelClassName={`com-btn btn-next w-100 mb-3 ${checkedTwo}`}
                    onClick={nextSlide}
                    dataAnsId={100}
                    dataQuestId={29}
                   // validation={validation()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* **** cannot accept your claim popup start **** */}
      <Modal
        size="md"
        show={claimModal}
        onHide={handleCloseclaimModal}
        centered
        backdrop="static"
        className="claimdeinial2"
      >
        <Modal.Body>
          <h3 className="text-center mb-3 option_butb3">
           Sorry, we will not be able to help you if you haven't experienced any hardships or struggles in repaying
          </h3>
          <div className="modal-content prevname-Btnpop justify-content-center" >
            <InputButton
              className="option_butb3 backPop "style={{ background:  '#2f9de8', color: '#fff', padding: '15px 10px', width: '100%', border: 'none', outline: '0', fontWeight: '600', fontSize: '20px'}}
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default QuestionnaireFlpVerify;
