import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButtonOutLabel";
import TextField from "../../../UI/TextField";

const QuestionnaireStillHave = ({ 
  clsName,
  onselectAnswer,
  reff,
}) => {

  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>Do you still have the vehicle?</h3>
        <RadioButton
          key="YES"
          id="vehicleYes1"
          value="YES"
          name="stillVehicle"
          className="radio"
          labelName="Yes"
          labelCountString="a"
          labelClassName="radioa next-6"
          onClick={labelclicked}
          dataAnsId={97}
          dataQuestId={16}
        />
        <RadioButton
          key="NO"
          id="vehicleYes2"
          value="NO"
          name="stillVehicle"
          className="radio"
          labelName="No"
          labelCountString="b"
          labelClassName="radioa next-6"
          onClick={labelclicked}
          dataAnsId={98}
          dataQuestId={16}
        />
        <RadioButton
          key="Not sure"
          id="vehicleYes3"
          value="Not sure"
          name="stillVehicle"
          className="radio"
          labelCountString="c"
          labelName="Not sure"
          labelClassName="radioa next-6"
          onClick={labelclicked}
          dataAnsId={195}
          dataQuestId={16}
        />
      </div>

    </>
  );
};

export default QuestionnaireStillHave;
