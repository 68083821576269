import React, { useEffect, useState, useRef, useReducer, useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Slide1 from '../Includes/Layouts/MB_UBRF_2/Slide1';
import Slide2 from '../Includes/Layouts/MB_UBRF_2/Slide2';
import Slide3 from '../Includes/Layouts/MB_UBRF_2/Slide3';
import Slide4 from '../Includes/Layouts/MB_UBRF_2/Slide4';
import Slide5 from '../Includes/Layouts/MB_UBRF_2/Slide5';
import Slide6 from '../Includes/Layouts/MB_UBRF_2/Slide6';
import Slide7 from '../Includes/Layouts/MB_UBRF_2/Slide7';
import Slide8 from '../Includes/Layouts/MB_UBRF_2/Slide8';
import Slide9 from '../Includes/Layouts/MB_UBRF_2/Slide9';
import Slide10 from "../Includes/Layouts/MB_UBRF_2/Slide10";
import Slide11 from "../Includes/Layouts/MB_UBRF_2/Slide11";
import Slide12 from "../Includes/Layouts/MB_UBRF_2/Slide12";
import Slide13 from "../Includes/Layouts/MB_UBRF_2/Slide13";
import Slide14 from "../Includes/Layouts/MB_UBRF_2/Slide14";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import QueryStringContext from "../../Contexts/QueryString";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import * as EnvConstants from "../../Constants/EnvConstants";

const FormMB_UBRF_2 = () => {
  const initialState = {
    showSlide1: "show",
    showSlide2: "hide",
    showSlide3: "hide",
    showSlide4: "hide",
    showSlide5: "hide",
    showSlide6: "hide",
    showSlide7: "hide",
    showSlide8: "hide",
    showSlide9: "hide",
    showSlide10: "hide",
    showSlide11: "hide",
    showSlide12: "hide",
    showSlide13: "hide",
    showSlide14: "hide",
  };
  const FormReducer = (state, action) => {
    switch (action.type) {
      case "showSlide": {
        if (action.payload.clickedSlide.slide === "carRegNo") {
          return {
            ...state,
            showSlide1: "hide",
            showSlide2: "show",
          };
        } else if (action.payload.clickedSlide.slide === "keeperDate") {
          return {
            ...state,
            showSlide2: "hide",
            showSlide3: "show",
          };
        } else if (action.payload.clickedSlide.slide === "yourVehicle") {
          return {
            ...state,
            showSlide3: "hide",
            showSlide4: "show",
          };
        } else if (action.payload.clickedSlide.slide === "employmentStatus") {
          return {
            ...state,
            showSlide4: "hide",
            showSlide5: "show",
          };
        } else if (action.payload.clickedSlide.slide === "dependents") {
          return {
            ...state,
            showSlide5: "hide",
            showSlide6: "show",
          };
        } else if (action.payload.clickedSlide.slide === "monthlyIncome") {
          return {
            ...state,
            showSlide6: "hide",
            showSlide7: "show",
          };
        } else if (action.payload.clickedSlide.slide === "securedFinance") {
          return {
            ...state,
            showSlide7: "hide",
            showSlide8: "show",
          };
        } else if (action.payload.clickedSlide.slide === "monthlyExpense") {
          return {
            ...state,
            showSlide8: "hide",
            showSlide9: "show",
          };
        } else if (action.payload.clickedSlide.slide === "verifyDetails") {
          return {
            ...state,
            showSlide9: "hide",
            showSlide10: "show",
          };
        } else if (action.payload.clickedSlide.slide === "complaintWithMB") {
          return {
            ...state,
            showSlide10: "hide",
            showSlide11: "show",
          };
        } else if (action.payload.clickedSlide.slide === "trustDeed") {
          return {
            ...state,
            showSlide11: "hide",
            showSlide12: "show",
          };
        } else if (action.payload.clickedSlide.slide === "basicDetails") {
          return {
            ...state,
            showSlide12: "hide",
            showSlide13: "show",
          };
        } else if (action.payload.clickedSlide.slide === "postcode") {
          return {
            ...state,
            showSlide13: "hide",
            showSlide14: "show",
          };
        } else {
          return {
            ...state,
          };
        }
      }
      case 'previousSlides': {
        if (action.payload.previousSlide.slide === 'back01') {
          return {
            ...state,
            showSlide1: "show",
            showSlide2: "hide"
          }
        } else if (action.payload.previousSlide.slide === 'back02') {
          return {
            ...state,
            showSlide2: "show",
            showSlide3: "hide"
          }
        } else if (action.payload.previousSlide.slide === 'back03') {
          return {
            ...state,
            showSlide3: "show",
            showSlide4: "hide"
          }
        } else if (action.payload.previousSlide.slide === 'back04') {
          return {
            ...state,
            showSlide4: "show",
            showSlide5: "hide"
          }
        } else if (action.payload.previousSlide.slide === 'back05') {
          return {
            ...state,
            showSlide5: "show",
            showSlide6: "hide"
          }
        } else if (action.payload.previousSlide.slide === 'back06') {
          return {
            ...state,
            showSlide6: "show",
            showSlide7: "hide"
          }
        } else if (action.payload.previousSlide.slide === 'back07') {
          return {
            ...state,
            showSlide7: "show",
            showSlide8: "hide"
          }
        } else if (action.payload.previousSlide.slide === 'back08') {
          return {
            ...state,
            showSlide8: "show",
            showSlide9: "hide"
          }
        } else if (action.payload.previousSlide.slide === 'back09') {
          return {
            ...state,
            showSlide9: "show",
            showSlide10: "hide"
          }
        } else if (action.payload.previousSlide.slide === 'back10') {
          return {
            ...state,
            showSlide10: "show",
            showSlide11: "hide"
          }
        } else if (action.payload.previousSlide.slide === 'back11') {
          return {
            ...state,
            showSlide11: "show",
            showSlide12: "hide"
          }
        } else if (action.payload.previousSlide.slide === 'back12') {
          return {
            ...state,
            showSlide12: "show",
            showSlide13: "hide"
          }
        } else if (action.payload.previousSlide.slide === 'back13') {
          return {
            ...state,
            showSlide13: "show",
            showSlide14: "hide"
          }
        } else {
          return {
            ...state
          }
        }
      }
      default: {
      }
    }
  };

  const scrollfrmv2div = useRef([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [previousSlide, setPreviousSlide] = useState([]);
  const [clickedSlide, setClickedSlide] = useState([]);
  const splitForm = useRef(null);
  const history = useHistory();
  const { saveDataIngestion, isLoading } = useDataIngestion();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const slideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });
    }, 50);
    setClickedSlide({ slide: e.target.name });
  };
  const previousSlideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0
      });
    }, 50);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: 'previousSlides', payload: { previousSlide } });
    }
  }, [previousSlide]);


  const formSubmit = async () => {
    const form = splitForm.current;
    const values = getValues();
    const formData = values;

    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }
    
    //Sending Questiionaire Data
    formData.question_10 = formData.carRegNo;
    formData.question_11 = formData.keeperDate;
    formData.question_12 = formData.yourVehicle;
    formData.question_2  = formData.employmentStatus;
    formData.question_3  = formData.dependents;
    formData.question_4  = formData.monthlyIncome;
    formData.question_5  = formData.securedFinance;
    formData.question_6  = formData.monthlyExpense;
    formData.question_7  = formData.verifyDetails;
    formData.question_8  = formData.complaintWithMB;
    formData.question_9  = formData.trustDeed;
    //Questiionaire Data Complete

    formData.page_name = 'MB_UBRF_2';
    formData.pCode_manual = form['pCode_EntryType'].value;
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem('formData', JSON.stringify(formData));
      localStorage.setItem('queryString', queryString);
      const formSUbmitResult = await saveDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        'split_form_submit',
        'MB_UBRF_2',
        queryString,
        visitorParameters.data
      )
      if (formSUbmitResult.data.status === "Success") {

        if (queryString != null) {
          if (queryString.indexOf("&" + field + "=") !== -1) {
            // if (EnvConstants.AppConversionPixel === 'true') {
            //   history.push("/fbpixel?split_name=Split_1");// fb fixel firing
            // } else {
              window.scrollTo(0, 0);
              history.push(
                "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
              );
            // }
          } else {
            window.scrollTo(0, 0);
            history.push(
              "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          }
        } else {
          window.scrollTo(0, 0);
          history.push(
            "/signature?uuid=" + visitorParameters.visitor_parameters.uuid
          );
        }
      }
    }
  };

  return (
    <>
      <form
        ref={splitForm}
        name="split_form"
        id="user_form"
        autoComplete="off"
      >
        <div ref={scrollfrmv2div}>
          <Slide1
            className={state.showSlide1}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
          />
          <Slide2
            className={state.showSlide2}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
          />
          <Slide3
            className={state.showSlide3}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
          />
          <Slide4
            className={state.showSlide4}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
          />
          <Slide5
            className={state.showSlide5}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
          />
          <Slide6
            className={state.showSlide6}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
          />
          <Slide7
            className={state.showSlide7}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
          />
          <Slide8
            className={state.showSlide8}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
          />
          <Slide9
            className={state.showSlide9}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
          />
          <Slide10
            className={state.showSlide10}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
          />
          <Slide11
            className={state.showSlide11}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
          />
          <Slide12
            className={state.showSlide12}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
          />
          <Slide13
            className={state.showSlide13}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
            scrollView={scrollfrmv2div}
          />
          <Slide14
            className={state.showSlide14}
            validation={register}
            getValues={getValues}
            slideChange={slideChange}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            splitForm={splitForm}
            setError={setError}
            backClick={previousSlideChange}
            formSubmit={formSubmit}
          />
          <div className="col-lg-12 text-center slide-logo">
            <img src="/assets/MB_UBRF_2/img/ssl.svg" alt="" />
          </div>
        </div>
      </form>
    </>
  );
};

export default FormMB_UBRF_2;