import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import AlertModal from "./AlertModal";
import AnalyzeModal from "./AnalayzeModal";


const Questionnaire75 = ({
  clsName,
  reff,
  onselectAnswer,
  userEmail
}) => {
  
  const labelclicked = async (e) => {
      onselectAnswer(e); 
  };
  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>
        Would you also like to share your income and expenditure at the time you took out the finance with Vanquis?
        </h3>

        <RadioButtonQuest
          key="YES"
          className="radio-button"
          labelName="Yes"
          labelClassName="option_but next-2"
          // labelCountString="a"
          onClick={labelclicked}
          value="Yes"
          name="question_75"
          id="twonethree"
          dataQuestId={75}
          dataAnsId={213}
        />

        <RadioButtonQuest
          key="NO"
          className="radio-button"
          labelName="No"
          labelClassName="option_but next-2"
          // labelCountString="b"
          onClick={labelclicked}
          value="NO"
          name="question_75"
          id="twonefour"
          dataQuestId={75}
          dataAnsId={214}
        />
      </div>
    </>
  );
};

export default Questionnaire75;
