import { Api } from "../api/Api";
export const userInfo = () => {
  const getUserInfo = async (uuid) => {
    const response = await Api.get("api/v1/user-info?uuid=" + uuid, {});
    return response;
  };

  const UserDataInfo  = (
    visitor_parameters
  ) => {
    const response =  Api.post("api/v1/user-basic-details-allmoneybarn", {
      visitor_parameters
    });
    return response;
  };
  return { getUserInfo,UserDataInfo };
};
