import React, { useState } from "react";
import OtpInputBox from "../../../../UI/OtpInputBox.js";
import Button from "../../../../UI/Button.js";


const SortCode = ({
  validationMsg,
  clearErrors,
  validation,
  trigger,
  getValues,
  onselectAnswer,
  reff,
  className,
  setSortCode,
  SkippedLeads,
  addPending,
  hidePrev
}) => {
  const [scode, setSCode] = useState("");
  const [error, setError] = useState(false);

  const handlebtnChange = (scode) => {
    setSCode(scode);
    setSortCode(scode);
  };
  const skipNext = () => {
    // let array = JSON.parse(localStorage.getItem('questionData'));
    // array = array.filter(item => item.question_id !== 86);
    // localStorage.setItem('questionData', JSON.stringify(array));
    SkippedLeads("skipFLPSortCode", "sort_code");
  };

  const handleNext = async (e) => {
    if (scode.length != 6) {
      setError(true);
      return;
    } else if (parseInt(scode) === 0) {
      setError(true);
      return;
    } else {
      setError(false);
      onselectAnswer(e);
    }
  };

  return (
    <>
      <div
        className={`radio-box animated fadeInUp ${className}`}
        id="slide-15"
        ref={reff}
      >
        <h1 className="title">
          Please provide your Sort Code
        </h1>
   
      <div className="question-content mt-0 scale-down-ver-center px-3 pb-lg-3 pb-md-3 pb-sm-2 pb-2">
        <div className="row">
          <div className="col-lg-12 mb-2 col-center">
            <div className="otp-input">
              <OtpInputBox
                length={6}
                onChange={handlebtnChange}
                className={"otp-input-field"}
              />
            </div>
            {error && (
              <span className="error_msg mt-2 err_msg">
                Please enter a valid Sort Code
              </span>
            )}
          </div>
          <div className="col-lg-12 mt-2">
            <button
              type="button"
              className="btn-next btncls1"
              id="nextscroll"
              name="sortcode"
              onClick={handleNext}
              data-quest-id={86}
            >
              Next
            </button>
          </div>
          <span className="warning-text">
            {
              "(Please confirm your Sort Code to expedite your claim. If you prefer not to, you can skip this step.)"
            }
          </span>
          <div className="col-lg-12">
          <div className="mb-2 mt-3 text-center">
            <a className="backbtn skip-btn" name="sort_code" onClick={skipNext}>
              {"Skip"}
            </a>
          </div>
        </div>
        <div class={`text-center back ${hidePrev}`}>
                    <Button 
                    className="btn btn-link mt-2 h2" 
                    id="back1" 
                    type="button"
                    buttonText="<< Previous"
                    onClick={()=>addPending("income_and_expenditure")}
                    />
                </div>
        </div>
        </div>
      </div>
    </>
  );
};

export default SortCode;
