import React from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import "../../../assets/css/split_1.scss";
import Footer from "../../Includes/Layouts/Split_1/Footer";
import Header from "../../Includes/Layouts/Split_1/Header";
import FormSplit_1 from "../../Forms/FormSplit_1";
import FbViewPixel from '../../../Utility/FbViewPixel';
import Accordion from 'react-bootstrap/Accordion';

const Split_1 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="Split_1" />
      <FbViewPixel/>
      <div className="TMS_BRN_V3_2_1B">
        <Header/>
        <section className="form-section">
          <div className="container">
            <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-3 col-md-6">
              <FormSplit_1/>
       
              <div className="col-xl-12 col-lg-12 text-center p-0 ssl_secure">
                <img src="/assets/img/ssl.png" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="content-section">
          <div className="container text-center">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <img src="/assets/img/icon-1.png" alt="" />
                <h4>Lorem Ipsum is simply dummy text of the printing.</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 bordr">
                <img src="/assets/img/icon-2.png" alt="" />
                <h4>Lorem Ipsum is simply dummy text of the printing.</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                <img src="/assets/img/icon-3.png" alt="" />
                <h4>Lorem Ipsum is simply dummy text of the printing.</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
              </div>
            </div>
          </div>
        </section>
        {/* <SplitFAQ faq={faq} /> */}
        <Accordion className="accordion-section">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <h4>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h4>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <div className="accordion">
                  <Accordion.Item className="accordion-item" eventKey="0">
                    <Accordion.Header className="accordion-header">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </Accordion.Header>
                    <Accordion.Collapse eventKey="0" id="accor-1" className="accordion-collapse collapse">
                      <Accordion.Body className="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                      </Accordion.Body>
                    </Accordion.Collapse>
                  </Accordion.Item>
                </div>
                <div className="accordion">
                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="accordion-header">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </Accordion.Header>
                    <Accordion.Collapse eventKey="1" id="accor-1" className="accordion-collapse collapse">
                      <Accordion.Body className="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                      </Accordion.Body>
                    </Accordion.Collapse>
                  </Accordion.Item>
                </div>
                <div className="accordion">
                  <Accordion.Item className="accordion-item" eventKey="2">
                    <Accordion.Header className="accordion-header">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </Accordion.Header>
                    <Accordion.Collapse eventKey="2" id="accor-1" className="accordion-collapse collapse">
                      <Accordion.Body className="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                      </Accordion.Body>
                    </Accordion.Collapse>
                  </Accordion.Item>
                </div>
                <div className="accordion">
                  <Accordion.Item className="accordion-item" eventKey="3">
                    <Accordion.Header className="accordion-header">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </Accordion.Header>
                    <Accordion.Collapse eventKey="3" id="accor-1" className="accordion-collapse collapse">
                      <Accordion.Body className="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                      </Accordion.Body>
                    </Accordion.Collapse>
                  </Accordion.Item>
                </div>
                <div className="accordion">
                  <Accordion.Item className="accordion-item" eventKey="4">
                    <Accordion.Header className="accordion-header">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </Accordion.Header>
                    <Accordion.Collapse eventKey="4" id="accor-1" className="accordion-collapse collapse">
                      <Accordion.Body className="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                      </Accordion.Body>
                    </Accordion.Collapse>
                  </Accordion.Item>
                </div>
                <div className="accordion">
                  <Accordion.Item className="accordion-item" eventKey="5">
                    <Accordion.Header className="accordion-header">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </Accordion.Header>
                    <Accordion.Collapse eventKey="5" id="accor-1" className="accordion-collapse collapse">
                      <Accordion.Body className="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                      </Accordion.Body>
                    </Accordion.Collapse>
                  </Accordion.Item>
                </div>
                <div className="accordion">
                  <Accordion.Item className="accordion-item" eventKey="6">
                    <Accordion.Header className="accordion-header">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </Accordion.Header>
                    <Accordion.Collapse eventKey="6" id="accor-1" className="accordion-collapse collapse">
                      <Accordion.Body className="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                      </Accordion.Body>
                    </Accordion.Collapse>
                  </Accordion.Item>
                </div>
                <div className="accordion">
                  <Accordion.Item className="accordion-item" eventKey="7">
                    <Accordion.Header className="accordion-header">
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </Accordion.Header>
                    <Accordion.Collapse eventKey="7" id="accor-1" className="accordion-collapse collapse">
                      <Accordion.Body className="accordion-body">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                        </p>
                      </Accordion.Body>
                    </Accordion.Collapse>
                  </Accordion.Item>
                </div>
              </div>
            </div>
          </div>
        </Accordion>
       <Footer/>
      </div>
    </>
  );
};

export default Split_1;
