import React from 'react';
import RadioButtonQuest from '../../../UI/RadioButtonQuest';

const QuestionnaireWrapper = ({questArr,questId,reff,labelclicked}) => {
    const countString = {
        0:'A',
        1:'B',
        2:'C'
    }
    const questAnswers = questArr.answers.map((answer,index) => {
        return (
                <RadioButtonQuest  
                    key={answer.value}
                    className="radio"
                    labelName={answer.label}
                    labelClassName="br radioa"
                    labelCountString={countString[index]}
                    onClick={labelclicked}
                    value={answer.value}
                    name={questArr.questionId}
                    id={answer.value}
                />
            )
    })
    return (
        <div className="radio-box question animated fadeInUp" ref={reff}>
            <h4>
                {questArr.question}
            </h4>
            <div className="form-group">
                <div className="" id="question_group_60" >
                    {questAnswers}
                </div>
            </div>
        </div>
    )
}

export default QuestionnaireWrapper;