import React, { useState, useEffect, useRef } from "react";
import { useIdUpload } from "../../../../Hooks/useIdUpload";
import { queryString } from "../../../../Utility/QueryString";
import { useHistory } from "react-router-dom";

const userfile = {
  preview: undefined,
  title: undefined,
};
const UploadSectionS2 = (props) => {
  const [errorMessage, setErrorMessage] = useState();
  const [isInValidfFile, setIsInValidfFile] = useState(true);
  const [base64Image, setBase64Image] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const { saveFLPUserIdUpload, isLoading } = useIdUpload();
  const history = useHistory();

  const changeFilesHandler = (event) => {
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setIsInValidfFile(false);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      let reader = new FileReader();
      reader.onload = _handleReaderLoaded.bind(this);
      reader.readAsDataURL(imageFile);
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const changeCameraHandler = (event) => {
    let imageFile = event.target.files[0];
    if (imageFile) {
      setIsFilePicked(true);
      let extension = event.target.files[0].type;
      let imgsize = event.target.files[0].size;
      let allowedExtensions = ["image/jpeg", "image/png"];
      if (allowedExtensions.indexOf(extension) === -1) {
        setIsInValidfFile(true);
        setErrorMessage({
          txt: "The uploaded ID type should be an image in jpeg,png or jpg",
        });
      } else if (imgsize > 1024 * 1024 * 20) {
        setIsInValidfFile(true);
        setErrorMessage({ txt: "Please choose a file less than 20mb" });
      } else {
        setIsInValidfFile(false);
      }

      userfile.preview = URL.createObjectURL(imageFile);
      userfile.title = imageFile.name;

      if (imageFile) {
        let reader = new FileReader();
        reader.onload = _handleReaderLoaded.bind(this);
        reader.readAsDataURL(imageFile);
      }
    } else {
      setIsInValidfFile(true);
      setIsFilePicked(false);
      userfile.preview = undefined;
      userfile.title = undefined;
    }
  };

  const _handleReaderLoaded = (readerEvt) => {
    let binaryString = readerEvt.target.result;
    setBase64Image({
      base64TextString: binaryString,
    });
  };

  const savedocument = async () => {
    try {
      if (isFilePicked) {
        let uploadData = "";
        uploadData = {
          imageFile: base64Image.base64TextString,
          captureType: props.idUploadState.uploadName.substring(
            props.idUploadState.uploadName.indexOf("doc")
          ),
          documentType: props.idUploadState.uploadName.substr(
            0,
            props.idUploadState.uploadName.indexOf("_")
          ),
        };

        const response = saveFLPUserIdUpload(
          uploadData,
          props.followupData,
          queryString
        );

        localStorage.setItem("t2a_result", 1);

        window.scrollTo(0, 0);

        let queryStrings = localStorage.getItem("queryString");

        history.push("/credit_check-s2?" + queryStrings);
      } else {
        setErrorMessage({ txt: "Please choose a file" });
        return false;
      }
    } catch (e) {
      console.warn("error");
      console.warn(e);
    }
  };

  return (
    <div className={`${props.show}`}>
      <div className="row justify-content-center preview_row1">
        <div className="col-lg-5 col-md-12 col-12 preview_col b-left-radius">
          <div className="preview_img" ref={props.scrollRef}>
            {!isInValidfFile ? (
              <img
                className="profile_pic_two"
                src={userfile.preview}
                alt={userfile.title}
              />
            ) : (
              <img
                className="profile_pic"
                src="/assets/IdFollowup/img/upload-img.png"
                alt=""
              />
            )}
          </div>
        </div>
        <div className="col-lg-5 col-md-12 col-12 preview_col b-right-radius">
          <p>{props.idUploadState.name}</p>
          <div className="preview_btn d-flex flex-column">
            <span className="button-tab">
              Upload a Photo
              <input
                className="btn btn-primary btn_upload"
                type="file"
                accept="image/*"
                id="idTypeFile"
                name="driversLicense_doc"
                onChange={changeFilesHandler}
                onClick={(e) => (e.target.value = null)}
              />
            </span>
            <span className="button-tab">
              Take a Photo
              <input
                className="btn btn-primary btn_upload"
                type="file"
                accept="image/*"
                capture="camera"
                id="idTypeFile"
                name="driversLicense_doc"
                onChange={changeCameraHandler}
                onClick={(e) => (e.target.value = null)}
              />
            </span>
            <input
              type="submit"
              className="btn button-submit"
              value="Submit Document"
              onClick={savedocument}
              onBlur={setErrorMessage}
            />
            {isInValidfFile && errorMessage ? (
              <p className="error-msg">{errorMessage.txt}</p>
            ) : null}
            <a
              onClick={props.backHide}
              className="backhere d-block d-lg-none d-flex pt-1 justify-content-center"
            >
              <img
                className="profile_pic"
                src="/assets/IdFollowup/img/back1.png"
                alt=""
              />
              Back
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UploadSectionS2;
