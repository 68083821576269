import React, { useState } from "react";
import RadioButtonQuest from "../../../../UI/RadioButtonQuest";
import Button from "../../../../UI/Button";
// import AlertModal from "./AlertModal";
// import AnalyzeModal from "./AnalayzeModal";

const QuestionnaireEmailConfirmation = ({
  clsName,
  reff,
  onselectAnswer,
  userEmail,
  className,
  addPending,
}) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };
  return (
    <>
      <div className={className}>
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <h1 className="title">
              {" "}
              Is this the email address "{userEmail}" that you registered with
              MoneyBarn
            </h1>

            <div className="row mt-4">
              <div className="col-lg-12">
                <div className=" col-lg-12 mb-pd text-center ">
                  <div className="btn-comm com-btn  ">
                    <RadioButtonQuest
                      key="YES"
                      className="radio-button"
                      labelName="Yes"
                      labelClassName="option_but  emailYes"
                      // labelCountString="a"
                      onClick={labelclicked}
                      value="1"
                      name="question_61"
                      id="sixone"
                      dataQuestId={61}
                      dataAnsId={162}
                    />
                    <RadioButtonQuest
                      key="NO"
                      className="radio-button"
                      labelName="No"
                      labelClassName="option_but  emailYes"
                      // labelCountString="b"
                      onClick={labelclicked}
                      value="NO"
                      name="question_61"
                      id="sixtwo"
                      dataQuestId={61}
                      dataAnsId={163}
                    />
                    <RadioButtonQuest
                      key="NotSure"
                      className="radio-button"
                      labelName="Not sure"
                      labelClassName="option_but notsure-btn emailYes"
                      // labelCountString="b"
                      onClick={labelclicked}
                      value="Not Sure"
                      name="question_61"
                      id="sixthree"
                      dataQuestId={61}
                      dataAnsId={195}
                    />
                  </div>
                  <div class="text-center back">
                    <Button 
                    className="btn btn-link mt-2 h2" 
                    id="back1" 
                    type="button"
                    buttonText="<< Previous"
                    onClick={()=>addPending("bank_stmt_confirm")}
                    />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionnaireEmailConfirmation;
