import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import SelectBox from "../../../UI/SelectBox";
import { YearPossession,VeryLenderArray } from "../../../../Constants/Constants";
import InputButton from "../../../UI/InputButton";
import RadioButton from "../../../UI/RadioButton";

const YearOfPossession = ({
  className,
  slideChange,
  textHeadingLine1,
  validationMsg,
  clearErrors,
  validation,
  backClick,
  trigger,
  getValues
}) => {

  const [agreeModalShow, setAgreeModalShow] = useState("hide");

  // const [checkedOne, setCheckedOne] = useState("unchecked");
  // const [checkedTwo, setCheckedTwo] = useState("unchecked");
  // // const nextSlide = async (e) => {
  //   if (e.target.value === "11") {
  //     setCheckedOne("checked");
  //     slideChange(e);
  //   } else {
  //     setCheckedOne("unchecked");
  //   }

  //   if (e.target.value === "12") {
  //     setCheckedTwo("checked");
  //     slideChange(e);
  //   } else {
  //     setCheckedTwo("unchecked");
  //   }
  // };
  const nextSlide = async (e) => {
    let errorFlag = 0;
    let yearresult = await trigger("YearOfPossession");

    if (!yearresult) {
      errorFlag = 1;
      return false;
    }
    const formData = getValues();
    let yearSelected = formData.YearOfPossession;
    if (yearSelected < 2017) {
      setAgreeModalShow('show') 
    } else {
      slideChange(e);
    }


    // if (errorFlag === 0) {
    //   slideChange(e);
    // } else {
    //   return false;
    // }
  };

  const AgreeSlide = async (e) => { 
    slideChange(e);
    setAgreeModalShow('hide')
  };

  return (
    <>
      <div id="slide-2" className={`${className}`}>
        <ProgressBar value="15" />

        <h3> { textHeadingLine1 } </h3>
        <div className="input-section mb-3">
          <SelectBox
            className="form-select form-control"
            OptionValue={YearPossession}
            name="YearOfPossession"
            id="YearOfPossession"
            onChange={() => clearErrors("YearOfPossession")}
            clearErrors={clearErrors}
            myRef={validation({ required: "Please Select Year" })}
            validationMsg={
              validationMsg.YearOfPossession && validationMsg.YearOfPossession.message
            }
          ></SelectBox>
        </div>

        <InputButton
          name="yearposession"
          className="btn next08  regNextBtn"
          id=""
          value="Next"
          btnType="button"
          onClick={nextSlide}
          style={{ float: "none" }}
        />

        <div>
          <p className="text-center">
            <span
              className="back1 back-btn btn-link"
              name="back02"
              children="<< Previous"
              onClick={backClick}
            ></span>
          </p>
        </div>
        <div role="dialog" className={`fade modal blackover ${agreeModalShow}`}>
          <div className="modal-dialog modal-md modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
              <h3><b>Please confirm if you agree with the statement</b></h3>
              <h3> I only recently understood I had cause to complain when TMS Legal represented my interests regarding irresponsible lending. I considered my financial issues to be my problem and didn't link it to any irresponsible lending by the lender.</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            
            <RadioButton
              value="131"
              className="radio radio-button"
              name="agreeYear"
              labelName="AGREE"
              labelClassName="option_but radio"
              btnType="button"
              validation={validation()}
              onClick={AgreeSlide}
            />

            <RadioButton
              value="132"
              className="radio radio-button"
              name="agreeYear"
              labelName="DISAGREE"
              labelClassName="option_but radio"
              btnType="button"
              validation={validation()}
              onClick={AgreeSlide}
            />
          </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default YearOfPossession;
