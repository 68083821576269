import React, { useState } from "react";
import ProgressBar from "../../Layouts/Split_1/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const BasicQuestionnaireOne = ({
  className,
  slideChange,
  textHeading,
  validation,
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const nextSlide = async (e) => {
    if (e.target.value === "1") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      slideChange(e);
    }
    if (e.target.value === "2") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      slideChange(e);
    }
  };
  return (
    <>
      <div id="slide-1" className={` slidetop_adjust ${className}`}>
      <ProgressBar value="0"/>
    
        <h4 className="text-center">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </h4>
        <div className="radio_buttons">
          <RadioButton
            value="1"
            name="question_1"
            className="radio"
            labelName="Yes"
            labelClassName={`radio ${checkedQuestionOne}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            value="2"
            name="question_1"
            className="radio"
            labelName="No"
            labelClassName={`radio ${checkedQuestionTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <p className="text-center">
          <span className="not_sure" id="not-sure">
            <u>Not Sure</u>
          </span>
        </p>
      </div>
    </>
  );
};
export default BasicQuestionnaireOne;
