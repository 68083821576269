import React,{useState} from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
const MinimumPayment = ({
    clsName,
    onSelectAnswer,
    splitForm,
    getValues,
    backClick
}) => {
    const [questResult, setQuestResult] = useState(false);
    const [checkedOne, setCheckedOne] = useState("unchecked");
    const [checkedTwo, setCheckedTwo] = useState("unchecked");
    const [checkedThree, setCheckedThree] = useState("unchecked");
    const [checkedFour, setcheckedFour] = useState("unchecked");
    const [checkedFive, setcheckedFive] = useState("unchecked");
    const optionClicked = async (e) => {
        if (e.target.value === "146") {
            setCheckedOne("checked");
        } else {
            setCheckedOne("unchecked");
        }

        if (e.target.value === "147") {
            setCheckedTwo("checked");
        } else {
            setCheckedTwo("unchecked");
        }

        if (e.target.value === "148") {
            setCheckedThree("checked");
        } else {
            setCheckedThree("unchecked");
        }

        if (e.target.value === "149") {
            setcheckedFour("checked");
        } else {
            setcheckedFour("unchecked");
        }
        if (e.target.value === "150") {
            setcheckedFive("checked");
        } else {
            setcheckedFive("unchecked");
        }
        setQuestResult(false);
    };
    const selectedClick = async (e) => {
        e.preventDefault();
        let errorFlag = 0;
        let optionselect = splitForm.current["minimumPayment"].value;
        if (optionselect == '') {
            errorFlag = 1;
            setQuestResult(true);
            return false;
        } else {
            onSelectAnswer(e);
        }
    }
    return (
        <>
         <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 pb-3">
                <div className="row">
                  <div className="col-lg-12 mb-2">
                    <ul className="p-0 m-0 option-grid">
                      <li className="adjustbalewid">
                        <div className="optionbtn">
                          <RadioButtonQuest
                                        name="minimumPayment"
                                        id="optionzthree"
                                        labelClassName={`${checkedOne}`}
                                        value="146"
                                        labelName="Always"
                                        onClick={optionClicked}
                                        dataQuestId={56}
                                        dataAnsId={146}
                                        dataAnsLabel="Always"
                                    />
                        </div>
                      </li>

                      <li className="adjustbalewid">
                        <div className="optionbtn">
                          <RadioButtonQuest
                                        name="minimumPayment"
                                        id="optionzthree2"
                                        labelClassName={`${checkedTwo}`}
                                        value="147"
                                        labelName="Sometimes"
                                        onClick={optionClicked}
                                        dataQuestId={56}
                                        dataAnsId={147}
                                        dataAnsLabel="Sometimes"
                                    />
                        </div>
                      </li>
                      <li className="adjustbalewid">
                        <div className="optionbtn">
                          <RadioButtonQuest
                                        name="minimumPayment"
                                        id="optionzthree3"
                                        labelClassName={`${checkedThree}`}
                                        value="148"
                                        labelName="Twice"
                                        onClick={optionClicked}
                                        dataQuestId={56}
                                        dataAnsId={148}
                                        dataAnsLabel="Twice"
                                    />
                        </div>
                      </li>
                      <li className="adjustbalewid">
                        <div className="optionbtn">
                          <RadioButtonQuest
                                        name="minimumPayment"
                                        id="optionzthree4"
                                        labelClassName={`${checkedFour}`}
                                        value="149"
                                        labelName="Once"
                                        onClick={optionClicked}
                                        dataQuestId={56}
                                        dataAnsId={149}
                                        dataAnsLabel="Once"
                                    />
                        </div>
                      </li>
                      <li className="adjustbalewid">
                        <div className="optionbtn">
                  
                          <RadioButtonQuest
                                        name="minimumPayment"
                                        id="optionzthree5"
                                        labelClassName={`${checkedFive}`}
                                        value="150"
                                        labelName="Never"
                                        onClick={optionClicked}
                                        dataQuestId={56}
                                        dataAnsId={150}
                                        dataAnsLabel="Never"
                                    />
                        </div>
                      </li>
                    </ul>
                    {questResult && (
                            <span className="error_msg">Please select an option</span>
                        )}
                  </div>
                  <div className="col-lg-12 mb-2 mt-2">
                    <button 
                    className="qb13 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                    name="minmumPaymnt"
                    type="button"
                    onClick={selectedClick}
                    data-quest-id={56}
                >
                    Next &#8594; 
                </button>
                        
                  </div>

                </div>
                <p className="text-center bck-marwid">
                    <button
                      name="excdCreditLimit"
                      className="btn btn-secondary backbtn1a"
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    >Go Back</button>
                    </p>
              </div>
        </>
    )
}
export default MinimumPayment;