import React, { useState } from "react";
import TextField from "../../../UI/TextField";
const PrivatePlateNum = ({
  clsName,
  reff,
  saveInputAns,
  validation,
  validationMsg,
  trigger,
  backClick,
  getValues,
  splitForm,
  onSelectAnswer
}) => {
 
  const handleClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    const values = getValues();
    let question44Result = await trigger("question_44");
    if (!question44Result) {
      errorFlag = 1;
      return false;
    }
    if (errorFlag === 0) {
      onSelectAnswer(e);
    }
  };
    

   
  
  return (
    <>
      <div className="question-content mt-0 scale-down-ver-center px-3  py-lg-3 py-md-3 py-sm-2 py-2" ref={reff}>
      <div className="row">
            <div className="col-lg-7 col-md-7 col-sm-12  " >
            <TextField
            type="text"
            className="car-number  w-100 "
            placeholder="Enter your car registration "
            name="question_44"
            maxlength="12"
            // onChange={handleChange}
            validation={validation({
              required: "Please enter your Privately Registered Plate",
              // pattern: {
              //   value: /^(?=.*[a-zA-Z])(?=.*[0-9])[a-zA-Z0-9 ]+$/,
              //   message: "Your Privately Registered Plate is Invalid. Please Recheck",
              // },
            })}
            validationMsg={
              validationMsg.question_44 &&
              validationMsg.question_44.message
            }
          ></TextField>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-12 ps-lg-1 ps-md-1 mt-lg-0 mt-md-0 mt-sm-2 mt-3">
              <button
                type="button"
                className="qb15 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn"
                id="nextscroll"
                name="btn_qest_44"
                onClick={handleClick}
                data-quest-id={44}
              >
                Next &#8594;
                {/* <i className="bi bi-arrow-right"></i>  */}
                </button>
            </div>
          </div>
    
        {/* <div className="row">

<div className="  text-center mt-3 " >
  
  <div className="backbtn"   > 
                    <button
                      name="incmAndMonth"
                      className=""
                      id=""
                      value=""
                      type="button"
                      onClick={backClick}
              
                    ><i className="bi bi-arrow-left"></i> Back</button> */}
        <div className="row">

          <div className="  text-center mt-3 " >

            <div className="backbtn" name="confirmPlate" onClick={backClick}  > <i className="bi bi-arrow-left"></i> Back</div>
          </div>

        </div>
        </div>
        
        

    </>
  )
}
export default PrivatePlateNum;