import React, { useState } from "react";
import ProgressBar from './ProgressBar';
import RadioButton from '../../../UI/RadioButton';

const Slide5 = ({
  className,
  slideChange,
  validation,
  trigger,
  clearErrors,
  getValues,
  setError,
  backClick
}) => {
  const [checked1,setChecked1] = useState('');
  const [checked2,setChecked2] = useState('');
  const [checked3,setChecked3] = useState('');
  const [checked4,setChecked4] = useState('');

  const nextSlide = async (e) => {
    if (e.target.value == 7) {
      setChecked1('activeclass');
    } else {
      setChecked1('');
    }

    if (e.target.value == 8) {
      setChecked2('activeclass');
    } else {
      setChecked2('');
    }

    if (e.target.value == 9) {
      setChecked3('activeclass');
    } else {
      setChecked3('');
    }

    if (e.target.value == 10) {
      setChecked4('activeclass');
    } else {
      setChecked4('');
    }

    slideChange(e);
  };
  return (
    <>
      <div id="slide5" className={`col-12 top_minus ${className}`}>
        <ProgressBar value={35} />
        <h3>At the time you secured finance… </h3>

        <div className="mb-3" id="slide-B">
          <h4>How many dependents did you have?</h4>

          <RadioButton
            htmlFor="previously"
            value="7"
            tabIndex="2"
            id="depend-1"
            name="dependents"
            className="radio-button"
            labelName="0"
            labelClassName={`option_but next-B ${checked1}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            htmlFor="previously"
            value="8"
            tabIndex="2"
            id="depend-2"
            name="dependents"
            className="radio-button"
            labelName="1"
            labelClassName={`option_but next-B ${checked2}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            htmlFor="sactive"
            value="9"
            tabIndex="2"
            id="depend-3"
            name="dependents"
            className="radio-button"
            labelName="2"
            labelClassName={`option_but next-B ${checked3}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            htmlFor="sactive"
            value="10"
            tabIndex="2"
            id="depend-4"
            name="dependents"
            className="radio-button"
            labelName="3 or more"
            labelClassName={`option_but next-B ${checked4}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <p className="text-center">
            <a href="#" className="back-A" id="back04" name="back04" onClick={backClick}>
              {'<< Previous'}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Slide5;