import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import SelectBox from "../../../UI/SelectBox";
import InputButton from "../../../UI/InputButton";
const Months = ({
  className,
  textHeadingLine1,
  questionnaire,
  questionId,
  slideChange,
  validationMsg,
  clearErrors,
  validation,
  backClick,
  trigger
}) => {
  let answers = questionnaire.answers;
  const nextSlide = async (e) => {
    let errorFlag = 0;
    let questionResult = await trigger("question_38");
    if (!questionResult) {
      errorFlag = 1;
    }
    if (errorFlag === 0) {
      slideChange(e);
    } else {
      return false;
    }
  };
  return (
    <>
      <div id="slide-1" className={`${className}`}>
        <ProgressBar value="0" />

        <h3>{textHeadingLine1}</h3>
        <div className="mb-3 mt-3 col-12 p-0">
          <SelectBox
            className="form-select form-control"
            OptionValue={answers}
            name="question_38"
            onChange={() => clearErrors("question_38")}
            clearErrors={clearErrors}
            myRef={validation({ required: "Please Select Number of Months" })}
            validationMsg={
              validationMsg.question_38 && validationMsg.question_38.message
            }
          ></SelectBox>
        </div>
        <div className="col-lg-12 mb-pd text-center ">
          <InputButton
            name="question38"
            className="btn next1 regNextBtn"
            id=""
            value="NEXT"
            btnType="button"
            onClick={nextSlide}
            style={{ float: "none" }}
          />
        </div>
      </div>
    </>
  );
};
export default Months;