import React, { useEffect, useState } from 'react';
import SelectBox from '../../../UI/SelectBox';
import { Dependent } from "../../../../Constants/Constants";



const DependentStatus = ({
	clsName,
	reff,
	validation,
	validationMsg,
	trigger,
	getValues,
	saveInputAns,
	backClick
}) => {



	// const [loader, showLoader] = useState(false);

	const handleClick = async (e) => {
		let Dependents = await trigger("question_3");
		let errorFlag = 0;
		if (!Dependents) {
			errorFlag = 1;
		}
		if (errorFlag === 0) {
			// onselectAnswer(e);  
			saveInputAns(e);
		}
		else {
			return false;
		}
	};

	return (
		<>
			<div className={`radio-box animated fadeInUp ${clsName}`} ref={reff} >
				<h3><b>How many dependents did you have at that time?</b></h3>
				<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
					<SelectBox
						className="form-select form-control"
						OptionValue={Dependent}
						name="question_3"
						id="question_3"
						myRef={validation({
							required: "Please Select Year"
						})}
						validationMsg={validationMsg.question_3 && validationMsg.question_3.message}
					/>
				</div>
				<div className="col-xl-12 col-lg-12 text-center p-0 mt-2" >
					<button
						type="button"
						className="btn-next"
						onClick={handleClick}
						data-quest-id={3}
						data-ans-id={null}
						data-ans-label={null}
						name="btn_qest_3"
					>{`NEXT`}</button>
				</div>
				<p className="text-center">
					<a href="#" className="backto79" name="back22" onClick={backClick}>
						&lt;&lt; Previous
					</a>
				</p>
			</div>
			{/* <AnalyzeModal modalClass={loader} /> */}

		</>
	)
}

export default DependentStatus;
