import { useState } from "react";
import CardMonthlyIncome from "./CardMonthlyIncome";
import DependentsNew from "./DependentsNew";
import IncomeAndExpV2 from "./IncomeAndExpV2";
import WereAlreadyPaying from "./WereAlreadyPaying";
import IncomeAndExpV3 from "./IncomeAndExpV3";

export default function IncomeAndExpenditureNew({
  validation,
  validationMsg,
  onselectAnswer,
  trigger,
  saveInputAns,
  saveIncomeAndExpNew,
  setChecklistOptions,
  checklistOptions,
  setError,
  getValues,
  splitForm,
  scrollToElement,
  clearErrors,
  setInputListValues,
  inputListValues
}) {
  return (
    <>
      <CardMonthlyIncome
        saveInputAns={saveInputAns}
        trigger={trigger}
        validation={validation}
        validationMsg={validationMsg}
        clearErrors={clearErrors}
        getValues={getValues}
      />
      <DependentsNew
        clearErrors={clearErrors}
        getValues={getValues}
        saveInputAns={saveInputAns}
        validation={validation}
        trigger={trigger}
        validationMsg={validationMsg}
      />

      <IncomeAndExpV3
        validation={validation}
        trigger={trigger}
        validationMsg={validationMsg}
        getValues={getValues}
        setError={setError}
        splitForm={splitForm}
        saveIncomeAndExpense={saveIncomeAndExpNew}
        scrollToElement={scrollToElement}
        checklistOptions={checklistOptions}
        setChecklistOptions={setChecklistOptions}
        inputListValues={inputListValues}
        setInputListValues={setInputListValues}
      />
    </>
  );
}
