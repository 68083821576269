import React, { useEffect, useState, useRef, useReducer, useContext } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import * as EnvConstants from "../../Constants/EnvConstants";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { blacklist } from "../../Hooks/blacklist";
import { questinnare38, questinnare39 } from "../../Constants/Questions";
import Months from "../Includes/Layouts/LS_UB_1/Months";
import Borrow from "../Includes/Layouts/LS_UB_1/Borrow";
import Arrears from "../Includes/Layouts/LS_UB_1/Arrears";
import Deed from "../Includes/Layouts/LS_UB_1/Deed";
import YearOfPossession from "../Includes/Layouts/LS_UB_1/YearOfPossession";
import EmploymentStatus from "../Includes/Layouts/LS_UB_1/EmploymentStatus";
import Struggle from "../Includes/Layouts/LS_UB_1/Struggle";
import Dependents from "../Includes/Layouts/LS_UB_1/Dependents";
import Documements from "../Includes/Layouts/LS_UB_1/Documements";
import Filed from "../Includes/Layouts/LS_UB_1/Filed";
import PersonalDetails from "../Includes/Layouts/LS_UB_1/PersonalDetails";
import PostCode from "../Includes/Layouts/LS_UB_1/PostCode";
import ContactDetails from "../Includes/Layouts/LS_UB_1/ContactDetails";

const initialState = {
  showSlide1: "show",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide",
  showSlide6: "hide",
  showSlide7: "hide",
  showSlide8: "hide",
  showSlide9: "hide",
  showSlide10: "hide",
  showSlide11: "hide",
  showSlide12: "hide",
  showSlide13: "hide",
};
const FormReducer = (state, action) => {
  switch (action.type) {
    case "showSlide": {
      if (action.payload.clickedSlide.slide === "question38") {
        GtmDataLayer({
          'question': 'consecutive months'
        });
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
        };
      } else if (action.payload.clickedSlide.slide === "question39") {
        GtmDataLayer({
          'question': 'How much you borrow?'
        });
        return {
          ...state,
          showSlide2: "hide",
          showSlide3: "show",
        };
      } else if (action.payload.clickedSlide.slide === "yearposession") {
        GtmDataLayer({
          'question': 'What year'
        });
        return {
          ...state,
          showSlide3: "hide",
          showSlide4: "show",
        };
      } else if (action.payload.clickedSlide.slide === "agreeYear") {
        GtmDataLayer({
          'question': 'What year'
        });
        return {
          ...state,
          showSlide3: "hide",
          showSlide4: "show",
        };
      }else if (action.payload.clickedSlide.slide === "question_40") {
        GtmDataLayer({
          'question': 'Arrears'
        });
        return {
          ...state,
          showSlide4: "hide",
          showSlide5: "show",
        };
      } else if (action.payload.clickedSlide.slide === "employment") {      
        GtmDataLayer({
          'question': 'employment status?'
        });   
        return {
          ...state,
          showSlide5: "hide",
          showSlide6: "show",
        };
      } else if (action.payload.clickedSlide.slide === "dependent") {
        GtmDataLayer({
          'question': 'dependents'
        });   
        return {
          ...state,
          showSlide6: "hide",
          showSlide7: "show",
        };
      } else if (action.payload.clickedSlide.slide === "struggle") {
        GtmDataLayer({
          'question': 'hardships  or struggle'
        }); 
        return {
          ...state,
          showSlide7: "hide",
          showSlide8: "show",
        };
      } else if (action.payload.clickedSlide.slide === "document") {
        GtmDataLayer({
          'question': 'What Documentation'
        }); 
        return {
          ...state,
          showSlide8: "hide",
          showSlide9: "show",
        };
      }else if (action.payload.clickedSlide.slide === "agreement_no") {
        GtmDataLayer({
          'question': 'What Documentation'
        });
        return {
          ...state,
          showSlide8: "hide",
          showSlide9: "show",
        };
      }else if (action.payload.clickedSlide.slide === "skip_agree_no") {
        GtmDataLayer({
          'question': 'What Documentation'
        });
        return {
          ...state,
          showSlide8: "hide",
          showSlide9: "show",
        };
      } else if (action.payload.clickedSlide.slide === "filed") {
        GtmDataLayer({
          'question': 'Filed a claim'
        });
        return {
          ...state,
          showSlide9: "hide",
          showSlide10: "show",
        };
      } else if (action.payload.clickedSlide.slide === "question_23") {
        return {
          ...state,
          showSlide10: "hide",
          showSlide11: "show",
        };
      } 
      else if (action.payload.clickedSlide.slide === "personalvalidate") {
        GtmDataLayer({
          'question': 'Name, DOB'
        });
        return {
          ...state,
          showSlide11: "hide",
          showSlide12: "show",
        };
      } else if (action.payload.clickedSlide.slide === "personal") {
        GtmDataLayer({
          'question': 'Name, DOB'
        });
        return {
          ...state,
          showSlide11: "hide",
          showSlide12: "show",
        };
      } else if (action.payload.clickedSlide.slide === "no_prev_name") {
        GtmDataLayer({
          'question': 'Name, DOB'
        });
        return {
          ...state,
          showSlide11: "hide",
          showSlide12: "show",
        };
      } else if (action.payload.clickedSlide.slide === "no_prev_post") {
        GtmDataLayer({
          'question': 'Postcode'
        });
        return {
          ...state,
          showSlide12: "hide",
          showSlide13: "show",
        };
      } else if (action.payload.clickedSlide.slide === "postcode") {
        GtmDataLayer({
          'question': 'Postcode'
        });
        return {
          ...state,
          showSlide12: "hide",
          showSlide13: "show",
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
    case "previousSlides": {
      if (action.payload.previousSlide.slide == "back02") {
        return {
          ...state,
          showSlide1: "show",
          showSlide2: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back03") {
        return {
          ...state,
          showSlide2: "show",
          showSlide3: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back04") {
        return {
          ...state,
          showSlide3: "show",
          showSlide4: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back05") {
        return {
          ...state,
          showSlide4: "show",
          showSlide5: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back06") {
        return {
          ...state,
          showSlide5: "show",
          showSlide6: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back07") {
        return {
          ...state,
          showSlide6: "show",
          showSlide7: "hide",
        };
      }
      else if (action.payload.previousSlide.slide == "back08") {
        return {
          ...state,
          showSlide7: "show",
          showSlide8: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back09") {
        return {
          ...state,
          showSlide8: "show",
          showSlide9: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back10") {
        return {
          ...state,
          showSlide9: "show",
          showSlide10: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back11") {
        return {
          ...state,
          showSlide10: "show",
          showSlide11: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back12") {
        return {
          ...state,
          showSlide11: "show",
          showSlide12: "hide",
        };
      } else if (action.payload.previousSlide.slide == "back13") {
        return {
          ...state,
          showSlide12: "show",
          showSlide13: "hide",
        };
      } else {
        return {
          ...state,
        };
      }
    }
    default: {
    }
  }
};

const FormLS_UB_1 = () => {
  const splitForm = useRef(null);
  const scrollfrmv2div = useRef([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const history = useHistory();
  const { saveDataIngestion,formDataIngestion, isLoading } = useDataIngestion();
  const { getBlacklist } = blacklist();
  const field = "pid";
  const { visitorParameters } = useContext(VisitorParamsContext);
  const { queryString } = useContext(QueryStringContext);
  const [previousName, setPreviousName] = useState("");
  const [previousAddressData, setPreviousAddressData] = useState({});
  const [blacklisted, setBlacklisted] = useState([]);
  const [skipAgNo, setSkipAgNo] = useState(false);

  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues
  } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const slideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setClickedSlide({ slide: e.target.name });
  };
  const slideChangeAddress = () => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setClickedSlide({ slide: "address" });
  };
  const previousSlideChange = (e) => {
    setTimeout(() => {
      scrollfrmv2div.current.scrollIntoView({
        behavior: "smooth",
        top: 0,
      });
    }, 50);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };
  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: "previousSlides", payload: { previousSlide } });
    }
  }, [previousSlide]);

  useEffect(() => {
    let responsesss = [];
    (async () => {
      const response = await getBlacklist();
      let dataLength = response.data.response.length;
      responsesss = response.data.response;
      //console.log(response.data.response);
      if (dataLength > 0) {
        setBlacklisted(response.data.response)
      }
    })();
  }, []);

  const formSubmit = () => {
    GtmDataLayer({
      'question': 'Email, Phone',
    });
    const form = splitForm.current;
    const values = getValues();
    const formData = values;

    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      const txtAddress3 = form["txtAddress3"].value;
      const txtDistrict = form["txtDistrict"].value;
      formData.txtDistrict = txtDistrict;
      formData.txtAddress3 = txtAddress3;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }

    //  ****Previous Name****
    formData.Previousname = previousName;

    //***previous_address ****/
    formData.previous_postcode = previousAddressData.postcode;
    formData.previous_address_line1 = previousAddressData.line_1;
    formData.previous_address_line2 = previousAddressData.line_2;
    formData.previous_address_line3 = previousAddressData.line_3;
    formData.previous_address_county = previousAddressData.county;
    formData.previous_address_town = previousAddressData.town;
    formData.previous_address_country = previousAddressData.country;
    formData.previous_district = previousAddressData.district;
    formData.previous_address_id = previousAddressData.prevAddressId;

    //Sending Questiionaire Data
    formData.question_2 = formData.employment;
    formData.question_3 = formData.dependent;
    formData.question_23 = formData.question_23;
    formData.question_28 = formData.YearOfPossession;
    formData.question_29 = formData.struggle;
    formData.question_30 = formData.filed;
    formData.question_38 = formData.question_38;
    formData.question_39 = formData.question_39;
    formData.question_40 = formData.question_40;
    formData.question_42 = formData.document;
    formData.question_45 = formData.question_45;
    formData.question_49 = formData.agreeYear;
    formData.skip_ag_no  = skipAgNo;
    //formData.question_41  = formData.question_41;
    //Questiionaire Data Complete

    formData.page_name = "LS_UB_1";
    formData.pCode_manual = form["pCode_EntryType"].value;
    console.log(formData);

    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("queryString", queryString);
      const formSUbmitResult = formDataIngestion(
        visitorParameters.visitor_parameters,
        formData,
        "split_form_submit",
        "LS_UB_1",
        queryString,
        visitorParameters.data
      );
      console.log('form submitted LS_UB_1');
      // if (formSUbmitResult.data.status === "Success") {

        let blacklistFlag = false;
        blacklisted.map((value, index) => {
          if ("email" == value.type && value.value == formData.txtEmail) {
            blacklistFlag = true;
          }

          if ("phone" == value.type && value.value == formData.txtPhone) {
            blacklistFlag = true;
          }
        });

        if (blacklistFlag) {
          window.scrollTo(0, 0);
          history.push(
            "/thankyou-bl?uuid=" + visitorParameters.visitor_parameters.uuid
          );
        } else {
          if (queryString != null) {
            console.log("first if");
            console.log(queryString);
            if (queryString.indexOf("&" + field + "=") !== -1) {
              console.log("s");
              if (EnvConstants.AppConversionPixel === "true") {
                console.log("if fb");
                history.push("/fbpixel?split_name=LS_UB_1"); // fb fixel firing
              } else {
                console.log("else fb");
                window.scrollTo(0, 0);
                history.push(
                 `/signature?split_name=LS_UB_1&uuid=${visitorParameters.visitor_parameters.uuid}`
                );
              }
            } else {
              console.log("another else fb");
              window.scrollTo(0, 0);
              history.push(
                `/signature?split_name=LS_UB_1&uuid=${visitorParameters.visitor_parameters.uuid}`
              );
            }
          } else {
            console.log("laset else  fb");
            window.scrollTo(0, 0);
            history.push(
              `/signature?split_name=LS_UB_1&uuid=${visitorParameters.visitor_parameters.uuid}`
            );
          }
        }
      // }
    }
  };

  return (
    <>
      <form
        ref={splitForm}
        name="split_form"
        id="user_form"
        method="POST"
        autoComplete="off"
      >
        <div ref={scrollfrmv2div}>
          <Months
            className={`col-12 top_minus_1 ${state.showSlide1}`}
            textHeadingLine1="Number of consecutive months borrowed from Lending Stream?"
            questionnaire={questinnare38}
            questionId={'38'}
            validation={register}
            clearErrors={clearErrors}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
            trigger={trigger}
            validationMsg={errors}
          />

          <Borrow
            className={`col-12 top_minus ${state.showSlide2}`}
            textHeadingLine1="How much would you typically borrow?"
            questionnaire={questinnare39}
            questionId={'39'}
            validation={register}
            clearErrors={clearErrors}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
            trigger={trigger}
            validationMsg={errors}
          />
          <YearOfPossession
            className={`col-12 top_minus ${state.showSlide3}`}
            textHeadingLine1="What year did you take out your loan with Lending Stream?"
            validation={register}
            clearErrors={clearErrors}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
            trigger={trigger}
            validationMsg={errors}
          />
          <Arrears
            className={`col-12 top_minus ${state.showSlide4}`}
            textHeadingLine1="Are you in arrears with Lending Stream?"
            questionId={'40'}
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          <EmploymentStatus
            className={`col-12 top_minus ${state.showSlide5}`}
            textHeadingLine1="when you secured finance,"
            textHeadingLine2="What was your employment status?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          <Dependents
            className={`col-12 top_minus ${state.showSlide6}`}
            textHeadingLine1="How many dependents did you have at that time?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          <Struggle
            className={`col-12 top_minus ${state.showSlide7}`}
            textHeadingLine1="Have you experienced any hardships or struggle in making the repayments to Lending Stream on time while still meeting all your other commitments?"
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          <Documements
            className={`col-12 top_minus ${state.showSlide8}`}
            textHeadingLine1="What supporting documentation are you able to provide to bolster your complaint with Lending Stream?"
            validationMsg={errors}
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
            trigger={trigger}
            setSkipAgNo={setSkipAgNo}
          />

          <Filed
            className={`col-12 top_minus ${state.showSlide9}`}
            textHeadingLine1="Have you, (or a company on your behalf), ever filed a claim with Lending Stream?"
            textHeadingLine2=""
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          <Deed
            className={`col-12 top_minus ${state.showSlide10}`}
            textHeadingLine1="Are you currently subject to Bankruptcy or Trust deed?"
            questionId={'23'}
            validation={register}
            slideChange={slideChange}
            getValues={getValues}
            backClick={previousSlideChange}
          />

          <PersonalDetails
            className={`col-12 top_minus ${state.showSlide11}`}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            getValues={getValues}
            splitForm={splitForm}
            slideChange={slideChange}
            slideChangeAddress={slideChangeAddress}
            backClick={previousSlideChange}
            setError={setError}
            setPreviousName={setPreviousName}
          />

          <PostCode
            className={`col-12 top_minus ${state.showSlide12}`}
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            getValues={getValues}
            splitForm={splitForm}
            scrollView={scrollfrmv2div}
            slideChange={slideChange}
            backClick={previousSlideChange}
            setPreviousAddressData={setPreviousAddressData}
          />

          <ContactDetails
            validation={register}
            validationMsg={errors}
            trigger={trigger}
            clearErrors={clearErrors}
            setError={setError}
            className={`col-12 top_minus ${state.showSlide13}`}
            getValues={getValues}
            formSubmit={formSubmit}
            backClick={previousSlideChange}
          />
        </div>
      </form>
    </>
  );
};

export default FormLS_UB_1;