import React from "react";

 const useValidDOB =()=>{
    const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
      };
      
      const isValidDayInMonth = (day, month, year) => {
        const daysInMonth = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        return day >= 1 && day <= daysInMonth[month - 1];
      };
      
      const validateDateOfBirth = (dobday, dobmonth, dobyear) => {
        const monthNames = [
          "January", "February", "March", "April",
          "May", "June", "July", "August",
          "September", "October", "November", "December"
        ];
      
        const isValidMonth = monthNames.includes(dobmonth);
        const isValidYear = dobyear >= 1910;
      
        if (isValidMonth && isValidYear && isValidDayInMonth(dobday, monthNames.indexOf(dobmonth) + 1, dobyear)) {
          return true; 
        } else {
          return false;
        }
      };

      return { isLeapYear, isValidDayInMonth, validateDateOfBirth };
};
export default useValidDOB;
