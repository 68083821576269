import React, { useState } from "react";
import ProgressBar from './ProgressBar';
import RadioButton from '../../../UI/RadioButton';

const Slide7 = ({
  className,
  slideChange,
  validation,
  trigger,
  clearErrors,
  getValues,
  setError,
  backClick
}) => {
  const [checked1, setChecked1] = useState('');
  const [checked2, setChecked2] = useState('');

  const nextSlide = async (e) => {
    if (e.target.value == 13) {
      setChecked1('activeclass');
    } else {
      setChecked1('');
    }

    if (e.target.value == 14) {
      setChecked2('activeclass');
    } else {
      setChecked2('');
    }

    slideChange(e);
  };
  return (
    <>
      <div id="slide7" className={`col-12 top_minus ${className}`}>
        <ProgressBar value={45} />
        <h3>At the time you secured finance… </h3>

        <div className="mb-3" id="slide-D">
          <h4>Did MoneyBarn then verify those details?</h4>

          <RadioButton
            htmlFor="previously"
            value="13"
            tabIndex="2"
            id="securedFinance-1"
            name="securedFinance"
            className="radio-button"
            labelName="Yes"
            labelClassName={`option_but next-D ${checked1}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <RadioButton
            htmlFor="previously"
            value="14"
            tabIndex="2"
            id="securedFinance-2"
            name="securedFinance"
            className="radio-button"
            labelName="No"
            labelClassName={`option_but next-D ${checked2}`}
            onClick={nextSlide}
            validation={validation()}
          />

          <p className="text-center">
            <a href="#" className="back-C" id="back06" name="back06" onClick={backClick}>
              {'<< Previous'}
            </a>
          </p>
        </div>

      </div>
    </>
  );
};

export default Slide7;