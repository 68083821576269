import React, { useState } from "react";
import TextField from "../../../UI/TextField";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const IncomeExpenditure = ({
  questArr,
  reff,
  display,
  validation,
  validationMsg,
  getValues,
  trigger,
  saveIncomeExpenditure,
  splitForm,
}) => {
  const [question57Result, setQuestion57Result] = useState(false);
  const [question58Result, setQuestion58Result] = useState(false);

  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const [checkedQuestionThree, setCheckedQuestionThree] = useState("unchecked");
  const [checkedQuestionFour, setCheckedQuestionFour] = useState("unchecked");
  const values = getValues();
  const questionId = questArr.questionId ? questArr.questionId : 0;

  const selectClick = async (e) => {
    e.preventDefault();
    let errorFlag = 0;
    let question31Result = await trigger("question_31");
    if (!question31Result) {
      errorFlag = 1;
      return false;
    }

    let question32Result = await trigger("question_32");
    if (!question32Result) {
      errorFlag = 1;
      return false;
    }

    let question33Result = await trigger("question_33");
    if (!question33Result) {
      errorFlag = 1;
      return false;
    }

    let question57Result = splitForm.current["question_57"].value;
    if (question57Result == "") {
      errorFlag = 1;
      setQuestion57Result(true);
      return false;
    }

    let question58Result = splitForm.current["question_58"].value;
    if (question58Result == "") {
      errorFlag = 1;
      setQuestion58Result(true);
      return false;
    }

    if (errorFlag === 0) {
      saveIncomeExpenditure(e);
    }
  };

  const handleChange = (e) => {
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    if (e.target.value !== "") {
      e.target.value = "£" + e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const labelclickedLoan = (e) => {
    if (e.target.value === "151") {
      setCheckedQuestionOne("checked_option_but");
      setCheckedQuestionTwo("unchecked");
    }
    if (e.target.value === "152") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked_option_but");
    }
    setQuestion57Result(false);
  };

  const labelclicked = (e) => {
    if (e.target.value === "153") {
      setCheckedQuestionThree("checked_option_but");
      setCheckedQuestionFour("unchecked");
    }
    if (e.target.value === "154") {
      setCheckedQuestionThree("unchecked");
      setCheckedQuestionFour("checked_option_but");
    }
    setQuestion58Result(false);
  };

  return (
    <>
      <div style={{ display: display }}>
        <div className="question animated fadeInUp" ref={reff}>
          <h3>
            <b>{questArr.question}</b>
          </h3>
          <p className="text-center text-success">
            {" "}
            Please confirm the following MONTHLY income and expenditure details as{" "}
            <b>
              <u>at the time of the Loan finance</u>
            </b>
            &nbsp;as accurately as possible:-
          </p>

          <div className="input-group mb-3">
            <span className="input-group-text input-mobile" id="basic-addon1">
              My monthly income, (after tax), was
            </span>
            <TextField
              type="tel"
              className="form-control"
              placeholder="My monthly income, (after tax), was"
              name="question_31"
              onChange={handleChange}
              validation={validation({
                required: "Please Enter Monthly Income",
                pattern: {
                  value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                  message: "Monthly Income is Invalid. Please Recheck",
                },
              })}
              validationMsg={validationMsg.question_31 && validationMsg.question_31.message}
            ></TextField>
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text input-mobile" id="basic-addon1">
              My monthly mortgage / rent was
            </span>
            <TextField
              type="tel"
              className="form-control"
              placeholder="My monthly mortgage / rent was"
              name="question_32"
              onChange={handleChange}
              validation={validation({
                required: "Please Enter MortgageRent Expense",
                pattern: {
                  value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                  message: "MortgageRent Expense is Invalid. Please Recheck",
                },
              })}
              validationMsg={validationMsg.question_32 && validationMsg.question_32.message}
            ></TextField>
          </div>

          <div className="input-group mb-3">
            <span className="input-group-text input-mobile" id="basic-addon1">
              My monthly car finance was
            </span>
            <TextField
              type="tel"
              className="form-control"
              placeholder="My monthly car finance was"
              name="question_33"
              onChange={handleChange}
              validation={validation({
                required: "Please Enter Car finance",
                pattern: {
                  value: /^[ A-Za-z0-9_@./#&+-£]*$/,
                  message: "Car finance is Invalid. Please Recheck",
                },
              })}
              validationMsg={validationMsg.question_33 && validationMsg.question_33.message}
            ></TextField>
          </div>

          <div className="input-group mb-3">
            <fieldset className="form-box2 second_box">
              <legend className="title"> Did you have any monthly Loan / Credit Card Expenses?</legend>
              <div className="mx-2">
                <div className="row">
                  <div className="col-sm-6">
                    <RadioButtonQuest
                      key="YES"
                      className="radio-button"
                      labelName="Yes"
                      labelClassName={`option_but_2 next-2 ${checkedQuestionOne}`}
                      onClick={labelclickedLoan}
                      value="151"
                      name="question_57"
                      id="Yes57"
                      dataQuestId={57}
                      dataAnsId={151}
                      dataAnsLabel="Yes"
                    />
                  </div>
                  <div className="col-sm-6">
                    <RadioButtonQuest
                      key="NO"
                      className="radio-button"
                      labelName="No"
                      labelClassName={`option_but_2 next-2 ms-4 ${checkedQuestionTwo}`}
                      onClick={labelclickedLoan}
                      value="152"
                      name="question_57"
                      id="No57"
                      dataQuestId={57}
                      dataAnsId={152}
                      dataAnsLabel="No"
                    />
                  </div>
                </div>
              </div>

              {question57Result && <span className="error_msg">Please Select an Item</span>}
            </fieldset>
          </div>

          <div className="input-group mb-3">
            <fieldset className="form-box2 second_box">
              <legend className="title first">
                {" "}
                Did you have any other monthly expenses not mentioned above (betting, groceries, takeaways etc)
              </legend>

              <div className="mx-2">
                <div className="row">
                  <div className="col-sm-6">
                    <RadioButtonQuest
                      key="YES"
                      className="radio-button"
                      labelName="Yes"
                      labelClassName={`option_but_2 next-2 ${checkedQuestionThree}`}
                      onClick={labelclicked}
                      value="153"
                      name="question_58"
                      id="yes58"
                      dataQuestId={58}
                      dataAnsId={153}
                      dataAnsLabel="Yes"
                    />
                  </div>
                  <div className="col-sm-6">
                    <RadioButtonQuest
                      key="NO"
                      className="radio-button"
                      labelName="No"
                      labelClassName={`option_but_2 next-2 ms-4 ${checkedQuestionFour}`}
                      onClick={labelclicked}
                      value="154"
                      name="question_58"
                      id="No58"
                      dataQuestId={58}
                      dataAnsId={154}
                      dataAnsLabel="No"
                    />
                  </div>
                </div>
              </div>
            </fieldset>

            {question58Result && <span className="error_msg">Please Select an Item</span>}
          </div>

          <div className="col-xl-12 col-lg-12 text-center p-0">
            <button
              type="button"
              className="btn-next vanquis-next"
              onClick={selectClick}
              data-quest-id={questionId}
              data-ans-id={null}
              data-ans-label={null}
              name="question_36"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default IncomeExpenditure;
