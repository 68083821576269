import React,{useState,useEffect} from 'react';
import RadioButtonQuest from '../../../UI/RadioButtonQuest';
import Modal from 'react-bootstrap/Modal';

const QuestionnaireModal = ({questArr,popDisplay,onModalClick}) => {
    const countString = {
        0:'A',
        1:'B',
        2:'C',
        3:'D',
        4:'E',
        5:'F',
        3:'D'
    }
    const handleClose = () => setShow(false);
    
    const questAnswers = questArr.answers.map((answer,index) => {
        return (
                <RadioButtonQuest  
                    key={answer.value} 
                    className="radio"
                    labelName={answer.label}
                    labelClassName="br radioa"
                    labelCountString={countString[index]}
                    value={answer.value}
                    name={questArr.questionId}
                    id={answer.value}
                    onLabelClick={onModalClick}
                    onClick={handleClose}
                />)
    })
    // let modalShow = (popDisplay)?'block':'none;'

    const [show, setShow] = useState(false);

    
    // const handleShow = () => setShow(true);

    useEffect(()=>{
        setShow(popDisplay);
    },[popDisplay])
    return (
        // <div className="modal fade animated fadeInUp" style={{display:modalShow}}  tabIndex="-1" role="dialog" aria-labelledby="formoptionLabel" aria-hidden="true">
        //     <div className="modal-dialog" role="document">
        //         <div className="modal-content">
        //         <div className="modal-header text-center">
        //             <h5 className="modal-title" id="formoptionLabel">{ questArr.question }</h5>
        //         </div>
        //         <div className="modal-body">
        //             <div className="modalform form-part">
        //             <form>
        //                 <div className="form-group">
        //                     {questAnswers}
        //                 </div>
        //             </form>
        //             </div>
        //         </div>
        //         </div>
        //     </div>
        // </div>
            <Modal show={show} onHide={handleClose} dialogClassName="modalReact">
                <Modal.Header closeButton>
                <Modal.Title>{ questArr.question }</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modalform form-part">
                        <form>
                            <div className="form-group">
                                {questAnswers}
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
       
    )
}

export default QuestionnaireModal;