import React from 'react';

const QuestionnaireLoder = ({showUserName,showLoad}) => {
	return (
		<>
			<section className={`loading_section ${showLoad}`}>
				<div className="questionspart">
					<div className="container">
						<div className="row">
							<div className="offset-xl-2 col-xl-8 offset-lg-2 col-lg-8 offset-md-2 col-md-8">
								<div className="space text-center">
									
									<img src="/assets/img/loader-review2.gif" alt=""/>

									{/* <p className="htext">
										<strong>
										{showUserName} we need a few more details before we can proceed.
										</strong>
									</p> */}

									{!showUserName && (
										<img src="/assets/img/loader-review2.gif" alt=""/>
									)} 
									
									{showUserName && (
									<p className="htext">
										<strong>
										{showUserName} we need a few more details before we can proceed.
										</strong>
									</p>
									)}
									
									<p>(Don't worry, it will only take a minute.)</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}

export default QuestionnaireLoder;