import React, { useState, useEffect } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";
import TextArea from "../../../UI/TextArea";
const DidYouAware = ({
    onSelectAnswer,
    saveInputAns,
    splitForm,
    validation,
    validationMsg,
    backClick,
    setError
}) => {
    const [checkedOne, setCheckedOne] = useState("unchecked");
    const [checkedTwo, setCheckedTwo] = useState("unchecked");
    const [checkedThree, setCheckedThree] = useState("unchecked");
    const [questResult, setQuestResult] = useState(false);
 
 
    const selectedClick = async (e) => {
        console.log(e.target.name);
        e.preventDefault();
        let errorFlag = 0;
        let optionselect = splitForm.current["didYouAware"].value;
        console.log(optionselect);
        if (optionselect == '') {
            errorFlag = 1;
            setQuestResult(true);
            return false;
        } else if(optionselect == "244") {
            let specify = splitForm.current["awareSpecify"].value.trim();
            if (specify == "" || specify.length < 3) {
                setError("awareSpecify", {
                  type: "manual",
                  message: "Please Enter Valid Description",
            });
                return false;   
            }
            saveInputAns(e);
        } else {
            onSelectAnswer(e);
        }
    }
 
    useEffect(() => {
        if (checkedThree == "checked") {
            const textarea = document.getElementById('awareTxtArea');
            if (textarea) {
                textarea.focus();
            }
        }
    }, [checkedThree]);
 
 
    const labelclicked = async (e) => {
        if (e.target.value === "242") {
            setCheckedOne("checked");
        } else {
            setCheckedOne("unchecked");
        }
 
        if (e.target.value === "243") {
            setCheckedTwo("checked");
        } else {
            setCheckedTwo("unchecked");
        }
 
        if (e.target.value === "244") {
            setCheckedThree("checked");
        } else {
            setCheckedThree("unchecked");
        }
        setQuestResult(false);
    }
 
    return (
        <>
            <div className={`question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2`}>
                <div className="row">
                    <div className="col-lg-12 mb-2">
                        <div className="p-0 m-0 doculist row">
                            <div className="col-md-4 mb-2">
                                <div className="optionbtn ">
                                    <RadioButtonQuest
                                        name="didYouAware"
                                        id="242"
                                        labelClassName={`label2 ${checkedOne}`}
                                        value="242"
                                        labelName="When TMS Legal contacted me"
                                        onClick={labelclicked}
                                        dataQuestId={85}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="optionbtn">
                                    <RadioButtonQuest
                                        name="didYouAware"
                                        id="243"
                                        labelClassName={`label2 ${checkedTwo}`}
                                        value="243"
                                        labelName="I saw their ad online"
                                        onClick={labelclicked}
                                        dataQuestId={85}
                                    />
                                </div>
                            </div>
                            <div className="col-md-4 mb-2">
                                <div className="optionbtn">
                                    <RadioButtonQuest
                                        name="didYouAware"
                                        id="244"
                                        labelClassName={`label2 ${checkedThree}`}
                                        value="244"
                                        labelName="Other"
                                        onClick={labelclicked}
                                        dataQuestId={85}
                                    />
                                </div>
                           </div>
                        </div>
                        {questResult && (
                            <span className="error_msg m-0 mt-2">Please select an option</span>
                        )}
                    </div>
                    {checkedThree == "checked" && (
                        <>
                            <div className="px-4 mt-3">
                                <p className="pb-0 mb-1">Please Specify : </p>
                                <TextArea
                                    type="textarea"
                                    className="form-control"
                                    name="awareSpecify"
                                    id="awareTxtArea"
                                    row={3}
                                    validation={validation({
                                        required: "Please Enter the Description",
                                        minLength: {
                                            value: 3,
                                            message: "Please Enter Valid Description",
                                        },
                                    })}
                                    validationMsg={
                                        validationMsg.awareSpecify &&
                                        validationMsg.awareSpecify.message
                                    }
                                />
                            </div>
                        </>
                    )}
                    <div className="col-lg-12 mb-2 mt-2">
                        <button className=" btn-comm2 py-3 w-100 nextBtn"
                            name="didYouAware"
                            id="nextscroll"
                            type="button"
                            onClick={selectedClick}
                            data-quest-id={85}
                        >
                            Next &#8594;
                        </button>
                    </div>
                    <p className="text-center bck-marwid">
                       <button
                       name="card_over_six_years"
                       className="btn btn-secondary backbtn1a"
                       id=""
                       value=""
                       type="button"
                       onClick={backClick}
              
                       >Go Back</button>
                       </p>
                </div>
            </div>
        </>
    )
}
export default DidYouAware;