import React, {useContext} from "react";
import { Api,CommonEmailValidateApi ,CommonEmailValidateApiTimeDelay} from "../api/Api";
import * as EnvConstants from "../Constants/EnvConstants";
import { useErrorWebhook } from "./useErrorWebhook";
import VisitorParamsContext from "../Contexts/VisitorParams";


export const useEmailValidation = () => {

  const { visitorParameters } = useContext(VisitorParamsContext);
  const uuid=visitorParameters.visitor_parameters.uuid;
  const { ErrorWebhookTrigger } = useErrorWebhook();
  
  const emailValidation = async (email) => {
    let emailVal = 1;

    //  const getValidation = await CommonEmailValidateApiTimeDelay.get(
    //   "sleep?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&Email='+email,
    //   {}

    const getValidation = await CommonEmailValidateApi.post(
      "api/validate?ProjectCode=" + EnvConstants.AppAdtopiaUPID + '&Environment=' + EnvConstants.AppEnv + '&Email='+email,
      {}
    ).catch(function (error) {
      const erroMessage = {
        serviceName: 'EMAIL VALIDATION',
        errorReason: error.message,
        errorCode: error.code,
        uuid: uuid
      }
      emailVal = 0;
      ErrorWebhookTrigger(erroMessage);
    });
    if (emailVal == 0) {
      let mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      if (!email.match(mailformat)) {
        return {
          data: {
            status_code: 0
          }
        }
      } else {
        return {
          data: {
            status_code: 1
          }
        }
      }
    } else {
      return getValidation;
    }
  };
  // const emailValidation = async (email) => {
  //   const getValidation = await Api.get(
  //     "api/v1/get-email-validation?email=" + email,
  //     {}
  //   );
  //   return getValidation;
  // };
  return { emailValidation };
};
