import { useContext, useEffect, useState } from "react";
import CheckBoxWithInput from "./CheckBoxWithInput";
import ReducerContext from "../../../../Contexts/ReducerContext";

const IncomeAndExpV3 = ({
  backClick,
  checklistOptions,
  setChecklistOptions,
  inputListValues,
  setInputListValues,
  saveIncomeAndExpense,
}) => {
  const [state, setState] = useState({
    checkboxes: {},
    inputs: {},
    errors: {},
  });

  const [selectOneError, setSelectOneError] = useState(false);
  const [checkedCheckBoxCount, setCheckedCheckBoxCount] = useState(1);
  const display = useContext(ReducerContext);
  const handleCheckboxChange = (id) => {
    
    setSelectOneError(false);
    setState((prevState) => ({
      ...prevState,
      errors: {
        ...prevState.errors,
        [id]: false,
      },
    }));
    setState((prevState) => ({
      ...prevState,
      checkboxes: {
        ...prevState.checkboxes,
        [id]: !prevState.checkboxes[id],
      },
    }));
    setState((prevState) => ({
      ...prevState,
      inputs: {
        ...prevState.inputs,
        [id]: "",
      },
    }));

  };

  const handleInputChange = (id, value) => {
    /* const errorStatus = (value == '£0' || value == '') ? true : false;
    setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [id]: errorStatus,
        },
    })); */
    setState((prevState) => ({
      ...prevState,
      inputs: {
        ...prevState.inputs,
        [id]: value,
      },
    }));

  };

  const checkboxes = [
    {
      label: "Mortgage / Rent",
      value: "229",
      placeholder: "My monthly Mortgage / Rent were:",
      errorMsg: "Please Enter Monthly Mortgage / Rent",
    },
    {
      label: "Utility Bills",
      value: "230",
      placeholder: "My monthly TOTAL utility bills were",
      errorMsg: "Please Enter Monthly TOTAL utility bills",
    },
    {
      label: "Loans",
      value: "231",
      placeholder: "My monthly loan repayments were:",
      errorMsg: "Please Enter Monthly loan repayments",
    },
    {
      label: "Credit Cards",
      value: "232",
      placeholder: "My monthly credit card repayments were:",
      errorMsg: "Please Enter Monthly credit card repayments",
    },
    {
      label: "Car Finance",
      value: "233",
      placeholder: "My monthly car finance payments were:",
      errorMsg: "Please Monthly car finance payments",
    },
    {
      label: "Store Cards",
      value: "234",
      placeholder: "My monthly store card payments were:",
      errorMsg: "Please Enter Monthly store card payments",
    },
    {
      label: "Other",
      value: "235",
      placeholder: "My other monthly payment commitments were:",
      errorMsg: "Please Enter Other monthly payment",
    },
  ];

  const handleNext = (e) => {
    var checkedCheckBoxCount = 0;
    var checklistOptions = [];
    var inputListValues = [];

    Object.entries(state.checkboxes).forEach(([checkboxId, checkboxValue]) => {
      if (checkboxValue === true) {
        checkedCheckBoxCount += 1;
        Object.entries(state.inputs).forEach(([inputId, inputValue]) => {
          if (checkboxId === inputId) {
            var errorStatus = (inputValue == '£0' || inputValue == '') ? true : false;
            setState((prevState) => ({
              ...prevState,
              errors: {
                ...prevState.errors,
                [inputId]: errorStatus,
              },
            }));
            if (!errorStatus) {
              checklistOptions.push({ id: parseInt(checkboxId) });
              inputListValues.push([inputId, inputValue]);
            }
          }
        });
      }
    });
    setCheckedCheckBoxCount(checkedCheckBoxCount);
    if (checkedCheckBoxCount > 0) {
        if(inputListValues.length > 0 && checkedCheckBoxCount === inputListValues.length) {
          setSelectOneError(false);
          saveIncomeAndExpense(checklistOptions, inputListValues);
        } 
    }
    else  {
        setSelectOneError(true);
        return false;
    }
    
  
  };

  useEffect(() => {
    if (checkedCheckBoxCount > 0) {
      setSelectOneError(false);
    } else {
      setSelectOneError(true);
    }
  }, [checkedCheckBoxCount]);


  return (
    <>
      <div className={`radio-box animated fadeInUp space-top space-top2 ${display.showSlide5_3}`}
        id="slide-25">
        <h3>
          <b>
            When you took out the card, were you already paying monthly for one
            or more of the following:
          </b>
        </h3>
        <div className="col-lg-12 mb-2">
          {/* className="p-0 m-0 option-grid-iande" <p> */}
          <ul className="p-0 m-0 option-grid">
            {checkboxes.map(({ label, value, placeholder, errorMsg }) => (
              <>
                <li className="adjustbalewidfull mt-1">
                  <CheckBoxWithInput
                    key={value}
                    id={value}
                    label={label}
                    isChecked={state.checkboxes[value] || false}
                    inputValue={state.inputs[value] || ""}
                    error={state.errors[value] || false}
                    errorMsg={errorMsg}
                    placeholder={placeholder}
                    handleCheckboxChange={handleCheckboxChange}
                    handleInputChange={handleInputChange}
                  />
                </li>
              </>
            ))}
          </ul>
        </div>
        <div className="col-lg-12 mb-2 mt-2">
          {/* <span className={`error_msg ${showError}`} id="email_err"> */}
          {selectOneError && (
            <span className="error_msg">Please select at least one</span>
          )}
          <button
            className="btn-next"
            name="wereAlreadyPaying"
            type="button"
            id="WereAlreadyPayingId"
            data-quest-id={79}
            //disabled={btndisabled}
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default IncomeAndExpV3;
