import React, { useState } from "react";
import RadioButton from "../../../UI/RadioButtonQuest";
import TextField from "../../../UI/TextField";

const QuestionnairePrivatePlate = ({
  onselectAnswer,
  reff,
  display,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  saveSlide4,
  clsName,
}) => {


  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
            <h3>Have you registered a Private Plate?</h3>
                    <RadioButton
                      key="YES"
                      id="Yes12"
                      value="YES"
                      name="question_43"
                      className="radio"
                      labelName="Yes"
                      labelClassName="radioa"
                      labelCountString="a"
                      onClick={labelclicked}
                      dataAnsId={121}
                      dataQuestId={43}
                    />
                    <RadioButton
                      key="NO"
                      id="NO12"
                      value="NO"
                      name="question_43"
                      className="radio"
                      labelName="No"
                      labelClassName="radioa"
                      labelCountString="b"
                      onClick={labelclicked}
                      dataAnsId={122}
                      dataQuestId={43}
                    />
                    <RadioButton
                      key="NotSure"
                      name="question_43"
                      id="next-1"
                      className="radio"
                      labelName="Not sure"
                      labelClassName="radioa"
                      labelCountString="c"
                      onClick={labelclicked}
                      value="Not Sure"
                      dataQuestId={43}
                      dataAnsId={195}
                    />
                  </div>
                
              
   
       
     
    </>
  );
};

export default QuestionnairePrivatePlate;
