import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const LatePaymentLetters = ({
  clsName,
  onselectAnswer,
  reff
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");

  const optionSelected = async (e) => {
    if (e.target.value === "238") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      // setlatePay("238");
      onselectAnswer(e);
    }
    if (e.target.value === "239") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      // setlatePay("239");
      onselectAnswer(e);
    }
  }
  return (
    <>
      <div
        className={`radio-box animated fadeInUp space-top space-top2 ${clsName}`}
        id="slide-23"
        ref={reff}
      >
        <h3><b>
          Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company?
        </b></h3>
        <RadioButtonQuest
          className="radio-buttons"
          labelName="Yes"
          labelClassName="option_but next-2"
          onClick={(e) => optionSelected(e)}
          name="late_payment_letter"
          dataQuestId={82}
          dataAnsId={238}
          value="238"
          id="late_payment_letterYes"
        />
        <RadioButtonQuest
          className="radio-buttons"
          labelName="No"
          labelClassName="option_but next-2"
          onClick={(e) => optionSelected(e)}
          name="late_payment_letter"
          dataQuestId={82}
          dataAnsId={239}
          value="239"
          id="late_payment_letterNo"
        />
      </div>
    </>
  )
}
export default LatePaymentLetters;
