import { useState } from "react";
// import { useFormContext } from "react-hook-form";
import Select from "react-select";

const Banks = ({
  show,
  backClick,
  lenderList,
  SelectBank,
  setSelectBank,
  saveInputAns,
  OldCardbck,
  Latepymnentbck
}) => {
  const [BankValidationMsg, setBankValidationMsg] = useState("");

  // const { register, errors, getValues, setError } = useFormContext();

  let toggle; 
    if (OldCardbck == "237") {
      toggle = "oldCard";
    }else if(Latepymnentbck == "239"){
      toggle = "latePayment";
    }else{
      toggle = "describeProblem";
    }
  

  const customStyles = {
    control: (base) => ({
      ...base,
      //height: 50,
      minHeight: 30,
    }),
  };

  const nextSlide = async (e) => {
    let LengthSelectedBank = SelectBank.length;
    let ValueSelectedBank = SelectBank.value;

    if (LengthSelectedBank < 1 || ValueSelectedBank == "") {
      setBankValidationMsg("Please select your Bank");
    } else {
      localStorage.setItem("bud_lender", ValueSelectedBank);
      localStorage.setItem("lenderArray", JSON.stringify([SelectBank]));
      saveInputAns(e);
    }
  };

  function handleSelect(opt) {
    setSelectBank(opt);
    setBankValidationMsg("");
  }
  let BankList = [];

  if (lenderList.length > 0) {
    BankList = BankList.concat(
      lenderList
        .filter(function (val) {
          return (
            !val.display_name.endsWith("Card") &&
            !val.display_name.endsWith("Business") &&
            !val.display_name.endsWith("card") &&
            !val.display_name.endsWith("Cards")
          );
        })
        .map(function (val) {
          return {
            value: val.provider,
            label: val.display_name,
          };
        })
    );
  }

  return (
    <div id="bankNamediv" className={`question-content mt-0 scale-down-ver-center  px-3 py-lg-3 py-md-3 py-sm-2 py-2 ${show}`} >
        <div className="row">
            <div className="col-lg-8 mb-2 z-3">
              <Select
                placeholder="Select Bank"
                options={BankList}
                aria-label="Username"
                name="bankName"
                id="bankName"
                value={SelectBank}
                onChange={handleSelect}
                isSearchable={true}
                styles={customStyles}
                //isMulti
              />

              {BankValidationMsg != "" && (
                <span id="bank_err" className="error_msg">
                  {BankValidationMsg}
                </span>
              )}
            </div>
            <div className="col-lg-4 mb-2">
              <button
              type="button"
              className=" btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn " 
                id="submitLender"
                onClick={nextSlide}
                name="banks"
                data-quest-id={64}
              >
                Next &#8594; 
              </button>
            </div>
            <p className="text-center bck-marwid">
              <button
                name={toggle}
                className="btn btn-secondary backbtn1a"
                id=""
                value=""
                type="button"
                onClick={backClick}
              >
                Go Back
              </button>
            </p>
            {/* </div> */}
        </div>
    </div>
  );
};
export default Banks;
