import react from "react";
import TextArea from "../../../UI/TextArea";
const FinanceProblem = ({
   validationMsg,
   clearErrors,
   validation,
   trigger,
   getValues,
   backClick,
   saveInputAns,
   setfinanceProbPrev,
   setError,
}) => {

    const backClickFun = (e) => { 
        setfinanceProbPrev(false);
        backClick(e);
    }

   const optionSelected = async (e) => {
       let errorFlag = 0;
       let result = await trigger("question_84");
       if (!result) {
           errorFlag = 1;
           return false;
       } else {
        const fieldResult = getValues();
        var reason = fieldResult["question_84"].trim();
        if (reason == "" || reason.length < 3) {
            setError("question_84", {
              type: "manual",
              message: "Please Enter Valid Reason",
        });
            return false;
        } else {
        saveInputAns(e);
        }
       }
   };
   return (
       <>
           <div className="question-content mt-0 scale-down-ver-center px-3 py-lg-3 py-md-3 py-sm-2 py-2 ">
             
                   {/* <div className="col-lg-8 mb-2"> */}
                       <TextArea
                           type="textarea"
                           className="form-control"
                           name="question_84"
                           validation={validation({
                               required: "Please Enter the Reason",
                               minLength: {
                                   value: 3,
                                   message: "Please Enter Valid Reason",
                               },
                           })}
                           validationMsg={
                               validationMsg.question_84 &&
                               validationMsg.question_84.message
                           }
                       ></TextArea>
                   {/* </div> */}
                   <div className="col-lg-12 mb-2 mt-2">
                       <button
                           type="button"
                           className="qb14 btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn "
                           id="nextscroll"
                           name="problem"
                           onClick={optionSelected}
                           data-quest-id={84}
                       >
                           Next &#8594;
                       </button>
                   </div>
              
               <p className="text-center bck-marwid">
                   <button
                       name="difficulty"
                       className="btn btn-secondary backbtn1a"
                       id=""
                       value=""
                       type="button"
                       onClick={backClickFun}
                   >
                       Go Back
                   </button>
               </p>
           </div>
       </>
   );
};
export default FinanceProblem;





