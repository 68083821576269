import React, { useState, useRef, useReducer, useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import "../../assets/MB_UFGN_1/css/MB_UFGN_1_Questionnaire.scss";
import { FormData } from "../../Utility/FormData";
import { userInfo } from "../../Hooks/userInfo";
import { MonthNumberArray } from "../../Constants/Constants";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { useHistory } from "react-router-dom";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import QuestionnaireLoder from "../Includes/Layouts/MB_UFGN_1_Questionnaire/QuestionnaireLoder";
import QuestionnaireFooter from "../Includes/Layouts/MB_UFGN_1_Questionnaire/QuestionnaireFooter";
import QuestionnaireEmailConfirmation from "../Includes/Layouts/MB_UFGN_1_Questionnaire/QuestionnaireEmailConfirmation";
import QuestionnaireEmail from "../Includes/Layouts/MB_UFGN_1_Questionnaire/QuestionnaireEmail";
import QuestionnairePrivatePlate from "../Includes/Layouts/MB_UFGN_1_Questionnaire/QuestionnairePrivatePlate";
import QuestionnairePrivatePlateNum from "../Includes/Layouts/MB_UFGN_1_Questionnaire/QuestionnairePrivatePlateNum";
import QuestionnaireFinanceActive from "../Includes/Layouts/MB_UFGN_1_Questionnaire/QuestionnaireFinanceActive";
import QuestionnaireStillHave from "../Includes/Layouts/MB_UFGN_1_Questionnaire/QuestionnaireStillHave";
import QuestionnairEmployment from "../Includes/Layouts/MB_UFGN_1_Questionnaire/QuestionnairEmployment";
import QuestionnairDepandants from "../Includes/Layouts/MB_UFGN_1_Questionnaire/QuestionnairDepandants";
import QuestionnaireIncome from "../Includes/Layouts/MB_UFGN_1_Questionnaire/QuestionnaireIncome";
import { userMilestoneLive } from "../../Hooks/userMilestoneLive";
import QuestionnairEmployment_MB_NEW_OPT from "../Includes/Layouts/MB_UFGN_1_Questionnaire/QuestionnairEmployment_MB_NEW_OPT";
import { questinnare1} from "../../Constants/Questions";
import Questionnaire73 from "../Includes/Layouts/MB_UFGN_1_Questionnaire/Questionnaire73";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";


import DidReceiveIncome from "../Includes/Layouts/MB_UFGN_1_Questionnaire/DidReceiveIncome";
import CardMonthlyIncome from "../Includes/Layouts/MB_UFGN_1_Questionnaire/CardMonthlyIncome";
import DependentsV2 from "../Includes/Layouts/MB_UFGN_1_Questionnaire/DependentsV2";
import WereAlreadyPaying from "../Includes/Layouts/MB_UFGN_1_Questionnaire/WereAlreadyPaying";
import IncomeAndExpV2 from "../Includes/Layouts/MB_UFGN_1_Questionnaire/IncomeAndExpV2";
import {wereUalreadyPaying} from "../../Constants/Questions";


let OBStatementValue;
let showSlide1Condition;
const initialState = {
  showSlide1a: showSlide1Condition,
  showSlide_DidReceiveIncome: "hide",
  showSlide_CardMonthlyIncome: "hide",
  showSlide_DependentsV2: "hide",
  showSlide_WereAlreadyPaying: "hide",
  showSlide_IncomeAndExpV2: "hide",
  showSlide73: showSlide1Condition,
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide5: "hide",
  showSlide6: "hide",
  showSlide7: "hide",
  showSlide8: "hide",
  showSlide9: "hide",
  showSlide1:"hide",
  percentage: 0
};

const FormReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_SLIDES": {
      const { OBStatementValue } = action.payload;

      showSlide1Condition = (OBStatementValue !== "1")
      return {
        ...state,
        showSlide1a: showSlide1Condition ? "show" : "hide",
        showSlide1: showSlide1Condition ? "hide" : "show",
      };
      
    }
    case "showSlide": {

      if(action.payload.clickedSlide.slide == "slide_4") {
        return {
          ...state,
          showSlide1a: "hide",
          showSlide1: "show",
          percentage: 0
        };
      } else if (action.payload.clickedSlide.slide == "didReceiveIncome" && action.payload.clickedSlide.slide_value == "226") {
        return {
          ...state,
          showSlide_DidReceiveIncome: "hide",
          showSlide_CardMonthlyIncome: "show",
          percentage: 2
        };
      } else if (action.payload.clickedSlide.slide == "didReceiveIncome" && action.payload.clickedSlide.slide_value == "227") {
        return {
          ...state,
          showSlide_DidReceiveIncome: "hide",
          showSlide_DependentsV2: "show",
          percentage: 2
        };
      } else if (action.payload.clickedSlide.slide == "cardMonthlyIncome") {
        return {
          ...state,
          showSlide_CardMonthlyIncome: "hide",
          showSlide_DependentsV2: "show",
          // showSlide1: "show",
          percentage: 4
        };
      } else if (action.payload.clickedSlide.slide == "dependents") {
        GtmDataLayer({
          'question': 'dependents'
        });
        return {
          ...state,
          showSlide_DependentsV2: "hide",
          showSlide_WereAlreadyPaying: "show",
          // showSlide1: "show",
          percentage: 4
        };
      } else if (action.payload.clickedSlide.slide == "wereAlreadyPaying") {
        return {
          ...state,
          showSlide_WereAlreadyPaying: "hide",
          showSlide_IncomeAndExpV2: "show",
          // showSlide1: "show",
          percentage: 4
        };
      } else if (action.payload.clickedSlide.slide == "incmAndMonth") {
        return {
          ...state,
          showSlide_IncomeAndExpV2: "hide",
          showSlide2: "show",
          // showSlide1: "show",
          percentage: 4
        };
      }
      else if (action.payload.clickedSlide.slide == "question_73") {
        // GtmDataLayer({
        //   'question': 'email address'
        // });
        return {
          ...state,
          showSlide73: "hide",
          showSlide1: "show",
          percentage: 2
        };
      }
      else if (action.payload.clickedSlide.slide == "question_61") {
        GtmDataLayer({
          'question': 'email address'
        });
        return {
          ...state,
          showSlide1: "hide",
          showSlide3: "show",
          percentage: 4
        };
      }
      else if (action.payload.clickedSlide.slide == "question_61a") {
        GtmDataLayer({
          'question': 'email address'
        });
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
          percentage: 5
        };
      } else if (action.payload.clickedSlide.slide == "btn_qest_48") {
        GtmDataLayer({
          'question': 'email address'
        });
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide3: "show",
          percentage: 10

        };
      } else if (action.payload.clickedSlide.slide == "question_43") {
        GtmDataLayer({
          'question': 'private plate'
        });
        return {
          ...state,
          showSlide3: "hide",
          showSlide5: "show",
          percentage: 20
        };
      } else if (action.payload.clickedSlide.slide == "question_43a") {
        GtmDataLayer({
          'question': 'private plate'
        });
        return {
          ...state,
          showSlide3: "hide",
          showSlide4: "show",
          percentage: 25

        };
      } else if (action.payload.clickedSlide.slide == "btn_qest_44") {
        GtmDataLayer({
          'question': 'private plate'
        });
        return {
          ...state,
          showSlide4: "hide",
          showSlide5: "show",
          percentage: 35

        };
      }
      else if (action.payload.clickedSlide.slide == "stillactive") {
        GtmDataLayer({
          'question': 'still active'
        });
        return {
          ...state,
          showSlide5: "hide",
          showSlide6: "show",
          percentage: 50

        };
      }else if (action.payload.clickedSlide.slide == "stillVehicle") {
        GtmDataLayer({
          'question': 'have vehicle'
        });
        return {
          ...state,
          showSlide6: "hide",
          showSlide7: "show",
          percentage: 70

        };
      }
      else if (action.payload.clickedSlide.slide == "employment") {
        GtmDataLayer({
          'question': 'employment status'
        });

        return {
          ...state,
          showSlide7: "hide",
          showSlide8: "show",
          percentage: 80

        };
      }
      else if (action.payload.clickedSlide.slide == "dependent") {
        
        return {
          ...state,
          showSlide8: "hide",
          percentage: 95

        };
      }

      else {
        return {
          ...state,
        };
      }
    }
    default:
      return state;
  }
};

const MB_UFGN_1_Questionnaire = () => {
  const scrollRef = useRef([]);
  const splitForm = useRef(null);
  const query_string = localStorage.getItem("querystring");
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [clickedSlide, setClickedSlide] = useState([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const history = useHistory();
  const [splitNameForSkip, setSplitNameForSkip] = useState();
  const [splitName, setSplitName] = useState();
  const [showLoad, setShowLoad] = useState("show");
  const { getUserInfo } = userInfo();
  const { setFormData } = FormData();
  const [showUserName, setUserName] = useState();
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { isLoading, SaveQuestionnaire } = useQuestionnaire();
  const [userEmail, setUserEmail] = useState();
  const [lastClickQuest, setLastClickQuest] = useState(null);
  let [totalQuestArr, setTotalQuestArr] = useState([77, 78, 79, 80, 61, 26, 16, 2, 3, 43, 31, 36, 45, 46, 42,73]);
  const {  updateMilestone } = userMilestoneLive();
  
  const { updateMilestone: updateMilestoneNew } = useUpdateMilestone();


  const [checklistOptions, setChecklistOptions] = useState([]);

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
    const getUuid = () => {
      const visitorData = visitorParameters.visitor_parameters;
      if (visitorData != "") {
        var uuid = visitorData.uuid;
      } else {
        const query = new URLSearchParams(window.location.search);
        const query_uuid = query.get("uuid");
        const local_storage_uuid = localStorage.getItem("uuid");
        var uuid = query_uuid ? query_uuid : local_storage_uuid;
      }
      return uuid;
    };
    const uuid = getUuid();

    useEffect(() => {
      // Fetch values from localStorage
       OBStatementValue = localStorage.getItem("OBStatement");
    
      // Dispatch action to update slides based on localStorage values
      dispatch({
        type: "UPDATE_SLIDES",
        payload: { OBStatementValue },
      });
    }, []);

  useEffect(() => {
    if (formdata === undefined || formdata === null || formdata == "") {
      (async () => {
        const response = await getUserInfo(uuid);
        const set_formdata = setFormData(response);
        let dataLength = response.data.response.length;
        if (dataLength > 0) {
          const pageName = response.data.response[0].page_name
            ? response.data.response[0].page_name
            : " ";
          setSplitName(pageName);
          if (localStorage.getItem("split_name") != undefined && localStorage.getItem("split_name") != undefined) {
            setSplitNameForSkip(localStorage.getItem("split_name"));
          } else {
            setSplitNameForSkip(pageName);
          }
          const userName = response.data.response[0].first_name
            ? response.data.response[0].first_name +
            " " +
            response.data.response[0].last_name
            : " ";
          var userDobN = response.data.response[0].user_dob.split('/');
          var DobDayF = userDobN[0];
          var DobMonthF = userDobN[1];
          var DobYearF = userDobN[2];
    
      const timer = setTimeout(() => {
        setShowLoad("hide");
      }, 3000);
    }

    })();

  }else {
    const pageName = formdata.page_name;
    setSplitName(pageName);
    if (localStorage.getItem("split_name") != undefined && localStorage.getItem("split_name") != undefined) {
      setSplitNameForSkip(localStorage.getItem("split_name"));
    } else {
      setSplitNameForSkip(pageName);
    }
    const userName = formdata.txtFName + " " + formdata.txtLName;
    setUserName(userName);
    const dobMonth = formdata.DobMonth ? MonthNumberArray[formdata.DobMonth] : '';
    const timer = setTimeout(() => {
      setShowLoad("hide");
    }, 3000);
    setUserEmail(formdata.txtEmail);
  }
}, [splitName]);

  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    const valuesreg = getValues();
    let question_data = {};
    let flage = false;
    let validEmail = valuesreg["question_48"];
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);


    if (e.target.name == 'question_61' && selectedAns == '163') {
      setClickedSlide({ slide: "question_61a" });
    } else if (e.target.name == 'question_43' && selectedAns == '121') {
      setClickedSlide({ slide: "question_43a" });
      totalQuestArr.push(44);
    } else if(e.target.name == "didReceiveIncome" && selectedAns) {
      console.log(selectedAns);
      setClickedSlide({ slide: "didReceiveIncome", slide_value: selectedAns });
    }
    else {
      setClickedSlide({ slide: e.target.name });
      if(e.target.name == "dependent"){
        const milestone =  await updateMilestone(
          uuid,
          "questions",
          "live"
        );

        
        updateMilestoneNew(uuid,"questions","live");

        if (milestone.data.status == "Success") {
          localStorage.setItem("Questionnaire", 1);
          // history.push("/view_docs_MB_UFGN_1?uuid=" + visitorParameters.visitor_parameters.uuid);
          history.push("/preview_MB_UFGN_1?uuid=" + visitorParameters.visitor_parameters.uuid);
      }
      }
    }

    if (e.target.name == "btn_qest_48") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 48,
          option_id: selectedAns,
          answer_text: null,
          input_answer: validEmail,
        };
      }
    } else if (e.target.name == "btn_qest_44") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: "",
          input_answer: anslabel,
        };
      }
    }
    else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }

    if (flage) {
      storeQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
    
    
  }
  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    const valuesreg = getValues();
    let regPlate = valuesreg["question_44"];
    let cardMonthlyIncome = valuesreg["cardMonthlyIncome"];
    let dependents = valuesreg["dependents"];
    let flage = false;
    let question_data = {};

    if (selectedQuest == '44' && regPlate) {
      setClickedSlide({ slide: "btn_qest_44" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: regPlate,
      };
    } else if (selectedQuest == '78' && cardMonthlyIncome) {
      setClickedSlide({ slide: "cardMonthlyIncome" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: cardMonthlyIncome,
      };
    } else if (selectedQuest == '3' && dependents) {
      setClickedSlide({ slide: "dependents" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: dependents,
      };
    }

    if (flage) {
      storeQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));

    
    }
  }

  const saveSlide80 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
  
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
  
    const values = getValues();
    let slide_array = [];
  
    if (checklistOptions.some((option) => option.id === 228)) {
      let answer228 = splitForm.current["question_228_229"].value;
      slide_array.push(["228", answer228]);
    }
    if (checklistOptions.some((option) => option.id === 229)) {
      let answer229 = splitForm.current["question_228_229"].value;
      slide_array.push(["229", answer229]);
    }
    if (checklistOptions.some((option) => option.id === 230)) {
      let answer230 = splitForm.current["question_230"].value;
      slide_array.push(["230", answer230]);
    }
    if (checklistOptions.some((option) => option.id === 231)) {
      let answer231 = splitForm.current["question_231"].value;
      slide_array.push(["231", answer231]);
    }
    if (checklistOptions.some((option) => option.id === 232)) {
      let answer232 = splitForm.current["question_232"].value;
      slide_array.push(["232", answer232]);
    }
    if (checklistOptions.some((option) => option.id === 233)) {
      let answer233 = splitForm.current["question_233"].value;
      slide_array.push(["233", answer233]);
    }
    if (checklistOptions.some((option) => option.id === 234)) {
      let answer234 = splitForm.current["question_234"].value;
      slide_array.push(["234", answer234]);
    }
    if (checklistOptions.some((option) => option.id === 235)) {
      let answer235 = splitForm.current["question_235"].value;
      slide_array.push(["235", answer235]);
    }

  
    var question_data = {
      slide_80: ["80",JSON.stringify(slide_array)],
    };
  
    setClickedSlide({ slide: "incmAndMonth" });
    storeQuestionnire(question_data);
  
    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
    if (existingIndex !== -1) {
      _existingEntries.splice(existingIndex, 1);
    }
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  const saveSlide4 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");

    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    //var answer34 = splitForm.current["question_34"].value;
    var answer35 = splitForm.current["question_35"].value;

    var slide_array = [
      ["31", answer31],
      ["32", answer32],
      ["33", answer33],
      //["34", answer34],
      ["35", answer35],
    ];

    var question_data = {
      slide_4: slide_array,
    };
    setClickedSlide({ slide: "slide_4" });
    storeQuestionnire(question_data);

    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };


  const storeQuestionnire = async (question_data) => {
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      query_string,
      formdata
    );
  };

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    localStorage.removeItem('questionData');
  }, []);


  const handleDownClick = () => {
    scrollRef.current[lastClickQuest + 2]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    setLastClickQuest(lastClickQuest + 1);
  };
  const handleUpClick = () => {
    scrollRef.current[lastClickQuest]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    setLastClickQuest(lastClickQuest - 1);
  };


  return (
    <>
      <GetVisitorsParams />
      <div className="MB_UFGN_1_Questionnaire">
        <QuestionnaireLoder showUserName={showUserName} showLoad={showLoad} />
        <section
          className="formsection dis_none"
          style={
            showLoad === "hide" ? { display: "block" } : { display: "none " }
          }
        >
          <div className="container">
            <div className="row">
              <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12 space-top">
                <div className="radio-box animated fadeInUp">
                  <form
                    ref={splitForm}
                    name="split_form"
                    id="user_form"
                    method="POST"
                    autoComplete="off"
                  >
                    <QuestionnaireIncome
                      clsName={`space-top2 ${state.showSlide1a}`}
                      //reff={(el) => (scrollRef.current[31] = el)}
                      questArr={questinnare1}
                      saveSlide4={saveSlide4}
                      name="question_31_35"
                      btnName="slide_4"
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />

                    <DidReceiveIncome
                      clsName={`${state.showSlide_DidReceiveIncome}`}
                      reff={(el) => (scrollRef.current[77] = el)}
                      onselectAnswer={onselectAnswer}
                      /* validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError} */
                    />

                    <CardMonthlyIncome
                      clsName={`${state.showSlide_CardMonthlyIncome}`}
                      reff={(el) => (scrollRef.current[78] = el)}
                      saveInputAns={saveInputAns}
                      validation={register}
                      clearErrors={clearErrors}
                      trigger={trigger}
                      validationMsg={errors}
                      getValues={getValues}
                      setError={setError}
                    />

                    <DependentsV2
                      clsName={`${state.showSlide_DependentsV2}`}
                      reff={(el) => (scrollRef.current[3] = el)}
                      saveInputAns={saveInputAns}
                      validation={register}
                      clearErrors={clearErrors}
                      trigger={trigger}
                      validationMsg={errors}
                      getValues={getValues}
                      setError={setError}
                    />

                    <WereAlreadyPaying
                      clsName={`${state.showSlide_WereAlreadyPaying}`}
                      reff={(el) => (scrollRef.current[79] = el)}
                      questArr={wereUalreadyPaying}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      checklistOptions={checklistOptions}
                      setChecklistOptions={setChecklistOptions}
                    />

                    <IncomeAndExpV2
                      clsName={`${state.showSlide_IncomeAndExpV2}`}
                      reff={(el) => (scrollRef.current[80] = el)}
                      questArr={wereUalreadyPaying}
                      saveSlide80={saveSlide80}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      checklistOptions={checklistOptions}
                      splitForm={splitForm}
                      setChecklistOptions={setChecklistOptions}
                    />
                    {/* <Questionnaire73
                      clsName={`${state.showSlide73}`}
                      reff={(el) => (scrollRef.current[73] = el)}
                      onselectAnswer={onselectAnswer}
                      
                    /> */}

                    <QuestionnaireEmailConfirmation
                      clsName={`${state.showSlide1}`}
                      reff={(el) => (scrollRef.current[61] = el)}
                      onselectAnswer={onselectAnswer}
                      userEmail={userEmail}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                    <QuestionnaireEmail
                      clsName={`${state.showSlide2}`}
                      reff={(el) => (scrollRef.current[48] = el)}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      onselectAnswer={onselectAnswer}
                      notSure={onselectAnswer}
                    />
                    <QuestionnairePrivatePlate
                      clsName={`${state.showSlide3}`}
                      reff={(el) => (scrollRef.current[43] = el)}
                      onselectAnswer={onselectAnswer}
                    />
                    <QuestionnairePrivatePlateNum
                      clsName={`${state.showSlide4}`}
                      reff={(el) => (scrollRef.current[44] = el)}
                      saveInputAns={saveInputAns}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      notSure={onselectAnswer}
                    />
                    <QuestionnaireFinanceActive
                      clsName={`${state.showSlide5}`}
                      reff={(el) => (scrollRef.current[26] = el)}
                      onselectAnswer={onselectAnswer}
                    />
                    <QuestionnaireStillHave
                      clsName={`${state.showSlide6}`}
                      reff={(el) => (scrollRef.current[16] = el)}
                      onselectAnswer={onselectAnswer}
                    />
                    {/* <QuestionnairEmployment
                      clsName={`${state.showSlide7}`}
                      reff={(el) => (scrollRef.current[2] = el)}
                      onselectAnswer={onselectAnswer}
                    /> */}

                    <QuestionnairEmployment_MB_NEW_OPT
                      clsName={`${state.showSlide7}`}
                      reff={(el) => (scrollRef.current[2] = el)}
                      onselectAnswer={onselectAnswer}
                    />
                    <QuestionnairDepandants
                      clsName={`${state.showSlide8}`}
                      reff={(el) => (scrollRef.current[3] = el)}
                      onselectAnswer={onselectAnswer}
                    />
                  </form>
                </div>
              </div>
            </div>
            <QuestionnaireFooter
              percentage={state.percentage}
              onDownClick={handleDownClick}
              onUpClick={handleUpClick}
            />
          </div>
        </section>
      </div>
    </>
  );
}
export default MB_UFGN_1_Questionnaire;