import React, { useState } from 'react';
import RadioButton from '../../../../UI/RadioButtonOutLabel';
import TextField from '../../../../UI/TextField';

const QuestionnaireFlpQ3 = ({
	questArr,
	onselectAnswer,
	reff,
	display,
	validation,
	validationMsg,
	getValues,
	trigger,
	setError,
	saveSlide4
}) => {
	const questionId = questArr.questionId ? questArr.questionId : 0;

	const labelclicked = async (e) => {
		onselectAnswer(e);
	};

	return (
		<>
			<div style={{ 'display': display }}>
				<div className="question animated fadeInUp" ref={reff} >
					<h3 style={{ color: "#444", textAlign: "center" }} className="mb-2">Have you registered a Private Plate?</h3>
					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 input-group mb-3">
						<RadioButton
							key="YES"
							id="Yes12"
							value="YES"
							name="question_43"
							className="radio-button"
							labelName="Yes"
							labelClassName="option_but next-2"
							onClick={labelclicked}
							dataAnsId={121}
							dataQuestId={43}
						/>
					</div>

					<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0 input-group mb-3">
						<RadioButton
							key="NO"
							id="NO12"
							value="NO"
							name="question_43"
							className="radio-button"
							labelName="No"
							labelClassName="option_but next-2"
							onClick={labelclicked}
							dataAnsId={122}
							dataQuestId={43}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default QuestionnaireFlpQ3;