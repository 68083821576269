import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';



const Complaint = ({ showstatus, hidepopup }) => {
    return (
        <>
            <div className="modal-custom-div">
                <Modal show={showstatus} onHide={hidepopup} dialog size="xl" ClassName="modal fade privacykl" 
                    aria-labelledby="example-custom-modal-styling-title" dialogClassName="privacykl" >
                    
                        <Modal.Header className="modal-header">
                            <Modal.Title  id="privacykellerLabel">COMPLAINTS PROCEDURE</Modal.Title>
                            <Button  className="close" onClick={hidepopup}>×</Button>
                        </Modal.Header>
                        <Modal.Body>
                 
          <div className="modal-body model-text">
            <h3>Complaints Procedure</h3>
            <ul>
              <li>We endeavour to provide a good quality service with efficiency and courtesy. It is our aim that our clients should be satisfied with all aspects of the work undertaken by us.</li>
              <li>We aim to resolve complaints as quickly and efficiently as possible while at the same time gaining a complete understanding of the client’s complaint points and addressing each one.</li>
              <li>A complaint can be made verbally or in writing. For example it can be by letter, telephone, e-mail or by any other contact medium we have available.</li>
            </ul>
            <ul>
              <li>Our contact details are:</li>
              <li>By post: TMS Legal Ltd,&nbsp;1 Poole Road, Bournemouth BH2 5QQ</li>
              <li>By email: <a href="mailto:info@moneysolicitor.com">info@moneysolicitor.com</a>
              </li>
              <li>By phone: 0800 0073 777</li>
            </ul>
            <ul>
              <li>Once a complaint has been received a letter or electronic acknowledgement of your complaint will be sent within <strong>5 business days</strong>. This acknowledgment will include the name of the <strong>manager</strong> responsible for investigating your complaint. </li>
              <li>Within 8 weeks of the complaint being received we will send either a final response letter addressing all your complaint points, or a holding response advising you as to why the complaint has not been resolved.</li>
              <li>If a holding response is sent an indication will be given as to when our final response letter will be sent to you.</li>
              <li>Within 8 weeks from the date of receipt of your complaint we aim to send you a final response letter addressing all your complaint points.</li>
              <li>If we have not resolved the complaint to your satisfaction you have the right to complain to the Legal Ombudsman, an independent complaints body established under the Legal Services Act 2007.</li>
              <li>You have 6 months from the date of our final response letter to complain to the Legal Ombudsman, whose details are set out below.</li>
            </ul>
            <ul>
              <li>By post: P.O Box 6806, Wolverhampton, WV1 9WJ</li>
              <li>By email: <a href="mailto:enquiries@legalombudsman.org.uk">enquiries@legalombudsman.org.uk</a>
              </li>
              <li>By phone: 0300 555 0333</li>
            </ul>
          </div>
         
              
            
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={hidepopup}>
                                Close
                            </Button>
                        </Modal.Footer>
                  
                </Modal>
            </div>
        </>
    )
}

export default Complaint;



