import React, { useState } from "react";
import ProgressBar from './ProgressBar';
import RadioButton from '../../../UI/RadioButton';

const Slide3 = ({
  className,
  slideChange,
  validation,
  trigger,
  clearErrors,
  getValues,
  setError,
  backClick
}) => {
  const formValue = getValues();
  const [checked1, setChecked1] = useState('');
  const [checked2, setChecked2] = useState('');

  const nextSlide = async (e) => {
    if (e.target.value == 23) {
      setChecked1('activeclass');
    } else {
      setChecked1('');
    }

    if (e.target.value == 24) {
      setChecked2('activeclass');
    } else {
      setChecked2('');
    }
    slideChange(e);
  };
  return (
    <>
      <div id="slide3" className={`col-12 top_minus ${className}`}>
        <ProgressBar value={20} />

        <h3>Is this your vehicle <b>{formValue.carRegNo}</b></h3>

        <div className="mb-3 text-center">
          <RadioButton
            htmlFor="previously"
            value="23"
            tabIndex="1"
            id="yescomplaint"
            name="yourVehicle"
            className="radio-button"
            labelName="Yes"
            labelClassName={`option_but next03 ${checked1}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <RadioButton
            htmlFor="previously"
            value="24"
            tabIndex="1"
            id="nocomplaint"
            name="yourVehicle"
            className="radio-button"
            labelName="No"
            labelClassName={`option_but next03 ${checked2}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <p className="text-center"><a href="#" id="back02" name="back02" className="back02" onClick={backClick}>{'<< Previous'}</a></p>

        </div>

      </div>
    </>
  );
};

export default Slide3;