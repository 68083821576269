import React, { useContext, useState } from "react";
import { Salutation } from "../../../../Constants/Questions";
import SelectBox from "../../../UI/SelectBox";
import TextField from "../../../UI/TextField";
import  { DateOfBirth } from "../../../../Utility/DateOfBirth";
import InputButton from "../../../UI/InputButton";
import Anchor from "../../../UI/Anchor";
import ProgressBar from "../../Layouts/Split_1/ProgressBar";

const PersonalDetails = ({
   className,
   slideChange,
   validation,
   validationMsg,
   trigger,
   clearErrors,
   getValues,
   slideChangeAddress,
   splitForm,
   backClick,
   setError,
}) => {
   const form = splitForm.current;
   const question_3 = getValues();

   let bank = localStorage.getItem('bankName');
   const basicDetailsValidation = async (e) => {
      let errorFlag = 0;
      var lstSalutationResult = await trigger("lstSalutation");

      var txtFNameResult;
      var txtLNameResult;
      var lstDobDayResult;
      var lstDobMonthResult;
      var lstDobYearResult;
      if (lstSalutationResult) {
         txtFNameResult = await trigger("txtFName");
      }
      else {
         document.getElementById("lstSalutation").focus();
         return false;
      }
      if (txtFNameResult) {
         txtLNameResult = await trigger("txtLName");
      }
      else {
         document.getElementById("txtFName").focus();
         return false;
      }
      if (txtLNameResult) {
         lstDobDayResult = await trigger("DobDay");
      }
      else {
         document.getElementById("txtLName").focus();
         return false;
      }
      if (lstDobDayResult) {
         lstDobMonthResult = await trigger("DobMonth");
      }
      else {
         document.getElementById("DobDay").focus();
         return false;
      }
      if (lstDobMonthResult) {
         lstDobYearResult = await trigger("DobYear");
      }
      else {
         document.getElementById("DobMonth").focus();
         return false;
      }
      if(lstDobYearResult)
      {

      }else{
         document.getElementById("DobYear").focus();
      }
     

      if (
         !lstSalutationResult ||
         !txtFNameResult ||
         !txtLNameResult ||
         !lstDobDayResult ||
         !lstDobMonthResult ||
         !lstDobYearResult
      ) {
         errorFlag = 1;
      }
      if (errorFlag === 0) {    
            slideChange(e);    
      } else {
         return false;
      }
   };

   const namevalidate = async (e) => {
      if (e.target.value.trim() == "" || e.target.value.trim().length < 3){
         var name = (e.target.name == 'txtFName')? 'First Name' : 'Last Name';
        setError(e.target.name, {
          type: "manual",
          message: "Your " +name+ " is Invalid. Please Recheck",
        });
         if(e.target.value.trim().length == 0){
            e.target.value = e.target.value.trim();
         }
      } 
      return false;
    };

   return (
      <div id="slide-5" className={`slidetop_adjust ${className}`}>
         <ProgressBar value="70"/>
         <h4 className="text-center">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry.
         </h4>
         <h6 className="text-center mb-3">
            <strong>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</strong>
         </h6>
         <div className="input-section">    
            <SelectBox
               className="form-select  mb-3"
               OptionValue={Salutation}
               name="lstSalutation"
               id="lstSalutation"
               onChange={() => clearErrors("lstSalutation")}
               clearErrors={clearErrors}
               myRef={validation({ required: "Please Select Title" })}
               validationMsg={
                  validationMsg.lstSalutation &&
                  validationMsg.lstSalutation.message
               }
            ></SelectBox>
            <div className="input-group mb-3">
               <TextField
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  name="txtFName"
                  id="txtFName"
                  onBlur={namevalidate}
                  validation={validation({
                     required: "Please Enter First Name",
                     minLength: {
                        value: 3,
                        message: "Please Enter Valid First Name",
                     },
                     pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your Name is Invalid. Please Recheck",
                     },
                  })}
                  
               ></TextField>
               <span className="input-group-text privacyico">
                  <img src="/assets/img/privacy.png" alt="" />
               </span>
               {validationMsg.txtFName && (
                  <span
                     className="error_msg "
                     id="email_err"
                  >
                     {validationMsg.txtFName.message}
                  </span>
               )}
            </div>
            <div className="input-group mb-3">
               <TextField
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  name="txtLName"
                  id="txtLName"
                  onBlur={namevalidate}
                  validation={validation({
                     required: "Please Enter Last Name",
                     minLength: {
                        value: 3,
                        message: "Please Enter Valid Last Name",
                     },
                     pattern: {
                        value: /^[a-zA-Z\s]+$/,
                        message: "Your Name is Invalid. Please Recheck",
                     },
                  })}
                  
               ></TextField>
               <span className="input-group-text privacyico">
                  <img src="/assets/img/privacy.png" alt="" />
               </span>              
               {validationMsg.txtLName && (
                  <span
                     className="error_msg "
                     id="email_err"
                  >
                     {validationMsg.txtLName.message}
                  </span>
               )}           
            </div>        
            <DateOfBirth
               textDob="Date Of Birth"
               validation={validation}
               validationMsg={validationMsg}
            />
            <InputButton
               name="five"
               className="btn next-bttn"
               id=""
               value="Next"
               btnType="button"
               onClick={basicDetailsValidation}
               style={{ float: "none" }}
            />
         </div>
         <p className="text-center">
            <span
               className="back04 back-btn"
               name="back04"
               children="<< Previous"
               onClick={backClick}
         ></span></p>
      </div>
   );
};
export default PersonalDetails;
