import React, { useState } from "react";
import TextArea from "../../../../UI/TextArea";

const FinanceProblem = ({
    clsName,
    reff,
    display,
    validation,
    validationMsg,
    getValues,
    trigger,
    setError,
    onselectAnswer,
}) => {

    const optionSelected = async (e) => {
        let errorFlag = 0;
        let result = await trigger("finance");
        if (!result) {
            errorFlag = 1;
            return false;
        } else {
            const fieldResult = getValues();
            var reason = fieldResult["finance"].trim();
            if (reason == "" || reason.length < 3) {
            setError("finance", {
              type: "manual",
              message: "Please Enter Valid Reason",
            });
            return false;
            } else {
            onselectAnswer(e);
            }
        }
    };

    return (
        <>
            <div style={{ display: display }}>
                <div className={`radio-box question animated fadeInUp ${clsName}`} ref={reff}>
                    <h3>Please describe what really caused you to have problems in repaying / financially?</h3>
                    <div className="input-group mb-3">
                        <TextArea
                            type="textarea"
                            className="form-control"
                            name="finance"
                            validation={validation({
                                required: "Please Enter the Reason",
                                minLength: {
                                    value: 3,
                                    message: "Please Enter Valid Reason",
                                },
                            })}
                            validationMsg={
                                validationMsg.finance &&
                                validationMsg.finance.message
                            }
                        ></TextArea>
                    </div>
                    <div className="col-xl-12 col-lg-12 text-center p-0">
                        <button
                            type="button"
                            className="btn-next"
                            onClick={optionSelected}
                            data-quest-id={84}
                            data-ans-id={null}
                            data-ans-label={null}
                            name="btn_qest_84"
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default FinanceProblem;
