import React, { useEffect, useState } from 'react';
import SelectBox from '../../../UI/SelectBox';
import AnalyzeModal from './AnalayzeModal';
import {accCloseYear} from "../../../../Constants/Constants";



const Questionnaire1 = ({
	clsName,
	reff,
	// checkCarRegistration,
	validation,
	validationMsg,
	trigger,
	getValues,
	setError,
	page_name,
	uuid,
	split_name,
	saveInputAns,
	accClsYear
}) => {
	// const [accCloseyear,setAccCloseyear] = useState('');
	
    
   

    const [loader, showLoader] = useState(false);

	const handleClick = async (e) => {
		let Accountyear = await trigger("question_52");
		let errorFlag = 0;
		if(!Accountyear) {
		errorFlag=1;
		}
			if(errorFlag === 0){    
			// onselectAnswer(e);  
			saveInputAns(e);  
			} 
			else 
			{
				return false;
			}		
	};

	return (
		<>
			<div className={`radio-box animated fadeInUp ${clsName}`} ref={reff} >
				<h3><b>What year did you close the account</b></h3>
				<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-0">
					 <SelectBox
                                className="form-select form-control"
                                OptionValue={accClsYear}
                                name="question_52"
                                id="question_52"
                                myRef={validation({
                                    required: "Please Select Year"
                                })}
                                validationMsg={validationMsg.question_52 && validationMsg.question_52.message}
                            />
				</div>
				<div className="col-xl-12 col-lg-12 text-center p-0" >
					<button 
						type="button" 
						className="btn-next" 
						onClick={handleClick} 
						data-quest-id={52} 
						data-ans-id={null}
						data-ans-label={null}
						name="btn_qest_10"
						>{`NEXT`}</button>
				</div>
			</div>
			<AnalyzeModal modalClass={loader}/>
		</>
	)
}

export default Questionnaire1;