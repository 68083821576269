const full_url = window.location.href;
const query_string_arr = full_url.split("?");
var queryString = "";
if (query_string_arr != null) {
  if(query_string_arr[1] === undefined || query_string_arr[1] === "" || query_string_arr[1] === null){
    queryString = "";
  }else{
    queryString = query_string_arr[1];
  }  
}
const urlParams = new URLSearchParams(window.location.search);

const updateQueryString = (newQueryString) => {
  queryString = newQueryString;
  return queryString;
};

export { queryString, urlParams, updateQueryString };
