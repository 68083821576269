import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect";
import "../../../../assets/MB_UFGN_1/css/signature.scss";
import CheckBox2 from "./Checkbox2";
import { useForm } from "react-hook-form";
import { useQuestionnaire } from "../../../../Hooks/useQuestionnaire";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { queryString } from "../../../../Utility/QueryString";
import SignatureCanvas from "react-signature-canvas";
import CheckBox from "../../../UI/CheckBox";
import { useHistory } from "react-router-dom";
import { useSignature } from "../../../../Hooks/useSignature";
import { signCheckText } from "../../../../Constants/Constants";
import GetVisitorsParams from "../../../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../../../Utility/CheckUUID";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
import LOA from '../../../../assets/pdf/MoneyBarn_LOA.pdf';    
import TOB from '../../../../assets/pdf/Business_Terms_TMS.pdf';      
import CFD from '../../../../assets/pdf/Moneybarn_FOS.pdf';
import MQ from '../../../../assets/pdf/Moneybarn_Questionnare.pdf';
import DSAR from '../../../../assets/pdf/MoneyBarn_DSAR.pdf'; 
import { useUpdateMilestone } from "../../../../Hooks/useUpdateMilestone";  

  

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};

const SignatureMB = () => {
  const { isCheck } = CheckUUID();
  isCheck();
  const splitForm = useRef(null);
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const [isChecked, setIsChecked] = useState(true);
  const history = useHistory();
  const [verf2, setVerf2] = useState([]);
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const signatureWrapRef = useRef(null);
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const {updateMilestone} = useUpdateMilestone();

  const redirectOb = () =>{
    // Generate a random number between 0 and 1
   const randomValue = Math.random();
   console.log('No-'+randomValue);
    // Define the percentage distribution
    const pageAProbability = 0.5; // 50% for page OBV2
    const pageBProbability = 0.5; // 50% for page OBV1

    if(formdata != null && formdata.question_74 == '211'){
      history.push("/QuestionnaireEnvlp?uuid=" + visitorParameters.visitor_parameters.uuid);
    } else {
      if(randomValue < pageAProbability){
        history.push("/OBV2?uuid=" + visitorParameters.visitor_parameters.uuid);
      }else{
        history.push("/OBV1?uuid=" + visitorParameters.visitor_parameters.uuid);
      }
    }
  }

  const handleClick = async (e) => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        const response = await saveSignature(
          signPad.getCanvas().toDataURL("image/png"),
          visitorParameters.visitor_parameters,
          formParameters,
          visitorParameters.data,
          queryString,
          message_type,
        );

        updateMilestone(visitorParameters.visitor_parameters.uuid,"signature","live");

        GtmDataLayer({
          question: "Signature",
        });

        if (response.data.status === "Success") {
          const formdata = JSON.parse(localStorage.getItem("formData"));
          if (formdata === undefined || formdata === null || formdata == "") {
            localStorage.setItem("formData", JSON.stringify(formdata));
          } else {
            formdata.user_sign = signPad.getCanvas().toDataURL("image/png");
            localStorage.setItem("formData", JSON.stringify(formdata));
          }
          
          let splitName = localStorage.getItem("split_name") ? localStorage.getItem("split_name") : null;

          if(splitName === null){
            const urlParams = new URLSearchParams(window.location.search);
            splitName = urlParams.get('split_name');
          }
         
          if(splitName == "MB_UBRF_1") {    
            history.push("/questionnaireV2?uuid=" + visitorParameters.visitor_parameters.uuid);
          }
          else if(splitName == "MB_UBRF_3") {    
            history.push("/questionnaireV9?uuid=" + visitorParameters.visitor_parameters.uuid);
          }
          else if(splitName == "MB_OB_UBRF_3_1") {    
            history.push("/questionnaireV16?uuid=" + visitorParameters.visitor_parameters.uuid);
          }
          else if(splitName == "MB_OBV1") {    
            redirectOb();
          }
          else{
            history.push("/AnalyseMB?uuid=" + visitorParameters.visitor_parameters.uuid);
          }

        }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    if (isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);
  const checkclick = (e) => {
    setVerf2(e.target.value);
    console.log("verify", verf2);
  };
  useEffect(() => { 
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <>
      <GetVisitorsParams />
      <div className="tmsversion_signature_MBUFGN_1">
        <header className="d-none d-lg-block">
          <div className="container">
            <div className="row">
              <div className="logo-part col-lg-12 col-md-12 col-12  text-center">
                <img src="../../../../assets/Signature/img/logo.png" alt="" />
              </div>
              <div className="col-lg-6 offset-lg-2 col-md-8 col-12 text-center d-none d-md-block "></div>
            </div>
          </div>
        </header>
        <section className="signature-section">
          <div className="container">
            <div className="row ">
              <div className="offset-lg-2 col-lg-8 col-12 sign-question">
                <h2>
                  Great news, you are eligible to make a claim! <br />
                  Sign here to start your claim online NOW.
                </h2>
                <div className="offset-lg-2 col-lg-8 col-12">
                  <ul>
                    <li>Sign using your finger, mouse or stylus</li>
                    <li>Keep it fully contained within the box</li>
                    <li>Ensure it is a true likeness of your signature</li>
                  </ul>
                </div>
                <div className="sign-div">
                  <form  ref={splitForm}>
                    <div className="form-group">
                      <div className="signature_canvaseWrap">
                        <div className="web-canvas">
                          <div
                            className="signature-pad anim_bt d-lg-block web-canvas"
                            ref={signatureWrapRef}
                          >
                            <SignatureCanvas
                              canvasProps={{
                                id: "myID",
                                width: signatureWrapWidth,
                                height: signatureWrapHeight,
                              }}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                  document
                                    .getElementById("acceptterms")
                                    .classList.add("anim_ylw");
                                }
                              }}
                            />
                          </div>

                          {state.isAgreed && (
                            <div className="sign_msg col-12 text-center">
                              Please Tick To Show Your Authorisation For Us To
                              Proceed
                            </div>
                          )}
                          {state.isCanvasEmpty && (
                            <div
                              className="sign_msg col-12 text-center sign_msg"
                              id="signatures_required"
                            >
                              Signature is Required!
                            </div>
                          )}
                          {state.isValid && (
                            <div
                              className="sign_msg col-12 text-center sign_msg"
                              id="signatures_valid"
                            >
                              Draw Valid Signature!
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-check my-3">
                    <CheckBox2
                          dataId="acceptterms"
                          anchorText=""
                          anchorStyle=""
                          href=""
                          onChange={(e) => setIsChecked(!isChecked)}
                          className="form-check-input input_1"
                          labelClassName="form-check-label sign-custom-control-label"
                          value={isChecked}
                          checked={isChecked}
                          CheckBoxText="By clicking submit you agree to us appending your electronic signature to the claim documents and confirming that you accept the terms of this contract for TMS Legal to represent you in your irresponsible Lending claim, and that you agree to pay us 45% commission if the claim is successful."
                        />
                        <p className="my-2" style={{fontSize:"14px"}}>
                        Preview your unsigned documents before signing above:
                        </p>

                     <ul className="list-1a">
                        <li> <a href={`${LOA}`} target="_blank"> <img className="link_img_1a" src="/assets/MB_UFGN_1/img/link_1a.png" alt=""/> Letter of Authority </a></li>
                        <li> <a href={`${TOB}`} target="_blank"> <img className="link_img_1a" src="/assets/MB_UFGN_1/img/link_1a.png" alt=""/> Terms of Business</a></li>
                        <li> <a href={`${MQ}`} target="_blank"> <img className="link_img_1a" src="/assets/SignatureVAQ/img/link_1a.png" alt=""/> Questionnaire</a></li>
                        <li> <a href={`${CFD}`} target="_blank"> <img className="link_img_1a" src="/assets/MB_UFGN_1/img/link_1a.png" alt=""/> Complaint Form & Declaration</a></li>
                        <li> <a href={`${DSAR}`} target="_blank"> <img className="link_img_1a" src="/assets/MB_UFGN_1/img/link_1a.png" alt=""/> DSAR</a></li>
                     </ul>
                    </div>

                    <input
                      type="button"
                      id=""
                      name=""
                      className="btn-clear"
                      value="Clear"
                      onClick={clearSign}
                    />
                    <input
                      className="btn btn-submit"
                      type="button"
                      onClick={handleClick}
                      value="Submit"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="bg-secondary">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>
                  TMS Legal Ltd is a limited company registered in England and
                  Wales under company no: 10315962 Its registered office is
                  Arena, 9 Nimrod Way, Ferndown, BH21 7UH It is authorised and
                  regulated by the Solicitors Regulation Authority, Number:
                  645041
                </p>
                <p>Copyright © 2022 TMS Legal Ltd - All rights reserved</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
export default SignatureMB;
