import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect";
import "../../../../assets/Signature/css/signature.scss";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import { queryString } from "../../../../Utility/QueryString";
import SignatureCanvas from "react-signature-canvas";
import CheckBox from "../../../UI/CheckBox";
import { useHistory } from "react-router-dom";
import InputButton from "../../../UI/InputButton";
import { useSignature } from "../../../../Hooks/useSignature";
import { signCheckText } from "../../../../Constants/Constants";
import GetVisitorsParams from "../../../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../../../Utility/CheckUUID";

const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const SignaturePopup = ({ show, setShowSignature, getValues }) => {
  const { isCheck } = CheckUUID();
  // useEffect(()=>{
  //   isCheck();
  // },[]);
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const signatureWrapRef = useRef(null);
  const handleClick = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        // const response = await saveSignature(
        //   signPad.getCanvas().toDataURL("image/png"),
        //   visitorParameters.visitor_parameters,
        //   formParameters,
        //   visitorParameters.data,
        //   queryString,
        //   message_type
        // );
        const formData = getValues();
        console.log(formData);
        localStorage.setItem("formData", JSON.stringify(formData));
        const formdata = JSON.parse(localStorage.getItem("formData"));
        if (formdata === undefined || formdata === null || formdata == "") {
          localStorage.setItem("formData", JSON.stringify(formdata));
        } else {
          formdata.user_sign = signPad.getCanvas().toDataURL("image/png");
          localStorage.setItem("formData", JSON.stringify(formdata));
        }
        history.push(
          "/previewV2?uuid=" + visitorParameters.visitor_parameters.uuid
        );
        // if (response.data.status === "Success") {
        //   const formData = getValues();
        //   console.log(formData);
        //   localStorage.setItem("formData", JSON.stringify(formData));
        //   const formdata = JSON.parse(localStorage.getItem("formData"));
        //   if (formdata === undefined || formdata === null || formdata == '') {
        //     localStorage.setItem("formData", JSON.stringify(formdata));
        //   } else {
        //     formdata.user_sign = signPad.getCanvas().toDataURL("image/png");
        //     localStorage.setItem("formData", JSON.stringify(formdata));
        //   }
        //   history.push(
        //         "/previewV2?uuid=" + visitorParameters.visitor_parameters.uuid
        //       );
        //   var splitName = localStorage.getItem("split_name");
        //   // if(splitName == "MB_UBRF_2"){
        //   //   history.push(
        //   //     "/questionnaire?uuid=" + visitorParameters.visitor_parameters.uuid
        //   //   );
        //   // }else{
        //   //   history.push(
        //   //     "/questionnaireV2?uuid=" + visitorParameters.visitor_parameters.uuid
        //   //   );
        //   // }
        // }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    if (isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  useEffect(() => {
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <>
      <GetVisitorsParams />
      <div
        className={`modal fade overlaybg hide ${show}`}
        id="exampleModal-signature"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <section className="tmssignature_2022">
              <section className="signature-section p-0">
                <div className="container ">
                  <div className="row ">
                    <div className="  col-12 sign-question">
                      <h2>
                        Great news, you are eligible to make a claim! <br />{" "}
                        Sign here to start your claim online NOW.{" "}
                      </h2>
                      <div className=" col-12">
                        <ul>
                          <li> Sign using your finger, mouse or stylus</li>
                          <li> Keep it fully contained within the box</li>
                          <li>
                            {" "}
                            Ensure it is a true likeness of your signature
                          </li>
                        </ul>
                      </div>
                      <div className="s-col">
                        <div className="sign-div">
                          <div className="form-group">
                            <div className="signature_canvaseWrap">
                              <div>
                                <div
                                  className="signature-pad anim_bt d-lg-block web-canvas"
                                  ref={signatureWrapRef}
                                >
                                  <SignatureCanvas
                                    canvasProps={{
                                      id: "myID",
                                      width: 646,
                                      height: 298,
                                    }}
                                    ref={signPadRef}
                                    onEnd={() => {
                                      if (!signPadRef.current.isEmpty()) {
                                        dispatch({
                                          type: "signPadChange",
                                          payload: { value: false },
                                        });
                                        dispatch({
                                          type: "validSignature",
                                          payload: { value: false },
                                        });
                                        document
                                          .getElementById("acceptterms")
                                          .classList.add("anim_ylw");
                                      }
                                    }}
                                  />
                                </div>

                                {state.isAgreed && (
                                  <div className="sign_msg col-12 text-center">
                                    Please tick to show your authorisation for
                                    us to proceed.
                                  </div>
                                )}
                                {state.isCanvasEmpty && (
                                  <div
                                    className="sign_msg col-12 text-center sign_msg"
                                    id="signatures_required"
                                  >
                                    Signature is required!
                                  </div>
                                )}
                                {state.isValid && (
                                  <div
                                    className="sign_msg col-12 text-center sign_msg"
                                    id="signatures_valid"
                                  >
                                    Draw valid signature!
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="form-check my-3">
                            <CheckBox
                              dataId="acceptterms"
                              anchorText=""
                              anchorStyle=""
                              href=""
                              onChange={(e) => setIsChecked(!isChecked)}
                              className="form-check-input"
                              labelClassName="form-check-label sign-custom-control-label"
                              value={isChecked}
                              CheckBoxText="By clicking submit you agree to us appending your electronic signature to the claim documents and confirming that you accept the terms of this contract for TMS Legal."
                            />
                          </div>
                          <input
                            type="button"
                            id=""
                            name=""
                            className="btn-clear"
                            value="Clear"
                            onClick={clearSign}
                          />
                          <input
                            className="btn btn-submit"
                            type="button"
                            onClick={handleClick}
                            value="Submit"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignaturePopup;
