import React, { useState } from "react";
import ProgressBar from '../Common/ProgressBar';
import RadioButton from '../../../UI/RadioButton';
import { useTotalCarCheck } from '../../../../Hooks/useTotalCarCheck';

const KeeperDate = ({
  className,
  slideChange,
  validation,
  reff,
  trigger,
  clearErrors,
  getValues,
  setError,
  vehicle,
  backClick,
  textHeading,
  setValidKeeperDate
}) => {
  const { totalCarCheckApi } = useTotalCarCheck();

  const prevClick = (e) => {
    localStorage.removeItem("keeprDate");
    backClick(e);

   }

  const nextSlide = (e) => {
    console.log("keeprDate"+e.target.value);
    localStorage.setItem("keeprDate",e.target.value);
    let date_index = e.target.id;
    let dataNsId = e.target.getAttribute('data-ans-id') ? e.target.getAttribute('data-ans-id') : '';
    if (date_index == '0') {
      console.log('valid keeper date');
      setValidKeeperDate(dataNsId);
    } else {
      console.log('not valid keeper date');
      setValidKeeperDate("");
    }
    slideChange(e);
  };
  let keeperArray = [];
  let keeperData = [];
  if (typeof vehicle.keeper_dates !== 'undefined') {
    keeperData = JSON.parse(vehicle.keeper_dates);
    keeperArray = keeperData.keeper_dates;
  }
  return (
    <>
      <div id="slide3" className={`col-12 top_minus ${className}`} ref={reff}>
        <ProgressBar value="8" />
        <h3>{textHeading}</h3>
        <div className="btn-comm mb-1">
          {keeperArray.map((text, index, { length }) => (
            <RadioButton
              key={text.toString()}
              id={index}
              value={text}
              htmlFor="previously"
              tabIndex="1"
              name="keeperDate"
              className="radio-button"
              labelName={text.slice(2)}
              labelClassName={`option_but next2aa`}
              onClick={nextSlide}
              dataAnsId={text}
              dataQuestId={11}
              validation={validation()}
            />
          ))}
          <p className="text-center"><a className="back01" name="prevReg" onClick={prevClick}> {'<< Previous'} </a></p>
        </div>
      </div>
    </>
  );
};

export default KeeperDate;