import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Complaints from '../Common/Complaints';
import PrivacyPolicy from '../Common/PrivacyPolicy';
import TC from '../../../../assets/pdf/PBA-terms.pdf';
const Footer = () => {
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showterms, setShowterms] = useState(false);
  const [showComplaint, setShowComplaint] = useState(false);
  const modalClick = (clickValue) => {
    switch (clickValue) {

      case "showPrivacy": {
        setShowPrivacy(true);
        break;
      }
      case "exitPrivacy": {
        setShowPrivacy(false);
        break;
      }
      case "showTerms": {
        setShowterms(true);
        break;
      }
      case "exitTerms": {
        setShowterms(false);
        break;
      }
      case "showComplaint": {
        setShowComplaint(true);
        break;
      }
      case "exitComplaint": {
        setShowComplaint(false);
        break;
      }
    }
  };
  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-12">
              <p>TMS Legal Ltd is a limited company registered in England and Wales under company no: 10315962 Its
                registered office is  Arena, 9 Nimrod Way, Ferndown, BH21 7UH It is authorised and regulated by the
                Solicitors Regulation Authority, Number: 645041</p>
              <div className="copyright">
                <ul>
                  <li>
                    <a className='text-primary' onClick={() => modalClick("showPrivacy")}>
                      Privacy and Cookie Policy
                    </a>
                  </li>
                  <li>
                    <a className='text-primary' href={`${TC}`} target="_blank">
                      TMS Legal Terms and Conditions
                    </a>
                  </li>
                  <li>
                    <a className='text-primary' onClick={() => modalClick("showComplaint")}>
                      Complaints Procedure
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <PrivacyPolicy
        showPrivacy={showPrivacy}
        modalClick={modalClick}
      />

      <Complaints
        showComplaint={showComplaint}
        modalClick={modalClick}
      />

      <Modal show={showterms} size="xl" className="modal fade" id="terms">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Terms and Conditions</h4>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => modalClick("exitTerms")}
            ></button>
          </div>
          <div className="modal-body">
            <h3>Terms and Conditions</h3>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap
              into electronic typesetting, remaining essentially unchanged.Lorem Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              It has survived not only five centuries, but also the leap into electronic typesetting, remaining
              essentially unchanged.
            </p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={() => modalClick("exitTerms")}
            >
              Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Footer;