import React, { useState } from "react";
import RadioButtonQuest from "../../../UI/RadioButtonQuest";

const Questionnaire31 = ({
  clsName,
  reff,
  onselectAnswer,
}) => {
  const labelclicked = async (e) => {
    onselectAnswer(e);
  };

  return (
    <>
      <div className={`radio-box animated fadeInUp ${clsName}`} ref={reff}>
        <h3>How often would you have a <strong>balance which was close to, or exceeding your credit limit</strong>?</h3>
        <RadioButtonQuest
          key="Always"
          className="radio-button"
          labelName="Always"
          labelClassName="option_but next-2"
          // labelCountString="a"
          onClick={labelclicked}
          value="Always"
          name="question_55"
          id="opt1"
          dataQuestId={55}
          dataAnsId={141}
          dataAnsLabel="Always"
        />
        <RadioButtonQuest
          key="Sometimes"
          className="radio-button"
          labelName="Sometimes"
          labelClassName="option_but next-2"
          // labelCountString="b"
          onClick={labelclicked}
          value="Sometimes"
          name="question_55"
          id="opt2"
          dataQuestId={55}
          dataAnsId={142}
          dataAnsLabel="Sometimes"
        />
        <RadioButtonQuest
          key="Twice"
          className="radio-button"
          labelName="Twice"
          labelClassName="option_but next-2"
          // labelCountString="c"
          onClick={labelclicked}
          value="Twice"
          name="question_55"
          id="opt3"
          dataQuestId={55}
          dataAnsId={143}
          dataAnsLabel="Twice"
        />
        <RadioButtonQuest
          key="Once"
          className="radio-button"
          labelName="Once"
          labelClassName="option_but next-2"
          // labelCountString="c"
          onClick={labelclicked}
          value="Once"
          name="question_55"
          id="opt4"
          dataQuestId={55}
          dataAnsId={144}
          dataAnsLabel="Once"
        />
        <RadioButtonQuest
          key="Never"
          className="radio-button"
          labelName="Never"
          labelClassName="option_but next-2"
          // labelCountString="c"
          onClick={labelclicked}
          value="Never"
          name="question_55"
          id="opt5"
          dataQuestId={55}
          dataAnsId={145}
          dataAnsLabel="Never"
        />
      </div>
    </>
  );
};
export default Questionnaire31;