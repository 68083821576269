import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect";
import "../../assets/Signature/css/signature.scss";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { queryString } from "../../Utility/QueryString";
import SignatureCanvas from "react-signature-canvas";
import CheckBox from "../UI/CheckBox";
import { useHistory } from "react-router-dom";
import InputButton from "../UI/InputButton";
import { useSignature } from "../../Hooks/useSignature";
import { signCheckText } from "../../Constants/Constants";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import { CheckUUID } from "../../Utility/CheckUUID";
import GtmDataLayer from "../../Utility/GtmDataLayer";


const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const Signature = () => {
  const { isCheck } = CheckUUID();
  isCheck();
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveSignature, isLoading } = useSignature();
  const [isChecked, setIsChecked] = useState(false);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const signatureWrapRef = useRef(null);

  // const redirectOb = () =>{
  //   // Generate a random number between 0 and 1
  //  const randomValue = Math.random();
  //  console.log('No-'+randomValue);
  //   // Define the percentage distribution
  //   const pageAProbability = 0.5; // 50% for page OBV2
  //   const pageBProbability = 0.5; // 50% for page OBV1

  //   if(randomValue < pageAProbability){
  //     history.push("/OBV2?uuid=" + visitorParameters.visitor_parameters.uuid);
  //   }else{
  //     history.push("/OBV1?uuid=" + visitorParameters.visitor_parameters.uuid);
  //   }
  // }
  const handleClick = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        const response = await saveSignature(
          signPad.getCanvas().toDataURL("image/png"),
          visitorParameters.visitor_parameters,
          formParameters,
          visitorParameters.data,
          queryString,
          message_type
        );
        
        GtmDataLayer({
          'question': 'Signature'
        });
        
        if (response.data.status === "Success") {
          const formdata = JSON.parse(localStorage.getItem("formData"));
          if (formdata === undefined || formdata === null || formdata == '') {
            localStorage.setItem("formData", JSON.stringify(formdata));
          } else {
            formdata.user_sign = signPad.getCanvas().toDataURL("image/png");
            localStorage.setItem("formData", JSON.stringify(formdata));
          }
          let splitName = localStorage.getItem("split_name") ? localStorage.getItem("split_name") : null;

          if(splitName === null){
            const urlParams = new URLSearchParams(window.location.search);
            splitName = urlParams.get('split_name');
          }
         
          if(splitName == "MB_UBRF_2"){ 
            history.push(
              "/questionnaire?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          } 
          // else if(splitName == "LS_UB_1"){    
          //   // history.push("/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid);
          //   history.push("/questionnaireV3?uuid=" + visitorParameters.visitor_parameters.uuid);
          // }
          else if(splitName == "VCC_UB_1"){    
            // history.push("/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid);
            history.push("/questionnaireV4?uuid=" + visitorParameters.visitor_parameters.uuid);
          } else if(splitName == "VCC_UB_2"){    
            // history.push("/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid);
            history.push("/questionnaireV5?uuid=" + visitorParameters.visitor_parameters.uuid);  
          }
          else if(splitName == "VCC_UB_3"){    
            // history.push("/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid);
            history.push("/questionnaireV8?uuid=" + visitorParameters.visitor_parameters.uuid);  
          } 
          // else if(splitName == "VAQ_UB_1"){    
          //   history.push("/questionnaireV6?uuid=" + visitorParameters.visitor_parameters.uuid);
          // }
          // else if(splitName == "NDY_UB_1"){    
          //   // history.push("/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid);
          //   history.push("/questionnaireV7?uuid=" + visitorParameters.visitor_parameters.uuid);
          // }
          // else if(splitName == "NDY_UB_2"){    
          //   // history.push("/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid);
          //   history.push("/questionnaireV12?uuid=" + visitorParameters.visitor_parameters.uuid);
          // }
          // else if(splitName == "MB_UBRF_3"){    
          //   // history.push("/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid);
          //   history.push("/questionnaireV9?uuid=" + visitorParameters.visitor_parameters.uuid);
          // }
          else if(splitName == "LS_UB_2"){    
            // history.push("/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid);
            history.push("/questionnaireV10?uuid=" + visitorParameters.visitor_parameters.uuid);
          }
          // else if(splitName == "VAQ_UB_2") {    
          //   history.push("/questionnaireV11?uuid=" + visitorParameters.visitor_parameters.uuid);
          // }
          // else if(splitName == "VAQ_UB_3") {    
          //   history.push("/questionnaireV14?uuid=" + visitorParameters.visitor_parameters.uuid);
          // }
          // else if(splitName == "MB_OBV1") {    
          //   // history.push("/thankyou?uuid=" + visitorParameters.visitor_parameters.uuid);
          //   //history.push("/OBV1?uuid=" + visitorParameters.visitor_parameters.uuid);
          //   redirectOb();
          // }
           else{
            history.push(
              "/questionnaireV3?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          }
        }
      } else {
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    if (isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  useEffect(() => {
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
  };
  return (
    <>
      <GetVisitorsParams />
      <div className="tmsversion_signature">
        <header className="d-none d-lg-block">
          <div className="container">
            <div className="row">
              <div className="logo-part col-lg-12 col-md-12 col-12  text-center">
                <img src="/assets/Signature/img/logo.png" alt="" />
              </div>
              <div
                className="col-lg-6 offset-lg-2 col-md-8 col-12 text-center d-none d-md-block "
              ></div>
            </div>
          </div>
        </header>
        <section className="signature-section">
          <div className="container">
            <div className="row ">
              <div className="offset-lg-2 col-lg-8 col-12 sign-question">
                <h2>
                  Great news, you are eligible to make a claim! <br />
                  Sign here to start your claim online NOW.
                </h2>
                <div className="offset-lg-2 col-lg-8 col-12">
                  <ul>
                    <li>Sign using your finger, mouse or stylus</li>
                    <li>Keep it fully contained within the box</li>
                    <li>Ensure it is a true likeness of your signature</li>
                  </ul>
                </div>
                <div className="sign-div">
                  <form>
                    <div className="form-group">
                      <div className="signature_canvaseWrap">
                        <div>
                          <div className="signature-pad anim_bt d-lg-block web-canvas" ref={signatureWrapRef}>
                            <SignatureCanvas
                              canvasProps={{
                                id: "myID",
                                width: signatureWrapWidth,
                                height: signatureWrapHeight,
                              }}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                  document
                                    .getElementById("acceptterms")
                                    .classList.add("anim_ylw");
                                }
                              }}
                            />
                          </div>

                          {state.isAgreed && (
                            <div className="sign_msg col-12 text-center">
                              Please Tick To Show Your Authorisation For Us To
                              Proceed
                            </div>
                          )}
                          {state.isCanvasEmpty && (
                            <div className="sign_msg col-12 text-center sign_msg" id="signatures_required">
                              Signature is Required!
                            </div>
                          )}
                          {state.isValid && (
                            <div className="sign_msg col-12 text-center sign_msg" id="signatures_valid">
                              Draw Valid Signature!
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-check my-3">
                      <CheckBox
                        dataId="acceptterms"
                        anchorText=""
                        anchorStyle=""
                        href=""
                        onChange={(e) => setIsChecked(!isChecked)}
                        className="form-check-input"
                        labelClassName="form-check-label sign-custom-control-label"
                        value={isChecked}
                        CheckBoxText="By clicking submit you agree to us appending your electronic signature to the claim documents and confirming that you accept the terms of this contract for TMS Legal to represent you in your Responsible Lending claim, and that you agree to pay us 45% commission if the claim is successful."
                      />
                    </div>
                    <input
                      type="button"
                      id=""
                      name=""
                      className="btn-clear"
                      value="Clear"
                      onClick={clearSign}
                    />
                    <input
                      className="btn btn-submit"
                      type="button"
                      onClick={handleClick}
                      value="Submit"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>

        <footer className="bg-secondary">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <p>
                  TMS Legal Ltd is a limited company registered in England and Wales
                  under company no: 10315962 Its registered office is Arena, 9 Nimrod Way, Ferndown, BH21 7UH It is authorised and regulated by
                  the Solicitors Regulation Authority, Number: 645041
                </p>
                <p>Copyright © 2022 TMS Legal Ltd - All rights reserved</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};
export default Signature;
