import React, { useEffect, useState, useContext } from 'react';
import { CheckUUID } from "../../Utility/CheckUUID";
import "../../assets/css/Unqualified.scss";
import Modal from 'react-bootstrap/Modal';
import RadioButtonQuest from "../UI/RadioButtonQuest";
import { useLenderName } from "../../Hooks/useLenderName";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { FormData } from "../../Utility/FormData";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";


const Unqualified = () => {

   const [lenderName, setLenderName] = useState('{Bank Name}');
   const { isCheck } = CheckUUID();
   const { getLenderName } = useLenderName();
   const { visitorParameters } = useContext(VisitorParamsContext);
   const query_string = localStorage.getItem("querystring");
   const { isLoading, SaveQuestionnaire } = useQuestionnaire();
   const formdata = JSON.parse(localStorage.getItem("formData"));
   const [slide1, setSlide1] = useState('show');
   const [slide2, setSlide2] = useState('hide')

   isCheck();
   let customUuid = "";
   const query = new URLSearchParams(window.location.search);
   const local_uuid = localStorage.getItem("uuid");
   const query_uuid = query.get("uuid");
   customUuid = query_uuid ? query_uuid : local_uuid;

   useEffect(() => {
      const lender_name = localStorage.getItem("lenderName");
      if (lender_name == null) {
         const getResult = async () => {
            let resp = await getLenderName(customUuid);
            var result = resp.data.result;
            return result;
         };
         getResult().then((res) => {
            var l_name = res.lender_name;
            setLenderName(l_name);
         });
      } else {
         setLenderName(lender_name);
      }
   }, []);

   const labelclicked = async (e) => {
      setSlide1('hide');
      setSlide2('show');
      let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
      let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
      let anslabel = e.target.getAttribute("data-ans-label");

      let question_data = {
         question_id: selectedQuest,
         option_id: selectedAns,
         answer_text: anslabel,
         input_answer: "",
      };
      storeQuestionnire(question_data);
   };

   const storeQuestionnire = async (question_data) => {
      const response = await SaveQuestionnaire(
         visitorParameters.visitor_parameters,
         question_data,
         "question_store",
         visitorParameters.data,
         query_string,
         formdata
      );
   };


   return (
      <>
         <GetVisitorsParams />
         <div className="unqualified">
            <Modal show={true}
               backdrop="static"
               keyboard={false}
               centered
               dialogClassName="modal-300"
               size="md"
            >
               <Modal.Body className='mt-4 modal_bdy1'>
                  <div className={`slide-1 ${slide1}`}>
                     <div className='text-center'>
                        <img src="assets/img/apology.png" className="modal_img1" alt="" />
                        <p className="modal_contnt1">Sorry,</p>
                        <p>The finance provider for your registered vehicle is {lenderName}, and not Moneybarn. <br /> We are therefore unable to assist you with this claim</p>
                        <p>If we could help you with {lenderName} in the near future, would you like to continue?</p>
                        <div className="text-center">
                           <RadioButtonQuest
                              key="YES"
                              className="form-check-input"
                              labelName="Yes"
                              labelClassName="form-check-label btn-yes yes_1"
                              labelCountString=""
                              onClick={labelclicked}
                              value="YES"
                              name="question_12"
                              id="Yes"
                              dataQuestId={37}
                              dataAnsId={111}
                              dataAnsLabel="Yes"
                           />

                           <RadioButtonQuest
                              key="NO"
                              className="form-check-input"
                              labelName="No"
                              labelClassName="form-check-label btn-yes no_1"
                              labelCountString=""
                              onClick={labelclicked}
                              value="NO"
                              name="question_12"
                              id="No"
                              dataQuestId={37}
                              dataAnsId={112}
                              dataAnsLabel="No"
                           />
                        </div>
                     </div>
                  </div>
                  <div className={`slide-2 ${slide2}`}>
                     <div className='text-center'>
                        <img src="assets/img/check.png" className="modal_img1" alt="" />
                        <p className="modal_contnt_22">Thankyou</p>
                     </div>
                  </div>
               </Modal.Body>
            </Modal>
         </div>
      </>
   )
}
export default Unqualified;
