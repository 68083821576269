import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useReducer,
} from "react";
import { useForm } from "react-hook-form";
import {
  questinnare1,
  questinnare2,
  questinnaire76MB,
  answerArray,
  supportDocTypeArray,
} from "../../Constants/Questions";
import { MonthNumberArray } from "../../Constants/Constants";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { userInfo } from "../../Hooks/userInfo";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { ConsoleView } from "react-device-detect";
import { CheckUUID } from "../../Utility/CheckUUID";
import { useHistory } from "react-router-dom";
import { FormData } from "../../Utility/FormData";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import "../../assets/css/QuestionnaireOb.scss";
import QuestionnaireLoder from "../Includes/Layouts/QuestionnaireOb/QuestionnaireLoder";
import QuestionnaireFooter from "../Includes/Layouts/QuestionnaireOb/QuestionnaireFooter";
import Questionnaire5 from "../Includes/Layouts/QuestionnaireOb/Questionnaire5";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { useUserVerify } from "../../Hooks/useUserVerify";
import { useTotalCarCheck } from "../../Hooks/useTotalCarCheck";
import { supportingdoc } from "../../Hooks/supportingdoc";
import QuestionnaireEmail from "../Includes/Layouts/QuestionnaireOb/QuestionnaireEmail";
import Questionnaire61 from "../Includes/Layouts/QuestionnaireOb/Questionnaire61";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
import { userMilestoneUpdateV2 } from "../../Hooks/userMilestoneUpdateV2";
import { useOpenBanking } from "../../Hooks/useOpenBanking";
import Questionnaire73 from "../Includes/Layouts/QuestionnaireOb/Questionnaire73";
import ConfirmSituationOB from "../Includes/Layouts/ObEnvelopeQuestionnaire/ConfirmSituationOB";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";

const initialState = {
  showSlide1: "show",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide10: "hide",
  percentage: 0,
};

const FormReducer = (state, action) => {
  switch (action.type) {
    case "showSlide": {
      if (action.payload.clickedSlide.slide == "question_73") {
        // GtmDataLayer({
        //   'question': 'reg. email'
        // });
        return {
          ...state,
          showSlide4: "hide",
          showSlide1: "show",
          percentage: 70,
        };
      } else if (action.payload.clickedSlide.slide == "question_61") {
        GtmDataLayer({
          question: "reg. email",
        });
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "hide",
          showSlide10: "show",
          percentage: 73,
        };
      } else if (action.payload.clickedSlide.slide == "question_61a") {
        GtmDataLayer({
          question: "reg. email",
        });
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
          percentage: 75,
        };
      } else if (action.payload.clickedSlide.slide == "btn_qest_36") {
        GtmDataLayer({
          question: "further details",
        });
        return {
          ...state,
          showSlide3: "hide",
          showSlide10: "show",
          percentage: 88,
        };
      } else if (action.payload.clickedSlide.slide == "btn_qest_76") {
        GtmDataLayer({
          'question': 'situation statements'
        });
        return {
          ...state,
          showSlide10: "hide",
          showSlide3: "show",
          percentage: 90,
        };
      } else if (action.payload.clickedSlide.slide == "btn_qest_48") {
        GtmDataLayer({
          question: "email registered with",
        });

        return {
          ...state,
          showSlide2: "hide",
          showSlide10: "show",
          SetPercentage: 80,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    default:
      return state;
  }
};

const QuestionnaireOb = () => {
  const {updateMilestone} = useUpdateMilestone();
  const { isCheck } = CheckUUID();
  const { getUserVerfyValidation, saveUserVerify } = useUserVerify();
  isCheck();
  const history = useHistory();
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { isLoading, SaveQuestionnaire } = useQuestionnaire();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const query_string = localStorage.getItem("querystring");
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const [lastClickQuest, setLastClickQuest] = useState(null);
  const [t2a, setT2a] = useState();
  const scrollRef = useRef([]);
  const splitForm = useRef(null);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const { updateMilestoneV2 } = userMilestoneUpdateV2();
  const [splitName, setSplitName] = useState();
  const [splitNameForSkip, setSplitNameForSkip] = useState();
  const { getUserInfo } = userInfo();
  const { setFormData } = FormData();
  const [showLoad, setShowLoad] = useState("show");
  const [showUserName, setUserName] = useState();
  const [vehicle, setVehicle] = useState("");
  const [totalCarCheck, settotalCarCheck] = useState("");
  const [clickedSlide, setClickedSlide] = useState([]);
  const [validKeeperDate, setValidKeeperDate] = useState("");
  const [optionSelected] = useState([]);
  const [optionSelected76] = useState([]);
  const [t2aApiReqData, setT2aApiReqData] = useState({});
  const { totalCarCheckApi } = useTotalCarCheck();
  let [totalQuestArr, setTotalQuestArr] = useState([
    10, 11, 12, 43, 31, 36, 76, 45, 46, 42, 73,
  ]);
  const { getSupportingDoc } = supportingdoc();
  const [supportDocType, setSupportDocType] = useState("");
  const [supportDoc, setSupportDoc] = useState("");
  const [docType, setDocType] = useState("");
  const [userEmail, setUserEmail] = useState();
  const [agNoShow, setAgNoShow] = useState("hide");
  const { skippedLeads } = useSkippedLeads();
  const {
    callBackGateWay,
    storeApiResponse,
    fetchAccountDetails,
    fetchTransactions,
  } = useOpenBanking();
  const query = new URLSearchParams(window.location.search);
  const query_status = query.get("status");
  var customer_id = localStorage.getItem("customer_id");
  var is_test = localStorage.getItem("is_test");
  var visitorId = localStorage.getItem("visitorId");
  const query_source = query.get("atp_sub6");
  var source = query_source ? query_source : "live";
  const task_id = query.get("task_id");

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  let year_minus = year - 6;

  month = month < 10 ? `0${month}` : `${month}`;

  let today = year + "-" + month + "-" + date;
  let from = year_minus + "-" + month + "-" + date;

  let from_date = from;
  let to_date = today;
  let payload = {};

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();
  const savetotalCarCheck = (totalCarCheck) => {
    settotalCarCheck(totalCarCheck);
  };

  useEffect(() => {
    if (formdata === undefined || formdata === null || formdata == "") {
      (async () => {
        const response = await getUserInfo(uuid);
        const set_formdata = setFormData(response);
        let dataLength = response.data.response.length;
        if (dataLength > 0) {
          const pageName = response.data.response[0].page_name
            ? response.data.response[0].page_name
            : " ";
          setSplitName(pageName);
          if (
            localStorage.getItem("split_name") != undefined &&
            localStorage.getItem("split_name") != undefined
          ) {
            setSplitNameForSkip(localStorage.getItem("split_name"));
          } else {
            setSplitNameForSkip(pageName);
          }
          const userName = response.data.response[0].first_name
            ? response.data.response[0].first_name +
              " " +
              response.data.response[0].last_name
            : " ";
          var userDobN = response.data.response[0].user_dob.split("/");
          var DobDayF = userDobN[0];
          var DobMonthF = userDobN[1];
          var DobYearF = userDobN[2];

          setT2aApiReqData({
            uuid: uuid,
            fName: response.data.response[0].first_name,
            lName: response.data.response[0].last_name,
            pCode: response.data.response[0].postcode,
            addressLine1: response.data.response[0].address_line1,
            dob_y: DobYearF,
            dob_d: DobDayF,
            dob_m: DobMonthF,
            addressLine2: response.data.response[0].address_line2,
            postTown: response.data.response[0].town,
            country: response.data.response[0].county,
            title: response.data.response[0].title,
            addressLine3: response.data.response[0].address_line3,
            addressLine4: response.data.response[0].address_line4,
            county: response.data.response[0].county,
          });

          setSupportDocType(
            response.data.response[0]?.questionnaire[42]["answerId"]
          );

          setUserName(userName);
          setUserEmail(response.data.response[0].email);
          const timer = setTimeout(() => {
            setShowLoad("hide");
          }, 3000);
        }
      })();
    } else {
      const pageName = formdata.page_name;
      setSplitName(pageName);
      if (
        localStorage.getItem("split_name") != undefined &&
        localStorage.getItem("split_name") != undefined
      ) {
        setSplitNameForSkip(localStorage.getItem("split_name"));
      } else {
        setSplitNameForSkip(pageName);
      }
      const userName = formdata.txtFName + " " + formdata.txtLName;
      setUserName(userName);
      const dobMonth = formdata.DobMonth
        ? MonthNumberArray[formdata.DobMonth]
        : "";

      setT2aApiReqData({
        uuid: uuid,
        fName: formdata.txtFName,
        lName: formdata.txtLName,
        pCode: formdata.txtPostCode,
        addressLine1: formdata.txtHouseNumber,
        dob_y: formdata.DobYear,
        dob_m: dobMonth,
        dob_d: formdata.DobDay,
        addressLine2: formdata.txtAddress2,
        postTown: formdata.txtTown,
        country: formdata.txtCountry,
        title: formdata.lstSalutation,
        addressLine3: formdata.txtAddress3,
        addressLine4: formdata.txtAddress4 ? formdata.txtAddress4 : "",
        county: formdata.txtCounty,
      });
      const timer = setTimeout(() => {
        setShowLoad("hide");
      }, 3000);
      setSupportDocType(formdata.question_42);
      setSupportDoc(answerArray[formdata.question_42]);
      setDocType(supportDocTypeArray[formdata.question_42]);
      setUserEmail(formdata.txtEmail);
    }
  }, [splitName]);

  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    const valuesreg = getValues();
    let validEmail = valuesreg["question_48"];

    // if (e.target.name == 'question_43' && selectedAns == '121') {
    //   setTotalQuestArr([...totalQuestArr, 44]);
    // }

    // setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    // if (e.target.name == 'question_43' && selectedAns == '121') {
    //   setClickedSlide({ slide: "question_43a" });
    //   totalQuestArr.push(44);
    // }
    if (e.target.name == "question_61" && selectedAns == "163") {
      setClickedSlide({ slide: "question_61a" });
    } else {
      setClickedSlide({ slide: e.target.name });
    }

    let flage = false;
    let question_data = {};
    if (e.target.name == "btn_qest_36") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
          is_ob_questionnaire: "yes",
        };
      }
      pushPageChange();
      // setClickedSlide({ slide: 'btn_qest_36' });
      // redirectToPreview();
    } 
     else if (e.target.name == "btn_qest_76") {
      // GtmDataLayer({
      //   'question': 'Next BTN'
      // });
      setClickedSlide({ slide: "btn_qest_76" });
      if (selectedQuest) {
        flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: anslabel,
        input_answer: optionSelected76,
        };
      }
    }
    
    else if (e.target.name == "question_73") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: null,
        };
      }
    } else if (e.target.name == "btn_qest_48") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 48,
          option_id: null,
          answer_text: null,
          input_answer: validEmail,
        };
      }
    } else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }

    if (flage) {
      storeQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };

  const redirectToPreview = async () => {
    GtmDataLayer({
      question: "Supporting Documentation",
    });
    if (t2a == 1) {
      (async () => {
        const response = await updateMilestoneV2(
          visitorParameters.visitor_parameters.uuid,
          t2a
        );
      })();
    }
    history.push(
      "/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid
    );
  };

  useEffect(() => {
    var questIntersection = totalQuestArr.filter(
      (x) => !completedQuestArr.includes(x)
    );

    let completeQuestCount = questIntersection.length;
    if (completeQuestCount == 1) {
      pushPageChange();
    }
  }, [completedQuestArr]);

  const storeQuestionnire = async (question_data) => {
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      query_string,
      formdata
    );
  };

  const storeT2aResponse = async (t2a_data) => {
    const response = await saveUserVerify(
      visitorParameters.visitor_parameters,
      t2a_data,
      "person_verify_store",
      visitorParameters.data,
      query_string,
      t2aApiReqData
    );
  };
  const pushPageChange = () => {
    sett2aValidation();

    updateMilestone(visitorParameters.visitor_parameters.uuid,"questions","live");

    history.push(
      "/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid
    );

    // if (validKeeperDate) {
    //   (async () => {
    //     const totalCarCheckApiData = await totalCarCheckApi(
    //       vehicle.reg_no,
    //       visitorParameters.visitor_parameters.uuid,
    //       splitName
    //     );
    //     savetotalCarCheck(totalCarCheckApiData.data.vehicleData);
    //     var lender = totalCarCheckApiData.data.vehicleData.finance_company;
    //     localStorage.setItem("lenderName", lender);
    //     // sett2aValidation();

    //   })();
    // }
  };

  const sett2aValidation = () => {
    const useruuid = t2aApiReqData.uuid;
    const fName = t2aApiReqData.fName;
    const lName = t2aApiReqData.lName;
    const pCode = t2aApiReqData.pCode;
    const addressLine1 = t2aApiReqData.addressLine1;
    const dobYr = t2aApiReqData.dob_y;
    const dobDy = t2aApiReqData.dob_d;
    const addressLine2 = t2aApiReqData.addressLine2;
    const postTown = t2aApiReqData.postTown;
    const country = t2aApiReqData.country;
    const title = t2aApiReqData.title;
    const addressLine3 = t2aApiReqData.addressLine3;
    const addressLine4 = t2aApiReqData.addressLine4;
    const county = t2aApiReqData.county;
    let dobMon;
    if (t2aApiReqData.dob_m === undefined) {
      dobMon = formdata.DobMonth;
    } else {
      dobMon = t2aApiReqData.dob_m;
    }

    if (
      useruuid &&
      fName &&
      lName &&
      pCode &&
      addressLine1 &&
      dobYr &&
      dobMon &&
      dobDy
    ) {
      (async () => {
        const response = await getUserVerfyValidation(
          useruuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYr,
          dobMon,
          dobDy,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
          addressLine4,
          county
        );

        if (response.data.status == "success") {
          setT2a(1);
          localStorage.setItem("t2aValidation", 1);
          updateMilestone(visitorParameters.visitor_parameters.uuid,"kyc_check","live",1);
        } else {
          setT2a(0);
          localStorage.setItem("t2aValidation", 0);
          updateMilestone(visitorParameters.visitor_parameters.uuid,"kyc_check","live",2);
        }

        storeT2aResponse(response.data);
      })();
    }
  };
  const handleDownClick = () => {
    scrollRef.current[lastClickQuest + 2]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    setLastClickQuest(lastClickQuest + 1);
  };
  const handleUpClick = () => {
    scrollRef.current[lastClickQuest]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    setLastClickQuest(lastClickQuest - 1);
  };

  useEffect(() => {
    localStorage.removeItem("questionData");
  }, []);

  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  // useEffect(() => {
  //   (async () => {
  //     localStorage.removeItem('questionData');
  //     let status = (query_status && query_status == "success") ? 1 : 2;
  //     payload = { "milestone_key": "bank_connection", "source": source, "value": status };
  //     if (query_status && query_status == "success") {
  //       //setStatus("Fetching Account Details......");
  //       await storeApiResponse(visitorParameters.visitor_parameters, "op_milestone_update", uuid, payload);
  //     } else if (query_status && query_status == "failure") {
  //       await storeApiResponse(visitorParameters.visitor_parameters, "op_milestone_update", uuid, payload);
  //     }
  //     const response = await callBackGateWay(uuid, source, query_status, customer_id, task_id,is_test,visitorId);
  //     if (response.data.status == "success") {
  //       // setCallbackStatus(true);
  //       //setStatus("Fetching Transactions......");
  //       payload = { "milestone_key": "callback_url", "source": source };
  //       const callbackResponse = await storeApiResponse(visitorParameters.visitor_parameters, "op_milestone_update", uuid, payload);
  //       // if (task_id != null && callbackResponse.data.status == "Success") {
  //       //   const accountResponse = await fetchAccountDetails(uuid, customer_id, source,is_test,visitorId);
  //       //   if (accountResponse.data.status == "Success") {
  //       //     payload = { "milestone_key": "account_fetch", "source": source };
  //       //     const storeAccount = await storeApiResponse(visitorParameters.visitor_parameters, "op_milestone_update", uuid, payload);
  //       //     if (storeAccount.data.status == "Success") {
  //       //       const transactionResponse = await fetchTransactions(uuid, customer_id, from_date, to_date, source,is_test,visitorId);
  //       //       if (transactionResponse.data.status == "Success") {
  //       //         payload = { "milestone_key": "transaction_fetch", "source": source };
  //       //         await storeApiResponse(visitorParameters.visitor_parameters, "op_milestone_update", uuid, payload);
  //       //       }
  //       //     }
  //       //   }

  //       // }
  //     }
  //   })();
  // }, []);

  return (
    <>
      <GetVisitorsParams />
      <div className="QuestionnaireOb">
        <QuestionnaireLoder showUserName={showUserName} showLoad={showLoad} />

        <section
          className="formsection"
          style={
            showLoad === "hide" ? { display: "block" } : { display: "none " }
          }
        >
          <div className="container">
            <div className="row">
              <form
                ref={splitForm}
                name="split_form"
                id="user_form"
                method="POST"
                autoComplete="off"
                //onSubmit={handleSubmit}
              >
                <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12">
                  <div className="radio-box animated fadeInUp">
                    {/* <Questionnaire73
                        clsName={`space-top ${state.showSlide4}`}
                        reff={(el) => (scrollRef.current[73] = el)}
                        onselectAnswer={onselectAnswer}
                      /> */}

                    <Questionnaire61
                      clsName={`space-top2 ${state.showSlide1}`}
                      reff={(el) => (scrollRef.current[41] = el)}
                      onselectAnswer={onselectAnswer}
                      userEmail={userEmail}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />

                    <QuestionnaireEmail
                      clsName={`space-top2 ${state.showSlide2}`}
                      reff={(el) => (scrollRef.current[43] = el)}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      onselectAnswer={onselectAnswer}
                    />

                    <Questionnaire5
                      clsName={`space-top ${state.showSlide3}`}
                      reff={(el) => (scrollRef.current[36] = el)}
                      questArr={questinnare2}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      optionSelected={optionSelected}
                    />

                    <ConfirmSituationOB
                      clsName={`space-top ${state.showSlide10}`}
                      reff={(el) => (scrollRef.current[76] = el)}
                      questArr={questinnaire76MB}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      optionSelected76={optionSelected76}
                    />
                  </div>
                </div>
              </form>
            </div>

            <QuestionnaireFooter
              percentage={state.percentage}
              onDownClick={handleDownClick}
              onUpClick={handleUpClick}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default QuestionnaireOb;
