import React, { useEffect, useState, useReducer, useRef, useContext, } from "react";
import { useForm } from "react-hook-form";
import "../../assets/Quest_VAQ_UB_4_3/scss/modules/questionnaire.scss";
import "../../assets/Quest_VAQ_UB_4_3/scss/modules/custom.scss";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import logowhite from "../../assets/Quest_VAQ_UB_4_3/img/logo-white.png";
import Loader from "../Includes/Layouts/QuestionnaireV20/Loader";
import Footer2 from "../Includes/Layouts/Common/Footer2";
import Filed from "../Includes/Layouts/QuestionnaireV20/Filed";
import Bankruptcy from "../Includes/Layouts/QuestionnaireV20/Bankruptcy";
import StillActive from "../Includes/Layouts/QuestionnaireV20/StillActive";
import RecentCreditIncrease from "../Includes/Layouts/QuestionnaireV20/RecentCreditIncrease";
import SoldOnDept from "../Includes/Layouts/QuestionnaireV20/SoldOnDept";
import WhatYearHappen from "../Includes/Layouts/QuestionnaireV20/WhatYearHappen";
import EmploymentStatus from "../Includes/Layouts/QuestionnaireV20/EmploymentStatus";
import Dependents from "../Includes/Layouts/QuestionnaireV20/Dependents";
// import Struggle from "../Includes/Layouts/QuestionnaireV20/Struggle";
import FurtherDetails from "../Includes/Layouts/QuestionnaireV20/FurtherDetails";
import { questinnare1, questinnaire36Vanquis, questinnaire76Vanquis, answerArray, supportDocTypeArray,wereUalreadyPaying } from "../../Constants/Questions";
import HighestBalance from "../Includes/Layouts/QuestionnaireV20/HighestBalance";
import CreditLimitIncreased from "../Includes/Layouts/QuestionnaireV20/CreditLimitIncreased";
import ExceedingCreditLimit from "../Includes/Layouts/QuestionnaireV20/ExceedingCreditLimit";
import MinimumPayment from "../Includes/Layouts/QuestionnaireV20/MinimumPayment";
import IncomeAndExp from "../Includes/Layouts/QuestionnaireV20/IncomeAndExp";
import Emailconfirmation from "../Includes/Layouts/QuestionnaireV20/Emailconfirmation";
import SupportingDoc from "../Includes/Layouts/QuestionnaireV20/SupportingDoc";
import AgreementNoModal from "../Includes/Layouts/QuestionnaireV20/AgreementNoModal";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { FormData } from "../../Utility/FormData";
import { userInfo } from "../../Hooks/userInfo";
import SupportingDocUpload from "../Includes/Layouts/QuestionnaireV20/SupportingDocUpload";
import { useHistory } from "react-router-dom";
import {
  MonthNumberArray
}
  from "../../Constants/Constants";
import Footer from "../Includes/Layouts/VAQ_UB_4_3/Footer";
import ClosedYear from "../Includes/Layouts/QuestionnaireV20/ClosedYear";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import WhetherIandE from "../Includes/Layouts/QuestionnaireV20/WhetherIandE";
import ConfirmSituation from "../Includes/Layouts/QuestionnaireV20/ConfirmSituation";
import Banks from "../Includes/Layouts/QuestionnaireV20/Banks";
import { obLender } from "../../Hooks/useOblender";
import Struggle from "../Includes/Layouts/QuestionnaireV20/Struggle";
import IncomeAndExpV2 from "../Includes/Layouts/QuestionnaireV20/IncomeAndExpV2";
import DidReceiveIncome from "../Includes/Layouts/QuestionnaireV20/DidReceiveIncome";
import CardMonthlyIncome from "../Includes/Layouts/QuestionnaireV20/CardMonthlyIncome";
import WereAlreadyPaying from "../Includes/Layouts/QuestionnaireV20/WereAlreadyPaying";
import IncomeAndExpV3 from "../Includes/Layouts/QuestionnaireV20/IncomeAndExpV3.js";
import OldCard from "../Includes/Layouts/QuestionnaireV20/OldCard";
import LatePayment from "../Includes/Layouts/QuestionnaireV20/LatePayment";
import Difficulties from "../Includes/Layouts/QuestionnaireV20/Difficulties";
import FinanceProblem from "../Includes/Layouts/QuestionnaireV20/FinanceProblem";
import Aware from "../Includes/Layouts/QuestionnaireV20/Aware.js";
import SortCode from "../Includes/Layouts/QuestionnaireV20/SortCode.js";
import BankAccNo from "../Includes/Layouts/QuestionnaireV20/BankAccNo.js";

import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";
import CreditCardOpenYear from "../Includes/Layouts/QuestionnaireV20/CreditCardOpenYear.js";

const initialState = {
  showSlide1: "show",
  showSlide2: "hide",
  showSlide2a: "hide",
  showSlide3: "hide",
  showSlide3a: "hide",
  showSlide4: "hide",
  showSlide4a: "hide",
  showSlide5: "hide",
  showSlide5a: "hide",
  showSlide6: "hide",
  showSlide6a: "hide",
  showSlide7: "hide",
  showSlide7a: "hide",
  showSlide8: "hide",
  showSlide8a: "hide",
  showSlide9: "hide",
  showSlide9a: "hide",
  showSlide10: "hide",
  showSlide10a: "hide",
  showSlide11: "hide",
  showSlide11a: "hide",
  showSlide12: "hide",
  showSlide12a: "hide",
  showSlide13: "hide",
  showSlide13a: "hide",
  showSlide14: "hide",
  showSlide14a: "hide",
  showSlide15: "hide",
  showSlide15a: "hide",
  showSlide16: "hide",
  showSlide16a: "hide",
  showSlide17: "hide",
  showSlide17a: "hide",
  showSlide18:"hide",
  showSlide18a:"hide",
  // showSlide19:"hide",
  // showSlide19a:"hide",
  showSlide20:"hide",
  showSlide20a:"hide",
  showSlide21:'hide',
  showSlide21a:'hide',
  showSlide22:'hide',
  showSlide22a:'hide',
  showSlide23:'hide',
  showSlide23a:'hide',
  showSlide24:'hide',
  showSlide24a:'hide',
  showSlide25:'hide',
  showSlide25a:'hide',
  // showSlide26:'hide',
  // showSlide26a:'hide',
  showSlide27:'hide',
  showSlide27a:'hide',
  showSlide28:'hide',
  showSlide28a:'hide',
  showSlide29:'hide',
  showSlide29a:'hide',
  showSlide30:'hide',
  showSlide30a:'hide',
  showSlide31:'hide',
  showSlide32:'hide',
  showSlide33:'hide',
  showSlide34:'hide',
  showSlide34a:'hide',
  prev:"",
  closedPrev:"",
  IandEPrev:"",
  q77prev:""
};


const FormReducer = (state, action) => {
  console.log(action.payload.clickedSlide);
  switch (action.type) {
    case "showSlide": {
      if (action.payload.clickedSlide.slide == "filed") {
        GtmDataLayer({
          question: "filed a claim",
        });
        return {
          ...state,
          showSlide1: "hide",
          showSlide2: "show",
          showSlide2a: "show"
        };
      } else if (action.payload.clickedSlide.slide == "bankruptcy") {
        GtmDataLayer({
          question: "bankruptcy",
        });
        return {
          ...state,
          // showSlide2: "hide",
          showSlide2a: "hide",
          showSlide22: "show",
          showSlide22a: "show"
        };
      } else if (action.payload.clickedSlide.slide == "struggle") {
        GtmDataLayer({
          question: "hardships or struggle",
        });
        return {
          ...state,
          showSlide22a: "hide",
          showSlide3: "show",
          showSlide3a: "show"
        };
      } else if (action.payload.clickedSlide.slide == "btn_qest_36") {
        GtmDataLayer({
          question: "finance situation",
        });
        return {
          ...state,
          // showSlide3: "hide",
          showSlide3a: "hide",
          showSlide20: "show",
          showSlide20a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "btn_qest_76") {
        GtmDataLayer({
          question: "situation statements",
        });
        return {
          ...state,
          showSlide20a: "hide",
          showSlide27: "show",
        };
      } else if (action.payload.clickedSlide.slide == "oldcard" && action.payload.clickedSlide.slide_value == "236"){
        GtmDataLayer({
          question: "card over 6 years ago",
        });
        return {
          ...state,
          showSlide31: "show",
        };
      } else if (action.payload.clickedSlide.slide == "oldcard" && action.payload.clickedSlide.slide_value == "237"){
        GtmDataLayer({
          question: "card over 6 years ago",
        });
        return {
          ...state,
          showSlide21: "show",
          showSlide21a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "aware") {
        GtmDataLayer({
          question: "aware of claim",
        });
        return {
          ...state,
          showSlide28: "show",
          showSlide28a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "latepayment" && action.payload.clickedSlide.slide_value == "238"){
        GtmDataLayer({
          question: "payment letters",
        });
        return {
          ...state,
          showSlide28a: "hide",
          showSlide29: "show",
        };
      } else if (action.payload.clickedSlide.slide == "latepayment" && action.payload.clickedSlide.slide_value == "239"){
        GtmDataLayer({
          question: "payment letters",
        });
        return {
          ...state,
          showSlide21: "show",
          showSlide21a: "show",
          showSlide28a: "hide",
        };
      } else if (action.payload.clickedSlide.slide == "difficulty") {
        GtmDataLayer({
          question: "financial difficulties",
        });
        return {
          ...state,
          showSlide30: "show",
          showSlide30a: "show",
        };
      }  else if (action.payload.clickedSlide.slide == "problem") {
        GtmDataLayer({
          question: "problems in repaying",
        });
        return {
          ...state,
          showSlide30a: "hide",
          showSlide21: "show",
          showSlide21a: "show",

        };
      } else if (action.payload.clickedSlide.slide == "creditopenyear") {
        GtmDataLayer({
          question: "account year",
        });
        return {
          ...state,
          // showSlide18: "hide",
          // showSlide34a: "hide",
          showSlide4: "show",
          showSlide4a: "show",
        };
      }
       else if (action.payload.clickedSlide.slide == "stillactive" && action.payload.clickedSlide.slide_value == "95") {
        GtmDataLayer({
          question: "still active",
        });
        return {
          ...state,
          showSlide4a: "hide",
          showSlide5: "show",
          showSlide5a: "show",
        };
      }else if (action.payload.clickedSlide.slide == "stillactive" && action.payload.clickedSlide.slide_value == "96") {
        GtmDataLayer({
          question: "still active",
        });
        return {
          ...state,
          // showSlide4: "hide",
          showSlide4a: "hide",
          showSlide18: "show",
          showSlide18a: "show",
        };
      }else if (action.payload.clickedSlide.slide == "closedYear") {
        GtmDataLayer({
          question: "account year",
        });
        return {
          ...state,
          // showSlide18: "hide",
          showSlide18a: "hide",
          showSlide6: "show",
          showSlide6a: "show",
        };
      }
       else if (action.payload.clickedSlide.slide == "recentcreditIncrease") {
        GtmDataLayer({
          question: "account year",
        });
        return {
          ...state,
          // showSlide5: "hide",
          showSlide5a: "hide",
          showSlide6: "show",
          showSlide6a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "SoldOnDebt" && action.payload.clickedSlide.slide_value == "206") {
        GtmDataLayer({
          question: "sold your debt",
        });
        return {
          ...state,
          // showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7: "show",
          showSlide7a: "show",
        };
      }else if (action.payload.clickedSlide.slide == "SoldOnDebt" && action.payload.clickedSlide.slide_value == "207") {
        GtmDataLayer({
          question: "sold your debt",
        });
        return {
          ...state,
          // showSlide6: "hide",
          showSlide6a: "hide",
          showSlide7a: "hide",
          showSlide8: "show",
          showSlide8a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "WhatYearThisHappen") {
        GtmDataLayer({
          question: "year sold",
        });
        return {
          ...state,
          // showSlide7: "hide",
          showSlide7a: "hide",
          showSlide8: "show",
          showSlide8a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "employmentsts") {
        GtmDataLayer({
          question: "employment status",
        });
        return {
          ...state,
          // showSlide8: "hide",
          showSlide8a: "hide",
          showSlide10: "show",
          showSlide10a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "high_balance") {
        GtmDataLayer({
          question: "highest balance",
        });
        return {
          ...state,
          // showSlide10: "hide",
          showSlide10a: "hide",
          showSlide11: "show",
          showSlide11a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "CredLimitInc") {
        GtmDataLayer({
          question: "credit limit increase",
        });
        return {
          ...state,
          // showSlide11: "hide",
          showSlide11a: "hide",
          showSlide12: "show",
          showSlide12a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "excdCreditLimit") {
        GtmDataLayer({
          question: "exceed credit limit",
        });
        return {
          ...state,
          // showSlide12: "hide",
          showSlide12a: "hide",
          showSlide13: "show",
          showSlide13a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "minmumPaymnt") {
        GtmDataLayer({
          question: "pay minimum payment",
        });
        return {
          ...state,
          // showSlide13: "hide",
          showSlide13a: "hide",
          // showSlide19: "show",
          // showSlide19a: "show",
          showSlide23: "show",
          showSlide23a: "show",

        };
      // } else if (action.payload.clickedSlide.slide == "whetherIandE" && action.payload.clickedSlide.slide_value == "213" ) {
      //   GtmDataLayer({
      //     question: "bolster complaint",
      //   });
      //   return {
      //     ...state,
      //     // showSlide19: "hide",
      //     showSlide19a: "hide",
      //     showSlide23: "show",
      //     showSlide23a: "hide",
      //   };
      // } else if (action.payload.clickedSlide.slide == "whetherIandE" && action.payload.clickedSlide.slide_value == "214" ) {
      //   GtmDataLayer({
      //     question: "bolster complaint",
      //   });
      //   return {
      //     ...state,
      //     // showSlide19: "hide",
      //     showSlide19a: "hide",
      //     showSlide15: "show",
      //     showSlide15a: "show",
      //   };
      }else if (action.payload.clickedSlide.slide == "didReceiveIncome" && action.payload.clickedSlide.slide_value == "226") {
        GtmDataLayer({
          question: "receive any income",
        });
        return {
          ...state,
          // showSlide23: "hide",
          showSlide23a: "hide",
          showSlide24: "show",
          showSlide24a: "hide",
        };
      }else if (action.payload.clickedSlide.slide == "didReceiveIncome" && action.payload.clickedSlide.slide_value == "227") {
        GtmDataLayer({
          question: "receive any income",
        });
        return {
          ...state,
          // showSlide23: "hide",
          showSlide23a: "hide",
          showSlide9: "show",
          showSlide9a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "cardMonthlyIncome") {
        GtmDataLayer({
          question: "monthly income",
        });
        return {
          ...state,
          // showSlide24: "hide",
          showSlide24a: "hide",
          showSlide9: "show",
          showSlide9a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "dependents") {
        GtmDataLayer({
          question: "dependents",
        });
        return {
          ...state,
          // showSlide9: "hide",
          showSlide9a: "hide",
          showSlide25: "show",
          showSlide25a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "wereAlreadyPaying") {
        GtmDataLayer({
          question: "paying monthly",
        });
        return {
          ...state,
          // showSlide25: "hide",
          showSlide25a: "hide",
          showSlide15: "show",
          showSlide15a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "sortcode") {
        GtmDataLayer({
          question: "sortcode ",
        });
        return {
          ...state,
          showSlide33: "show",
        };
      } else if (action.payload.clickedSlide.slide == "bank_acc_no") {
        GtmDataLayer({
          question: "account number",
        });
        return {
          ...state,
          showSlide16: "show",
          showSlide16a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "btn_qest_48" || action.payload.clickedSlide.slide == "question_61") {
        GtmDataLayer({
          question: "registered email address",
        });
        return {
          ...state,
          //showSlide15: "hide",
          showSlide15a: "hide",
          // showSlide16: "show",
          // showSlide16a: "show",
          showSlide32: "show",
        };
      } else if (action.payload.clickedSlide.slide == "question_42") {
        GtmDataLayer({
          question: "supporting documentation",
        });
        return {
          ...state,
          // showSlide16: "hide",
          showSlide16a: "hide",
          showSlide17: "show",
          showSlide17a: "show",
        };
      } else if (action.payload.clickedSlide.slide == "support_doc") {
        GtmDataLayer({
          question: "submit documentation",
        });
        return {
          ...state,
          // showSlide17: "hide",
          showSlide17a: "hide",
        };
      } else if (action.payload.clickedSlide.slide == "banks") {
        GtmDataLayer({
          question: "bank select",
        });
        return {
          ...state,
          showSlide34: "show",
          // showSlide34a: "show",
          showSlide21a:"hide"
        };
      }
      else {
        return {
          ...state,
        };
      }
    }
    
    
      case "previousSlides": {    
         if (action.payload.previousSlide.slide == "filed") {
          return {
            ...state,
            showSlide2a:"hide",
            showSlide2: "hide",
            showSlide1: "show",
            showSlide1a: "show",
          };
        }else if (action.payload.previousSlide.slide == "bankruptcy") {
          return {
            ...state,
            showSlide22a:"hide",
            showSlide22: "hide",
            showSlide2: "show",
            showSlide2a: "show",
          };
        }
        else if (action.payload.previousSlide.slide == "struggle") {
          return {
            ...state,
            showSlide22a:"show",
            showSlide22: "show",
            showSlide3: "hide",
            showSlide3a: "hide",
          };
        }else if (action.payload.previousSlide.slide == "btn_qest_36") {
          return {
            ...state,
            showSlide20a:"hide",
            showSlide20: "hide",
            showSlide3: "show",
            showSlide3a: "show",
          };
        }else if (action.payload.previousSlide.slide == "btn_qest_76") {
          return {
            ...state,
            // showSlide21a:"hide",
            // showSlide21: "hide",
            showSlide27: "hide",
            showSlide20: "show",
            showSlide20a: "show",
          };
        } else if (action.payload.previousSlide.slide == "oldcard" && state.oldCardval == "237") {
          return {
            ...state,
            showSlide21: "hide",
            showSlide21a: "hide",
            showSlide27: "show",
          };
        } else if (action.payload.previousSlide.slide == "latepayment" && state.latePayval == "239") {
          return {
            ...state,
            showSlide21: "hide",
            showSlide21a: "hide",
            showSlide28: "show",
            showSlide28a: "show",
          };
        } else if (action.payload.previousSlide.slide == "oldcard" && state.oldCardval == "236") {
          return {
            ...state,
            // showSlide21a:"hide",
            // showSlide21: "hide",
            showSlide31: "hide",
            showSlide27: "show",
          };
        } else if (action.payload.previousSlide.slide == "aware") {
          return {
            ...state,
            showSlide28: "hide",
            showSlide28a: "hide",
            showSlide31: "show",
          };
        } else if (action.payload.previousSlide.slide == "difficulty") {
          return {
            ...state,
            showSlide30: "hide",
            showSlide30a: "hide",
            showSlide29: "show",
          };
        }  
        else if (action.payload.previousSlide.slide == "problem" && state.latePayval == "238") {
          return {
            ...state,
            showSlide21: "hide",
            showSlide21a: "hide",
            showSlide30: "show",
            showSlide30a: "show",            
          };
        } else if (action.payload.previousSlide.slide == "latepayment"&& state.latePayval == "238")  {
          return {
            ...state,
            showSlide29: "hide",
            showSlide28: "show",
            showSlide28a: "show",
          };
        } 
        else if (action.payload.previousSlide.slide == "stillactive" && state.closedPrev == "95") {
          return {
            ...state,
            showSlide5a:"hide",
            showSlide5: "hide",
            showSlide4: "show",
            showSlide4a: "show",
          };
        }else if (action.payload.previousSlide.slide == "stillactive" && state.closedPrev == "96") {
          return {
            ...state,
            showSlide18a:"hide",
            showSlide18: "hide",
            showSlide4: "show",
            showSlide4a: "show",
          };
        }else if (action.payload.previousSlide.slide == "recentcreditIncrease") {
          return {
            ...state,
            showSlide6a:"hide",
            showSlide6: "hide",
            showSlide5: "show",
            showSlide5a: "show",
          };
        }
        else if (action.payload.previousSlide.slide == "closedYear") {
          return {
            ...state,
            showSlide6a:"hide",
            showSlide6: "hide",
            showSlide18: "show",
            showSlide18a: "show",
          };
        }else if (action.payload.previousSlide.slide == "SoldOnDebt" && state.prev == "206") {
          return {
            ...state,
            showSlide7a:"hide",
            showSlide7: "hide",
            showSlide6: "show",
            showSlide6a: "show",
          };
        }else if (action.payload.previousSlide.slide == "SoldOnDebt"  && state.prev == "207" ) {
          return {
            ...state,
            showSlide8a:"hide",
            showSlide8: "hide",
            showSlide6: "show",
            showSlide6a: "show",
          };
        }else if (action.payload.previousSlide.slide == "WhatYearThisHappen") {
          return {
            ...state,
            showSlide8a:"hide",
            showSlide8: "hide",
            showSlide7: "show",
            showSlide7a: "show",
          };
        }else if (action.payload.previousSlide.slide == "employmentsts") {
          return {
            ...state,
            showSlide10a:"hide",
            showSlide10: "hide",
            showSlide8: "show",
            showSlide8a: "show",
          };
        }else if (action.payload.previousSlide.slide == "high_balance") {
          return {
            ...state,
            showSlide11a:"hide",
            showSlide11: "hide",
            showSlide10: "show",
            showSlide10a: "show",
          };
        }else if (action.payload.previousSlide.slide == "CredLimitInc") {
          return {
            ...state,
            showSlide12a:"hide",
            showSlide12: "hide",
            showSlide11: "show",
            showSlide11a: "show",
          };
        }else if (action.payload.previousSlide.slide == "excdCreditLimit") {
          return {
            ...state,
            showSlide13a:"hide",
            showSlide13: "hide",
            showSlide12: "show",
            showSlide12a: "show",
          };
        }else if (action.payload.previousSlide.slide == "minmumPaymnt") {
          return {
            ...state,
            showSlide23a:"hide",
            showSlide23: "hide",
            showSlide13: "show",
            showSlide13a: "show",
          };
        // }else if (action.payload.previousSlide.slide == "whetherIandE" && state.IandEPrev == "213") {
        //   return {
        //     ...state,
        //     // showSlide23a:"hide",
        //     showSlide23: "hide",
        //     showSlide19: "show",
        //     showSlide19a: "show",
        //   };
        // }else if (action.payload.previousSlide.slide == "whetherIandE" && state.IandEPrev == "214") {
        //   return {
        //     ...state,
        //     showSlide15a:"hide",
        //     showSlide15: "hide",
        //     showSlide19: "show",
        //     showSlide19a: "show",
        //   };
        }else if (action.payload.previousSlide.slide == "cardMonthlyIncome") {
          return {
            ...state,
            showSlide9a:"hide",
            showSlide9: "hide",
            showSlide24: "show",
            // showSlide24a: "hide",
          };
        }else if (action.payload.previousSlide.slide == "didReceiveIncome" && state.q77prev == "226") {
          return {
            ...state,
            showSlide24: "hide",
            showSlide23: "show",
            //showSlide23a: "show",
          };
        }else if (action.payload.previousSlide.slide == "didReceiveIncome" && state.q77prev == "227" ) {
          return {
            ...state,
            showSlide9a:"hide",
            showSlide9: "hide",
            showSlide23: "show",
            // showSlide23a: "hide",
          };
        }else if (action.payload.previousSlide.slide == "dependents") {
          return {
            ...state,
            showSlide25a:"hide",
            showSlide25: "hide",
            showSlide9: "show",
            showSlide9a: "show",
          };
        }else if (action.payload.previousSlide.slide == "wereAlreadyPaying") {
          return {
            ...state,
            showSlide15a:"hide",
            showSlide15: "hide",
            showSlide25: "show",
            showSlide25a: "show",
          };
        }else if (action.payload.previousSlide.slide == "btn_qest_48") {
          return {
            ...state,
            // showSlide16a:"hide",
            // showSlide16: "hide",
            // showSlide15: "show",
            // showSlide15a: "show",
            showSlide32: "hide",
            showSlide15: "show",
            showSlide15a: "show",
          };
        }else if (action.payload.previousSlide.slide == "question_42") {
          return {
            ...state,
            showSlide17a:"hide",
            showSlide17: "hide",
            // showSlide16: "show",
            // showSlide16a: "show",
            showSlide33 : "show",
          };
        } else if (action.payload.previousSlide.slide == "sortcode") {
          return {
            ...state,
            showSlide33:"hide",
            showSlide32: "show",
          };
        } else if (action.payload.previousSlide.slide == "bank_acc_no") {
          return {
            ...state,
            showSlide16:"hide",
            showSlide16a:"hide",
            showSlide33: "show",
          };
        } else if (action.payload.previousSlide.slide == "banks") {
          return {
            ...state,
            // showSlide34a:"hide",
            showSlide34: "hide",
            // showSlide21: "show",
            showSlide21a:'show'
          };
        } else if (action.payload.previousSlide.slide == "creditopenyear") {
          return {
            ...state,
            showSlide4a:"hide",
            showSlide4: "hide",
            showSlide34: "show",
            // showSlide34a:'show'
          };
        }   
       
       
      }
      default:
      return state;
  }
};
const QuestionnaireV20 = () => {

  const splitForm = useRef(null);
  const scrollRef = useRef([]);
  const history = useHistory();
  const [showLoad, setShowLoad] = useState("");
  const [clickedSlide, setClickedSlide] = useState([]);
  const [state, dispatch] = useReducer(FormReducer, initialState);
  const [optionSelected] = useState([]);
  const [SelectedOptions] = useState([]);
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const [isRegisteredEmail, setIsRegisteredEmail] = useState('show');
  const [isNewEmailVisible, setIsNewEmailVisible] = useState('hide');
  const [agNoShow, setAgNoShow] = useState("hide");
  const { skippedLeads } = useSkippedLeads();
  const { isLoading, SaveQuestionnaire } = useQuestionnaire();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const query_string = localStorage.getItem("querystring");
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const { setFormData } = FormData();
  const [splitName, setSplitName] = useState();
  const [splitNameForSkip, setSplitNameForSkip] = useState();
  const [docType, setDocType] = useState("");
  const [showUserName, setUserName] = useState("");
  const { getUserInfo } = userInfo();
  const [supportDocType, setSupportDocType] = useState("");
  const [userEmail, setUserEmail] = useState();
  const [supportDoc, setSupportDoc] = useState("");
  const [questionCounter, setQuestionCounter] = useState(0);
  let [totalQuestArr, setTotalQuestArr] = useState([61,56,55,54,53,3,2,72,71,70,26,31,36,76,9,30,62,42,46,64,78,79,80,81,82,82,84,85,86,87,88]);
  const [previousSlide, setPreviousSlide] = useState([]);
  const [previous, setPrevious] = useState("");
  const [quest77Previous, setquest77Previous] = useState("");
  const [IandEPrev, setiandEPrev] = useState("");
  const [percentage, SetPercentage] = useState(0);
  const [stillAct, setStillAct] = useState("");
  const [lenderList, setLenderList] = useState([]);
  const [SelectBank, setSelectBank] = useState([]);
  const { getLenderlist } = obLender();
  const [checklistOptions, setChecklistOptions] = useState([]);
  const [inputListValues, setInputListValues] = useState([]);
  const [oldCard, setOldCard] = useState("");
  const [latePay, setlatePay] = useState("");
  const [sortCode, setSortCode] = useState(false);
  const [accNum, setAccNum] = useState(false);
  const {updateMilestone} = useUpdateMilestone();
  const [cardOpenYr, setCardOpenYr] = useState([]);
  const [productType,setProductType] = useState('');

  const { register, errors, trigger, setError, clearErrors, getValues,setValue } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });

  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();

  useEffect(() => {
    const ProductId = localStorage.getItem("ProductType");
    if(ProductId == "167"){
      setProductType("loan");
    }else{
      setProductType("card");
    }
    if (formdata === undefined || formdata === null || formdata == "") {
      (async () => {
        const name = localStorage.getItem("UcFname") ;
        const response = await getUserInfo(uuid);
        const set_formdata = setFormData(response);
        let dataLength = response.data.response.length;
        if (dataLength > 0) {
          const pageName = response.data.response[0].page_name
            ? response.data.response[0].page_name
            : " ";
          setSplitName(pageName);
          if (localStorage.getItem("split_name") != undefined && localStorage.getItem("split_name") != undefined) {
            setSplitNameForSkip(localStorage.getItem("split_name"));
          } else {
            setSplitNameForSkip(pageName);
          }
          const userName = response.data.response[0].first_name
            ? response.data.response[0].first_name +
            " " +
            response.data.response[0].last_name
            : " ";
          var userDobN = response.data.response[0].user_dob.split('/');
          var DobDayF = userDobN[0];
          var DobMonthF = userDobN[1];
          var DobYearF = userDobN[2];
          setSupportDocType(response.data.response[0]?.questionnaire[42]['answerId']);

          setUserName(name);
          setUserEmail(response.data.response[0].email);
          const timer = setTimeout(() => {
            setShowLoad("hide");
          }, 3000);
        }
      })();
    } else {
      const name = localStorage.getItem("UcFname") ;
      const pageName = formdata.page_name;
      setSplitName(pageName);
      if (localStorage.getItem("split_name") != undefined && localStorage.getItem("split_name") != undefined) {
        setSplitNameForSkip(localStorage.getItem("split_name"));
      } else {
        setSplitNameForSkip(pageName);
      }
      const userName = formdata.txtFName + " " + formdata.txtLName;
      setUserName(name);
      const dobMonth = formdata.DobMonth ? MonthNumberArray[formdata.DobMonth] : '';

      const timer = setTimeout(() => {
        setShowLoad("hide");
      }, 3000);
      setSupportDocType(formdata.question_42);
      setSupportDoc(answerArray[formdata.question_42]);
      setDocType(supportDocTypeArray[formdata.question_42]);
      setUserEmail(formdata.txtEmail);
    }

  }, [splitName]);

  state.prev = previous;
  state.closedPrev = stillAct;
  state.IandEPrev = IandEPrev;
  state.q77prev = quest77Previous;
  state.oldCardval = oldCard;
  state.latePayval = latePay;


  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
      scrollToElement(clickedSlide.slide);
      setTickToElement(clickedSlide);
      setQuestionCounter(questionCounter+1);
    }

  }, [clickedSlide]);

  const previousSlideChange = (e) => {
    setQuestionCounter((questionCounter)-1);
    setPreviousSlide({ slide: e.target.getAttribute("name") });
  };

  useEffect(() => {
    if (previousSlide) {
      dispatch({ type: "previousSlides", payload: { previousSlide } });
      scrollToElement(previousSlide.slide);
      // setTimeout(() => {
      //   window.scrollTo(0,0);
      // }, 50);
      slideBack(previousSlide);
      // setQuestionCounter(questionCounter-1);
    }
  }, [previousSlide]);


  const slideBack = (element) => {
    scrollRef.current[element.slide]?.classList.remove("active");

    scrollRef.current[element.slide]?.querySelector(".question-content").classList.remove("hide");
  
};



  const storeQuestionnire = async (question_data) => {
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      query_string,
      formdata
    );
  };

  const storeUnqualifiedQuestionnire = async (question_data) => {
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "unqualified_question_store",
      visitorParameters.data,
      query_string,
      formdata
    );
  };


  const onSelectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    const valuesreg = getValues();
    let employment = valuesreg["employment"];
    let validEmail = valuesreg["question_48"];
    let dependents = valuesreg["dependents"];
    let didReceiveIncome = valuesreg["didReceiveIncome"];
    let cardMonthlyIncome = valuesreg["cardMonthlyIncome"];
    let credLimitIncreased = splitForm.current["incresedCreditLimit"].value;
    let excredLimit = splitForm.current["exceedCreditLimit"].value;
    let minPayment = splitForm.current["minimumPayment"].value;
    let flage = false;
    let question_data = {};
    console.log(selectedAns);
    console.log(selectedQuest);
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    if (e.target.name == 'employmentsts' && employment) {
      setClickedSlide({ slide: "employmentsts" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: employment,
        answer_text: "",
        input_answer: null,
      };
    }
     else if (e.target.name == 'dependents' && dependents) {
      setClickedSlide({ slide: "dependents" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: dependents,
      };
    } else if (e.target.name == 'didReceiveIncome' && didReceiveIncome) {
      setClickedSlide({ slide: "didReceiveIncome" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: didReceiveIncome,
        answer_text: "",
        input_answer: null,
      }; 
    } else if (e.target.name == 'cardMonthlyIncome' && cardMonthlyIncome) {
      setClickedSlide({ slide: "cardMonthlyIncome" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: cardMonthlyIncome,
      };  
    } else if (e.target.name == "btn_qest_36") {
      setClickedSlide({ slide: "btn_qest_36" });
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: optionSelected,
        };
      }
    } else if (e.target.name == "btn_qest_76") {
    setClickedSlide({ slide: "btn_qest_76" });
    if (selectedQuest) {
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: SelectedOptions,
      };
     }
    } else if (e.target.name == "wereAlreadyPaying") {
      setClickedSlide({ slide: "wereAlreadyPaying" });
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: checklistOptions,
        };
      }
    } else if (e.target.name == "CredLimitInc" && credLimitIncreased) {
      setClickedSlide({ slide: "CredLimitInc" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: credLimitIncreased,
        answer_text: anslabel,
        input_answer: null,
      };

    } else if (e.target.name == "excdCreditLimit" && excredLimit) {
      setClickedSlide({ slide: "excdCreditLimit" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: excredLimit,
        answer_text: anslabel,
        input_answer: null,
      };

    } else if (e.target.name == "minmumPaymnt" && minPayment) {
      setClickedSlide({ slide: "minmumPaymnt" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: minPayment,
        answer_text: anslabel,
        input_answer: null,
      };

    } else if (e.target.name == "btn_qest_48" && validEmail) {
      setClickedSlide({ slide: "btn_qest_48" });
      flage = true;
      question_data = {
        question_id: 48,
        option_id: null,
        answer_text: null,
        input_answer: validEmail,
      };
    }
    else if (e.target.name == "question_61" && selectedAns == "163") {
      // setClickedSlide({ slide: "question_61"});
      totalQuestArr.push(48);
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: null,
        input_answer: null,
      };
    } else if (e.target.name == 'question_42') {

      if (selectedAns && selectedQuest) {
        setClickedSlide({ slide: e.target.name });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: null,
          input_answer: "",
        };
        if (selectedAns == '124') {
          setAgNoShow('show');
        }
      }
      setClickedSlide({ slide: "question_42" });
    }

    else {
      if (selectedAns && selectedQuest) {
        setClickedSlide({ slide: e.target.name, slide_value: e.target.value });
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: null,
          input_answer: "",
        };
      }
    }
    console.log(question_data);
    if (flage) {
      storeQuestionnire(question_data);
      // var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      // if (existingEntries == null) existingEntries = [];
      // localStorage.setItem(
      //   "currentquestionData",
      //   JSON.stringify(question_data)
      // );
      // existingEntries.push(question_data);
      // localStorage.setItem("questionData", JSON.stringify(existingEntries));

       let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
      if (existingIndex !== -1) {
          _existingEntries.splice(existingIndex, 1);
      }
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));

      if (selectedAns == '123') {
        GtmDataLayer({
          question: "supporting documentation",
        });
        redirectToPreview();
      }
    }
  }
  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    const valuesreg = getValues();
    let creditIncrease = valuesreg["RecentCreditIncrease"];
    let whatYear = valuesreg["whatYearThisHappen"];
    let highBalance = valuesreg["highest_balance"];
    let financeAgremmentNo = valuesreg["question_45"];
    let aware_issue = splitForm.current["aware_reason"].value;
    let closedYr = valuesreg["closedyears"];
    let creditOpenYr = valuesreg["creditopenyears"];
    let problemvalue = splitForm.current["finance"].value;
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    console.log(selectedQuest);
    console.log(creditIncrease);
    let flage = false;
    let question_data = {};
    let bud_lender = SelectBank.value;

    if (e.target.name == "recentcreditIncrease" && creditIncrease) {
      setClickedSlide({ slide: "recentcreditIncrease" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: creditIncrease,
      };

    }else if (e.target.name == "dontRecall") {
      setClickedSlide({ slide: "recentcreditIncrease" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: "I Don't Recall",
      };
    }
     else if (e.target.name == "WhatYearThisHappen" && whatYear) {
      setClickedSlide({ slide: "WhatYearThisHappen" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: whatYear,
      };

    } else if (e.target.name == 'problem' && problemvalue) {
      setClickedSlide({ slide: "problem" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: problemvalue.trim(),
      };
    } else if (e.target.name == "aware" && aware_issue) {
      setClickedSlide({ slide: "aware" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: 244,
        answer_text: "",
        input_answer: aware_issue.trim(),
      };
    } else if (e.target.name == "sortcode") {
      setClickedSlide({ slide: "sortcode" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: sortCode,
      };
    } else if (e.target.name == "bank_acc_no") {
      setClickedSlide({ slide: "bank_acc_no" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: accNum,
      };
    } else if (e.target.name == "closedYear" && closedYr) {
      setClickedSlide({ slide: "closedYear" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: closedYr,
      };

    }else if (e.target.name == "creditopenyear" && creditOpenYr) {
      setClickedSlide({ slide: "creditopenyear" });
      setCardOpenYr(creditOpenYr);
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: creditOpenYr,
      };

    } else if (e.target.name == "high_balance" && highBalance) {
      setClickedSlide({ slide: "high_balance" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: highBalance,
      };

    } else if (selectedQuest == '45' && financeAgremmentNo) {
      setAgNoShow('hide');
      // setClickedSlide({ slide: "question_45" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: financeAgremmentNo,
      };
    } else if (e.target.name == "banks" && bud_lender) {
      console.log("banks",bud_lender,selectedQuest);
      setClickedSlide({ slide: "banks" });
      flage = true;
      question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: bud_lender,
      };
  }
    console.log(question_data);
    if (flage) {
      storeQuestionnire(question_data);
      // var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      // if (existingEntries == null) existingEntries = [];
      // localStorage.setItem(
      //   "currentquestionData",
      //   JSON.stringify(question_data)
      // );
      // existingEntries.push(question_data);
      // localStorage.setItem("questionData", JSON.stringify(existingEntries));

      let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (_existingEntries == null) _existingEntries = [];
      let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
      if (existingIndex !== -1) {
          _existingEntries.splice(existingIndex, 1);
      }
      _existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(_existingEntries));

      if (selectedQuest == '45') {
        GtmDataLayer({
          question: "credit agreement number",
        });
        redirectToPreview();
      }
    }


  }

  // const saveSlide4 = async (e) => {
  //   let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
  //   let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));

  //   setCompletedQuestArr([...completedQuestArr, selectedQuest]);

  //   var answer31 = splitForm.current["question_1"].value;
  //   var answer32 = splitForm.current["question_3"].value;
  //   var answer33 = splitForm.current["question_4"].value;


  //   // let answer57 = splitForm.current["question_57"].value;
  //   // let answer58 = splitForm.current["question_58"].value;
  //   // let answer59 = splitForm.current["question_59"].value;
  //   // let answer60 = splitForm.current["question_60"].value;

  //   var slide_array = [
  //     ["31", answer31],
  //     ["32", answer32],
  //     ["33", answer33],
  //     // ["57", answer57],
  //     // ["58", answer58]
  //   ];
  //   // if (answer59 != '') {
  //   //   slide_array.push(["59", answer59]);
  //   // }
  //   // if (answer60 != '') {
  //   //   slide_array.push(["60", answer60]);
  //   // }


  //   var question_data = {
  //     slide_4: slide_array,
  //   };
  //   setClickedSlide({ slide: "incmAndMonth" });
  //   storeQuestionnire(question_data);
  //   console.log(question_data);

  //   // let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
  //   // if (_existingEntries == null) _existingEntries = [];
  //   // _existingEntries.push(question_data);
  //   // localStorage.setItem("questionData", JSON.stringify(_existingEntries));

  //   let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
  //   if (_existingEntries == null) _existingEntries = [];
  //   let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
  //   if (existingIndex !== -1) {
  //       _existingEntries.splice(existingIndex, 1);
  //   }
  //   _existingEntries.push(question_data);
  //   localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  // };
  const saveSlide80 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
  
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
  
    const values = getValues();
    let slide_array = [];
  
    if (checklistOptions.some((option) => option.id === 228)) {
      let answer228 = splitForm.current["question_228_229"].value;
      slide_array.push(["228", answer228]);
    }
    if (checklistOptions.some((option) => option.id === 229)) {
      let answer229 = splitForm.current["question_228_229"].value;
      slide_array.push(["229", answer229]);
    }
    if (checklistOptions.some((option) => option.id === 230)) {
      let answer230 = splitForm.current["question_230"].value;
      slide_array.push(["230", answer230]);
    }
    if (checklistOptions.some((option) => option.id === 231)) {
      let answer231 = splitForm.current["question_231"].value;
      slide_array.push(["231", answer231]);
    }
    if (checklistOptions.some((option) => option.id === 232)) {
      let answer232 = splitForm.current["question_232"].value;
      slide_array.push(["232", answer232]);
    }
    if (checklistOptions.some((option) => option.id === 233)) {
      let answer233 = splitForm.current["question_233"].value;
      slide_array.push(["233", answer233]);
    }
    if (checklistOptions.some((option) => option.id === 234)) {
      let answer234 = splitForm.current["question_234"].value;
      slide_array.push(["234", answer234]);
    }
    if (checklistOptions.some((option) => option.id === 235)) {
      let answer235 = splitForm.current["question_235"].value;
      slide_array.push(["235", answer235]);
    }

  
    var question_data = {
      slide_80: ["80",JSON.stringify(slide_array)],
    };
  
    setClickedSlide({ slide: "incmAndMonth" });
    storeQuestionnire(question_data);
  
    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    let existingIndex = _existingEntries.findIndex(entry => entry.question_id === question_data.question_id);
    if (existingIndex !== -1) {
      _existingEntries.splice(existingIndex, 1);
    }
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  const saveIncomeAndExpense = (checklistOptions, inputListValues) => {
    var question_data_79 = {
      question_id: 79,
      option_id: null,
      answer_text: null,
      input_answer: checklistOptions,
    };
    var question_data_80 = {
      slide_80: ["80", JSON.stringify(inputListValues)],
    };

    setClickedSlide({ slide: "wereAlreadyPaying" });
    storeQuestionnire(question_data_79);
    storeQuestionnire(question_data_80);
    setDataToLocalStorage(79, question_data_79);
    setDataToLocalStorage(80, question_data_80);
  }

  const unQualifiedAnswer = (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let flag = false;
    let question_data = {};

    if (selectedQuest && selectedAns){
      flag = true;
      question_data = {
        question_id: selectedQuest,
        option_id: selectedAns,
        answer_text: "",
        input_answer: null,
      };
   }

    if (flag == true) {
      storeUnqualifiedQuestionnire(question_data);
    }
  };

  const setDataToLocalStorage = (quest_id, quest_data) => {
    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    let existingIndex;
    if(quest_id == 80) {
      existingIndex = _existingEntries.findIndex(entry => (entry?.slide_80));
    } else {
      existingIndex = _existingEntries.findIndex(entry => entry.question_id == quest_id);
    }
    if (existingIndex !== -1) {
      _existingEntries.splice(existingIndex, 1);
    }
    _existingEntries.push(quest_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  }

  
  

  useEffect(() => {

    var value = questionCounter;
    if(value == 1){
      SetPercentage(0);
    }else{
      SetPercentage(
        Math.round(((value-1) * 100) / totalQuestArr.length)
      );
    }
		

	}, [questionCounter]);

  const scrollToElement = (element) => {

    scrollRef.current[element]?.scrollIntoView({ behavior: "smooth", block: 'start', inline: 'center' });
  };

  const setTickToElement = (element) => {
    if (element.slide == "question_61") {
      scrollRef.current['btn_qest_48']?.classList.add("active");
    } else {
      scrollRef.current[element.slide]?.classList.add("active");
    }
    if (element.slide == "question_61" && element.slide_value == '162') {
      scrollRef.current['btn_qest_48']?.querySelector(".question-content").classList.add("hide");
    }
    if (element.slide === 'WhatYearThisHappen') {
      scrollRef.current['WhatYearThisHappen']?.querySelector(".question-content").classList.remove("show");
      scrollRef.current['WhatYearThisHappen']?.querySelector(".question-content").classList.add("hide");
    }

    scrollRef.current[element.slide]?.querySelector(".question-content").classList.add("hide");
    // scrollRef.current[element]?.querySelector(".tipbox").classList.add("hide");
  };
  const pushPageChangeV2 = () => {
    GtmDataLayer({
    	'question': 'submit documentation'
      });

      updateMilestone(visitorParameters.visitor_parameters.uuid,"questions","live");

    history.push("/Verify_VAQ_UB_4?uuid=" + visitorParameters.visitor_parameters.uuid);
  };

  const redirectToPreview = async () => {
    updateMilestone(visitorParameters.visitor_parameters.uuid,"questions","live");
    history.push("/Verify_VAQ_UB_4?uuid=" + visitorParameters.visitor_parameters.uuid);
  }

  const SkippedLeads = async (type,flag = true) => {
    const skippedLeadsResponse = await skippedLeads(type, uuid, splitName);
    if (flag && skippedLeadsResponse.data.status == "Success") {
      redirectToPreview();
    }
  }
  const skipAgNo = async (e) => {
    setAgNoShow('hide');
    SkippedLeads("SkipAgreementNo");
  }

  // bank lenders
  const getLender = async () => {
    try {
        const response = await getLenderlist();
        console.log(response.data.providers.length);
        const dataLength = response.data.providers.length;

        if (dataLength > 0) {
            setLenderList(response.data.providers);
        }
    } catch (error) {
        console.error("Error fetching lender list:", error);
    }
}
useEffect(() => {
  getLender();
}, []);

  return (
    <>
      <GetVisitorsParams />

      <div className="questionnaire20">
        <section className="banner qs-section">
          <div className="container">
            <div>
              <div className="brand">
                <div className="logo pt-3 text-center">
                  <a>
                    {" "}
                    <img src={logowhite} style={{ height: 60 }} alt=""></img>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="container mt-5 mb-5">
            <Loader showUserName={showUserName} showLoad={showLoad} />

            <div
              id="quetWraper"
              className="questionnair-section form-style mb-5"
              style={
                showLoad === "hide"
                  ? { display: "block" }
                  : { display: "none " }
              }
            >
              <div
                id="rangequestions"
                className="qrange d-flex align-items-sm-center text-center"
              >
                Completed
                <br className="giveSpace" />
                &nbsp;{percentage}%
              </div>

              <form
                ref={splitForm}
                name="split_form"
                id="user_form"
                autoComplete="off"
              >
                <ul className="p-0 m-0 questionist mb-5">
                  <li>
                    <div
                      className="questitem "
                      id="slide-1"
                      ref={(el) => (scrollRef.current["filed"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        Have you, (or a company on your behalf), ever filed a
                        claim with Vanquis?{" "}
                      </h1>

                      <Filed
                        clsName={`${state.showSlide1}`}
                        onSelectAnswer={onSelectAnswer}
                        unQualifiedAnswer={unQualifiedAnswer}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide1}`}>
                      This inquiry aims to determine if you have previously submitted a claim to Vanquis. If you have, you will not qualify for another claim.
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide2}`}
                      id="slide-2"
                      ref={(el) => (scrollRef.current["bankruptcy"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        Are you currently subject to Bankruptcy or Trust deed?
                      </h1>
                      <Bankruptcy
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                        unQualifiedAnswer={unQualifiedAnswer}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide2a}`}>
                      This question is posed to assess your financial situation.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                  <div className={`questitem ${state.showSlide22}` } id="slide9" ref={(el) => (scrollRef.current["struggle"] = el)}>
                  <h1 className="que-title p-3 ">Have you <b>experienced any hardships or struggle in making the repayments to
                      Vanquis</b> on time while still meeting all your other commitments? </h1>
                      <Struggle
                      onSelectAnswer={onSelectAnswer}                        
                      backClick={previousSlideChange}
                      unQualifiedAnswer={unQualifiedAnswer}
                      />
                  </div>
                  <div className={`tipbox tip2 ${state.showSlide22a}`}>
                  This is to understand you have any hardships or struggle in making the repayments.
                      <div className="arrow  arrow-top" />
                    </div>
                </li>
                  <li>
                    <div
                      className={`questitem q3 ${state.showSlide3}`}
                      id="slide-3"
                      ref={(el) => (scrollRef.current["btn_qest_36"] = el)}
                    >
                      <h1 className="que-title p-3">
                      <b>At the time you secured your finance with Vanquis</b>, which
                        of the below statements accurately describe your
                        situation
                      </h1>
                      <FurtherDetails
                        onSelectAnswer={onSelectAnswer}
                        questArr={questinnaire36Vanquis}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        optionSelected={optionSelected}
                        backClick={previousSlideChange}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide3a}`}>
                      Your feedbacks helps support the basis of claims.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>

                  <li>
                    <div
                      className={`questitem q3 ${state.showSlide20}`}
                      id="slide-20"
                      ref={(el) => (scrollRef.current["btn_qest_76"] = el)}
                    >
                      <h1 className="que-title p-3">
                        Thanks for that, <b>please confirm if any of these
                        statements</b> also accurately describe your situation.
                      </h1>
                      <ConfirmSituation
                        onSelectAnswer={onSelectAnswer}
                        optionArr={questinnaire76Vanquis}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        SelectedOptions={SelectedOptions}
                        backClick={previousSlideChange}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide20a}`}>
                      Your feedbacks helps support the basis of claims.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide27} z-3`}
                      id="slide-27"
                      ref={(el) => (scrollRef.current["oldcard"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                      {`Did you take the ${productType} over 6 years ago?`}
                      </h1>

                      <OldCard
                        setOldCard={setOldCard}
                        backClick={previousSlideChange}
                        onSelectAnswer={onSelectAnswer}
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide31} z-3`}
                      id="slide-31"
                      ref={(el) => (scrollRef.current["aware"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                      When did you become aware you could make a claim against Vanquis?
                      </h1>

                      <Aware
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        setValue={setValue}
                        backClick={previousSlideChange}
                        onSelectAnswer={onSelectAnswer}
                        saveInputAns={saveInputAns}
                        setError={setError}
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide28} z-3`}
                      id="slide-28"
                      ref={(el) => (scrollRef.current["latepayment"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                      Did you receive any late payment letters / any other notifications from them or was your account passed to a debt company?
                      </h1>

                      <LatePayment
                        setlatePay={setlatePay}
                        setOldCard={setOldCard}
                        backClick={previousSlideChange}
                        onSelectAnswer={onSelectAnswer}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide28a}`}>
                    Do you recall ever receiving any messages from Vanquis regarding late payments / issues / sold to Lowell / Arrow Global?
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide29} z-3`}
                      id="slide-29"
                      ref={(el) => (scrollRef.current["difficulty"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                      When you received the communications from the lender, did you feel that any difficulties you had repaying / financially were:
                      </h1>
                      <Difficulties
                        backClick={previousSlideChange}
                        onSelectAnswer={onSelectAnswer}
                        unQualifiedAnswer={unQualifiedAnswer}
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem  ${state.showSlide30}`}
                      id="slide-9"
                      ref={(el) => (scrollRef.current["problem"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                      Please describe what really caused you to have problems in repaying / financially?
                      </h1>
                      <FinanceProblem
                         validation={register}
                         clearErrors={clearErrors}
                         trigger={trigger}
                         validationMsg={errors}
                         getValues={getValues}
                         backClick={previousSlideChange}
                         saveInputAns={saveInputAns}
                         setError={setError}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide30a}`}>
                    e.g. A change in circumstances / job loss / illness / divorce etc
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide21} z-3`}
                      id="slide-21"
                      ref={(el) => (scrollRef.current["banks"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                      Which <b>bank were you with when you took the finance</b> with Vanquis?
                      </h1>
                      <Banks
                        backClick={previousSlideChange}
                        lenderList={lenderList}
                        SelectBank={SelectBank}
                        setSelectBank={setSelectBank}
                        saveInputAns={saveInputAns}
                        oldCard={oldCard}
                        latePay={latePay}
                        show={state.showSlide21a}
                      />
                    </div>
                      <div className={`tipbox tip2 ${state.showSlide21a}`}>
                      Details regarding your lender are crucial for ensuring the seamless progress of your claim.

                        <div className="arrow  arrow-top" />
                      </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide34}`}
                      id="slide-18"
                      ref={(el) => (scrollRef.current["creditopenyear"] = el)}
                    >
                       <h1 className="que-title p-3 ">
                       {productType == "loan" ? 'What year did you open your Vanquis loan?' : 'What year did you open your Vanquis credit card?'}
                      </h1>
                      <CreditCardOpenYear
                        saveInputAns={saveInputAns}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        backClick={previousSlideChange}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide34a}`}>
                      Your response to this question help us in assessing your
                      eligibility for the claim process. If your account was
                      closed prior to 2016, you will not qualify for this claim.
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide4}`}
                      id="slide-4"
                      ref={(el) => (scrollRef.current["stillactive"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        Is your <b>account with Vanquis</b> still active?
                      </h1>
                      <StillActive
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                        setStillAct={setStillAct}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide4a}`}>
                      This is to determine the status of your Vanquis account.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide18}`}
                      id="slide-18"
                      ref={(el) => (scrollRef.current["closedYear"] = el)}
                    >
                     <h1 className="que-title p-3 ">
                        What <b>year did you close</b> the account?
                      </h1>
                      <ClosedYear
                        saveInputAns={saveInputAns}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        backClick={previousSlideChange}
                        cardOpenYr={cardOpenYr}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide18a}`}>
                      Your response to this question help us in assessing your
                      eligibility for the claim process. If your account was
                      closed prior to 2016, you will not qualify for this claim.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide5}`}
                      id="slide-5"
                      ref={(el) =>
                        (scrollRef.current["recentcreditIncrease"] = el)
                      }
                    >
                      <h1 className="que-title p-3 ">
                        What year was the <b>last credit limit increase</b> on your
                        account, if ever?
                      </h1>
                      <RecentCreditIncrease
                        saveInputAns={saveInputAns}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        backClick={previousSlideChange}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide5a}`}>
                      If you're unsure,please select "I Don't Recall" to avoid
                      neagatively effecting the claim.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide6}`}
                      id="slide-6"
                      ref={(el) => (scrollRef.current["SoldOnDebt"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        Has Vanquis sold on your debt, if any, to a company such
                        as Lowell Portfolio / Arrow Global?
                      </h1>
                      <SoldOnDept
                        onSelectAnswer={onSelectAnswer}
                        validation={register}
                        backClick={previousSlideChange}
                        setPrevious={setPrevious}
                        stillAct={stillAct}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide6a}`}>
                      Answer to this question will help us identify your debt
                      profile.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide7}`}
                      id="slide-7"
                      ref={(el) =>
                        (scrollRef.current["WhatYearThisHappen"] = el)
                      }
                    >
                      <h1 className="que-title p-3 ">
                        What year did this happen?{" "}
                      </h1>
                      <WhatYearHappen
                        saveInputAns={saveInputAns}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        backClick={previousSlideChange}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide7a}`}>
                      This is to identify the year in which your debt incurred.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li id="lop">
                    <div
                      className={`questitem ${state.showSlide8}`}
                      id="slide-8"
                      ref={(el) => (scrollRef.current["employmentsts"] = el)}
                    >
                       <h1 className="que-title p-3 ">
                        At the <b>time you took out the account with Vanquis</b>, what
                        was your employment status?{" "}
                      </h1>
                      <EmploymentStatus
                        onSelectAnswer={onSelectAnswer}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        backClick={previousSlideChange}
                        previous={previous}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide8a}`}>
                      We require details regarding your employment status at the
                      time you obtained the loan, as it aids in assessing your
                      financial situation..
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem q10 ${state.showSlide10}`}
                      id="slide-10"
                      ref={(el) => (scrollRef.current["high_balance"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        Approximately what was the{" "}
                        <b>highest limit you ever had with Vanquis</b> credit
                        account?
                      </h1>
                      <HighestBalance
                        saveInputAns={saveInputAns}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        backClick={previousSlideChange}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide10a}`}>
                      Your response to this question helps us to evaluate your
                      financial stability.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem q11 ${state.showSlide11}`}
                      id="slide-11"
                      ref={(el) => (scrollRef.current["CredLimitInc"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        Approximately how many{" "}
                        <strong>
                          {" "}
                          times was your credit limit increased on this account?
                        </strong>{" "}
                      </h1>
                      <CreditLimitIncreased
                        onSelectAnswer={onSelectAnswer}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        getValues={getValues}
                        splitForm={splitForm}
                        backClick={previousSlideChange}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide11a}`}>
                      This assists us in recognizing your ability to repay.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem q12 ${state.showSlide12}`}
                      id="slide-12"
                      ref={(el) => (scrollRef.current["excdCreditLimit"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        How often would you have a{" "}
                        <strong>
                          {" "}
                          balance which was close to, or exceeding your credit
                          limit?{" "}
                        </strong>
                      </h1>
                      <ExceedingCreditLimit
                        onSelectAnswer={onSelectAnswer}
                        splitForm={splitForm}
                        getValues={getValues}
                        backClick={previousSlideChange}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide12a}`}>
                      This assists us in evaluating your expenses.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem q13  ${state.showSlide13}`}
                      id="slide-13"
                      ref={(el) => (scrollRef.current["minmumPaymnt"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                        How often did you only{" "}
                        <strong>
                          {" "}
                          make the minimum payment due on the monthly account
                          statement
                        </strong>{" "}
                        (rather than paying off a larger amount)?
                      </h1>
                      <MinimumPayment
                        onSelectAnswer={onSelectAnswer}
                        splitForm={splitForm}
                        getValues={getValues}
                        backClick={previousSlideChange}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide13a}`}>
                      This is to understand your repaying pattern every month.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  {/* <li>
                    <div
                      className={`questitem ${state.showSlide19}`}
                      id="slide-19"
                      ref={(el) => (scrollRef.current["whetherIandE"] = el)}
                    >
                     <h1 className="que-title p-3 ">
                        Would you also like to <b>share your income and expenditure
                        at the time you took out the finance</b> with Vanquis?
                      </h1>
                      <WhetherIandE
                        onSelectAnswer={onSelectAnswer}
                        validation={register}
                        backClick={previousSlideChange}
                        setiandEPrev={setiandEPrev}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide19a}`}>
                      This helps us in evaluating your monthly income and
                      expenditures..
                      <div className="arrow  arrow-top" />
                    </div>
                  </li> */}
                  <li>
                    <div
                      className={`questitem ${state.showSlide23}`}
                      id="slide-23"
                      ref={(el) => (scrollRef.current["didReceiveIncome"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                      {`In the year when you took the ${productType} - did you receive any income?`}
                      </h1>
                      <DidReceiveIncome
                        onSelectAnswer={onSelectAnswer}
                        validation={register}
                        backClick={previousSlideChange}
                        setquest77Previous={setquest77Previous}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide23a}`}>
                      This helps us in evaluating your monthly income and
                      expenditures..
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>
                  <li>
                    <div
                      className={`questitem ${state.showSlide24}`}
                      id="slide-24"
                      ref={(el) =>
                        (scrollRef.current["cardMonthlyIncome"] = el)
                      }
                    >
                      <h1 className="que-title p-3 ">
                      {`When you took out your ${productType} what was your monthly income?`}
                      </h1>
                      <CardMonthlyIncome
                       onSelectAnswer={onSelectAnswer}
                       validation={register}
                       clearErrors={clearErrors}
                       trigger={trigger}
                       validationMsg={errors}
                       getValues={getValues}
                       backClick={previousSlideChange}
                      />
                    </div>
                    {/* <div className={`tipbox tip2 ${state.showSlide24a}`}>
                      If you're unsure,please select "I Don't Recall" to avoid
                      neagatively effecting the claim.
                      <div className="arrow  arrow-top" />
                    </div> */}
                  </li>
                  <li>
                    <div
                      className={`questitem  ${state.showSlide9}`}
                      id="slide-9"
                      ref={(el) => (scrollRef.current["dependents"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                       How many dependents did you have at that time?{" "}
                      </h1>
                      <Dependents
                         onSelectAnswer={onSelectAnswer}
                         validation={register}
                         clearErrors={clearErrors}
                         trigger={trigger}
                         validationMsg={errors}
                         getValues={getValues}
                         backClick={previousSlideChange}
                         quest77Previous={quest77Previous}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide9a}`}>
                    We need to determine the number of dependents in order to assess and evaluate your financial position.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem q25 ${state.showSlide25}`}
                      id="slide-25"
                      ref={(el) => (scrollRef.current["wereAlreadyPaying"] = el)}
                    >
                      <h1 className="que-title p-3">
                      {`When you took out the ${productType},`} <b>{`were you already paying monthly`}</b> {`for one or more of the following:`}
                      </h1>
                      {/* <WereAlreadyPaying
                        onSelectAnswer={onSelectAnswer}
                        questArr={wereUalreadyPaying}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        checklistOptions={checklistOptions}
                        backClick={previousSlideChange}
                        setChecklistOptions={setChecklistOptions}
                      /> */}
                      <IncomeAndExpV3
                        // checklistOptions={checklistOptions}
                        // setChecklistOptions={setChecklistOptions}
                        // inputListValues={inputListValues}
                        // setInputListValues={setInputListValues}
                        backClick={previousSlideChange}
                        saveIncomeAndExpense={saveIncomeAndExpense}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide25a}`}>
                     Select all that you had
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  {/* <li> */}
                    {/* <div
                      className={`questitem q26 ${state.showSlide26}`}
                      id="slide-26"
                      ref={(el) => (scrollRef.current["incmAndMonth"] = el)}
                    >
                      <h1 className="que-title p-3">
                        {" "}
                        Please confirm the following MONTHLY / ANNUAL income and
                        expenditure details
                      </h1>
                      <IncomeAndExpV2
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        splitForm={splitForm}
                        questArr={wereUalreadyPaying}
                        saveSlide80={saveSlide80}
                        scrollToElement={scrollToElement}
                        backClick={previousSlideChange}
                        checklistOptions={checklistOptions}
                      />
                    </div> */}
                    {/* <div className={`tipbox tip2 ${state.showSlide26a}`}>
                      slide26a
                      <div className="arrow  arrow-top" />
                    </div> */}
                  {/* </li> */}
                  <li>
                    <div
                      className={`questitem q15 ${state.showSlide15}`}
                      id="slide-15"
                      ref={(el) => (scrollRef.current["btn_qest_48"] = el)}
                    >
                      <div
                        className={
                          isRegisteredEmail == "hide" ? "hide" : "show"
                        }
                      >
                        <h1 className="que-title p-3 regemail">
                          Is this the email address {userEmail} that you
                          registered with Vanquis
                        </h1>
                      </div>
                      <div
                        className={
                          isNewEmailVisible == "show" ? "show" : "hide"
                        }
                      >
                        <h1 className="p-3 newemail que-title">
                          Please provide the e-mail address you registered with
                          Vanquis
                        </h1>
                      </div>
                      <Emailconfirmation
                        onSelectAnswer={onSelectAnswer}
                        splitForm={splitForm}
                        getValues={getValues}
                        setIsRegisteredEmail={setIsRegisteredEmail}
                        setIsNewEmailVisible={setIsNewEmailVisible}
                        isRegisteredEmail={isRegisteredEmail}
                        isNewEmailVisible={isNewEmailVisible}
                        validation={register}
                        clearErrors={clearErrors}
                        trigger={trigger}
                        validationMsg={errors}
                        backClick={previousSlideChange}
                        IandEPrev={IandEPrev}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide15a}`}>
                      This is required to cross confirm with Vanquis database
                      and for effective communication.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem  ${state.showSlide32}`}
                      id="slide-32"
                      ref={(el) => (scrollRef.current["sortcode"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                      Please provide your Sort Code
                      </h1>
                      <SortCode
                         validation={register}
                         clearErrors={clearErrors}
                         trigger={trigger}
                         validationMsg={errors}
                         getValues={getValues}
                         backClick={previousSlideChange}
                         saveInputAns={saveInputAns}
                         SkippedLeads={SkippedLeads}
                         setClickedSlide={setClickedSlide}
                         setSortCode={setSortCode}
                      />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem  ${state.showSlide33}`}
                      id="slide-33"
                      ref={(el) => (scrollRef.current["bank_acc_no"] = el)}
                    >
                      <h1 className="que-title p-3 ">
                      Please provide your Bank Account Number
                      </h1>
                      <BankAccNo
                         validation={register}
                         clearErrors={clearErrors}
                         trigger={trigger}
                         validationMsg={errors}
                         getValues={getValues}
                         backClick={previousSlideChange}
                         saveInputAns={saveInputAns}
                         SkippedLeads={SkippedLeads}
                         setClickedSlide={setClickedSlide}
                         setAccNum={setAccNum}
                      />
                    </div>
                  </li>

                  <li>
                    <div
                      className={`questitem q16 ${state.showSlide16}`}
                      id="slide-16"
                      ref={(el) => (scrollRef.current["question_42"] = el)}
                    >
                     <h1 className="que-title p-3 ">
                        What <b>supporting documentation are you able to provide</b> to
                        bolster your complaint with Vanquis?
                      </h1>
                      <SupportingDoc
                        onSelectAnswer={onSelectAnswer}
                        backClick={previousSlideChange}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide16a}`}>
                      Supporting documents provide evidence to validate the
                      claim being made, and provide necessary information for
                      decision-making processes.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                  <li>
                    <div
                      className={`questitem q17 ${state.showSlide17}`}
                      id="slide-17"
                    >
                      <h1 className="que-title p-3 ">
                        Supporting Documentation
                      </h1>
                      <SupportingDocUpload
                        saveInputAns={saveInputAns}
                        validation={register}
                        validationMsg={errors}
                        getValues={getValues}
                        trigger={trigger}
                        setError={setError}
                        backClick={previousSlideChange}
                        pushPageChange={pushPageChangeV2}
                        docType={docType}
                        split_name={splitNameForSkip}
                        uuid={uuid}
                        onSelectAnswer={onSelectAnswer}
                      />
                    </div>
                    <div className={`tipbox tip2 ${state.showSlide17a}`}>
                      Uploading any Supporting documents enable us to understand
                      your financial status.
                      <div className="arrow  arrow-top" />
                    </div>
                  </li>
                </ul>
                <AgreementNoModal
                  validation={register}
                  validationMsg={errors}
                  trigger={trigger}
                  saveInputAns={saveInputAns}
                  agNoShow={agNoShow}
                  skipAgNo={skipAgNo}
                  setAgNoShow={setAgNoShow}
                />
              </form>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}
export default QuestionnaireV20;