import React, {
  useState,
  useEffect,
} from "react";
import { Api } from "../../api/Api";
import "../../assets/OpenBankingMb2/css/style.scss";
import { queryString } from "../../Utility/QueryString";
import { useHistory } from "react-router-dom";
import { useOpenBanking } from "../../Hooks/useOpenBanking";
import InputButton from "../UI/InputButton";
import { AppDomain } from "../../Constants/EnvConstants";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import * as EnvConstants from "../../Constants/EnvConstants";


const OpenBankingV2 = () => {
  const [userDetails, setUserDetails] = useState({});
  const [loader, setLoader] = useState(1);
  const [reloader, setReloader] = useState(1);
  
  const history = useHistory();
  const { getVisitorID, getAccessToken, createCustomer, getProviders, getAuthGatewayUrl, storeApiResponse, OpenBankVisit } = useOpenBanking();
  const [btndisable, setBtnDisable] = useState(false);
  const [visitorId, setVisitorId] = useState(0);
  const environment = EnvConstants.AppEnv;

  useEffect(() => {
    (async () => {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem('uuid');
      var uuid = (query_uuid)? query_uuid : local_storage_uuid;

      const response = await Api.get(
        "/api/v1/user-basic-details?uuid=" + uuid,
        {}
      );
      if (response.data.status === "Success") {
        let user_data = response.data.response.user_data;
        let uuid = response.data.response.uuid;
        let lender = response.data.response.lender;
        let source = 'live';
        let lstSalutation = response.data.response.lstSalutation;
        let txtFName = response.data.response.txtFName;
        let txtLName = response.data.response.txtLName;
        let txtEmail = response.data.response.txtEmail;
        let txtPhone = response.data.response.txtPhone;
        let is_test = response.data.response.is_test;
        let token = response.data.response.user_data.atp_sub2;
        let pixel = response.data.response.user_data.pixel;
        let bud_lender = response.data.response.bud_lender;

        localStorage.setItem('is_test', is_test);
        localStorage.setItem('user_data', JSON.stringify(user_data));

        // if(uuid == null){
        //   history.push("/thankyou?uuid=" + uuid);
        // }

        if(environment == "prod" && is_test === true){
          bud_lender = [];
        }else{
          bud_lender = [bud_lender]
        }

        setUserDetails({
          uuid: uuid,
          lender: lender,
          source: source,
          lstSalutation: lstSalutation,
          txtFName: txtFName,
          txtLName: txtLName,
          txtEmail: txtEmail,
          txtPhone: txtPhone,
          is_test: is_test,
          token: token,
          pixel: pixel,
          budLender:bud_lender
        });

        setTimeout(() => {
          setLoader(0);
        }, 1500);

        let visitor_id = localStorage.getItem('visitorId');

        if(visitor_id === undefined || visitor_id === null || visitor_id == ""){
          const visitor = await getVisitorID(
            uuid,
            user_data,
            'new'
          )
  
          if (visitor.data.status == "success") {
            setVisitorId(visitor.data.visitorId);
          }
          localStorage.setItem('visitorId', visitorId);

        }else{
          setVisitorId(visitor_id);
        }

        const flpResponse = await OpenBankVisit(
          "open_bank_visit_live",
          uuid,
          user_data,
          "OpenBankingLive",
          queryString,
          "OBV2"
        );
      } else {
        setLoader(0);
        history.push("/thankyou?uuid=" + response.data.response.uuid);
        return false;
      }
    })();
  }, []);

  const pushProceedLink = async () => {
    GtmDataLayer({
      'question': 'Connect your bank'
    });
    setBtnDisable(true);
    setLoader(1)
    const response1 = await getAccessToken(userDetails.uuid, userDetails.source, userDetails.is_test, visitorId);
    
    if (response1 == undefined ||  response1 == null || response1 == '') {
      setLoader(0);
      setReloader(0);
      return false;
    }

    if (response1.data.status == "success") {
      //(response.data.access_token_ID);
      let payload = { "milestone_key": "access_token", "source": userDetails.source,"lender": userDetails.budLender[0] };
      await storeApiResponse(userDetails, "op_milestone_update", userDetails.uuid, payload);
      const customerResponse = await createCustomer(userDetails.uuid, userDetails.source, userDetails.lstSalutation, userDetails.txtFName, userDetails.txtLName, userDetails.txtEmail, userDetails.txtPhone, userDetails.is_test, visitorId)
      
      if (customerResponse == undefined ||  response1 == null || response1 == '') {
        setLoader(0);
        setReloader(0);
        return false;
      }

      if (customerResponse.data.status == "success") {
        localStorage.setItem('customer_id', customerResponse.data.customer_ID);
        let payload = { "milestone_key": "customer_create", "customer_id": customerResponse.data.customer_ID, 'source': userDetails.source, "lender": userDetails.budLender[0]};
        
        await storeApiResponse(userDetails, "op_customer_store", userDetails.uuid, payload);
        // const providerResponse = await getProviders(userDetails.uuid,userDetails.source,userDetails.is_test);
        // if (providerResponse.data.status === "success") {
        //     const providers = providerResponse.data.providers;
        //     const optionData = providers.map((value, index) => {
        //         return (
        //           <option value={value.provider} key={index}>
        //             {value.display_name}
        //           </option>
        //         );
        //       });
        // }
        let redirectUrl = AppDomain + "/analyse?uuid=" + userDetails.uuid;
        var response2 = await getAuthGatewayUrl(userDetails.uuid, customerResponse.data.customer_ID, redirectUrl, userDetails.source, userDetails.is_test, visitorId,userDetails.budLender);
         
        if (response2 == undefined ||  response2 == null || response1 == '') {
          setLoader(0);
          setReloader(0);
          return false;
        }

        if (response2.data.status == "success") {
        payload = { "milestone_key": "lender_redirection", 'source': userDetails.source, "lender": userDetails.budLender[0] };
        await storeApiResponse(userDetails, "op_milestone_update", userDetails.uuid, payload);
        localStorage.setItem('bud_lender', userDetails.budLender[0]);
          let url = response2.data.gateway_url;
          window.location.href = url;
          // setLoader(0)
          setReloader(1);
        }
      }
    }
  }
  

  return (
    <div className="OpenBanking">
      <div className={`main-page-btn ${loader == 0 ? 'show' : 'hide'}`} id="page1">
        <section id="main-dv" className="btn-info_section">
          <div className="container btn-info_part">
            <div className="row justify-content-center ">
              <div className="col-lg-10 col-11">
                <div className={`space text-center ${reloader == 1 ? 'show' : 'hide'}`}>
                  <img className="logo_img" src="assets/img/logo_1.png" alt="" />
                  <p className="htext">
                    <strong> One of the main reasons Moneybarn rejects claims is the complaint was not accompanied by bank statements that verify income and expense at the time of the initial lending.</strong>
                  </p>
                  <p className="sub-text">Connect your bank account using <strong>OPEN BANKING</strong> to download your bank statements directly</p>
                  <p className="info-italic">It's safe, secure, private and FREE!</p>
                  <div className="button _1 anim_blue">
                    <InputButton
                      name="proceed"
                      className="btn_proceed"
                      id=""
                      value="Connect My Bank Account >"
                      btnType="button"
                      onClick={pushProceedLink}
                      disabled={btndisable}
                    />
                    <div className="back"></div>
                  </div>
                  
                



                  <div className="mt-4">
                  <img className="api-logo p-2" src="assets/img/bud-api.png" alt="" />
                  <img className="api-logo p-2" src="assets/img/fca-logo.png" alt="" />
                  </div>

                </div>

                <div className={`space text-center ${reloader == 0 ? 'show' : 'hide'}`}>
                  <div className="button _1 w-100 anim_blue refresh_btn_outer">
                    <InputButton
                      name="proceedd"
                      className="btn_proceed refresh_btn"
                      id="bb"
                      value="Refresh"
                      btnType="button"
                      onClick={pushProceedLink}
                    />
                    <div className="back"></div>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </section>
      </div>
      <div className={`loader text-center ${loader == 1 ? 'showload' : 'hide'}`} id="page2">
        <div className="loader-anime">
          <img src="assets/img/loading-23.gif" alt="" />
        </div>
      </div>
    </div>
  );
};

export default OpenBankingV2;
