import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
const BrandComplaint = ({
  className,
  slideChange,
  textHeading,
  validation,
  setVeryLender
}) => {
  const [checkedQuestionOne, setCheckedQuestionOne] = useState("unchecked");
  const [checkedQuestionTwo, setCheckedQuestionTwo] = useState("unchecked");
  const [checkedQuestionThree,setCheckedQuestionThree] = useState("unchecked");
  const nextSlide = async (e) => {
    if (e.target.value === "133") {
      setCheckedQuestionOne("checked");
      setCheckedQuestionTwo("unchecked");
      setCheckedQuestionThree("unchecked");
      slideChange(e);
    }
    if (e.target.value === "134") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("checked");
      setCheckedQuestionThree("unchecked");
      slideChange(e);
    }
    if (e.target.value === "135") {
      setCheckedQuestionOne("unchecked");
      setCheckedQuestionTwo("unchecked");
      setCheckedQuestionThree("checked");
      slideChange(e);
    }
    setVeryLender(e.target.value)
  };
  return (
    <>
      <div id="slide-1" className={`${className}`}>
        <ProgressBar value="0" />

        <h3> {textHeading} </h3>
        <div className="col-lg-12 mb-pd text-center">
          <div className="btn-comm">
            <RadioButton
              value="133"
              name="brandregister"
              className="radio regNextBtn"
              labelName="Very"
              labelClassName={`option_but next1a ${checkedQuestionOne}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
        </div>

        <div className="col-lg-12 mb-pd text-center">
          <div className="btn-comm">
            <RadioButton
              value="134"
              name="brandregister"
              className="radio regNextBtn"
              labelName="Shop Direct"
              labelClassName={`option_but next1a ${checkedQuestionTwo}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
        </div>

        <div className="col-lg-12 mb-pd text-center">
          <div className="btn-comm">
            <RadioButton
              value="135"
              name="brandregister"
              className="radio regNextBtn"
              labelName="Littlewoods"
              labelClassName={`option_but next1a ${checkedQuestionThree}`}
              onClick={nextSlide}
              validation={validation()}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default BrandComplaint;
