import axios from "axios";
import {AppAdtopiaToken,
  AppPostCodeSingleApi,
  AppEmailValidateSingleApi,
  AppApiUrl,
  AppSinglePointPhoneValidationURL,
  AppT2aCheckSingleEndpointURL,
  AppBudsSingleApi,
  AppGmailWebhookApi,
  AppPersonVerifySingleEndpointURL
  } from "../Constants/EnvConstants";

require("dotenv").config();

export const Api = axios.create({
  baseURL: AppApiUrl,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + AppAdtopiaToken,
  },
});

export const CommonEndApi =  axios.create({
  baseURL : AppPostCodeSingleApi,
  timeout : 30000,
  headers : {
    "Content-Type" : "application/json",
    Accept : "application/json",
    Authorization : "Bearer " + AppAdtopiaToken
  }
});

export const CommonEmailValidateApi =  axios.create({
  baseURL : AppEmailValidateSingleApi,
  timeout : 30000,
  headers : {
    "Content-Type" : "application/json",
    Accept : "application/json",
    Authorization : "Bearer " + AppAdtopiaToken
  }
});

export const IdUploadApi =  axios.create({
  baseURL: AppApiUrl,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + AppAdtopiaToken,
  },
});

export const CommonPhoneValidation =  axios.create({
  baseURL : AppSinglePointPhoneValidationURL,
  timeout : 30000,
  headers : {
    "Content-Type" : "application/json",
    Accept : "application/json",
    Authorization : "Bearer " + AppAdtopiaToken
  }
});

export const T2aValidationApi =  axios.create({
  baseURL : AppT2aCheckSingleEndpointURL,
  timeout : 30000,
  headers : {
    "Content-Type" : "application/json",
    Accept : "application/json",
    Authorization : "Bearer " + AppAdtopiaToken
  }
});

export const UserVerifyApi =  axios.create({
  baseURL : AppPersonVerifySingleEndpointURL,
  timeout : 30000,
  headers : {
    "Content-Type" : "application/json",
    Accept : "application/json",
    Authorization : "Bearer " + AppAdtopiaToken
  }
});

export const BudsApi =  axios.create({
  baseURL : AppBudsSingleApi,
  timeout : 30000,
  headers : {
    "Content-Type" : "application/json",
    Accept : "application/json",
    Authorization : "Bearer " + AppAdtopiaToken
  }
});

export const GmailWebhookApi = axios.create({
  baseURL: AppGmailWebhookApi,
  timeout: 6000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});

export const CommonEmailValidateApiTimeDelay =  axios.create({
  baseURL : 'https://dev.app.leadfinery.com/api/emvs037/dev/v1',
  timeout : 3000,
  headers : {
    "Content-Type" : "application/json",
    Accept : "application/json",
    Authorization : "Bearer " + AppAdtopiaToken
  }
});


export const ApiTimeDelay =  axios.create({
  baseURL : 'https://dev.api.themoneysolicitor.com',
  timeout : 3000,
  headers : {
    "Content-Type" : "application/json",
    Accept : "application/json",
    Authorization : "Bearer " + AppAdtopiaToken
  }
})

export const WebBookErrorPage = axios.create({
  baseURL: 'https://chat.googleapis.com/v1/spaces/AAAAFvlTyDY/messages?key=AIzaSyDdI0hCZtE6vySjMm-WEfRq3CPzqKqqsHI&token=2ZvN8b6fvNGbDKV1lEiU8iyjETFq-XrwKD7rG5c3qhs',
  timeout: 6000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json"
  }
});
