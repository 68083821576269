import React, { useState, useRef } from "react";
import ProgressBar from "../Common/ProgressBar";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
import CheckBox from "../../../Includes/Layouts/MB_UFGN_1/CheckBox";
import InputButton from "../../../UI/InputButton";

const Questionnaire5 = ({
  clsName,
  reff,
  onselectAnswer,
  validation,
  validationMsg,
  getValues,
  trigger,
  setError,
  optionSelected,
  questArr,
  backClick,
}) => {
  const [showError, setshowError] = useState("hide");
  const [btndisabled, setBtndisabled] = useState(false);
  const checkboxRefs = useRef({});

  const labelclicked = async (e) => {
    let date_index = e.target.id;
    let selectedCount = optionSelected.length;
    if (selectedCount == 0) {
      setBtndisabled(false);
      setshowError("show");
    } else {
      //   setBtndisabled(true);
      onselectAnswer(e);
      // }
    }
  };

  const onSelectOption = (e) => {
    if (e.target.checked === true) {
      if(e.target.value == '155'){
        optionSelected.length = 0;
        optionSelected.push({ id: +e.target.value });
        checkBoxDisable(e.target.value);
      } else {
        setshowError("hide");
        optionSelected.push({ id: +e.target.value });
      }
    } else {
      let deselect = e.target.value;
      const decre = optionSelected.map((OptionArray, index) => {
        if (deselect == OptionArray.id) {
          optionSelected.splice(index, 1);
          if (optionSelected.length == 0) {
            checkBoxEnable();
          }
        }
      });
    }
  };

  const handleChange = (e) => {
    // const re = /^[0-9\b]+$/;
    const re = /^[1-9]\d*$/;
    if (e.target.value !== "" && !re.test(e.target.value)) {
      e.target.value = e.target.value.replace(/\D/g, "") * 1;
    }
    e.target.value = e.target.value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  let checkArray = questArr.answers;

  const questionId = questArr.questionId ? questArr.questionId : 0;

  const lenderCheckBox = checkArray.map((checArr, index) => {
    let heightcls = checArr.value == "104" ? "heightnewcls" : "";

    return (
      <div
        className={`slect_bank_item ${heightcls}`}
        key={checArr.value}
        ref={(e) => (checkboxRefs.current[checArr.value] = e)}
      >
        <CheckBox
          dataId={checArr.value}
          name="chkBankName[]"
          onChange={onSelectOption}
          className="hiden_chekbox"
          CheckBoxText={`<span>${checArr.label}</span>`}
          value={checArr.value}
          labelClassName={"option_items"}
          divclassname={"bank_name"}
        />
      </div>
    );
  });

  const checkBoxDisable = (checkBoxId) => {
    checkArray.forEach((checArr, index) => {
      if (checkBoxId != 155) {
        checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
        checkboxRefs.current[checArr.value].childNodes[1].classList.remove(
          "disabledcheckbox"
        );
      } else {
        checkboxRefs.current[checArr.value].childNodes[0].checked = false;
        checkboxRefs.current[checArr.value].childNodes[0].disabled = true;
        checkboxRefs.current[checArr.value].childNodes[1].classList.remove(
          "disabledcheckbox"
        );

        checkboxRefs.current[155].childNodes[0].disabled = false;
        checkboxRefs.current[155].childNodes[0].checked = true;
      }
    });
  };

  const checkBoxEnable = () => {
    checkArray.map((checArr, index) => {
      checkboxRefs.current[checArr.value].childNodes[0].disabled = false;
      checkboxRefs.current[checArr.value].childNodes[1].classList.remove(
        "disabledcheckbox"
      );
    });
  };

  return (
    <>
      <div className="col-12 top_minus show">
        <div
          className={`radio-box animated fadeInUp col-12 top_minus_1 ${clsName}`}
          ref={reff}
        >
          <ProgressBar value="10" />
          <h3>
            {questArr.question}
          </h3>
          <div className=" col-lg-12 mb-pd  ">
            <div className="form-box">{lenderCheckBox}</div>
          </div>
          <div className="col-xl-12 col-lg-12 text-center p-0">
            <span className={`error_msg ${showError}`} id="email_err">
              Please select atleast one from the above
            </span>
            <InputButton
              btnType="button"
              className="btn-next regNextBtn btn-comm d-lg-block d-md-block d-sm-none d-none"
              value="Next"
              onClick={labelclicked}
              dataQuestId={questionId}
              data-ans-id={null}
              data-ans-label={null}
              disabled={btndisabled}
              name="btn_qest_36"
            />
          </div>

          <p className="text-center back-btn mb-5">
            <a className="back01" name="prev" onClick={backClick}>
              {"<< Previous"}
            </a>
          </p>
        </div>
      </div>
      <div className="text-center">
        <InputButton
          btnType="button"
          className={`btn-next regNextBtn-2 btn-comm  d-lg-none d-md-none d-sm-block d-block ${clsName}`}
          value="Next"
          onClick={labelclicked}
          dataQuestId={questionId}
          data-ans-id={null}
          data-ans-label={null}
          disabled={btndisabled}
          name="btn_qest_36"
          style={{
            position: "fixed",
            width: "100%",
            textAlign: "center",
            bottom: "0",
            zIndex: "11",
            margin: "0 -15px",
            marginBottom: "0"
          }}
        />
      </div>
    </>
  );
};

export default Questionnaire5;
