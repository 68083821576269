import React, { useEffect, useState } from "react";
import "../../../../assets/FollowupFos/css/questionnairefos.scss";


const MatchCheckLoader = ({ matchCheckLoader }) => {

  return (
    <>

      <div className={`tms-wrap bg-main-pr text-center loader-wrap loader2 ${matchCheckLoader}`}>

        <div className="loader-logo">
          <img src="assets/img/a00187b31dd5a4ece0c8f809b69962eb.png" alt="" />
        </div>
        <div className="loader">
          <img src="assets/img/review.gif" alt="" />
          <div className="loader-text">
            <p className=" text-analys">
              Analysing your Results</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MatchCheckLoader;