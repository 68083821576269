import React, {
  useRef,
  useState,
  useContext,
  useReducer,
  useEffect,
} from "react";
import {
  BrowserView,
  MobileView,
  MobileOnlyView,
  TabletView,
  deviceType,
  isIPad13,
  ConsoleView,
} from "react-device-detect";
import "../../../../assets/css/signatureVaq.scss";
import VisitorParamsContext from "../../../../Contexts/VisitorParams";
import SignatureCanvas from "react-signature-canvas";
import CheckBox2 from "../../../UI/CheckBox2";
import { useHistory } from "react-router-dom";
import { useFollowupUserSignature } from "../../../../Hooks/useFollowupUserSignature";
import Footer from "../../../Includes/Layouts/FollowupVAQ/Footer";
import LOA from '../../../../assets/pdf/LOA_Vanquis.pdf';
import TOB from '../../../../assets/pdf/Business_Terms_TMS.pdf';
import CFD from '../../../../assets/pdf/FOS_Vanquis.pdf';
import VQ from '../../../../assets/pdf/Questionnaire_Vanquis.pdf';
import { useClaimReg } from "../../../../Hooks/useClaimReg";


const initialState = {
  isChecked: false,
  isAgreed: false,
  isCanvasEmpty: false,
  isValid: false,
};
const signatureReducer = (state, action) => {
  switch (action.type) {
    case "signPadChange": {
      return {
        ...state,
        isCanvasEmpty: action.payload.value,
      };
    }
    case "privacyAgreed": {
      return {
        ...state,
        isAgreed: action.payload.value,
      };
    }
    case "validSignature": {
      return {
        ...state,
        isValid: action.payload.value,
      };
    }
    default:
      return state;
  }
};
const SignatureForm = ({ allFollowupData, showQuestion, split_name, splitData,uuid }) => {
  const [state, dispatch] = useReducer(signatureReducer, initialState);
  const { saveFollowupUserSignature, isLoading } = useFollowupUserSignature();
  const [isChecked, setIsChecked] = useState(true);
  const history = useHistory();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const signPadRef = useRef();
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const message_type = "signature_store";
  const [signatureWrapWidth, setSignatureWrapWidth] = useState(0);
  const [signatureWrapHeight, setSignatureWrapHeight] = useState(0);
  const signatureWrapRef = useRef(null);
  const [signatureStatus, setSignatureStatus] = useState(0);
  const topPortion = useRef(null);
  const { signatureConfirmFlp } = useClaimReg();
  

  const handleClick = async () => {
    try {
      const signPad = signPadRef.current;
      if (signPad.isEmpty()) {
        topPortion.current.scrollIntoView({ behavior: 'smooth', top: 0 });
        return dispatch({ type: "signPadChange", payload: { value: true } });
      }
      if (!isChecked) {
        topPortion.current.scrollIntoView({ behavior: 'smooth', top: 0 });
        return dispatch({ type: "privacyAgreed", payload: { value: true } });
      }
      const rpdata = signPad.toData();
      var signLength = 0;
      for (var i = 0; i < rpdata.length; i++) {
        signLength += Object.keys(rpdata[i]).length;
      }
      if (signLength > 13) {
        const response = await saveFollowupUserSignature(
          signPad.getCanvas().toDataURL("image/png"),
          allFollowupData,
          'followup_user_signature'
        )
        if (response.data.status === "Success") {
          // formParameters.user_sign = signPad.getCanvas().toDataURL("image/png");
          // localStorage.setItem("formData", JSON.stringify(formParameters));
          showQuestion(split_name);
        }
      } else {
        signatureWrapRef.current.scrollIntoView({ behavior: 'smooth' });
        return dispatch({ type: "validSignature", payload: { value: true } });
      }
    } catch (e) {
      console.warn(e);
    }
  };
  useEffect(() => {
    if (isChecked) {
      dispatch({ type: "privacyAgreed", payload: { value: false } });
    }
  }, [isChecked]);

  useEffect(() => {
    setSignatureStatus(splitData.signStatus)
    function handleResize() {
      setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
      setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    }
    setSignatureWrapWidth(signatureWrapRef.current.clientWidth);
    setSignatureWrapHeight(signatureWrapRef.current.clientHeight);
    window.scrollTo(0,0);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const clearSign = () => {
    if (signPadRef.current) signPadRef.current.clear();
    topPortion.current.scrollIntoView({ behavior: 'smooth', top: 0 });
  };

  const editSign = async () => {
    signatureConfirmFlp(
      uuid,
      "followup-vaq",
      "signature_edit",
      allFollowupData.atp_sub6
    );
    topPortion.current.scrollIntoView({ behavior: 'smooth', top: 0 });
    setSignatureStatus(0);
  }

  const confirmSign = async () => {
    if (!isChecked) {
      // formParameters.user_sign = splitData.signImg;
      // localStorage.setItem("formData", JSON.stringify(formParameters));
      console.log('the ivde'+splitData.signImg);
      topPortion.current.scrollIntoView({ behavior: 'smooth', top: 0 });
      return dispatch({ type: "privacyAgreed", payload: { value: true } });
    }else{
      signatureConfirmFlp(
        uuid,
        "followup-vaq",
        "signature_confirm",
         allFollowupData.atp_sub6
      );
      showQuestion(split_name);
    }
  }
  
  return (
    <>
      <div className="SignatureVAQ">
        <header className="d-none d-lg-block">
          <div className="container">
            <div className="row">
              <div className="logo-part col-lg-12 col-md-12 col-12  text-center">
                <img src="/assets/Signature/img/logo.png" alt="" />
              </div>
              <div
                className="col-lg-6 offset-lg-2 col-md-8 col-12 text-center d-none d-md-block "
              ></div>
            </div>
          </div>
        </header>
        <section className="signature-section" >
          <div className="container ">
            <div className="row ">
              <div className="offset-lg-2 col-lg-8 col-12 sign-question">
                {signatureStatus === 1 && (
                    <>
                     <h2>
                     <span>IMPORTANT</span> <br /> 
                     Your complaint against Vanquis appears valid, and we're prepared to help you pursue it further. We ask all clients to confirm their signature and ensure they've read our subsequent documents.
                      </h2>
                    </>
                )}
                {signatureStatus === 0 && (
                    <>
                      <h1>
                      <span>IMPORTANT</span> <br /> 
                        Your signature is crucial for us to progress with your claim <br/>
                        Sign here to start your claim online NOW..
                      </h1>
                    </>
                )}

                <div className="offset-lg-2 col-lg-8 col-12">
                  <ul className="head-ul">
                    <li> Sign using your finger, mouse or stylus</li>
                    <li> Keep it fully contained within the box</li>
                    <li> Ensure it is a true likeness of your signature</li>
                  </ul>
                </div>
                <div className="sign-div" ref={topPortion}>
                  <form>
                    <div className="form-group">
                      <div className="signature_canvaseWrap">
                        <div className="signature-pad anim_bt d-lg-block web-canvas" ref={signatureWrapRef}>
                          {signatureStatus == 1 ?
                            <img className="img-fluid" src={splitData.signImg} alt="Signature" />
                            : <SignatureCanvas
                              canvasProps={{
                                id: "myID",
                                width: signatureWrapWidth,
                                height: signatureWrapHeight,
                              }}
                              ref={signPadRef}
                              onEnd={() => {
                                if (!signPadRef.current.isEmpty()) {
                                  dispatch({
                                    type: "signPadChange",
                                    payload: { value: false },
                                  });
                                  dispatch({
                                    type: "validSignature",
                                    payload: { value: false },
                                  });
                                  document
                                    .getElementById("acceptterms")
                                    .classList.add("anim_ylw");
                                }
                              }}
                            />}
                        </div>
                        {state.isCanvasEmpty && (
                          <div className="sign_msg col-12 text-center sign_msg" id="signatures_required">
                            Signature is required!
                          </div>
                        )}
                        {state.isValid && (
                          <div className="sign_msg col-12 text-center sign_msg" id="signatures_valid">
                            Draw valid signature!
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-check my-3 checkbox-div">
                      <CheckBox2
                        dataId="acceptterms"
                        anchorText=""
                        anchorStyle=""
                        href=""
                        onChange={(e) => setIsChecked(!isChecked)}
                        className="form-check-input input_1 pulse"
                        labelClassName="form-check-label sign-custom-control-label"
                        value={isChecked}
                        checked={isChecked}
                        CheckBoxText="By clicking submit you agree to us appending your electronic signature to the claim documents
                        and confirming that you accept the terms of this contract for TMS Legal to represent you in
                        your irresponsible Lending claim, and that you agree to pay us 45% commission if the claim is
                        successful."
                      />
                      {state.isAgreed && (
                          <div className="sign_msg col-12 text-center">
                            Please tick to show your authorisation for us to
                            proceed
                          </div>
                        )}
                      <p className="pt-3">Preview your unsigned documents before signing above:</p>
                      <ul className="list-1a">
                        <li> <a href={`${LOA}`} target="_blank"> <img className="link_img_1a" src="/assets/SignatureVAQ/img/link_1a.png" alt="" /> Letter of Authority </a></li>
                        <li> <a href={`${TOB}`} target="_blank"> <img className="link_img_1a" src="/assets/SignatureVAQ/img/link_1a.png" alt="" /> Terms of Business</a></li>
                        <li> <a href={`${VQ}`} target="_blank"> <img className="link_img_1a" src="/assets/SignatureVAQ/img/link_1a.png" alt="" /> Questionnaire</a></li>
                        <li> <a href={`${CFD}`} target="_blank"> <img className="link_img_1a" src="/assets/SignatureVAQ/img/link_1a.png" alt="" /> Complaint Form & Declaration</a></li>
                      </ul>
                    </div>
                    {signatureStatus == 1 ?
                      <>
                      <input
                        type="button"
                        id=""
                        name=""
                        className="btn-clear"
                        value="Edit"
                        onClick={editSign}
                      />
                      <input
                          className="btn btn-submit"
                          type="button"
                          onClick={confirmSign}
                          value="Submit"
                        />
                      </>
                      :
                      <>
                        <input
                          type="button"
                          id=""
                          name=""
                          className="btn-clear"
                          value="Edit"
                          onClick={clearSign}
                        />
                        <input
                          className="btn btn-submit"
                          type="button"
                          onClick={handleClick}
                          value="Submit"
                        />
                      </>
                    }
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};
export default SignatureForm;
