import React from "react";

const Header = () => {
  return (
    <>
      <header id="hding">
        <div className="container">
          <div className="col-lg-8 offset-lg-2 col-12 text-center p-0" id="slide01">
            <img src="/assets/VCC_UB_1/img/logo.png" alt="" />
            <h1>
            Taken Out Repeated High-Cost Credit from Very, Shop Direct, or Littlewoods Catalogues?
            </h1>
            <h2>
            Starting your claim is easy and 100% online. You could recover money back if you were mis-sold
            </h2>
          </div>
          <div className="spacemob" style={{ display: "none" }}></div>
        </div>
      </header>
    </>
  );
};

export default Header;