import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import AnalyzeModal from "../../Layouts/Split_1/AnalayzeModal";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import InputButton from "../../../UI/InputButton";
const Bankruptcy = ({
  className,
  slideChange,
  textHeadingLine1,
  textHeadingLine2,
  validation,
  backClick,
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [claimModal, setshowClaimModal] = useState(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);

  const nextSlide = async (e) => {

    if (e.target.value === "21") {
      setCheckedOne("checked");
      setCheckedTwo("unchecked");
      setshowClaimModal(true);
      return false
      //slideChange(e);
    } else {
      setCheckedOne("unchecked");
    }

    handleShowFirst();
    setTimeout(() => {
      handleCloseFirst();
      handleShowSecond();
    }, 3000);
    setTimeout(() => {
      handleCloseSecond();
      if (e.target.value === "22") {
        setCheckedTwo("checked");
        slideChange(e);
      } else {
        setCheckedTwo("unchecked");
      }
      // slideChange(e);
    }, 6000);
  };
  const [showFirstModal, setShowFirstModal] = useState(false);
  const handleShowFirst = () => setShowFirstModal(true);
  const handleCloseFirst = () => setShowFirstModal(false);

  const [showSecondModal, setShowSecondModal] = useState(false);
  const handleShowSecond = () => setShowSecondModal(true);
  const handleCloseSecond = () => setShowSecondModal(false);
  return (
    <>
      <div id="slide-8a" className={`${className}`}>
        <ProgressBar value="70" />

        <h3> {textHeadingLine1} </h3>
        <h4> {textHeadingLine2} </h4>
        <div className="col-lg-12 mb-pd text-center">
        <div className="btn-comm">
          <RadioButton
            value="22"
            name="bankruptcy"
            className="radio regNextBtn"
            labelName="No"
            labelClassName={`option_but ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
          </div>
          <div className="col-lg-12 mb-pd text-center">
          <div className="btn-comm">
          <RadioButton
            value="21"
            name="bankruptcy"
            className="radio regNextBtn"
            labelName="Yes"
            labelClassName={`option_but ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />
          </div>
        </div>
        <p className="text-center">
          <span
            className="back1 back-btn btn-link"
            name="back08a"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div>
      {/* **** cannot accept your claim popup start **** */}
      <Modal size="md" show={claimModal} onHide={handleCloseclaimModal} centered backdrop="static" className="claimdeinial modaloutersec">
        <Modal.Body>
          <h3 className="text-center">Sorry, we cannot accept your claim</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew regNxtBtnNw text-center"
              name="modalBack"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showFirstModal}
        onHide={handleCloseFirst}
        backdrop="static"
        keyboard={false}
        className="formmodal"
        contentClassName="modalcontent"
      >
        <Modal.Body className="modal-bordr">
          <div id="slide7" className="col-12 slide-pad text-center">
            <div className="form-group text-center" id="analize1">
              <h2>Analysing Your Results</h2>
              <img src="/assets/MB_UBRF_1/img/review.gif" alt="" />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSecondModal}
        onHide={handleCloseSecond}
        backdrop="static"
        keyboard={false}
        className="formmodal"
        contentClassName="modalcontent"
      >
        <Modal.Body className="modal-bordr">
          <div id="slide7nxt" className="col-12 slide-pad text-center">
            <div className="form-group text-center" id="analize1">
              <h2>Good News! </h2>
              <p>
                We are able to assist you further. Please give us some back
                information. <br />
                (This will only take 1 minute)
              </p>
              <img src="/assets/MB_UBRF_1/img/review.gif" alt="" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Bankruptcy;
