import { useState } from "react";
import { Api } from "../api/Api";
import {useErrorWebhook} from "./useErrorWebhook";

export const usePreviewData = () => {
  const { ErrorWebhookTrigger } = useErrorWebhook();

  const savePreviewData= async (
    uuid
  ) => {
    const response = await Api.post("api/v1/get-preview-data", {
      uuid
    }).catch(function (error) {
      const erroMessage = {
        serviceName : 'PREVIEW DATA',
        errorReason:error.message,
        errorCode:error.code,
        uuid:uuid
      }
      ErrorWebhookTrigger(erroMessage);
  });
    return response;
  };
  return { savePreviewData };
};
