import React from "react";
import AdtopiaLoadLP from "../../../Utility/AdtopiaLoadLP";
import FormVCC_UB_2 from "../../Forms/FormVCC_UB_2";
import Header from "../../Includes/Layouts/VCC_UB_2/Header";
import Footer from "../../Includes/Layouts/VCC_UB_2/Footer";
import FbViewPixel from "../../../Utility/FbViewPixel";
import "../../../assets/VCC_UB_2/css/VCC_UB_2.scss";
import Section1 from "../../Includes/Layouts/VCC_UB_2/Section1";
import AccordSection from "../../Includes/Layouts/LS_UB_1/AccordSection";

const VCC_UB_2 = () => {
  return (
    <>
      <AdtopiaLoadLP pageType="LP" splitName="VCC_UB_2" />
      <FbViewPixel />
      <div className="VCC_UB_2">
        <Header />
        <section className="form-part" id="firstform">
          <div className="container nopad">
            <div className="col-lg-6 offset-lg-3 col-md-8 offset-md-2 col-12 nopad">
              <FormVCC_UB_2 />
            </div>
          </div>
        </section>
        <Section1 />
        <AccordSection />
        <Footer />
      </div>
    </>
  );
};

export default VCC_UB_2;