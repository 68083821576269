import React, {
  useState,
  useReducer,
  useRef,
  useContext,
  useEffect,
} from "react";
import PersonalDetails from "../Includes/Layouts/NDY_UB_4_2/PersonalDetails";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useDataIngestion } from "../../Hooks/useDataIngestion";
import QueryStringContext from "../../Contexts/QueryString";
import { blacklist } from "../../Hooks/blacklist";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import * as EnvConstants from "../../Constants/EnvConstants";
import { useSignature } from "../../Hooks/useSignature";
import { useUpdateMilestone } from "../../Hooks/useUpdateMilestone";


const FormNDY_UB_4_2 = () => {
  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
    resolver: undefined,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const {
    register,
    errors,
    trigger,
    setError,
    clearErrors,
    getValues,
    watch,
    handleSubmit,
  } = formMethods;

  const { updateMilestone } = useUpdateMilestone();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const [previousAddressData, setPreviousAddressData] = useState({});
  const formParameters = JSON.parse(localStorage.getItem("formData"));
  const { saveSignature } = useSignature();
  const [signatureDataURL, setSignatureDataURL] = useState('');
  const history = useHistory();
  const splitForm = useRef(null);
  const signPadRef = useRef(null);
  const [divShow, setDivShow] = useState('');
  const [blacklisted, setBlacklisted] = useState([]);
  const [checkedOption1, setCheckedOption1] = useState("unchecked");
  const [checkedOption2, setCheckedOption2] = useState("unchecked");
  const [checkedOption3, setCheckedOption3] = useState("unchecked");
  const [productClaim, setProductClaim] = useState();

  const field = "pid";
  const { getBlacklist } = blacklist();
  const { saveDataIngestion, formDataIngestion, isLoading } =
    useDataIngestion();
  const { queryString } = useContext(QueryStringContext);
  useEffect(() => {
    let responsesss = [];
    (async () => {
      const response = await getBlacklist();
      let dataLength = response.data.response.length;
      responsesss = response.data.response;
      if (dataLength > 0) {
        setBlacklisted(response.data.response);
      }
    })();
  }, []);


  useEffect(() => {
    if (signatureDataURL !== '') {
      setSignatureDataURL(signatureDataURL);
    }
  }, [signatureDataURL]);
  const form = splitForm.current;
  const values = getValues();
  const formData = values;

  const formSubmit = async () => {

    if (formData.address1 !== "") {
      const txtUdprn = form["txtUdprn"].value;
      const txtDeliveryPointSuffix = form["txtDeliveryPointSuffix"].value;
      const txtPz_mailsort = form["txtPz_mailsort"].value;
      const txtStreet = form["txtStreet"].value;
      const txtAddress3 = form["txtAddress3"].value;
      const txtDistrict = form["txtDistrict"].value;

      formData.txtDistrict = txtDistrict;
      formData.txtAddress3 = txtAddress3;
      formData.txtUdprn = txtUdprn;
      formData.txtDeliveryPointSuffix = txtDeliveryPointSuffix;
      formData.txtPz_mailsort = txtPz_mailsort;
      formData.txtStreet = txtStreet;
    }

    // //***previous_address ****/
    // formData.previous_postcode = previousAddressData.postcode;
    // formData.previous_address_line1 = previousAddressData.line_1;
    // formData.previous_address_line2 = previousAddressData.line_2;
    // formData.previous_address_line3 = previousAddressData.line_3;
    // formData.previous_address_county = previousAddressData.county;
    // formData.previous_address_town = previousAddressData.town;
    // formData.previous_address_country = previousAddressData.country;
    // formData.previous_district = previousAddressData.district;
    // formData.previous_address_id = previousAddressData.prevAddressId;

    if (form["addressprev1"].value) {
      formData.previous_postcode = form["txtPrevPostCode"].value.toUpperCase();
      formData.previous_address_line1 = form["txtHouseNumberPrev"].value;
      formData.previous_address_line2 = form["txtAddress3prev"].value;
      formData.previous_address_line3 = form["txtAddress3Previous"]?.value;
      formData.previous_address_county = form["txtCountyprev"].value;
      formData.previous_address_town = form["txtTownprev"].value;
      formData.previous_address_country = form["txtCountryprev"].value;
      formData.previous_district = form["txtDistrictPrev"].value;
      formData.previous_address_id = form["addressprev1"].value;
    }

    formData.user_sign = signatureDataURL;
    formData.Previousname = form["txtPreName"].value;
    formData.question_62 = productClaim;
    formData.productclaim = productClaim;

    formData.page_name = "NDY_UB_4_2";
    formData.pCode_manual = form["pCode_EntryType"].value;
    if (
      visitorParameters.data !== null ||
      visitorParameters.data !== undefined
    ) {
      // let dobMonth = formData.DobMonth ? MonthNameArray[formData.DobMonth] : '';
      localStorage.setItem("fName", formData.txtFName);
      localStorage.setItem("lName", formData.txtLName);
      localStorage.setItem("pCode", formData.txtPostCode);
      localStorage.setItem("addressLine1", formData.txtHouseNumber);
      localStorage.setItem("dobYr", formData.DobYear);
      localStorage.setItem("dobMon", formData.DobMonth);
      localStorage.setItem("dobDy", formData.DobDay);
      localStorage.setItem("addressLine2", formData.txtAddress2);
      localStorage.setItem("postTown", formData.txtTown);
      localStorage.setItem("country", formData.txtCounty);
      localStorage.setItem("title", formData.lstSalutation);
      localStorage.setItem("addressLine3", formData.txtAddress3);
      localStorage.setItem("county", formData.txtCounty);
      localStorage.setItem(
        "district",
        formData.txtDistrict ? formData.txtDistrict : ""
      );
      let UcFname =
        formData.txtFName.charAt(0).toUpperCase() + formData.txtFName.slice(1);

      localStorage.setItem("UcFname", UcFname);

      localStorage.setItem("formData", JSON.stringify(formData));
      localStorage.setItem("queryString", queryString);

      if (signatureDataURL) {
        const formSUbmitResult = formDataIngestion(
          visitorParameters.visitor_parameters,
          formData,
          "split_form_submit",
          "NDY_UB_4_2",
          queryString,
          visitorParameters.data
        );
        const message_type = "signature_store";
        const response = await saveSignature(
          signatureDataURL,
          visitorParameters.visitor_parameters,
          formData,
          visitorParameters.data,
          queryString,
          message_type
        );
        if (response.data.status === "Success") {
          updateMilestone(visitorParameters.visitor_parameters.uuid, "lead", "live");
          updateMilestone(visitorParameters.visitor_parameters.uuid, "signature", "live");
          pageChange();
        }
      }
    } else {
      return "Fail";
    }
  };
  const pageChange = () => {

    let blacklistFlag = false;
    blacklisted.map((value, index) => {
      if ("email" == value.type && value.value == formData.txtEmail) {
        blacklistFlag = true;
      }

      if ("phone" == value.type && value.value == formData.txtPhone) {
        blacklistFlag = true;
      }
    });

    if (blacklistFlag) {
      window.scrollTo(0, 0);
      history.push(
        "/thankyou-bl?uuid=" + visitorParameters.visitor_parameters.uuid
      );
    } else {
      if (queryString != null) {
        if (queryString.indexOf("&" + field + "=") !== -1) {
          if (EnvConstants.AppConversionPixel === "true") {
            history.push("/fbpixel?split_name=NDY_UB_4_2");
          }
          else {
            window.scrollTo(0, 0);

            history.push(
              "/questionnaireV22?uuid=" + visitorParameters.visitor_parameters.uuid
            );
          }
        }
        else {
          window.scrollTo(0, 0);
          history.push(
            "/questionnaireV22?uuid=" + visitorParameters.visitor_parameters.uuid
          );
        }
      } else {
        window.scrollTo(0, 0);
        history.push(
          "/questionnaireV22?uuid=" + visitorParameters.visitor_parameters.uuid
        );
      }
    }
  }

  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();

  // const handleRadioButtonClick = (e, id, value) => {
  //   GtmDataLayer({
  //     question: "select product",
  //   });
  //   clearErrors(["productclaim"])
  //   setCheckedOption(id);
  //   const targetInput = document.getElementById("sign-div");
  //   if (targetInput) {
  //     targetInput.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // };

  const slideChange = async (e) => {

    GtmDataLayer({
      question: "select product",
    });

    clearErrors(["productclaim"])

    if (e.target.value === "173") {
      setCheckedOption1('checked');
      setCheckedOption2('unchecked');
      setCheckedOption3('unchecked');
    } else if (e.target.value === "174") {
      setCheckedOption1('unchecked');
      setCheckedOption2('checked');
      setCheckedOption3('unchecked');
    } else if (e.target.value === "178") {
      setCheckedOption1('unchecked');
      setCheckedOption2('unchecked');
      setCheckedOption3('checked');
    }
    setProductClaim(e.target.value);

    setDivShow('is-open')
  };


  return (
    <>
      <FormProvider {...formMethods}>
        <form
          ref={splitForm}
          name="split_form"
          id="user_form"
          method="POST"
          autoComplete="off"
        >
          <section className="banner">
            <div className="container-fluid caption-top"><p>Our Average Refund for NewDay as of 14/05/2024 is <strong> £1049</strong></p></div>
            <div className="container">
              <div>
                <div className="brand">
                  <div className="logo pt-3">
                    <a href="index.html">
                      <img src="../../assets/NDY_UB_4_2/img/logo-white.png" height="60" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="container-xxl">
                <div className="banner-inner">
                  <div className="row">
                    <div className="col-lg-7 col-md-12 col-sm-12  ">
                      <div className="banner-caption">
                        <div className="overflow-hidden">
                          <h1 className="fw-bold  drop-in tex-lg-start text-md-start text-sm-center text-center">Taken Out Repeated High-Cost Credit?</h1>
                        </div>

                        <div className="overflow-hidden">
                          <p className="py-lg-3 py-md-3 py-sm-3 py-1 pt-0 drop-in-2 mb-0 mb-sm-3 subtext tex-lg-start text-md-start text-sm-center text-center">Starting your claim is easy and 100% online. You could recover money back if you were mis-sold</p>
                        </div>
                        <div className="d-block d-lg-none d-md-block d-sm-block py-1">
                          <div className="banner-car   text-lg-start text-md-center text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                            <div className="review drop-in-3  d-none">
                              <div className="overflow-hidden ">
                                <img src="../../assets/NDY_UB_4_2/img/review-star.png" className="drop-in-3 " alt="" />
                              </div>
                            </div>
                            <img className=" car-img fade-in" src="../../assets/NDY_UB_4_2/img/banner-img2.png" alt="" />
                          </div>
                        </div>
                        <div id="slide-1" className="overflow-hidden col-12 top_minus_1 mt-lg-0 mt-md-0 mt-sm-0 mt-3">
                          <fieldset className="ques1 drop-in-2">
                            <legend className="drop-in-2 fw-bold fs-5  tex-lg-start text-md-start text-sm-center text-center">Which product would you like to register a claim against?</legend>
                            <div className="row">
                              <div className="col-lg-12 mb-2 text-center">
                                <div className="">
                                  <label className={`btn-comm2 btn-comm4 w-100 drop-in-3 fw-bold fs-5 ${checkedOption1}`}>
                                    <input type="radio" className="radio d-none regNextBtn" name="productclaim" onClick={slideChange} value="173"
                                    /><span>Aquacard
                                    </span> </label>
                                </div>
                              </div>
                              <div className="col-lg-12 mb-2 text-center">
                                <div className="">
                                  <label className={`btn-comm2 btn-comm4 w-100 drop-in-3 fw-bold fs-5 ${checkedOption2}`}>
                                    <input type="radio" className="radio d-none regNextBtn" name="productclaim" onClick={slideChange} value="174" /><span>Marbles
                                    </span> </label>
                                </div>
                              </div>
                              <div className="col-lg-12 text-center">
                                <div className="">
                                  <label className={`btn-comm2 btn-comm4 w-100 drop-in-3 fw-bold fs-5 ${checkedOption3}`}>
                                    <input type="radio" className="radio d-none regNextBtn" name="productclaim" onClick={slideChange} value="178" /><span>Other
                                    </span></label>
                                </div>
                                <span className="btn-caption">(Others includes Bip, Fluid, John Lewis & Partners, AO, Argos, Newpay & Pulse)
                                </span>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-12 col-sm-12  d-lg-block d-md-none d-none">
                      <div className="bannerimage-wraper text-lg-start text-md-center text-sm-center text-center mt-lg-0 mt-md-0 mt-sm-0 mt-2">
                        <div className="review drop-in-3 d-none">
                          <div className="overflow-hidden ">
                            <img src="../../assets/NDY_UB_4_2/img/review-star.png" className="drop-in-3 " alt="" />
                          </div>
                        </div>
                        <img className="bannerimg fade-in" src="../../assets/NDY_UB_4_2/img/banner-img1.png" />
                      </div>
                    </div>
                    <div className="col-12 mt-3">
                      <div className="tab-content">
                        <div className="tab-pane drop-in-2 active" id="one">
                          <div className="testimonial-wrap">
                            <div className="testimonial drop-in-3">
                              {/* <!-- <div className="date"><b>Date of experience:</b> May 20, 2024 <span className="stars">
                            <img src="dist/img/stars-5.svg" alt="" className="star-rating">
                          </span></div> --> */}

                              <div className="date"><span className="stars">
                                <img src="../../assets/NDY_UB_4_2/img/stars-5.svg" alt="" className="star-rating" />
                              </span></div>

                              <strong>"Simple, easy and painless"</strong>
                              <p>This was the easiest and most painless experience I have had. Simple, straight forward and excellent communication from the team at TMS. Thank you for supporting my win</p>

                            </div>
                            <cite className="testimonial-author drop-in-3">
                              <img src="../../assets/NDY_UB_4_2/img/user-avatar.png" alt="User Avatar" className="rounded-circle me-3" />
                              <span className="name">
                                <strong>Craig Mcdermott</strong>
                                May 21, 2024
                              </span>
                            </cite>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="welcome-highlights py-lg-5 py-md-0 pb-lg-4 pb-md-5 pb-sm-5 pb-5">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                  <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                    <img src="../../assets/NDY_UB_4_2/img/icon01.svg" alt="" />
                    <h2 className="mt-3"> Get your Mis-sold Loan Fees and Charges back</h2>
                    <p>Many loans were not sold properly. You could claim back the fees and charges plus 8% interest.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                  <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                    <img src="../../assets/NDY_UB_4_2/img/icon02.svg" alt="" />
                    <h2 className="mt-3">Your Claim Fought by Financial & Legal Experts</h2>
                    <p>We're ahead of the field, drawing on years of financial services and legal expertise for a strong claim</p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 text-center mb-3">
                  <div className="px-lg-3 px-md-0 px-sm-0 px-0 items">
                    <img src="../../assets/NDY_UB_4_2/img/icon03.svg" alt="" />
                    <h2 className="mt-3">Respecting Your Information Security</h2>
                    <p>We take security very seriously and follow the rules of the Data Protection Act</p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <PersonalDetails
            formSubmit={formSubmit}
            pageChange={pageChange}
            divShow={divShow}
            setPreviousAddressData={setPreviousAddressData}
            setSignatureDataURL={setSignatureDataURL}

          />
        </form>

      </FormProvider>
    </>
  );
};

export default FormNDY_UB_4_2;
