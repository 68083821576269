import React, { useState } from "react";
import TmsLogo from "../../../../assets/QuestionaireMBGN_4/img/logo-white.png";
import Complaints from '../Common/Complaints';
import PrivacyPolicy from '../Common/PrivacyPolicy';
import TC from '../../../../assets/pdf/PBA-terms.pdf';
const Footer = () => {
    const [showPrivacy, setShowPrivacy] = useState(false);
    const [showterms, setShowterms] = useState(false);
    const [showComplaint, setShowComplaint] = useState(false);

    const modalClick = (clickValue) => {
        switch (clickValue) {

            case "showPrivacy": {
                setShowPrivacy(true);
                break;
            }
            case "exitPrivacy": {
                setShowPrivacy(false);
                break;
            }
            case "showTerms": {
                setShowterms(true);
                break;
            }
            case "exitTerms": {
                setShowterms(false);
                break;
            }
            case "showComplaint": {
                setShowComplaint(true);
                break;
            }
            case "exitComplaint": {
                setShowComplaint(false);
                break;
            }
        }
    };

    return (
        <footer className="py-lg-5 py-md-5 py-sm-4 py-4">
            <div className="container">
                <div className="row">
                <div className="col-lg-3 tex-lg-start mb-3 text-lg-start text-md-start text-sm-center text-center">
                        <div className="ft-logo">
                            {" "}
                            <img src={TmsLogo} height={70} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-9 text-lg-start text-md-start text-sm-center text-center">
                        <p>
                            TMS Legal Ltd is a limited company registered in England and Wales
                            under company no: 10315962 Its registered office is Arena, 9 Nimrod
                            Way, Ferndown, BH21 7UH It is authorised and regulated by the
                            Solicitors Regulation Authority, Number: 645041
                        </p>
                        <PrivacyPolicy
                            showPrivacy={showPrivacy}
                            modalClick={modalClick}
                        />

                        <Complaints
                            showComplaint={showComplaint}
                            modalClick={modalClick}
                        />
                        <ul className="m-0 p-0 text-lg-start text-md-start text-sm-center text-center">
                            <li>
                                <a onClick={() => modalClick("showPrivacy")}>Privacy and Cookie Policy</a>
                            </li>
                            <li>
                                <a href={`${TC}`} target="_blank" style={{color:'white'}}>TMS Legal Terms and Conditions</a>
                            </li>
                            <li>
                                <a onClick={() => modalClick("showComplaint")}> Complaints Procedure</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
}
export default Footer;