import React from 'react';

const RadioButtonQuest = ({
    id,
    value,
    name,
    className,
    labelName,
    labelClassName,
    onClick,
    onLabelClick,
    validation,
    labelCountString,
    dataQuestId,
    dataAnsId,
    dataAnsLabel
  }) => {
    return (
        <>
          <input type="radio" className={className} name={name} id={id} data-ans-id={dataAnsId} data-quest-id={dataQuestId} data-ans-label={dataAnsLabel}
            value={value} onClick={onClick} 
        />
          <label htmlFor={id} data-ans-id={dataAnsId} data-quest-id={dataQuestId}  className={labelClassName} 
                onClick={onLabelClick}>
                <span>{labelCountString}</span>{labelName}
          </label>
        </>
      );
}

export default RadioButtonQuest;