import React from "react";

const InputButton = ({ value, className, name, validation, id, onClick, btnType,labelName, disabled, style,dataQuestId,dataBtnLabel,dataPageLabel }) => {
  return (
    <input
      className={className}
      name={name}
      ref={validation}
      id={id}
      style={style}
      onClick={onClick}
      type={btnType}
      disabled={disabled}
      value={value}
      labelName={labelName}
      data-quest-id={dataQuestId}
      data-btn-label = {dataBtnLabel}
      data-page-label = {dataPageLabel}
    />
  );
};
export default InputButton;
