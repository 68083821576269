import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useReducer,
} from "react";
import { useForm } from "react-hook-form";
import { questinnare1, questinnare2LS, answerArray, supportDocTypeArray } from "../../Constants/Questions";
import { useQuestionnaire } from "../../Hooks/useQuestionnaire";
import { userInfo } from "../../Hooks/userInfo";
import VisitorParamsContext from "../../Contexts/VisitorParams";
import { ConsoleView } from "react-device-detect";
import { CheckUUID } from "../../Utility/CheckUUID";
import { useHistory } from "react-router-dom";
import { FormData } from "../../Utility/FormData";
import GetVisitorsParams from "../../Utility/GetVisitorsParams";
import "../../assets/css/QuestionnaireV10.scss";
import QuestionnaireLoder from "../Includes/Layouts/QuestionnaireV10/QuestionnaireLoder";
import QuestionnaireFooter from "../Includes/Layouts/QuestionnaireV10/QuestionnaireFooter";
import Monthly from "../Includes/Layouts/QuestionnaireV10/Monthly";
import PointsSelect from "../Includes/Layouts/QuestionnaireV10/PointsSelect";
import AgreementNo from "../Includes/Layouts/QuestionnaireV10/AgreementNo";
import SupportDocument from "../Includes/Layouts/QuestionnaireV10/SupportDocument";
import QuestionnaireEmail from "../Includes/Layouts/QuestionnaireV10/QuestionnaireEmail";
import { useUserVerify } from "../../Hooks/useUserVerify";
import GtmDataLayer from "../../Utility/GtmDataLayer";
import { MonthNumberArray } from "../../Constants/Constants";
import EmailConfirmation from "../Includes/Layouts/QuestionnaireV10/EmailConfirmation";
import QuestionnaireSupportDoc from "../Includes/Layouts/QuestionnaireV10/QuestionnaireSupportDoc";
import QuestionnaireAgreementNoModal from "../Includes/Layouts/QuestionnaireV10/QuestionnaireAgreementNoModal";
import { useSkippedLeads } from "../../Hooks/useSkippedLeads";
import { userMilestoneUpdateV2 } from "../../Hooks/userMilestoneUpdateV2";



const initialState = {
  showSlide1: "show",
  showSlide2: "hide",
  showSlide3: "hide",
  showSlide4: "hide",
  showSlide51: "hide",
  showSlide5: "hide",
  percentage: 0,

};

const FormReducer = (state, action) => {
  switch (action.type) {
    case "showSlide": {
      if (action.payload.clickedSlide.slide == "quest117") {
        GtmDataLayer({
          'question': 'Further details'
        });
        return {
          ...state,
          showSlide2: "hide",
          showSlide3: "show",
          percentage: 50
        };
      } else if (action.payload.clickedSlide.slide == "quest120") {
        return {
          ...state,
          showSlide3: "hide",
          showSlide5: "show",
          percentage: 80
        };
      } else if (action.payload.clickedSlide.slide == "slide_4") {
        GtmDataLayer({
          'question': 'Income details'
        });
        return {
          ...state,
          showSlide1: "hide",
          showSlide51: "show",
          percentage: 20
        };
      } else if (action.payload.clickedSlide.slide == "question_61") {
        return {
          ...state,
          showSlide51: "hide",
          showSlide3: "show",
          percentage: 35
        };
      } else if (action.payload.clickedSlide.slide == "question_61a") {
        return {
          ...state,
          showSlide51: "hide",
          showSlide2: "show",
          percentage: 25
        };
      } else if (action.payload.clickedSlide.slide == "question_42") {
        return {
          ...state,
          showSlide5: "hide",
          showSlide4: "show",
          percentage: 95

        };
      }
      else {
        return {
          ...state,
        };
      }
    }
    default:
      return state;
  }
};

const QuestionnaireV10 = () => {
  const { isCheck } = CheckUUID();
  const { getUserVerfyValidation, saveUserVerify } = useUserVerify();
  isCheck();
  const history = useHistory();
  const [completedQuestArr, setCompletedQuestArr] = useState([]);
  const { isLoading, SaveQuestionnaire } = useQuestionnaire();
  const { visitorParameters } = useContext(VisitorParamsContext);
  const [checkboxValues, SetCheckboxValues] = useState([]);
  const [displayError, SetDisplayError] = useState("d-none");
  const [modalSub1Ans, setModalSub1Ans] = useState(null);
  const [modalSub2Ans, setModalSub2Ans] = useState(null);
  const query_string = localStorage.getItem("querystring");
  const formdata = JSON.parse(localStorage.getItem("formData"));
  const [checkQuestion, setCheckQuestion] = useState(0);
  // const [percentage, SetPercentage] = useState(0);
  const [lastClickQuest, setLastClickQuest] = useState(null);
  const [documentTypeId, setDocumentTypeId] = useState(null);
  const [documentType, setDocumentType] = useState(null);
  const [docType, setDocType] = useState("");
  const [splitNameForSkip, setSplitNameForSkip] = useState();
  const [t2a, setT2a] = useState();
  const [userEmail, setUserEmail] = useState();

  const scrollRef = useRef([]);
  const splitForm = useRef(null);
  const [state, dispatch] = useReducer(FormReducer, initialState);

  const [splitName, setSplitName] = useState();
  const { getUserInfo } = userInfo();
  const { setFormData } = FormData();
  const [showLoad, setShowLoad] = useState("show");
  const [showUserName, setUserName] = useState();
  const [clickedSlide, setClickedSlide] = useState([]);
  const [optionSelected] = useState([]);
  const [t2aApiReqData, setT2aApiReqData] = useState({});
  const [agNoShow, setAgNoShow] = useState("hide");
  const { skippedLeads } = useSkippedLeads();
  const { updateMilestoneV2 } = userMilestoneUpdateV2();

  const { register, errors, trigger, setError, clearErrors, getValues } =
    useForm({
      mode: "onBlur",
      reValidateMode: "onChange",
      defaultValues: {},
      resolver: undefined,
      context: undefined,
      criteriaMode: "firstError",
      shouldFocusError: true,
      shouldUnregister: true,
    });
  const getUuid = () => {
    const visitorData = visitorParameters.visitor_parameters;
    if (visitorData != "") {
      var uuid = visitorData.uuid;
    } else {
      const query = new URLSearchParams(window.location.search);
      const query_uuid = query.get("uuid");
      const local_storage_uuid = localStorage.getItem("uuid");
      var uuid = query_uuid ? query_uuid : local_storage_uuid;
    }
    return uuid;
  };
  const uuid = getUuid();

  useEffect(() => {
    if (formdata === undefined || formdata === null || formdata == "") {
      (async () => {
        const response = await getUserInfo(uuid);
        const set_formdata = setFormData(response);
        let dataLength = response.data.response.length;
        if (dataLength > 0) {
          const pageName = response.data.response[0].page_name
            ? response.data.response[0].page_name
            : " ";
          setSplitName(pageName);
          if (localStorage.getItem("split_name") != undefined && localStorage.getItem("split_name") != undefined) {
            setSplitNameForSkip(localStorage.getItem("split_name"));
          } else {
            setSplitNameForSkip(pageName);
          }
          const userName = response.data.response[0].first_name
            ? response.data.response[0].first_name +
            " " +
            response.data.response[0].last_name
            : " ";

          var userDobN = response.data.response[0].user_dob.split('/');
          var DobDayF = userDobN[0];
          var DobMonthF = userDobN[1];
          var DobYearF = userDobN[2];

          setT2aApiReqData({
            uuid: uuid,
            fName: response.data.response[0].first_name,
            lName: response.data.response[0].last_name,
            pCode: response.data.response[0].postcode,
            addressLine1: response.data.response[0].address_line1,
            dob_y: DobYearF,
            dob_d: DobDayF,
            dob_m: DobMonthF,
            addressLine2: response.data.response[0].address_line2,
            postTown: response.data.response[0].town,
            country: response.data.response[0].county,
            title: response.data.response[0].title,
            addressLine3: response.data.response[0].address_line3,
            addressLine4: response.data.response[0].address_line4,
            county: response.data.response[0].county
          });

          setDocumentTypeId(response.data.response[0]?.questionnaire[42]['answerId']);

          setUserName(userName);
          const timer = setTimeout(() => {
            setShowLoad("hide");
          }, 3000);
        }
      })();
    } else {
      const pageName = formdata.page_name;
      setSplitName(pageName);
      if (localStorage.getItem("split_name") != undefined && localStorage.getItem("split_name") != undefined) {
        setSplitNameForSkip(localStorage.getItem("split_name"));
      } else {
        setSplitNameForSkip(pageName);
      }
      const userName = formdata.txtFName + " " + formdata.txtLName;
      const dobMonth = formdata.DobMonth ? MonthNumberArray[formdata.DobMonth] : '';

      setUserName(userName);
      setT2aApiReqData({
        uuid: uuid,
        fName: formdata.txtFName,
        lName: formdata.txtLName,
        pCode: formdata.txtPostCode,
        addressLine1: formdata.txtHouseNumber,
        dob_y: formdata.DobYear,
        dob_m: dobMonth,
        dob_d: formdata.DobDay,
        addressLine2: formdata.txtAddress2,
        postTown: formdata.txtTown,
        country: formdata.txtCountry,
        title: formdata.lstSalutation,
        addressLine3:formdata.txtAddress3,
        addressLine4:formdata.txtAddress4 ? formdata.txtAddress4 : '',
        county:formdata.txtCounty
      });
      const timer = setTimeout(() => {
        setShowLoad("hide");
      }, 3000);
      setDocumentTypeId(formdata.question_42);
      setDocumentType(answerArray[documentTypeId]);
      setDocType(supportDocTypeArray[formdata.question_42]);
      setUserEmail(formdata.txtEmail);
    }
  }, [splitName]);
  const onselectAnswer = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");
    const valuesreg = getValues();
    let validEmail = valuesreg["question_48"];

    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    setClickedSlide({ slide: e.target.name });

    let flage = false;
    let question_data = {};
    if (e.target.name == "btn_qest_48") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 48,
          option_id: null,
          answer_text: null,
          input_answer: validEmail,
        };
      }
      setClickedSlide({ slide: "quest117" });
    } else if (e.target.name == 'question_61' && selectedAns == '163') {
      setClickedSlide({ slide: "question_61a" });
    }
    else if (e.target.name == 'question_42') {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 42,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: null,
        };
      }
      if (selectedAns == '124') {
        setAgNoShow('show');
      }
      else if (selectedAns == '123') {
        redirectToPreview();
      }
      else {
        setClickedSlide({ slide: "question_42" });
      }
    }
    else if (e.target.name == "btn_qest_36") {
      if (selectedQuest) {
        flage = true;
        question_data = {
          question_id: 36,
          option_id: null,
          answer_text: anslabel,
          input_answer: optionSelected,
        };
      }
      sett2aValidation();
      setClickedSlide({ slide: "quest120" });
    } else if (e.target.name == "btn_qest_45") {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: null,
          answer_text: "",
          input_answer: e.target.value,
        };
      }
      // sett2aValidation();
    } else {
      if (selectedAns && selectedQuest) {
        flage = true;
        question_data = {
          question_id: selectedQuest,
          option_id: selectedAns,
          answer_text: anslabel,
          input_answer: "",
        };
      }
    }

    if (flage) {
      storeQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));
    }
  };
  const saveInputAns = async (e) => {
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    setCompletedQuestArr([...completedQuestArr, selectedQuest]);
    const valuesreg = getValues();
    let regPlate = valuesreg["question_44"];
    let financeAgremmentNo = valuesreg["question_45"];
    let flage = false;
    let question_data = {};

    if (selectedQuest == '44' && regPlate) {
      setClickedSlide({ slide: "question_32" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: regPlate,
      };

    }
    else if (selectedQuest == '45' && financeAgremmentNo) {
      setClickedSlide({ slide: "question_45" });
      flage = true;
      question_data = {
        question_id: selectedQuest,
        option_id: null,
        answer_text: "",
        input_answer: financeAgremmentNo,
      };
    }

    if (flage) {
      storeQuestionnire(question_data);
      var existingEntries = JSON.parse(localStorage.getItem("questionData"));
      if (existingEntries == null) existingEntries = [];
      localStorage.setItem(
        "currentquestionData",
        JSON.stringify(question_data)
      );
      existingEntries.push(question_data);
      localStorage.setItem("questionData", JSON.stringify(existingEntries));

      if (selectedQuest == '45') {
        redirectToPreview();
      }
    }




  }
  const redirectToPreview = async () => {
    if (t2a == 1) {
      (async () => {
        const response = await updateMilestoneV2(
          visitorParameters.visitor_parameters.uuid,
          t2a
        );
      })();
    }
    history.push("/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid);
  }
  const SkippedLeads = async (type) => {
    // type : SkipAgreementNo,SkipDoc
    const skippedLeadsResponse = await skippedLeads(type, uuid, splitName);
    if (skippedLeadsResponse.data.status == "Success") {
      redirectToPreview();
    }
  }
  const skipAgNo = async (e) => {
    // setAgNoShow('hide');
    SkippedLeads("SkipAgreementNo");
    // redirectToPreview();
  }
  const saveSlide4 = async (e) => {
    let selectedAns = parseInt(e.target.getAttribute("data-ans-id"));
    let selectedQuest = parseInt(e.target.getAttribute("data-quest-id"));
    let anslabel = e.target.getAttribute("data-ans-label");

    setCompletedQuestArr([...completedQuestArr, selectedQuest]);

    var answer31 = splitForm.current["question_31"].value;
    var answer32 = splitForm.current["question_32"].value;
    var answer33 = splitForm.current["question_33"].value;
    //var answer34 = splitForm.current["question_34"].value;
    var answer35 = splitForm.current["question_35"].value;

    var slide_array = [
      ["31", answer31],
      ["32", answer32],
      ["33", answer33],
      //["34", answer34],
      ["35", answer35],
    ];

    var question_data = {
      slide_4: slide_array,
    };
    setClickedSlide({ slide: "slide_4" });
    storeQuestionnire(question_data);

    let _existingEntries = JSON.parse(localStorage.getItem("questionData"));
    if (_existingEntries == null) _existingEntries = [];
    _existingEntries.push(question_data);
    localStorage.setItem("questionData", JSON.stringify(_existingEntries));
  };

  useEffect(() => {
    const totalQuestArr = [31, 36, 48];

    console.log(totalQuestArr);
    var questIntersection = totalQuestArr.filter(
      (x) => !completedQuestArr.includes(x)
    );
    console.log(questIntersection);

    // SetPercentage(
    //   Math.round((completedQuestArr.length * 100) / totalQuestArr.length)
    // );

  }, [completedQuestArr]);

  const handleCheckBoxChange = (e) => {
    SetDisplayError("d-none");
    let checkVal = e.target.value;
    if (e.target.checked == true) {
      SetCheckboxValues([...checkboxValues, checkVal]);
    } else {
      SetCheckboxValues(checkboxValues.filter((e) => e !== checkVal));
    }
  };
  const onModalClick = (e) => {
    let selectedAns = e.target.getAttribute("data-ans-id");
    let selectedQuest = e.target.getAttribute("data-quest-id");
    let selectedAnsTxt = e.target.getAttribute("data-ans-label");
    if (selectedQuest === "sub1") {
      setModalSub1Ans(selectedAns);
    } else if (selectedQuest === "sub2") {
      setModalSub2Ans(selectedAns);
    }
  };
  const storeQuestionnire = async (question_data) => {
    const response = await SaveQuestionnaire(
      visitorParameters.visitor_parameters,
      question_data,
      "question_store",
      visitorParameters.data,
      query_string,
      formdata
    );
  };

  const storeT2aResponse = async (t2a_data) => {
    const response = await saveUserVerify(
      visitorParameters.visitor_parameters,
      t2a_data,
      "person_verify_store",
      visitorParameters.data,
      query_string,
      t2aApiReqData,
    );
  };

  const sett2aValidation = () => {
    const useruuid = t2aApiReqData.uuid;
    const fName = t2aApiReqData.fName;
    const lName = t2aApiReqData.lName;
    const pCode = t2aApiReqData.pCode;
    const addressLine1 = t2aApiReqData.addressLine1;
    const dobYr = t2aApiReqData.dob_y;
    const dobDy = t2aApiReqData.dob_d;
    const addressLine2 = t2aApiReqData.addressLine2;
    const postTown = t2aApiReqData.postTown;
    const country = t2aApiReqData.country;
    const title = t2aApiReqData.title;
    const addressLine3 = t2aApiReqData.addressLine3;
		const addressLine4 = t2aApiReqData.addressLine4;
		const county = t2aApiReqData.county;
    let dobMon;
    if (t2aApiReqData.dob_m === undefined) {
      dobMon = formdata.DobMonth;
    }
    else {
      dobMon = t2aApiReqData.dob_m;
    }

    if (useruuid && fName && lName && pCode && addressLine1) {
      (async () => {
        const response = await getUserVerfyValidation(
          useruuid,
          fName,
          lName,
          pCode,
          addressLine1,
          dobYr,
          dobMon,
          dobDy,
          addressLine2,
          postTown,
          country,
          title,
          addressLine3,
					addressLine4,
					county
        );
        console.log(response.data);

        if (response.data.status == "success") {
          setT2a(1);
          localStorage.setItem("t2aValidation", 1);
        } else {
          setT2a(0);
          localStorage.setItem("t2aValidation", 0);
        }
        window.scrollTo(0, 0);
        // history.push("/view_docs?uuid=" + visitorParameters.visitor_parameters.uuid);
        storeT2aResponse(response.data);
      })();


    }

  }
  const handleDownClick = () => {
    scrollRef.current[lastClickQuest + 2]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    setLastClickQuest(lastClickQuest + 1);
  };
  const handleUpClick = () => {
    scrollRef.current[lastClickQuest]?.scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
    setLastClickQuest(lastClickQuest - 1);
  };


  useEffect(() => {
    if (clickedSlide) {
      dispatch({ type: "showSlide", payload: { clickedSlide } });
    }
  }, [clickedSlide]);

  useEffect(() => {
    localStorage.removeItem('questionData');
  }, []);

  return (
    <>
      <GetVisitorsParams />
      <div className="QuestionnaireV10">
        <QuestionnaireLoder showUserName={showUserName} showLoad={showLoad} />

        <section
          className="formsection"
          style={
            showLoad === "hide" ? { display: "block" } : { display: "none " }
          }
        >
          <div className="container">
            <div className="row">
              <form
                ref={splitForm}
                name="split_form"
                id="user_form"
                method="POST"
                autoComplete="off"
              //onSubmit={handleSubmit}
              >
                <div className="offset-xl-3 col-xl-6 offset-lg-3 col-lg-6 offset-md-2 col-md-8 col-12">
                  <div className="radio-box animated fadeInUp">


                    <Monthly
                      clsName={`space-top2 ${state.showSlide1}`}
                      //reff={(el) => (scrollRef.current[31] = el)}
                      questLabel="Income and Expenditure Details"
                      questArr={questinnare1}
                      saveSlide4={saveSlide4}
                      name="question_31_35"
                      btnName="slide_4"
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                    />
                    <EmailConfirmation
                      clsName={`space-top2 ${state.showSlide51}`}
                      reff={(el) => (scrollRef.current[41] = el)}
                      onselectAnswer={onselectAnswer}
                      userEmail={userEmail}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}

                    />

                    <QuestionnaireEmail
                      clsName={`space-top2 ${state.showSlide2}`}
                      reff={(el) => (scrollRef.current[43] = el)}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      onselectAnswer={onselectAnswer}
                    />

                    <PointsSelect
                      clsName={`space-top ${state.showSlide3}`}
                      reff={(el) => (scrollRef.current[36] = el)}
                      questLabel='Further details to be considered'
                      questArr={questinnare2LS}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      optionSelected={optionSelected}
                    />

                    {/* <AgreementNo
                      clsName={`space-top ${state.showSlide4}`}
                      //reff={(el) => (scrollRef.current[10] = el)}
                      uuid={uuid}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      page_name={splitName}
                      onselectAnswer={onselectAnswer}
                      documentType={documentType}
                    /> */}
                    <QuestionnaireSupportDoc
                      clsName={`space-top ${state.showSlide5}`}
                      reff={(el) => (scrollRef.current[49] = el)}
                      onselectAnswer={onselectAnswer}

                    />


                    <SupportDocument
                      clsName={`space-top ${state.showSlide4}`}
                      reff={(el) => (scrollRef.current[43] = el)}
                      onselectAnswer={onselectAnswer}
                      validation={register}
                      validationMsg={errors}
                      getValues={getValues}
                      trigger={trigger}
                      setError={setError}
                      documentType={documentType}
                      docType={docType}
                      split_name={splitNameForSkip}
                      redirectToPreview={redirectToPreview}
                    />

                  </div>
                </div>
                <QuestionnaireAgreementNoModal
                  validation={register}
                  validationMsg={errors}
                  trigger={trigger}
                  saveInputAns={saveInputAns}
                  agNoShow={agNoShow}
                  skipAgNo={skipAgNo}
                />
              </form>
            </div>

            <QuestionnaireFooter
              percentage={state.percentage}
              onDownClick={handleDownClick}
              onUpClick={handleUpClick}
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default QuestionnaireV10;
