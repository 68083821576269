import React, { useState } from "react";
import ProgressBar from "../Common/ProgressBar";
import RadioButton from "../../../UI/RadioButton";
import Modal from "react-bootstrap/Modal";
import InputButton from "../../../UI/InputButton";
const Filed = ({
  className,
  slideChange,
  textHeadingLine1,
  textHeadingLine2,
  validation,
  backClick,
}) => {
  const [checkedOne, setCheckedOne] = useState("unchecked");
  const [checkedTwo, setCheckedTwo] = useState("unchecked");
  const [claimModal, setshowClaimModal] = useState(false);
  const [showFirstModal, setShowFirstModal] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);

  const handleShowFirst = () => setShowFirstModal(true);
  const handleCloseFirst = () => setShowFirstModal(false);
  const handleCloseclaimModal = () => setshowClaimModal(false);
  const handleShowSecond = () => setShowSecondModal(true);
  const handleCloseSecond = () => setShowSecondModal(false);

  const nextSlide = async (e) => {
    if (e.target.value === "101") {
      setCheckedOne("checked");
      setshowClaimModal(true);
     // slideChange(e);
    } else {
      setCheckedOne("unchecked");
    }

    if (e.target.value === "102") {
      setCheckedTwo("checked");
      slideChange(e);
      // handleShowFirst();
      // setTimeout(() => {
      //   handleCloseFirst();
      //   handleShowSecond();
      // }, 3000);
      // setTimeout(() => {
      //   handleCloseSecond();
        
      // }, 6000);
    } else {
      setCheckedTwo("unchecked");
    }
    
  };
  return (
    <>
      <div id="slide-9" className={`${className}`}>
        <ProgressBar value="70" />

        <h3> {textHeadingLine1} </h3>
        <h4> {textHeadingLine2} </h4>
        <div className="radio_buttons">
          <RadioButton
            value="102"
            name="filed"
            className="radio-button"
            labelName="No"
            labelClassName={`option_but radio ${checkedTwo}`}
            onClick={nextSlide}
            validation={validation()}
          />
          <RadioButton
            value="101"
            name="filed"
            className="radio-button"
            labelName="Yes"
            labelClassName={`option_but radio ${checkedOne}`}
            onClick={nextSlide}
            validation={validation()}
          />
        </div>
        <p className="text-center">
          <span
            className="back-btn btn-link"
            name="back09"
            children="<< Previous"
            onClick={backClick}
          ></span>
        </p>
      </div>
      {/* **** cannot accept your claim popup start **** */}
      <Modal
        show={showFirstModal}
        onHide={handleCloseFirst}
        backdrop="static"
        keyboard={false}
        className="formmodal"
        contentClassName="modalcontent"
      >
        <Modal.Body className="modal-bordr">
          <div id="slide7" className="col-12 slide-pad text-center">
            <div className="form-group text-center" id="analize1">
              <h2>Analysing Your Results</h2>
              <img src="/assets/MB_UBRF_1/img/review.gif" alt="" />
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showSecondModal}
        onHide={handleCloseSecond}
        backdrop="static"
        keyboard={false}
        className="formmodal"
        contentClassName="modalcontent"
      >
        <Modal.Body className="modal-bordr">
          <div id="slide7nxt" className="col-12 slide-pad text-center">
            <div className="form-group text-center" id="analize1">
              <h2>Good News! </h2>
              <p>
                We are able to assist you further. Please give us some back
                information. <br />
                (This will only take 1 minute)
              </p>
              <img src="/assets/MB_UBRF_1/img/review.gif" alt="" />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal size="md" show={claimModal} onHide={handleCloseclaimModal} centered backdrop="static">
        <Modal.Body>
          <h3 className="text-center fonts15">Sorry, we cannot accept your claim</h3>
          <div className="input-group mb-3 prevname-Btnpop justify-content-center">
            <InputButton
              className="btn next08  regNextBtnNew text-center"
              name="back04"
              value="Back"
              btnType="button"
              onClick={handleCloseclaimModal}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Filed;
