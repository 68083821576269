import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import InputButton from "../../../UI/InputButton";
import GtmDataLayer from "../../../../Utility/GtmDataLayer";
const Difficulties = ({
    onSelectAnswer,
    backClick
}) => {
    const [checkedOne, setCheckedOne] = useState("unchecked");
    const [checkedTwo, setCheckedTwo] = useState("unchecked");
    const [claimModal, setshowClaimModal] = useState(false);
    const handleCloseclaimModal = () => setshowClaimModal(false);

    const optionSelected = async (e) => {
        GtmDataLayer({
            question: "financial difficulties",
          });
        if (e.target.value === "241") {
            setCheckedOne("checked");
            setCheckedTwo("unchecked");
            setshowClaimModal(true);
        } else {
            setCheckedOne("unchecked");
        }
        if (e.target.value === "240") {
            setCheckedTwo("checked");
            onSelectAnswer(e);
        } else {
            setCheckedTwo("unchecked");
        }

    };

    return (
        <>
            <div className="question-content mt-0 scale-down-ver-center pt-0 px-3 py-lg-3 py-md-3 py-sm-2 pt-lg-0 pt-md-0 pt-1 py-2 pb-3">
                <ul className="mb-3 listStyle">
                    <li>The lender will claim that you should have known that <b>they</b> were at fault when you received these communications, and too much time has now passed.</li>
                    <li>If you consider that any financial difficulties you had were <b>your fault / responsibility</b>, not theirs we can argue your case with them and still do your claim.</li>
                </ul>
             
             
                <div className="row" >
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className={`btn-comm2 py-lg-3 py-md-3 py-sm-2 py-2 w-100 nextBtn btn-height ${checkedTwo}`}
                                onClick={optionSelected}
                                value="240"
                                name="difficulties"
                                id="nextscroll"
                                data-quest-id={83}
                                data-ans-id={240}
                                data-ans-label={null}
                            >I thought that it was my fault / responsibility
                            </button>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className=" mb-2">
                            <button
                                type="button"
                                className={`btn-comm4 py-lg-3 py-md-3 py-sm-2 py-2 w-100 noissue btn-height nextBtn ${checkedOne}`}
                                onClick={optionSelected}
                                value="241"
                                name="difficulties"
                                id="nextscroll"
                                data-quest-id={83}
                                data-ans-id={241}
                                data-ans-label={null}
                            >I thought that it was the MoneyBarn’s fault / responsibility
                            </button>
                        </div>
                    </div>
                </div>
                <div className=" text-center mt-2">
                    <button className="backbtn" type="button" name="latePayment" onClick={backClick}>
                        {" "}
                        <i className="bi bi-arrow-left" /> Back
                    </button>
                </div>
            </div>

            <Modal size="md" show={claimModal} onHide={handleCloseclaimModal} centered backdrop="static" className="modal fade " id="cannotClaim">
                <div className="modal-content">
                    <Modal.Body>
                        <div className="text-center my-3">
                            <svg width="50px" height="50px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.5 9C8.22386 9 8 8.77614 8 8.5C8 8.22386 8.22386 8 8.5 8C8.77614 8 9 8.22386 9 8.5C9 8.77614 8.77614 9 8.5 9Z" fill="#EF8019" stroke="#EF8019" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M15.5 9C15.2239 9 15 8.77614 15 8.5C15 8.22386 15.2239 8 15.5 8C15.7761 8 16 8.22386 16 8.5C16 8.77614 15.7761 9 15.5 9Z" fill="#EF8019" stroke="#EF8019" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#EF8019" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M7.5 15.5C7.5 15.5 9 13.5 12 13.5C15 13.5 16.5 15.5 16.5 15.5" stroke="#EF8019" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>

                        <h4 className="text-center my-4">Sorry we are unable to assist with this claim, as MoneyBarn are very likely to time-bar it, and not review it.</h4>
                        <div className="input-group  prevname-Btnpop justify-content-center">
                            <InputButton
                                className="btn-comm2modalreject py-lg-3 py-md-3 py-sm-2 py-2 w-100"
                                name="modalBack"
                                value="Close"
                                btnType="button"
                                onClick={handleCloseclaimModal}
                            />
                        </div>
                    </Modal.Body>
                </div>
            </Modal>
        </>
    )
}
export default Difficulties;