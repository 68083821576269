import '../../assets/ObUnqualified/scss/main.scss'
import logo from '../../assets/ObUnqualified/img/a00187b31dd5a4ece0c8f809b69962eb.png'
import document from '../../assets/ObUnqualified/img/image.png'


import { useContext, useEffect } from 'react'
import { Api } from '../../api/Api'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import VisitorParamsContext from '../../Contexts/VisitorParams'

export default function ObUnqualified() {
    const history = useHistory()
    const { visitorParameters } = useContext(VisitorParamsContext);

    const queryParameters = new URLSearchParams(window.location.search)
    const atp_sub2 = queryParameters.get("atp_sub2")
    useEffect(() => {
        const addUserVisit = async () => {
            try {
                const response = await Api.get(`/api/v1/unqualified-ob?query_string=atp_sub2=${atp_sub2}`);
                console.log("Response:", response);
                // Handle the response as needed
            } catch (error) {
                console.log("Error fetching data:", error);
                // Handle the error, e.g., set an error state
            }
        };

        addUserVisit();
    }, [atp_sub2]);
    return (
        <div className='ob_unqulified'>
            <div className="tms-wrap2 ">
                <div className="main text-center main-div ">
                    {/* header  */}
                    <div className="brand-logo mt-lg-0 mt-md-2 mt-sm-2 mt-2">
                        <img height={65} src={logo} alt='logo' />
                    </div>
                    <div className="main-form-wraper">
                    <img  className='pb-3' src={document} alt='document' />
                        <div className="container">
                            <div className="item-cent-flx ">
                                {/* ---------------------------------------------- Slide ---------------------------------------------- */}
                                <div className="chooseDocumType" >
                                    <h1 className="mt-md-2 mt-sm-2 mt-0 text-danger"> We acknowledge your decision to stop at this point.</h1>
                                    <p style={{ maxWidth: 800, margin: '0 auto' }} className="my-3"> Your request for a claim has been closed.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
    )
}
